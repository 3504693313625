import { Injectable } from '@angular/core';

import {ReporterUtilityService} from './reporter-utility.service';


@Injectable({
  providedIn: 'root'
})

/**
 * Service used when loading artifact data.
 */
export class ReporterArtifactLoaderService {

  private _overviewFieldsDataSet = null;

  /**
   * Object constructor
   * @param reporterUtility ReporterUtilityService
   */
  constructor(private reporterUtility: ReporterUtilityService) {

  }

  /**
   * Loads the specified artifacts.
   * @param pdfType
   * @param artifactIDs
   * @param overviewFieldsDataSet
   */
  public loadArtifacts(pdfType, artifactIDs, overviewFieldsDataSet) {
    return new Promise( resolve => {

      const url = this.reporterUtility.getCmsApiUrl('artifact/artifacts_simple'),
          params = {
            artifact_ids: artifactIDs,
            no_sections: (pdfType !== 'fullobject')
          };

      this._overviewFieldsDataSet = overviewFieldsDataSet;

      this.reporterUtility.makeReq('POST', url, JSON.stringify(params))
        .then((res) => {
          let artifacts = res['artifacts'];
          artifacts = this._mergeOverviewFieldsData(artifacts);
          resolve(artifacts);
        });

    });
  }

  /**
   * Merge data specified in overview fields with the artifacts data set.
   * @param artifacts
   * @private
   */
  private _mergeOverviewFieldsData(artifacts) {
    artifacts.forEach((art, ix) => {
      const artifactOverviewFields = this._overviewFieldsDataSet[art.artifact_id];

      if (typeof(artifactOverviewFields) !== 'undefined') {
        artifactOverviewFields.forEach(function (fld) {
          if (typeof(art[fld.name]) === 'undefined') {
            artifacts[ix][fld.name] = fld.value;
          }
        });
      }
    });

    return artifacts;
  }

}
