import {Component, OnInit} from '@angular/core';
import {StateService, UIRouterGlobals} from '@uirouter/core';
import {ChangeTrackerService} from '../../core/change-tracker.service';
import {UserDialogsService} from '../../shared/user-dialogs.service';
import {CmsApiService} from '../../core/cms-api.service';
import {ModelObjectService} from '../../core/model-object.service';
import {AConst} from '../../core/a-const.enum';
import {ObjectEditService} from '../../core/object-edit.service';
import {SelectorContainer} from '../../core/selector-container';
import {FieldValueService} from '../../core/field-value.service';
import {CommonsService} from '../../core/commons.service';

@Component({
  selector: 'app-admin-template-group',
  templateUrl: './admin-template-group.component.html',
  styleUrls: ['./admin-template-group.component.scss']
})
export class AdminTemplateGroupComponent implements OnInit {

  AConst = AConst;
  searching = false;
  templateGroups;
  createDisabled = true;
  parent;
  templateSelectorContainer: SelectorContainer;

  private adminType;
  private curStateName = 'home.primus.admin.templateGroup';
  private sectionsContainers = {};

  constructor(private stateService: StateService,
              private uiRouterGlobals: UIRouterGlobals,
              private changeTracker: ChangeTrackerService,
              private userDialogsService: UserDialogsService,
              private cms: CmsApiService,
              private modelObjectService: ModelObjectService,
              private objectEditService: ObjectEditService,
              private fieldValueService: FieldValueService,
              private commons: CommonsService) {
  }

  ngOnInit() {
    this.adminType = this.uiRouterGlobals.params['admin_type'];
    this.parent = {
      path: 'home.primus.admin',
      adminType: this.adminType,
      subMenus: true,
      target_params: {admin_type: this.adminType},
      currentState: this.curStateName
    };
    this.loadTemplateGroups();
  }

  getSectionsContainer(group) {
    let res;
    if (group.$$uuid && this.sectionsContainers.hasOwnProperty(group.$$uuid)) {
      res = this.sectionsContainers[group.$$uuid];
    } else {
      this.setGroupHelperProps(group);
    }
    return res;
  }

  createTemplateGroup() {
    const templateGroupsField = this.templateGroups[AConst.$$META][AConst.TEMPLATE_GROUPS];
    const newGroup = this.fieldValueService.createAddItem(this.templateGroups, templateGroupsField);
    newGroup['$$isEditing'] = true;
    this.setGroupHelperProps(newGroup);
    this.objectEditService.setTopModel(this.templateGroups, AConst.TEMPLATE_GROUPS);
    this.createDisabled = true;
    this.setTemplateGroupOrder();
  }

  deleteCallback() {
    this.loadTemplateGroups();
  }

  showUsage(group) {
    this.userDialogsService.showObjectUsage(group[AConst.TEMPLATE_GROUP_ID]).then();
  }

  onTemplateSelectorCreated(templateSelectorContainer) {
    this.templateSelectorContainer = templateSelectorContainer;
  }

  onChangeTemplateGroup(templateGroupId) {
    this.stateService.go('home.primus.admin.templateModel', {
      template_group_id: templateGroupId
    });
  }

  onCloseEdit(group) {
    if (group) {
      group['$$isEditing'] = false;
    }
    this.createDisabled = false;
  }

  private loadTemplateGroups() {
    this.searching = true;
    this.cms.getMetaObject({
      objectType: 'TemplateGroups'
      // , template_group_type_id: "ct_208-1"
    }).then(data => {
      if (data) {
        this.objectEditService.createModelItem('TemplateGroups', data).then(groups => {
          this.templateGroups = groups;
          this.setTemplateGroupOrder();
          this.searching = false;
          this.createDisabled = false;
          this.objectEditService.setTopModel(groups, AConst.TEMPLATE_GROUPS);
        });
      } else {
        this.objectEditService.createModelItem('TemplateGroups', null).then(groupsItem => {
          this.templateGroups = groupsItem;
          this.searching = false;
          this.createDisabled = false;
        });
      }
    });
  }

  private setTemplateGroupOrder() {
    this.templateGroups[AConst.TEMPLATE_GROUPS] = this.commons.orderArray(
      this.templateGroups[AConst.TEMPLATE_GROUPS], 'order_number', true);
  }

  private setGroupHelperProps(group) {
    group.$$uuid = this.commons.uuid();
    if (!group.$$isSetting) {
      group.$$isSetting = true;
      this.modelObjectService.getSectionsAndFormGroup(group).then(sc => {
        sc.hideSectionTitle = true;
        sc.viewMode = 'admin';
        this.sectionsContainers[group.$$uuid] = sc;
      });
    }
  }

}
