import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MatDatepickerModule} from '@angular/material';

import {EditFieldSelectComponent} from './edit-field-select/edit-field-select.component';
import {EditFieldSelectOptionsComponent} from './edit-field-select/edit-field-select-options/edit-field-select-options.component';
// tslint:disable-next-line:max-line-length
import {EditFieldSelectSelectedOptionsComponent} from './edit-field-select/edit-field-select-selected-options/edit-field-select-selected-options.component';
import {SharedModule} from '../shared/shared.module';
import {AConst} from '../core/a-const.enum';
import {EditObjectDialogComponent} from './edit-object-dialog/edit-object-dialog.component';
import {EditFieldComponent} from './edit-field/edit-field.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {EditFieldsComponent} from './edit-fields/edit-fields.component';
import {SectionViewEditComponent} from './section-view-edit/section-view-edit.component';
import {SectionViewTitleEditComponent} from './section-view-edit/section-view-title-edit/section-view-title-edit.component';
import {EditFieldInlineArrayComponent} from './edit-field-inline-array/edit-field-inline-array.component';
// tslint:disable-next-line:max-line-length
import {EditFieldInlineArrayItemTopBarComponent} from './edit-field-inline-array/edit-field-inline-array-item-top-bar/edit-field-inline-array-item-top-bar.component';
// tslint:disable-next-line:max-line-length
import {EditFieldInlineArrayAddButtonComponent} from './edit-field-inline-array/edit-field-inline-array-add-button/edit-field-inline-array-add-button.component';
import {EditFieldIdentifierComponent} from './edit-field-identifier/edit-field-identifier.component';
// tslint:disable-next-line:max-line-length
import {EditFieldInlineArrayFieldsComponent} from './edit-field-inline-array/edit-field-inline-array-fields/edit-field-inline-array-fields.component';
import {EditFieldSwitchComponent} from './edit-field-switch/edit-field-switch.component';
import {EditFieldCompareComponent} from './edit-field-compare/edit-field-compare.component';
import {EditFieldSearchSelectorComponent} from './edit-field-search-selector/edit-field-search-selector.component';
// tslint:disable-next-line:max-line-length
import {EditFieldSearchSelectorMultipleComponent} from './edit-field-search-selector-multiple/edit-field-search-selector-multiple.component';
// tslint:disable-next-line:max-line-length
import {EditFieldInlineArrayDeleteButtonComponent} from './edit-field-inline-array/edit-field-inline-array-delete-button/edit-field-inline-array-delete-button.component';
import {CopyKeepCheckboxComponent} from './copy-keep-checkbox/copy-keep-checkbox.component';
import {CopyKeepService} from './copy-keep-checkbox/copy-keep.service';
// tslint:disable-next-line:max-line-length
import {EditFieldInlineArrayItemComponent} from './edit-field-inline-array/edit-field-inline-array-item/edit-field-inline-array-item.component';
import {EditFieldSelectHierarchyComponent} from './edit-field-select/edit-field-select-hierarchy/edit-field-select-hierarchy.component';
// tslint:disable-next-line:max-line-length
import {EditFieldSelectChildOptionsComponent} from './edit-field-select/edit-field-select-child-options/edit-field-select-child-options.component';
// tslint:disable-next-line:max-line-length
import {EditFieldSelectHierarchyNodesComponent} from './edit-field-select/edit-field-select-hierarchy-nodes/edit-field-select-hierarchy-nodes.component';
import {EditPrimeFieldsComponent} from './edit-prime-fields/edit-prime-fields.component';
import {FolderEditDialogComponent} from './folder-edit-dialog/folder-edit-dialog.component';
import {FolderAppendDialogComponent} from './folder-append-dialog/folder-append-dialog.component';
import {EditFieldCheckArrayComponent} from './edit-field-check-array/edit-field-check-array.component';
import {EditFieldPrecisionDateComponent} from './edit-field-precision-date/edit-field-precision-date.component';
import {EditFieldPrecisionDateRangeComponent} from './edit-field-precision-date-range/edit-field-precision-date-range.component';
import {CmsQueueService} from '../core/cms-queue.service';
import { ObjectEditComponent } from './object-edit/object-edit.component';
import { EditFieldImageSelectorComponent } from './edit-field-image-selector/edit-field-image-selector.component';
import { EditFieldDatePickerComponent } from './edit-field-date-picker/edit-field-date-picker.component';
import { EditFieldDateHistoricComponent } from './edit-field-date-historic/edit-field-date-historic.component';
import { EditFieldObjectReferenceComponent } from './edit-field-object-reference/edit-field-object-reference.component';
import {OverviewFieldsModule} from '../overview-fields/overview-fields.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import { EditFieldSpecificArrayElementComponent } from './edit-field-specific-array-element/edit-field-specific-array-element.component';
// tslint:disable-next-line:max-line-length
import { EditFieldSelectHierarchyNodeComponent } from './edit-field-select/edit-field-select-hierarchy-node/edit-field-select-hierarchy-node.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    MatDatepickerModule,
    MatMomentDateModule,
    DragDropModule,
    OverviewFieldsModule
  ],
  exports: [
    EditFieldSwitchComponent,
    EditPrimeFieldsComponent,
    ObjectEditComponent
  ],
  declarations: [
    ConfirmDialogComponent,
    EditFieldComponent,
    EditFieldInlineArrayAddButtonComponent,
    EditFieldInlineArrayComponent,
    EditFieldInlineArrayItemTopBarComponent,
    EditFieldInlineArrayDeleteButtonComponent,
    EditFieldsComponent,
    EditFieldSelectComponent,
    EditFieldSelectOptionsComponent,
    EditFieldSelectSelectedOptionsComponent,
    EditObjectDialogComponent,
    SectionViewEditComponent,
    SectionViewTitleEditComponent,
    EditFieldIdentifierComponent,
    EditFieldInlineArrayFieldsComponent,
    EditFieldSwitchComponent,
    EditFieldCompareComponent,
    EditFieldSearchSelectorComponent,
    EditFieldSearchSelectorMultipleComponent,
    CopyKeepCheckboxComponent,
    EditFieldInlineArrayItemComponent,
    EditFieldSelectHierarchyComponent,
    EditFieldSelectChildOptionsComponent,
    EditFieldSelectHierarchyNodesComponent,
    EditPrimeFieldsComponent,
    FolderEditDialogComponent,
    FolderAppendDialogComponent,
    EditFieldCheckArrayComponent,
    EditFieldPrecisionDateComponent,
    EditFieldPrecisionDateRangeComponent,
    ObjectEditComponent,
    EditFieldImageSelectorComponent,
    EditFieldDatePickerComponent,
    EditFieldDateHistoricComponent,
    EditFieldObjectReferenceComponent,
    EditFieldSpecificArrayElementComponent,
    EditFieldSelectHierarchyNodeComponent,
  ],
  providers: [
    CopyKeepService
  ]
})
export class ObjectEditModule {
  constructor(private translate: TranslateService,
              private cmsQueueService: CmsQueueService) {
    cmsQueueService.runCmsFnWithQueue('getSettings').then(data => {
      translate.use(data[AConst.CLIENT_LANGUAGE]);
    });
  }
}
