import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UIRouterModule} from '@uirouter/angular';
import {MatDialogModule} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {DragulaModule} from 'ng2-dragula';

import {ImageCarouselComponent} from './image-carousel/image-carousel.component';
import {ObjectViewModule} from '../object-view/object-view.module';
import {ImageZoomComponent} from './image-zoom/image-zoom.component';
import {ActiveImageComponent} from './active-image/active-image.component';
import {ActiveAnnotateImageComponent} from './active-annotate-image/active-annotate-image.component';
import {AConst} from '../core/a-const.enum';
import {SharedModule} from '../shared/shared.module';
import {ChangeImageOrderComponent} from './change-image-order/change-image-order.component';
import {CmsQueueService} from '../core/cms-queue.service';
import {MediaModule} from '../media/media.module';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    SharedModule,
    DragulaModule,
    UIRouterModule,
    ObjectViewModule,
    MediaModule
  ],
  exports: [
    ImageZoomComponent,
    ActiveImageComponent,
    ImageCarouselComponent
  ],
  declarations: [
    ImageCarouselComponent,
    ImageZoomComponent,
    ActiveImageComponent,
    ActiveAnnotateImageComponent,
    ChangeImageOrderComponent
  ]
})
export class MediaCarouselModule {
  constructor(private translate: TranslateService,
              private cmsQueueService: CmsQueueService) {
    cmsQueueService.runCmsFnWithQueue('getSettings').then(data => {
      translate.use(data[AConst.CLIENT_LANGUAGE]);
    });
  }
}
