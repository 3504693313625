import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {group, query, stagger, style, transition, trigger, useAnimation} from '@angular/animations';
import {StateService} from '@uirouter/core';

import {SearchContainer} from '../../search-container';
import {UiToolsService} from '../../../core/ui-tools.service';
import {AConst} from '../../../core/a-const.enum';
import {FieldParameters} from '../../../core/field-parameters';
import {FieldConditionService} from '../../../core/field-condition.service';
import {FolderEditDialogComponent} from '../../../object-edit/folder-edit-dialog/folder-edit-dialog.component';
import {FolderAppendDialogComponent} from '../../../object-edit/folder-append-dialog/folder-append-dialog.component';
import {FolderService} from '../../../core/folder.service';
import {SearchHandlerService} from '../../search-handler.service';
import {ReportMenuService} from '../../../report/report-menu/report-menu.service';
import {ProgressDialogComponent} from '../../../shared/progress-dialog/progress-dialog.component';
import {ReporterService} from '../../../report/reporter.service';
import {fadeIn, fadeOut, slideDownMenu, slideUpMenu} from '../../../shared/animations';
import {OverviewField} from '../../../core/object-view';

@Component({
  selector: 'app-search-menu-actions',
  templateUrl: './search-menu-actions.component.html',
  styleUrls: ['./search-menu-actions.component.scss'],
  animations: [
    trigger('slideInSearchMenuActions', [
      transition(':leave', [
        group([
          useAnimation(slideUpMenu, {params: {time: '300ms ease-in-out'}}),
          query('li', [
            style({opacity: 1}),
            stagger(-23, useAnimation(fadeOut, {params: {time: '10ms'}}))
          ]),
        ])
      ]),
      transition(':enter', [
        group([
          useAnimation(slideDownMenu, {params: {time: '300ms ease'}}),
          query('li', [
            style({opacity: 0}),
            stagger(23, useAnimation(fadeIn, {params: {time: '10ms'}}))
          ]),
        ])
      ]),
    ])
  ]
})
export class SearchMenuActionsComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;
  @Input() actionMenus;
  @Input() reportContainer;

  AConst = AConst;

  private clickListenerId;

  constructor(private uiTools: UiToolsService,
              private conditionHandler: FieldConditionService,
              private modalService: MatDialog,
              private folderService: FolderService,
              private searchHandler: SearchHandlerService,
              private reportMenuService: ReportMenuService,
              private reporter: ReporterService,
              private stateService: StateService) {
  }

  ngOnInit() {
    this.clickListenerId = this.uiTools.addDocumentClickListener(() => {
      this.closeMenu();
    });
  }

  ngOnDestroy(): void {
    if (this.clickListenerId) {
      this.uiTools.removeDocumentClickListener(this.clickListenerId);
    }
  }

  toggleSearchActionMenu() {
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    this.searchContainer.toggleActionMenu = !this.searchContainer.toggleActionMenu;
    this.searchContainer.toggleSortMenu = false;
    this.searchContainer.toggleView = false;
  }

  checkShowIf(menu) {
    let res = true, obj;
    const ifs = menu[AConst.FIELD_IFS];
    if (ifs) {
      obj = this.searchContainer.targetObject;
      const fieldParameters = new FieldParameters();
      fieldParameters.field = {field_ifs: ifs};
      fieldParameters.rootObject = obj;
      res = this.conditionHandler.runIf('show', fieldParameters);
    }
    return res;
  }

  handleMenuAction(menu) {
    let progressModal;
    this.closeMenu();
    if (menu[AConst.MENU_TYPE] === 'report_menu') {
      this.reportContainer = menu;
    }
    setTimeout(() => {
      switch (menu[AConst.MENU_TYPE]) {
        case 'report_menu':
          this.openReportMenu(menu);
          break;
        case 'report_export_excel_menu':
          const rType = 'excel';

          if (this.searchContainer.searchResultViewName === 'list') {
            progressModal = this.modalService.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
            this.reporter.init(this, this.searchHandler, this.getOverviewFields(), rType, true).then(function () {
              progressModal.close();
            });
          } else {
            this.searchHandler.setCurrentResultView('list', this.searchContainer);
            this.searchHandler.runSearch(this.searchContainer, true).then(() => {
              setTimeout(() => {
                progressModal = this.modalService.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
                this.reporter.init(this, this.searchHandler, this.getOverviewFields(), rType, true).then(() => {
                  progressModal.close();
                });
              }, 200);
            });
          }
          break;
        case 'report_export_pdf_3x2grid_menu':
          console.log('Exporting to 3x2 grid PDF.');
          progressModal = this.modalService.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
          this.reporter.init(this, this.searchHandler, this.getOverviewFields(), 'pdf3x2grid', false).then(() => {
            progressModal.close();
          });
          break;
        case 'report_export_pdf_2x2grid_menu':
          console.log('Exporting to 2x2 grid PDF.');
          progressModal = this.modalService.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
          this.reporter.init(this, this.searchHandler, this.getOverviewFields(), 'pdf2x2grid', false).then(() => {
            progressModal.close();
          });
          break;
        case 'report_export_pdf_image_to_the_left_table':
          console.log('Exporting to image to the left table PDF.');
          progressModal = this.modalService.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
          this.reporter.init(this, this.searchHandler, this.getOverviewFields(), 'imageToTheLeftTable', false).then(() => {
            progressModal.close();
          });
          break;
        case 'report_export_pdf_full_object_menu':
          progressModal = this.modalService.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
          console.log('Exporting full object PDF.');
          this.reporter.init(this, this.searchHandler, this.getOverviewFields(), 'pdffullobject', false).then(() => {
            progressModal.close();
          });
          break;
        case 'add_concept':
          this.stateService.go('home.primus.artifactNew', {
            artifactId: 'new',
            objectType: menu[AConst.SUB_TYPE_ID]
          });
          break;
        case 'folder_menu':
          if (this.searchContainer.allSelected) {
            this.searchHandler.getAllArtifactsFromSearch(this.searchContainer).then(artifact_ids => {
              this.handleFolderAction(menu[AConst.SUB_TYPE], artifact_ids);
            });
          } else {
            this.handleFolderAction(menu[AConst.SUB_TYPE], this.searchContainer.selected);
          }
          break;
        default:
          console.warn('Unknown menu type',
            menu[AConst.MENU_TYPE]);
      }
    }, 100);
  }

  get selectedCount() {
    return this.searchContainer.selected ? this.searchContainer.selected.length : 0;
  }

  get actionSectionMenuCount() {
    return this.actionMenus && this.actionMenus[AConst.SECTION_MENUS] ? this.actionMenus[AConst.SECTION_MENUS].length : 0;
  }

  private closeMenu() {
    this.searchContainer.toggleActionMenu = false;
  }

  private setReportType(reportContainer) {
    const filters = this.searchContainer.currentPathView.filters;
    reportContainer.objectType = filters.object_type;
    reportContainer.metaType = filters.meta_type;
  }

  private getOverviewFields() {
    const overviewFields = {};
    this.searchContainer.searchResult['artifacts'].forEach(searchObject => {
      if (this.searchContainer.allSelected || this.searchContainer.selected.indexOf(searchObject[AConst.ARTIFACT_ID]) !== -1) {
        overviewFields[searchObject[AConst.ARTIFACT_ID]] = this.convertOverviewFieldsToOldFormat(searchObject.$$overview);
      }
    });
    return overviewFields;
  }

  // TODO:
  // Converting server generated overview fields to an old format that is currently supported by the report service. But the report
  // service should be rewritten in order to support the new format, and use the overview field class definitions.
  private convertOverviewFieldsToOldFormat(overviewFields: Array<OverviewField>) {
    overviewFields.forEach(overviewField => {
      overviewField['name'] = overviewField.field_name;
      overviewField['field_title'] = overviewField.title;
      overviewField['value'] = [];
      overviewField['field_type'] = overviewField.field_type;
      overviewField['class'] = overviewField.field_class;
      overviewField['isLink'] = overviewField.is_link;
      overviewField.items.forEach(item => {
        let value = '';
        item.field_values.values.forEach(fieldValue => {
          value += fieldValue.value;
        });
        overviewField['value'].push(value);
      });
    });
    return overviewFields;
  }

  private handleFolderAction(type, artifactIds) {
    let modalRef;
    switch (type) {
      case 'add_to_new_folder':
        modalRef = this.modalService.open(FolderEditDialogComponent, {panelClass: 'edit-dialog'});
        modalRef.componentInstance.objectIds = artifactIds;
        modalRef.afterClosed().subscribe(folder => {
          if (folder) {
            this.folderService.goToFolder(folder[AConst.ARTIFACT_ID]);
          }
        });
        break;
      case 'add_to_other_folder':
      case 'add_to_existing_folder':
      case 'add_to_folder':
        modalRef = this.modalService.open(FolderAppendDialogComponent, {panelClass: 'edit-dialog'});
        modalRef.componentInstance.objectIds = artifactIds;
        modalRef.afterClosed().subscribe(folder => {
          if (folder) {
            this.folderService.goToFolder(folder[AConst.ARTIFACT_ID]);
          }
        });
        break;
      case 'delete_from_folder':
        this.folderService.removeFolderItems(this.searchContainer.targetObject, artifactIds).then(
          () => {
            this.searchHandler.runSearch(this.searchContainer).then(() => {
              // No actions
            });
          },
          () => {
            console.log('Either user canceled or something went wrong');
          });

        break;
      default:
        console.warn('Unknown menu sub type', type);
    }
  }

  private scrollToTop(delay) {
    setTimeout(() => {
      const scrollElement = document.getElementById('searchResult-main-content');
      const scrollTop = scrollElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({top: scrollTop - 350, behavior: 'smooth'});
    }, delay);
  }

  private openReportMenu(menu) {
    this.reportContainer = this.createSearchReportContainer(this.searchContainer, menu);
    this.searchContainer.reportContainer = this.reportContainer;

    this.reportContainer.show = true;
  }

  private closeReportMenu() {
    if (this.reportContainer && this.reportContainer.show) {
      this.reportContainer.show = false;
      this.scrollToTop(0);
    }
  }

  private createSearchReportContainer(searchContainer, menu) {
    const res = {
      menu: menu,
      actions: [
        {
          containerClass: 'register-button-container',
          buttonClass: 'register-button',
          type: 'register',
          fn: () => {
            this.reportMenuService.getMakeSearchReportFn(searchContainer);
            this.closeReportMenu();
          },
          buttonText: 'TRANS__REPORT_MENU__MAKE_REPORT_BUTTON',
          helperBoxText: 'TRANS__VALIDATION__NO_SELECTED_OBJECT'
        },
        {
          buttonClass: 'cancel-button',
          fn: () => {
            this.closeReportMenu();
          },
          buttonText: 'TRANS__REPORT_MENU__CANCEL_REPORT'
        }
      ],
      type: menu[AConst.SUB_TYPE],
      isSearchView: ['admin-event-search', 'simple',
        'search-view-all'].indexOf(
        menu[AConst.SUB_TYPE]) !== -1
    };
    this.setReportType(res);
    return res;
  }

}
