import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ContentInfo} from '../../core/content-info.service';
import {UiToolsService} from '../../core/ui-tools.service';

export class SectionMenuContainer {
  showSectionMenu = false;
  artifact = null;
  sections = [];
  contentInfo: ContentInfo = null;
  currentSectionInViewPort: string;
}

@Component({
  selector: 'app-section-menu',
  templateUrl: './section-menu.component.html',
  styleUrls: ['./section-menu.component.scss']
})
export class SectionMenuComponent implements OnInit, OnDestroy {

  @Input() sectionMenuContainer: SectionMenuContainer;

  private mediumScreen;
  private stopSizeWatch;

  constructor(private uiTools: UiToolsService) {
  }

  ngOnInit() {
    this.mediumScreen = this.uiTools.setMediumScreenSizeOnLoad(this.mediumScreen);
    this.stopSizeWatch = this.uiTools.addWindowSizeListener(newVal => {
        this.mediumScreen = newVal.width < 1025;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
  }

  onClick(sectionName) {
    const section = <HTMLElement>document.querySelector('#section-' + sectionName);
    if (section) {
      // TODO: Smooth parameter not supported by Edge (and Safari?)
      window.scrollTo({
        top: section.offsetTop - 100,
        behavior: 'smooth'
      });
    }
  }

  get showSectionsMenu() {
    return !this.mediumScreen &&
      this.sectionMenuContainer.showSectionMenu &&
      this.sectionMenuContainer.contentInfo &&
      this.sectionMenuContainer.contentInfo.curListName === 'overview';
  }
}
