import {Component, Input, OnInit} from '@angular/core';
import {UiToolsService} from '../../core/ui-tools.service';
import {UploadService} from '../../core/upload.service';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-edit-field-image-selector',
  templateUrl: './edit-field-image-selector.component.html',
  styleUrls: ['./edit-field-image-selector.component.scss']
})
export class EditFieldImageSelectorComponent implements OnInit {
  @Input() field;
  @Input() object;

  computer = true;
  container = {};

  constructor(private uiTools: UiToolsService, private uploadService: UploadService) {
  }

  ngOnInit() {
    this.computer = this.uiTools.getClientType().isComputer;
  }

  deleteImage() {
    this.object[this.field.name].value = null;
  }

  // At the drag drop area
  // (drop)="onDropFile($event)"
  onDropFile(event) {
    event.preventDefault();
    this.uploadFiles(event.dataTransfer.files);
  }

  // At the drag drop area
  // (dragover)="onDragOverFile($event)"
  onDragOverFile(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  // At the file input element
  // (change)="selectFile($event)"
  onFileSelect(event) {
    this.uploadFiles(event.target.files);
  }

  private uploadFiles(files) {
    this.object[this.field.name].value = null;
    this.uploadService.uploadFiles(this.container, files, 'image').then(res => {
      this.object[this.field.name].value = res[0][AConst.ARTIFACT_ID];
    });
  }
}
