import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';

import {Focus} from '../../search-focus.service';

@Component({
  selector: 'app-search-focus-edit-dialog',
  templateUrl: './search-focus-edit-dialog.component.html',
  styleUrls: ['./search-focus-edit-dialog.component.scss']
})
export class SearchFocusEditDialogComponent implements OnInit {

  @Input() fsi: Focus;
  @Input() focusNameQuery: string;
  @Input() originalFocus: boolean;
  @Input() focusInEdit: any;

  validateError = null;

  constructor(public dialogRef: MatDialogRef<SearchFocusEditDialogComponent>) {
  }

  ngOnInit() {
  }

  clearNameInput() {
    this.focusNameQuery = '';
  }

  validateCurrentAndAddNewName() {
    let res = false, t, f;
    const query = this.focusNameQuery;
    if (query !== undefined && query !== '') {
      const focuses = this.getFocuses();
      for (t = 0; t < focuses.length; t++) {
        f = focuses[t];
        if (this.focusInEdit.name !== query.trim() && f.name.trim() === query.trim()) {
          this.validateError = 'TRANS__FOCUS__EDIT__VALIDATE__NAME_EXISTS';
          res = true;
          break;
        }
      }
      if (this.focusInEdit.name === query.trim() &&
        this.originalFocus === this.focusInEdit.isDefault) {
        res = true;
        this.validateError = null;
      }
    } else {
      this.validateError = 'TRANS__FOCUS__EDIT__VALIDATE__NO_NAME';
      res = true;
    }
    return res;
  }

  toggleIsDefault(fo) {
    this.fsi.toggleFocusIsDefault(fo);
  }

  saveFocus() {
    this.dialogRef.close(this.focusNameQuery);
  }

  private getFocuses() {
    return this.fsi.getFocusesAsArray();
  }
}
