class ItemMappingSourceField {
  source_field: string;
  source_type: string;
}

class ItemMapping {
  separator: string;
  source_fields: Array<ItemMappingSourceField>;
  target_field: string;
}

export class UploadInfo {
  file_accept: string;
  file_type: string;
  from_camera: boolean;
  item_mapping: Array<ItemMapping>;
  item_model: string;
  multiple_files: boolean;
  object_type: string;
  parent_to_item_fields: Array<any>;
  select_from_parent: boolean;
  target_array: string;
  text_from_camera: string;
  text_select_from_parent: string;
  text_upload: string;
  text_upload_single: string;
  text_upload_single_tablet: string;
  text_upload_tablet: string;
}
