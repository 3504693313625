import {
  Component,
  ElementRef,
  Input,
  OnChanges, OnDestroy, OnInit,
  ViewChild
} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {FormControl} from '@angular/forms';
import {FieldConditionService} from '../../core/field-condition.service';
import {FieldStateService} from '../../core/field-state.service';
import {SectionsContainer} from '../../core/sections-container';
import {FieldValidationService} from '../../core/field-validation.service';
import {CommonsService} from '../../core/commons.service';
import {FieldValueService} from '../../core/field-value.service';
import {EditFieldSelectComponent} from '../edit-field-select/edit-field-select.component';
import {FieldParameters} from '../../core/field-parameters';
import {UiToolsService} from '../../core/ui-tools.service';

@Component({
  selector: 'app-edit-field',
  templateUrl: './edit-field.component.html',
  styleUrls: ['./edit-field.component.scss']
})
export class EditFieldComponent implements OnChanges, OnInit, OnDestroy {
  AConst = AConst;

  fieldParameters: FieldParameters;
  isGenerallyEditable: boolean;
  inFocus = false;
  placeholder;
  fieldKey;
  userData;
  generalFieldType;
  isEditable = true;
  toDateControlName: string;
  toDateField;
  stopSizeWatch;
  mediumScreen;

  @Input() sectionsContainer: SectionsContainer;
  @Input() object;
  @Input() grandParentObject;
  @Input() field;
  @Input() index: number;
  @Input() parentIndex: number;
  @ViewChild('textarea', { static: true }) textarea: ElementRef;
  @ViewChild(EditFieldSelectComponent, { static: true }) editFieldSelect;

  constructor(private fieldState: FieldStateService,
              private fieldValueSvc: FieldValueService,
              private fieldCondition: FieldConditionService,
              private commons: CommonsService,
              public fieldValidation: FieldValidationService,
              private uiTools: UiToolsService) {

  }
  ngOnInit() {
    this.mediumScreen = this.uiTools.setMediumScreenSizeOnLoad(this.mediumScreen);
    this.stopSizeWatch = this.uiTools.addWindowSizeListener(
      (newVal) => {
        this.mediumScreen = newVal.width < 1025;
      }
    );
  }

  ngOnChanges() {
    this.commons.getUserData(false).then(userData => {
      this.userData = userData;
      this.isGenerallyEditable = this.fieldState.isEditable(this.sectionsContainer.rootObject, this.object, this.field, userData);
      this.generalFieldType = this.fieldState.getFieldInputType(
        this.field, this.sectionsContainer.rootObject, this.object, userData, this.sectionsContainer.isCopy);
      this.setToDateInfo();
    });

    this.fieldKey = this.fieldState.getFieldKeyWhileDrawingInputs(this.field, this.index, this.parentIndex);
    this.fieldParameters = this.makeFieldParameters();
    this.placeholder = this.fieldState.getFieldTitle(this.field, this.sectionsContainer.rootObject);
    setTimeout(() => {
      this.autoExpand();
    }, 10);
  }

  autoExpand() {
    if (this.textarea) {
      this.textarea.nativeElement.style.height = 'auto';
      this.textarea.nativeElement.style.height = this.textarea.nativeElement.scrollHeight + 'px';
    }
  }

  get isFieldInvalid() {
    let res = this.fieldValidation.isFieldInvalid(this.sectionsContainer, this.fieldKey);
    if (!res && this.toDateControlName) {
      res = this.fieldValidation.isFieldInvalid(this.sectionsContainer, this.toDateControlName);
    }
    return res;
  }

  get invalidTypeErrors() {
    let res = {};
    const field = this.getFormControlField();
    if (field) {
      res = field.errors;
      if (!res && this.toDateControlName) {
        res = this.sectionsContainer.formGroup.controls[this.toDateControlName].errors;
      }
    }
    return res || {};
  }

  get fieldInputType() {
    let res = this.generalFieldType;
    this.isEditable = this.isGenerallyEditable && this.fieldCondition.runIf('edit', this.fieldParameters);
    if (res !== 'display' && !this.isEditable) {
      res = 'display';
    }
    return res;
  }

  get fieldTextValue() {
    return this.fieldValueSvc.getFieldTextValue(this.sectionsContainer.rootObject, this.fieldKey, true);
  }

  onFieldBlur() {
    this.inFocus = false;
  }

  onFieldFocus() {
    if (!this.sectionsContainer.isDialog && !this.mediumScreen) {
      this.inFocus = true;
    }
  }

  toggleShowOptions() {
    if (this.fieldInputType === 'map_id' && this.editFieldSelect) {
      this.editFieldSelect.toggleShowOptions(false);
      this.onFieldBlur();
    }
  }

  onValueChange($event) {
    let value = $event.target.value;
    $event.stopPropagation();
    if (this.field[AConst.FIELD_TYPE] === AConst.BOOLEAN) {
      value = !this.object[this.field.name];
      this.object[this.field.name] = value;
      const formField = this.getFormControlField();
      formField.setValue(value);
      formField.markAsDirty();
      console.log('Value for ' + this.field.name + ' set to ' + value);
    } else if (this.field[AConst.INPUT_TYPE] === 'radio') {
      this.object[this.field.name] = value;
    } else if (this.field[AConst.INPUT_TYPE] === AConst.NUMBER && value !== '' && value !== null) {
      this.object[this.field.name] = Number(value);
    } else {
      this.object[this.field.name] = value;
    }
  }

  checkValueWithKey(evt) {
    evt = evt || window.event;
    switch (evt.key) {
      case 'Enter':
      case ' ': // Space
        this.onValueChange(evt);
        evt.preventDefault();
        break;
    }
  }

  getFormControlField(): FormControl {
    const res = <FormControl>this.sectionsContainer.formGroup.controls[this.fieldKey];
    if (!res) {
      console.warn('No form control for key: ' + this.fieldKey);
    }
    return res;
  }

  private makeFieldParameters() {
    const fieldParameters = new FieldParameters();
    fieldParameters.sectionsContainer = this.sectionsContainer;
    fieldParameters.grandParentObject = this.grandParentObject;
    fieldParameters.rootObject = this.sectionsContainer.rootObject || this.object;
    fieldParameters.object = this.object;
    fieldParameters.field = this.field;
    fieldParameters.edit = true;
    fieldParameters.index = this.index;
    fieldParameters.parentIndex = this.parentIndex;
    return fieldParameters;
  }

  private setToDateInfo() {
    if (this.fieldInputType === AConst.PRECISION_DATE_RANGE) {
      let parentName = '';
      const lastDot = this.fieldKey.lastIndexOf('.');
      if (lastDot !== -1) {
        parentName = this.fieldKey.substring(0, lastDot) + '.';
      }
      const toDateName = this.field[AConst.INLINE][AConst.TO_DATE_FIELD];
      this.toDateField = this.object[AConst.$$META][toDateName];
      this.toDateControlName = parentName + toDateName;
    }
  }
  ngOnDestroy() {
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
  }

}
