import {Component, OnInit} from '@angular/core';
import {StateService, UIRouterGlobals} from '@uirouter/core';

import {AConst} from '../../core/a-const.enum';
import {CmsApiService} from '../../core/cms-api.service';
import {ModelObjectService} from '../../core/model-object.service';
import {ObjectEditService} from '../../core/object-edit.service';
import {FieldValueService} from '../../core/field-value.service';
import {FieldActionService} from '../../shared/field-action.service';
import {CommonsService} from '../../core/commons.service';

@Component({
  selector: 'app-admin-template-model',
  templateUrl: './admin-template-model.component.html',
  styleUrls: ['./admin-template-model.component.scss']
})
export class AdminTemplateModelComponent implements OnInit {

  AConst = AConst;
  sectionsContainers = {};
  parent;
  templateModel = null;
  debug;
  createDisabled: boolean;
  templateModels;
  actionButtons = [];
  templateGroups;
  templateGroupName;
  templateGroupId;

  private adminType;
  private curStateName = 'home.primus.admin.templateModel';

  constructor(private stateService: StateService,
              private uiRouterGlobals: UIRouterGlobals,
              private cms: CmsApiService,
              private modelObjectService: ModelObjectService,
              private objectEditService: ObjectEditService,
              private fieldValueService: FieldValueService,
              private fieldActionService: FieldActionService,
              private commons: CommonsService) {
  }

  ngOnInit() {
    this.adminType = this.uiRouterGlobals.params['admin_type'];
    this.parent = {
      path: 'home.primus.admin.templateGroup',
      adminType: this.adminType,
      subMenus: true,
      target_params: {admin_type: this.adminType},
      currentState: this.curStateName
    };

    this.templateGroupId = this.uiRouterGlobals.params['template_group_id'];
    const modelTypeId = this.uiRouterGlobals.params['model_type_id'];
    this.debug = this.uiRouterGlobals.params['debug'];
    this.createDisabled = true;
    this.getGroupName(this.templateGroupId);
    this.cms.getMetaObject({
      objectType: 'TemplateModels',
      template_group_id: this.templateGroupId,
      model_type_id: modelTypeId
    }).then(data => {
      if (data) {
        this.objectEditService.createModelItem('TemplateModels', data).then((templateModels) => {
          templateModels[AConst.TEMPLATE_MODELS].forEach(item => {
            this.setModelHelperProps(item);
          });
          templateModels[AConst.TEMPLATE_MODELS] = this.commons.orderArray(
            templateModels[AConst.TEMPLATE_MODELS], AConst.ORDER_NUMBER, true);
          this.templateModels = templateModels;
          this.createDisabled = false;
          if (modelTypeId) {
            this.setTemplateModel(modelTypeId, this.templateGroupId);
          }
        });
      }
    });
  }

  disableTemplateModel() {
    this.stateService.go('home.primus.admin.templateModel', {
      objectType: this.adminType,
      template_group_id: this.templateGroupId,
      model_type_id: null
    });
  }

  addTemplateModel() {
    const field = this.templateModels[AConst.$$META][AConst.TEMPLATE_MODELS];
    const newModel = this.fieldValueService.createAddItem(this.templateModels, field, {
      template_group_id: this.uiRouterGlobals.params['template_group_id']
    });
    newModel['$$isEditing'] = true;
    this.setModelHelperProps(newModel);
    this.createDisabled = true;
  }

  getGroupName(templateGroupId) {
    this.cms.getMetaObject({
      objectType: 'TemplateGroups'
    }).then(data => {
      if (data) {
        this.objectEditService.createModelItem('TemplateGroups', data).then((item) => {
          this.templateGroups = item;
          item[AConst.TEMPLATE_GROUPS].forEach(group => {
            if (group.template_group_id === templateGroupId) {
              this.templateGroupName = group[AConst.GROUP_NAME];
            }
          });
        });
      }
    });
  }

  getModelIsEditing(model) {
    let res;
    if (model.$$isEditing) {
      res = true;
    } else {
      // Model object must be set to editable if not stored in database, or else the object view will fail
      res = !this.sectionsContainers[model['$$uuid']]['rootObject']['artifact_id'];
    }
    return res;
  }

  private setTemplateModel(modelTypeId, templateGroupId) {
    this.createDisabled = true;
    this.cms.getMetaObject({
      objectType: 'TemplateModel',
      template_group_id: templateGroupId,
      model_type_id: modelTypeId
    }).then(data => {
      this.getModelName(modelTypeId).then(modelName => {
        this.objectEditService.createModelItem('template_model_' + modelName,
          data).then(
          (item) => {
            this.setFormSections(item, true);
            this.templateModel = item;
            this.templateModel.name = modelName;
            this.actionButtons = this.getActionButtons();
          }
        );

      });
    });
  }

  // Get model name using concept search
  private getModelName(modelTypeId) {
    return new Promise(resolve => {
      this.cms.searchJson({
        filters: {
          artifact_id: modelTypeId
        }
      }).then(
        function (search) {
          let modelName;
          if (search[AConst.ARTIFACTS]) {
            modelName = search[AConst.ARTIFACTS][0].description;
            resolve(modelName);
          } else {
            throw new Error('Model name not found');
          }
        },
        function () {
          throw new Error('Unable to get model name');
        });
    });
  }

  private getActionButtons() {
    let res = [];
    if (this.templateModel) {
      res = this.fieldActionService.getActionButtonsFromModel(this.templateModel, 'header');
    }
    return res;
  }

  private setModelHelperProps(model) {
    this.setFormSections(model, false, 'admin');
  }

  private setFormSections(item, isTemplate: boolean, viewMode?: string) {
    this.modelObjectService.getSectionsAndFormGroup(item).then(sc => {
      sc.hideSectionTitle = true;
      sc.isTemplate = isTemplate;
      sc.viewMode = viewMode;
      item.$$uuid = this.commons.uuid();
      this.sectionsContainers[item.$$uuid] = sc;
    });
  }

}
