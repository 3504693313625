import { Injectable } from '@angular/core';
import {ReporterPdfConfigHelperService} from './reporter-pdf-config-helper.service';
import {ReporterPdfFullObjectFrontPageService} from './reporter-pdf-full-object-front-page.service';
import {ReporterUtilityService} from './reporter-utility.service';
import {ReporterPdfImageConverterService} from './reporter-pdf-image-converter.service';
import {ReporterTranslationService} from './reporter-translation.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Service hosting methods used when parsing an artifact containing all data.
 * @type {{parse, parseAll, getArtifactName}}
 */
export class ReporterPdfFullArtifactParserService {
  private parsed = [];

  private artifact = null;
  private frontPageTexts = null;
  private overviewFieldsDataSet = null;

  constructor(private configHelper: ReporterPdfConfigHelperService,
              private fullObjectFrontPage: ReporterPdfFullObjectFrontPageService,
              private reporterUtility: ReporterUtilityService,
              private reporterPdfImageConverter: ReporterPdfImageConverterService,
              private reporterTranslation: ReporterTranslationService
              ) { }

  /**
   * Creates the pdfMake doc. structure.
   * @returns {{}}
   * @private
   */
  private createContent(artifact, username, frontPage, overviewFields, sections, images, createdHr) {
    const subject = artifact.object_type;
    const artifactName = artifact.artifact_name;
    const keywords = [
      artifact.meta_type,
      subject, artifactName,
      artifact.artifact_id].join(',');

    const primusVersion = this.reporterUtility.getPrimusVersion();
    const doc = this.configHelper.getFullReportDocDef(primusVersion,
      artifactName, username, subject, keywords, this.frontPageTexts, createdHr);

    doc.content = [];

    // Front page
    doc.content.push(frontPage);

    // Add overview fields
    if (overviewFields !== null) {
      doc.content.push(overviewFields);
    }

    // Add sections
    if (sections !== null) {
      doc.content.push(sections);
    }

    // Add images
    if (images) {
      const imageGrid = this.createImageGrid(images);
      if (imageGrid) {
        doc.content.push(imageGrid);
      }
    }
    return doc;
  }

  /**
   * Downloads all artifact images as base64 encoded strings.
   * @returns {*}
   * @private
   */
  private parseImages(imageIDs) {
    return new Promise((resolve) => {
      if (typeof(imageIDs) === 'undefined' || imageIDs === null) {
        resolve();
      }

      this.reporterPdfImageConverter.convertMultiple(imageIDs, true).then((res) => {
        resolve(res);
      });
    });
  }

  /**
   * Creates a two-column grid of all the images.
   * @returns {*[]}
   * @private
   */
  private createImageGrid(images) {
    if (images.length === 1) {
      return;
    }

    const rows = [];
    let cols = [];

    images.map(function (img, i) {
      if (i > 0) {
        const max = images.length - 1;
        cols.push({image: img, width: 250});

        if (i % 2 === 0 || i === max) {
          if (cols.length < 2 && i === max) {
            cols.push({});
          }

          // Add row every 2nd column
          rows.push(cols);
          cols = [];
        }
      }
    });

    if (rows.length > 0) {
      const colouredSectionHeader = this.reporterUtility.createColouredSectionHeader(
        this.reporterTranslation.getString('TRANS__REPORTER_PDF__ARTIFACTPARSER_BILDER')
      );

      const colouredTable = ReporterPdfFullArtifactParserService.create2ColTable(
        rows, [250, 250], false);

      return [colouredSectionHeader, colouredTable];
    } else {
      return null;
    }
  }

  /**
   * Creates a two-column table, where the first column has width = auto and the last *.
   * @param rows
   * @param widths
   * @param borders
   * @returns {{table: {headerRows: number, widths: string[], body: *}}}
   * @private
   */
  private static create2ColTable(rows, widths, borders) {
    const table = {
      widths: widths,
      body: rows
    };


    let obj = null;

    if (!borders) {
      obj = {
        table: table,
        layout: {
          vLineWidth: function () { return 0; },
          hLineWidth: function () { return 0; }
        }
      };
    } else {
      obj = {
        table: table,
        layout: {
          hLineWidth: function (i) { return 1; },
          vLineWidth: function (i) { return 0; },
          hLineColor: function (i) { return '#7e97ad'; }
        }
      };
    }

    return obj;
  }

  /**
   * Constructs a table containing the overview fields values.
   * @param artifactName
   * @returns {*[]}
   * @private
   */
  private parseOverviewFields(artifactName) {
    const overviewFields = this.overviewFieldsDataSet[Object.keys(this.overviewFieldsDataSet)[0]];

    const rows = [];
    overviewFields.map((f) => {

      let fldValue = null;
      if (f.field_type === 'array' && Array.isArray(f.value)) {
        const values = f.value.map(function (o) {
          if (typeof(o.artifact_name) !== 'undefined') {
            return o.artifact_name;
          }
        });

        fldValue = values.join(' ');

      } else {
        fldValue = f.value;
      }

      let fldName = this.reporterTranslation.getString(f.field_title);

      /*
         PRIMUS-1030:
         Workaround that uses the admin-title instead of the title, when displaying date-/timespan fields.
        */
      if (f.class === 'precision-date-field') {
        fldName = this.reporterTranslation.getString(f.admin_title).toUpperCase();
      } else {
        fldName = fldName.toUpperCase();
      }

      rows.push([
        {
          text: fldName,
          style: 'sectionText',
          color: '#7e97ad'
        },
        {text: fldValue, style: 'sectionText'}
      ]);
    });

    return [
      this.reporterUtility.createColouredSectionHeader(artifactName),
      ReporterPdfFullArtifactParserService.create2ColTable(rows, ['40%', '*'], true)
    ];
  }

  /**
   * Parses an artifact inline-array values to a two-column table.
   * @param arr
   * @returns {*[]}
   * @private
   */
  private parseInlineArray(arr) {
    const headers = [];
    arr.map(function (o) {
      headers.push({text: o.fields_header, style: 'sectionText'});
    });

    return headers;
  }

  /**
   * Parses an artifact section
   * @param section
   * @returns {*}
   * @private
   */
  private parseSection(section) {
    const rows = [];

    if (section.fields !== null && section.fields.length > 0) {
      section.fields.map((fld) => {

        const cols = [];

        cols.push({
          text: ((fld.title !== null && fld.title !== 'undefined'))
            ? fld.title.toUpperCase() : '',
          color: '#7e97ad',
          style: 'sectionText'
        }); // 1st column contains the field title.

        switch (fld.field_type) {
          case 'array':
            cols.push({
              text: fld.value.join('\n'),
              style: 'sectionText'
            });
            break;
          case 'inline_array':
            cols.push(this.parseInlineArray(fld.inline_array));
            break;
          default:
            const val = ((typeof(fld.value) === 'undefined') ? '' : fld.value);
            cols.push({text: val, style: 'sectionText'});
            break;
        }

        rows.push(cols);

      });

      return [
        this.reporterUtility.createColouredSectionHeader(section.title),
        ReporterPdfFullArtifactParserService.create2ColTable(rows, ['30%', '*'], true)
      ];
    } else {
      return;
    }
  }

  /**
   * Parses all artifact sections
   * @param sections
   * @private
   */
  private parseSections(sections) {
    const parsedSections = [];
    sections.map((section) => {
      const res = this.parseSection(section);
      if (res && typeof(res) !== 'undefined') {
        parsedSections.push(res);
      }
    });
    return parsedSections;
  }

  /**
   * Returns the name of the current artifact.
   * @returns {*|string|string}
   */
  getArtifactName() {
    return this.artifact ? this.artifact.artifact_name : null;
  }

  /**
   * Clears parsed objects;
   * @private
   */
  clearParsed() {
    this.parsed = [];
  }

  /**
   * Parses the artifacts, returning a pdfMake object structure used when rendering the report.
   * @param artifact
   * @param createdHr
   * @returns {*}
   */
  parse(artifact, createdHr) {

    /**
     * Prevents duplicating already parsed items.
     * @param obj
     * @param parsed
     * @returns {boolean}
     */
    function exists(obj, parsed) {
      if (parsed.length === 0) {
        return false;
      }

      const hits = parsed.filter(function (el) {
        return (JSON.stringify(el.info) === JSON.stringify(obj.info));
      });

      return (hits.length > 0);
    }


    this.artifact = artifact;
    return new Promise((resolve) => {
      this.parseImages(typeof(artifact.image_ids) === 'undefined' ? null : artifact.image_ids).then((images) => {
        const artifactName = this.getArtifactName();
        const frontPageImage = (typeof(images) !== 'undefined' ? images[0] : null);

        this.fullObjectFrontPage.create(frontPageImage, artifactName, this.frontPageTexts, createdHr).then((res) => {
          const parsedOverviewFields = this.parseOverviewFields(artifactName);
          const parsedSections = this.parseSections(artifact.sections);
          const doc = this.createContent(artifact, res['username'], res['frontpage'],
            parsedOverviewFields, parsedSections, images, createdHr);

          doc._frontpageTexts = this.frontPageTexts;

          if (!exists(doc, this.parsed)) {
            // Cloning the doc-object using Object.assign({}, doc) in order
            // to make sure a unique object is pushed to the parsed array.
            this.parsed.push(Object.assign({}, doc));
          }

          resolve();

        }).catch(function (e) {
          console.error(e);
        });
      });
    });
  }

  parseAll(artifacts, overviewFieldsDataSet, frontPageTexts, createdHr) {
    this.frontPageTexts = frontPageTexts;
    this.overviewFieldsDataSet = overviewFieldsDataSet;

    return new Promise((resolve) => {

      const self = this;
      const version = self.reporterUtility.getPrimusVersion();

      self.frontPageTexts.version += ' ' + version;

      const promises = [];

      artifacts.map((a) => {
        const obj = self.parse(a, createdHr);
        promises.push(obj);
      });

      Promise.all(promises).then(() => {
        resolve(self.parsed);
      });

    });

  }

}
