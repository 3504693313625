import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchContainer} from '../../search-container';
import {SearchHandlerService} from '../../search-handler.service';
import {CommonsService} from '../../../core/commons.service';

@Component({
  selector: 'app-search-result-pager',
  templateUrl: './search-result-pager.component.html',
  styleUrls: ['./search-result-pager.component.scss']
})
export class SearchResultPagerComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;

  searchPage = 1;
  timeoutPromise;
  hasFocus = false;
  private lastPage = 1;

  constructor(private searchHandler: SearchHandlerService,
              private commons: CommonsService) {
  }

  ngOnInit() {
    if (this.searchContainer) {
      this.setPage(this.searchContainer.searchPage || 1);
    }
    this.searchHandler.subscribeToSearchResult(this.searchContainer, () => {
      this.pagerSearchResultCallback();
    });

  }

  ngOnDestroy(): void {
    this.searchHandler.unSubscribeToSearchResult(this.searchContainer, () => {
      this.pagerSearchResultCallback();
    });
  }

  setPage(page) {
    this.searchPage = page;
    this.searchPageChange();
  }

  prevPage() {
    if (this.searchPage > 1) {
      this.setPage(this.searchPage - 1);
    }
  }

  nextPage() {
    if (this.searchPage < this.searchContainer.maxPage) {
      this.setPage(this.searchPage + 1);
    }
  }

  searchPageChange() {
    if (this.timeoutPromise) {
      clearTimeout(this.timeoutPromise);
    }
    this.timeoutPromise = setTimeout(() => {
      const sc = this.searchContainer;
      if (sc && this.lastPage !== this.searchPage) {
        const page = this.getSearchPage(this.searchPage);
        if (page) {
          sc.searchPage = page;
          this.searchHandler.runSearch(sc, true).then();
          this.lastPage = this.searchPage;
        }
      }
    }, 500);
  }

  toggleSearchPager($event) {
    if ($event) {
      this.hasFocus = true;

      if (this.hasFocus === true) {
        window.addEventListener('mouseup', this.ignoreClicksCallback, true);
      } else {
        window.removeEventListener('mouseup', this.ignoreClicksCallback, true);

      }
    } else {
      this.hasFocus = false;
    }
  }

  private getSearchPage(value) {
    let page, res;
    if (!isNaN(value)) {
      page = Number(value);
      if (page > 0 && page <= this.searchContainer.maxPage) {
        res = page;
      }
    }
    return res;
  }

  private pagerSearchResultCallback() {
    const sc = this.searchContainer;
    if (sc && sc.searchResult) {
      this.searchPage = this.searchContainer.searchPage;
    }
  }

  private ignoreClicksCallback = (item) => {
    const found = this.commons.findClassNameRecursively(item.target, 'dropdownIgnoreClicks');
    if (!found) {
      setTimeout(() => {
        this.hasFocus = false;
        window.removeEventListener('mouseup', this.ignoreClicksCallback, true);
      }, 100);
    }
  }

}
