import {Injectable} from '@angular/core';
import {CmsApiService} from './cms-api.service';
import {CmsQueueService} from './cms-queue.service';
import {AConst} from './a-const.enum';

@Injectable({
  providedIn: 'root'
})
export class CommonsService {

  constructor(private cms: CmsApiService, private cmsQueue: CmsQueueService) {
  }

  userData;
  objectTypes;
  objectStatusTypes = null;
  mappedStatusTypes = {};

  private noObjectIdDisplayed = false;

  private static hasClass(el, class_to_match) {
    let c;
    if (el && el.className &&
      typeof class_to_match === 'string') {
      c = el.getAttribute('class');
      c = ' ' + c + ' ';
      return c.indexOf(' ' + class_to_match + ' ') > -1;
    } else {
      return false;
    }
  }

  public getUserData(noCache, suppressErrHandler?) {
    return new Promise((resolve, reject) => {
      const params = {suppressErrHandler: suppressErrHandler};
      this.cmsQueue.runCmsFnWithQueue('getUserData', params, noCache).then(
        (data) => {
          this.userData = data[AConst.USER];
          resolve(this.userData);
        },
        (response) => {
          const message = response.data ? response.data.message : '';
          console.error(
            'Error getting user data: ' + message + ': ' + response.status);
          if (reject) {
            reject(message);
          }
        }
      );
    });
  }

  // Submit an array of objects that contains valid editions to
  // be filtered based on application edition
  public getValidEditionArray(objArray, fn) {
    this.cms.getEditionFilter(objArray).then(
      fn,
      () => {
        console.warn('Unable to retrieve edition filter');
      }
    );
  }

  public setEditionTitle(user) {
    let edition;
    if (user && user.edition) {
      edition = {
        id: user.edition,
        title: {
          Large: 'TRANS__EDITION__LARGE',
          Medium: 'TRANS__EDITION__MEDIUM',
          Small: 'TRANS__EDITION__SMALL'
        }[user.edition]
      };
    }
    return edition;
  }

  public isOf(object, attrib, typesIn) {
    let res = false, type, types = typesIn;
    if (!Array.isArray(types)) {
      types = [types];
    }

    if (object) {
      type = object[attrib];
      res = types.indexOf(type) !== -1;
    }
    return res;
  }

  public canAnnotate(object) {
    return this.hasAnnotationRights() && object && object[AConst.ARTIFACT_ID] && !this.isOf(
      object, AConst.OBJECT_TYPE, ['actor', 'user']) && !this.isOf(
      object, AConst.META_TYPE, ['artifact', 'activity']);
  }

  public hasAnnotationRights() {
    let res = false;
    if (this.userData) {
      res = this.userData[AConst.EDITION] === 'Large';
    }
    return res;
  }

  public compareArrays(targetArray, compArray, targetProp,
                       compProp, fn) {
    if (!compProp) {
      compProp = targetProp;
    }
    if (targetArray && Array.isArray(targetArray)) {
      targetArray.forEach((targetItem, targetIndex) => {
        compArray.forEach((compItem) => {
          let err;
          if (targetItem[targetProp] &&
            compItem[compProp]) {
            if (targetItem[targetProp] ===
              compItem[compProp]) {
              fn(targetIndex);
            }
          } else {
            err = 'Items missing comparator ' +
              'property \'' + targetProp + '\'';
            if (compProp !== targetProp) {
              err += ' or \'' + compProp + '\'';
            }
            throw err;
          }
        });
      });
    } else {
      throw new Error('Target array does not exist or is not an array');
    }
  }

  // Recursive search a model for a property with name "propName".
  // This function has a weakness: Cannot have two properties with
  // the same name in the model!
  public searchModel(mod, propName, level?, grandParent?,
                     modName?) {
    let res = null, granny, parentName;
    if (!Array.isArray(mod)) {
      granny = mod;
      parentName = modName;
    } else {
      granny = grandParent;
      parentName = null;
    }
    if (mod) {
      if (!level) {
        level = 0;
      }
      if (level < 10) {
        this.each(mod, (pName, value) => {
          if (pName[0] !== '$') {
            if (pName === propName) {
              res = {
                grandParentModel: grandParent,
                parentPropName: parentName,
                parentModel: mod,
                propName: propName,
                value: value
              };
            } else {
              if (value !== null &&
                typeof value === 'object') {
                res = this.searchModel(value, propName,
                  level + 1, granny, pName);
              }
            }
          }
          return res === null;
        });
      } else {
        console.warn('Too many levels in prop find! ' +
          'Check for circular object structures!');
      }
    } else {
      console.warn('Not a valid model!');
    }
    return res;
  }

  public applyWordBreakOpportunity(input) {
    const re = /([\\;/_\-»])(?![^<]*>|[^<>]*<\/)/gm;
    const subst = '$1<wbr>';
    const maxLengthWithoutBreak = 25;

    if (typeof input !== 'string') {
      return input;
    }

    if (input.length > maxLengthWithoutBreak &&
      !input.match(/([\\;/_\-»\s])/gm)) {
      return input.substr(0, maxLengthWithoutBreak) + '<wbr>' +
        input.substr(maxLengthWithoutBreak);
    }

    if (input && input.replace !== undefined) {
      return input.replace(re, subst);
      // return $sce.trustAsHtml(input.replace(re, subst));
    }
    return input;
  }

  public findObjectContainingProp(mod, propVal) {
    let res = null;
    this.each(mod, (pName, item) => {
      if (pName === propVal || item === propVal) {
        res = {
          item: item,
          itemName: pName
        };
      } else {
        res = this.findObjectContainingProp(item, propVal);
      }
      return res === null;
    });
    return res;
  }

  // Similar to angular.forEach, but with break!
  public each(object, fn) {
    let t, cont = true;
    if (Array.isArray(object)) {
      for (t = 0; t < object.length; t++) {
        cont = fn(t, object[t]);
        if (typeof cont !== 'undefined' && !cont) {
          break;
        }
      }
    } else if (typeof object === 'object') {
      for (t in object) {
        if (object.hasOwnProperty(t)) {
          cont = fn(t, object[t]);
          if (typeof cont !== 'undefined' && !cont) {
            break;
          }
        }
      }
    } else {
      cont = false;
    }
    return cont;
  }

  public compareValues(value1, comp, value2) {
    let res = false;
    if (!comp) {
      comp = '==';
    }
    value1 = value1 === undefined ? null : value1;
    value2 = value2 === undefined ? null : value2;
    switch (comp) {
      case '<=':
        res = value1 <= value2;
        break;
      case '>=':
        res = value1 >= value2;
        break;
      case '>':
        res = value1 > value2;
        break;
      case '<':
        res = value1 < value2;
        break;
      case '==':
        res = value1 === value2;
        break;
      case '!=':
        res = value1 !== value2;
        break;
      default:
        throw new Error('Unknown or missing comparator: \'' + comp + '\'');
    }
    return res;
  }

  public getContextIds(contexts) {
    let res;
    if (contexts) {
      res = [];
      contexts.forEach((context) => {
        if (typeof context === 'string') {
          res.push(context);
        } else {
          res.push(context[AConst.CONTEXT_ARTIFACT_ID]);
        }
      });
    }
    return res;
  }

  public getObjectType(obj) {
    let objType = obj[AConst.OBJECT_TYPE];
    const mt = obj[AConst.META_TYPE];
    if (mt && (mt === 'actor' || mt === 'place')) {
      objType = obj.meta_type;
    } else if (mt === 'sub_model') {
      // Could be context object type, in which case object
      // type should be obtained using context artifact id
      if (objType === 'AdmEventContextItem') {
        objType = obj[AConst.CONTEXT_OBJECT_TYPE];
      } else {
        objType = null;
      }
    }
    if (!objType) {
      objType = this.getObjectTypeFromObjectId(obj[AConst.ARTIFACT_ID]);
    }
    return objType;
  }

  public getObjectIdField(object) {
    let res = null;
    switch (object[AConst.OBJECT_TYPE]) {
      case 'ImageItem':
        res = AConst.IMAGE_ID;
        break;
      case 'VideoItem':
        res = AConst.VIDEO_ID;
        break;
      case 'AttachmentItem':
        res = AConst.ATTACHMENT_ID;
        break;
    }
    if (!res && object[AConst.ARTIFACT_ID]) {
      res = AConst.ARTIFACT_ID;
    }
    if (!res) {
      throw new Error('Unable to get id field for ' + object[AConst.OBJECT_TYPE]);
    }
    return res;
  }

  public setObjectTypes(objectTypesIn) {
    this.objectTypes = objectTypesIn;
  }

  public getObjectTypeFromObjectId(objectId) {
    let firstPos, typeId, objectType = '';
    // Object types loaded from server in index.html:
    // cms_api/v1.0/js_settings
    //noinspection JSUnresolvedVariable
    if (objectId) {
      firstPos = objectId.indexOf('-');
      if (firstPos !== -1) {
        typeId = objectId.substring(0, firstPos);
      } else {
        if (objectId.indexOf('ct_') === 0) {
          typeId = objectId;
        }
      }
      if (typeId) {
        if (typeId.indexOf('ct_') === 0) {
          if (firstPos !== -1) {
            objectType = typeId;
          } else {
            objectType = 'conceptType';
          }
        } else {
          if (this.objectTypes) {
            if (this.objectTypes[typeId]) {
              objectType = this.objectTypes[typeId].type;
            } else {
              throw new Error('No object type defined for ' + typeId);
            }
          } else {
            throw new Error('Object types not loaded yet!!');
          }
        }
      } else {
        // throw new Error('No type id found for ' + objectId);
      }
    } else {
      if (!this.noObjectIdDisplayed) {
        console.warn('No object id received');
        this.noObjectIdDisplayed = true;
      }
    }
    return objectType;
  }

  public uuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }

  public getObjPropFromPath(obj, path: string) {
    let pSplit, prop = obj;
    if (path) {
      pSplit = path.split('.');
      pSplit.forEach((propName) => {
        if (typeof prop[propName] !== 'undefined') {
          prop = prop[propName];
        } else if (typeof prop === 'string') {
          // If object comes from SOLR, it will not have the
          // same object hierarchy as DAO objects, so may
          // need to return the property from a lower level.
          // E.g. DAO CommentEvent.cost.value = 333 versus
          // SOLR CommentEvent.cost = "333"
        } else {
          throw new Error('Property \'' + propName +
            '\' does not exist for object of type \'' +
            obj[AConst.OBJECT_TYPE] + '\' in path \'' + path + '\'');
        }
      });
    }
    return prop;
  }

  /**
   Set value on an object within a field path.
   If e.g. fieldPath is contexts[0].context_artifact_id, the object structure will be traversed using the fieldPath information in order to
   set the value.
   If the field path does not already exist within the object it will be created.
   **/
  public setObjectValue(object, fieldPath, value) {
    const dotPath = fieldPath.split('.');
    if (dotPath.length === 1) {
      object[fieldPath] = value;
    } else {
      let subObj = object;
      for (let dotIndex = 0; dotIndex < dotPath.length - 1; dotIndex++) {
        const dotField = dotPath[dotIndex];
        const bStart = dotField.indexOf('[');
        if (bStart !== -1) {
          const bEnd = dotField.indexOf(']');
          const index = dotField.substring(bStart + 1, bEnd);
          const fieldName = dotField.substring(0, bStart);
          if (subObj[fieldName] === undefined) {
            subObj[fieldName] = [];
          }
          if (subObj[fieldName][index] === undefined) {
            subObj[fieldName][index] = {};
          }
          subObj = subObj[fieldName][index];
        } else {
          if (subObj[dotField] === undefined) {
            subObj[dotField] = {};
          }
          subObj = subObj[dotField];
        }
      }
      subObj[dotPath[dotPath.length - 1]] = value;
    }
  }

  public UrlBuilder(path) {
    let firstParam = true;
    let url = path;
    if (!url) {
      url = '';
    }

    const val = (value) => {
      let res = '&';
      if (firstParam) {
        res = '?';
        firstParam = false;
      }
      return res + value;
    };

    const array2url = (name, values) => {
      values.forEach((value) => {
        url += val(name) + '=' + value;
      });
    };

    const valName2url = (name, value) => {
      url += val(name) + '=' + value;
    };

    const object2url = (obj: object) => {
      for (const name in obj) {
        if (obj.hasOwnProperty(name)) {
          let value = obj[name];
          if (name.indexOf('_') !== 0) {
            if (typeof value === 'undefined') {
              value = '';
            }
            if (Array.isArray(value)) {
              array2url(name, value);
            } else if (typeof value === 'object') {
              for (const subName in value) {
                if (value.hasOwnProperty(subName)) {
                  const subValue = value[subName];
                  if (Array.isArray(subValue)) {
                    array2url(subName, subValue);
                  } else {
                    valName2url(subName, subValue);
                  }
                }
              }
            } else {
              valName2url(name, value);
            }
          }
        }
      }
    };

    const getUrl = () => {
      return this.cms.getApiUrl(url);
    };

    return {
      array2url: array2url,
      valName2url: valName2url,
      object2url: object2url,
      getUrl: getUrl
    };
  }

  public stopPropagation(event) {
    // cross-browser event
    if (event) {
      event = event || window.event;
    }
    if (event.stopPropagation) {
      event.stopPropagation();
    } else {
      event.cancelBubble = true;
    }
  }

  public findClassNameRecursively(nodeIn, className) {
    let node = nodeIn, found = false;
    while (node.parentNode) {
      node = node.parentNode;
      if (CommonsService.hasClass(node, className)) {
        found = true;
      }
    }
    return found;
  }

  public getStatusFromArtifact(art) {
    let res = null, statusType;
    if (art) {
      if (art.status) {
        statusType = art.status[AConst.STATUS_TYPE_ID];
      } else {
        statusType = art[AConst.STATUS_TYPE_ID];
      }
      if (statusType) {
        res = this.mapStatusTypeId(statusType);
      } else {
        console.log('No status type');
      }
    } else {
      console.log('Art not defined');
    }
    return res;
  }

  public getStatusTypeId(objectType, statusType, fn?) {
    let res;
    if (this.objectStatusTypes) {
      res = this.mapObjectStatusType(
        this.objectStatusTypes, objectType, statusType);
    } else if (fn) {
      this.getStatusTypeIds((objectStatusTypes) => {
        fn(this.mapObjectStatusType(
          objectStatusTypes, objectType, statusType));
      });
    } else {
      console.warn('Status types not loaded yet');
    }
    if (fn) {
      fn(res);
    }
    return res;
  }

  private mapObjectStatusType(objectStatusTypes, objectType,
                              statusType) {
    let res = null;
    if (objectStatusTypes[objectType]) {
      res = objectStatusTypes[objectType][statusType];
    } else {
      console.warn('No object status types for ' + objectType);
    }
    return res;
  }

  public setArtifactStatus(art, status) {
    art.status[AConst.STATUS_TYPE_ID] =
      this.getStatusTypeId(art[AConst.OBJECT_TYPE], status);
  }

  private mapStatusTypeId(statusTypeId) {
    return this.mappedStatusTypes[statusTypeId];
  }

  public mapStatusTypeIds(objectStatusTypes) {
    const res = {};
    for (const key in objectStatusTypes) {
      if (objectStatusTypes.hasOwnProperty(key)) {
        const statusType = objectStatusTypes[key];
        for (const status in statusType) {// .forEach((statusTypeIdItem, status) => {
          if (statusType.hasOwnProperty(status)) {
            const statusTypeIdItem = statusType[status];
            res[statusTypeIdItem] = status;
          }
        }
      }
    }
    return res;
  }

  public getStatusTypeIds(fn?) {
    if (!this.objectStatusTypes) {
      this.cmsQueue.runCmsFnWithQueue('getObjectStatusTypes', null, false).then(
        (data) => {
          console.log('Object status types retrieved');
          this.objectStatusTypes = data;
          this.mappedStatusTypes = this.mapStatusTypeIds(data);
          if (fn) {
            fn(data);
          }
        },
        (response) => {
          const message = response.data ? response.data.message : '';
          console.error('Error getting status type ids: ' + message + ': ' + response.status);
        });
    }
  }

  public sortArray(array, sortOpt, reverse?) {
    array.sort((a, b) => {
      if (a[sortOpt] < b[sortOpt]) {
        return !reverse ? -1 : 1;
      } else if (a[sortOpt] > b[sortOpt]) {
        return !reverse ? 1 : -1;
      } else {
        return 0;
      }
    });
    return array;
  }

  public isObjectState(state) {
    return [
      'home.primus.artifact',
      'home.primus.search.artifact',
      'home.primus.gallery',
      'home.primus.search.gallery',
      'home.primus.videoGallery',
      'home.primus.search.videoGallery'

    ].indexOf(state) !== -1;
  }

  public copy(object: any, options?: any) {
    let res;
    options = options || {};
    if (options.ignoreEmptyArray) {
      options.ignoreUndefined = true;
    }
    if (object === null) {
      res = null;
    } else if (Array.isArray(object)) {
      if (options.ignoreEmptyArray && object.length === 0) {
        return;
      }
      res = [];
      object.forEach(item => {
        res.push(this.copy(item, options));
      });
    } else if (typeof object === 'object') {
      res = {};
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          const value = this.copy(object[key], options);
          if (value !== undefined || !options.ignoreUndefined) {
            res[key] = value;
          }
        }
      }
    } else {
      res = object;
    }
    return res;
  }

  public orderArray(array: Array<object>, orderField: string, reverse?: boolean) {
    if (!array) {
      return;
    }
    let ordered = false;
    const res = array.slice();
    do {
      ordered = true;
      for (let t = 0; t < res.length - 1; t++) {
        const item1 = res[t];
        const item2 = res[t + 1];
        if (item1[orderField] > item2[orderField]) {
          res[t] = item2;
          res[t + 1] = item1;
          ordered = false;
        }
      }
    } while (!ordered);
    if (reverse) {
      res.reverse();
    }
    return res;
  }

  // Recursive equals method that hopefully replaces the old angular.equals method
  public equals(var1, var2, logUnequal?: boolean) {
    let res;
    if (var1 === null && var2 === null) {
      return true;
    }
    if (var1 === null || var2 === null) {
      return false;
    }
    if (Array.isArray(var1)) {
      if (!Array.isArray(var2)) {
        return false;
      }
      if (var1.length !== var2.length) {
        return false;
      }
      res = true;
      var1.forEach((item, index) => {
        if (!this.equals(item, var2[index])) {
          res = false;
        }
      });
      return res;
    }
    if (typeof var1 === 'object') {
      if (typeof var2 !== 'object') {
        return false;
      }
      if (Object.keys(var1).length !== Object.keys(var2).length) {
        return false;
      }
      res = true;
      for (const key in var1) {
        if (var1.hasOwnProperty(key)) {
          if (!this.equals(var1[key], var2[key])) {
            if (logUnequal) {
              console.log('Not equals ' + key);
            }
            res = false;
          }
        }
      }
      return res;
    }
    res = var1 === var2;
    if (!res && logUnequal) {
      console.log(var1 + ' != ' + var2);
    }
    return res;
  }

  // Method for filtering out array elements based on a filter object, hopefully replaces the old angular filter "$filter('filter')"
  public filter(arr: Array<any>, filterObj: object) {
    const res = [];
    arr.forEach(item => {
      for (const filterKey in filterObj) {
        if (filterObj.hasOwnProperty(filterKey)) {
          if (this.equals(item[filterKey], filterObj[filterKey])) {
            res.push(item);
          }
        }
      }
    });
    return res;
  }

}
