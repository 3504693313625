import {Injectable} from '@angular/core';
import {StateService} from '@uirouter/core';
import {MatDialog} from '@angular/material';
import {AConst} from './a-const.enum';
import {ModelFactoryService} from './model-factory.service';
import {ObjectStorageService} from './object-storage.service';
import {CommonsService} from './commons.service';
import {NotificationService} from '../shared/notification.service';
import {ConfirmDialogComponent} from '../object-edit/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {ProgressDialogComponent} from '../shared/progress-dialog/progress-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class FolderService {
  folderTypes = {
    // read: owner, write: owner
    'private': {id: 'ct_77-2'},
    // read: all, write: owner
    'shared': {id: 'ct_77-1'},
    // read: all, write: all
    'public': {id: 'ct_77-0'}
  };

  constructor(private modelFactory: ModelFactoryService,
              private objectStorage: ObjectStorageService,
              private commons: CommonsService,
              private stateService: StateService,
              private notificationService: NotificationService,
              private modalService: MatDialog,
              private translate: TranslateService) {
  }

  addFolderItemsStoreFolder(folderId, objectIds) {
    return new Promise((resolve, reject) => {
      this.objectStorage.loadObject(folderId).then(folder => {
        this.appendFolderItems(folder, objectIds);
        this.objectStorage.storeObject(folder).then(
          res => {
            this.objectStorage.loadObject(res[AConst.ARTIFACT_ID]).then(
              folderUpdated => {
                resolve(folderUpdated);
              },
              reason => {
                reject(reason);
              });
          },
          reason => {
            reject(reason);
          }
        );
      });
    });
  }

  appendFolderItems(folder, objectIds) {
    if (!objectIds) {
      return folder;
    }
    if (!Array.isArray(objectIds)) {
      objectIds = [objectIds];
    }

    // filter out existing items
    const folderObjectIds = folder[AConst.FOLDER_ITEMS].map(
      item => {
        return item[AConst.FOLDER_ARTIFACT_ID];
      });

    objectIds = objectIds.filter((objectId) => {
      return (folderObjectIds.indexOf(objectId) === -1);
    });

    // create models for items
    const newItems = objectIds.map((artifact_id) => {
      return this.modelFactory.createModelItem('FolderItem', {
        folder_artifact_id: artifact_id,
        folder_id: folder[AConst.ARTIFACT_ID]
      });
    });
    folder[AConst.FOLDER_ITEMS] = folder[AConst.FOLDER_ITEMS].concat(newItems);

    return folder;
  }

  canUserEditFolder(folder): Promise<boolean> {
    return new Promise(resolve => {
      this.commons.getUserData(false).then(userData => {
        const userId = userData[AConst.ARTIFACT_ID];
        if (folder && folder[AConst.FOLDER_TYPE_ID] &&
          [this.folderTypes.public.id, this.folderTypes.private].includes(folder[AConst.FOLDER_TYPE_ID])) {
            resolve(folder[AConst.CREATED_BY_ID] === userId);
        }
        resolve(true);
      });
    });
  }

  goToFolder(folderId) {
    this.stateService.go('home.primus.search',
      {
        targetId: folderId,
        objectCount: 0,
        path: 'home/folders/folder',
        search_result_view: 'thumbnail'
      },
      {reload: true});
  }

  removeFolderItems(folder, items): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!Array.isArray(items)) {
        items = [items];
      }

      const folderItemsToDelete = folder[AConst.FOLDER_ITEMS].filter(item => {
        return (items.indexOf(item[AConst.FOLDER_ARTIFACT_ID]) > -1);
      });

      const confirmModal = this.modalService.open(ConfirmDialogComponent, {panelClass: 'confirm-dialog'});
      confirmModal.componentInstance.modalContent = this.translate.instant('TRANS__FOLDER__CONFIRM_DELETE_ITEMS', {
        // 'Are you sure you want to delete ' +
        // folderItemsToDelete.length +
        // ' items from this folder?'
        folderName: folder.title,
        count: folderItemsToDelete.length
      });
      confirmModal.componentInstance.modalTitle = 'TRANS__SEARCH__ACTION_MENU__MENU_TITLE__DELETE_FROM_FOLDER';

      confirmModal.afterClosed().subscribe(confirmed => {
        if (confirmed) {
          let deleted_items = 0;
          try {
            folder[AConst.FOLDER_ITEMS] = this.deleteItems(folder, AConst.FOLDER_ITEMS, folderItemsToDelete, AConst.FOLDER_ARTIFACT_ID);
            deleted_items = folderItemsToDelete.length;
          } catch (e) {
            this.notificationService.addNotification({
              messages: ['TRANS__FOLDER__DELETE_ITEMS_ERROR'],
              values: {
                folderName: folder.title,
                count: folderItemsToDelete.length
              },
              type: 'error'
            });
            console.error('delete items failed:', e, folder, items);
          }

          if (!deleted_items) {
            reject();
            return;
          }

          const progressModal = this.modalService.open(
            ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
          this.objectStorage.storeObject(folder).then(
            folderUpdated => {
              this.notificationService.addNotification({
                messages: ['TRANS__FOLDER__DELETE_ITEMS_SUCCESS'],
                values: {
                  folderName: folder.title,
                  count: deleted_items
                },
                type: 'success'
              });
              progressModal.close();
              resolve(folderUpdated);
            },
            reason => {
              this.deleteItemsError(reason.error.message, folder, folderItemsToDelete);
              progressModal.close();
              reject();
            });
        }
      });
    });
  }

  copyFolder(folder) {
    return new Promise(resolve => {
      const progressModal = this.modalService.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});

      const artifactIds = this.getFolderItemIds(folder);
      const folderData = {
        title: folder.title + ' (' + this.translate.instant('TRANS__FOLDER__COPY_LABEL') + ')',
        description: folder.description,
        folder_type_id: folder.folder_type_id,
        folder_items: []
      };
      this.modelFactory.createModelItemAsync('folder', folderData).then(folderCopy => {
        this.appendFolderItems(folderCopy, artifactIds);
        this.objectStorage.storeObject(folderCopy).then(
          storeResult => {
            progressModal.close();
            this.notificationService.addNotification({
              messages: ['TRANS__FOLDER__COPY_FOLDER_SUCCESS'],
              type: 'success'
            });
            resolve(storeResult);
          },
          reason => {
            progressModal.close();
            this.notificationService.addNotification({
              messages: ['TRANS__FOLDER__COPY_FOLDER_ERROR'],
              values: {folderName: folder.title},
              type: 'error'
            });
            console.error('error storing folder: ' + reason.error.message);
          });
      });
    });
  }

  deleteFolder(folder) {
    return new Promise(resolve => {
      this.objectStorage.loadObject(folder[AConst.ARTIFACT_ID]).then(
        folderLoaded => {
          let dialogText;

          if (folderLoaded[AConst.FOLDER_ITEMS] &&
            folderLoaded[AConst.FOLDER_ITEMS].length) {
            // 'The folder is not empty. Do you want to delete
            // the folder and its contents?'
            dialogText = this.translate.instant('TRANS__FOLDER__CONFIRM_DELETE_CONTENT', {
              count: folderLoaded[AConst.FOLDER_ITEMS].length,
              folderName: folderLoaded.title
            });
          } else {
            // 'Are you sure you want to delete the folder "' +
            // folder.title + '"?'
            dialogText = this.translate.instant('TRANS__FOLDER__CONFIRM_DELETE', {
              folderName: folderLoaded.title
            });
          }
          const confirmModal = this.modalService.open(ConfirmDialogComponent, {panelClass: 'confirm-dialog'});
          confirmModal.componentInstance.modalContent = dialogText;
          confirmModal.componentInstance.modalTitle = 'TRANS__FOLDER__VIEW__DELETE_FOLDER';
          confirmModal.afterClosed().subscribe(confirmed => {
            if (confirmed) {
              this.deleteFolderFn(folderLoaded).then(() => {
                resolve();
              });
            }
          });
        },
        reason => {
          console.error('error retrieving folder ' + folder[AConst.ARTIFACT_ID] + ': ' + reason.error.message);
        }
      );
    });
  }

  private deleteFolderFn(folder) {
    return new Promise((resolve => {
      this.canUserEditFolder(folder).then(res => {
        if (res) {
          const progressModal = this.modalService.open(
            ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
          this.objectStorage.deleteObject(folder[AConst.ARTIFACT_ID]).then(
            () => {
              progressModal.close();
              this.notificationService.addNotification({
                messages: ['TRANS__FOLDER__DELETE_SUCCESS'],
                values: {folderName: folder.title},
                type: 'success'
              });

              resolve();
            },
            (response) => {
              progressModal.close();
              this.notificationService.addNotification({
                messages: ['TRANS__FOLDER__DELETE_ERROR'],
                values: {folderName: folder.title},
                type: 'error'
              });
              console.error('error deleting ' + folder[AConst.ARTIFACT_ID] + ': ' + response.error.message);
            }
          );
        } else {
          this.notificationService.addNotification({
            messages: ['TRANS__FOLDER__DELETE_FOLDER_PERMISSIONS_ERROR'],
            values: {folderName: folder.title},
            type: 'error'
          });
        }
      });
    }));
  }

  private getFolderItemIds(folder) {
    let itemIds = folder[AConst.FOLDER_ARTIFACT_ID];
    if (!itemIds) {
      itemIds = [];
      const folderItems = folder[AConst.FOLDER_ITEMS];
      if (folderItems) {
        folderItems.forEach(folderItem => {
          itemIds.push(folderItem[AConst.FOLDER_ARTIFACT_ID]);
        });
      } else {
        console.warn('Unable to locate folder item ids');
      }
    }
    return itemIds;
  }

  private deleteItemsError(error, folder, folderItemsToDelete) {
    this.notificationService.addNotification({
      messages: ['TRANS__FOLDER__DELETE_ITEMS_ERROR', error],
      values: {
        // 'Failed deleting ' +
        // folderItemsToDelete.length +
        // ' from folder'
        folderName: folder.title,
        count: folderItemsToDelete.length
      },
      type: 'error'
    });
  }

  private deleteItems(mod, targetArrayName, deleteArray, compProp1, compProp2?) {
    const targetArray = mod[targetArrayName];
    let deleted = false;

    this.commons.compareArrays(targetArray, deleteArray, compProp1, compProp2, targetIndex => {
      this.modelFactory.deleteArrayItem(targetArray, targetIndex, mod);
      deleted = true;
    });
    if (!deleted) {
      console.warn('No items deleted! Target array probably did not contain items');
    }
    return targetArray;
  }

}
