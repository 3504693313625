import {Injectable} from '@angular/core';
import {FieldValueService} from '../../core/field-value.service';
import {ChangeTrackerService} from '../../core/change-tracker.service';
import {ModelFactoryService} from '../../core/model-factory.service';
import {AConst} from '../../core/a-const.enum';
import {FieldMetaService} from '../../core/field-meta.service';
import {InlineArrayItemService} from '../../core/inline-array-item.service';

export class CopyKeepServiceParams {
  object: object;
  field: object;
  index: number;
  fieldChangeCallback;

  constructor(object: object, field: object, index: number, fieldChangeCallback?) {
    this.object = object;
    this.field = field;
    this.index = index;
    this.fieldChangeCallback = fieldChangeCallback;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CopyKeepService {

  constructor(private fieldValueSvc: FieldValueService,
              private inlineArrayItemSvc: InlineArrayItemService,
              private changeTracker: ChangeTrackerService,
              private fieldMetaSvc: FieldMetaService,
              private modelFactory: ModelFactoryService) {
  }

  getKeepProps(params: CopyKeepServiceParams) {
    let keepProps;
    if (params.object && params.object['$$keep']) {
      keepProps = params.object['$$keep'][params.field['name']];
      if (keepProps && params.index !== undefined) {
        keepProps = keepProps['indexes'][params.index];
      }
    }
    return keepProps;
  }

  /**
   * The following conditions shall prevent the "keep" checkbox
   * from being displayed next to a field:
   *  - Copied fields missing values
   *  - Copied fields that are required
   *  - New array items, added after the copy was created
   *  - Fields within a newly created array item object
   *
   * @param keep, boolean, whether field shall be default kept or not
   * @param params containing model information
   * @param params.object, the parent model of the field to be checked
   * @param params.fieldName, the name of the field to be checked
   * @param params.index, if set: the index of the array item to be checked
   */
  setKeep(keep, params: CopyKeepServiceParams) {
    let keepProps;
    const res = this.checkCanKeep(params);
    if (res) {
      keepProps = this.getSetKeepProps(params);
      if (keepProps) {
        keepProps.keep = keep;
      }
    }
    return res;
  }


  hasChanges(params: CopyKeepServiceParams) {
    return this.changeTracker.checkFieldChanged(params.object, params.field['name']);
  }

  removeNotKeep(object) {
    this.recursiveRemoveKeep(object);
  }

  setKeepSection(model, section, keep) {
    model.$$keepSection = model.$$keepSection || {};
    model.$$keepSection[section.name] =
      model.$$keepSection[section.name] || {};
    model.$$keepSection[section.name].keep = keep;
    this.setKeepSectionFields(model, section, keep);
  }

  private checkCanKeep(params: CopyKeepServiceParams) {
    let res = true, arrayItem;
    if (params.object) {
      if (params.index !== undefined) {
        arrayItem = params.object[params.field['name']][params.index];
        if (arrayItem !== undefined) {
          res = !arrayItem._create;
        } else {
          console.warn('No item ' + params.index + ' in ' + params.field['name']);
        }
      }
      if (res) {
        // meta = this.fieldMetaSvc.getFieldMetaData(params.object, params.field);
        res = this.fieldValueSvc.hasValue(params.field, params.object) &&
          !params.object['_create'] && !params.field[AConst.REQUIRED];
      }
      if (res) {
        res = !this.hasChanges(params);
      }
    }
    return res;
  }

  private getSetKeepProps(params: CopyKeepServiceParams) {
    let keepProps;
    if (params.object) {
      params.object['$$keep'] = params.object['$$keep'] || {};
      params.object['$$keep'][params.field['name']] = params.object['$$keep'][params.field['name']] || {indexes: {}};
      keepProps = params.object['$$keep'][params.field['name']];
      if (params.index !== undefined) {
        keepProps.indexes[params.index] = keepProps.indexes[params.index] || {};
        keepProps = keepProps.indexes[params.index];
      }
      if (params.fieldChangeCallback) {
        this.changeTracker.setFieldChangeCallback(params.object, params.field['name'], params.fieldChangeCallback);
      }
    } else {
      console.log('getSetKeepProps: Missing object');
    }
    return keepProps;
  }

  private recursiveRemoveKeep(obj) {
    let keep = null;
    if (obj) {
      keep = obj['$$keep'];
    } else {
      console.warn('No object!');
    }
    if (keep) {
      for (const fieldName in keep) {
        if (keep.hasOwnProperty(fieldName)) {
          const keepProps = keep[fieldName];
          if (keepProps.keep === false) {
            obj[fieldName] = null;
          }
          if (keepProps.indexes && obj[fieldName]) {
            const sortedIndex = this.getReverseSortedIndexes(keepProps.indexes);
            sortedIndex.forEach(index => {
              if (keepProps.indexes.hasOwnProperty(index)) {
                const keepPropsNdx = keepProps.indexes[index];
                if (keepPropsNdx.keep === false) {
                  obj[fieldName].splice(index, 1);
                } else {
                  this.recursiveRemoveKeep(obj[fieldName][index]);
                }
              }
            });
          }
        }
      }
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const subObj = obj[key];
          if (subObj && subObj['$$keep']) {
            this.recursiveRemoveKeep(subObj);
          }
        }
      }
    }
  }

  private getReverseSortedIndexes(indexes) {
    return Object.keys(indexes).sort((index1, index2) => {
      let res = 0;
      if (Number(index1) < Number(index2)) {
        res = 1;
      } else if (Number(index1) > Number(index2)) {
        res = -1;
      }
      return res;
    });
  }

  private setKeepSectionFields(model, section, keep) {
    section.fields.forEach((secField) => {
        this.setKeepSectionField(keep, model, secField);
      }
    );
  }

  private setKeepSectionField(keep, model, sectionField) {
    let secModel = model;
    const path = <string>sectionField.path;
    if (path) {
      path.split('.').forEach((s) => {
        secModel = secModel[s];
      });
    }
    if (secModel) {
      this.recursiveSetKeep(keep, secModel, sectionField);
    } else {
      console.warn('Model not found for ' + sectionField.path);
    }
  }

  private recursiveSetKeep(keep, object, field) {
    this.modelFactory.traverseModelField((model, fieldName, index) => {
      const fieldRes = model[AConst.$$META][fieldName];
      this.setKeep(keep, new CopyKeepServiceParams(model, fieldRes, index));
    }, object, field.name);
  }

}
