import { Injectable } from '@angular/core';
import {ReporterPdfConfigHelperService} from './reporter-pdf-config-helper.service';
import {ReporterPdfImageToTheLeftFrontPageService} from './reporter-pdf-image-to-the-left-front-page.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Service hosting methods used when rendering pages in a report with the object image to the
 * left, and the description of the object in a table to the right.
 */
export class ReporterPdfImageToTheLeftService {

  private frontPageTexts = null;

  constructor(
    private reporterPdfImageToTheLeftFrontPage: ReporterPdfImageToTheLeftFrontPageService,
    private reporterPdfConfigHelper: ReporterPdfConfigHelperService) { }

  /**
   * Creates the specified page, populating it with the specified artifacts.
   * @param artifacts
   * @returns object
   * @private
   */
  _create(artifacts) {

    const widths = ['*', '70%'],
          json = [];

    for (let n = 0; n < artifacts.length; n++) {
      const rows = [];
      const obj = artifacts[n];
      const title = (typeof(obj.title) !== 'undefined') ? obj.title : null;
      const data = (typeof(obj.data) !== 'undefined') ? obj.data : null;
      let img = (typeof(obj.image) !== 'undefined' && obj.image !== null) ? obj.image : null;

      // Insert image as a table, in order to draw a surrounding border.
      if (img != null) {
        img = {
          table: {
            widths: ['*'],
            body: [[img]]
          },
          layout: {
            hLineWidth: function (i) {
              return 0;
            },
            vLineWidth: function (i) {
              return 0;
            },
            hLineColor: function (i) {
              return '#7e97ad';
            }
          }
        };
      } else {
        img = {};
      }

      // Add the object's metadata as a table below the title.
      title.table.body.push([data]);

      // Push the image and title as a single row in the page table.
      rows.push([img, title]);

      // Add a new table for each artifact
      // NOTE: Required in order to make the artifacts appear fluently on the pages, and avoid cropping of data.
      const artifactTable = {
        table: {
          headerRows: 1,
          keepWithHeaderRows: 1,
          widths: widths,
          body: rows
        },
        layout: {
          hLineWidth: function (i) { return 0; },
          vLineWidth: function (i) { return 0; },
          height: function (i) { return 100; }
        }
      };
      json.push(artifactTable);
    }
    return json;
  }

  /**
   * Creates a grid-based page, where each line in the grid consists of the
   * following. the image to the left,a space grid, and then the text-grid.
   * @param artifacts
   * @param createdHr
   * @param filterSettings
   * @returns {Array}
   * @private
   */
  private createGridPage(artifacts, createdHr, filterSettings) {
    return new Promise((resolve) => {
      const pages = [];

      this.reporterPdfImageToTheLeftFrontPage.create(
        this.frontPageTexts, createdHr, filterSettings).then((res) => {
          pages.push(res);
          pages.push(this._create(artifacts));
          resolve(pages);
        });
    });
  }

  /**
   * Creates a table with the primary object image to the left,
   * and the object's metadata to the right.
   * @param artifacts
   * @param frontpageTexts
   * @param createdHr
   * @param filterSettings
   */
  createImageToTheLeftTable(artifacts, frontpageTexts, createdHr, filterSettings) {
    this.frontPageTexts = frontpageTexts;

    return new Promise( resolve => {
      const docDef = this.reporterPdfConfigHelper.getImageToTheLeftTable(this.frontPageTexts, createdHr);
      this.createGridPage(artifacts, createdHr, filterSettings).then( pages => {
        docDef.content = pages;
        resolve(docDef);
      });
    });
  }
}
