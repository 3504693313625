import {Component, Input, OnInit} from '@angular/core';
import {SearchContainer} from '../../search-container';

@Component({
  selector: 'app-search-filter-menu-small',
  templateUrl: './search-filter-menu-small.component.html',
  styleUrls: ['./search-filter-menu-small.component.scss']
})
export class SearchFilterMenuSmallComponent implements OnInit {

  dropdownMenu = false;
  @Input() searchContainer: SearchContainer;
  @Input() showFilters: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  toggleDropDownMenu() {
    this.dropdownMenu = !this.dropdownMenu;
  }
}
