import {Injectable} from '@angular/core';
import {AConst} from './a-const.enum';
import * as moment from 'moment';
import {SettingsService} from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class DateToolsService {

  constructor(private settings: SettingsService) {
  }

  private static getUtcDateFromTime(time) {
    const utcDate = new Date(0);
    utcDate.setUTCMilliseconds(time);
    return utcDate;
  }

  private static getUtcDate(dateIn) {
    const date = new Date(dateIn.getTime());
    const offset = date.getTimezoneOffset();
    const osh = Math.floor(offset / 60);
    const osm = offset % 60;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    date.setHours(hours - osh);
    date.setMinutes(minutes - osm);
    return date;
  }

  private static getDateSplitter(dateFormat) {
    const splitters = ['.', '-', '/', ':'];
    let res, test, t;
    for (t = 0; t < splitters.length; t++) {
      test = splitters[t];
      // Check for position > 0 in order for negative years to work
      if (dateFormat.indexOf(test) > 0) {
        res = test;
        break;
      }
    }
    return res;
  }

  // splitData avoids empty split parts
  private static splitDate(date, splitter) {
    const res = [];
    const split = date.split(splitter);
    for (let t = 0; t < split.length; t++) {
      if (split[t] && split[t] !== '0') {
        res.push(split[t]);
      } else {
        break;
      }
    }
    return res;
  }

  private static validateDateParts(dateParts) {
    const res = {
      valid: true,
      error: ''
    };

    if (dateParts.dayPart !== -1 && (dateParts.dayPart < 1 || dateParts.dayPart > 31)) {
      res.valid = false;
      res.error = 'TRANS__VALIDATION__INVALID_DAY_NUMBER';
    }

    if (dateParts.monthPart !== -1 && (dateParts.monthPart < 0 || dateParts.monthPart > 11)) {
      res.valid = false;
      res.error = 'TRANS__VALIDATION__INVALID_MONTH_NUMBER';
    }

    return res;
  }

  private static getDateFromDateParts(p) {
    const res = {date: undefined, valid: false, solrDate: null};
    try {
      res.date = new Date(p.yearPart, p.monthPart, p.dayPart, 0, 0, 0, 0);
      if (res.date.getTime()) {
        res.valid = true;
      } else {
        res.date = null;
      }
    } catch (e) {
      console.warn('Invalid date');
    }
    return res;
  }

  private static createDate(year, month, day, hour, minute, sec) {
    let res;
    if (year >= 0) {
      res = new Date(DateToolsService.isoDateStr(year, month, day, hour, minute, sec));
    } else {
      res = new Date(year, month - 1, day, hour, minute, sec, 0);
    }
    return res;
  }

  private static isoDateStr(year, month, day, hour, minute, sec) {
    return DateToolsService.numAsFixedStr(year, 4) + '-' +
      DateToolsService.numAsFixedStr(month, 2) + '-' +
      DateToolsService.numAsFixedStr(day, 2) + 'T' +
      DateToolsService.numAsFixedStr(hour, 2) + ':' +
      DateToolsService.numAsFixedStr(minute, 2) + ':' +
      DateToolsService.numAsFixedStr(sec, 2) + 'Z';
  }

  private static numAsFixedStr(num, digits) {
    const str = num.toString().split('-').pop();
    return '0000'.substr(0, digits - str.length) + str;
  }

  public precisionDateToDate(precisionDate, defaultDate?: Date): Date {
    let date = defaultDate;
    if (precisionDate && precisionDate[AConst.DD_DATE] !== undefined &&
      precisionDate[AConst.DD_DATE] !== null) {
      date = DateToolsService.getUtcDateFromTime(precisionDate[AConst.DD_DATE]);
    }
    return date;
  }

  public precisionDateToString(precisionDate) {
    let textDate = null;
    const date = this.precisionDateToDate(precisionDate);
    if (date) {
      if (precisionDate.dd_precision) {
        switch (precisionDate.dd_precision) {
          case 'full':
          case 'date':
          case 'datetime':
            textDate = this.getLocalStringDate(date);
            break;
          case 'month':
            textDate = this.getLocalStringDateYearMonth(date);
            break;
          case 'year':
            textDate = this.getLocalStringDateYear(date);
            break;
          default:
            console.error('Invalid precision: ' +
              precisionDate.dd_precision);
        }
      }
    }
    return textDate;
  }

  public getCompareDates(timeStamps) {
    const d = [];
    timeStamps.forEach((ts) => {
      const dFull = new Date(ts);
      d.push(new Date(dFull.getFullYear(), dFull.getMonth(),
        dFull.getDate()));
    });
    return d;
  }

  public validateDate(dateInfo) {
    let dateParts, splitChar, newDate, newPrecision, validate;
    if (typeof dateInfo === 'string') {
      if (!dateInfo) {
        return null;
      }
      splitChar = DateToolsService.getDateSplitter(dateInfo);

      if (this.hasIllegalDateChars(dateInfo, splitChar)) {
        return 'TRANS__VALIDATION__ILLEGAL_CHARACTERS_IN_DATE';
      }
      dateParts = this.getDateParts(dateInfo, true);
    } else if (typeof dateInfo === 'object' && dateInfo) {
      dateParts = {
        dayPart: dateInfo['day'],
        monthPart: dateInfo['month'] - 1,
        yearPart: dateInfo['year']
      };
    } else {
      console.warn('Unable to get date parts');
      return 'TRANS__VALIDATION__ILLEGAL_DATE';
    }
    validate = DateToolsService.validateDateParts(dateParts);
    if (!validate.valid) {
      return validate.error;
    }

    newPrecision = 'date';
    if (dateParts.dayPart !== -1 && dateParts.monthPart !== -1) {
      newPrecision = 'full';
      newDate = new Date(dateParts.yearPart, dateParts.monthPart,
        dateParts.dayPart, 15, 30, 0, 0);
    } else if (dateParts.monthPart !== -1) {
      newPrecision = 'month';
      newDate = new Date(dateParts.yearPart, dateParts.monthPart,
        1, 14, 30, 0, 0);
    } else {
      newPrecision = 'year';
      newDate = DateToolsService.createDate(dateParts.yearPart, 1, 1, 13, 30, 0);
    }

    return this.dateToPrecisionDate(newDate, newPrecision);
  }

  public dateToPrecisionDate(date, precision?) {
    precision = precision ? precision : 'full';
    const utcDate = DateToolsService.getUtcDate(date);
    return {
      'dd_date': utcDate.getTime(),
      'dd_precision': precision
    };
  }

  public solrDateToLocalStringDate(solrDate) {
    let res = '', datePart, date;
    if (solrDate) {
      datePart = solrDate.substring(0, 10);
      date = new Date(datePart);
      res = this.getLocalStringDate(date);
    }
    return res;
  }

  public localStringDateToSolrDate(stringDate) {
    let dateRes = {
      solrDate: '',
      date: null,
      valid: true
    };
    if (stringDate) {
      dateRes = this.getDateFromLocalStringDate(stringDate);
      if (dateRes.date) {
        dateRes.solrDate = moment(dateRes.date).format('YYYY-MM-DDTHH:mm:ss');
        if (dateRes.solrDate) {
          dateRes.solrDate += 'Z';
        } else {
          dateRes.valid = false;
          console.warn('Invalid date filtering');
        }
      }
    }
    return dateRes;
  }

  /**
   * If a field containing a precision date timestamp that is
   * incorrectly set according to the "dd_precision" value, the
   * timestamp will be changed when the field is opened, and the
   * application will incorrectly mark the object as changed.
   * @param fc field container containing the field value
   */
  public fixPrecisionDate(fc) {
    let strDate, vd;
    const pd = fc.parentModel[fc.propName];
    if (pd[AConst.DD_PRECISION] !== 'datetime' &&
      pd[AConst.DD_DATE] !== null) {
      strDate = this.precisionDateToString(pd);
      vd = this.validateDate(strDate);
      if (vd[AConst.DD_DATE] !== pd[AConst.DD_DATE]) {

        console.warn('Incorrect precision date: '
          + fc.propName + ' (' + pd[AConst.DD_PRECISION] +
          ') Was ' + new Date(pd[AConst.DD_DATE]) +
          ' should have been ' + new Date(vd[AConst.DD_DATE]));

        pd[AConst.DD_DATE] = vd[AConst.DD_DATE];
        pd[AConst.$$META][AConst.DD_DATE]._origVal = vd[AConst.DD_DATE];
      }
    }
  }

  public checkExpiredPrecisionDate(inline, date, statusType) {
    let res = false, currentDate;
    if (inline[AConst.CHECK_EXPIRED]) {
      currentDate = this.getTodayUtcTime();
      if (date && currentDate > date[AConst.DD_DATE] &&
        date.dd_precision === 'datetime') {
        if (statusType !== 'closed') {
          res = true;
        }
      }
    }
    return res;
  }

  public getTodayUtcTime() {
    return DateToolsService.getUtcDate(new Date()).getTime();
  }

  getDateFromLocalStringDate(localDate) {
    const p = this.getDateParts(localDate, false);
    return DateToolsService.getDateFromDateParts(p);
  }

  private hasIllegalDateChars(dateString, sep) {
    let hasIllegalChars = false, index;
    for (index = 0; index < dateString.length; index++) {
      if (!this.isLegalDateChar(dateString.charAt(index), sep)) {
        hasIllegalChars = true;
      }
    }
    return hasIllegalChars;
  }

  private isLegalDateChar(char, sep) {
    if (this.isNumeric(char) || char === '-') {
      return true;
    }
    return char === sep;
  }

  public isNumeric(char) {
    return /^\d+$/.test(char);
  }

  private getLocalStringDate(date) {
    return moment(date).format(
      this.settings.vc('LOCAL_DATE_FORMAT_FULL', 'DD.MM.YYYY'));
  }

  private getLocalStringDateYearMonth(date) {
    return moment(date).format(
      this.settings.vc('LOCAL_DATE_FORMAT_YEAR_MONTH', 'MM.YYYY'));
  }

  private getLocalStringDateYear(date) {
    let dateStr;
    const fullYear = date.getFullYear();
    if (fullYear >= 0) {
      dateStr = moment(date).format(this.settings.vc('LOCAL_DATE_FORMAT_YEAR', 'YYYY'));
    } else {
      dateStr = fullYear.toString();
    }
    return dateStr;
  }

  private getDatePartIndexes(dateFormat, dateSplitter) {
    let dayPartIndex = -1, monthPartIndex = -1, yearPartIndex = -1;
    dateFormat.split(dateSplitter).forEach(
      (dateFormatPart, index) => {
        if (dateFormatPart === 'DD') {
          dayPartIndex = index;
        } else if (dateFormatPart === 'MM') {
          monthPartIndex = index;
        } else if (dateFormatPart === 'YYYY') {
          yearPartIndex = index;
        }
      }
    );
    return {
      day: dayPartIndex,
      month: monthPartIndex,
      year: yearPartIndex
    };
  }

  getDateParts(localDate, setNullIfMissing) {
    let dayPart = 1, monthPart = 0, yearPart = 0;
    let localDateSplit, dpi, dateFormat;
    const dateSplitter = DateToolsService.getDateSplitter(localDate);
    if (setNullIfMissing) {
      dayPart = -1;
      monthPart = -1;
    }

    if (!dateSplitter) {
      yearPart = localDate;
    } else {
      localDateSplit = DateToolsService.splitDate(localDate, dateSplitter);
      if (localDateSplit.length === 1) {
        dateFormat = this.settings.vc('LOCAL_DATE_FORMAT_YEAR', 'YYYY');
      } else if (localDateSplit.length === 2) {
        dateFormat =
          this.settings.vc('LOCAL_DATE_FORMAT_YEAR_MONTH', 'MM.YYYY');
      } else {
        dateFormat = this.settings.vc('LOCAL_DATE_FORMAT_FULL', 'DD.MM.YYYY');
      }
      dpi = this.getDatePartIndexes(dateFormat, dateSplitter);
      yearPart = Number(localDateSplit[dpi.year]);
      if (dpi.month !== -1) {
        monthPart = Number(localDateSplit[dpi.month]) - 1;
      }
      if (dpi.day !== -1) {
        dayPart = Number(localDateSplit[dpi.day]);
      }
    }

    return {
      dayPart: dayPart,
      monthPart: monthPart,
      yearPart: yearPart
    };
  }
}
