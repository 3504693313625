import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageAnnotationComponent} from './image-annotation/image-annotation.component';
import {AnnotationCanvasComponent} from './annotation-canvas/annotation-canvas.component';
import {AnnotationDashboardComponent} from './annotation-dashboard/annotation-dashboard.component';
import {SharedModule} from '../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {AnnotationParentsComponent} from './annotation-dashboard/annotation-parents/annotation-parents.component';
import {AnnotationShapeMenuComponent} from './annotation-dashboard/annotation-shape-menu/annotation-shape-menu.component';
import {AnnotationColorMenuComponent} from './annotation-dashboard/annotation-color-menu/annotation-color-menu.component';
import {AnnotationDashboardButtonsComponent} from './annotation-dashboard/annotation-dashboard-buttons/annotation-dashboard-buttons.component';
import {AnnotationDescriptionComponent} from './annotation-dashboard/annotation-description/annotation-description.component';
import {AnnotationPointsComponent} from './annotation-dashboard/annotation-points/annotation-points.component';

@NgModule({
  declarations: [ImageAnnotationComponent, AnnotationCanvasComponent, AnnotationDashboardComponent, AnnotationParentsComponent, AnnotationShapeMenuComponent, AnnotationColorMenuComponent, AnnotationDashboardButtonsComponent, AnnotationDescriptionComponent, AnnotationPointsComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ],
  exports: [
    AnnotationCanvasComponent,
    AnnotationDashboardComponent
  ]
})
export class ImageAnnotationModule {
}
