import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ObjectStorageService} from './object-storage.service';
import {ModelObjectService} from './model-object.service';
import {SectionsContainer} from './sections-container';
import {ProgressDialogComponent} from '../shared/progress-dialog/progress-dialog.component';
import {AConst} from './a-const.enum';
import {ModelFactoryService} from './model-factory.service';
import {CmsApiService} from './cms-api.service';
import {CopyKeepService} from '../object-edit/copy-keep-checkbox/copy-keep.service';
import {ObjectRestrictionService} from './object-restriction.service';
import {ObjectViewAndData} from './object-view';

@Injectable({
  providedIn: 'root'
})
export class ObjectEditService {

  currentObject;
  objectNameField = {};

  constructor(private objectStorage: ObjectStorageService,
              private modelObjectService: ModelObjectService,
              private modalService: MatDialog,
              private modelFactory: ModelFactoryService,
              private cms: CmsApiService,
              private copyKeepService: CopyKeepService,
              private objectRestrictionService: ObjectRestrictionService) {
  }

  loadObjectGetSectionsContainer(objectId: string, templateGroupId: string): Promise<SectionsContainer> {
    return new Promise<SectionsContainer>((resolve, reject) => {
      this.loadObjectViewAndData(objectId, templateGroupId).then(
        objectViewAndData => {
          this.modelObjectService.getSectionsAndFormGroup(objectViewAndData.artifact, templateGroupId).then(sc => {
            this.currentObject = sc.rootObject;
            sc.objectView = objectViewAndData.view_data;
            resolve(sc);
          });
        },
        reason => {
          reject(reason);
        });
    });
  }

  copyObjectGetSectionsContainer(objectId: string, templateGroupId: string, targetModelType): Promise<SectionsContainer> {
    return new Promise<SectionsContainer>((resolve, reject) => {
      this.objectStorage.copyObject(objectId, templateGroupId, targetModelType).then(
        obj => {
          this.modelObjectService.getSectionsAndFormGroup(obj, templateGroupId).then(sc => {
            this.currentObject = sc.rootObject;
            resolve(sc);
          });
        },
        reason => {
          reject(reason);
        });
    });
  }

  loadObject(objectId: string, templateGroupId?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.objectStorage.loadObject(objectId, templateGroupId).then(
        obj => {
          this.objectRestrictionService.isObjectReadOnly(obj).then(readOnly => {
            obj.$$readOnly = readOnly;
            resolve(obj);
          });
        },
        reason => {
          reject(reason);
        });
    });
  }

  setObjectValuesStoreObject(sectionsContainer: SectionsContainer, reloadObject?: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (sectionsContainer.formGroup.valid) {
        this.modelObjectService.setObjectValuesFromForm(sectionsContainer.rootObject, sectionsContainer.formGroup);
        this.copyKeepService.removeNotKeep(sectionsContainer.rootObject);
        this.storeObjectShowProgressModal(sectionsContainer.rootObject, reloadObject).then(
          res => {
            resolve(res);
          },
          reason => {
            reject(reason);
          }
        );
      }
    });
  }

  storeObjectShowProgressModal(object, reloadObject?: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const progressModal = this.modalService.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
      this.objectStorage.storeObject(object).then(
        res => {
          progressModal.close();
          if (reloadObject) {
            const objId = res[AConst.ARTIFACT_ID];
            if (objId) {
              this.objectStorage.loadObject(res[AConst.ARTIFACT_ID]).then(obj => {
                resolve(obj);
              });
            } else {
              console.error('Re-loaded object had no artifact id!');
              resolve(res);
            }
          } else {
            resolve(res);
          }
        },
        reason => {
          progressModal.close();
          console.error('Store failed with message: ' + reason.message);
          reject(reason);
        }
      );
    });
  }

  deleteObjectShowDialog(object) {
    return new Promise(((resolve, reject) => {
      const progressModal = this.modalService.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
      this.objectStorage.deleteObject(object[AConst.ARTIFACT_ID]).then(
        () => {
          progressModal.close();
          resolve();
        },
        response => {
          progressModal.close(response.error.message);
          reject(response);
        }
      );
    }));
  }

  setObjectName(object, name: string) {
    const objType = object[AConst.OBJECT_TYPE];
    let names, res = name;
    let sep = '', revIndex;
    let nameFields = this.objectNameField[objType];
    if (nameFields === undefined) {
      nameFields = null;
      for (const fieldName in object[AConst.$$META]) {
        if (object[AConst.$$META].hasOwnProperty(fieldName)) {
          const meta = object[AConst.$$META][fieldName];
          const nameFieldNo = meta[AConst.NAME_FIELD];
          if (nameFieldNo !== undefined) {
            nameFields = nameFields || {};
            nameFields[nameFieldNo] = fieldName;
          }
        }
      }
      if (!nameFields && object[AConst.$$META].name) {
        nameFields = {'1': 'name'};
      }
      this.objectNameField[objType] = nameFields;
    }
    if (nameFields) {
      // Handles last name, first name for actors
      names = name ? name.split(',') : [];
      res = '';
      revIndex = names.length;
      names.forEach((nameItem, index) => {
        let nameField = nameFields[revIndex.toString()];
        if (nameField) {
          object[nameField] = nameItem;
        } else if (index > 0) {
          nameField = nameFields[index.toString()];
          object[nameField] += sep + nameItem;
        }
        res += sep + nameItem;
        sep = ', ';
        revIndex--;
      });
    }
    return res;
  }

  createModelItemGetSectionsContainer(modelName, data?): Promise<SectionsContainer> {
    return new Promise<SectionsContainer>(resolve => {
      this.createModelItem(modelName, data).then(item => {
        this.modelObjectService.getSectionsAndFormGroup(item).then(sectionsContainer => {
          resolve(sectionsContainer);
        });
      });
    });
  }

  createModelItemGetSectionsContainerForPrimeFields(modelName, data?): Promise<SectionsContainer> {
    return new Promise<SectionsContainer>(resolve => {
      this.createModelItem(modelName, data).then(item => {
        this.getSectionsContainerForObjectPrimeFields(item, modelName).then(sectionsContainer => {
          resolve(sectionsContainer);
        });
      });
    });
  }

  createModelItem(modelName, data?) {
    return new Promise(resolve => {
      this.modelFactory.createModelItemAsync(modelName, data).then(item => {
        this.setMapValues(item).then(res => {
          resolve(res);
        });
      });
    });
  }

  getSectionsContainerForObject(object, templateGroupId?, isCopy?): Promise<SectionsContainer> {
    return new Promise<SectionsContainer>(resolve => {
      this.modelObjectService.getSectionsAndFormGroup(object, templateGroupId, isCopy).then(sectionsContainer => {
        resolve(sectionsContainer);
      });
    });
  }

  getSectionsContainerForObjectPrimeFields(object, modelName?: string): Promise<SectionsContainer> {
    return new Promise(resolve => {
      this.getPrimeFields(modelName || object[AConst.OBJECT_TYPE]).then(fields => {
        this.modelObjectService.getFormGroup(object, fields, true).then(formGroup => {
          const res = new SectionsContainer();
          res.rootObject = object;
          res.primeFields = fields;
          res.formGroup = formGroup;
          resolve(res);
        });
      });
    });
  }

  /*
  setSectionFields(object, editFieldsOnly: boolean, requiredOnly: boolean) {
    const sections = object.$$meta.sections;
    if (sections) {
      sections.forEach(section => {
        section.fields = [];
        for (const fieldName in object.$$meta) {
          if (object.$$meta.hasOwnProperty(fieldName)) {
            const fieldMeta = object.$$meta[fieldName];
            if (fieldMeta) {
              const sectionName = fieldMeta.section;
              if (sectionName === section.name) {
                if ((!editFieldsOnly || fieldMeta.edit) && (!requiredOnly || fieldMeta.required)) {
                  section.fields.push(fieldName);
                }
              }
            }
          }
        }
      });
    }
  }*/

  resetSectionsContainerFormGroup(sectionsContainer: SectionsContainer) {
    return new Promise(resolve => {
      this.modelObjectService.setSectionsContainerFormGroup(sectionsContainer).then(() => {
        resolve();
      });
    });
  }

  // Useful for objects that have "root model" outside the regular
  // field container regime, in order to add items to the root model
  setTopModel(model, arrayName) {
    model[arrayName].forEach(item => {
      item.$$topModel = model;
      item.$$topModelsArrayName = arrayName;
    });
  }

  private setMapValues(modelObject) {
    return new Promise(resolve => {
      const searches = [];
      for (const fieldName in modelObject[AConst.$$META]) {
        if (modelObject[AConst.$$META].hasOwnProperty(fieldName)) {
          const fieldMeta = modelObject[AConst.$$META][fieldName];
          const fieldVal = modelObject[fieldName];
          if (fieldVal && fieldMeta[AConst.FIELD_TYPE] === 'map_id' && fieldMeta.display && fieldMeta.display.show === 'yes') {
            searches.push({
              fieldName: fieldName,
              searchParams: {
                filters: {
                  object_type: fieldMeta.reference[AConst.OBJECT_TYPE],
                  artifact_id: fieldVal
                }
              }
            });
          }
        }
      }
      if (searches.length) {
        let countDown = searches.length;
        searches.forEach(search => {
          this.cms.searchJson(search.searchParams).then(searchRes => {
            const artifacts = searchRes[AConst.ARTIFACTS];
            if (artifacts && artifacts.length) {
              modelObject[search.fieldName + '_value'] = artifacts[0][AConst.ARTIFACT_NAME];
            } else {
              console.warn('Could not find value searching for ' + JSON.stringify(search.searchParams));
            }
            if (!--countDown) {
              resolve(modelObject);
            }
          });
        });
      } else {
        resolve(modelObject);
      }
    });
  }

  private getPrimeFields(modelName): Promise<Array<any>> {
    return new Promise(resolve => {
      this.cms.getModelOverviewFields({
        modelName: modelName,
        type: 'prime'
      }).then(fields => {
        resolve(<Array<any>>fields);
      }).catch(reason => {
        console.warn('Unable to retrieve prime fields for model ' + modelName + ': ' + reason.error.message);
      });

    });
  }

  private loadObjectViewAndData(objectId: string, templateGroupId: string): Promise<ObjectViewAndData> {
    return new Promise<ObjectViewAndData>((resolve, reject) => {
      this.objectStorage.loadObjectWithViewData(objectId, templateGroupId).then(
        res => {
          this.objectRestrictionService.isObjectReadOnly(res.artifact).then(readOnly => {
            res.artifact.$$readOnly = readOnly;
            resolve(res);
          });
        },
        reason => {
          reject(reason);
        });
    });
  }

}
