import {Injectable} from '@angular/core';
import {NotificationService} from '../shared/notification.service';
import {CommonsService} from './commons.service';
import {FieldMetaHandlerService} from './field-meta-handler.service';

@Injectable({
  providedIn: 'root'
})
export class CmsErrorHandlerService {

  constructor(private notificationService: NotificationService,
              private commons: CommonsService,
              private fieldMetaHandler: FieldMetaHandlerService) {
  }

  errHandler(response, params) {
    // "response.error" is used for new Angular CmsService
    const data = response.data || response.error;
    const cmsMsg = data ? data.message : 'TRANS__CONNECTION__UNKNOWN_ERROR';
    const status = response.status;
    const clientMsg = params.clientMsg;
    const transValues = params.transValues;
    this.formatSaveError(data, clientMsg, cmsMsg, status).then(messages => {
      this.notificationService.addNotification({
        messages: messages,
        values: transValues,
        type: 'error',
        status: status
      });
    });

    console.warn('CMS function failed: ' + cmsMsg + ': ' + status);
  }

  private formatSaveError(obj, clientMsg, error, status) {
    return new Promise(resolve => {
      const errorTypes = {
        'error.not_string': {
          msg: 'TRANS__SERVER_ERROR__NOT_STRING'
        },
        'error.not_integer': {
          msg: 'TRANS__SERVER_ERROR__NOT_INTEGER'
        },
        'error.not_float': {
          msg: 'TRANS__SERVER_ERROR__NOT_FLOAT'
        },
        'error.not_date': {
          msg: 'TRANS__SERVER_ERROR__NOT_DATE'
        },
        'error.min_length': {
          msg: 'TRANS__SERVER_ERROR__MIN_LENGTH'
        },
        'error.max_length': {
          msg: 'TRANS__SERVER_ERROR__MAX_LENGTH'
        },
        'error.not_boolean': {
          msg: 'TRANS__SERVER_ERROR__NOT_BOOLEAN'
        },
        'error.required': {
          msg: 'TRANS__SERVER_ERROR__FIELD_REQUIRED'
        },
        'error.regexp': {
          msg: 'TRANS__SERVER_ERROR__FIELD_REGEXP'
        },
        'error.invalid_ct_val': {
          msg: 'TRANS__SERVER_ERROR__INVALID_LIST_VALUE'
        }
      };
      const msg = [];
      if (clientMsg) {
        msg.push(clientMsg);
        msg.push(': ');
      }
      // clientMsg = clientMsg || "TRANS__SERVER_ERROR__SAVE_FAILED";
      // clientMsg = clientMsg || "TRANS__SERVER_ERROR__SAVE_FAILED";
      // msg = [clientMsg, ": ", status];
      if (typeof error === 'string') {
        // msg.push(": ");
        msg.push(error);
      } else {
        for (const errTypeKey in errorTypes) {
          if (errorTypes.hasOwnProperty(errTypeKey)) {
            let title;
            const err = this.commons.findObjectContainingProp(error, errTypeKey);
            if (err) {
              msg.push(': ');
              msg.push(errorTypes[errTypeKey].msg);
              msg.push(': ');
              title = this.fieldMetaHandler.searchGetMetaProp(obj, err.itemName, 'title');
              if (title) {
                msg.push(title);
              } else {
                msg.push(err.itemName);
              }
            }
          }
        }
      }
      resolve(msg);
    });
  }

}
