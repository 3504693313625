export enum ReportMainTypes {
  adminEvent = 'admin-event',
  adminEventSearch = 'admin-event-search',
  artifact = 'artifact',
  simple = 'simple',
  searchViewAll = 'search-view-all'
}
export class ReportSettings {
  id: string;
  mainType: ReportMainTypes;
  objectType: string;
  metaType: string;
  collectionId: string;
  isSearchView: boolean;
  toc: boolean = true;
  titlePage: boolean = true;
  singleSided: boolean = false;
  largeImages: boolean = false;
  title = '';
  fullReport = { set: false };
  allSelectedReport = {set: false};
  belongingEvents = {set: false};
  reportTypes = {
    pdf: {
      type: 'pdf',
      title: 'TRANS__REPORT_MENU__MAKE_PDF_REPORT',
      selected: false
    },
    docx: {
      type: 'docx',
      title: 'TRANS__REPORT_MENU__MAKE_WORD_REPORT',
      selected: false
    }
  };
  reportTypeSelected: any;
  reportAdminEvents: Array<any> = [];

}
