import {Component, Input, OnInit} from '@angular/core';
import {ReportMenuService} from './report-menu.service';
import {ReportMainTypes, ReportSettings} from './report-settings';

@Component({
  selector: 'app-report-menu',
  templateUrl: './report-menu.component.html',
  styleUrls: ['./report-menu.component.scss']
})
export class ReportMenuComponent implements OnInit {

  @Input() mainType;
  @Input() objectType;
  @Input() collectionId;
  @Input() metaType;
  @Input() isSearchView;
  @Input() makeReportFn;

  constructor(private reportMenuService: ReportMenuService) {
  }

  reportSettings: ReportSettings;
  ReportMainTypes = ReportMainTypes;

  ngOnInit() {
    this.reportSettings = new ReportSettings();
    this.reportSettings.mainType = this.mainType;
    this.reportSettings.objectType = this.objectType;
    this.reportSettings.metaType = this.metaType;
    this.reportSettings.collectionId = this.collectionId;
    this.reportSettings.isSearchView = this.isSearchView;
    this.reportMenuService.setReportScope(this.reportSettings);


    this.reportMenuService.getReportMenusAdminEvent().then(menus => {
      this.reportSettings.reportAdminEvents = menus;
      this.reportSettings.reportTypeSelected = this.reportSettings.reportTypes.pdf;
      this.reportSettings.reportTypeSelected.selected = true;
    });
  }

  makeReport() {
    this.makeReportFn();
  }

  getReportTypes() {
    return Object.keys(this.reportSettings.reportTypes);
  }

  reportTypeSelect(reportType) {
    if (this.reportSettings.reportTypeSelected) {
      this.reportSettings.reportTypeSelected.selected = false;
    }
    this.reportSettings.reportTypeSelected = reportType;

    this.reportSettings.reportTypeSelected.selected = true;

    this.reportSettings.fullReport.set = true;
  }

}
