import {Component, Input, OnInit} from '@angular/core';
import {Annotation} from '../../annotation';

@Component({
  selector: 'app-annotation-color-menu',
  templateUrl: './annotation-color-menu.component.html',
  styleUrls: ['./annotation-color-menu.component.scss']
})
export class AnnotationColorMenuComponent implements OnInit {

  @Input() curAnn: Annotation;

  constructor() { }

  ngOnInit() {
  }

}
