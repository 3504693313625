import {Component, Input, OnChanges} from '@angular/core';
import {InlineArrayItemService} from '../../../core/inline-array-item.service';
import {SectionsContainer} from '../../../core/sections-container';
import {FieldParameters} from '../../../core/field-parameters';

@Component({
  selector: 'app-edit-field-inline-array-delete-button',
  templateUrl: './edit-field-inline-array-delete-button.component.html',
  styleUrls: ['./edit-field-inline-array-delete-button.component.scss']
})
export class EditFieldInlineArrayDeleteButtonComponent implements OnChanges {
  @Input() sectionsContainer: SectionsContainer;
  @Input() object;
  @Input() field;
  @Input() arrayIndex;
  @Input() parentIndex;
  @Input() checkUsage: boolean;

  confirm = false;
  private fieldParameters: FieldParameters;

  constructor(private inlineArrayItemSvc: InlineArrayItemService) { }

  ngOnChanges() {
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters.object = this.object;
    this.fieldParameters.field = this.field;
    this.fieldParameters.index = this.arrayIndex;
    this.fieldParameters.parentIndex = this.parentIndex;
  }

  toggleConfirm() {
    this.confirm = !this.confirm;
  }

  confirmDelete() {
    if (this.checkUsage) {

    } else {
      this.deleteItem();
    }

  }

  cancel() {
    this.confirm = false;
  }

  private deleteItem() {
    this.inlineArrayItemSvc.deleteInlineArrayItemFromForm(this.fieldParameters);
  }

}
