import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UiToolsService {
  windowSizeData = {
    width: window.innerWidth,
    height: window.innerHeight
  };
  windowListeners = [];
  clickListeners = [];
  clickIgnoreList = [];

  constructor() {
  }

  public getClientType() {
    const ua = window.navigator.userAgent;
    const isAndroid = ua.toLowerCase().indexOf('android') > -1;
    const res = {
      isIphone: false,
      isIpad: false,
      isApple: false,
      isAndroid: false,
      isComputer: false
    };

    const vend = window.navigator.vendor;
    if (vend !== null && vend.match(/Apple Computer, Inc./) &&
      (ua.match(/iPhone/i) || ua.match(/iPod/i))) {
      res.isIphone = true;
    } else if (vend !== null &&
      vend.match(/Apple Computer, Inc./) && ua.match(/iPad/i)) {
      res.isIpad = true;
    } else if (vend !== null && vend.match(/Apple Computer, Inc./) &&
      ua.indexOf('Safari') !== -1) {
      res.isApple = true;
    } else if (isAndroid) {
      res.isAndroid = true;
    } else {
      res.isComputer = true;
    }
    return res;
  }

  public get windowSize() {
    return this.windowSizeData;
  }

  public setMediumScreenSizeOnLoad(mediumScreen) {
    const windowSize = this.windowSize;
    if (mediumScreen === undefined) {
      mediumScreen = windowSize.width < 1025;
    }
    return mediumScreen;
  }

  public setWindowSize() {
    const oldData = {width: this.windowSize.width, height: this.windowSize.height};
    this.windowSizeData.width = window.innerWidth;
    this.windowSizeData.height = window.innerHeight;
    this.runListenerCallbacks(this.windowListeners, [], this.windowSizeData, oldData);
  }

  public addWindowSizeListener(callback) {
    return this.addListener(this.windowListeners, callback);
  }

  public removeWindowSizeListener(id) {
    this.removeListener(this.windowListeners, id);
  }

  public addDocumentClickListener(callback) {
    return this.addListener(this.clickListeners, callback);
  }

  public removeDocumentClickListener(id) {
    this.removeListener(this.clickListeners, id);
  }

  public registerDocumentClick(event) {
    this.runListenerCallbacks(this.clickListeners, this.clickIgnoreList, event);
  }

  public ignoreNextDocumentClick(id) {
    this.clickIgnoreList.push(id);
  }

  private addListener(listenerList, callback) {
    const id = 'ID:' + Math.random();
    listenerList.push({id: id, callback: callback});
    return id;
  }

  private removeListener(listenerList, id) {
    let index;
    listenerList.forEach((clickListener, ndx) => {
      if (clickListener.id === id) {
        index = ndx;
      }
    });
    if (index !== undefined) {
      listenerList.splice(index, 1);
    }
  }

  private runListenerCallbacks(listenerList, ignoreList, data?, oldData?) {
    listenerList.forEach(listener => {
      if (!this.ignoreCallback(listener, ignoreList)) {
        listener.callback(data, oldData);
      }
    });
  }

  private ignoreCallback(listener, ignoreList) {
    let res = false, ignoreIndex = 0;
    if (ignoreList.length) {
      ignoreList.forEach((ignoreId, index) => {
        if (ignoreId === listener.id) {
          ignoreIndex = index;
          res = true;
        }
      });
    }
    if (res) {
      this.clickIgnoreList.splice(ignoreIndex, 1);
    }
    return res;
  }
}
