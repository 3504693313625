import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-search-action-menu-report',
  templateUrl: './search-action-menu-report.component.html',
  styleUrls: ['./search-action-menu-report.component.scss']
})
export class SearchActionMenuReportComponent implements OnInit {

  @Input() reportContainer;

  constructor() { }

  ngOnInit() {
  }

}
