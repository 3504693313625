import {Component, Input, OnInit} from '@angular/core';
import {SearchContainer} from '../../search-container';
import {SearchHandlerService} from '../../search-handler.service';

@Component({
  selector: 'app-search-menu-toggle-restrictions',
  templateUrl: './search-menu-toggle-restrictions.component.html',
  styleUrls: ['./search-menu-toggle-restrictions.component.scss']
})
export class SearchMenuToggleRestrictionsComponent implements OnInit {

  @Input() searchContainer: SearchContainer;

  constructor(private searchHandler: SearchHandlerService) {
  }

  ngOnInit() {
  }

  runSearch() {
    this.searchHandler.runSearch(this.searchContainer).then();
  }

}
