import { Injectable } from '@angular/core';
import {AConst} from '../core/a-const.enum';
import {RefService} from '../core/ref.service';
import {ModelsService} from '../core/models.service';
import {CommonsService} from '../core/commons.service';
import {CmsApiService} from '../core/cms-api.service';
import {FieldValueService} from '../core/field-value.service';
import {FieldMetaService} from '../core/field-meta.service';
import {hasOwnProperty} from 'tslint/lib/utils';
import {InlineArrayHeaderContainer} from './inline-array-header-container';
import {InlineArrayItemService} from '../core/inline-array-item.service';
import {FieldParameters} from '../core/field-parameters';
import {SectionsContainer} from '../core/sections-container';

@Injectable({
  providedIn: 'root'
})
export class InlineArrayHeaderService {

  private showErrors = false;
  private cancelPromise;
  private errors = ['Set meta attribute to e.g. display=Dis(label=Lab(prefix=\':\u00A0\')) on these fields: '];

  constructor(private ref: RefService,
              private models: ModelsService,
              private commons: CommonsService,
              private cms: CmsApiService,
              private fieldValueService: FieldValueService,
              private fieldMetaService: FieldMetaService,
              private inlineArrayItemSvc: InlineArrayItemService) {
    this.commons.getUserData(false).then((userData) => {
      if (userData[AConst.NAME] === 'Primus') {
        this.showErrors = true;
      }
    });
  }

  createContainer(rootObject, field, arrayIndex, parentIndex): InlineArrayHeaderContainer {
    const container = new InlineArrayHeaderContainer();
    const fieldParameters = new FieldParameters();
    fieldParameters.sectionsContainer = new SectionsContainer();
    fieldParameters.sectionsContainer.rootObject = rootObject;
    fieldParameters.field = field;
    fieldParameters.index = arrayIndex;
    fieldParameters.parentIndex = parentIndex;
    const items = this.inlineArrayItemSvc.getArrayItems(fieldParameters);
    container.arrayItem = items[arrayIndex];
    const metaProps = this.getMetaProps(rootObject, field, container.arrayItem);
    container.labels = metaProps.labels;
    container.headlineFields = metaProps.headlineFields;
    this.setLabelIcon(container);

    return container;
  }

  setValues(headerContainer: InlineArrayHeaderContainer, repeatSetValueTime?) {
    let waitTime = repeatSetValueTime;
    if (!repeatSetValueTime) {
      waitTime = 100;
    }
    if (headerContainer.timeOutId) {
      clearTimeout(headerContainer.timeOutId);
    }
    headerContainer.timeOutId = setTimeout(() => {
      headerContainer.values = headerContainer.values || {};
      headerContainer.labels.forEach((label) => {
        let parentObject;
        parentObject = this.commons.getObjPropFromPath(headerContainer.arrayItem, label.path);
        headerContainer.values[label.key] = this.fieldValueService.getFieldTextValue(parentObject, label.name);
      });
      if (repeatSetValueTime) {
        this.setValues(headerContainer, repeatSetValueTime);
      }
    }, waitTime);
  }

  getLabelFields(rootItem, item, parentMeta?) {
    let sortMeta, labelFields = [];
    if (item) {
      sortMeta = this.getSortedMetaData(item);
      sortMeta.forEach((mi) => {
        const type = this.getLabelType(mi);
        if (type === 'inline') {
          labelFields = labelFields.concat(
            this.getSubLabelFields(rootItem, mi));
        } else {
          labelFields.push(this.getLabelProps(rootItem,
            mi, parentMeta));
        }
      });
    } else {
      console.warn('Label item not set');
    }
    if (this.showErrors) {
      this.checkMissingPrefixes(rootItem, labelFields);
    }
    return labelFields;
  }

  private getMetaProps(parentObject, field, item) {
    const res = {
      labels: [],
      headlineFields: []
    };
    const pMeta = this.fieldMetaService.getFieldMetaData(parentObject, field);
    if (!item['$$label'] && this.fieldMetaService.checkSetMetaData(item)) {
      res.labels = this.getLabelFields(item, item);
    }
    return res;
  }

  private setLabelIcon(container: InlineArrayHeaderContainer) {
    container.labels.forEach((label) => {
      const icon = label.icon;
      let artifactId: string;
      if (icon) {
        if (icon === '{conceptIcon}') {
          artifactId = container.arrayItem[label.key];
          if (artifactId) {
            this.cms.searchJson({
              filters: {
                artifact_id: artifactId,
                object_type: artifactId.split('-')[0]
              }
            }).then((res) => {
              let art;
              if (res[AConst.ARTIFACTS]) {
                art = res[AConst.ARTIFACTS][0];
                container.arrayItem.icon = art.icon;
                container.arrayItem.icon_frame = art[AConst.ICON_FRAME];
              }
            });
          }
        } else {
          container.arrayItem.icon = label.icon;
        }
      }
    });
  }

  private getLabelProps(rootItem, mi, parentMeta?) {
    const label = mi.display ? this.commons.copy(mi.display.label) || {} : {};
    let pLabel, sRef;
    const labelVal = rootItem[mi.name];
    const reference = mi.reference;
    const objectType = reference && reference[AConst.OBJECT_TYPE] || '';
    label.type = this.getLabelType(mi);
    label.title = mi.title;
    label.name = mi.name;
    label.key = mi.name;
    label.path = '';
    if (labelVal && mi[AConst.FIELD_TYPE] === AConst.MAP_ID &&
      objectType.indexOf('ct_') !== 0) {
      sRef = this.ref.makeRef({
        object: {
          artifact_id: labelVal
        }
      });
      if (sRef) {
        label.sRef = sRef;
        label.sRef.param.reload = true;
      }
    }

    if (parentMeta) {
      label.key = parentMeta.name + '.' + label.key;
      pLabel = parentMeta.display ? parentMeta.display.label || {} : {};
      label.title = label.title || parentMeta[AConst.FIELD_TITLE];
      label.prefix = label.prefix || pLabel.prefix;
      label.append = label.append || pLabel.append;
      label[AConst.CSS_CLASS_EDIT] = label[AConst.CSS_CLASS_EDIT] || pLabel[AConst.CSS_CLASS_EDIT];
      label[AConst.CSS_CLASS_VIEW] = label[AConst.CSS_CLASS_VIEW] || pLabel[AConst.CSS_CLASS_VIEW];
      label.path = parentMeta.name;
    }
    return label;
  }

  private checkMissingPrefixes(rootItem, labelFields) {
    const origErrLen = this.errors.length;
    labelFields.forEach((label, index) => {
      let error;
      if (!label.prefix && index > 0 && !labelFields[index - 1].append) {
        error = rootItem[AConst.OBJECT_TYPE] + ': ' + label.key;
        if (this.errors.indexOf(error) === -1) {
          this.errors.push(error);
        }
      }
    });
    if (this.errors.length > origErrLen) {
      if (this.cancelPromise) {
        clearTimeout(this.cancelPromise);
      }
      this.cancelPromise = setTimeout(() => {
        let errStr = '';
        this.errors.forEach((error) => {
          errStr += error + '\n';
        });
        console.warn(errStr);
        // window.alert(errStr);
      }, 4000);
    }
  }

  private getSubLabelFields(rootItem, parentMeta) {
    const model = this.models.getModel(this.getInlineModel(parentMeta));
    return this.getLabelFields(rootItem, model, parentMeta);
  }

  private getInlineModel(meta) {
    return meta[AConst.INLINE] ? meta[AConst.INLINE].model : null;
  }

  private getLabelType(meta) {
    let fieldType = meta[AConst.FIELD_TYPE];
    const inlineModel = this.getInlineModel(meta);
    if (inlineModel === 'PrecisionDate') {
      fieldType = 'precision_date';
    }
    if (inlineModel === 'Timespan') {
      fieldType = 'timespan';
    }
    return fieldType;
  }

  private getSortedMetaData(item) {
    const meta = item.$$meta, metaArr = [];
    if (meta) {
      for (const metaFieldName in meta) {
        if (hasOwnProperty(meta, metaFieldName)) {
          const mi = meta[metaFieldName];
          const show = mi.display ? mi.display.show : null;
          if (show === 'yes' && mi[AConst.FIELD_TYPE] !== 'array') {
            metaArr.push(mi);
          }
        }
      }
    } else {
      // throw "No meta data found for item";
    }
    return this.commons.orderArray(metaArr, 'sub_order');
  }

}
