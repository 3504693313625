import {Component, Input, OnInit} from '@angular/core';
import {FieldValues} from '../../core/object-view';

@Component({
  selector: 'app-object-view-field-values',
  templateUrl: './object-view-field-values.component.html',
  styleUrls: ['./object-view-field-values.component.scss']
})
export class ObjectViewFieldValuesComponent implements OnInit {

  @Input() fieldValues: FieldValues;
  @Input() edit: boolean;

  constructor() { }

  ngOnInit() {
  }

}
