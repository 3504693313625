import {Component, OnDestroy, OnInit} from '@angular/core';
import {StateService, UIRouterGlobals} from '@uirouter/core';
import {SearchHandlerService} from '../../object-search/search-handler.service';
import {CmsApiService} from '../../core/cms-api.service';
import {CommonsService} from '../../core/commons.service';
import {DateToolsService} from '../../core/date-tools.service';
import {AConst} from '../../core/a-const.enum';
import {SearchContainer} from '../../object-search/search-container';
import {UiToolsService} from '../../core/ui-tools.service';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit, OnDestroy {

  AConst = AConst;
  adminType;
  parent;
  adminModelMenus;
  visibleFq = {};
  searchContainer: SearchContainer;
  adminConceptsFq;
  private currentMenu;
  private lastMainMenu;
  private clickListenerId;

  constructor(private stateService: StateService,
              private uiRouterGlobals: UIRouterGlobals,
              private searchHandler: SearchHandlerService,
              private cms: CmsApiService,
              private commons: CommonsService,
              private dateTools: DateToolsService,
              private uiTools: UiToolsService) {
  }

  ngOnInit() {
    this.adminType = this.uiRouterGlobals.params['admin_type'];
    this.cms.getAdminPageMenus({adminType: this.adminType}).then(
      data => {
        this.adminModelMenus = data;
        this.adminModelMenus.menus = this.commons.orderArray(this.adminModelMenus.menus, 'order');
        this.adminModelMenus.menus.forEach(mainMenu => {
          mainMenu[AConst.SUB_MENUS] = this.commons.orderArray(mainMenu[AConst.SUB_MENUS], 'menu_title');
        });
      });
    this.setParent();
    this.getFaq(this.adminType);

    if (this.adminType === 'create-new') {
      this.parent.subMenus = false;
      this.getlastCreatedItems().then(() => {
        this.setSearchResultItemsDate();
      });
    }

    this.clickListenerId = this.uiTools.addDocumentClickListener(() => {
      if (this.currentMenu) {
        this.currentMenu.toggle = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.uiTools.removeDocumentClickListener(this.clickListenerId);
  }

  toggleFq(index) {
    this.visibleFq[index] = !this.visibleFq[index];
  }

  toggleTooltip(menu, mainMenu) {
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    if (this.lastMainMenu && this.lastMainMenu !== mainMenu) {
      this.setSelectedMenu(this.lastMainMenu, null);
      this.setSelectedMenu(mainMenu, menu);
    } else {
      this.setSelectedMenu(mainMenu, menu);
    }
    this.currentMenu = menu;
    this.lastMainMenu = mainMenu;
  }

  private setParent() {
    this.parent = {
      path: 'home.primus.admin',
      adminType: this.adminType,
      subMenus: true,
      target_params: {admin_type: this.adminType},
      currentState: this.stateService.$current.name
    };
  }


  private setSelectedMenu(mainMenu, menu) {
    mainMenu.forEach(value => {
      if (menu && value[AConst.MENU_TITLE] === menu[AConst.MENU_TITLE]) {
        value.toggle = !value.toggle;
      } else {
        value.toggle = false;
      }
    });
  }

  private setSearchResultItemsDate() {
    if (this.searchContainer.searchResult) {
      this.searchContainer.searchResult[AConst.ARTIFACTS].forEach(item => {
        const date = item[AConst.CREATED_AT];
        item.date = this.dateTools.precisionDateToString(date);
      });
    }
  }

  private getlastCreatedItems() {
    return new Promise(resolve => {
      this.searchHandler.createSearchContainer(
        {
          searchViewName: 'content_last_created',
          stateParams: this.uiRouterGlobals.params,
          rows: {'list': 5},
          runSearch: false
        }).then(sc => {
        this.searchContainer = sc;
        this.searchContainer.searchResultViewName = 'list';
        this.searchContainer.searchResultViews.default = 'list';
        this.searchContainer.order = 'created_at desc';
        this.searchHandler.runSearch(this.searchContainer).then(() => {
          resolve();
        });
      });

    });
  }

  private getFaq(category) {
    this.cms.getFaq({category: category}).then(
      data => {
        this.adminConceptsFq = data[AConst.FAQ];
      },
      function (failed) {
        console.error('This failed! ' + failed.message);
      }
    );
  }

}
