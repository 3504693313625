import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {CmsApiService} from '../../core/cms-api.service';
import {CommonsService} from '../../core/commons.service';
import {SettingsService} from '../../core/settings.service';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit {
  AConst = AConst;
  toggleMenu1 = false;
  toggleMenu2 = false;
  firstSelectMenu = [];
  currentFirstMenu = {};
  currentSecondMenu = {};

  @Input() parent;

  constructor(private cms: CmsApiService,
              private commons: CommonsService,
              private settings: SettingsService) {
  }

  ngOnInit() {
    let parentMenu;
    if (!this.parent.subMenus) {
      this.parent.path = 'home.primus.search';
      this.parent.target_params = {
        targetId: 'none',
        objectCount: 0
      };
    }

    this.cms.getMainMenu().then((data) => {
        parentMenu = data;
        if (parentMenu) {

          this.settings.isSitulaEnabled().then( situlaEnabled => {
            if (!situlaEnabled) {
              parentMenu.filter(m => {
                if (m.name === 'admin' && m.sub_menus) {
                    m.sub_menus.filter( (sm, ix) => {
                      if (sm.name === 'importSitula' && !situlaEnabled) {
                        m.sub_menus.splice(ix, 1);
                      }
                    });
                }
              });
            }

            this.setFirstSelectMenu(parentMenu);
            this.setCurrentFirstSelectMenu(this.firstSelectMenu[AConst.SUB_MENUS]);
            if (this.parent[AConst.MENUS]) {
              const menu = this.parent.menus[AConst.SUB_MENUS];
              this.setCurrentSecondMenu(menu);
              this.commons.sortArray(menu, 'menu_title');
            }

          }); // isSitulaEnabled

        }
      }
    );
  }

  toggleBreadcrumbsMenu(buttonName) {
    if (buttonName === 'button1') {
      this.toggleMenu1 = !this.toggleMenu1;
      this.toggleMenu2 = false;
    } else if (buttonName === 'button2') {
      this.toggleMenu2 = !this.toggleMenu2;
      this.toggleMenu1 = false;
    }
    if (this.toggleMenu1 === true ||
      this.toggleMenu2 === true) {
      document.addEventListener('mouseup',
        this.callbackBreadcrumbsMenu.bind(this), true);

    } else {
      this.stopCallBack.bind(this);
    }
  }

  stopCallBack(): void {
    document.removeEventListener('mouseup',
      this.callbackBreadcrumbsMenu, true);
  }

  callbackBreadcrumbsMenu(item) {
    const found = this.commons.findClassNameRecursively(
      item.target, 'dropdownIgnoreClicks');
    if (!found) {
      this.toggleMenu1 = false;
      this.toggleMenu2 = false;
      this.stopCallBack.bind(this);
    }
  }

  setFirstSelectMenu(parentMenu) {
    if (parentMenu) {
      parentMenu.forEach((menu) => {
        const menuType = menu[AConst.TARGET_PARAMS];
        if (menuType && menuType['admin_type'] === 'admin') {
          this.firstSelectMenu = menu;
          this.commons.sortArray(this.firstSelectMenu[AConst.SUB_MENUS], 'order');
        }
      });
    }
  }

  setCurrentFirstSelectMenu(menus) {
    if (menus) {
      menus.forEach((menu) => {
        if (menu[AConst.TARGET_STATE] === this.parent.currentState ||
          menu[AConst.TARGET_STATE] === this.parent.path) {
          this.currentFirstMenu = menu;
          if (menu[AConst.MENUS] &&
            !this.parent[AConst.MENUS] &&
            this.parent.currentChildPath !== '') {
            this.parent[AConst.MENUS] = {
              menu_title: menu.title,
              sub_menus: menu[AConst.MENUS]};
          }
        }
      });
    }
  }

  setCurrentSecondMenu(menus) {
    if (menus && this.parent.currentChildPath !== '') {
      menus.forEach((menu) => {
        if (!menu[AConst.MENU_TITLE] && menu[AConst.TITLE]) {
          menu[AConst.MENU_TITLE] = menu[AConst.TITLE];
        }
        if (!menu[AConst.TARGET_STATE]) {
          menu[AConst.TARGET_STATE] = this.currentFirstMenu['target_state'];
        }
        if ((menu[AConst.TARGET_PARAMS].path &&
          menu[AConst.TARGET_PARAMS].path.indexOf(this.parent.currentChildPath) > -1 ) ||
          menu[AConst.TARGET_PARAMS].typeFilter === this.parent.currentChildPath) {
          this.currentSecondMenu = menu;
        }
      });
    }
  }
}
