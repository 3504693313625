import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {OptionsService} from '../../../core/options.service';
import {CommonsService} from '../../../core/commons.service';
import {HierarchicNode} from "../hierarchic-node";

export class HierarchyRow {
  selectedNode = -1;
  selectedChildNode = -1;
  selectedChild = -1;
  level = 1;
}

@Component({
  selector: 'app-edit-field-select-hierarchy',
  templateUrl: './edit-field-select-hierarchy.component.html',
  styleUrls: ['./edit-field-select-hierarchy.component.scss']
})
export class EditFieldSelectHierarchyComponent implements OnChanges {
  @Input() field;
  @Input() query;
  @Input() useQuery;
  @Input() selectedOptionId;
  @Output() optionSelected = new EventEmitter<object>();
  @Output() optionUnChecked = new EventEmitter<object>();
  @Output() keyEventsInHierarchy = new EventEmitter<object>();
  @ViewChild('focusHierarchyOption', {static: true}) focusHierarchyOption: ElementRef;

  nodes = [];
  AConst = AConst;
  rootNode = {
    option: {
      level: 0
    },
    childNodes: []
  } as HierarchicNode;
  objectType;
  isArray = false;
  selectedOpened = false;
  loading = false;
  rowHeight = 26;
  lastPage;
  maxRows = 30;
  timeOut = null;
  selectedRow: HierarchyRow = new HierarchyRow();


  constructor(private optionsService: OptionsService,
              private commons: CommonsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isArray = Array.isArray(this.selectedOptionId);
    this.objectType = this.field.reference[AConst.OBJECT_TYPE];
    this.loading = true;
    this.getRootNodes().then(nodes => {
      this.loading = false;
      this.nodes = nodes;
      this.setOpenNodeOnSearchResult(this.nodes);
      if (!this.isArray && this.selectedOptionId && !this.selectedOpened) {
        this.selectedOpened = true;
        this.openSelectedNode().then();
      }
    });
  }

  private setOpenNodeOnSearchResult(nodes) {
    if (nodes && (this.query !== undefined && this.query !== '')) {
      nodes.forEach(node => {
        node.$$open = node.$$count !== 0;
      });
    }
  }

  setNodeToFocusFromInput() {
    let focusNode;
    this.selectedRow = {
      selectedNode: 0,
      selectedChildNode: -1,
      selectedChild: -1,
      level: 1
    };
    if (this.nodes.length > 0) {
      focusNode = this.nodes[this.selectedRow.selectedNode].option.id;
    }
    setTimeout(() => {
      if (focusNode) {
        document.getElementById(focusNode).focus();
      }
    }, 100);
  }

  setNodeToFocus(node) {
    setTimeout(() => {
      if (node) {
        document.getElementById(node).focus();
      }
    }, 100);
  }

  setChildOptionsToFocus(level, id) {
    setTimeout(() => {
      document.getElementById('child' + level + '-' + id).focus();
    }, 100);
  }

  setShowAllOptionsButtonToFocus(level) {
    setTimeout(() => {
      document.getElementById('showAllChildren-' + level).focus();
    }, 100);
  }

  setNextChildrenButtonToFocus(level) {
    setTimeout(() => {
      document.getElementById('nextChildren-' + level).focus();
    }, 100);
  }

  openNode(node) {
    node.$$open = !node.$$open;
  }

  onChildSelected(child) {
    this.optionSelected.emit(child);
  }

  onChildUnselected(child) {
    this.optionUnChecked.emit(child);
  }

  onsetMaxRow(maxRow) {
    this.maxRows = maxRow;
    const node = this.nodes[this.selectedRow.selectedNode];
    this.selectedRow.selectedChild--;
    if (this.selectedRow.level === 1) {
      this.nextChildrenOption(
        this.rootNode.children,
        this.selectedRow.selectedChild,
        this.rootNode.children[0].level);
    } else if (this.selectedRow.level === 2) {
      this.nextChildrenOption(
        node.children,
        this.selectedRow.selectedChild,
        node.children[this.selectedRow.selectedChild].level);
    } else if (this.selectedRow.level === 3) {
      const childNode = node.childNodes[this.selectedRow.selectedChildNode];
      this.nextChildrenOption(
        childNode.children,
        this.selectedRow.selectedChild,
        childNode.children[this.selectedRow.selectedChild].level);
    }
  }

  nextPreviousRow(node, select, children, increase) {
    const visibleNode = node.find(item => item.$$count || item.option.level === 1);
    if (node.length > 0 && visibleNode) {
      let lastVisibleNodeIndex;
      if (node[0].option.level === 1) {
        lastVisibleNodeIndex = node.length - 1;
      } else {
        const reversedNodeArray = node.concat([]);
        const lastVisibleNode = reversedNodeArray.reverse().find(item => item.$$count);
        lastVisibleNodeIndex = node.indexOf(lastVisibleNode);
      }
      if (select > lastVisibleNodeIndex) {
        if (children.length > 0) {
          if (increase) {
            this.nextChildrenOption(
              children,
              this.selectedRow.selectedChild,
              children[0].level);
          } else {
            this.arrowUpChildren(
              node[lastVisibleNodeIndex].option.level,
              node[lastVisibleNodeIndex].option.id,
              children, true, lastVisibleNodeIndex);
          }
        }
      } else {
        this.selectedRow.level = node[lastVisibleNodeIndex].option.level;
        if (increase) {
          const lastNode = lastVisibleNodeIndex === select;
          if (lastNode) {
            if (children.length > 0) { // if children
              if (this.selectedRow.level === 1) {
                this.selectedRow.selectedNode = lastVisibleNodeIndex + 1;
              }
              if (this.selectedRow.level === 2) {
                this.selectedRow.selectedChildNode = lastVisibleNodeIndex + 1;
              }
              this.nextChildrenOption(
                children,
                this.selectedRow.selectedChild,
                children[0].level);
            }
          } else {
            this.increaseNodeRow(select, node);
          }
        } else {
          this.decreaseNodeRow(select, node);
        }
      }
    } else {
      if (children.length > 0) {
        if (increase) {
          this.nextChildrenOption(
            children,
            this.selectedRow.selectedChild,
            children[0].level);
        } else {
          const nodeId = children[0].id;
          this.arrowUpChildren(
            this.selectedRow.level,
            nodeId,
            children, false);
        }
      }
    }
  }

  decreaseNodeRow(select, node) {
    let rows, currentNodeChildRow;
    if (select > 0) {
      select--;
    }
    if (this.selectedRow.level === 1) {
      rows = (select + 1) * this.rowHeight;
      this.selectedRow.selectedNode = select;
    }
    if (this.selectedRow.level === 2) {
      if (this.query !== undefined && this.query !== '') {
        const reversedNodeArray = node.concat([]);
        const previousNode =
          reversedNodeArray.reverse().find(item => item.$$count && (select + 1) > node.indexOf(item));
        select = previousNode ? node.indexOf(previousNode) : (select + 1);
      }
      currentNodeChildRow = select + this.selectedRow.selectedNode;
      rows = (currentNodeChildRow + 2) * this.rowHeight;
      this.selectedRow.selectedChildNode = select;
    }
    if (this.selectedRow.level === 3) {
      // N/A
    }
    this.scrollWithKey(rows);
    if (select <= node.length - 2) {
      this.setNodeToFocus(node[select].option.id);
    }
  }

  increaseNodeRow(select, node) {
    let rows, currentNodeChildRow;

    if (select < node.length - 1) {
      select++;
    }
    if (this.selectedRow.level === 1) {
      rows = (select + 1) * this.rowHeight;
      this.selectedRow.selectedNode = select;
    } else if (this.selectedRow.level === 2) {
      if (this.query !== undefined && this.query !== '') {
        const nextNode = node.find(item => item.$$count && (select - 1) < node.indexOf(item));
        if (nextNode) {
          select = node.indexOf(nextNode);
        }
      }
      currentNodeChildRow = select + this.selectedRow.selectedNode;
      rows = (currentNodeChildRow + 2) * this.rowHeight;
      this.selectedRow.selectedChildNode = select;
    } else if (this.selectedRow.level === 3) {
      // N/A
    }
    this.scrollWithKey(rows);
    if (select <= node.length - 1) {
      this.setNodeToFocus(node[select].option.id);
    }
  }


  nextChildrenOption(childrenArray, select, level) {
    const lastChild = select + 1 > childrenArray.length - 1;
    if (select > (this.maxRows - 2)) {
      if (childrenArray.length > this.maxRows) {
        this.selectedRow.selectedChild++;
        this.setShowAllOptionsButtonToFocus(this.selectedRow.level);
      }
    } else {
      if (!lastChild) {
        this.arrowDownChildren(level, childrenArray);
      }
    }
  }

  arrowDownChildren(level, children) {
    let id;
    if (this.selectedRow.selectedChild === -1) {
      this.selectedRow.level = level;
      this.selectedRow.selectedChild = 0;
      id = children[this.selectedRow.selectedChild].id;
      this.setChildOptionsToFocus(this.selectedRow.level, id);

    } else {
      this.selectedRow.selectedChild++;
      id = children[this.selectedRow.selectedChild].id;
      this.setChildOptionsToFocus(this.selectedRow.level, id);
    }
  }

  arrowUpChildren(level, nodeId, children, hasNodeSiblings, lastVisibleItemIndex?) {
    if (this.selectedRow.selectedChild === 0) {
      if (hasNodeSiblings) {
        if (level === 1) {
          this.selectedRow.selectedNode = lastVisibleItemIndex;
        }
        if (level === 2) {
          this.selectedRow.selectedChildNode = lastVisibleItemIndex;
        }
        this.setNodeToFocus(nodeId);
        this.selectedRow.selectedChild = -1;
        this.selectedRow.level = level;
      }
    } else {
      this.selectedRow.selectedChild--;
      const id = children[this.selectedRow.selectedChild].id;
      this.setChildOptionsToFocus(this.selectedRow.level, id);
    }
  }

  keyEventArrowUp(node, childNode, level) {
    if (level === 1) {
      if (this.selectedRow.selectedNode === 0) { // set input to focus
        this.selectedRow.selectedNode = -1;
        this.keyEventsInHierarchy.emit({
          row: false,
          setFocus: true,
          tabbing: false
        });
      } else {
        this.nextPreviousRow(
          this.nodes,
          this.selectedRow.selectedNode,
          this.rootNode['children'], false);
      }
    } else if (level === 2) {
      if (node.$$open) {
        this.nextPreviousRow(
          node.childNodes,
          this.selectedRow.selectedChildNode,
          node.children, false);

      }
    } else if (level === 3) {
      if (node.$$open) {
        this.nextPreviousRow(
          childNode.childNodes,
          this.selectedRow.selectedChild,
          childNode.children, false);
      }
    }
  }

  keyEventArrowDown(node, childNode, level) {
    if (level === 1) {
      this.nextPreviousRow(
        this.nodes,
        this.selectedRow.selectedNode,
        this.rootNode['children'], true);

    } else if (level === 2) {
      if (node.$$open) {
        this.nextPreviousRow(
          node.childNodes,
          this.selectedRow.selectedChildNode,
          node.children, true);
      }
    } else if (level === 3) {
      if (node.$$open) {
        this.nextPreviousRow(
          childNode.childNodes,
          this.selectedRow.selectedChild,
          childNode.children, true);
      }
    }
  }

  selectHierarchyWithKey(evt, timeout?) {
    evt = evt || window.event;
    let node, childNode;
    const level = this.selectedRow.level;
    if (this.selectedRow.selectedNode > 0) {
      node = this.nodes[this.selectedRow.selectedNode];
    } else {
      node = this.nodes[0];
    }
    if (this.selectedRow.selectedChildNode > -1) {
      childNode = node.childNodes[this.selectedRow.selectedChildNode];
    }
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = 0;
    }
    switch (evt.key) {
      case 'Tab':
        this.keyEventsInHierarchy.emit({
          row: false,
          setFocus: false,
          tabbing: true
        });
        break;
      case 'ArrowUp':
        this.timeOut = setTimeout(() => {
          this.keyEventArrowUp(node, childNode, level);
        }, timeout ? timeout : 50);
        evt.preventDefault();
        break;
      case 'ArrowDown':
        this.timeOut = setTimeout(() => {
          this.keyEventArrowDown(node, childNode, level);
        }, timeout ? timeout : 50);
        evt.preventDefault();
        break;
      case 'ArrowLeft':
        if (this.selectedRow.selectedNode > this.nodes.length) {
          // N/A
        } else {
          if (this.selectedRow.selectedChild < this.maxRows) {
            if (level === 2) {
              this.closeNodeLevel(node, node.option.level);
              this.selectedRow.selectedChildNode = -1;
            } else if (level === 3) {
              this.closeNodeLevel(childNode, childNode.option.level);
            }
          } else {
            this.setShowAllOptionsButtonToFocus(this.selectedRow.level);
          }

        }
        break;
      case 'ArrowRight':
        if (this.selectedRow.selectedNode > this.nodes.length) {
          // N/A
        } else {
          if (this.selectedRow.selectedChild < this.maxRows) {
            if (node) {
              node.$$open = true;
              if (level > 1) {
                if (childNode) {
                  childNode.$$open = true;
                  if (childNode.childNodes.length > 0) { // Node
                    this.selectedRow.level = childNode.childNodes[0].option.level;
                  } else {
                    if (childNode.children.length > 0) { // child
                      this.selectedRow.level = childNode.children[0].level;
                      this.selectedRow.selectedChild = -1;
                      this.arrowDownChildren(this.selectedRow.level, childNode.children);
                    }
                  }
                }
              } else {
                let firstNode;
                if (node.childNodes.length > 0) { // Node
                  firstNode = node.childNodes.find(item => item.$$count);
                }
                if (firstNode) {
                  this.selectedRow.selectedChildNode = node.childNodes.indexOf(firstNode);
                  this.selectedRow.level = node.childNodes[this.selectedRow.selectedChildNode].option.level;
                  this.setNodeToFocus(node.childNodes[this.selectedRow.selectedChildNode].option.id);
                } else {
                  if (node.children.length > 0) { // child
                    this.selectedRow.level = node.children[0].level;
                    this.selectedRow.selectedChild = -1;
                    this.arrowDownChildren(this.selectedRow.level, node.children);
                  }
                }
              }
            }
          } else {
            this.setNextChildrenButtonToFocus(this.selectedRow.level);
          }
        }
        break;
      case 'i':
        if (level === 1) {
          this.description(this.rootNode);
        }
        if (level === 2) {
          this.description(node);
        }
        if (level === 3) {
          this.description(node.childNodes);
        }
        break;
      case 'Escape':
      case 'Esc':
        this.keyEventsInHierarchy.emit({
          row: false,
          setFocus: true,
          tabbing: false
        });
        break;
      case ' ':
        evt.preventDefault();
        break;
    }
  }


  description(node) {
    let selectedNode, selectedChild;
    if (this.selectedRow.level === 1) {
      selectedNode = this.selectedRow.selectedNode;
    } else {
      selectedNode = this.selectedRow.selectedChildNode;
    }
    if (node.childNodes.length > 0) { // if list has nodes
      if (selectedNode > (node.childNodes.length - 1)) { // end of node
        selectedChild = this.selectedRow.selectedChild;
        if (node.children[selectedChild].$$description) {
          this.openDescription(node.children[selectedChild]);
        }
      } else {
        if (node.childNodes[selectedNode] && node.childNodes[selectedNode].option.$$description) {
          this.openDescription(node.childNodes[selectedNode].option);
        }
      }
    } else { // If list has children
      if (node.children && node.children[this.selectedRow.selectedChild] &&
        node.children[this.selectedRow.selectedChild].$$description) {
        this.openDescription(node.children[this.selectedRow.selectedChild]);
      }
    }
  }

  openDescription(node) {
    this.optionsService.toggleDescription(node);
  }

  closeNodeLevel(node, level) {
    node.$$open = false;
    this.selectedRow.level = level;
    this.selectedRow.selectedChild = -1;
    this.setNodeToFocus(node.option.id);
  }

  scrollWithKey(rows) {
    let scrollToRow, containerHeight, page, container;
    container = this.focusHierarchyOption.nativeElement;
    if (container) {
      containerHeight = container.offsetHeight;
      page = Math.ceil(rows / containerHeight);
      if (page === 1) {
        container.scrollTop = 0;
      } else if (page !== this.lastPage) { // scroll only on page
        if (page > this.lastPage) { // increase on keydown
          scrollToRow = rows - this.rowHeight;
        } else {
          scrollToRow = rows - 182; // decrease on keyup
        }
        container.scrollTop = scrollToRow;
      }
      this.lastPage = page;
    }
  }

  private async openSelectedNode() {
    const option = await this.optionsService.getSingleOptionFromId(this.selectedOptionId);
    if (!option) {
      return;
    }
    const pathIds = [];
    this.query = option['name'];
    if (option[AConst.M_PATH]) {
      option[AConst.M_PATH].split('/').forEach(pathPart => {
        if (pathPart) {
          const id = this.objectType + '-' + pathPart;
          if (id !== this.selectedOptionId) {
            pathIds.push(id);
          }
        }
      });
    }
    if (pathIds.length) {
      let nodes = this.nodes;
      for (const pathId of pathIds) {
        if (!nodes) {
          console.log('No more child nodes!');
          break;
        }
        nodes = this.findOpenNode(pathId, nodes);
      }
    }
  }

  private findOpenNode(optionId, nodes) {
    let nodeFound, childNodes;
    for (const node of nodes) {
      if (node.option[AConst.ARTIFACT_ID] === optionId) {
        nodeFound = node;
        break;
      }
    }
    if (nodeFound) {
      this.openNode(nodeFound);
      childNodes = nodeFound.childNodes;
    } else {
      console.log('Option not found: ' + optionId);
    }
    return childNodes;
  }

  private async getRootNodes(): Promise<Array<object>> {
    if (!this.nodes.length) {
      const res = await this.getNodes(this.rootNode);
      this.rootNode.childNodes = res;
      await this.getSetChildren([this.rootNode]);
      return res;
    } else {
      await this.getSetChildren([this.rootNode]);
      return this.nodes;
    }
  }

  private async getNodes(parentNode): Promise<Array<HierarchicNode>> {
    const level = parentNode.option.level + 1;
    const parentId = parentNode.option[AConst.ARTIFACT_ID];

    const res = await this.optionsService.searchHierarchicOptions(
      this.objectType, level, false, 0, 1000, parentId)
    const nodes = [];
    const options = res[AConst.ARTIFACTS] || [];
    if (options.length) {
      if (options[0].code) {
        this.commons.sortArray(options, 'code');
      } else {
        this.commons.sortArray(options, 'artifact_name');
      }
      this.optionsService.setOptionsQueryHighlight(options, this.query, false, true);
      let count = options.length;
      for (let option of options) {
        const childNode = {option: option, childNodes: []};
        nodes.push(childNode);
        childNode.childNodes = await this.getNodes(childNode);
        if (!--count) {
          return nodes;
        }
      }
    } else {
      return nodes;
    }
  }

  private async getSetChildren(nodes: HierarchicNode[]): Promise<number> {
    let childCount = 0;
    if (!nodes || !nodes.length) {
      return childCount;
    }
    const query = this.useQuery ? this.query : undefined;
    let downCount = nodes.length;
    for (const node of nodes) {
      let children = [];
      node.$$count = 0;
      const res = await this.optionsService.searchHierarchicOptions(
        this.objectType, node.option.level + 1, true, 0, 10000, node.option.artifact_id, query);
      children = res[AConst.ARTIFACTS] || [];
      this.setNodeCount(node, res);
      childCount += res[AConst.SEARCH_COUNT];
      if (children.length) {
        this.prepareChildren(node, children);
      } else {
        node.children = [];
      }
      const count = await this.getSetChildren(node.childNodes);
      node.$$count += count;
      if (query !== undefined && query !== '') {
        node.$$open = node.$$count !== 0;
      } else {
        node.$$open = false;
      }
      if (!--downCount) {
        return childCount;
      }
    }
  }

  private setNodeCount(node: HierarchicNode, res: any) {
    if (res[AConst.SEARCH_COUNT]) {
      node.$$count = res[AConst.SEARCH_COUNT];
    } else {
      node.$$count = node.childNodes.length;
    }
  }

  private prepareChildren(node: HierarchicNode, children) {
    if (children[0].code) {
      this.commons.sortArray(children, 'code');
    } else {
      this.commons.sortArray(children, 'artifact_name');
    }
    if (this.isArray) {
      this.optionsService.markSelectedOptions(children, this.selectedOptionId, this.field.inline.prop);
    }
    this.optionsService.setOptionsQueryHighlight(children, this.query, false, true);
    node.children = this.optionsService.weightOptionsByQuery(children, this.query);

  }
}
