import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {Annotation} from '../../annotation';

@Component({
  selector: 'app-annotation-parents',
  templateUrl: './annotation-parents.component.html',
  styleUrls: ['./annotation-parents.component.scss']
})
export class AnnotationParentsComponent implements OnInit {
  @Input() curAnn: Annotation;

  AConst = AConst;

  constructor() { }

  ngOnInit() {
  }

}
