import {Component, Input, OnInit} from '@angular/core';
import {Annotation} from '../../annotation';

@Component({
  selector: 'app-annotation-points',
  templateUrl: './annotation-points.component.html',
  styleUrls: ['./annotation-points.component.scss']
})
export class AnnotationPointsComponent implements OnInit {

  @Input() curAnn: Annotation;

  constructor() {
  }

  ngOnInit() {
  }

}
