import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {SearchHandlerService} from '../../search-handler.service';
import {ObjectDeletionService} from '../../../core/object-deletion.service';
import {AConst} from '../../../core/a-const.enum';
import {SearchContainer} from '../../search-container';

@Component({
  selector: 'app-search-result-view-content-list',
  templateUrl: './search-result-view-content-list.component.html',
  styleUrls: ['./search-result-view-content-list.component.scss']
})
export class SearchResultViewContentListComponent implements OnChanges, OnDestroy {

  @Input() searchContainer: SearchContainer;
  @Output() artifactUpdated = new EventEmitter();

  AConst = AConst;
  private animated = false;

  constructor(private renderer: Renderer2,
              private searchHandler: SearchHandlerService,
              private objectDeletionService: ObjectDeletionService,
              @Inject(DOCUMENT) private document: any) {
  }

  ngOnChanges(): void {
    this.animateListItems();
    this.searchHandler.subscribeToSearchResult(this.searchContainer, this.contentListSearchResultCallback);
  }

  ngOnDestroy(): void {
    this.searchHandler.unSubscribeToSearchResult(this.searchContainer, this.contentListSearchResultCallback);
  }

  afterDelete() {
    if (this.artifactUpdated) {
      this.artifactUpdated.emit();
    }
    this.searchHandler.runSearch(this.searchContainer).then();
  }

  private restartTransition(RemoveClassName, addClassName) {
    this.searchContainer.searchResult.artifacts.forEach((item, index) => {
      const listElement = this.document.getElementById('item-' + index);
      if (listElement) {
        this.renderer.removeClass(listElement, RemoveClassName);
        if (this.searchContainer.displayAll) {
          this.renderer.addClass(listElement, 'no-animate-content-list-thumbnail');
        } else {
          setTimeout(() => {
            this.renderer.addClass(listElement, addClassName);
          }, index * 100);
        }
      }
    });
  }

  // Need delay cuz the list elements may not have been painted yet
  private delayedAnimateListItems() {
    setTimeout(this.animateListItems, 500);
  }

  private setArtifactsDeletable(searchResult) {
    if (searchResult && searchResult[AConst.ARTIFACTS].length > 0) {
      this.objectDeletionService.setObjectsDeletable(searchResult[AConst.ARTIFACTS], !!this.searchContainer.targetObject);
    }
  }

  private animateListItems = () => {
    if (this.animated) {
      this.restartTransition('start2', 'start');
    } else {
      this.restartTransition('start', 'start2');
    }
    this.animated = !this.animated;
    return false;
  };

  private contentListSearchResultCallback = (searchResult) => {
    this.setArtifactsDeletable(searchResult);
    this.delayedAnimateListItems();
  }

}
