import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OverviewFieldValueComponent} from './overview-field-value/overview-field-value.component';
import {OverviewFieldLinkComponent} from './overview-field-link/overview-field-link.component';
import {SharedModule} from '../shared/shared.module';
import {ObjectOverviewFieldsComponent} from './object-overview-fields/object-overview-fields.component';
import {SearchTileOverviewFieldsComponent} from './search-tile-overview-fields/search-tile-overview-fields.component';
import {SearchColumnsOverviewFieldsComponent} from './search-columns-overview-fields/search-columns-overview-fields.component';
import { OverviewFieldComponent } from './overview-field/overview-field.component';
import { OverviewFieldItemComponent } from './overview-field-item/overview-field-item.component';

@NgModule({
  declarations: [
    OverviewFieldValueComponent,
    OverviewFieldLinkComponent,
    ObjectOverviewFieldsComponent,
    SearchTileOverviewFieldsComponent,
    SearchColumnsOverviewFieldsComponent,
    OverviewFieldComponent,
    OverviewFieldItemComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    OverviewFieldValueComponent,
    OverviewFieldLinkComponent,
    SearchColumnsOverviewFieldsComponent,
    SearchTileOverviewFieldsComponent,
    ObjectOverviewFieldsComponent,
    OverviewFieldComponent,
    OverviewFieldItemComponent
  ]
})
export class OverviewFieldsModule {
}
