import {Injectable} from '@angular/core';
import {CmsApiService} from './cms-api.service';
import {AConst} from './a-const.enum';
import {CmsQueueService} from './cms-queue.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private cfg = null;
  private modelIcons = null;

  constructor(private cms: CmsApiService,
              private cmsQueue: CmsQueueService) {
    this.initSettings();
  }

  public vc(key: string, defaultValue?) {
    let res = '';
    const k_arr = key.split('.');

    if (this.cfg) {
      res = this.getValue(k_arr);
      if (typeof res === 'undefined') {
        console.warn('Setting not found: \'' + key + '\'.');
      }
    } else {
      console.warn('No settings set!');
    }
    if (!res && defaultValue) {
      res = defaultValue;
    }

    return res;
  }

  public initSettings(post_fn?, failed_fn?,
                      get_user_data?) {
    let user = null;
    this.cms.init(
      (message) => {
        console.log(message);
      }
    );
    if (get_user_data) {
      this.cmsQueue.runCmsFnWithQueue('getUserData').then(
        (user_data) => {
          user = user_data['user'];
          if (this.cfg) {
            this.cfg.user = user;
            if (post_fn) {
              post_fn(this.cfg);
            }
          }
        }
      );
    }
    this.cmsQueue.runCmsFnWithQueue('getSettings').then(
      (data) => {
        this.cfg = data;
        if (user) {
          this.cfg.user = user;
        }
        if (post_fn) {
          post_fn(data);
        }
      },
      (data) => {
        if (failed_fn) {
          failed_fn(data);
        }
      }
    );
    this.cms.getModelIcons().then(
      (data) => {
        this.modelIcons = data;
      },
      () => {
        throw new Error('Unable to get model icons');
      }
    );
  }

  private _configValueAsBool = function (arg) {
    if (typeof(arg) === 'string') {
      return arg.toUpperCase() === 'TRUE';
    } else {
      return arg;
    }
  };

  private getValue(k_arr) {
    let value = this.cfg[k_arr[0]];
    if (value && k_arr.length > 1) {
      value = this.cfg[k_arr[0]][k_arr[1]];
      if (value && k_arr.length > 2) {
        value = this.cfg[k_arr[0]][k_arr[1]][k_arr[2]];
      }
    }
    return value;
  }

  public isSitulaEnabled() {
    return new Promise( (resolve) => {
      let k = 'SITULA_ENABLED';
      let sc = null;
      if (!this.cfg) {
        this.initSettings( () => {
          sc = this.vc(k, false);
        });
      } else {
        sc = this.vc(k, false);
      }
      resolve(this._configValueAsBool(sc));
    });
  }

  public objectIcon(objectType, size, object, noDefault) {
    let attachmentType, contextType;
    let parentTypeBuilding = false;
    const objIcons = this.modelIcons;
    let icon;

    if (object) {
      contextType = object[AConst.CONTEXT_OBJECT_TYPE];
      if (Array.isArray(contextType)) {
        contextType = contextType[0];
      }
      if (contextType &&
        contextType.toLowerCase().indexOf('building') !== -1) {
        parentTypeBuilding = true;
      }

    }
    if (objIcons) {
      if (objectType) {
        if (objectType === 'Attachment' && object) {
          attachmentType = object[AConst.ATTACHMENT_TYPE];
          icon = objIcons[attachmentType];
          if (!icon) {
            icon = objIcons[objectType];
          }
        } else {
          icon = objIcons[objectType];
          if (objectType === 'ObservationEvent' &&
            parentTypeBuilding) {
            icon = objIcons['ObservationEventBuilding'];
          }
        }
      }
      if (!icon) {
        if (contextType) {
          icon = objIcons[contextType];
        } else if (!noDefault) {
          icon = objIcons[AConst.THING];
        }
      }
    }
    // TODO: Using Font Awesome's sizing classes. The sizing
    // classes used by IcoMoon are not implemented.
    if (icon) {
      if (size) {
        icon += ' fa-' + size + 'x';
      }
    }
    return icon;
  }

}
