import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {MediaHelperService} from '../../core/media-helper.service';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-active-image',
  templateUrl: './active-image.component.html',
  styleUrls: ['./active-image.component.scss']
})
export class ActiveImageComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  AConst = AConst;
  dragItem;
  imageSize = {width: 0, height: 0};
  naturalImageSize = {width: 0, height: 0};
  imageLoaded = 0;
  playMedia = false;
  status;
  showVideo = false;
  validSrc = true;
  @Input() src;
  @Input() imgIndex;
  @Input() art;
  @Input() zoomValue;
  @Input() container;

  constructor(private elementRef: ElementRef,
              private mediaHelper: MediaHelperService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.art) {
      if (this.art[this.AConst.OBJECT_TYPE] === 'Video') {
        this.showVideo = true;
        this.mediaHelper.getMediaUploadStatus(this.art, (status) => {
          this.status = status;
          if (status === 'done') {
            this.mediaHelper.getMediaPlaybackUrls(this.art, (urls) => {
              this.art.$$mediaPlaybackUrls = urls;
            });
          }
        });
      }
    }

    if (changes.hasOwnProperty('imgIndex') && changes.imgIndex.previousValue !== changes.imgIndex.currentValue) {
      this.imgIndex = changes.imgIndex.currentValue;
      this.setActiveImageSize();
      this.resizeActiveImage(this.zoomValue);
    } else {
      if (changes.hasOwnProperty('zoomValue') &&
        changes.zoomValue.previousValue !== changes.zoomValue.currentValue &&
        !changes.zoomValue.firstChange) {
        this.resizeActiveImage(changes.zoomValue.currentValue);
      }
    }
  }

  onPlayMedia() {
    this.playMedia = true;
  }

  ngAfterViewInit() {
    this.dragItem = this.getDragItem();
    this.imageLoaded = 1;
  }

  setDefaultImg() {
    this.validSrc = false;
  }

  getDragItem() {
    return this.imgIndex === undefined ?  this.elementRef.nativeElement.querySelector('#imgItem-') :
      this.elementRef.nativeElement.querySelector('#imgItem-' + this.imgIndex);
  }

  setActiveImageSize() {
    setTimeout(() => {
      this.dragItem = this.getDragItem();
    }, 100);
    setTimeout(() => {
      if (this.dragItem) {
        this.imageSize = this.mediaHelper.setNewImageSize(
          this.container, this.dragItem, this.dragItem.naturalWidth, this.dragItem.naturalHeight);
      }
    }, 200);
  }

  resizeActiveImage(value) {
    if (this.imageLoaded === 1) {
      this.zoomValue = value;

      if (this.imageSize.width === 0 && this.imageSize.height === 0) {
        this.imageSize.width = this.dragItem.width;
        this.imageSize.height = this.dragItem.height;
      }

      this.naturalImageSize = {
        width: this.dragItem.naturalWidth,
        height: this.dragItem.naturalHeight
      };

      this.mediaHelper.resizeImage(
        this.container,
        this.zoomValue,
        this.dragItem,
        this.naturalImageSize,
        this.imageSize, false);

      if (this.dragItem.width > this.container.offsetWidth ||
        this.dragItem.height > this.container.offsetHeight) {
        this.mediaHelper.imgDrag(
          this.container,
          this.imageLoaded,
          this.dragItem,
          this.imageSize,
          this.zoomValue);
      } else {
        this.stopCallBack.bind(this);
      }
    }

  }

  stopCallBack(): void {
    this.mediaHelper.stopCallBack();
  }

  ngOnDestroy() {
    this.stopCallBack.bind(this);
    this.imageLoaded = 0;
  }

}
