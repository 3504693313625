import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';

import {SearchContainer} from '../../search-container';
import {SearchHandlerService} from '../../search-handler.service';
import {FolderService} from '../../../core/folder.service';
import {ArefHandlerService} from '../../../core/aref-handler.service';
import {AConst} from '../../../core/a-const.enum';
import {FolderEditDialogComponent} from '../../../object-edit/folder-edit-dialog/folder-edit-dialog.component';

@Component({
  selector: 'app-search-result-view-folder',
  templateUrl: './search-result-view-folder.component.html',
  styleUrls: ['./search-result-view-folder.component.scss']
})
export class SearchResultViewFolderComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;

  AConst = AConst;
  folderArtifactIdFilter = AConst.FOLDER_ITEMS + '.' + AConst.FOLDER_ARTIFACT_ID;
  private canEditFolders = {};

  constructor(private searchHandler: SearchHandlerService,
              private folderService: FolderService,
              private modalService: MatDialog,
              private arefHandler: ArefHandlerService) {
  }

  ngOnInit() {
    this.searchHandler.subscribeToSearchResult(this.searchContainer, searchRes => {
      this.setFolderItemProps(searchRes);
    });
  }

  ngOnDestroy(): void {
    this.searchHandler.unSubscribeToSearchResult(this.searchContainer, searchRes => {
      this.setFolderItemProps(searchRes);
    });
  }

  editFolder(folder) {
    const modalRef = this.modalService.open(FolderEditDialogComponent, {panelClass: 'edit-dialog'});
    modalRef.componentInstance.folderId = folder[AConst.ARTIFACT_ID];
    modalRef.afterClosed().subscribe(folderEdited => {
      if (folderEdited) {
        this.folderService.goToFolder(folderEdited[AConst.ARTIFACT_ID]);
      }
    });
  }

  copyFolder(folder) {
    this.folderService.copyFolder(folder).then(storeRes => {
      this.folderService.goToFolder(storeRes[AConst.ARTIFACT_ID]);
    });
  }

  deleteFolder(folder) {
    this.folderService.deleteFolder(folder).then(() => {
      this.searchHandler.runSearch(this.searchContainer).then();
    });
  }

  canEditFolder(folder) {
    const res = this.canEditFolders[folder[AConst.ARTIFACT_ID]];
    if (res === undefined) {
      this.folderService.canUserEditFolder(folder).then(canEdit => {
        this.canEditFolders[folder[AConst.ARTIFACT_ID]] = canEdit;
      });
    }
    return res;
  }

  private setFolderItemProps(searchRes) {
    searchRes.artifacts.forEach(fld => {
      let ids = fld[this.folderArtifactIdFilter];
      const count = 4;

      if (ids) {
        ids = ids.slice(0, 100);
        this.arefHandler.getRefsFromServer(ids, arefs => {
          let t, ref;
          fld.$$thumbs = [];
          for (t = 0; t < arefs.length; t++) {
            ref = arefs[t];
            if (ref[AConst.THUMBNAIL_ID]) {
              fld.$$thumbs.push(ref);
              if (fld.$$thumbs.length >= count) {
                break;
              }
            }
          }
          if (fld.$$thumbs.length <= count) {
            this.setEmptyThumb(fld, count);
          }
        });


      } else {
        fld.$$thumbs = [];
        this.setEmptyThumb(fld, count);
      }
    });
  }

  private setEmptyThumb(fld, count) {
    for (let m = 0; m < count; m++) {
      if (fld.$$thumbs.length <= count) {
        const noThumbs = {
          thumbnail_id: null
        };
        fld.$$thumbs.push(noThumbs);
      }
    }
  }

}
