import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AConst} from '../../core/a-const.enum';
import {ModelFactoryService} from '../../core/model-factory.service';

@Component({
  selector: 'app-edit-field-check-array',
  templateUrl: './edit-field-check-array.component.html',
  styleUrls: ['./edit-field-check-array.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldCheckArrayComponent)
    }
  ]
})
export class EditFieldCheckArrayComponent implements OnInit, ControlValueAccessor {

  @Input() field;
  @Input() object;
  @Input() formControl;

  checkField;
  model;
  private onChange;

  constructor(private modelFactory: ModelFactoryService) { }

  ngOnInit() {
    const optionInfo = this.field[AConst.OPTION_INFO];
    this.checkField = optionInfo[AConst.INLINE_CHECK_FIELD];
    this.model = this.object[this.field.name];
    const options = this.getFieldOptions(this.field);
    if (options) {
      options.forEach((opt, index) => {
        if (typeof this.model[index] === 'undefined') {
          this.model[index] = opt;
        } else {
          this.model[index].$$label = opt.$$label;
        }
      });
    } else {
      throw new Error('Check array requires options');
    }
  }

  toggleItem($event, item) {
    $event.preventDefault();
    item[this.checkField] = !item[this.checkField];
    this.formControl.markAsDirty();
  }

  private getFieldOptions(field) {
    let res = [];
    const optionInfo = field[AConst.OPTION_INFO];
    const valueField = optionInfo[AConst.INLINE_VALUE_FIELD];
    const checkField = optionInfo[AConst.INLINE_CHECK_FIELD];
    const modelName = field.inline ? field.inline.model : null;
    if (modelName) {
      optionInfo[AConst.OPTIONS].forEach((opt) => {
        const modelData = {};
        if (valueField) {
          modelData[valueField] = opt.value;
        }
        if (checkField) {
          modelData[checkField] = false;
        }
        const modelItem = this.modelFactory.createModelItem(modelName, modelData);
        modelItem['$$label'] = opt[AConst.LABEL];
        res.push(modelItem);
      });
    } else {
      res = optionInfo[AConst.OPTIONS];
    }
    return res;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
  }

}
