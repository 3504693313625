import {interval} from 'rxjs';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {StateService, UIRouterGlobals} from '@uirouter/core';

import {CmsApiService} from '../../core/cms-api.service';
import {ActionMenuService} from '../../core/action-menu.service';
import {ProgressDialogComponent} from '../../shared/progress-dialog/progress-dialog.component';

@Component({
  selector: 'app-import-situla',
  templateUrl: './import-situla.component.html',
  styleUrls: ['./import-situla.component.scss']
})
export class ImportSitulaComponent implements OnInit, OnDestroy {

  public headerLabel: object;
  public dataSets: any = null;
  public parent: object;

  private actionMenuContainer: any;
  private poller: any;
  private progressModal: MatDialogRef<ProgressDialogComponent>;

  private readonly adminType: UIRouterGlobals;
  private readonly refreshInterval: number;

  constructor(private cms: CmsApiService,
              private translate: TranslateService,
              private actionMenuService: ActionMenuService,
              private state: StateService,
              private stateParams: UIRouterGlobals,
              private modalService: MatDialog) {

    this.actionMenuService.newActionMenuContainer();

    this.actionMenuContainer = actionMenuService.actionMenuContainer;

    this.refreshInterval = 60 * 1000; // Refresh content every 1 minute

    this.adminType = stateParams.params['admin_type'];

    this.parent = {
      path: 'home.primus.admin',
      adminType: this.adminType,
      subMenus: true,
      target_params: {admin_type: this.adminType},
      currentState: this.state.$current.name
    };

    // Keys used on labels displayed on each "information card" when viewed on narrow screens.
    this.headerLabel = {
      name: 'TRANS__LIST__NAME',
      profileName: 'TRANS__LIST__PROFILE_NAME',
      imported: 'TRANS__LIST__IMPORTED',
      status: 'TRANS__LIST__STATUS',
      folder: 'TRANS__LIST__FOLDER'
    };
  }

  ngOnInit() {
    this._init();
  }

  ngOnDestroy() {
    this.poller.unsubscribe();
  }

  deleteDataset(id) {
    const confMsg = this.translate.instant('TRANS__CONF__DELETE_SITULA_DATASET') + '?';
    if (confirm(confMsg)) {
      this.cms.deleteSitulaDataset(id).then(() => {
        this.downloadData(false);
      }).catch(err => {
        console.error('Error deleting Situla dataset: ' + err);
      });
    }
  }

  importDataset(id) {
    this.cms.importSitulaDataset(id).then(() => {
      this.downloadData(false);
    }).catch(err => {
      console.error('Error importing Situla dataset: ' + err);
    });
  }

  downloadData(showSpinner) {
    if (showSpinner) {
      this._showProgressBar();
    }

    this.cms.downloadSitulaDatasets(false)
      .then(data => {
        this.dataSets = data;
        this._closeProgressBar();
      });
  }

  private _showProgressBar() {
    this.progressModal = this.modalService.open(ProgressDialogComponent,
      {disableClose: true, panelClass: 'progress-modal'});

  }

  private _closeProgressBar() {
    interval(500).subscribe(() => {
      this.progressModal.close(null);
    });
  }

  private _init() {
    // Download dataset
    this.downloadData(true);

    // Translate card headers (used on mobile displays).
    Object.keys(this.headerLabel).forEach((key) => {
      this.headerLabel[key] = this.translate.instant(this.headerLabel[key]);
    });

    //  Init poller, used to refresh the page at a given interval.
    this.poller = interval(this.refreshInterval)
      .subscribe(() => {
        this.downloadData(false);
      });
  }
}

