import {AConst} from '../core/a-const.enum';
import {ModelFactoryService} from '../core/model-factory.service';
import {CommonsService} from '../core/commons.service';
import {ObjectDeletionService} from '../core/object-deletion.service';
import {UiToolsService} from '../core/ui-tools.service';
import {CurrentObjectService} from '../core/current-object.service';

export class Annotation {
  annotateImage = null;
  target = null;
  ane = null;
  annotationType = 'square';
  currentColor = '#FFFFFF';
  aneSet = true;
  selectedAnnPoint = null;
  showAnnMenuShape = false;
  showAnnMenuColor = false;
  hasSelectedEdit = false;
  disabledSaveReason = '';
  closeCallback;
  canvasCallback;
  aTypes = [
    {
      type: 'square',
      description: 'TRANS__ANNOTATION__WITH_BOX',
      icon: 'icon-square',
      fontCode: '&#xe638;'
    },
    {
      type: 'circle',
      description: 'TRANS__ANNOTATION__WITH_CIRCLE',
      icon: 'icon-circle',
      fontCode: '&#xe639;'
    }
  ];
  colors = [
    '#FFFFFF', '#BBBBBB', '#777777', '#333333', '#000000',
    '#FF0000', '#00FF00', '#0000FF', '#FF8080', '#80FF80',
    '#8080FF', '#FFFF00', '#00FFFF', '#FF00FF', '#FFFF80',
    '#80FFFF', '#FF80FF'
  ];
  actionStates = {
    idle: null,
    draw: 'draw',
    undo: 'undo',
    zoomIn: 'zoomIn',
    zoomOut: 'zoomOut',
    pan: 'pan'
  };
  state = this.initState();
  nonStateChangeActions = [this.actionStates.undo];

  private lastColor;
  private lastAType;
  private lastAnnPoint;
  private clickListenerId;

  constructor(private modelFactory: ModelFactoryService,
              private commons: CommonsService,
              private objectDeletionService: ObjectDeletionService,
              private uiTools: UiToolsService,
              private currentObjectService: CurrentObjectService) {

  }

  resetState() {
    this.state = this.initState();
  }

  setStateAction(action, toggle) {
    // Timeout necessary due to dashboard updates
    setTimeout(() => {
      if (this.nonStateChangeActions.indexOf(action) === -1) {
        this.state.action = toggle ? action : null;
        if (action === this.actionStates.draw) {
          this.setEditState(toggle);
        }
      } else {
        this.doAction(action);
      }
    }, 300);
  }

  setDrawState() {
    this.setStateAction(this.actionStates.draw, true);
  }

  toggleStateAction(action) {
    this.setStateAction(action, action !== this.state.action);
  }

  setColor(color) {
    this.currentColor = color;
    this.showAnnMenuColor = !this.showAnnMenuColor;
    this.setAnnotationPointAttributesFromCurrent();
  }

  selectAnnotationType(aType) {
    this.annotationType = aType;
    this.showAnnMenuShape = !this.showAnnMenuShape;
    this.setAnnotationPointAttributesFromCurrent();
  }

  closeAnnotation() {
    let aes, ane, aPoints;
    if (this.state.edit) {
      // Remove annotation if there are no annotation points
      aes = this.target.annotation_events;
      ane = aes[aes.length - 1];
      aPoints = this.getAnnotationPoints({ane: ane});
      if (aPoints.length === 0) {
        this.target.annotation_events.pop();
        this.ane = null;
      }
      this.close();
    }
  }

  cancelAnnotation() {
    this.target.annotation_events.pop();
    this.ane = null;
    this.close();
  }

  // Return all non-destroyed annotation points, unless "all"
  // parameter is specified
  getAnnotationPoints(params?) {
    const res = [], p = params ? params : {};
    let pts;
    const ane = p.ane ? p.ane : this.ane;
    if (ane) {
      pts = ane[AConst.ANNOTATION_POINTS];
    }
    if (pts) {
      pts.forEach(aPoint => {
        if (!aPoint._destroy || p.all) {
          res.push(aPoint);
        }
      });
    }
    return res;
  }

  getDisabledSave() {
    let res = false;
    if (this.state.edit) {
      this.hasSelectedEdit = true;
    }
    if (!this.state.edit && !this.hasSelectedEdit) {
      res = true;
      this.disabledSaveReason = 'TRANS__ANNOTATION__NO_EDIT';
    } else if (this.getAnnotationPoints().length === 0) {
      res = true;
      this.disabledSaveReason = 'TRANS__ANNOTATION__NO_ANNOTATION_POINTS';
    } else {
      this.disabledSaveReason = '';
    }
    return res;
  }

  getAnnotationIndex(ann) {
    let index, a, res = -1;
    const aPoints = this.getAnnotationPoints({all: true});
    for (index = 0; index < aPoints.length; index++) {
      a = aPoints[index];
      if (a.uuid === ann.uuid) {
        res = index;
        break;
      }
    }
    return res;
  }

  createAnnotation(scopeIn, x, y, fn) {
    const array = this.ane[AConst.ANNOTATION_POINTS];
    this.modelFactory.createAddArrayItemAsync(array, 'AnnotationPoint', {
      uuid: this.commons.uuid(),
      a_type: this.annotationType,
      x1: x,
      y1: y,
      x2: x,
      y2: y,
      color: this.currentColor,
      $$unfinished: true
    }).then(annotationPoint => {
      fn(annotationPoint);
    });
  }

  deleteAnnotation(ann) {
    const index = this.getAnnotationIndex(ann);
    // Timeout necessary due to dashboard updates
    setTimeout(() => {
      if (ann['$$selected']) {
        this.selectAnnotation(null);
      }
      if (index !== -1) {
        this.objectDeletionService.deleteItem(this.ane, 'annotation_points', index);
      }
    }, 500);
  }

  undoDeleteAnnotation() {
    this.objectDeletionService.undoDeleteItem(this.ane, 'annotation_points');
  }

  selectAnnotation(ann) {
    if (this.selectedAnnPoint) {
      this.selectedAnnPoint['$$selected'] = false;
    }
    this.selectedAnnPoint = ann;
    if (ann) {
      this.setCurrentAttributesFromAnnotationPoint(ann);
      this.selectedAnnPoint['$$selected'] = true;
      // Timeout allows annotate dashboard to update
      setTimeout(() => {
        // Some times selectAnnPoint is null
        if (this.selectedAnnPoint) {
          this.selectedAnnPoint['$$updated'] = true;
        }
      }, 300);
    }
  }

  toggleAnnotateMenuShape() {
    if (this.state.edit) {
      this.showAnnMenuShape = !this.showAnnMenuShape;
      this.showAnnMenuColor = false;
      this.setClickListener();
    }
  }

  toggleAnnotateMenuColor() {
    if (this.state.edit) {
      this.showAnnMenuColor = !this.showAnnMenuColor;
      this.showAnnMenuShape = false;
      this.setClickListener();
    }
  }

  private setClickListener() {
    if (!this.clickListenerId) {
      this.clickListenerId = this.uiTools.addDocumentClickListener(() => {
        this.showAnnMenuColor = false;
        this.showAnnMenuShape = false;
      });
    }
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
  }

  private initState() {
    return {
      action: this.actionStates.idle,
      draw: {
        drawing: false,
        moving: false
      },
      edit: false
    };
  }

  private doAction(action) {
    if (action === this.actionStates.undo) {
      this.undoDeleteAnnotation();
    }
  }

  private setAnnotationPointAttributesFromCurrent() {
    const cap = this.selectedAnnPoint;
    if (cap) {
      if (this.currentColor !== this.lastColor) {
        cap[AConst.COLOR] = this.currentColor;
      }
      if (this.annotationType !== this.lastAType) {
        cap[AConst.A_TYPE] = this.annotationType;
      }
      if (!this.lastAnnPoint || cap.uuid !== this.lastAnnPoint.uuid) {
        if (cap.a_type) {
          this.annotationType = cap.a_type;
        } else {
          this.annotationType = 'box';
        }
        this.currentColor = cap.color;
      }
      this.lastAnnPoint = cap;
    }
    this.lastAType = this.annotationType;
    this.lastColor = this.currentColor;
  }

  private setCurrentAttributesFromAnnotationPoint(point) {
    this.annotationType = point.a_type;
    this.currentColor = point.color;
  }

  private close() {
    this.uiTools.removeDocumentClickListener(this.clickListenerId);
    if (this.target) {
      this.target.$$annotateImage = null;
    }
    this.setEditState(false);
    this.hasSelectedEdit = false;
    if (this.closeCallback) {
      this.closeCallback(this);
    }
  }

  private setEditState(isEditing) {
    this.state.edit = isEditing;
    this.currentObjectService.isEditing = isEditing;
  }

}
