import {Component, Input, OnInit} from '@angular/core';
import {Annotation} from '../../image-annotation/annotation';
import {UiToolsService} from '../../core/ui-tools.service';
import {UploadService} from '../../core/upload.service';
import {ObjectEditService} from '../../core/object-edit.service';
import {MediaHelperService} from '../../core/media-helper.service';
import {CmsApiService} from '../../core/cms-api.service';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-media-selector-image-vertical',
  templateUrl: './media-selector-image-vertical.component.html',
  styleUrls: ['./media-selector-image-vertical.component.scss']
})
export class MediaSelectorImageVerticalComponent implements OnInit {

  @Input() fieldName: string;
  @Input() object;
  @Input() target;
  @Input() curAnn: Annotation;
  @Input() multipleImages: boolean;

  computer = true;
  uploadContainer = {};
  selectImg = false;
  cameraOpen = false;
  parentImages = [];


  constructor(private uiTools: UiToolsService,
              private uploadService: UploadService,
              private objectEditService: ObjectEditService,
              private mediaHelper: MediaHelperService,
              private cms: CmsApiService) {
  }

  get images() {
    return this.object[this.fieldName] || [];
  }

  ngOnInit() {
    if (this.target) {
      if (this.target[AConst.CONTEXTS]) {
        this.target[AConst.CONTEXTS].forEach(context => {
          this.loadImages(context[AConst.CONTEXT_ARTIFACT_ID]);
        });
      }
      if (this.target[AConst.PARENT_ID]) {
        this.loadImages(this.target[AConst.PARENT_ID]);
      }
    }

    if (this.multipleImages === undefined) {
      this.multipleImages = true;
    }
    this.computer = this.uiTools.getClientType().isComputer;
  }

  uploadImg() {
    this.selectImg = false;
    this.cameraOpen = false;
  }

  openCamera() {
    this.cameraOpen = !this.cameraOpen;
    this.selectImg = false;
  }

  // At the drag drop area
  // (drop)="onDropFile($event)"
  onDropFile(event) {
    event.preventDefault();
    this.uploadFiles(event.dataTransfer.files);
  }

  // At the drag drop area
  // (dragover)="onDragOverFile($event)"
  onDragOverFile(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  // At the file input element
  // (change)="selectFile($event)"
  onFileSelect(event) {
    this.uploadFiles(event.target.files);
  }

  hasImages() {
    return this.images.length;
  }

  deleteImage(index) {
    const image = this.images[index];
    if (image._create) {
      this.images.splice(index, 1);
    } else {
      image._destroy = true;
    }
  }

  uploadFiles(files) {
    this.uploadService.uploadFiles(this.uploadContainer, files, 'image').then(res => {
      this.getImageItems(res).then(items => {
        items.forEach(item => {
          this.images.push(item);
        });
      });
    });
  }

  private getImageItems(images): Promise<Array<any>> {
    return new Promise(resolve => {
      const res = [];
      let counter = images.length;
      images.forEach(img => {
        this.objectEditService.createModelItem('ImageItem', {image_id: img[AConst.ARTIFACT_ID]}).then(
          item => {
            res.push(item);
            if (!--counter) {
              resolve(res);
            }
          });
      });
    });
  }

  private loadImages(parentId) {
    this.loadNewImages(parentId).then(images => {
      this.mediaHelper.addImageUrls(images);
      this.parentImages = this.parentImages.concat(images);
    });
  }

  private loadNewImages(parentId) {
    return new Promise(resolve => {
      this.cms.getArtifactImages({artifact_id: parentId}).then(
        data => {
          const newImages = data[AConst.IMAGES];
          const res = [];
          newImages.forEach(newImage => {
            let exists = false;
            this.parentImages.forEach(existingImg => {
                if (newImage[AConst.IMAGE_ID] === existingImg[AConst.IMAGE_ID]) {
                  exists = true;
                }
              }
            );
            if (!exists) {
              res.push(newImage);
            }
          });
          resolve(res);

        },
        function (status) {
          console.warn('Failed with code: ' + status);
        }
      );
    });
  }

}
