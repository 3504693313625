import {Component, Input, OnInit} from '@angular/core';
import {SectionsContainer} from '../../core/sections-container';
import {CheckboxField} from '../../core/object-view';
import {ActionMenuService} from '../../core/action-menu.service';

@Component({
  selector: 'app-object-view-field-checkbox',
  templateUrl: './object-view-field-checkbox.component.html',
  styleUrls: ['./object-view-field-checkbox.component.scss']
})
export class ObjectViewFieldCheckboxComponent implements OnInit {
  @Input() sectionsContainer: SectionsContainer;
  @Input() checkboxField: CheckboxField;

  checkboxModel;

  constructor(private actionMenuService: ActionMenuService) {
  }

  ngOnInit() {
    if (this.checkboxField) {
      this.checkboxModel = this.getCheckboxFieldModel();
    }
  }

  checkboxValueChanged() {
    this.actionMenuService.actionMenuContainer.checkboxChange = true;
    this.sectionsContainer.rootObject[this.checkboxField.array_field_name][this.checkboxField.index][this.checkboxField.field_name] =
      this.checkboxModel;
  }

  private getCheckboxFieldModel() {
    return this.sectionsContainer.rootObject[this.checkboxField.array_field_name][this.checkboxField.index][this.checkboxField.field_name];
  }

}
