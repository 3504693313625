import {Component, OnInit} from '@angular/core';
import {StateService, UIRouterGlobals} from '@uirouter/core';
import {SearchSelectorService} from '../../core/search-selector.service';
import {RefService} from '../../core/ref.service';
import {CmsApiService} from '../../core/cms-api.service';
import {ModelsService} from '../../core/models.service';
import {ModelObjectService} from '../../core/model-object.service';
import {ObjectEditService} from '../../core/object-edit.service';
import {MediaHelperService} from '../../core/media-helper.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {AConst} from '../../core/a-const.enum';
import {SelectorContainer} from '../../core/selector-container';
import {SectionsContainer} from '../../core/sections-container';

@Component({
  selector: 'app-object-new-page',
  templateUrl: './object-new-page.component.html',
  styleUrls: ['./object-new-page.component.scss']
})
export class ObjectNewPageComponent implements OnInit {

  AConst = AConst;
  object;
  selectorContainer: SelectorContainer;
  sectionsContainer: SectionsContainer;
  isCopy = false;
  objectType;
  debug = false;
  newObjectText = '';
  edit = true;
  objectTypeName;
  newModelInfo;
  actions = [];

  private canSelectImg;

  constructor(private stateService: StateService,
              private uiRouterGlobals: UIRouterGlobals,
              private searchSelectorService: SearchSelectorService,
              private ref: RefService,
              private cms: CmsApiService,
              private models: ModelsService,
              private modelObjectService: ModelObjectService,
              private objectEditService: ObjectEditService,
              private mediaHelper: MediaHelperService,
              public currentObjectService: CurrentObjectService) {
  }

  ngOnInit() {
    this.objectType = this.uiRouterGlobals.params.objectType;
    this.debug = this.uiRouterGlobals.params.debug;
    this.actions = [
      {
        containerClass: 'register-button-container',
        buttonClass: 'register-button',
        type: 'register',
        fn: () => {
          this.saveArtifact();
        },
        buttonText: 'TRANS__EDIT__STORE_ARTIFACT'
      },
      {
        buttonClass: 'cancel-button',
        fn: () => {
          window.history.back();
        },
        buttonText: 'TRANS__EDIT__CANCEL'
      }
    ];

    this.models.getModelTitle(this.objectType, title => {
        this.newObjectText = title;
      }
    );

    this.objectEditService.createModelItemGetSectionsContainerForPrimeFields(this.objectType).then(sectionsContainer => {
      this.sectionsContainer = sectionsContainer;
      this.object = sectionsContainer.rootObject;
      this.currentObjectService.currentObject = this.object;
      this.objectTypeName = this.objectType;
    });

    this.cms.getNewModelInfo({modelName: this.objectType}).then(
      data => {
        this.newModelInfo = data;
      },
      () => {
        console.error('Unable to get new model info for ' + this.objectType);
      }
    );
  }

  canSelectImages() {
    let res = false;
    if (this.object) {
      if (this.canSelectImg === undefined && this.object) {
        this.canSelectImg = false;
        this.mediaHelper.objectCanHaveImages(this.object).then(canSelectRes => {
          this.canSelectImg = canSelectRes;
        });
      }
      res = this.canSelectImg;
    }
    return res;
  }

  enableSelector(selector) {
    this.selectorContainer = this.searchSelectorService.createSelectorContainer(this.object);
    this.searchSelectorService.enableSelector(selector, this.selectorContainer, null,
      {
        searchContainerCreated: (/*sc*/) => {
        },
        selectorCallback: (arts) => {
          this.selectorCallback(arts);
        }
      }, this.uiRouterGlobals.params.template_group_id
    );
  }

  private selectorCallback(arts) {
    if (arts && arts.length > 0) {
      this.object = null;
      this.searchSelectorService.disableSelector(this.selectorContainer);
      setTimeout(() => {
        this.object = arts[0];
        this.modelObjectService.getSectionsAndFormGroup(this.object, this.uiRouterGlobals.params.template_group_id, true).then(
          sectionsContainer => {
            this.sectionsContainer = sectionsContainer;
          });
        this.isCopy = true;
      }, 500);
    }
  }

  private saveArtifact() {
    const sRef = this.ref.makeRef({
      object: this.object, stateParams: this.uiRouterGlobals.params,
      edit: !this.isCopy, isNew: true
    });
    sRef.param.artifactId = '{artifactId}';
    if (this.sectionsContainer.formGroup.valid) {
      this.objectEditService.setObjectValuesStoreObject(this.sectionsContainer).then(art => {
        sRef.param.artifactId = art[AConst.ARTIFACT_ID];
        this.currentObjectService.currentObject = null;
        this.stateService.go(sRef.stateName, sRef.param);
      });
    }
  }

}
