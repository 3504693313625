import {Component, Input, OnInit} from '@angular/core';
import {SectionsContainer} from '../../../core/sections-container';

@Component({
  selector: 'app-admin-settings-edit-form',
  templateUrl: './admin-settings-edit-form.component.html',
  styleUrls: ['./admin-settings-edit-form.component.scss']
})
export class AdminSettingsEditFormComponent implements OnInit {
  @Input() sectionsContainer: SectionsContainer;

  constructor() { }

  ngOnInit() {
  }

}
