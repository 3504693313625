import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {InlineArrayItemService} from '../../../core/inline-array-item.service';
import {SectionsContainer} from '../../../core/sections-container';
import {FieldParameters} from '../../../core/field-parameters';

@Component({
  selector: 'app-edit-field-inline-array-item-top-bar',
  templateUrl: './edit-field-inline-array-item-top-bar.component.html',
  styleUrls: ['./edit-field-inline-array-item-top-bar.component.scss']
})
export class EditFieldInlineArrayItemTopBarComponent implements OnChanges {
  AConst = AConst;
  item;
  ACTIVE_DROPDOWN = '$$activeDropdown';
  private fieldParameters: FieldParameters;

  @Input() sectionsContainer: SectionsContainer;
  @Input() object;
  @Input() field;
  @Input() arrayIndex;
  @Input() parentIndex;
  @Output() setFirstInputFocus = new EventEmitter<object>();

  constructor(private inlineArrayItemSvc: InlineArrayItemService) {
  }

  ngOnChanges() {
    this.item = this.object[this.field.name][this.arrayIndex];
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters.object = this.object;
    this.fieldParameters.field = this.field;
    this.fieldParameters.index = this.arrayIndex;
    this.fieldParameters.parentIndex = this.parentIndex;
  }


  toggleOpenItem() {
    if (this.item[this.ACTIVE_DROPDOWN]) {
      return;
    }
    this.inlineArrayItemSvc.toggleInlineArrayItemOpen(this.fieldParameters);
    this.setFirstInputFocus.emit();
  }

  toggleOpenItemWithKey(/*event*/) {
    this.toggleOpenItem();
    this.setFirstInputFocus.emit();
  }
}
