import {Component, OnDestroy, OnInit} from '@angular/core';
import {StateService, UIRouterGlobals} from '@uirouter/core';
import {MediaHelperService} from '../../core/media-helper.service';
import {ActionMenuService} from '../../core/action-menu.service';
import {ObjectEditService} from '../../core/object-edit.service';
import {UiToolsService} from '../../core/ui-tools.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {AConst} from '../../core/a-const.enum';
import {SectionsContainer} from '../../core/sections-container';
import {ContentInfo, ContentInfoService} from '../../core/content-info.service';
import {SectionMenuContainer} from '../../object-view/section-menu/section-menu.component';

@Component({
  selector: 'app-media-page',
  templateUrl: './media-page.component.html',
  styleUrls: ['./media-page.component.scss']
})
export class MediaPageComponent implements OnInit, OnDestroy {

  actionMenuContainer = this.actionMenuService.newActionMenuContainer();
  contentInfo = new ContentInfo();
  art = null;
  contextId;
  parentId;
  galleryParentId;
  mediaId;
  objectType;
  actions = [];
  sectionMenuContainer = new SectionMenuContainer();
  artTarget;
  mediaItems;
  mediumScreen: boolean;
  smallObjectMenus: boolean;
  smallScreen: boolean;
  sectionsContainer: SectionsContainer;
  scrollContent = false;

  private artifactId;
  private stopSizeWatch;

  constructor(private stateService: StateService,
              private uiRouterGlobals: UIRouterGlobals,
              private mediaHelper: MediaHelperService,
              private actionMenuService: ActionMenuService,
              private objectEditService: ObjectEditService,
              private uiTools: UiToolsService,
              public currentObjectService: CurrentObjectService,
              private contentInfoService: ContentInfoService) {
  }

  ngOnInit() {
    if (this.uiRouterGlobals.params.contextIds && typeof this.uiRouterGlobals.params.contextIds === 'string') {
      this.contextId = this.uiRouterGlobals.params.contextIds;
      this.parentId = this.uiRouterGlobals.params.parentId !== this.contextId ? this.uiRouterGlobals.params.parentId : undefined;
    } else if (this.uiRouterGlobals.params.parentId) {
      this.contextId = this.uiRouterGlobals.params.parentId;
      this.parentId = null;
    }
    this.galleryParentId = this.uiRouterGlobals.params.galleryParentId;
    this.mediaId = this.uiRouterGlobals.params.mediaId;
    this.objectType = this.uiRouterGlobals.params.objectType;


    if (this.parentId) {
      this.artifactId = this.parentId;
    } else if (this.contextId) {
      this.artifactId = this.contextId;
    } else if (this.mediaId) {
      this.artifactId = this.mediaId;
    } else {
      console.warn('Could not get artifact id for retrieving media');
    }

    this.actionMenuContainer.parentId = this.uiRouterGlobals.params.parentId;
    this.actionMenuContainer.artifactId = this.artifactId;
    this.actionMenuContainer.objectType = this.objectType;
    this.actionMenuContainer.currentMenu = null;
    this.actionMenuContainer.showMenu = false;
    this.actionMenuContainer.showMenuReport = false;
    this.actionMenuContainer.artifactUpdateCallback = () => {
      this.artifactUpdated();
    };

    this.actions = [
      {
        containerClass: 'register-button-container',
        buttonClass: 'register-button',
        type: 'register',
        fn: () => {
          this.updateArtifact();
        },
        buttonText: 'TRANS__EDIT__STORE_ARTIFACT',
        helperBoxText: 'TRANS__EDIT__STORE_ARTIFACT__HELP_TEXT'
      },
      {
        buttonClass: 'cancel-button',
        fn: () => {
          this.cancelArtifact();
        },
        buttonText: 'TRANS__EDIT__CANCEL'
      }
    ];

    this.loadMedia();

    const windowSize = this.uiTools.windowSize;
    this.mediumScreen = windowSize.width < 1025;
    this.smallObjectMenus = windowSize.width < 870;
    this.smallScreen = windowSize.width < 642;
    window.addEventListener('scroll', this.checkScroll);

    this.stopSizeWatch = this.uiTools.addWindowSizeListener(newVal => {
        this.smallObjectMenus = newVal.width < 1025;
        this.mediumScreen = newVal.width < 1025;
        this.smallScreen = newVal.width < 642;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
    window.removeEventListener('scroll', this.checkScroll);
  }

  artifactUpdated() {
    this.setSelectedMediaItem();
  }

  selectMedia(mediaId) {
    if (this.mediaItems) {
      this.stateService.go(this.stateService.current.name,
        {
          mediaId: mediaId
        });
      this.mediaId = mediaId;
      this.setSelectedMediaItem();
    }
  }

  private loadMedia() {
    if (this.artifactId.indexOf('IMG-') === -1 && this.artifactId.indexOf('VID-') === -1) {
      // This happens if you open an artifact containing images or videos
      this.objectEditService.loadObjectGetSectionsContainer(this.artifactId, this.uiRouterGlobals.params.template_group_id).then(
        sectionsContainer => {
          this.artTarget = sectionsContainer.rootObject;
          this.getAllMedia().then(items => {
            this.mediaItems = items;
            this.setSelectedMediaItem();
          });
        },
        reason => {
          console.error('Loading artifact with media failed: ' + reason.error.message);
        }
      );
    } else {
      // This happens if you click on a media based search item
      this.objectEditService.loadObject(this.artifactId, this.uiRouterGlobals.params.template_group_id).then(
        mediaItem => {
          this.mediaItems = this.getMedia([mediaItem]);
          this.setSelectedMediaItem();
        },
        reason => {
          console.error('Loading media failed: ' + reason.error.message);
        });
    }
  }

  private getAllMedia() {
    return new Promise(resolve => {
      if (this.objectType === 'Image') {
        this.mediaHelper.getAllImages(this.artTarget, items => {
          resolve(items);
        });
      } else if (this.objectType === 'Video') {
        this.mediaHelper.getAllVideos(this.artTarget, items => {
          resolve(items);
        });
      }
    });
  }

  private getMedia(mediaItems) {
    if (mediaItems && mediaItems.length > 0) {
      this.mediaHelper.addImageUrls(mediaItems);
    } else {
      return [];
    }
    return mediaItems;
  }

  private setSelectedMediaItem() {
    this.mediaItems.forEach(mediaItem => {
      if (mediaItem[AConst.ARTIFACT_ID] === this.mediaId) {
        this.objectEditService.loadObjectGetSectionsContainer(mediaItem[AConst.ARTIFACT_ID], null).then(
          sectionsContainer => {
            this.postLoadOperations(mediaItem, sectionsContainer);
          }
        );
      }
    });
  }

  private postLoadOperations(mediaItem, sectionsContainer: SectionsContainer) {
    this.sectionsContainer = sectionsContainer;
    this.sectionMenuContainer.sections = sectionsContainer.sections;
    this.art = sectionsContainer.rootObject;

    this.art.$$context_id = this.contextId;
    this.art.$$parent_id = this.parentId;
    this.contentInfoService.updateContentInfo(this.contentInfo, this.art, this.uiRouterGlobals.params.listName);
    this.actionMenuContainer.artifactId = mediaItem.artifact_id;
    this.actionMenuContainer.art = this.art;
    this.sectionMenuContainer.artifact = this.art;
    this.sectionMenuContainer.contentInfo = this.contentInfo;
    this.currentObjectService.currentObject = this.art;
  }

  private cancelArtifact() {
    if (this.art && this.art[AConst.ARTIFACT_ID]) {
      this.stateService.go(this.stateService.current,
        {edit: undefined},
        {location: 'replace', reload: true});
      this.artifactUpdated();
    } else {
      window.history.back();
    }
  }

  private updateArtifact() {
    if (this.art !== undefined) {
      this.storeArtifact();
    }
  }

  private storeArtifact() {
    this.objectEditService.setObjectValuesStoreObject(this.sectionsContainer).then(value => {
      this.art[AConst.ARTIFACT_ID] = value[AConst.ARTIFACT_ID];
      this.currentObjectService.isEditing = false;
      this.stateService.go(this.stateService.current.name, {edit: undefined, isNew: undefined}, {location: 'replace ', reload: true});
      this.artifactUpdated();
    });
  }

  private checkScroll = () => {
    if (!this.mediumScreen) {
      if (this.contentInfo && this.contentInfo.curListName === 'overview') {
        this.scrollContent = window.pageYOffset > 30 && window.pageYOffset < 240;
      } else {
        this.scrollContent = window.pageYOffset > 30;
      }
    }
  }

}
