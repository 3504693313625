import { Injectable } from '@angular/core';
import {AConst} from "./a-const.enum";
import {CmsQueueService} from "./cms-queue.service";
import {CmsApiService} from "./cms-api.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ArefHandlerService {
  arefs = {};
  //callBacks = {};

  /*
     References that are not found in artifact.aref will be stored in
     the arefHandler arefs object in order to avoid repeated requests
          */

  constructor(private cms: CmsApiService,
              private cmsQueue: CmsQueueService,
              private translate: TranslateService) { }


  private getRef(params) {
    let refData, res;
    if (this.arefs[params.refId]) {
      refData = this.arefs[params.refId];
    }

    if (refData) {
      if (params.allProps) {
        res = refData;
      } else {
        res = this.getLabelProp(refData, params.labelProp);
      }
    }

    return res;
  };

  public getRefsFromServer(refIds, fn) {
    this.cms.searchJson({filters: {artifact_id: refIds}}).then(
      (data) => {
        let items = [];
        if (data[AConst.ARTIFACTS]) {
          items = data[AConst.ARTIFACTS];
        } else {
          console.log("Search failed");
        }
        fn(items);
        },
      () => {
        console.log("Failed searching for ref ids");
        fn([]);
      });
  };



  /*
   Asynchronously retrieval of reference data from server.
   If multiple sources are asking for the same reference ID
   simultaneously, the resulting function parameter is added to a
   list of callbacks in order to prevent unnecessary server requests.
   This occurs e.g. in artifact search lists.
   */
  private getRefFromServer(params) {
    let refProp = params.refProp || "artifact_id";
    let searchParams = {
      filters: {
        object_type: params.refTypeId
      }
    };
    searchParams.filters[refProp] = params.refId;
    this.cmsQueue.runCmsFnWithQueue(
      "searchJson", searchParams, false).then(
      (data) => {
        let artifact;
        if (data[AConst.ARTIFACTS].length > 0) {
          artifact = data[AConst.ARTIFACTS][0];
          this.storeRef(params.refId, artifact);
        } else {
          artifact = this.getNotFoundArtifact(params.refId);
        }
        if (params.allProps) {
          params.fn(artifact)
        } else {
          params.fn(this.getLabelProp(artifact, params.labelProp));
        }
      },
      (response) => {
        let message = response.data ? response.data.message : "";
        console.log("Error getting ref: " + message + ": " + response.status);
      }
    );
  };

  private getNotFoundArtifact(refId) {
    return {
      artifact_id: refId,
      artifact_name: this.translate.instant(
        AConst.TRANS__REFERENCE__REF_NOT_AVAILABLE) +
      ": " + refId,
      notFound: true
    };
  };

  private getLabelProp(refData, labelProps) {
    let res, t, labelProp;
    if (labelProps.constructor !== Array) {
      labelProps = [labelProps];
    }
    for (t = 0; t < labelProps.length; t++) {
      labelProp = labelProps[t];
      if (labelProp !== 'all' && refData !== null) {
        res = refData[labelProp];
      } else {
        res = refData;
      }
      if (res !== undefined) {
        break;
      }
    }
    if (res === undefined) {
      console.log("Missing property '" + labelProps + "' in " +
        JSON.stringify(refData));
    }
    return res;
  };

  private storeRef(refId, refData) {
    this.arefs[refId] = refData;
  };

  /**
   * First try to find reference id "ref_id" in artifact arefs
   * If not found, load concepts from server and search in the
   * results.
   * @param params parameters needed for mapping a ref id
   * @params.refId the ref id to be mapped
   * @params.refProp the name of the property that will be used in
   * the search. If omitted, will be set based on the refId value
   * @params.labelProp the search item property that will be returned.
   * If not set, will be set to "artifact_name"
   * @params.refTypeId the reference type (actor, thing, ct_10 etc).
   * If omitted, will try to set from refId parameter.
   * @params.parentsOnly if set to true, and reference data is a
   * hierarchic list, retrieve only parent items
   * @params.allProps if set to true, the whole search item will be
   * returned instead of the only the property defined in labelProp
   * @params.fn since the mapRef method may run an asynchronous call
   * towards the server, we need an async callback function
   *
   */
  public mapRef(params) {
    let ref;
    if (typeof(params.refId) !== 'string') {
      throw "Unable to map field value " + params.refId +
      ", of ref type '" + params.refTypeId + "'";
    }
    if (params.refId.indexOf('user.') === 0) {
      throw "Default value '" + params.refId +
      "' has not been mapped! Please check issue with" +
      " default value mapping on server";
    }
    if (!params.labelProp) {
      params.labelProp = ["artifact_name"];
    }
    ref = this.getRef(params);
    if (typeof ref === "undefined") {
      // Art not defined or reference not found in arefs.
      // Load from server instead.
      this.getRefFromServer(params);
    } else {
      params.fn(ref);
    }

  };

}
