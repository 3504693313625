import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ObjectEditService} from '../../core/object-edit.service';
import {SearchSelectorService} from '../../core/search-selector.service';
import {FieldValueService} from '../../core/field-value.service';
import {CmsApiService} from '../../core/cms-api.service';
import {FieldMetaHandlerService} from '../../core/field-meta-handler.service';
import {FieldParameters} from '../../core/field-parameters';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-template-group-select-button',
  templateUrl: './template-group-select-button.component.html',
  styleUrls: ['./template-group-select-button.component.scss']
})
export class TemplateGroupSelectButtonComponent implements OnChanges {
  @Input() object: any;
  @Input() templateGroupId: string;
  @Input() noPreselect: boolean;
  @Input() disabled: boolean;
  @Input() simple: boolean;
  @Input() collapseOnSmallScreen: boolean;
  @Output() templateSelectorCreated = new EventEmitter<any>();
  @Output() changeTemplateGroup = new EventEmitter<string>();

  private model;
  private fieldParameters: FieldParameters;
  templateSelectorContainer: any;
  locked: boolean;

  constructor(private objectEditService: ObjectEditService,
              private selectorHandler: SearchSelectorService,
              private fieldValueService: FieldValueService,
              private cms: CmsApiService,
              private fieldMetaHandler: FieldMetaHandlerService) {
  }

  ngOnChanges() {
    this.initSelector().then();
  }

  enableTemplateSelector() {
    if (this.templateSelectorContainer && this.templateSelectorContainer.selectorEnabled) {
      this.selectorHandler.disableSelector(this.templateSelectorContainer);
    } else {
      this.getListSearchContainer();
    }
  }

  private async initSelector() {
    if (!this.noPreselect) {
      const info = await this.cms.getUserTemplateGroupInfo();
      if (info) {
        this.doChangeTemplateGroup(info[AConst.TEMPLATE_GROUP_ID]);
        this.templateGroupId = info[AConst.TEMPLATE_GROUP_ID];
        this.locked = info[AConst.LOCKED];
      }
    }
    await this.createTemplateSelector();
  }

  private createTemplateSelector() {
    let modelTypeId;
    if (this.object) {
      modelTypeId = this.object[AConst.ARTIFACT_TYPE_ID];
    }
    this.checkTemplateGroupExists().then((found) => {
      if (!found) {
        this.templateGroupId = null;
      }
      this.objectEditService.createModelItem(
        'TemplateGroupSelector',
        {
          model_type_id: modelTypeId,
          template_group_id: this.templateGroupId
        }
      ).then(
        mod => {
          this.model = mod;
          this.fieldParameters = new FieldParameters();
          this.fieldParameters.rootObject = this.model;
          this.fieldParameters.object = this.model;
          this.fieldParameters.field = {name: AConst.TEMPLATE_GROUP_ID};
        }
      );
    });
  }

  private checkTemplateGroupExists() {
    return new Promise((resolve) => {
      this.cms.getMetaObject({
        objectType: 'TemplateGroups'
      }).then((data) => {
        let groupFound = false, t, group;
        if (data && data[AConst.TEMPLATE_GROUPS]) {
          for (t = 0; t < data[AConst.TEMPLATE_GROUPS].length; t++) {
            group = data[AConst.TEMPLATE_GROUPS][t];
            if (group[AConst.TEMPLATE_GROUP_ID] === this.templateGroupId) {
              groupFound = true;
              break;
            }
          }
        }
        resolve(groupFound);
      });
    });
  }

  private getSelectedValues() {
    const res = [];
    const value = this.fieldValueService.getFieldValue(this.fieldParameters.object, AConst.TEMPLATE_GROUP_ID);
    if (value) {
      res.push(value);
    }
    return res;
  }

  private doChangeTemplateGroup(newGroupId) {
    if (newGroupId !== this.templateGroupId) {
      this.changeTemplateGroup.emit(newGroupId);
    }
  }

  private getListSearchContainer() {
    let selector, params, selected;
    if (this.fieldParameters) {
      const meta = this.fieldMetaHandler.getMetaParamsFromFieldParameters(this.fieldParameters, 'current', true);
      selector = meta.reference[AConst.SELECTOR];
      selected = this.getSelectedValues();
      params = {
        selected: selected
      };
      this.templateSelectorContainer = this.selectorHandler.createSelectorContainer(selector);

      this.templateSelectorCreated.emit(this.templateSelectorContainer);

      this.selectorHandler.enableSelector(
        selector, this.templateSelectorContainer, params,
        {
          searchContainerCreated: (/*sc*/) => {
          },
          selectorCallback: (selectedObj) => {
            if (selectedObj.length > 0) {
              this.model[AConst.TEMPLATE_GROUP_ID] = selectedObj[0][AConst.TEMPLATE_GROUP_ID];
            } else {
              this.model[AConst.TEMPLATE_GROUP_ID] = null;
            }
            this.doChangeTemplateGroup(this.model[AConst.TEMPLATE_GROUP_ID]);
            this.selectorHandler.disableSelector(this.templateSelectorContainer);
          }
        }
      );
    }
  }

}
