import {Component, ElementRef, Input, OnChanges, OnDestroy, QueryList, Renderer2, ViewChild, ViewChildren} from '@angular/core';
import {StateService, UIRouterGlobals} from '@uirouter/core';
import {ContentMenusService} from '../content-menus.service';
import {UiToolsService} from '../../core/ui-tools.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {AConst} from '../../core/a-const.enum';
import {ObjectContentMainTabComponent} from '../object-content-main-tab/object-content-main-tab.component';
import {ContentInfo} from '../../core/content-info.service';

@Component({
  selector: 'app-object-content-tabs',
  templateUrl: './object-content-tabs.component.html',
  styleUrls: ['./object-content-tabs.component.scss']
})
export class ObjectContentTabsComponent implements OnChanges, OnDestroy {

  @ViewChild('menuControl', { static: true }) menuControl: ElementRef;
  @ViewChildren(ObjectContentMainTabComponent) menuItems: QueryList<ObjectContentMainTabComponent>;

  @Input() contentInfo: ContentInfo;

  AConst = AConst;
  menuItemsReady = false;

  myRoot;
  private isNotComputer: boolean;

  constructor(private stateService: StateService,
              private uiRouterGlobals: UIRouterGlobals,
              private renderer: Renderer2,
              private contentMenusService: ContentMenusService,
              private uiTools: UiToolsService,
              public currentObjectService: CurrentObjectService) {
  }

  ngOnChanges() {
    this.contentInfo.visibleDropDowns = [];
    this.myRoot = this.menuControl.nativeElement;
    this.isNotComputer = !this.uiTools.getClientType().isComputer;
    if (this.contentInfo && this.contentInfo.artifact) {
      this.contentInfo.setContentMenus = () => {
        this.setContentMenus();
      };
      this.setContentMenus();
    }

    setTimeout(() => {
      this.menuItemsReady = true;
    }, 1000);
  }

  ngOnDestroy(): void {
  }

  openMenu(menu) {
    let t, sm;
    const isOpen = menu.open;
    if (isOpen && menu[AConst.LEAF_MENU]) {
      return;
    }
    this.closeMenus();

    if (!isOpen) {
      menu.open = true;
      if (menu[AConst.LEAF_MENU]) {
        // TODO: In the previous AngularJS based component used $location.search method to replace url without doing actual state change
        // but has not found a way to do this in Angular:
        // $location.search('listName', menu[AConst.CONTENT_LIST]);
        // this.stateService.go(this.stateService.current, {'listName': menu[AConst.CONTENT_LIST]});
        this.setCurrentList(menu[AConst.CONTENT_LIST]);
        this.contentInfo.menus.forEach(item => {
          if (item[AConst.CONTENT_LIST] === menu[AConst.CONTENT_LIST]) {
            this.contentInfo.selectedMainMenu = menu;
          }
        });
      } else {
        this.contentInfo.selectedMainMenu = menu;
        for (t = 0; t < menu[AConst.SUB_MENUS].length; t++) {
          sm = menu[AConst.SUB_MENUS][t];
          if (sm.count) {
            this.openMenu(menu[AConst.SUB_MENUS][t]);
            break;
          }
          if (sm.count === 0) {
            this.openMenu(menu[AConst.SUB_MENUS][0]);
            menu.open = true;
          }
        }
      }
    }
  }

  private closeMenus(menus?) {
    (menus || this.contentInfo.menus || []).forEach(menu => {
      menu.open = false;
      if (menu[AConst.SUB_MENUS]) {
        this.closeMenus(menu[AConst.SUB_MENUS]);
      }
    });
  }

  private setCurrentList(listName) {
    const ci = this.contentInfo;
    ci.curListName = listName;
    ci.curListSearchContainer = null;
    ci.contentListSource = null;
    if (ci.searchContainers[listName]) {
      this.contentMenusService.runListSearch(ci.curListName, ci).then(() => {
        this.contentMenusService.setActiveTopMenu(ci.menus, ci);
      });
    } else if (ci.sources && ci.sources[listName]) {
      ci.contentListSource = ci.sources[listName];
    }
  }

  private setContentMenus() {
    const noListSearch = this.currentObjectService.isEditing || this.isNotComputer;
    this.contentInfo.setCurrentList = (listName) => {
      this.setCurrentList(listName);
    };
    if (!this.contentInfo.menus) {
      this.contentMenusService.getMenus(this.contentInfo).then(
        menus => {
          this.contentInfo.menus = menus;
          this.contentMenusService.setContentMenus(menus, this.contentInfo, this.uiRouterGlobals.params, noListSearch);
        });
    } else {
      this.contentMenusService.setContentMenus(this.contentInfo.menus, this.contentInfo, this.uiRouterGlobals.params, noListSearch);
    }
  }


}
