import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {CommonsService} from '../../core/commons.service';
import {SettingsService} from '../../core/settings.service';

@Component({
  selector: 'app-object-icon',
  templateUrl: './object-icon.component.html',
  styleUrls: ['./object-icon.component.scss']
})
export class ObjectIconComponent implements OnInit {
  icon: string = null;

  @Input() iconClass: string;
  @Input() object;
  @Input() objectType;
  @Input() iconSize;

  constructor(private commons: CommonsService,
              private settings: SettingsService) {
  }

  ngOnInit() {
    this.setObjectType();
    this.setIconFromObjectType();
  }

  setObjectType() {
    let objType, level, fPath;
    if (this.object) {
      objType = this.commons.getObjectType(this.object);
      fPath = this.object[AConst.F_PATH];
      if (objType === 'concept' ||
        objType === 'conceptType' ||
        objType.startsWith('ct_')) {
        objType = 'concept';
        if (fPath) {
          level = fPath.split('»').length - 1;
          if (level === 1) {
            objType = 'ConceptChild';
          } else if (level >= 2) {
            objType = 'ConceptGrandChild';
          }
        }
      }
      this.objectType = objType;
    }
  }

  setIconFromObjectType() {
    if (this.objectType) {
      this.icon = this.settings.objectIcon(
        this.objectType.startsWith('ct_') ?
          'concept' : this.objectType,
        this.iconSize, this.object, false);
    }
  }
}
