import {Component, Input, OnInit} from '@angular/core';
import {InlineArrayHeaderContainer} from '../../inline-array-header-container';

@Component({
  selector: 'app-inline-array-header-value',
  templateUrl: './inline-array-header-value.component.html',
  styleUrls: ['./inline-array-header-value.component.scss']
})
export class InlineArrayHeaderValueComponent implements OnInit {

  @Input() label;
  @Input() container: InlineArrayHeaderContainer;

  constructor() {
  }

  ngOnInit() {
  }

  isString() {
    return !this.label.sRef && typeof this.container.values[this.label.key] === 'string';
  }

}
