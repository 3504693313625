import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {ContentInfo} from '../../core/content-info.service';

@Component({
  selector: 'app-object-content-list',
  templateUrl: './object-content-list.component.html',
  styleUrls: ['./object-content-list.component.scss']
})
export class ObjectContentListComponent implements OnInit {
  @Input() contentInfo: ContentInfo;
  @Output() artifactUpdated = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  setArtifactUpdated() {
    this.artifactUpdated.emit(true);
  }
}
