import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SectionsContainer} from '../../core/sections-container';
import {FieldParameters} from '../../core/field-parameters';
import {OptionsService} from '../../core/options.service';
import {AConst} from '../../core/a-const.enum';
import {CmsApiService} from '../../core/cms-api.service';
import {FieldValues} from '../../core/object-view';

@Component({
  selector: 'app-edit-field-object-reference',
  templateUrl: './edit-field-object-reference.component.html',
  styleUrls: ['./edit-field-object-reference.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldObjectReferenceComponent)
    }
  ]
})
export class EditFieldObjectReferenceComponent implements OnInit, ControlValueAccessor {

  @Input() sectionsContainer: SectionsContainer;
  @Input() formControlName: string;
  @Input() object: any;
  @Input() field: any;

  addNewTitle = '';
  fieldValues: FieldValues;
  artifactId = null;

  private fieldParameters: FieldParameters;
  private addNewParams;

  constructor(private optionsService: OptionsService,
              private cms: CmsApiService) {
  }

  ngOnInit() {
    this.artifactId = this.sectionsContainer.rootObject[AConst.ARTIFACT_ID];
    this.addNewParams = this.field[AConst.REFERENCE][AConst.ADD_NEW_PARAMS];
    this.addNewTitle = this.addNewParams[AConst.ADD_BUTTON_TITLE];
    this.setObjectIds();
  }

  openCreateNew() {
    this.optionsService.createOption(this.fieldParameters).then(() => {
      this.setObjectIds();
    }, reason => {
      if (reason) {
        console.warn('Error storing new artifact: ' + reason.toLocaleString());
      }
    });
  }

  private setObjectIds() {
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters.field = this.field;
    this.fieldParameters.object = this.object;
    this.fieldParameters.rootObject = this.sectionsContainer.rootObject;

    if (this.artifactId) {
      this.cms.getObjectFieldItems({
        artifact_id: this.artifactId,
        fieldName: this.field.name
      }).then(items => {
        if (items && items.length) {
          // Put all field values into a single FieldValues object
          const fieldValues = new FieldValues();
          fieldValues.values = [];
          items.forEach(item => {
            fieldValues.values = fieldValues.values.concat(item.field_values.values);
          });
          this.fieldValues = fieldValues;
        }
      });
    }

    const searchParams = {
      meta: this.field,
      fieldParameters: this.fieldParameters
    };
    this.optionsService.searchOptions(searchParams).then(options => {
      if (options && options.length) {
        this.cms.getArtifact({artifact_id: options[0][AConst.ARTIFACT_ID]}).then(prevObject => {
          this.fieldParameters.prevObject = prevObject;
        });
      }
    });

  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
  }

}
