import {Component, Input, OnChanges} from '@angular/core';
import {SearchHandlerService} from '../../search-handler.service';
import {CommonsService} from '../../../core/commons.service';
import {SearchContainer} from '../../search-container';
import {AConst} from '../../../core/a-const.enum';
import {TranslateService} from '@ngx-translate/core';
import {CheckFilter} from '../../filter-group';

@Component({
  selector: 'app-search-filter-group',
  templateUrl: './search-filter-group.component.html',
  styleUrls: ['./search-filter-group.component.scss']
})
export class SearchFilterGroupComponent implements OnChanges {

  @Input() filterGroup;
  @Input() sideMenu;
  @Input() searchContainer: SearchContainer;

  showFilterItem = true;
  hasManyFilters = false;
  AConst = AConst;
  filterFilter = {
    query: '',
    hasMore: false,
    showMore: false,
    filterCount: 0
  };
  visibleFilters: Array<CheckFilter> = [];

  constructor(private translate: TranslateService,
              private searchHandler: SearchHandlerService,
              private commons: CommonsService) {
  }

  ngOnChanges() {
    if (this.filterGroup) {
      if (this.filterGroup.checkFilters.length > AConst.MAX_FILTER_LENGTH) {
        this.hasManyFilters = true;
      }
    }

    if (this.searchContainer.searchResultViews.default === 'selector' ||
      this.searchContainer.searchResultViews.default === 'content-list') {
      this.showFilterItem = false;
    }
    this.setVisibleFilters();
  }

  checkFilter($event, filter) {
    $event.preventDefault();
    this.searchHandler.checkCheckFilter(filter, this.searchContainer).then(() => {
      this.setVisibleFilters();
    });
  }

  toggle() {
    this.showFilterItem = !this.showFilterItem;
  }

  showMore() {
    this.filterFilter.showMore = true;
    this.setVisibleFilters();
  }

  getTotalCount() {
    const facetCount = this.searchHandler.getFacetCount(this.filterGroup.name, this.searchContainer);
    return facetCount ? facetCount.totalCount : 0;
  }

  getFilterCount(filter) {
    filter.count = this.searchHandler.getFilterCount(filter, this.searchContainer);
    return filter.count;
  }

  setVisibleFilters() {
    const filters = this.getMaxFilters(this.getQueriedFilters(this.filterGroup.checkFilters));
    this.visibleFilters = this.commons.sortArray(filters, 'noTransTitle');
  }

  private getQueriedFilters(filters): Array<CheckFilter> {
    const res = [];
    this.filterFilter.filterCount = 0;
    filters.forEach(filter => {
      let q, name, found = true;
      if (!filter.checked && this.filterFilter.query) {
        q = this.filterFilter.query.toLocaleLowerCase();
        name = filter.noTransTitle || this.translate.instant(filter.title);
        found = name.toLocaleLowerCase().indexOf(q) !== -1;
      }
      if (found) {
        res.push(filter);
        this.filterFilter.filterCount++;
      }
    });
    return res;
  }

  private getMaxFilters(filters) {
    const res = [];
    filters.forEach((filter, index) => {
      if (this.checkMaxFilterLen(index)) {
        res.push(filter);
      }
    });
    return res;
  }

  private checkMaxFilterLen(index) {
    let res = true;
    this.filterFilter.hasMore = false;
    if (index >= AConst.MAX_FILTER_LENGTH && !this.filterFilter.showMore) {
      this.filterFilter.hasMore = true;
      res = false;
    }
    return res;
  }

}
