import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {AConst} from '../core/a-const.enum';
import {CmsQueueService} from '../core/cms-queue.service';
import {ContentListSourceArrayComponent} from './content-list-source-array/content-list-source-array.component';
import {ListPagerComponent} from './list-pager/list-pager.component';
import {SharedModule} from '../shared/shared.module';
import {SectionMenuScrollComponent} from './section-menu-scroll/section-menu-scroll.component';
import {SearchCollapseComponent} from './search-collapse/search-collapse.component';
import {ObjectSearchMenuComponent} from './object-search-menu/object-search-menu.component';
import {ObjectSearchModule} from '../object-search/object-search.module';
import {SectionMenuComponent} from './section-menu/section-menu.component';
import {ObjectMenusComponent} from './object-menus/object-menus.component';
import {ObjectMenusSmallComponent} from './object-menus-small/object-menus-small.component';
import {OrderByTranslatedPipe} from './order-by-translated.pipe';
import {ObjectMenuUploadComponent} from './object-menu-upload/object-menu-upload.component';
import {MediaModule} from '../media/media.module';
import {ObjectEditModule} from '../object-edit/object-edit.module';
import {ImageAnnotationModule} from '../image-annotation/image-annotation.module';
import { ObjectMenuReportComponent } from './object-menu-report/object-menu-report.component';
import {ReportModule} from '../report/report.module';
import { ObjectMenuCreateComponent } from './object-menu-create/object-menu-create.component';
import { ObjectMenuCurrentComponent } from './object-menu-current/object-menu-current.component';
import { ObjectContentTabsComponent } from './object-content-tabs/object-content-tabs.component';
import { ObjectContentMainTabComponent } from './object-content-main-tab/object-content-main-tab.component';
import { ObjectContentDropdownTabComponent } from './object-content-dropdown-tab/object-content-dropdown-tab.component';
import { ObjectContentDropdownMenuComponent } from './object-content-dropdown-menu/object-content-dropdown-menu.component';
import { ObjectContentListComponent } from './object-content-list/object-content-list.component';
import { ObjectViewAndEditComponent } from './object-view-and-edit/object-view-and-edit.component';
import { ObjectUpdateLogComponent } from './object-update-log/object-update-log.component';
import {UIRouterModule} from '@uirouter/angular';

@NgModule({
  exports: [
    ObjectSearchMenuComponent,
    SectionMenuComponent,
    ObjectMenusComponent,
    ObjectMenusSmallComponent,
    ObjectMenuCurrentComponent,
    SectionMenuScrollComponent,
    ObjectContentTabsComponent,
    ObjectViewAndEditComponent,
    ObjectUpdateLogComponent,
    ObjectContentListComponent,
  ],
  imports: [
    FormsModule,
    UIRouterModule,
    CommonModule,
    TranslateModule,
    SharedModule,
    ObjectSearchModule,
    MediaModule,
    ObjectEditModule,
    ImageAnnotationModule,
    ReportModule
  ],
  declarations: [
    SectionMenuComponent,
    SectionMenuScrollComponent,
    SearchCollapseComponent,
    ObjectSearchMenuComponent,
    ObjectMenusComponent,
    ObjectMenusSmallComponent,
    OrderByTranslatedPipe,
    ContentListSourceArrayComponent,
    ListPagerComponent,
    ObjectMenuUploadComponent,
    ObjectMenuReportComponent,
    ObjectMenuCreateComponent,
    ObjectMenuCurrentComponent,
    ObjectContentTabsComponent,
    ObjectContentMainTabComponent,
    ObjectContentDropdownTabComponent,
    ObjectContentDropdownMenuComponent,
    ObjectContentListComponent,
    ObjectViewAndEditComponent,
    ObjectUpdateLogComponent
  ]
})
export class ObjectViewModule {
  constructor(private translate: TranslateService,
              private cmsQueueService: CmsQueueService) {
    cmsQueueService.runCmsFnWithQueue('getSettings').then(data => {
      translate.use(data[AConst.CLIENT_LANGUAGE]);
    });
  }
}
