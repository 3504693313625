import { Injectable } from '@angular/core';
import {ReporterTranslationService} from './reporter-translation.service';
import {ReporterPdfImageConverterService} from './reporter-pdf-image-converter.service';
import {ReporterUtilityService} from './reporter-utility.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Service hosting methods used when parsing single artifacts.
 * @type {{parse}}
 */
export class ReporterPdfSingleArtifactParserService {

  constructor(private reporterPdfImageConverter: ReporterPdfImageConverterService,
              private reporterTranslation: ReporterTranslationService,
              private reporterUtility: ReporterUtilityService
              ) { }

  /**
   * Creates a pdfMake { image: .... } object.
   * @param url
   * @param imageSize
   * @param alignment
   * @param fillColor
   * @returns {{image: '...', width: number}}
   * @private
   */
  private static createImageJson(url, imageSize, alignment, fillColor) {
    return {
      image: url + '?height=1080&border_color=(221,221,221)',
      fit: imageSize,
      alignment: alignment,
      fillColor: fillColor
    };
  }

  /**
   * Wraps long words at the given position by inserting a space.
   * @param input
   * @param breakAt
   * @returns {*}
   * @private
   */
  private static wordWrap(input, breakAt) {
    const re = /([\\;/_\-»])(?![^<]*>|[^<>]*<\/)/gm;
    const subst = '$1\ ';
    const maxLengthWithoutBreak = breakAt;

    if (typeof input !== 'string') {
      return input;
    }

    if (input.length > maxLengthWithoutBreak &&
      !input.match(/([\\;/_\-»\s])/gm)) {
      return input.substr(0, maxLengthWithoutBreak) + ' ' +
        input.substr(maxLengthWithoutBreak);
    }

    if (input && input.replace !== undefined) {
      return input.replace(re, subst);
    }
  }

  /**
   * Creates a pdfMake object structure containing the data from the overview fields.
   * @param overviewFieldsDataSet
   * @returns {{table: {headerRows: number, widths: string[]}, layout: string}}
   * @private
   */
  private parseOverviewFields(overviewFieldsDataSet) {
    const table = {
      headerRows: 1,
      dontBreakRows: true,
      keepWithHeaderRows: 1,
      widths: ['auto', '*'],
      body: null
    };

    const rows = [];

    overviewFieldsDataSet.map((fld) => {
      const cols = [];

      let fldValue = ((fld.field_type === 'array' && Array.isArray(fld.value)) ? fld.value.join(' ') : fld.value);
      fldValue = ReporterPdfSingleArtifactParserService.wordWrap(fldValue, 18);

      let fldName = this.reporterTranslation.getString(fld.field_title);

      if (fld.name === 'motif_description') {
        cols.push({
          colSpan: 2,
          fontSize: 7,
          text: [
            {text: fldName.toUpperCase() + '\n', color: '#7e97ad'},
            {text: fldValue, fontSize: 7}
          ]
        });
        cols.push({});
      } else {
        /*
          PRIMUS-1030:
          Workaround that uses the admin-title instead of the title, when displaying date-/timespan fields.
         */
        if (fld.class === 'precision-date-field') {
          if (typeof(fld.admin_title) !== 'undefined') {
            fldName = this.reporterTranslation.getString(fld.admin_title).toUpperCase();
          } else if (fld.field_title !== '') {
            fldName = fld.field_title;
          }
        } else {
          fldName = fldName.toUpperCase();
        }

        cols.push({
          text: fldName,
          fontSize: 7,
          color: '#7e97ad'
        });
        cols.push({text: fldValue, fontSize: 7});
      }
      rows.push(cols);
    });

    table.body = rows;

    return {
      table: table,
      layout: { hLineWidth: function () { return 1; },
        vLineWidth: function () { return 0; },
        hLineColor: function () { return '#7e97ad'; }
      },
      margin: [0, 0, 0, 15]
    };
  }

  /**
   * Parses the artifacts, returning a pdfMake object structure used when rendering the report.
   * @param artifact
   * @param imageSize
   * @param imageAlign
   * @param overviewFieldsDataSet
   * @returns {*}
   */
  parse(artifact, imageSize, imageAlign, overviewFieldsDataSet) {
    return new Promise((resolve) => {
      this.reporterPdfImageConverter.convert(artifact).then((converted: any) => {
        const contents = {
          title: null,
          data: null,
          image: null
        };

        if (converted['b64Img'] !== undefined && converted['b64Img'] !== null)  {
          contents.image = ReporterPdfSingleArtifactParserService.createImageJson(converted['b64Img'], imageSize, imageAlign, '#dddddd');
        }

        for (const prop in converted) {
          if (converted.hasOwnProperty(prop)) {
            switch (prop) {
              case 'artifact_name':
                const header = this.reporterUtility.createColouredSectionHeader(converted[prop], [0, 0, 0, 0]);
                contents.title = header;
                break;
              case 'overview_fields':
                contents.data = this.parseOverviewFields(overviewFieldsDataSet);
                break;
            }
          }
        }
        resolve(contents);
      });
    });
  }

}
