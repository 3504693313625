import {Injectable} from '@angular/core';
import {AConst} from './a-const.enum';
import {CmsApiService} from './cms-api.service';
import {ArefHandlerService} from './aref-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectNameService {
  objectNameSet;
  promise;
  searchParamsList;

  constructor(private cms: CmsApiService,
              private arefHandler: ArefHandlerService) {
  }


  public getObjectName(params, fn) {
    const obj = params.object;

    if (obj[AConst.ARTIFACT_NAME]) {
      this.setNameFromArtifactName(obj, params, fn);
    } else if (obj[AConst.ARTIFACT_ID]) {
      this.searchArtifactName(obj[AConst.ARTIFACT_ID], params, fn);
    } else if (obj[AConst.CONTEXT_ARTIFACT_ID]) {
      const valueName = obj[AConst.CONTEXT_ARTIFACT_ID + '_value'];
      if (valueName) {
        params.name = valueName;
        this.setName(params, fn);
      } else {
        this.searchArtifactName(obj[AConst.CONTEXT_ARTIFACT_ID], params, fn);
      }
    } else {
      console.log('Unable to get object name!');
    }
  }

  // $$objectName
  public getObjectNames(params, fn) {
    params.objects.forEach((obj, idx) => {
      this.setNameWithRegisterLevel({
        name: obj[AConst.ARTIFACT_NAME],
        object: obj,
        isLast: idx === params.objects.length - 1,
        showRegistrationLevel: params.showRegistrationLevel
      }, fn);
    });
  }

  private setNameFromArtifactName(obj, params, fn) {
    params.name = obj[AConst.ARTIFACT_NAME];
    params.translationData = obj.$$translationData;
    this.setNameWithRegisterLevel(params, fn);
  }

  private searchArtifactName(artifactId, params, fn) {
    this.cumulativeSearch({
      artifactId: artifactId,
      params: params,
      fn: fn
    });
  }

  private cumulativeSearch(searchParams) {
    if (!this.promise) {
      this.searchParamsList = [searchParams];
      this.promise = setTimeout(() => {
        this.searchCumulative(Object.assign([], this.searchParamsList));
        this.promise = null;
      }, 500);
    } else {
      this.searchParamsList.push(searchParams);
    }
  }

  // Removes doublets
  private getArtifactIdsFromSearchParamsList(searchParamsList) {
    const artifactIdObj = {}, artifactIds = [];
    searchParamsList.forEach((searchParams) => {
      if (Array.isArray(searchParams.artifactId)) {
        searchParams.artifactId.forEach((artifactId) => {
          artifactIdObj[searchParams.artifactId] = artifactId;
        });
      } else {
        artifactIdObj[searchParams.artifactId] =
          searchParams.artifactId;
      }
    });
    Object.keys(artifactIdObj).forEach(objId => {
      artifactIds.push(objId);

    });
    return artifactIds;
  }

  private searchCumulative(searchParamsList) {
    const artifactIds = this.getArtifactIdsFromSearchParamsList(
      searchParamsList);
    this.cms.searchJson({
      filters: {artifact_id: artifactIds},
      rows: 10000,
      fl: [AConst.ARTIFACT_NAME, AConst.ARTIFACT_ID]
    }).then((data) => {
        this.setCumulativeSearchRes(
          searchParamsList, data[AConst.ARTIFACTS]);
      },
      () => {
        this.setCumulativeSearchRes(searchParamsList, []);
        console.log('Failed search for ids ' + artifactIds);
      }
    );
  }

  // Return a list of object names from object ids, the names
  // are also set directly on each object using attribute

  private setCumulativeSearchRes(searchParamsList, objects) {
    const searchResObj = {}, notFound = {};
    let nf;
    objects.forEach((object) => {
      searchResObj[object[AConst.ARTIFACT_ID]] = object;
    });
    searchParamsList.forEach((searchParams) => {
      const searchArtId = searchParams.artifactId;
      let searchObj, nameObj;
      searchObj = searchResObj[searchArtId];
      if (searchObj) {
        nameObj = searchObj;
      } else {
        notFound[searchArtId] = searchArtId;
        nameObj = {
          artifact_name: 'TRANS__ARTIFACT_NOT_FOUND',
          $$translationData: {id: searchArtId}
        };
      }
      this.setNameFromArtifactName(
        nameObj, searchParams.params, searchParams.fn);
    });
    nf = Object.keys(notFound);
    if (nf.length > 0) {
      console.log('Not found: ' + JSON.stringify(nf));
    }
  }

  private setNameWithRegisterLevel(params, fn) {
    if (params.showRegistrationLevel &&
      params.object[AConst.REG_LEVEL_TYPE_ID]) {
      this.arefHandler.mapRef({
        refId: params.object[AConst.REG_LEVEL_TYPE_ID],
        fn: (level) => {
          params.registrationLevel = level;
          this.setName(params, fn);
        }
      });
    } else {
      this.setName(params, fn);
    }
  }

  private setName(params, fn) {
    let name = params.name;
    if (!name) {
      throw new Error('Name missing');
    }
    if (name.indexOf('TRANS__') === 0) {
      params.translate = true;
    }
    if (params.toLowerCase) {
      name = name.toLocaleLowerCase();
    }
    if (params.replace) {
      name = params.replace.replace('{name}', name);
    }
    this.objectNameSet = true;
    params.name = name;
    if (fn) {
      fn(params);
    }
  }
}
