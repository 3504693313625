import {Injectable} from '@angular/core';
import {Base64Service} from './base64.service';

@Injectable({
  providedIn: 'root'
})
export class CmsAuthService {

  constructor(private base64: Base64Service) {}

  public setAuth(username, password, rememberMe) {
    let authorization = 'Basic ' +
      this.base64.encode(username + ':' + password);
    this.storeAuth(authorization, username, rememberMe);
  };

  public getAuth(fn_done, fn_not_stored) {
    let curAuth, auth;
    curAuth = this.getCurrentAuth();
    if (curAuth) {
      auth = this.getDecodedAuth(curAuth.authorization);
      if (auth) {
        fn_done(auth);
      }
    } else {
      fn_not_stored();
    }
  };

  public clearAuth() {
    let auth = this.getCurrentAuth();
    let ss = this.getSessionStorage();
    if (ss && auth) {
      if (auth.username) {
        ss.removeItem(this.getStoreKey(auth.username));
      } else {
        this.log('User not logged in!');
      }
    }
  };

  public getStoredAuth(username?) {
    let authorization;
    let storeKey;
    let res = null;
    let ls = this.getLocalStorage();
    if (ls) {
      if (!username) {
        username = ls.getItem('last-user');
      }
      if (username) {
        storeKey = this.getStoreKey(username);
        authorization = ls.getItem(storeKey);
        if (authorization) {
          res = this.getDecodedAuth(authorization);
        } else {
          this.log(
            'Expected to find local storage key ' +
            storeKey);
        }
      } else {
        this.log('No user name found or provided');
      }
    }
    return res;
  };

  public useStoredAuth(username, rememberMe) {
    let storedAuth = this.getStoredAuth(username);
    this.storeAuth(storedAuth.authorization, username, rememberMe);
  };

  private log(text) {
    if (window.console) {
      console.log(text);
    }
  };

  private getStoreKey(username) {
    return 'stored-auth-' + username;
  };

  private getSessionStorage() {
    let res = null;
    if (sessionStorage) {
      res = sessionStorage;
    } else {
      this.log('No session storage available');
    }
    return res;
  };

  private getLocalStorage() {
    let res = null;
    if (localStorage) {
      res = localStorage;
    } else {
      this.log('No local storage available');
    }
    return res;
  };

  private storeSessionAuth(authorization, username) {
    let ss = this.getSessionStorage();
    if (ss) {
      ss.setItem(this.getStoreKey(username), authorization);
      ss.setItem('last-user', username);
    }
  };

  private storeLocalAuth(authorization, username) {
    let ls = this.getLocalStorage();
    if (ls) {
      ls.setItem('last-user', username);
      ls.setItem(this.getStoreKey(username), authorization);
    }
  };

  private clearLocalAuth(username) {
    let storeKey = this.getStoreKey(username);
    let ls = this.getLocalStorage();
    if (ls) {
      if (ls.getItem(storeKey)) {
        ls.removeItem(storeKey);
      }
      if (ls.getItem('last-user') === username) {
        ls.removeItem('last-user');
      }
    }
  };

  private storeAuth(authorization, username, rememberMe) {
    this.storeSessionAuth(authorization, username);
    if (rememberMe) {
      this.storeLocalAuth(authorization, username);
    } else {
      this.clearLocalAuth(username);
    }
  };

  private getCurrentAuth() {
    let username, auth, res = null;
    let ss = this.getSessionStorage();
    if (ss) {
      username = ss.getItem('last-user');
      if (username) {
        auth = ss.getItem(this.getStoreKey(username));
        if (auth) {
          res = {
            username: username,
            authorization: auth
          };
        }
      }
    }
    return res;
  };

  private getDecodedAuth(authorization) {
    let decoded = this.base64.decode(authorization.substr(6));
    let authList = decoded.split(':');
    if (authList.length === 2) {
      return {
        username: authList[0],
        password: authList[1],
        authorization: authorization
      };
    } else {
      throw 'Unable to process decoded auth: ' + decoded;
    }
  };

}
