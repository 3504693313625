import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {SearchHandlerService} from '../../search-handler.service';
import {SearchContainer} from '../../search-container';

@Component({
  selector: 'app-search-result-view-thumbnail',
  templateUrl: './search-result-view-thumbnail.component.html',
  styleUrls: ['./search-result-view-thumbnail.component.scss']
})
export class SearchResultViewThumbnailComponent implements OnInit {

  @Input() searchContainer: SearchContainer;

  AConst = AConst;

  constructor(private searchHandler: SearchHandlerService) {
  }

  ngOnInit() {
  }

  select(obj) {
    this.searchHandler.selectSearchResultItem(obj, this.searchContainer);
  }

  getAuth(art) {
    let auth = art[AConst.AUTHORITY];
    if (auth) {
      auth = auth.toLowerCase();
    }
    return auth;
  }

}
