import {Component, Input, OnChanges} from '@angular/core';
import {SectionsContainer} from '../../../core/sections-container';
import {ObjectViewDataParams, ObjectViewDataService} from '../../../core/object-view-data.service';
import {AConst} from '../../../core/a-const.enum';
import {ObjectView} from '../../../core/object-view';

@Component({
  selector: 'app-admin-settings-view-fields',
  templateUrl: './admin-settings-view-fields.component.html',
  styleUrls: ['./admin-settings-view-fields.component.scss']
})
export class AdminSettingsViewFieldsComponent implements OnChanges {

  @Input() sectionsContainer: SectionsContainer;

  AConst = AConst;
  objectViewData: ObjectView;

  constructor(private objectViewDataService: ObjectViewDataService) {
  }

  ngOnChanges() {
    const objectViewDataParams = new ObjectViewDataParams();
    objectViewDataParams.object = this.sectionsContainer.rootObject;
    this.objectViewDataService.createViewData(objectViewDataParams).then(objectViewData => {
      this.objectViewData = objectViewData;
    });
  }

}
