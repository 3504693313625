import {Injectable} from '@angular/core';
import {CmsQueueService} from './cms-queue.service';
import {AConst} from './a-const.enum';

@Injectable({
  providedIn: 'root'
})
export class ModelsService {

  constructor(private cmsQueue: CmsQueueService) {
  }

  models = null;

  getModels(noThrow) {
    if (this.models) {
      return this.models;
    } else {
      if (!noThrow) {
        console.error('Models not loaded yet!');
      }
    }
  }

  getModelsAsync(force?) {
    return new Promise((resolve) => {
      const models = this.models;
      if (models && !force) {
        resolve(models);
      } else {
        this.cmsQueue.runCmsFnWithQueue('getModels', null, force).then(
          (loadedModels) => {
            this.models = loadedModels;
            resolve(loadedModels);
          },
          (response) => {
            const message = response.data ? response.data.message : '';
            console.error('Error models: ' + message + ': ' + response.status);
          }
        );
      }
    });
  }

  getModel(modelName) {
    return this.models ? this.models[modelName] : null;
  }

  getModelAsync(modelName): Promise<any> {
    return new Promise<any>(resolve => {
      this.getModelsAsync().then(models => {
        resolve(models[modelName]);
      });
    });
  }

  getModelTitle(objectType, fn) {
    this.getModelsAsync().then(models => {
        let title;
        const model = models[objectType];
        if (model) {
          title = model[AConst.MODEL_TITLE];
          if (title) {
            fn(title);
          } else {
            this.getModelTitleFromTypeList(objectType, fn);
          }
        } else {
          console.error('Model ' + objectType + ' not found');
        }
      }
    );
  }

  getModelTitleFromTypeList(objectType, fn) {
    this.cmsQueue.runCmsFnWithQueue('searchJson', {
      filters: {
        object_type: 'ct_200',
        description: objectType
      }
    }, false).then(
      (data) => {
        if (data[AConst.ARTIFACTS] && data[AConst.ARTIFACTS].length > 0) {
          fn(data[AConst.ARTIFACTS][0].name);
        } else {
          fn(objectType);
          console.error('No ct_200 value found for \'' + objectType + '\'');
        }
      },
      (response) => {
        const message = response.data ? response.data.message : '';
        console.error('Error searching for title: ' + message + ': ' + response.status);
      }
    );
  }

}
