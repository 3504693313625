import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CommonsService} from '../core/commons.service';

@Pipe({
  name: 'orderByTranslated'
})
export class OrderByTranslatedPipe implements PipeTransform {

  constructor(private translateService: TranslateService,
              private commons: CommonsService) {
  }

  transform(value: any, objKey: string): any {
    const res = [];
    const translated = [];
    const array = <Array<string>>value;
    array.forEach(item => {
      const transKey = objKey ? item[objKey] : item;
      const transRes = this.translateService.get(transKey);
      translated.push({
        key: item,
        label: transRes ? transRes['value'] : transKey
      });
    });
    this.commons.sortArray(translated, 'label').forEach(item => {
      res.push(item['key']);
    });
    return res;
  }

}
