import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchHandlerService} from '../../search-handler.service';
import {CommonsService} from '../../../core/commons.service';
import {AConst} from '../../../core/a-const.enum';
import {SearchContainer} from '../../search-container';

@Component({
  selector: 'app-search-facet-ranges',
  templateUrl: './search-facet-ranges.component.html',
  styleUrls: ['./search-facet-ranges.component.scss']
})
export class SearchFacetRangesComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;
  @Input() sideMenu;

  AConst = AConst;
  showFilterItem = true;

  constructor(private searchHandler: SearchHandlerService,
              private commons: CommonsService) {
  }

  ngOnInit() {
    if (this.searchContainer) {
      this.searchHandler.subscribeToSearchResult(this.searchContainer, this.searchResultCallbackFacetRange);
    }
  }

  ngOnDestroy(): void {
    this.searchHandler.unSubscribeToSearchResult(this.searchContainer, this.searchResultCallbackFacetRange);
  }

  getGroupRangesOrdered(groupRanges) {
    return this.commons.sortArray(groupRanges, 'order');
  }

  checkRange($event, rangeGroup, range) {
    $event.preventDefault();
    if (range[AConst.TOTAL_COUNT] > 0) {
      this.searchHandler.checkRangeFilter(rangeGroup, range, this.searchContainer);
    }
  }

  setRange(params) {
    const rangeGroup = params[0];
    const range = params[1];
    this.searchHandler.setRangeFilter(rangeGroup, range, this.searchContainer);
  }

  toggle() {
    this.showFilterItem = !this.showFilterItem;
    if (this.showFilterItem === true) {
      window.addEventListener('mouseup', this.callback, true);
    } else {
      window.removeEventListener('mouseup', this.callback, true);
    }
  }

  private callback = (item) => {
    const found = this.commons.findClassNameRecursively(item.target, 'dropdownIgnoreClicks');
    if (!found) {
      setTimeout(() => {
        this.showFilterItem = false;
        window.removeEventListener('mouseup', this.callback, true);
      }, 100);
    }
  }

  private searchResultCallbackFacetRange = () => {
    this.searchHandler.setCheckedRangeFacets(this.searchContainer);
    this.searchHandler.setOldRangeFilters(this.searchContainer);
  }


}
