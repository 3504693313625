import {Injectable} from '@angular/core';
import {ActionMenuContainer} from './action-menu-container';

@Injectable({
  providedIn: 'root'
})
export class ActionMenuService {

  container: ActionMenuContainer;

  constructor() {
  }

  newActionMenuContainer() {
    this.container = new ActionMenuContainer();
    return this.container;
  }

  get actionMenuContainer() {
    return this.container;
  }

  isEditableActionMenu() {
    let res = false;
    if (this.container && this.container.currentMenu) {
      res = 'menuCreateObject' === this.container.currentMenu['view'];
    }
    return res;
  }

  cancelActionMenu() {
    if (this.container && this.container.currentMenu) {
      this.container.cancelActionMenu();
    }
  }

}
