import {Component, OnInit} from '@angular/core';
import {UIRouterGlobals} from '@uirouter/core';
import {SearchContainer} from '../search-container';
import {SearchHandlerService} from '../search-handler.service';
import {AConst} from '../../core/a-const.enum';
import {SearchPageService} from '../search-page.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit {

  searchContainer: SearchContainer;
  AConst = AConst;

  constructor(private uiRouterGlobals: UIRouterGlobals,
              private searchHandler: SearchHandlerService,
              private searchPageService: SearchPageService) {
  }

  ngOnInit() {
    this.searchPageService.addSearchContainerCreationListener(this.searchContainerCreationCallback);
    this.searchPageService.checkCreateSearchContainer().then(
      () => {
      });
  }

  searchContainerCreationCallback = (searchContainer) => {
    const stateParams = this.uiRouterGlobals.params;
    const stateParamsPath = this.searchHandler.getDecodedStateParameter(stateParams.path);
    this.searchContainer = searchContainer;
    const stateTargetId = stateParams.targetId || 'none';
    const searchContainerTargetId = searchContainer.targetId || 'none';
    if (!stateParamsPath || searchContainer.path !== stateParamsPath || stateTargetId !== searchContainerTargetId) {
      this.searchHandler.goSearchMenu(
        {
          path: stateParamsPath || searchContainer.path,
          targetId: stateParams.targetId || searchContainer.targetId,
          objectCount: stateParams.objectCount || searchContainer.objectCount
        },
        searchContainer);
    } else {
      searchContainer.query = this.searchHandler.getDecodedStateParameter(stateParams.query);
      this.searchHandler.runSearch(searchContainer).then();
    }
  }
}
