import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {AConst} from './a-const.enum';
import {ModelFactoryService} from './model-factory.service';
import {ModelObjectService} from './model-object.service';
import {FieldValueService} from './field-value.service';
import {FieldStateService} from './field-state.service';
import {FieldParameters} from './field-parameters';

@Injectable({
  providedIn: 'root'
})
export class InlineArrayItemService {

  constructor(private modelFactory: ModelFactoryService,
              private modelObjectSvc: ModelObjectService,
              private fieldValueSvc: FieldValueService,
              private fieldStateSvc: FieldStateService) {
  }

  addInlineArrayItemToForm(fieldParameters: FieldParameters, noToggle?: boolean) {
    return new Promise(resolve => {
      const inlineArray = this.getArrayItems(fieldParameters);
      const validCurrent = this.checkValidInlineArrayItem(fieldParameters);
      if (validCurrent.valid) {
        this.modelFactory.createAddArrayItemAsync(inlineArray, fieldParameters.field.inline[AConst.MODEL], null).then(item => {
          const newIndex = inlineArray.length - 1;
          item['$$justAdded'] = true;
          const parentKey = this.getParentKey(fieldParameters.field);
          this.modelObjectSvc.setFormGroupFieldInlineArrayItem({
            object: fieldParameters.object,
            field: fieldParameters.field,
            group: fieldParameters.sectionsContainer.formGroup,
            parentKey: parentKey,
            index1: fieldParameters.parentIndex
          }, item, newIndex).then(() => {
              if (!noToggle) {
                this.toggleInlineArrayItemOpen(fieldParameters, newIndex);
              }
              item['$$addedIndex'] = newIndex;
              resolve(item);
            }
          );
        });
      } else {
        this.notifyInvalidArrayItem(validCurrent.invalidFieldKey);
      }
    });
  }

  deleteInlineArrayItemFromForm(fieldParameters: FieldParameters) {
    const parentKey = this.getParentKey(fieldParameters.field);
    const arr = fieldParameters.object[fieldParameters.field.name];
    const item = this.modelFactory.deleteArrayItem(arr, fieldParameters.index, fieldParameters.sectionsContainer.rootObject);
    const params = {
      object: fieldParameters.object,
      field: fieldParameters.field,
      group: fieldParameters.sectionsContainer.formGroup,
      parentKey: parentKey,
      index1: fieldParameters.parentIndex
    };
    if (item._destroy) {
      // This makes sure that the store button is enabled after removing array elements
      this.modelObjectSvc.markFormGroupFieldInlineArrayItemAsDeleted(params, fieldParameters.index);
    }
    this.modelObjectSvc.removeFormGroupFieldInlineArrayItem(params, item, fieldParameters.index);
    this.setValidIndex(fieldParameters);
  }

  swapInlineArrayItems(fieldParameters: FieldParameters, swapIndex) {
    const array = fieldParameters.object[fieldParameters.field.name];
    const item1 = array[fieldParameters.index];
    const item2 = array[swapIndex];
    const myOrderNumber = item1[AConst.ORDER_NUMBER];
    item1[AConst.ORDER_NUMBER] = item2[AConst.ORDER_NUMBER];
    item2[AConst.ORDER_NUMBER] = myOrderNumber;
    array[fieldParameters.index] = item2;
    array[swapIndex] = item1;
    this.modelObjectSvc.setSectionsContainerFormGroup(fieldParameters.sectionsContainer).then(() => {
      fieldParameters.index = swapIndex;
      this.markAsDirty(fieldParameters, AConst.ORDER_NUMBER);
    });
  }

  changeInlineArrayItemsOrder(fieldParameters: FieldParameters) {
    this.modelObjectSvc.setSectionsContainerFormGroup(fieldParameters.sectionsContainer).then(() => {
      this.markAsDirty(fieldParameters, AConst.ORDER_NUMBER);
    });
  }

  getOpenArrayItemIndex(fieldParameters: FieldParameters) {
    const arrayObject = this.getArrayItems(fieldParameters);
    return arrayObject['$$openIndex'];
  }

  setOpenArrayItemIndex(fieldParameters: FieldParameters, index) {
    const arrayObject = this.getArrayItems(fieldParameters);
    arrayObject.$$openIndex = index;
  }

  setOpenArrayItemIndexFromKey(object, key) {
    const bracketStart = key.lastIndexOf('[');
    let index;
    if (bracketStart !== -1) {
      const bracketEnd = key.lastIndexOf(']');
      if (bracketEnd !== -1) {
        const arrayObjectKey = key.substring(0, bracketStart);
        const arrayObject = this.getArrayItemsFromKey(object, arrayObjectKey);
        index = Number(key.substring(bracketStart + 1, bracketEnd));
        arrayObject.$$openIndex = index;
      }
    }
    if (index === undefined) {
      console.warn('Unable to get index from ' + key);
    }
  }

  isInlineArrayItemOpen(fieldParameters: FieldParameters) {
    return this.getOpenArrayItemIndex(fieldParameters) === fieldParameters.index;
  }

  getInlineArrayItemId(fieldParameters: FieldParameters) {
    let key;
    if (fieldParameters.parentIndex === undefined) {
      key = fieldParameters.field.key + '[{index1}]';
    } else {
      key = fieldParameters.field.key + '[{index2}]';
    }
    return this.fieldStateSvc.getFieldKeyWhileDrawingInputs({key: key}, fieldParameters.index, fieldParameters.parentIndex);
  }

  toggleInlineArrayItemOpen(fieldParameters: FieldParameters, indexIn?: number) {
    const index = indexIn !== undefined ? indexIn : fieldParameters.index;
    const validCurrent = this.checkValidInlineArrayItem(fieldParameters);
    if (validCurrent.valid) {
      if (this.getOpenArrayItemIndex(fieldParameters) !== index) {
        this.setOpenArrayItemIndex(fieldParameters, index);
      } else {
        this.setOpenArrayItemIndex(fieldParameters, undefined);
      }
    } else {
      this.notifyInvalidArrayItem(validCurrent.invalidFieldKey);
    }
  }

  getArrayItems(fieldParameters: FieldParameters) {
    const fieldKey = this.fieldStateSvc.getFieldKeyWhileDrawingInputs(fieldParameters.field, fieldParameters.parentIndex);
    return this.getArrayItemsFromKey(fieldParameters.sectionsContainer.rootObject, fieldKey);
  }

  moveItem(arr, index, item) {
    const orderField = 'order_number';
    arr.forEach((i, arrIndex) => {
      if (index !== arrIndex &&
        i[orderField] === item[orderField]) {
        // Copy properties from item to array item.
        // This ensures that "hidden" properties, like
        // "$$meta" is copied over from item
        this.copyItem(item, i);
      }

    });
    arr.splice(index, 1);
    this.resetOrder(arr, orderField);
  }

  private copyItem(origItem, newItem) {
    let key;
    for (key in origItem) {
      if (!origItem.hasOwnProperty(key)) {
        continue;
      }
      newItem[key] = origItem[key];
    }
  }

  private resetOrder(arr, orderField) {
    let order = 0;
    arr.forEach(i => {
      i[orderField] = order;
      order++;
    });
  }

  private markAsDirty(fieldParameters: FieldParameters, fieldName) {
    const key = this.getInlineArrayItemId(fieldParameters) + '.' + fieldName;
    const control: AbstractControl = fieldParameters.sectionsContainer.formGroup.controls[key];
    control.markAsDirty();
  }

  private getParentKey(field) {
    let parentKey;
    const lastIndexOf = field.key.lastIndexOf('.');
    if (lastIndexOf !== -1) {
      parentKey = field.key.substring(0, lastIndexOf);
    }
    return parentKey;
  }

  private getArrayItemsFromKey(object, fieldKey) {
    let items = this.fieldValueSvc.getFieldValue(object, fieldKey);
    if (!items) {
      items = [];
      console.warn('Unable to retrieve array items for ' + fieldKey);
    }
    return items;
  }

  private checkValidInlineArrayItem(fieldParameters: FieldParameters) {
    const res = {
      valid: true,
      invalidFieldKey: undefined
    };
    const curIndex = this.getOpenArrayItemIndex(fieldParameters);
    if (curIndex !== undefined) {
      for (const arraySubFieldName in fieldParameters.field[AConst.INLINE_FIELDS]) {
        if (fieldParameters.field[AConst.INLINE_FIELDS].hasOwnProperty(arraySubFieldName)) {
          const subFieldMeta = fieldParameters.field[AConst.INLINE_FIELDS][arraySubFieldName];
          // Generate a sub field key based on parent key, array index and sub field name;
          let subFieldKey;
          if (fieldParameters.parentIndex === undefined) {
            subFieldKey = subFieldMeta.key.replace('{index1}', curIndex);
          } else {
            subFieldKey = subFieldMeta.key.replace('{index1}', fieldParameters.parentIndex).replace('{index2}', curIndex);
          }
          const subFieldCtrl = fieldParameters.sectionsContainer.formGroup.controls[subFieldKey];
          // If the field
          if (subFieldCtrl) {
            if (subFieldCtrl.invalid) {
              res.valid = false;
              res.invalidFieldKey = subFieldKey;
            }
          }
        }
      }
    }
    return res;
  }

  private notifyInvalidArrayItem(fieldKey) {
    let value;
    const inputEl = document.getElementById(fieldKey);
    if (inputEl) {
      value = inputEl.classList.add('shaking');
      setTimeout(() => {
        value = inputEl.classList.remove('shaking');
      }, 4000);
    }
  }

  // Set "valid" index, usually used after deleting items from an
  // array. A valid index is the index of an element in an array
  // that hasn't been marked as "_destroy".
  private setValidIndex(fieldParameters: FieldParameters) {
    let curIndex = this.getOpenArrayItemIndex(fieldParameters);

    if (curIndex !== undefined) {
      let valIndex;
      const arr = fieldParameters.object[fieldParameters.field.name];
      if (curIndex >= arr.length) {
        curIndex--;
      }

      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        if (!item._destroy) {
          valIndex = i;
          if (i >= curIndex) {
            break;
          }
        }
      }
      this.setOpenArrayItemIndex(fieldParameters, valIndex);
    }
  }

}
