import {Component, Input, OnChanges} from '@angular/core';
import {CommonsService} from '../../core/commons.service';
import {DateToolsService} from '../../core/date-tools.service';
import {FieldIcon, FieldItem, FieldValue, OverviewField} from '../../core/object-view';
import {ModelsService} from '../../core/models.service';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-overview-field-value',
  templateUrl: './overview-field-value.component.html',
  styleUrls: ['./overview-field-value.component.scss']
})
export class OverviewFieldValueComponent implements OnChanges {

  @Input() field: OverviewField;
  @Input() object: any;
  @Input() viewName: string;
  @Input() disableLinks: boolean;
  @Input() toneDownTextColor: boolean;

  isArrayValue: boolean;
  showMore: boolean;
  expired: boolean;
  fieldValues: Array<FieldValue> = [];
  fieldIcon: FieldIcon = null;

  constructor(private commons: CommonsService,
              private dateTools: DateToolsService,
              private modelsService: ModelsService) {
  }

  ngOnChanges() {
    const sourceStr = this.object['source'];
    const object = sourceStr ? JSON.parse(sourceStr) : this.object;
    this.field.items.forEach((fieldItem: FieldItem) => {
      this.fieldIcon = fieldItem.field_values.field_icon;
      const singleFieldValue = new FieldValue();
      singleFieldValue.value = '';
      fieldItem.field_values.values.forEach((fieldValue: FieldValue) => {
        singleFieldValue.value += fieldValue.value.toString();
        if (fieldValue.ref_id) {
          singleFieldValue.ref_id = fieldValue.ref_id;
        }
      });
      singleFieldValue.value = singleFieldValue.value || '-';
      this.fieldValues.push(singleFieldValue);
    });
    this.isArrayValue = this.fieldValues.length > 1;
    if (this.field.field_class === 'precision-date-field') {
      const statusType = this.commons.getStatusFromArtifact(object);
      this.modelsService.getModelAsync(object[AConst.OBJECT_TYPE]).then(model => {
        const fieldMeta = model['$$meta'][this.field.field_name];
        this.expired = this.dateTools.checkExpiredPrecisionDate(
          fieldMeta.inline, object[this.field.field_name], statusType);
      });
    }
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
    if (this.showMore) {
      window.addEventListener('mouseup', this.showMoreCallback, true);
    } else {
      window.removeEventListener('mouseup', this.showMoreCallback, true);
    }

  }

  private showMoreCallback = (item) => {
    const found = this.commons.findClassNameRecursively(item.target, 'dropdownIgnoreClicks');
    if (!found) {
      setTimeout(() => {
        this.showMore = false;
        window.removeEventListener('mouseup', this.showMoreCallback, true);
      }, 100);
    }
  }

}
