import {
  Component, ElementRef,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {OptionsService} from '../../../core/options.service';
import {FieldValueService} from '../../../core/field-value.service';
import {FieldStateService} from '../../../core/field-state.service';
import {FieldParameters} from '../../../core/field-parameters';


@Component({
  selector: 'app-field-select-options',
  templateUrl: './edit-field-select-options.component.html',
  styleUrls: ['./edit-field-select-options.component.scss']
})
export class EditFieldSelectOptionsComponent implements OnInit, OnChanges {
  reference = {};
  AConst = AConst;
  rowHeight = 26;
  lastPage;
  fieldKey;
  fieldName;
  searching = false;
  isShortList = false;
  shortList = 30;
  enableGetAllOptions = false;
  private delayedSearchId;

  @Input() fieldParameters: FieldParameters;
  @Input() optionContainer;
  @Input() query;
  @Input() selectedRow;
  @Input() isArray;
  @Input() fullSearch;
  @Output() optionSelected = new EventEmitter<object>();
  @Output() optionUnChecked = new EventEmitter<object>();
  @Output() setInputFocus = new EventEmitter<object>();
  @Output() keyEventsInShowOptions = new EventEmitter<object>();
  @ViewChild('focusOption', { static: true }) focusOption: ElementRef;

  constructor(private optionsService: OptionsService, private fieldValueSvc: FieldValueService, private fieldStateSvc: FieldStateService) {
  }

  ngOnInit() {
    const fieldInfo = this.fieldParameters.field;
    this.fieldKey = this.fieldStateSvc.getFieldKeyWhileDrawingInputs(
      fieldInfo, this.fieldParameters.index, this.fieldParameters.parentIndex);
    this.reference = fieldInfo.reference;
    this.fieldName = this.fieldStateSvc.getFieldTitle(fieldInfo, this.fieldParameters.rootObject);
  }

  selectOption(event, option) {
    event.preventDefault();
    if (!option['$$isSelected']) {
      this.optionSelected.emit(option);
    } else {
      this.optionUnChecked.emit(option);
    }

  }

  ngOnChanges() {
    this.runDelayedSearch();
  }

  runDelayedSearch() {
    if (this.delayedSearchId) {
      clearTimeout(this.delayedSearchId);
    }
    this.delayedSearchId = setTimeout(() => {
      this.delayedSearchId = null;
      const query = this.fullSearch ? '' : this.query;
      this.searchForOptions(query).then(() => {
        this.searching = false;
        this.selectedRow = 0;
        this.enableGetAllOptions = false;
      });
      if (!this.searching) {
        this.setOptionsToFocus();
      }
    }, 100);
  }

  setOptionsToFocus(selectedRow?) {
    if (selectedRow) {
      this.selectedRow = selectedRow;
    }
    if (this.selectedRow > 0) {
      if (this.focusOption) {
        setTimeout(() => {
          if (this.focusOption.nativeElement.querySelector('a')) {
            this.focusOption.nativeElement.querySelector('a').focus();
          }
        }, 200);
      }
    }
  }

  onToggleDescription(option) {
    this.optionsService.toggleDescription(option);
  }

  selectOptionWithKey(evt) {
    evt = evt || window.event;
    let rows;
    switch (evt.key) {
      case 'Tab':
        this.selectedRow = 0;
        this.keyEventsInShowOptions.emit({
          row: this.selectedRow,
          setFocus: false,
          tabbing: true
        });
        break;
      case 'Enter':
      case ' ':
        this.selectOption(evt, this.optionContainer.options[this.selectedRow]);
        evt.preventDefault();
        break;
      case 'ArrowUp':
        if (this.selectedRow === 0) {
          this.keyEventsInShowOptions.emit({
            row: this.selectedRow,
            setFocus: true,
            tabbing: false
          });
        } else {
          this.selectedRow--;
          rows = (this.selectedRow + 1) * this.rowHeight;
          this.scrollWithKey(rows);
        }
        evt.preventDefault();
        break;
      case 'ArrowDown':
        if (this.selectedRow === this.optionContainer.options.length - 1) {
        } else {
          this.selectedRow++;
          rows = (this.selectedRow + 1) * this.rowHeight;
          this.scrollWithKey(rows);
        }
        evt.preventDefault();
        break;
      case 'Escape':
      case 'Esc':
        this.selectedRow = 0;
        this.keyEventsInShowOptions.emit({
          row: this.selectedRow,
          setFocus: true,
          tabbing: false
        });
        break;
      case 'i':
        this.onToggleDescription(this.optionContainer.options[this.selectedRow]);
        break;
    }
  }

  scrollWithKey(rows) {
    const container = document.getElementById(this.fieldParameters.field.name + '-drop-down');
    let scrollToRow, containerHeight, page;
    if (container) {
      containerHeight = container.offsetHeight;
      page = Math.ceil(rows / containerHeight);

      if (page === 1) {
        container.scrollTop = 0;
      } else if (page !== this.lastPage) { // scroll only on page
        if (page > this.lastPage) { // increase on keydown
          scrollToRow = rows - this.rowHeight;
        } else {
          scrollToRow = rows - 182; // decrease on keyup
        }
        container.scrollTop = scrollToRow;
      }
      this.lastPage = page;
    }

  }

  showAllOptions() {
    this.enableGetAllOptions = true;
    this.searchForOptions().then(() => {
      this.searching = false;
      this.selectedRow = 0;
      this.keyEventsInShowOptions.emit({
        row: this.selectedRow,
        setFocus: true,
        tabbing: false
      });
    });
  }

  showAllOptionsWithKey(event) {
    if (event.key === 'Enter') {
      this.showAllOptions();
    }
    if (event.key === 'Tab') {
      this.selectOptionWithKey(event);
    }
    event.preventDefault();
  }

  private searchForOptions(query?) {
    return new Promise(resolve => {
      let getAllOptions;
      if (query || !this.reference[AConst.IS_HIERARCHIC]) {
        this.searching = true;
        this.optionsService.searchOptions({
          meta: this.fieldParameters.field,
          fieldParameters: this.fieldParameters,
          query: query,
        }).then((res) => {
          this.isShortList = res.length <= this.shortList;
          if (!this.isShortList && this.fullSearch && !query && this.query) {
            this.searchForOptions(this.query).then(() => {
              resolve();
            });
          } else {
            if (!this.isShortList && this.enableGetAllOptions) {
              this.isShortList = true;
            }
            if (!query) {
              getAllOptions = this.isShortList;
              resolve();
            }
            if (query || getAllOptions) {
              this.optionsService.setOptionsQueryHighlight(res, this.query, true);
              this.optionContainer.options = this.optionsService.weightOptionsByQuery(res, this.query);
              if (this.isArray) {
                this.setSelectedOptions(this.optionContainer.options);
              }
              resolve();
            } else {
              if (!query) {
                this.optionContainer.options = [];
              }
              resolve();
            }

          }
        });
      }
    });
  }

  private setSelectedOptions(options) {
    const array = this.getFieldArray();
    const inlineProp = this.fieldParameters.field.inline['prop'];
    this.optionsService.markSelectedOptions(options, array, inlineProp);
  }

  private getFieldArray() {
    return this.fieldValueSvc.getFieldValue(this.fieldParameters.rootObject, this.fieldKey);
  }

}
