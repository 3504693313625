import {Component, Input, OnChanges} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {FieldConditionService} from '../../../core/field-condition.service';
import {SectionsContainer} from '../../../core/sections-container';
import {FieldParameters} from '../../../core/field-parameters';

@Component({
  selector: 'app-edit-field-inline-array-fields',
  templateUrl: './edit-field-inline-array-fields.component.html',
  styleUrls: ['./edit-field-inline-array-fields.component.scss']
})
export class EditFieldInlineArrayFieldsComponent implements OnChanges {

  AConst = AConst;

  @Input() sectionsContainer: SectionsContainer;
  @Input() object;
  @Input() grandParentObject;
  @Input() fields;
  @Input() index: number;
  @Input() parentIndex: number;

  fieldParameters: FieldParameters;

  constructor(private fieldCondition: FieldConditionService) {
  }

  ngOnChanges() {
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters.rootObject = this.sectionsContainer.rootObject || this.object;
    this.fieldParameters.grandParentObject = this.grandParentObject;
    this.fieldParameters.object = this.object;
    this.fieldParameters.edit = true;
    this.fieldParameters.index = this.index;
    this.fieldParameters.parentIndex = this.parentIndex;
  }

  canShow(field) {
    const show = field.display ? field.display.show : null;
    let res = show === 'yes';
    if (res) {
      this.fieldParameters.field = field;
      res = this.fieldCondition.runIf('show', this.fieldParameters);
    }
    return res;
  }
}
