import {Component, Input, OnInit} from '@angular/core';
import {UIRouterGlobals} from '@uirouter/core';
import {AConst} from '../../core/a-const.enum';
import {ActionMenuContainer} from '../../core/action-menu-container';

@Component({
  selector: 'app-object-menu-create',
  templateUrl: './object-menu-create.component.html',
  styleUrls: ['./object-menu-create.component.scss']
})
export class ObjectMenuCreateComponent implements OnInit {

  @Input() menuContainer: ActionMenuContainer;
  @Input() menu;

  AConst = AConst;
  debug: boolean;

  constructor(private uiRouterGlobals: UIRouterGlobals) {
  }

  ngOnInit() {
    this.debug = this.uiRouterGlobals.params.debug;
  }

  showMediaSelectors(menu) {
    const obj = menu.obj;
    let res = true;
    if (this.menu.obj.$$firstStep) {
      res = false;
    } else if (obj) {
      if (menu[AConst.NO_MEDIA_ADD]) {
        res = false;
      }
    }
    return res;
  }

  enableSelector(selector) {
    this.menuContainer.enableSelector(selector);
  }

}
