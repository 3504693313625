import {AfterViewInit, Component, OnInit} from '@angular/core';
import {StateService} from '@uirouter/core';
import {CmsApiService} from '../../core/cms-api.service';
import {AConst} from '../../core/a-const.enum';
import {CommonsService} from '../../core/commons.service';
import {FaqLoader} from './faqloader';

@Component({
  selector: 'app-admin-main-page',
  templateUrl: './admin-main-page.component.html',
  styleUrls: ['./admin-main-page.component.scss']
})
export class AdminMainPageComponent implements OnInit, AfterViewInit {

  AConst = AConst;
  parent;
  menus;
  adminFq = [];
  visibleFq = {};
  adminMenus;

  constructor(private stateService: StateService,
              private cms: CmsApiService,
              private commons: CommonsService) {
  }

  ngOnInit() {
    this.parent = {
      adminType: 'admin',
      subMenus: false,
      currentState: this.stateService.$current.name
    };
  }

  ngAfterViewInit() {
    this.cms.getMainMenu().then(data => {
        this.menus = data;

        if (this.menus) {
          const faqLoader = new FaqLoader(this.menus, this.cms);
          faqLoader.getFAQData().then((faqData: any) => {
            faqData.forEach(f => {
              f.faq.forEach(fq => {
                this.adminFq.push(fq);
              });
            });
            this.adminMenu();
          });
        }
      }
    );
  }

  toggleFq(index) {
    this.visibleFq[index] =
      !this.visibleFq[index];
  }

  private adminMenu() {
      this.menus.forEach(menu => {
        const menuType = menu[AConst.TARGET_PARAMS];
        if (menuType && menuType['admin_type'] === 'admin') {
          this.adminMenus = menu;
          this.adminMenus[AConst.SUB_MENUS] = this.commons.orderArray(this.adminMenus[AConst.SUB_MENUS], 'order');
          this.adminMenus[AConst.SUB_MENUS].forEach(subMenu => {
            subMenu.menus = this.commons.orderArray(subMenu.menus, 'order');
          });
        }
      });
  }
}
