import { Injectable } from '@angular/core';
import {ExtArray} from './ext-array';
import {ReporterUtilityService} from './reporter-utility.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Service hosting methods used when parsing the report data,
 * replacing the concept IDs with their equivalent labels.
 * @type {{parse}}
 */
export class ResultParserService {
  private bulkSize: 100;    // Number of labels fetched pr. request.
  private artifacts = [];            // Report artifacts.
  private processedArtifacts = [];   // Finished/processed artifacts.
  private conceptIDs = [];    // List of concept IDs to be replaced.
  private conceptLabels = [];        // List of fetched concept labels.


  constructor(private reporterUtility: ReporterUtilityService) { }

  /**
   * Calculates and returns the number of bulk operations needed to fetch all concept labels.
   * @returns {number}
   * @private
   */
  private getNumBulks() {
    return Math.ceil(this.conceptIDs.length / this.bulkSize);
  }

  /**
   * Returns the list of unique concept IDs to be replaced with their equivalent labels.
   * @private
   */
  private getUniqueConceptIDs() {
    this.artifacts.map((artifact) => {
      if (artifact.reference_ids) {
        artifact.reference_ids.map((id) => {
          this.conceptIDs.push(id);
        });
      }
    });
    this.conceptIDs = new ExtArray(this.conceptIDs).removeDuplicates(); // Remove duplicates
  }

  /**
   * Returns the concept label matching the specified concept ID,
   * if it exists in the list of fetched concept labels.
   * @param conceptID
   * @returns {*}
   * @private
   */
  private conceptLabelExists(conceptID) {
    const c = this.conceptLabels.find((concept) => {
      return concept.artifact_id === conceptID;
    });
    return ((typeof(c) !== 'undefined') ? c.artifact_name : null);
  }

  /**
   * Parses a list of concept IDs and replaces them with their equivalent concept label.
   * @param lst
   * @returns {Array}
   * @private
   */
  private parseList(lst) {
    const results = [];
    for (let o in lst) {
      const lbl = this.conceptLabelExists(o);
      if (lbl) {
        results.push(lbl);
      } else {
        results.push(o);
      }
    }
    return results;
  }

  /**
   * Parses a string-array containing Concept IDs and replaces them with their equivalent concept label.
   * @param arr
   * @returns {Array}
   * @private
   */
  private parseArray(arr) {
    const results = [];
    arr.map((a) => {
      const lbl = this.conceptLabelExists(a);
      if (lbl) {
        results.push(lbl);
      }
    });
    return results;
  }

  /**
   * Replaces all concept IDs with their equivalent concept label for all report artifacts.
   * @private
   */
  private replaceConceptIDs() {
    this.artifacts.map((artifact) => {
      this.processedArtifacts.push(this.parseArtifact(artifact));
    });
  }

  /**
   * Executes an XMLHttpRequest, used when bulk downloading concept artifacts.
   * @param ids
   * @returns {*}
   * @private
   */
  private execReq(ids) {
    const url = this.reporterUtility.getCmsApiUrl('search/json');

    const params = {
      filters: {
        artifact_id: ids
      },
      fl: ['artifact_id', 'artifact_name'],
      start: 0,
      rows: ids.length
    };

    return this.reporterUtility.makeReq('POST', url, JSON.stringify(params)).then((json) => {
      if (json['status'] !== 'ok') {
        //reject();
      } else {
        json['artifacts'].map((artifact) => {
          this['conceptLabels'].push(artifact);
        });
      }
    });
  }

  /**
   * Downloads all concept names/labels.
   * @returns {*}
   * @private
   */
  private downloadConceptNames() {
    return new Promise((resolve) => {
      const promises = [];
      for (let i = 0; i < this.getNumBulks(); i++) {
        const bulkStart = ((i === 0) ? 0 : (i * this.bulkSize));  // 0, 100, 200....
        let bulkEnd = (bulkStart + this.bulkSize) - 1;         // 99, 199, 299....
        if (bulkEnd > this.conceptIDs.length) {
          bulkEnd = this.conceptIDs.length - 1;
        }
        promises.push(this.execReq(this.conceptIDs.slice(bulkStart, bulkEnd)));
      }

      Promise.all(promises).then(() => {
        this.replaceConceptIDs();
        return resolve();
      }).catch(function(e) {
        console.error(e);
      });
    });
  }

  /**
   * Resets global variables used when parsing.
   * @private
   */
  private reset() {
    this.bulkSize = 100;
    this.artifacts = [];
    this.processedArtifacts = [];
    this.conceptIDs = [];
    this.conceptLabels = [];
  }

  /**
   * Parses a single artifact, replacing its concept IDs with the equivalent concept label.
   * @param artifact
   * @returns {*}
   * @private
   */
  private parseArtifact(artifact) {
    for (let prop in artifact) {
      if (Array.isArray(prop)) {
        artifact[prop] = this.parseList(artifact[prop]);
      } else if (Array.isArray(artifact[prop])) {
        artifact[prop] = this.parseArray(artifact[prop]);
      } else {
        const lbl = this.conceptLabelExists(artifact[prop]);
        if (lbl) {
          artifact[prop] = lbl;
        }
      }
    }
    return artifact;
  }

  /**
   * Parses the list of report artifacts, replacing concept IDs with their equivalent concept label.
   * @param artifacts
   * @returns {*}
   */
  parse(artifacts) {
    return new Promise((resolve) => {
      this.reset();

      this.artifacts = artifacts;

      this.getUniqueConceptIDs();
      this.downloadConceptNames().then(() => {
        resolve(this.processedArtifacts);
      });
    });
  }

}
