import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {SectionsContainer} from '../../core/sections-container';
import {FieldValidationService} from '../../core/field-validation.service';
import {DateToolsService} from '../../core/date-tools.service';
import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-edit-field-date-historic',
  templateUrl: './edit-field-date-historic.component.html',
  styleUrls: ['./edit-field-date-historic.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldDateHistoricComponent)
    }]
})
export class EditFieldDateHistoricComponent implements OnInit, ControlValueAccessor {
  @Input() object: any;
  @Input() field: any;
  @Input() sectionsContainer: SectionsContainer;
  @Input() formControlName;
  @Input() otherFormControlName;
  @Input() placeholder;

  inFocus: boolean;
  dateControl: AbstractControl;
  otherDateControl: AbstractControl;

  constructor(private fieldValidation: FieldValidationService,
              private dateTools: DateToolsService) {
  }

  ngOnInit() {
    this.dateControl = this.sectionsContainer.formGroup.controls[this.formControlName];
    if (this.otherFormControlName) {
      this.otherDateControl = this.sectionsContainer.formGroup.controls[this.otherFormControlName];
    }
  }

  isInvalid() {
    return this.fieldValidation.isFieldInvalid(this.sectionsContainer, this.formControlName);
  }

  onFieldBlur() {
    this.inFocus = false;
  }

  onFieldFocus() {
    if (!this.sectionsContainer.isDialog) {
      this.inFocus = true;
    }
  }

  onValueChange($event) {
    const value = $event.target.value;
    const validDate = this.dateTools.validateDate(value);
    if (typeof validDate !== 'string') {
      this.object[this.field.name] = validDate;
      this.dateControl.setValue(this.dateTools.precisionDateToString(validDate));
      if (validDate && this.otherDateControl) {
        this.otherDateControl.updateValueAndValidity();
      }
    }
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
  }

}
