import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {MatDialogModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {FieldTitleComponent} from './field-title/field-title.component';
import {FieldToolTipComponent} from './field-tool-tip/field-tool-tip.component';
import {InlineArrayHeaderComponent} from './inline-array-header/inline-array-header.component';
import {InlineArrayHeaderSepComponent} from './inline-array-header/inline-array-header-sep/inline-array-header-sep.component';
import {ApplyWordBreakOpportunityPipe} from './apply-word-break-opportunity.pipe';
import {ActionToolbarComponent} from './action-toolbar/action-toolbar.component';
import {FieldActionButtonComponent} from './field-action-button/field-action-button.component';
import {ObjectIconComponent} from './object-icon/object-icon.component';
import {ObjectNameComponent} from './object-name/object-name.component';
import {KulturnavSearchDialogComponent} from './kulturnav-search-dialog/kulturnav-search-dialog.component';
import {ProgressDialogComponent} from './progress-dialog/progress-dialog.component';
import {AlertDialogComponent} from './alert-dialog/alert-dialog.component';
import {InlineArrayHeaderHeadlinesComponent} from './inline-array-header/inline-array-header-headlines/inline-array-header-headlines.component';
import {InlineArrayHeaderValueComponent} from './inline-array-header/inline-array-header-value/inline-array-header-value.component';
import {InlineArrayHeaderItemComponent} from './inline-array-header/inline-array-header-item/inline-array-header-item.component';
import {TemplateGroupSelectButtonComponent} from './template-group-select-button/template-group-select-button.component';
import {DropdownMenuComponent} from './dropdown-menu/dropdown-menu.component';
import {DeleteItemButtonComponent} from './delete-item-button/delete-item-button.component';
import {ObjectUsageDialogComponent} from './object-usage-dialog/object-usage-dialog.component';
import {DropdownMenuItemComponent} from './dropdown-menu/dropdown-menu-item/dropdown-menu-item.component';
import {DropdownMenuHrComponent} from './dropdown-menu/dropdown-menu-hr/dropdown-menu-hr.component';
import {ObjectUrlComponent} from './object-url/object-url.component';
import {ThumbnailComponent} from './thumbnail/thumbnail.component';
import {DownloadUrlComponent} from './download-url/download-url.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {UIRouterModule} from '@uirouter/angular';
import {ObjectViewComponent} from './object-view/object-view.component';
import {DisplayFieldTitleComponent} from './display-field-title/display-field-title.component';
import {ObjectViewFieldValuesComponent} from './object-view-field-values/object-view-field-values.component';
import {ObjectViewFieldValueComponent} from './object-view-field-value/object-view-field-value.component';
import {ObjectViewFieldCheckboxComponent} from './object-view-field-checkbox/object-view-field-checkbox.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    TranslateModule,
    UIRouterModule,
    MatDialogModule,
    FormsModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FieldTitleComponent,
    InlineArrayHeaderComponent,
    ActionToolbarComponent,
    FieldActionButtonComponent,
    FieldToolTipComponent,
    ObjectIconComponent,
    ObjectNameComponent,
    KulturnavSearchDialogComponent,
    AlertDialogComponent,
    ObjectUrlComponent,
    DeleteItemButtonComponent,
    DropdownMenuComponent,
    DropdownMenuItemComponent,
    DropdownMenuHrComponent,
    TemplateGroupSelectButtonComponent,
    ThumbnailComponent,
    DownloadUrlComponent,
    ApplyWordBreakOpportunityPipe,
    NotificationsComponent,
    ObjectViewComponent,
    DisplayFieldTitleComponent,
    ObjectViewFieldValuesComponent
  ],
  declarations: [
    FieldTitleComponent,
    FieldToolTipComponent,
    InlineArrayHeaderComponent,
    InlineArrayHeaderSepComponent,
    ApplyWordBreakOpportunityPipe,
    ActionToolbarComponent,
    FieldActionButtonComponent,
    ObjectIconComponent,
    ObjectNameComponent,
    KulturnavSearchDialogComponent,
    ProgressDialogComponent,
    AlertDialogComponent,
    InlineArrayHeaderHeadlinesComponent,
    InlineArrayHeaderValueComponent,
    InlineArrayHeaderItemComponent,
    TemplateGroupSelectButtonComponent,
    DropdownMenuComponent,
    DeleteItemButtonComponent,
    ObjectUsageDialogComponent,
    DropdownMenuItemComponent,
    DropdownMenuHrComponent,
    ObjectUrlComponent,
    ThumbnailComponent,
    DownloadUrlComponent,
    NotificationsComponent,
    ObjectViewComponent,
    DisplayFieldTitleComponent,
    ObjectViewFieldValuesComponent,
    ObjectViewFieldValueComponent,
    ObjectViewFieldCheckboxComponent
  ]
})
export class SharedModule {
}
