import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchContainer} from '../../search-container';
import {SearchHandlerService} from '../../search-handler.service';

@Component({
  selector: 'app-search-result-pager-list',
  templateUrl: './search-result-pager-list.component.html',
  styleUrls: ['./search-result-pager-list.component.scss']
})
export class SearchResultPagerListComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;

  searchPage = 1;
  endPage;
  private startPage = 1;
  private timeoutPromise;
  private lastPage = 1;
  private artifactLowerEl;

  constructor(private searchHandler: SearchHandlerService) {
  }

  ngOnInit() {

    if (this.searchContainer.searchResultViewName !== 'selector') {
      this.artifactLowerEl = document.getElementById('artifactLower');
    }

    if (this.searchContainer) {
      this.setPage(this.searchContainer.searchPage || 1);
    }
    this.searchHandler.subscribeToSearchResult(this.searchContainer, this.pagerSearchResultCallback);

  }

  ngOnDestroy(): void {
    this.searchHandler.unSubscribeToSearchResult(this.searchContainer, this.pagerSearchResultCallback);
  }

  setPage(page) {
    this.searchPage = page;
    this.searchPageChange();
  }

  prevPage() {
    if (this.searchPage > 1) {
      this.setPage(this.searchPage - 1);
    }
  }

  nextPage() {
    if (this.searchPage < this.searchContainer.maxPage) {
      this.setPage(this.searchPage + 1);
    }
  }

  setLastPage() {
    this.setPage(this.searchContainer.maxPage);
  }

  searchPageChange() {
    if (this.timeoutPromise) {
      clearTimeout(this.timeoutPromise);
    }
    this.timeoutPromise = setTimeout(() => {
      const sc = this.searchContainer;
      if (sc && this.lastPage !== this.searchPage) {
        const page = this.getSearchPage(this.searchPage);
        if (page) {
          sc.searchPage = page;
          this.searchHandler.runSearch(sc, true).then();
          this.lastPage = this.searchPage;
          if (!sc.displayAll && this.searchContainer.searchResultViewName !== 'selector') {
            this.scrollToPager(500);
          }
        }
      }
    }, 500);
  }

  getPages() {
    let t;
    const res = [];
    for (t = this.startPage; t < this.endPage + 1; t++) {
      res.push(t);
    }
    return res;
  }

  toggleDisplayAll() {
    const sc = this.searchContainer;
    sc.displayAll = !sc.displayAll;
    if (!sc.displayAll) {
      this.scrollToPager(500);
    }
    if (this.searchPage !== 1) {
      this.setPage(1);
    } else {
      this.searchHandler.runSearch(sc, true).then();
    }
  }

  private getSearchPage(value) {
    let page, res;
    if (!isNaN(value)) {
      page = Number(value);
      if (page > 0 && page <= this.searchContainer.maxPage) {
        res = page;
      }
    }
    return res;
  }

  private scrollToPager(timeOut) {
    if (this.artifactLowerEl) {
      setTimeout(() => {
        const lowerPos = this.artifactLowerEl.offsetTop + 210;

        if (window.pageYOffset > lowerPos) {
          // const divPosition = this.artifactLowerEl.offsetTop;
          // const container = angular.element(
          //   document.getElementsByTagName(
          //     'body'));
          // $document.duScrollToElement(
          //   container, divPosition, 100);
        }
      }, timeOut);
    }
  }

  private pagerSearchResultCallback = () => {
    const sc = this.searchContainer;
    if (sc && sc.searchResult) {
      this.searchPage = this.searchContainer.searchPage;

      if (this.searchContainer.maxPage <= 6) {
        // Less than 6 total pages so show all
        this.startPage = 1;
        if (this.searchContainer.maxPage === 0) {
          this.searchContainer.maxPage = 1;
        }
        this.endPage = this.searchContainer.maxPage;
      } else {
        // More than 6 total pages: calculate start and end.
        if (this.searchPage <= 4) {
          this.startPage = 1;
          this.endPage = 6;
        } else if (this.searchPage + 2 >= this.searchContainer.maxPage) {
          this.startPage = this.searchContainer.maxPage - 5;
          this.endPage = this.searchContainer.maxPage;
        } else {
          this.startPage = this.searchPage - 3;
          this.endPage = this.searchPage + 3;
        }
      }

    }
  }

}
