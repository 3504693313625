import { Injectable } from '@angular/core';
import {CmsApiService} from './cms-api.service';
import {AConst} from './a-const.enum';

const PRIMUS_OPTIONS = 'primus';
const COPY_OPTIONS = 'copyOptions';
const FOCUS_OPTIONS = 'focusOptions';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  private storage = localStorage;
  constructor(private cms: CmsApiService) { }

  public getCopyOptions() {
    return new Promise((resolve) => {
      this.getUserOptions(COPY_OPTIONS).then((options) => {
        resolve(options);
      });
    });
  }

  public storeCopyOptions(options) {
    this.storeOptions(COPY_OPTIONS, options);
  }

  public getFocusOptions(): Promise<Array<any>> {
    return new Promise((resolve) => {
      this.getUserOptions(FOCUS_OPTIONS).then((options) => {
        resolve(<Array<any>>options);
      });
    });
  }

  public storeFocusOptions(options) {
    this.storeOptions(FOCUS_OPTIONS, options);
  }

  private loadData(storeId) {
    let res;
    const data = this.storage.getItem(storeId);
    try {
      res = JSON.parse(data) || {};
    } catch (e) {
      res = {};
      console.warn('Parsing ' + data + ' failed');
    }
    return res;
  }

  private storeData(storeId, data) {
    this.storage.setItem(storeId, JSON.stringify(data));
  }

  private getOptionStoreId(optionType) {
    return new Promise((resolve) => {
      this.cms.getUserData().then((data) => {
        resolve(PRIMUS_OPTIONS + '-' + data[AConst.USER][AConst.USER_ID] +
          '-' + optionType);
      });
    });
  }

  private getUserOptions(optionType) {
    return new Promise((resolve) => {
      this.getOptionStoreId(optionType).then((storeId) => {
        resolve(this.loadData(storeId));
      });
    });
  }

  private storeOptions(optionType, options) {
    this.getOptionStoreId(optionType).then((storeId) => {
      this.storeData(storeId, options);
    });
  }

}
