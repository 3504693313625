import {Injectable} from '@angular/core';
import {UIRouterGlobals} from '@uirouter/core';
import {SearchHandlerService} from './search-handler.service';
import {UiToolsService} from '../core/ui-tools.service';
import {SearchContainer} from './search-container';

@Injectable({
  providedIn: 'root'
})
export class SearchPageService {

  private searchContainer: SearchContainer;
  private callbacks = [];

  constructor(private searchHandler: SearchHandlerService,
              private uiTools: UiToolsService,
              private uiRouterGlobals: UIRouterGlobals) {
  }

  checkCreateSearchContainer(): Promise<SearchContainer> {
    const stateParams = this.uiRouterGlobals.params;
    const toStateName = this.uiRouterGlobals.current.name;
    return new Promise<SearchContainer>(resolve => {
      if (!this.searchContainer) {
        this.searchHandler.createSearchContainer(
          {
            searchViewName: 'all_objects',
            toState: toStateName,
            targetState: 'home.primus.search',
            stateParams: stateParams,
            getFocus: true,
            useFocus: !stateParams.path,
            runSearch: true
          }).then(
          sc => {
            sc.windowSize = this.uiTools.windowSize;
            this.searchContainer = sc;
            this.runCallbacks(sc);
            resolve(sc);
          }
        );
      } else {
        this.searchContainer.toState = toStateName;
        resolve(this.searchContainer);
      }
    });
  }

  clearSearchContainer() {
    this.searchContainer = undefined;
  }

  addSearchContainerCreationListener(callback) {
    if (!this.searchContainer) {
      this.callbacks.push(callback);
    } else {
      callback(this.searchContainer);
    }
  }

  private runCallbacks(searchContainer) {
    this.callbacks.forEach(callback => {
      callback(searchContainer);
    });
    this.callbacks = [];
  }
}
