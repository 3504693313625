import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OptionsService} from '../../../core/options.service';
import {HierarchyRow} from '../edit-field-select-hierarchy/edit-field-select-hierarchy.component';

@Component({
  selector: 'app-edit-field-select-hierarchy-node',
  templateUrl: './edit-field-select-hierarchy-node.component.html',
  styleUrls: ['./edit-field-select-hierarchy-node.component.scss']
})
export class EditFieldSelectHierarchyNodeComponent implements OnInit {

  @Input() node: any;
  @Input() nodeIndex: number;
  @Input() rootNodeIndex;
  @Input() selectedRow: HierarchyRow;
  @Output() childSelected = new EventEmitter<object>();

  constructor(private optionsService: OptionsService) {
  }

  ngOnInit() {
  }

  selectChildedWithKey(event, option) {
    switch (event.key) {
      case 'Enter':
      case ' ':
        if (!option.$$showDescription) {
          this.onChildSelected(option);
          event.preventDefault();
        }
        break;
    }
  }

  onChildSelected(child) {
    this.childSelected.emit(child);
  }

  getRootIndex(node, i) {
    if (node.option.level === 1) {
      this.rootNodeIndex = i;
      return i;
    } else {
      return this.rootNodeIndex;
    }
  }

  toggleOpenNode(node) {
    node.$$open = !node.$$open;
  }

  toggleDescription(node) {
    this.optionsService.toggleDescription(node);
  }
}
