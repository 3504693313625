import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {InlineArrayItemService} from '../../core/inline-array-item.service';
import {SectionsContainer} from '../../core/sections-container';
import {hasOwnProperty} from 'tslint/lib/utils';
import {CommonsService} from '../../core/commons.service';

@Component({
  selector: 'app-action-toolbar',
  templateUrl: './action-toolbar.component.html',
  styleUrls: ['./action-toolbar.component.scss']
})
export class ActionToolbarComponent implements OnChanges, OnDestroy {
  @Input() sectionsContainer: SectionsContainer;
  @Input() changePropertyParent;
  @Input() changePropertyField;
  @Input() actions;
  @Input() getActions;
  @Input() searchContainer;
  @Input() disableChangeCheck;
  @Input() stylingClass: string;

  disableReg = false;
  AConst = AConst;
  errors = [];
  errorPointer = 0;
  registerHasBeenRun = false;
  myActions = [];
  changePropertyOrig;
  searchContainerTimeout;
  scrollToOpenArrayItemTimeout;
  checkChangedPropertyTimeout;

  constructor(private inlineArrayItemSvc: InlineArrayItemService, private commons: CommonsService) {
  }

  ngOnChanges() {
    if (this.actions) {
      this.myActions = this.actions;
    } else if (this.getActions) {
      this.myActions = this.getActions();
    } else {
      console.warn('No actions defined');
    }
    if (this.searchContainer) {
      this.checkSearchContainer();
    }

    if (this.changePropertyParent && !this.changePropertyOrig) {
      if (!this.disableChangeCheck) {
        this.changePropertyOrig = this.commons.copy(this.getChangeProperty());
        this.checkChangedProperty();
      }
    }
  }

  ngOnDestroy() {
    if (this.searchContainerTimeout) {
      clearTimeout(this.searchContainerTimeout);
    }
    if (this.scrollToOpenArrayItemTimeout) {
      clearTimeout(this.scrollToOpenArrayItemTimeout);
    }
    if (this.checkChangedPropertyTimeout) {
      clearTimeout(this.checkChangedPropertyTimeout);
    }
  }

  get errorCount() {
    let res = 0;
    this.errors = [];
    for (const fieldName in this.sectionsContainer.formGroup.controls) {
      if (hasOwnProperty(this.sectionsContainer.formGroup.controls, fieldName)) {
        const fieldCtrl = this.sectionsContainer.formGroup.controls[fieldName];
        if (fieldCtrl.invalid) {
          this.errors.push(fieldName);
          res++;
        }
      }
    }
    if (this.errorPointer >= res) {
      this.errorPointer = 0;
    }
    return res;
  }

  nextError() {
    const errorId = this.errors[this.errorPointer];
    this.errorPointer++;
    if (this.errorPointer >= this.errors.length) {
      this.errorPointer = 0;
    }
    if (!this.scrollTo(errorId)) {
      this.scrollToOpenArrayItem(errorId, 0);
    }
  }

  isDisabled(action) {
    let res = false;
    if (action.type === 'register' && !this.disableChangeCheck) {
      res = this.disableReg;
      if (!res) {
        const formGroup = this.sectionsContainer ? this.sectionsContainer.formGroup : null;
        if (formGroup) {
          res = !formGroup.dirty || (this.registerHasBeenRun && formGroup.invalid);
        }
      }
    }
    return res;
  }

  get isInvalid() {
    return this.registerHasBeenRun && this.sectionsContainer && this.sectionsContainer.formGroup.invalid;
  }

  runAction(action) {
    if (action.type === 'register') {
      this.registerHasBeenRun = true;
      if (this.sectionsContainer) {
        if (this.sectionsContainer.form) {
          // This is supposed to set the form's 'submitted' property to true, but it doesn't work
          this.sectionsContainer.form.ngSubmit.emit();
        }
        // Need to set a parameter on sectionsContainer since the above doesn't work
        this.sectionsContainer.submitted = true;
        if (!this.sectionsContainer.formGroup.invalid) {
          action.fn();
        }
      } else {
        action.fn();
      }
    } else {
      action.fn();
    }
  }

  private scrollTo(errorId) {
    let res = false;
    const scrollElement = document.getElementById(errorId);
    if (scrollElement) {
      const offset = 500;
      const scrollTop = scrollElement.getBoundingClientRect().top + window.scrollY;
      res = true;
      setTimeout(function () {
        window.scrollTo({top: scrollTop - offset, behavior: 'smooth'});
      }, 111);
    }
    return res;
  }

  private scrollToOpenArrayItem(idString, idIndexStart) {
    const bracketEnd = idString.indexOf(']', idIndexStart);
    if (bracketEnd !== -1) {
      const arrayId = idString.substring(0, bracketEnd + 1);
      this.inlineArrayItemSvc.setOpenArrayItemIndexFromKey(this.sectionsContainer.rootObject, arrayId);
      this.scrollToOpenArrayItemTimeout = setTimeout(() => {
        if (this.scrollTo(arrayId)) {
          this.scrollToOpenArrayItem(idString, bracketEnd + 1);
        } else {
          console.warn('Unable to find element to scroll to ' + arrayId);
        }
      }, 200);
    }
  }

  private checkSearchContainer() {
    this.searchContainerTimeout = setTimeout(() => {
      this.disableReg = ((!this.searchContainer.selected || this.searchContainer.selected.length === 0) &&
        !this.searchContainer.allSelected) || this.searchContainer.selectorEnabled === true;
      this.checkSearchContainer();
    }, 2500);
  }

  private checkChangedProperty() {
    this.checkChangedPropertyTimeout = setTimeout(() => {
      if (Array.isArray(this.changePropertyOrig)) {
        this.disableReg = !this.checkChangedArray();
      } else {
        this.disableReg = !this.checkChangedField(
          this.changePropertyOrig, this.getChangeProperty());
      }
      this.checkChangedProperty();
    }, 2500);
  }

  private getChangeProperty() {
    return this.changePropertyParent[this.changePropertyField];
  }

  private checkChangedArray() {
    let res = this.changePropertyOrig.length !== this.getChangeProperty().length;
    if (!res) {
      this.changePropertyOrig.forEach((origItem, index) => {
        res = this.checkChangedField(origItem, this.getChangeProperty()[index]);
      });
    }
    return res;
  }

  private checkChangedField(origItem, changeItem) {
    let res = false;
    if (typeof origItem === 'object') {
      res = this.checkChangedObject(origItem, changeItem);
    } else {
      if (origItem !== changeItem) {
        res = true;
      }
    }
    return res;
  }

  private checkChangedObject(origO, changeO: object) {
    let res = false;
    for (const name in changeO) {
      if (!changeO.hasOwnProperty(name)) {
        continue;
      }
      const origField = changeO[name];
      if (typeof origField !== 'object' && name.indexOf('$$') === -1) {
        if (origField !== origO[name]) {
          res = true;
        }
      }
    }
    return res;
  }
}
