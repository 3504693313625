import {Injectable} from '@angular/core';
import {ModelsService} from './models.service';
import {LoginService} from './login.service';
import {AConst} from './a-const.enum';
import {FieldValueService} from './field-value.service';
import {ModelFactoryService} from './model-factory.service';
import {CommonsService} from './commons.service';
import {ObjectEditService} from './object-edit.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectDeletionService {

  constructor(private models: ModelsService,
              private loginService: LoginService,
              private fieldValueService: FieldValueService,
              private modelFactory: ModelFactoryService,
              private commons: CommonsService,
              private objectEditService: ObjectEditService) {
  }

  deleteObject(object) {
    return new Promise(((resolve, reject) => {
      this.objectEditService.deleteObjectShowDialog(object).then(
        () => {
          resolve();
        },
        response => {
          reject(response.error.message);
        }
      );
    }));
  }

  deleteItem(mod, arrayName, index) {
    const arr = mod[arrayName];
    this.modelFactory.deleteArrayItem(arr, index, mod);
  }

  undoDeleteItem(mod, arrayName) {
    const arr = mod[arrayName];
    this.modelFactory.undoDeleteArrayItem(arr);
  }

  deleteItems(mod, targetArrayName, deleteArray, compProp1, compProp2?) {
    const targetArray = mod[targetArrayName];
    let deleted = false;

    this.commons.compareArrays(targetArray, deleteArray, compProp1, compProp2, (targetIndex) => {
      this.modelFactory.deleteArrayItem(targetArray, targetIndex, mod);
      deleted = true;
    });
    if (!deleted) {
      throw new Error('No items deleted! Target array probably did not ' + 'contain items');
    }
    return targetArray;
  }

  // Detach images from object in a process where the the image is
  // removed from the image array, and then the artifact is stored
  detachImagesFromObject(art, images, fn, fnFailed) {
    this.getSetObjectImages(art, images, true,
      () => {
        this.objectEditService.storeObjectShowProgressModal(art).then(
          () => {
            fn();
          }, reason => {
            fnFailed(reason.error.message, 0);
          });
      },
      error => {
        fnFailed(error);
      }
    );
  }

  setObjectsDeletable(objects: Array<any>, hasParent) {
    this.models.getModelsAsync().then(mods => {
      objects.forEach(art => {
          this.setDeletable(art, mods, hasParent);
        }
      );
    });
  }

  private setDeletable(art, mods, hasParent) {
    let res = false;
    if (this.hasDeleteRights(this.loginService.getCurrentUser(), art)) {
      res = this.isObjectDeletable(art, mods, hasParent);
    }
    art.$$deletable = res;
  }

  private isObjectDeletable(art, mods, hasParent) {
    let res = false;
    const objType = this.getObjectType(art);
    if (objType) {
      const model = mods[objType];
      if (!model) {
        console.warn('No model found for object type ' + objType);
      } else {
        const deletableInfo = model[AConst.DELETABLE];

        if (deletableInfo) {
          if (deletableInfo[AConst.DELETABLE_TYPE] === 'bool') {
            res = deletableInfo[AConst.IS_DELETABLE];
          } else if (deletableInfo[AConst.DELETABLE_TYPE] === 'compare_field') {
            res = this.fieldValueService.fieldIf(art, deletableInfo.field,
              deletableInfo[AConst.COMPARATOR], deletableInfo.value);
          } else if (deletableInfo[AConst.DELETABLE_TYPE] === 'has_parent') {
            res = hasParent;
          } else {
            throw new Error('Deletable type \'' + deletableInfo[AConst.DELETABLE_TYPE] +
              '\' not supported');
          }
        }
      }
    }

    return res;
  }

  private hasDeleteRights(user, art) {
    let res = false;
    if (user.role === 'admin' ||
      art[AConst.CREATED_BY_ID] === user[AConst.ARTIFACT_ID]) {
      res = true;
    }
    return res;
  }

  private getObjectType(m) {
    const objType = m[AConst.OBJECT_TYPE] || m[AConst.CONCEPT_TYPE_ID];
    if (!objType) {
      console.warn('Object is missing \'object_type\' field: ' + JSON.stringify(m));
    }
    return objType;
  }

  private getSetObjectImages(art, changedImages, remove, fn, failedFn) {
    let images;
    const arrayName = 'images';
    if (art[arrayName] === undefined) {
      console.error('Images array not found');
    }
    if (changedImages) {
      if (remove) {
        try {
          images = this.deleteItems(art, arrayName, changedImages, 'image_id', 'artifact_id');
        } catch (e) {
          if (failedFn) {
            failedFn(e);
            return;
          } else {
            console.error('Error deleting images: ' + e);
          }
        }
      } else {
        // No longer used
        // images = addItems(art, arrayName, changedImages);
      }
    } else {
      images = art[arrayName];
    }
    if (fn) {
      fn(images);
    }
    return images;
  }


}
