import {Component, Input, OnInit} from '@angular/core';
import {SearchSelectorService} from '../../../core/search-selector.service';
import {SelectorContainer} from '../../../core/selector-container';
import {SectionsContainer} from '../../../core/sections-container';

@Component({
  selector: 'app-object-new-object-view',
  templateUrl: './object-new-object-view.component.html',
  styleUrls: ['./object-new-object-view.component.scss']
})
export class ObjectNewObjectViewComponent implements OnInit {

  @Input() selectorContainer: SelectorContainer;
  @Input() sectionsContainer: SectionsContainer;

  constructor(private searchSelectorService: SearchSelectorService) {
  }

  ngOnInit() {
  }

  selectorOverlay() {
    this.searchSelectorService.disableSelector(this.selectorContainer);
  }

}
