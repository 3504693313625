import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {RefService} from '../../core/ref.service';
import {SearchHandlerService} from '../../object-search/search-handler.service';

@Component({
  selector: 'app-object-url',
  templateUrl: './object-url.component.html',
  styleUrls: ['./object-url.component.scss']
})
export class ObjectUrlComponent implements OnInit {
  AConst = AConst;
  sRef;
  @Input() object;
  @Input() searchItemIndex;
  @Input() rootObjId;
  @Input() searchContainer;
  @Input() disable;
  @Input() listName;
  @Input() hasImage;
  @Input() useUrl;
  @Input() aClass;

  constructor(private ref: RefService,
              private searchHandler: SearchHandlerService) {
  }

  ngOnInit() {
    const params = {
      object: this.object,
      useUrl: this.useUrl,
      stateParams: {
        listName: null
      },
      searchItemIndex: null,
      rootObjId: null,
    };
    if (this.searchItemIndex !== undefined) {
      params.searchItemIndex = this.searchItemIndex.toString();
    }
    if (this.rootObjId !== undefined) {
      params.rootObjId = this.rootObjId;
    }
    if (this.listName) {
      params.stateParams.listName = this.listName;
    }
    this.sRef = this.ref.makeRef(params);
  }

  clickItem() {
    if (this.searchContainer && this.searchItemIndex !== undefined) {
      this.searchHandler.setSearchItemIndex(this.searchContainer, this.searchItemIndex);
    }
  }

  isDisabled() {
    return this.disable || !this.object;
  }

}
