import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {SectionsContainer} from '../../core/sections-container';
import {ObjectEditService} from '../../core/object-edit.service';
import {UserSettingsService} from '../../core/user-settings.service';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-section-view-edit',
  templateUrl: './section-view-edit.component.html',
  styleUrls: ['./section-view-edit.component.scss']
})
export class SectionViewEditComponent implements OnChanges {

  AConst = AConst;
  allSections = {keep: true};
  hideSectionTitle = false;

  @Input() sectionsContainer: SectionsContainer;
  @Input() form: NgForm;
  @Output() closeEdit = new EventEmitter<any>();

  constructor(private objectEditService: ObjectEditService,
              private userSettings: UserSettingsService) {
  }

  ngOnChanges() {
    const copyOptions = this.sectionsContainer.copyOptions;
    if (copyOptions) {
      const objType = this.sectionsContainer.rootObject[AConst.OBJECT_TYPE];
      if (typeof copyOptions[objType].allSections === 'object') {
        this.allSections = copyOptions[objType].allSections;
      } else {
        copyOptions[objType].allSections = this.allSections;
      }
    }
    if (this.form) {
      this.sectionsContainer.form = this.form;
    }
  }

  setAllSectionKeep() {
    const objType = this.sectionsContainer.rootObject[AConst.OBJECT_TYPE];
    const objCopyOptions = this.sectionsContainer.copyOptions[objType];
    this.allSections.keep = !this.allSections.keep;
    for (const i in objCopyOptions.sections) {
      if (objCopyOptions.sections.hasOwnProperty(i)) {
        objCopyOptions.sections[i].keep = this.allSections.keep;
      }
    }
    this.userSettings.storeCopyOptions(this.sectionsContainer.copyOptions);
  }

  onUpdateKeepSection(section) {
    this.sectionsContainer.sections.forEach((val, index) => {
      if (val.name === section.name) {
        this.sectionsContainer.sections[index].keep = section.keep;
      }
    });
  }

  onCloseEdit() {
    this.closeEdit.emit();
  }
}
