/**
 * This service is responsible for generating and modifying model objects to be stored on the server
 */
import {Injectable} from '@angular/core';
import {AbstractControlOptions, FormControl, FormGroup, Validators} from '@angular/forms';

import {AConst} from './a-const.enum';
import {CmsApiService} from './cms-api.service';
import {FieldValidators} from './field-validators';
import {DateToolsService} from './date-tools.service';
import {FieldStateService} from './field-state.service';
import {ObjectFieldTraverseService} from './object-field-traverse.service';
import {CommonsService} from './commons.service';
import {ModelSectionsService} from './model-sections.service';
import {SectionsContainer} from './sections-container';
import {UserSettingsService} from './user-settings.service';
import {FieldValueService} from './field-value.service';

@Injectable({
  providedIn: 'root'
})
export class ModelObjectService {
  copyOptions;
  nonSettableInputTypes = [
    AConst.PRECISION_DATE,
    AConst.CHECK_ARRAY,
    AConst.MAP_ID,
    AConst.REF_ARRAY,
    AConst.REF_DYNAMIC_ARRAY,
    AConst.SEARCH_SELECTOR,
    AConst.SEARCH_SELECTOR_MULTIPLE,
    AConst.IMAGE,
    AConst.COMPARE_VALUE
  ];

  constructor(private fieldState: FieldStateService,
              private cms: CmsApiService,
              private dateTools: DateToolsService,
              private objectFieldTraverse: ObjectFieldTraverseService,
              private commons: CommonsService,
              private modelSections: ModelSectionsService,
              private userSettings: UserSettingsService,
              private fieldValueService: FieldValueService) {

  }

  getSectionsAndFormGroup(object, templateGroupId?, isCopy?): Promise<SectionsContainer> {
    return new Promise(resolve => {
      this.modelSections.getObjectSections(object, templateGroupId).then(sections => {
        const sectionsContainer = new SectionsContainer();
        sectionsContainer.rootObject = object;
        sectionsContainer.sections = sections;
        sectionsContainer.templateGroupId = templateGroupId;
        this.setSectionsContainerFormGroup(sectionsContainer).then(() => {
          if (isCopy) {
            this.setCopyOptions(sectionsContainer).then(() => {
              resolve(sectionsContainer);
            });
          } else {
            resolve(sectionsContainer);
          }
        });
      });
    });
  }

  setSectionsContainerFormGroup(sectionsContainer: SectionsContainer) {
    return new Promise(resolve => {
      this.getFormGroup(sectionsContainer.rootObject, this.getFormFieldsForSections(sectionsContainer.sections)).then(formGroup => {
        sectionsContainer.formGroup = formGroup;
        resolve();
      });
    });
  }

  setObjectValuesFromForm(object: object, form: FormGroup) {
    for (const fieldPath in form.value) {
      if (form.value.hasOwnProperty(fieldPath)) {
        const metaAndObject = this.objectFieldTraverse.getFieldMetaAndSubObjectFromPath(object, fieldPath);
        const subObject = metaAndObject.subObject;
        const fieldMeta = metaAndObject.fieldMeta;
        const fieldValue = form.value[fieldPath];
        if (fieldMeta) {
          this.setFieldValueFromControlValue(subObject, fieldMeta, fieldValue);
        }
      }
    }
  }

  // Create form controls for an array item of an inline array field element
  setFormGroupFieldInlineArrayItem(params, item, index) {
    return new Promise(resolve => {
      const indexes = this.getIndexesFromParams(params, index);
      this.setFormGroupFields({
        object: item,
        fields: params.field[AConst.INLINE_FIELDS],
        group: params.group,
        parentKey: indexes.indexKey,
        index1: indexes.index1,
        index2: indexes.index2
      }).then(() => {
        resolve();
      });
    });
  }

  markFormGroupFieldInlineArrayItemAsDeleted(params, index) {
    const indexes = this.getIndexesFromParams(params, index);
    const field = {
      name: params.field.name + '._destroy',
      key: indexes.indexKey + '._destroy'
    };
    const controlKey = this.fieldState.getFieldKey(field, indexes.index1, indexes.index2);
    const formControl = params.group.controls[controlKey];
    if (formControl) {
      formControl.markAsDirty();
    } else {
      console.warn('_destroy must be defined as a model field for field ' + params.field.name);
    }
  }

  // Remove form controls for an inline array element, which is necessary in order to avoid prevailing errors on deleted elements
  removeFormGroupFieldInlineArrayItem(params, item, index) {
    const indexes = this.getIndexesFromParams(params, index);
    this.removeFormGroupFields({
      object: item,
      fields: params.field[AConst.INLINE_FIELDS],
      group: params.group,
      parentKey: indexes.indexKey,
      index1: indexes.index1,
      index2: indexes.index2
    });
  }

  getFormGroup(object: object, fields, createArrayElement?): any {
    return new Promise(resolve => {
      const group: any = {};
      let fieldCounter = fields.length;
      fields.forEach(field => {
        const traverseRes = this.objectFieldTraverse.traverseObjectByPath(object, field.path, 0, createArrayElement);
        const o = traverseRes.subObject;
        if (o === undefined) {
          console.warn('Unable to find sub object for ' + field.path);
        }
        this.setFormGroupField({
          object: o,
          field: field,
          group: group,
          parentKey: traverseRes.parentKey,
          index1: traverseRes.parentIndex
        }).then(() => {
          fieldCounter--;
          if (!fieldCounter) {
            resolve(new FormGroup(group));
          }
        });
      });
    });
  }

  getSectionField(sectionsContainer: SectionsContainer, sectionFieldName: string) {
    let res = null;
    for (let s = 0; s < sectionsContainer.sections.length; s++) {
      const section = sectionsContainer.sections[s];
      for (let f = 0; f < section.fields.length; f++) {
        const field = section.fields[f];
        if (field.name === sectionFieldName) {
          res = field;
          break;
        }
      }
      if (res) {
        break;
      }
    }
    return res;
  }

  getSectionFields(sectionsContainer: SectionsContainer, fieldPathList: Array<string>) {
    let res = [];
    const sectionField = this.getSectionField(sectionsContainer, fieldPathList[0]);
    if (sectionField) {
      res = [sectionField];
      if (fieldPathList.length > 1) {
        let field = sectionField;
        for (let f = 1 ; f < fieldPathList.length ; f++) {
          const inlineField = this.getInlineField(field, fieldPathList[f]);
          if (inlineField) {
            res.push(inlineField);
            field = inlineField;
          } else {
            console.warn('Inline field named "' + fieldPathList[f] + '" not found');
            break;
          }
        }
      }
    } else {
      console.warn('Section field named "' + fieldPathList[0] + '" not found');
    }
    return res;
  }

  getInlineField(sectionField, fieldName) {
    const inlineFields = sectionField[AConst.INLINE_FIELDS];
    let res = null;
    for (let t = 0 ; t < inlineFields.length ; t++) {
      const inlineField = inlineFields[t];
      if (inlineField.name === fieldName) {
        res = inlineField;
        break;
      }
    }
    return res;
  }

  private getFormFieldsForSections(sections: Array<any>) {
    const formFields = [];
    sections.forEach(section => {
      section.editFields = [];
      section.fields.forEach(field => {
        if (this.isEditField(field)) {
          formFields.push(field);
          section['editFields'].push(field);
        }
      });
    });
    return formFields;
  }

  private getIndexesFromParams(params, index) {
    const fieldKey = this.fieldState.generateFieldKey(params.field, params.parentKey);
    let indexKey, index1, index2;
    if (params.index1 === undefined) {
      indexKey = fieldKey + '[{index1}]';
      index1 = index;
    } else {
      indexKey = fieldKey + '[{index2}]';
      index1 = params.index1;
      index2 = index;
    }
    return {
      indexKey: indexKey,
      index1: index1,
      index2: index2
    };
  }

  private setFieldValueFromControlValue(object, fieldMeta, fieldValue) {
    const inputType = fieldMeta[AConst.INPUT_TYPE];
    const fieldType = fieldMeta[AConst.FIELD_TYPE];
    let setFieldValue = true;
    if (this.nonSettableInputTypes.indexOf(inputType) !== -1 || fieldType === AConst.MAP_ID) {
      setFieldValue = false;
    } else if (inputType === 'number') {
      if (fieldValue === '' || fieldValue === null) {
        fieldValue = null;
      } else {
        fieldValue = Number(fieldValue);
      }
    } else if (inputType === AConst.CHECKBOX) {
      fieldValue = fieldValue === true || fieldValue === 'true';
    }
    if (setFieldValue) {
      if (object && object[fieldMeta.name] !== undefined) {
        object[fieldMeta.name] = fieldValue;
      } else {
        console.error('Field "' + fieldMeta.name + '" does not exist in object or object empty!');
      }
    }
  }

  private isEditField(field) {
    let res = false;
    if ((field.edit && field[AConst.FIELD_TITLE]) || field[AConst.FIELD_TYPE] === AConst.ACTION_BUTTON) {
      res = true;
    }
    return res;
  }

  private setFormGroupFields(params) {
    return new Promise(resolve => {
      let fieldCounter = params.fields.length;
      if (fieldCounter) {
        params.fields.forEach(field => {
          this.setFormGroupField({
            object: this.getFieldObject(params.object, field),
            field: field,
            group: params.group,
            parentKey: params.parentKey,
            index1: params.index1,
            index2: params.index2
          }).then(() => {
            fieldCounter--;
            if (!fieldCounter) {
              resolve();
            }
          });
        });
      } else {
        resolve();
      }
    });
  }

  private getFieldObject(object, field) {
    if (object[field.name] === undefined) {
      if (field[AConst.PARENT_NAME]) {
        object = object[field[AConst.PARENT_NAME]];
        if (object === undefined) {
          console.warn('No object found for parent field: ' + field[AConst.PARENT_NAME]);
        }
      } else {
        console.warn('No parent object found for: ' + field.key);
      }
    }
    return object;
  }

  private setFormGroupField(params) {
    return new Promise(resolve => {
      this.fieldState.generateFieldKey(params.field, params.parentKey);
      if (params.field[AConst.INPUT_TYPE] !== AConst.INLINE && params.field[AConst.INPUT_TYPE] !== AConst.INLINE_ARRAY) {
        this.addFormGroupControl(params).then(() => {
          resolve();
        });
      } else if (params.field[AConst.INPUT_TYPE] === AConst.INLINE) {
        this.setFormGroupFieldInline(params).then(() => {
          resolve();
        });
      } else if (params.field[AConst.INPUT_TYPE] === AConst.INLINE_ARRAY) {
        const array = params.object[params.field.name];
        if (array.length) {
          array.forEach((item, index) => {
            this.setFormGroupFieldInlineArrayItem(params, item, index).then(() => {
              if (index === array.length - 1) {
                resolve();
              }
            });
          });
        } else {
          resolve();
        }
      }
    });
  }

  private addFormGroupControl(params): Promise<FormControl> {
    return new Promise(resolve => {
      // The controlKey contains the key with correct inline array indexes
      const controlKey = this.fieldState.getFieldKey(params.field, params.index1, params.index2);
      if (params.group.addControl) {
        // Used when adding new inline array elements
        this.createFormControl(params.object, params.field).then(control => {
          params.group.addControl(controlKey, control);
          resolve(control);
        });
      } else {
        // Used when generating a form group the first time
        let object = params.object;
        const parentName = params.field[AConst.PARENT_NAME];
        if (parentName && object[params.field.name] === undefined && object[parentName] !== undefined) {
          // This condition is triggered for a few fields, like the field "history_events[n].timespan_historic.from_date"
          object = object[parentName];
        }
        this.createFormControl(object, params.field).then(control => {
          params.group[controlKey] = control;
          resolve(control);
        });
      }
    });
  }

  // Create form controls for an inline field element
  private setFormGroupFieldInline(params) {
    return new Promise(resolve => {
      // Setting the static field.key to "raw" key without indexes as the key will be replaced with indexes later on
      const fieldKey = this.fieldState.generateFieldKey(params.field, params.parentKey);
      this.setFormGroupFields({
        object: params.object[params.field.name],
        fields: params.field[AConst.SUB_FIELDS] || [],
        group: params.group,
        parentKey: fieldKey,
        index1: params.index1,
        index2: params.index2
      }).then(() => {
        resolve();
      });
    });
  }

  private removeFormGroupFields(params) {
    params.fields.forEach(field => {
      this.removeFormGroupField({
        object: params.object,
        field: field,
        group: params.group,
        parentKey: params.parentKey,
        index1: params.index1,
        index2: params.index2
      });
    });
  }

  private removeFormGroupField(params) {
    if (params.field[AConst.INPUT_TYPE] !== AConst.INLINE && params.field[AConst.INPUT_TYPE] !== AConst.INLINE_ARRAY) {
      this.removeFormGroupControl(params);
    } else if (params.field[AConst.INPUT_TYPE] === AConst.INLINE) {
      this.removeFormGroupFieldInline(params);
    } else if (params.field[AConst.INPUT_TYPE] === AConst.INLINE_ARRAY) {
      const array = params.object[params.field.name];
      array.forEach((item, index) => {
        this.removeFormGroupFieldInlineArrayItem(params, item, index);
      });
    }
  }

  // Used when removing inline array elements
  private removeFormGroupControl(params) {
    // The controlKey contains the key with correct inline array indexes
    const controlKey = this.fieldState.getFieldKey(params.field, params.index1, params.index2);
    params.group.removeControl(controlKey);
  }

  // Remove form controls for an inline field element
  private removeFormGroupFieldInline(params) {
    // Setting the static field.key to "raw" key without indexes as the key will be replaced with indexes later on
    const fieldKey = this.fieldState.generateFieldKey(params.field, params.parentKey);
    this.removeFormGroupFields({
      object: params.object[params.field.name],
      fields: params.field[AConst.SUB_FIELDS] || [],
      group: params.group,
      parentKey: fieldKey,
      index1: params.index1,
      index2: params.index2
    });
  }

  private createFormControl(object, field): Promise<FormControl> {
    return new Promise<FormControl>(resolve => {
      this.fieldValueService.getControlValueFromObjectField(object, field.name).then(controlValue => {
        const options: AbstractControlOptions = {
          asyncValidators: [],
          validators: [],
          updateOn: this.getUpdateOn(field)
        };
        this.setValidators(options, field, object, controlValue);
        resolve(new FormControl(controlValue, options));
      });
    });
  }

  private getUpdateOn(field) {
    return field[AConst.FIELD_TYPE] === AConst.MAP_ID ? 'submit' : 'blur';
  }

  private setValidators(options, field, object, controlValue) {
    if (!this.fieldState.checkEditOnce(field, object)) {
      if (field.required) {
        options.validators.push(Validators.required);
      }
      if (field.validation) {
        for (const validationKey in field.validation) {
          if (field.validation.hasOwnProperty(validationKey)) {
            const validationValue = field.validation[validationKey];
            switch (validationKey) {
              case AConst.MAX_LENGTH:
                options.validators.push(Validators.maxLength(validationValue));
                break;
              case AConst.MIN_LENGTH:
                options.validators.push(Validators.minLength(validationValue));
                break;
              case AConst.COMPARE:
                options.validators.push(FieldValidators.validateCompare(this.dateTools, this.commons, object, field));
                break;
              case AConst.USERNAME:
                options.asyncValidators.push(FieldValidators.validateUsername(this.cms, field));
                break;
              case AConst.REG_EXP:
                options.validators.push(Validators.pattern(validationValue));
                break;
              default:
                console.warn('Missing implementation of validation type ' + validationKey);
            }
          }
        }
      }
      if (field[AConst.INPUT_TYPE] === 'precision_date') {
        options.validators.push(FieldValidators.validatePrecisionDate(this.dateTools));
      }
      if (field[AConst.INPUT_TYPE] === 'identifier') {
        options.asyncValidators.push(FieldValidators.validateIdentifier(this.cms, controlValue));
      }
    }
  }

  private getCopyOptions(): Promise<any> {
    return new Promise<any>(resolve => {
      if (!this.copyOptions) {
        this.userSettings.getCopyOptions().then(copyOptions => {
          this.copyOptions = copyOptions;
          resolve(copyOptions);
        });
      } else {
        resolve(this.copyOptions);
      }
    });
  }

  private setCopyOptions(sectionsContainer: SectionsContainer) {
    return new Promise(resolve => {
      this.getCopyOptions().then(copyOptions => {
        const objectType = sectionsContainer.rootObject[AConst.OBJECT_TYPE];
        if (!copyOptions[objectType]) {
          const objCopyOptions = {
            allSections: {keep: true},
            sections: {}
          };
          sectionsContainer.sections.forEach(sect => {
            objCopyOptions.sections[sect.name] = {
              showKeepCheckbox: sect.order > 1,
              keep: true
            };
          });
          copyOptions[objectType] = objCopyOptions;
          this.userSettings.storeCopyOptions(copyOptions);
        }
        sectionsContainer.isCopy = true;
        sectionsContainer.copyOptions = copyOptions;
        resolve();
      });
    });
  }
}
