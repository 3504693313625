import { Injectable } from '@angular/core';
import {AConst} from './a-const.enum';
import {ObjectFieldTraverseService} from './object-field-traverse.service';
import {ModelsService} from './models.service';

@Injectable({
  providedIn: 'root'
})
export class FieldMetaService {

  constructor(private objectFieldTraverse: ObjectFieldTraverseService,
              private modelsService: ModelsService) { }

  getFieldMetaData(object, field): any {
    let fieldMeta = {};
    let subObject = this.objectFieldTraverse.getSubObjectFromField(object, field);
    if (subObject) {
      if (Array.isArray(subObject)) {
        if (subObject.length > 0) {
          subObject = subObject[0];
        } else {
          console.warn("Array is empty, unable to get metadata");
        }
      }
      if (subObject[AConst.$$META]) {
        fieldMeta = subObject[AConst.$$META][field.name];
      } else {
        console.warn("No meta data in object");
      }
    }
    return fieldMeta || {};
  }

  checkSetMetaData(item) {
    let res = item[AConst.$$META];
    if (!res) {
      let model = this.modelsService.getModel(item[AConst.OBJECT_TYPE]);
      if (model) {
        res = model[AConst.$$META];
        item[AConst.$$META] = res;
      } else {
        console.warn("Cannot get meta data for " + item[AConst.OBJECT_TYPE]);
        res = false;
      }
    }
    return res;
  }

}
