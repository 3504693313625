import {Component, Input, OnInit} from '@angular/core';
import {SectionsContainer} from '../../core/sections-container';
import {FieldValueService} from '../../core/field-value.service';
import {ChangeTrackerService} from '../../core/change-tracker.service';
import {CommonsService} from '../../core/commons.service';
import {OptionsService} from '../../core/options.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {AnnotationService} from '../../image-annotation/annotation.service';
import {ObjectEditService} from '../../core/object-edit.service';
import {AConst} from '../../core/a-const.enum';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {FieldParameters} from '../../core/field-parameters';
import {UploadInfo} from '../../object-view/upload-info';

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss']
})
export class MediaListComponent implements OnInit {

  @Input() uploadInfo: UploadInfo;
  @Input() files: Array<any>;
  @Input() fileObjects: Array<any>;
  @Input() parentObject: any;

  confirm = false;
  fileContainers = {};
  commonRegVisible = false;
  sectionsContainer: SectionsContainer;
  allChecked = false;

  private promise;
  private editFields;
  private commonObj;
  private oldValues;
  private loadingContainer = false;
  private modName;

  constructor(private fieldValueHandler: FieldValueService,
              private changeTracker: ChangeTrackerService,
              private commons: CommonsService,
              private optionsService: OptionsService,
              private currentObjectService: CurrentObjectService,
              private annotationService: AnnotationService,
              private objectEditService: ObjectEditService) {
  }

  ngOnInit() {
    this.modName = this.uploadInfo.object_type;
    this.objectEditService.createModelItemGetSectionsContainerForPrimeFields(this.modName).then(sectionsContainer => {
      this.sectionsContainer = sectionsContainer;
      this.commonObj = sectionsContainer.rootObject;
      this.editFields = [];
      this.fieldValueHandler.setObjectEditFields(this.editFields, this.commonObj, false);
    });
  }

  checkAll(checked) {
    this.files.forEach(file => {
      file.checked = checked;
    });
    if (checked) {
      this.promise = setTimeout(() => {
        this.copyFields();
      }, 500);
    } else {
      if (this.promise) {
        clearTimeout(this.promise);
      }
    }
  }

  showCommonReg() {
    this.commonRegVisible = !this.commonRegVisible;
  }

  fileChecked(file) {
    file.singleSelect = !file.singleSelect;
    if (file.singleSelect) {
      this.promise = setTimeout(() => {
        this.copyFields();
      }, 500);
    } else {
      if (this.promise) {
        clearTimeout(this.promise);
      }
    }
  }

  getObjectIdField(item) {
    return this.commons.getObjectIdField(item);
  }

  canAnnotate(file) {
    let res = false;
    if (file[AConst.OBJECT_TYPE] === 'Image') {
      res = this.commons.canAnnotate(this.parentObject);
    }
    return res;
  }

  get curAnn() {
    return this.currentObjectService.curAnn;
  }

  annotateImage(file) {
    this.files.forEach(item => {
      if (item[AConst.ARTIFACT_ID] !== file[AConst.ARTIFACT_ID]) {
        item.$$annotateImage = false;
      } else {
        file.$$annotateImage = true;
      }
    });

    if (!this.parentObject.$$annotateImage && file.$$annotateImage) {
      let contextIds, parentId;
      const contexts = this.parentObject.contexts;
      const artifactId = this.parentObject[AConst.ARTIFACT_ID];
      if (contexts && contexts.length) {
        contextIds = this.commons.getContextIds(contexts);
        parentId = artifactId;
      } else {
        contextIds = [artifactId];
      }

      this.curAnn.closeCallback = (curAnn) => {
        if (curAnn.ane) {
          this.annotationService.saveAnnotation(curAnn).then(() => {
            console.log('Annotation stored');
            this.parentObject.$$annotateImage = false;
          });
        }
      };
      const image = {image_id: file[AConst.ARTIFACT_ID]};
      this.annotationService.setCurAnnotation(this.curAnn, null, this.parentObject, image,
        contextIds, parentId, true, () => {
          this.parentObject.$$annotateImage = true;
        });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.files, event.previousIndex, event.currentIndex);
    this.files.forEach((item, index) => {
      item['order_number'] = index;
    });
  }

  toggle() {
    this.confirm = !this.confirm;
  }

  deleteFile(index) {
    const file = this.files[index];
    if (file._create) {
      this.files.splice(index, 1);
    } else {
      file._destroy = true;
    }
  }

  cancel() {
    this.confirm = false;
  }

  getFileContainer(file, $index) {
    const objectId = this.getObjectId(file);
    file['$$id'] = objectId;
    const res = this.fileContainers[objectId];
    if (!res && !this.loadingContainer) {
      this.loadingContainer = true;
      this.objectEditService.createModelItemGetSectionsContainerForPrimeFields(this.modName, file).then(sectionsContainer => {
        const item = sectionsContainer.rootObject;
        delete item._create; // Must remove _create or file will be stored as new image
        this.files[$index].order_number = $index;
        this.setItemMapping(item, file, sectionsContainer);
        this.fileContainers[objectId] = sectionsContainer;
        this.loadingContainer = false;
        this.fileObjects.push(sectionsContainer.rootObject);
      });
    }
    return res;
  }


  private getObjectId(item) {
    const idField = this.commons.getObjectIdField(item);
    return item[idField];
  }


  private copyFields() {
    const values = {};
    let hasChanges = false;
    this.editFields.forEach(editField => {
      values[editField] = this.fieldValueHandler.getFieldValue(this.commonObj, editField);
      if (!this.oldValues || values[editField] !== this.oldValues[editField]) {
        hasChanges = true;
      }
    });
    if (hasChanges) {
      this.files.forEach((file, $index) => {
        if (file.checked) {
          const fileContainer: SectionsContainer = this.fileContainers[file['$$id']];
          const fileObj = fileContainer.rootObject;
          for (const name in values) {
            if (values.hasOwnProperty(name)) {
              const value = values[name];
              let origVal;
              if (value !== null) {
                if (this.commonRegVisible) {
                  this.setFieldValue(fileContainer, name, value).then(changed => {
                    if (changed) {
                      this.optionsService.setIconAndAuthority(fileContainer.rootObject, name);
                      this.highlightFieldElement(name, $index);
                    }
                  });
                } else {
                  origVal = this.changeTracker.getOriginalFieldValue(fileObj, name);
                  const changed = this.fieldValueHandler.setFieldValue(fileObj, name, origVal);
                  if (changed) {
                    this.highlightFieldElement(name, $index);
                  }
                }
              }
            }
          }
        }
      });
    }
    this.oldValues = values;
    this.promise = setTimeout(() => {
      this.copyFields();
    }, 1000);
  }

  private getFieldParameters(sectionsContainer: SectionsContainer, fieldName): FieldParameters {
    const fieldParameters = new FieldParameters();
    fieldParameters.sectionsContainer = sectionsContainer;
    sectionsContainer.primeFields.forEach(primeField => {
      if (fieldName.indexOf(primeField.name) !== -1) {
        fieldParameters.field = primeField;
        fieldParameters.object = sectionsContainer.rootObject;
        const pathSplit = fieldName.split('.');
        pathSplit.forEach((pathName, index) => {
          if (index < pathSplit.length - 1) {
            fieldParameters.object = fieldParameters.object[pathName];
          }
        });
      }
    });
    return fieldParameters;
  }


  private setFieldValue(sectionsContainer, fieldName, fieldValue): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const fieldParameters = this.getFieldParameters(sectionsContainer, fieldName);
      if (fieldParameters.field) {
        this.fieldValueHandler.setFieldValueAndControlValue(
          fieldParameters, fieldParameters.object, fieldParameters.field.name, fieldValue).then(
          changed => {
            resolve(changed);
          }
        );
      } else {
        console.warn('No prime field named ' + fieldName + ' found');
        resolve(false);
      }
    });
  }

  private highlightFieldElement(fieldName, index) {
    const fileIndex = index + 1;
    const fieldElement = document.getElementsByName(fieldName);
    if (fieldElement[fileIndex]) {
      fieldElement[fileIndex]['classList'].add('field-highlighted');
    }
  }

  private setItemMapping(item, file, sectionsContainer: SectionsContainer) {
    if (this.uploadInfo.item_mapping) {
      let separator = '';
      this.uploadInfo.item_mapping.forEach(mapping => {
        let targetVal = '';
        if (mapping.source_fields) {
          mapping.source_fields.forEach(sourceFieldInfo => {
            const sourceType = sourceFieldInfo.source_type;
            const sourceField = sourceFieldInfo.source_field;
            let source = {};
            switch (sourceType) {
              case 'art':
                source = this.parentObject;
                break;
              case 'file':
                source = file;
                break;
            }
            const sourceVal = source[sourceField];
            if (sourceVal) {
              targetVal += separator + sourceVal;
              separator = mapping.separator;
            }
          });
        }
        const targetField = mapping.target_field;
        const fieldMeta = item[AConst.$$META][targetField];
        const maxLength = fieldMeta[AConst.VALIDATION][AConst.MAX_LENGTH];
        if (maxLength) {
          targetVal = targetVal.substr(0, maxLength);
        }
        this.setFieldValue(sectionsContainer, targetField, targetVal).then();
      });
    }
  }

}
