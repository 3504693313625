import {Component, OnInit} from '@angular/core';
import {UIRouterGlobals} from '@uirouter/core';
import {AnnotationService} from '../annotation.service';
import {CommonsService} from '../../core/commons.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {AConst} from '../../core/a-const.enum';
import {Annotation} from '../annotation';

@Component({
  selector: 'app-image-annotation',
  templateUrl: './image-annotation.component.html',
  styleUrls: ['./image-annotation.component.scss']
})
export class ImageAnnotationComponent implements OnInit {

  edit: boolean;
  curAnn: Annotation;

  private contextIds = null;
  private parentId = null;


  constructor(private uiRouterGlobals: UIRouterGlobals,
              private annotationService: AnnotationService,
              private commons: CommonsService,
              private currentObjectService: CurrentObjectService) {
  }

  ngOnInit() {
    this.curAnn = this.currentObjectService.curAnn;
    this.edit = this.uiRouterGlobals.params.edit === 'true';

    if (this.uiRouterGlobals.params.contextIds) {
      this.contextIds = this.uiRouterGlobals.params.contextIds;
      if (!Array.isArray(this.contextIds)) {
        this.contextIds = [this.contextIds];
      }
    }

    const annotationId = this.uiRouterGlobals.params.annotationId;
    let imageId;
    if (!annotationId) {
      imageId = this.uiRouterGlobals.params.imageId;
      if (this.uiRouterGlobals.params.parentId) {
        this.parentId = this.uiRouterGlobals.params.parentId;
      } else {
        this.parentId = this.contextIds[0];
      }
    }
    const annotateTarget = {
      annotation_events: []
    };
    const annotateImage = {
      image_id: imageId
    };

    this.curAnn.closeCallback = (curAnn) => {
      if (curAnn.ane) {
        this.saveCloseAnnotation(curAnn);
      } else {
        this.closeAnnotation();
      }
    };

    this.annotationService.setCurAnnotation(this.curAnn, annotationId, annotateTarget, annotateImage, this.contextIds, this.parentId,
      false, ane => {
        this.contextIds = this.commons.getContextIds(
          ane[AConst.CONTEXTS]);
        this.parentId = ane[AConst.PARENT_ID];
      }
    );
  }

  private saveCloseAnnotation(curAnn) {
    this.annotationService.saveAnnotation(curAnn).then(() => {
      window.history.back();
    });
  }

  private closeAnnotation() {
    window.history.back();
  }


}
