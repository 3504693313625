import { Injectable } from '@angular/core';
import {ReporterUtilityService} from './reporter-utility.service';
import {CommonsService} from '../core/commons.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Service hosting methods used when creating the PDF-report's front page.
 * @type {{create}}
 */
export class ReporterPdfFullObjectFrontPageService {
  private artifactName: '';
  private texts = null;
  private image = null;
  private username = null;
  private settings;


  constructor(private reporterUtility: ReporterUtilityService,
              private commons: CommonsService) {
      this.reporterUtility.getReportSettings().then((settings) => {
      this.settings = settings;
      commons.getUserData(false).then( data => {
        this.username = data['name'];
      });
    });
  }

  /**
   * Creates the JSON-structure representing a pdf make image element.
   * @param image
   * @param width
   * @returns {{image: *, width: *, alignment: string}}
   * @private
   */
  private static createImageJSON(image, width) {
    return {
      image: image,
      width: width,
      alignment: 'center'
    };
  }

  /**
   * Promise returning the front page image JSON-structure for the
   * specified image type.
   * @param image
   * @private
   */
  private createFrontPageImage(image) {
    return new Promise((resolve) => {
      this.reporterUtility.isImagePortrait(image).then((res) => {
        const imgWidth = ((res) ? 300 : 400);
        resolve(ReporterPdfFullObjectFrontPageService.createImageJSON(image, imgWidth));
      }).catch(function (e) {
        console.error(e);
      });
    });
  }

  /**
   * Creates the header JSON-structure.
   * @returns {{text: string, style: string, bold: boolean, margin: number[]}}
   * @private
   */
  private createHeaderJson() {
    return {
      text: this.texts.header.toUpperCase(),
      style: 'frontpageHeader',
      bold: true,
      margin: [0, 40, 0, 10]
    };
  }

  /**
   * Creates the artifact name JSON-structure.
   * @returns {{text: string, style: string, margin: number[]}}
   * @private
   */
  private createArtifactNameJson() {
    return {
      text: this.artifactName.toUpperCase(),
      style: 'frontpageHeader',
      margin: [0, 0, 0, 15]
    };
  }

  /**
   * Creates the museum name JSON-structure.
   * @returns {{text: *, style: string}}
   * @private
   */
  private createMuseumNameJson() {
    return {
      text: this.settings.museumName,
      style: 'frontpageDetails'
    };
  }

  /**
   * Creates the JSON-structures that defines the report's front page.
   * @returns {*}
   * @private
   */
  private createFrontPageJSON(createdHr) {

    if (this.image === null || typeof(this.image) === 'undefined') {
      this.image = {};
    }

    return new Promise((resolve) => {
      const created = this.texts.created + ': ' + createdHr;
      const primusVersion = this.texts.version;

      const frontPage = {
        stack: [
          this.image,
          {
            table: {
              widths: ['auto', 'auto'],
              body: [
                [
                  {text: ' ', margin: [7, 0, 0, 0]},
                  [
                    {
                      image: this.reporterUtility.getPrimusLogo(),
                      width: 80
                    },
                    this.createHeaderJson(),
                    this.createArtifactNameJson(),
                    this.createMuseumNameJson(),
                    {
                      text: created,
                      style: 'frontpageDetails'
                    },
                    {
                      text: primusVersion,
                      style: 'frontpageDetails'
                    },
                    {
                      text: this.texts.createdBy + ' ' + this.username,
                      style: 'frontpageDetails'
                    }
                  ]
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0;
              },
              vLineWidth: function (i, node) { return (i === 0) ? 4 : 0; },
              vLineColor: function (i, node) { return '#7e97ad'; }
            },
            margin: [40, 30, 0, 0]
          }
        ],
        pageBreak: 'after'
      };

      resolve({username: this.username, frontpage: frontPage});
    });
  }

  /**
   * Promise, returning the front page's JSON-structure when resolved.
   * @param imageSrc
   * @param artifactName
   * @param texts
   * @param createdHr
   * @returns {*}
   */
  create(imageSrc, artifactName, texts, createdHr) {
    this.artifactName = artifactName;
    this.texts = texts;

    return new Promise((resolve) => {
      const promises = [];

      if (typeof(imageSrc) !== 'undefined') {
        promises.push(this.createFrontPageImage(imageSrc));
      }

      Promise.all(promises).then((values) => {
        if (values.length === 2) {
          this.image = values[0];
        } else {
          this.image = null;
        }

        resolve(this.createFrontPageJSON(createdHr));
      });
    });
  }

}
