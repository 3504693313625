import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SectionsContainer} from '../../core/sections-container';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-object-view-and-edit',
  templateUrl: './object-view-and-edit.component.html',
  styleUrls: ['./object-view-and-edit.component.scss']
})
export class ObjectViewAndEditComponent implements OnInit {

  @Input() sectionsContainer: SectionsContainer;
  @Input() templateGroupId: string;
  @Input() isEditing: boolean;
  @Output() closeEdit = new EventEmitter<any>();

  AConst = AConst;

  constructor() {
  }

  ngOnInit() {
  }

  onCloseEdit() {
    this.closeEdit.emit();
  }

}
