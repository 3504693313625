import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ObjectDeletionService} from '../../core/object-deletion.service';
import {UserDialogsService} from '../user-dialogs.service';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-delete-item-button',
  templateUrl: './delete-item-button.component.html',
  styleUrls: ['./delete-item-button.component.scss']
})
export class DeleteItemButtonComponent implements OnInit {

  @Input() rootObject: any;
  @Input() parentObject: any;
  @Input() fieldName: string;
  @Input() item: any;
  @Input() deleteFromStore: boolean;
  @Input() checkUsage: boolean;

  @Output() callback = new EventEmitter<void>();

  error = null;
  confirm = false;

  private justClicked = false;
  private clickCount = 0;


  constructor(private objectDeletionService: ObjectDeletionService,
              private userDialogService: UserDialogsService) {
  }

  ngOnInit() {
  }

  private deleteArtifact(fn) {
    this.objectDeletionService.deleteObject(this.item).then(
      () => {
        fn.success();
      },
      message => {
        fn.error('Failed: ' + message);
      }
    );
  }

  private detachImage(fn) {
    if (this.parentObject) {
      this.objectDeletionService.detachImagesFromObject(this.parentObject, [this.item],
        () => {
          fn.success();
        },
        error => {
          fn.error('Detaching images failed with message ' + error);
        });
    } else {
      throw new Error('A parent must be present in order to delete images');
    }
  }

  private itemsEqual(item1, item2) {
    const res = {
      equal: false,
      err: null
    };
    if (item1.order_number === undefined) {
      res.err =
        'Missing order number for ' + this.fieldName;
    } else {
      res.equal =
        item1.order_number === item2.order_number;
    }
    return res;
  }

  private deleteArrayItem(fn) {
    let itemIndex = -1, err, index, res;
    const array = this.parentObject[this.fieldName];

    if (!this.rootObject) {
      err = 'Root model must be set in order to delete';
    } else {
      for (index = 0; index < array.length; index++) {
        res = this.itemsEqual(array[index], this.item);
        if (res.equal) {
          itemIndex = index;
          break;
        }
        err = res.err;
      }
      if (itemIndex !== -1) {
        this.objectDeletionService.deleteItem(this.rootObject, this.fieldName, itemIndex);
        if (this.deleteFromStore) {
          this.deleteArtifact(fn);
        } else {
          fn.success();
        }
      } else {
        err = 'Unable to find item in array ';
      }
    }
    if (err) {
      fn.error(err);
    }
  }

  private deleteIt(fn) {
    if (this.fieldName) {
      this.deleteArrayItem(fn);
    } else {
      if (this.item.object_type !== 'Image') {
        this.deleteArtifact(fn);
      } else {
        this.detachImage(fn);
      }
    }
  }

  private setClickCount() {
    this.clickCount++;
    if (this.clickCount > 20) {
      this.confirm = false;
      this.error = {
        message: 'TRANS__DELETE_ITEM__TEST'
      };
      this.clickCount = 0;
    }
  }

  private deleteTheItem() {
    this.deleteIt({
      success: () => {
        if (this.callback) {
          this.callback.emit();
        }
      },
      error: (errMsg) => {
        this.confirm = false;
        this.error = {
          message: 'TRANS__DELETE_ITEM__ERROR_DELETING'
        };
        console.warn(errMsg);
      }
    });
  }

  deleteItem() {
    this.justClicked = true;
    this.confirm = !this.confirm;
    this.setClickCount();
  }

  confirmDelete() {
    this.justClicked = true;
    if (this.checkUsage) {
      this.userDialogService.showObjectUsage(this.item[AConst.ARTIFACT_ID], true,
        'TRANS__DELETE_ITEM__CANNOT_DELETE_HAS_USAGES').then(usage => {
        if (!usage) {
          this.deleteTheItem();
        }
      });
    } else {
      this.deleteTheItem();
    }
  }
  cancel() {
    this.justClicked = true;
    this.confirm = false;
  }

  dismissError() {
    this.error = null;
  }

}
