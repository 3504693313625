import {Injectable} from '@angular/core';
import {StateService} from '@uirouter/core';
import {UiToolsService} from '../core/ui-tools.service';
import {NotificationService} from '../shared/notification.service';
import {QueryParserService} from '../core/query-parser.service';
import {MediaHelperService} from '../core/media-helper.service';
import {FieldValueService} from '../core/field-value.service';
import {CmsApiService} from '../core/cms-api.service';
import {CmsQueueService} from '../core/cms-queue.service';
import {DateToolsService} from '../core/date-tools.service';
import {UserSettingsService} from '../core/user-settings.service';
import {CommonsService} from '../core/commons.service';
import {AConst} from '../core/a-const.enum';
import {PerformanceTimer} from '../core/performance-timer';
import {SearchContainer} from './search-container';
import {Focus, SearchFocusService} from './search-focus.service';
import {searchStateParamMapper, searchSettingsParams} from './search-state-params';

@Injectable({
  providedIn: 'root'
})
export class SearchHandlerService {

  defaultRows = {};
  fsi: Focus;

  constructor(private $state: StateService,
              private uiTools: UiToolsService,
              private notificationService: NotificationService,
              private queryParser: QueryParserService,
              private mediaHelper: MediaHelperService,
              private fieldValueHandler: FieldValueService,
              private cms: CmsApiService,
              private cmsQueue: CmsQueueService,
              private dateTools: DateToolsService,
              private userSettings: UserSettingsService,
              private commons: CommonsService,
              private searchFocusService: SearchFocusService) {
  }

  /**
   * @param params Params for creating search container
   * @param.searchViewName Name of search view to retrieve from
   * server.
   * @param.filter Search filters, which will be added to search view
   * filters
   * @param.targetState The target state name that will be used when
   * setting state parameters.
   * @param.stateParams Initial state parameters that will be used
   * to set some of the search container attributes. Relevant for
   * page reload.
   * @param.targetObject An object that will be associated with the
   * search container. The object can later be accessed directly
   * from the search container object.
   */
  createSearchContainer(params): Promise<SearchContainer> {
    return new Promise((resolve) => {
      let viewItemId;
      const filters = params.filters || {};
      if (params.targetObject) {
        viewItemId = params.targetObject[AConst.ARTIFACT_ID];
      }
      this.getSearchView(params.searchViewName, filters).then(
        (view) => {
          const searchContainer: SearchContainer = new SearchContainer();
          if (view) {
            searchContainer.origSelected = this.commons.copy(params.selected) || [];
            searchContainer.restrictions = params.restrictions;
            searchContainer.rows = params.rows || {};
            searchContainer.searchPostFn = params.searchPostFn;
            searchContainer.searchResultViews = view[AConst.SEARCH_RESULT_VIEWS];
            searchContainer.searchView = view;
            searchContainer.selected = params.selected || [];
            searchContainer.state.targetState = params.targetState;
            searchContainer.targetObject = params.targetObject;
            searchContainer.templateGroupId = params.templateGroupId;
            searchContainer.used = params.used || [];
            searchContainer.keepSelected = params.keepSelected;
            this.setDefaultRows(searchContainer);
            if (params.getFocus) {
              this.fsi = this.searchFocusService.createFocus(searchContainer);
              this.fsi.getSetFocuses().then(
                () => {
                  const curFocus = this.setSearchContainerFromFocus(this.fsi, params);
                  if (!curFocus) {
                    this.setDefaultSearchResultView(searchContainer);
                    this.setDefaultSearchContainerProps(searchContainer, params);
                  }
                  this.finishSearchContainerCreation(searchContainer, params).then((searchContainerFinished) => {
                    resolve(searchContainerFinished);
                  });
                });
            } else {
              this.setDefaultSearchResultView(searchContainer);
              this.setDefaultSearchContainerProps(searchContainer, params);
              this.finishSearchContainerCreation(searchContainer, params).then((searchContainerFinished) => {
                resolve(searchContainerFinished);
              });
            }
          } else {
            console.warn('No view: ' + params.searchViewName);
          }
        }, viewItemId);
    });
  }

  setDefaultRows(searchContainer: SearchContainer) {
    const viewNames = ['content-list', 'thumbnail', 'list-thumbnail',
      'list', 'folder', 'selector', 'report'];
    viewNames.forEach(
      (viewName) => {
        searchContainer.searchResultViews.views.forEach(
          (view) => {
            if (view.name === viewName) {
              this.defaultRows[viewName] = this.getSearchRows(viewName);
            }
          });
      }
    );
  }

  getDefaultRows(view) {
    let res = {
      rows: 50,
      widthResultItems: undefined,
      thumbWidth: undefined
    };
    const viewName = this.getViewName(view);
    if (this.defaultRows[viewName]) {
      res = this.defaultRows[viewName];
    }
    return res;
  }

  getPathView(path, searchContainer: SearchContainer) {
    let pathView;
    const searchView = searchContainer.searchView;
    if (searchView) {
      pathView = this.findPathView(path, searchView);
      if (!pathView) {
        console.warn('Could not find path \'' + path + '\' ' +
          'in menu view');
      }
    } else {
      console.warn('Search view not defined in search container');
    }
    return pathView;
  }

  setReportView(searchContainer: SearchContainer) {
    this.setCurrentResultView('report', searchContainer);
  }

  setCurrentResultView(viewName, searchContainer: SearchContainer) {
    if (viewName === 'thumbnail') {
      this.setDefaultRows(searchContainer);
    }
    const r = this.getDefaultRows(viewName);
    if (searchContainer) {
      searchContainer.searchPage = 1;
      searchContainer.searchResultViewName = viewName;
      searchContainer.combineHorizontal = viewName !== 'list';
      if (r) {
        searchContainer.rows = searchContainer.rows || {};
        searchContainer.rows[viewName] = r.rows;
        if (viewName === 'thumbnail') {
          if (r.widthResultItems) {
            searchContainer.widthResultItems = r.widthResultItems;
          }
          if (r.thumbWidth) {
            searchContainer.thumbWidth = r.thumbWidth;
          }
        }
      }
    }
  }

  getCurrentActionMenus(searchContainer: SearchContainer) {
    let res = [];
    const pathView = searchContainer.currentPathView;
    if (pathView && pathView[AConst.ACTION_MENUS]) {
      res = pathView[AConst.ACTION_MENUS];
    }

    return res;
  }

  subscribeToSearchResult(searchContainer: SearchContainer, callback) {
    searchContainer.searchResultCallbacks.push(callback);
    if (searchContainer.searchResult) {
      callback(searchContainer.searchResult);
    }
  }

  unSubscribeToSearchResult(searchContainer: SearchContainer, callback) {
    let t, callbackItem;
    const callbacks = searchContainer.searchResultCallbacks;
    for (t = 0; t < callbacks.length; t++) {
      callbackItem = callbacks[t];
      if (callbackItem === callback) {
        callbacks.splice(t, 1);
        break;
      }
    }
  }

  runSearch(searchContainer: SearchContainer, keepSelected?) {
    return new Promise(resolve => {
      this.getSearchParams(searchContainer, false).then(
        (searchParams) => {
          if (searchContainer.searchResultViewName === 'list') {
            searchParams.overview_fields = searchContainer.currentPathView[AConst.OVERVIEW_FIELDS];
          }
          const keepSel = keepSelected !== undefined ? keepSelected : searchContainer.keepSelected;
          this.startSearch(searchContainer, searchParams, keepSel).then(
            (result) => {
              this.executeSearchResultCallbacks(searchContainer);
              resolve(result);
            });
        }
      );
    });
  }

  selectionsChanged(searchContainer: SearchContainer) {
    let res = false, t;
    const origSelected = searchContainer.origSelected;
    const selected = searchContainer.selected;

    if (searchContainer.singleSelect) {
      res = selected.length !== 0;
    } else {
      if (origSelected.length !== selected.length) {
        res = true;
      } else {
        for (t = 0; t < origSelected.length; t++) {
          if (selected.indexOf(origSelected[t]) === -1) {
            res = true;
            break;
          }
        }
      }
    }
    return res;
  }

  setState(searchContainer: SearchContainer) {
    const stateParams = {};
    const targetState = searchContainer.state.targetState;
    const toState = this.$state.current.name;
    if (toState && toState.indexOf(targetState) === 0) {
      this.commons.each(searchStateParamMapper, (stateParamName, scParamName) => {
          this.setStateParam(searchContainer, stateParams, scParamName, stateParamName);
        }
      );
      if (targetState === toState) {
        this.$state.go(searchContainer.state.targetState, stateParams);
      }
    }
  }

  setOrder(searchContainer: SearchContainer, fieldName, fieldInfo?) {
    const curOrder = searchContainer.order;
    let orderFieldName = fieldName;
    if (fieldInfo && fieldInfo[AConst.FIELD_TYPE] === AConst.MAP_ID) {
      orderFieldName = fieldName + '_value';
    }
    if (curOrder.indexOf(orderFieldName) === -1 || curOrder.indexOf(' desc') > 1) {
      searchContainer.order = (fieldInfo && fieldInfo.path) ? fieldInfo.path + '.' + orderFieldName : orderFieldName;
    } else {
      searchContainer.order += ' desc';
    }
    searchContainer.searchPage = 1;
    this.runSearch(searchContainer, true).then(() => {

    });
  }

  getSearchFields(searchContainer): Promise<Array<any>> {
    return new Promise(resolve => {
      let meta, fields;
      if (searchContainer.currentPathView) {
        fields = searchContainer.currentPathView[AConst.OVERVIEW_FIELDS];
        meta = this.searchFiltersToMeta(searchContainer.currentPathView.filters);
        if (!fields || (meta && (searchContainer.oldTempGroupId !== undefined
          && searchContainer.templateGroupId !== searchContainer.oldTempGroupId))) {
          const objType = meta[AConst.OBJECT_TYPE] || meta[AConst.CONCEPT_TYPE_ID];
          this.getOverviewFields(objType, searchContainer.templateGroupId).then(ovFields => {
            searchContainer.currentPathView[AConst.OVERVIEW_FIELDS] = ovFields;
            resolve(<Array<any>>ovFields);
          });
        } else {
          resolve(fields);
        }
        searchContainer.oldTempGroupId = searchContainer.templateGroupId;
      } else {
        resolve();
      }
    });
  }

  getFacetCount(facetName, searchContainer: SearchContainer) {
    if (!searchContainer.facetCount[facetName]) {
      searchContainer.facetCount[facetName] = {
        totalCount: 0,
        itemCounts: {}
      };
    }
    return searchContainer.facetCount[facetName];
  }

  getFilterCount(filter, searchContainer: SearchContainer) {
    let res, facetCount;
    facetCount = this.getFacetCount(filter.name, searchContainer);
    res = facetCount.itemCounts[filter.value] || facetCount.itemCounts[filter.name];
    if (res === undefined) {
      res = 0;
    }
    return res;
  }

  goPathView(path, searchContainer: SearchContainer) {
    this.checkSetParamsFromFocus(path, searchContainer);
    this.closeActionMenus(searchContainer);
    searchContainer.path = path;
    searchContainer.searchPage = 1;
    searchContainer.searchResult = null;
    this.setCurrentPathView(searchContainer).then((pathView) => {
      if (!pathView[AConst.OVERRIDE_TARGET]) {
        this.setCurrentResultViewFromPathView(searchContainer, pathView);
        setTimeout(() => {
          this.resetFilter(searchContainer);
          if (searchContainer.curFocusId) {
            this.setTotalSelectedFilters(searchContainer);
            this.setCurrentFocusName(searchContainer);
          }
        }, 500);
      }
    });
  }

  goSearchMenu(menu, searchContainer: SearchContainer) {
    searchContainer.targetId = menu.targetId;
    searchContainer.objectCount = menu.objectCount;
    this.goPathView(menu.path, searchContainer);
  }

  getCheckFilterGroups(searchContainer: SearchContainer) {
    let fGroupsOrig, fGroups, pathView;
    pathView = searchContainer.currentPathView;
    if (pathView) {
      fGroupsOrig = pathView[AConst.CHECK_FILTER_GROUPS];
      if (fGroupsOrig) {
        fGroups = this.commons.copy(fGroupsOrig);
        fGroups.forEach((fGroup) => {
          this.getCheckFilterGroupFilters(fGroup, searchContainer);
        });
      }
    }
    return fGroups;
  }

  checkMenuFilter(menu, searchContainer, noSearch?) {
    this.checkFilter({
        name: menu[AConst.FACET],
        value: menu[AConst.FACET_ITEM]
      },
      menu, searchContainer, noSearch).then();
  }

  checkCheckFilter(filter, searchContainer): Promise<void> {
    return new Promise<void>(resolve => {
      this.checkFilter(filter, filter, searchContainer, false).then(() => {
        resolve();
      });
    });
  }

  setRangeFilter(rangeGroup, range, searchContainer: SearchContainer) {
    const checkedFilters = this.getCheckedFilters(searchContainer);
    const rangeGroups = searchContainer.currentPathView[AConst.FACET_RANGE_GROUPS];
    this.deleteCheckFilterValue(checkedFilters[this.getRangeFilterName(range)], range.oldRangeFilter);
    this.checkFilter({
        name: this.getRangeFilterName(range),
        value: this.getRangeFilterValue(range)
      },
      range, searchContainer, false).then();
    rangeGroups.forEach((existingGroup) => {
      let facetRange;
      if (rangeGroup.title === existingGroup.title) {
        facetRange = this.getFacetRangeFromTitle(existingGroup[AConst.FACET_RANGES], range.title);
        if (facetRange) {
          facetRange.start = range.start;
          facetRange.end = range.end;
        }
      }
    });
  }

  checkRangeFilter(rangeGroup, range, searchContainer: SearchContainer) {
    const rangeFilterName = this.getRangeFilterName(range);
    const unChecked = this.unCheckOldRangeFilters(rangeGroup, rangeFilterName, searchContainer);
    if (!unChecked) {
      this.checkFilter({
          name: rangeFilterName,
          value: this.getRangeFilterValue(range)
        },
        range, searchContainer, false).then();
    }
  }

  // LOOP-O-RAMA!!!
  setCheckedRangeFacets(searchContainer: SearchContainer) {
    const facetRangeGroups =
      searchContainer.searchResult[AConst.FACET_RANGE_GROUPS];
    this.commons.each(
      <object>this.getCheckedFilters(searchContainer),
      (filterName, filterValues) => {
        if (filterValues.length > 0) {
          facetRangeGroups.forEach((group) => {
              group[AConst.FACET_RANGES].forEach((range) => {
                  if (this.getRangeFilterName(range) === filterName) {
                    range.checked = true;
                    this.setRangeFromFilter(range, filterValues);
                  }
                }
              );
            }
          );
        }
      }
    );
  }

  setOldRangeFilters(searchContainer: SearchContainer) {
    searchContainer.searchResult[AConst.FACET_RANGE_GROUPS].forEach((group) => {
        group[AConst.FACET_RANGES].forEach((range) => {
            range.oldRangeFilter = this.getRangeFilterValue(range);
          }
        );
      }
    );
  }

  getSearchMenuPath(searchContainer: SearchContainer) {
    let pathView = searchContainer.currentPathView;
    const pathNames = this.getPath(searchContainer).split('/');
    const res = [];
    let path = '', pathSplit = '';
    pathNames.forEach((pathName, index) => {
      let menuPath, targetId;
      path += pathSplit + pathName;
      pathView = this.getPathView(path, searchContainer);
      if (pathView) {
        targetId = pathName === 'folder' ? searchContainer.targetId : 'none';
        menuPath = {
          title: pathView[AConst.SEARCH_VIEW].title,
          targetId: targetId
        };
        if (index + 1 < pathNames.length) {
          menuPath.path = path;
        }
        res.push(menuPath);
        pathSplit = '/';
      }
    });
    return res;
  }

  selectSearchResultItem(obj, searchContainer: SearchContainer) {
    let existPos;
    if (!searchContainer.singleSelect) {
      if (!searchContainer.selected) {
        searchContainer.selected = [];
      }
      existPos =
        searchContainer.selected.indexOf(obj.artifact_id);
      if (existPos === -1) {
        searchContainer.selected.push(obj.artifact_id);
      } else {
        searchContainer.selected.splice(existPos, 1);
      }
    } else {
      existPos = searchContainer.selected.indexOf(obj.artifact_id);

      if (obj.artifact_id === searchContainer.selected[0]) {
        searchContainer.selected.splice(existPos, 1);
        obj.selected = false;
      } else {
        searchContainer.selected = [];
        searchContainer.selected.push(obj.artifact_id);
        this.setSelectedUsed(searchContainer, searchContainer.searchResult);
      }
    }
    this.setState(searchContainer);
  }

  setSearchItemIndex(searchContainer: SearchContainer, index) {
    searchContainer.searchItemIndex = index;
  }

  searchItemPrev(searchContainer: SearchContainer) {
    return new Promise(resolve => {
      searchContainer.searchItemIndex--;
      if (searchContainer.searchItemIndex >= 0) {
        resolve(this.getCurrentSearchItem(searchContainer));
      } else {
        if (searchContainer.searchPage > 1) {
          searchContainer.searchPage--;
          searchContainer.searchItemIndex = searchContainer.searchResult[AConst.ARTIFACTS].length - 1;
          this.runSearch(searchContainer).then(() => {
            resolve(this.getCurrentSearchItem(searchContainer));
          });
        } else {
          searchContainer.searchItemIndex = 0;
          resolve(null);
        }
      }
    });
  }

  searchItemNext(searchContainer: SearchContainer) {
    return new Promise(resolve => {
      searchContainer.searchItemIndex++;
      if (searchContainer.searchItemIndex < searchContainer.searchResult[AConst.ARTIFACTS].length) {
        resolve(this.getCurrentSearchItem(searchContainer));
      } else {
        if (searchContainer.searchPage < searchContainer.maxPage) {
          searchContainer.searchPage++;
          searchContainer.searchItemIndex = 0;
          this.runSearch(searchContainer).then(() => {
            resolve(this.getCurrentSearchItem(searchContainer));
          });
        } else {
          resolve(null);
        }
      }
    });
  }

  getSearchParams(searchContainer: SearchContainer, includeSelected): Promise<any> {
    return new Promise(resolve => {
      const pathView = searchContainer.currentPathView || {};
      let res, query = '*:*';
      this.getSort(searchContainer).then((sort) => {
        let fq = pathView.fq;
        const rows = this.getRows(searchContainer);
        // Used for setting folder id in folder search view
        if (fq && fq.indexOf('{}') !== -1 && searchContainer.targetObject) {
          fq = fq.replace('{{', '{').replace('}}', '}');
          fq = fq.replace('{}', searchContainer.targetObject[AConst.ARTIFACT_ID]);
        }
        if (!this.hasQueryField(searchContainer)) {
          query = this.queryParser.parse(searchContainer.query);
        }

        res = {
          filters: this.getSearchFilters(pathView, searchContainer, includeSelected),
          query: query,
          fq: fq,
          facets: this.getFacets(pathView),
          facet_range_groups: pathView[AConst.FACET_RANGE_GROUPS],
          rows: rows,
          start: (searchContainer.searchPage - 1) *
            rows,
          sort: sort,
          overview: true,
          template_group_id: searchContainer.templateGroupId,
          combine_horizontal: searchContainer.combineHorizontal
        };
        if (searchContainer.noFullSearch) {
          res.fl = ['artifact_id'];
          res.overview = false;
        }
        res[AConst.INCLUDE_META] = true;
        res.search_engine = searchContainer.searchEngine;
        resolve(res);
      });
    });
  }

  getMenuCount(menu, searchContainer: SearchContainer) {
    let facetItem, res = 0;
    const facet = this.getSearchFacet({name: menu[AConst.FACET]}, searchContainer);
    if (facet) {
      facetItem = this.getFacetItem(facet, menu[AConst.FACET_ITEM]);
      if (facetItem) {
        res = facetItem[AConst.COUNT];
      }
    }
    return res ? res : 0;
  }

  checkFieldIsSortable(field) {
    let res = true;
    if (field[AConst.IS_ARRAY]  || field[AConst.FIELD_TYPE] === 'array') {
      res = false;
    }
    return res;
  }

  getAllArtifactsFromSearch(searchContainer: SearchContainer) {
    return new Promise(resolve => {
      this.getSearchParams(searchContainer, true).then(
        (searchParams) => {
          searchParams.rows = searchContainer.searchResult[AConst.SEARCH_COUNT] || searchParams.rows;
          searchParams.start = 0;
          searchParams[AConst.INCLUDE_META] = false;
          searchParams.fl = ['artifact_id'];

          this.cms.searchJson(searchParams).then(
            (searchRes) => {
              // filter to just artifact ids
              resolve(searchRes[AConst.ARTIFACTS].map((art) => {
                return art.artifact_id;
              }));
            },
            (response) => {
              console.log(response.message, response.status);
            }
          );
        }
      );
    });
  }

  getDecodedStateParameter(origValue) {
    let res = origValue;
    if (origValue) {
      let val;
      do {
        val = res;
        res = decodeURIComponent(val);
      } while (res.length !== val.length);
    }
    return res;
  }

  private addSearchResError(searchRes, params) {
    console.warn('Search error: ' + searchRes[AConst.STATUS_MSG] + ': ' + JSON.stringify(params));
    this.notificationService.addNotification({
      messages: ['TRANS__SEARCH__ILLEGAL_SEARCH', ': \'', searchRes[AConst.STATUS_MSG], '\''],
      type: 'error',
      status: searchRes[AConst.STATUS_CODE]
    });
  }

  private calcSearchRows(params) {
    const minTileRows = params.minTileRows;
    const tileHeight = params.tileHeight;
    const tileWidth = params.tileWidth;
    const res = {
      rows: 50,
      widthResultItems: 16,
      thumbWidth: '100px'
    };
    let tileRows, tileColumns = 1;
    const s = this.getSearchViewSizes(params);
    if (s) {
      tileRows = Math.max(minTileRows, Math.floor(s.height / tileHeight));
      if (tileWidth) {
        tileColumns = Math.floor(s.width / tileWidth);
        res.thumbWidth = Math.floor((tileWidth * tileColumns) - 10) + 'px';
        res.widthResultItems = 100 / tileColumns;
      }
      res.rows = tileColumns * tileRows;
    }
    return res;
  }

  private checkDeletePreCheckFilter(searchContainer: SearchContainer, filter) {
    let filterGroup;
    if (filter.preChecked) {
      filterGroup = this.getNamedFilterGroup(searchContainer, filter.name);
      if (filterGroup) {
        this.findDeleteFilterGroupFilter(filterGroup.checkFilters, 'value', filter);
      }
    }
  }

  // Check whether named fields are part of overview fields and can be used for sorting
  private checkFieldExistsAndSortable(searchContainer: SearchContainer, fieldName) {
    return new Promise(resolve => {
      this.getSearchFields(searchContainer).then((fields) => {
        let fieldNo, field, res = false;
        if (fields && fieldName) {
          for (fieldNo = 0; fieldNo < fields.length; fieldNo++) {
            field = fields[fieldNo];
            if (fieldName.indexOf(field.name) !== -1) {
              if (this.checkFieldIsSortable(field)) {
                res = true;
              }
              break;
            }
          }
        }
        resolve(res);
      });
    });
  }

  private checkFieldIsInSortOrderMenus(searchContainer: SearchContainer, orderField) {
    let res = false, sortOrderMenus, sortOrderMenu, t;
    if (searchContainer.currentPathView) {
      sortOrderMenus = searchContainer.currentPathView[AConst.SORT_ORDER_MENUS];
      for (t = 0; t < sortOrderMenus.length; t++) {
        sortOrderMenu = sortOrderMenus[t];
        if (orderField === sortOrderMenu.order) {
          res = true;
          break;
        }
      }
    }
    return res;
  }

  private checkFilter(filter, checkObject, searchContainer, noSearch?): Promise<void> {
    return new Promise<void>(resolve => {
      let checkedFilters, existIndex;

      checkedFilters = this.getCheckedFilters(searchContainer);
      if (!checkedFilters[filter.name]) {
        checkedFilters[filter.name] = [];
      }
      existIndex = this.checkFilterExists(checkedFilters[filter.name],
        filter.value);
      if (existIndex !== -1) {
        checkedFilters[filter.name].splice(existIndex, 1);
        this.checkDeletePreCheckFilter(searchContainer, filter);
      } else {
        // console.log(filter);
        checkedFilters[filter.name].push(filter.value);
      }
      checkObject.checked = existIndex === -1;
      searchContainer.searchPage = 1;
      this.setFiltersChecked(searchContainer);
      if (!noSearch) {
        this.runSearch(searchContainer).then(() => {
          this.setCheckFilterMenusAfterSearch(searchContainer);
          this.setTotalSelectedFilters(searchContainer);
          resolve();
        });
      } else {
        this.setTotalSelectedFilters(searchContainer);
        resolve();
      }
    });
  }

  private checkFilterExists(filterValues, filterValue) {
    let res = -1;
    filterValues.forEach((existingValue, index) => {
      if (existingValue === filterValue) {
        res = index;
      }
    });
    return res;
  }

  private checkItemInArray(arr, art, setProp) {
    const index = arr.indexOf(art[AConst.ARTIFACT_ID]);
    const found = index !== -1;
    switch (setProp) {
      case 'selected':
        art.selected = found;
        break;
      case 'used':
        if (found) {
          art.selected = true;
        }
        art.$$used = found;
        break;
      default:
        console.warn('Unknown prop ' + setProp);
    }
  }

  private checkMenuFiltersFromDefaultFilters(searchContainer: SearchContainer, defFilters) {

    this.loopMenusAndFilters(searchContainer, defFilters,
      (menu) => {
        this.checkMenuFilter(menu, searchContainer, true);
      });
  }

  private checkMenusFromCheckedFilters(searchContainer: SearchContainer) {
    this.loopMenusAndFilters(searchContainer, this.getCheckedFilters(searchContainer),
      (menu) => {
        menu.checked = true;
      });
  }

  private checkObjectCount(searchContainer: SearchContainer) {
    const sc = searchContainer, objectCount = sc.objectCount;
    if (objectCount) {
      sc.windowSize = this.uiTools.windowSize;
    }
  }

  private checkSetFilterChecked(searchContainer: SearchContainer, checkFilters) {
    const checkedFilters = this.getCheckedFilters(searchContainer);
    this.commons.each(checkFilters, (key, checkFilter) => {
      if (checkedFilters[checkFilter.name]) {
        checkedFilters[checkFilter.name].forEach(
          (checkedValue) => {
            if (checkFilter.value === checkedValue) {
              checkFilter.checked = true;
            }
          });
      }
    });
  }

  private checkSetParamsFromFocus(path, searchContainer: SearchContainer) {
    const curFocusId = searchContainer.curFocusId;
    let pathParams;
    if (curFocusId) {
      pathParams = this.fsi.getSetFocusPathParams(curFocusId, path);
      if (pathParams) {
        this.fsi.setFocusPathParamsOnSearchContainer(pathParams);
      }
    }
  }

  private closeActionMenus(searchContainer: SearchContainer) {
    if (searchContainer.reportContainer) {
      searchContainer.reportContainer.actions.forEach(
        (action) => {
          if (action.buttonClass === 'cancel-button') {
            action.fn();
          }
        }
      );
    }
  }

  private convertStateParamVal(valIn) {
    let res = valIn;
    if (valIn === 'true') {
      res = true;
    } else if (valIn === 'false') {
      res = false;
    }
    return res;
  }

  private deleteCheckFilterValue(filterValues, filterValue) {
    const index = this.checkFilterExists(filterValues, filterValue);
    if (index !== -1) {
      filterValues.splice(index, 1);
    }
  }

  private executeSearchResultCallbacks(searchContainer: SearchContainer) {
    // A timeout is necessary in order to set some useful properties before running callbacks (setting checked menus etc.)
    setTimeout(() => {
      searchContainer.searchResultCallbacks.forEach((callback) => {
        callback(searchContainer.searchResult);
      });
    }, 500);
  }

  private findDeleteFilterGroupFilter(filters, valueField, filter) {
    let deleteIndex = -1;
    filters.forEach((f, index) => {
      if (f[valueField] === filter.value) {
        deleteIndex = index;
      }
    });
    if (deleteIndex !== -1) {
      filters.splice(deleteIndex, 1);
    }
  }

  private finishSearchContainerCreation(searchContainer: SearchContainer, params): Promise<SearchContainer> {
    return new Promise(resolve => {
      this.setSearchResultViewDefaultRows(searchContainer);
      if (searchContainer.curFocusId) {
        this.setCurrentFocusName(searchContainer);
      }
      this.setCurrentPathView(searchContainer).then((pathView) => {
        this.setCheckedFiltersFromSearchContainer(searchContainer,
          !!pathView, params.defaultCheckedFilters);

        if (params.runSearch) {
          this.runSearch(searchContainer, true).then(() => {
            resolve(searchContainer);
          });
        } else {
          resolve(searchContainer);
        }
      });

    });
  }

  private findPathView(pathName, searchView) {
    let t, path, res;
    if (searchView.paths) {
      for (t = 0; t < searchView.paths.length; t++) {
        path = searchView.paths[t];
        if (path[AConst.PATH_NAME] === pathName) {
          res = this.commons.copy(path);
          break;
        }
      }
    } else {
      console.warn('No paths!');
    }
    return res;
  }

  private getCheckedFilters(searchContainer: SearchContainer) {
    return searchContainer.checkedFilters;
  }

  private getCheckFilterGroupFilters(filterGroup, searchContainer: SearchContainer) {
    let checkFilters = [], checkFilter, filterName = 'no-name';
    filterGroup.totalCount = 0;
    this.commons.each(filterGroup.filters, (key, filter) => {
      let facetItem, facet;
      filterName = filter.name;
      facet = this.getSearchFacet(filter, searchContainer);
      if (facet) {
        if (filter.value !== null && filter.value !== true) {
          facetItem = this.getFacetItemFromFilter(facet, filter);
          filterGroup.totalCount += facetItem.count;
        } else {
          filterGroup.totalCount += facet[AConst.TOTAL_COUNT];
        }
      }
      if (filter.value !== null) {
        checkFilters.push(filter);
      } else if (facet) {
        if (facet.items.length) {
          checkFilter = this.getCheckFiltersFromFacet(facet, filter);
          checkFilters = checkFilters.concat(checkFilter);
        } else {
          if (filter[AConst.CHECKED_VALUE] !== null) {
            checkFilters.push(this.getPreCheckedCheckFilter(filter));
          }
        }
      }
    });
    filterGroup.name = filterName;
    this.checkSetFilterChecked(searchContainer, checkFilters);
    filterGroup.checkFilters = checkFilters;
  }

  private getCheckFiltersFromFacet(facet, filter) {
    const res = [];

    facet.items.forEach((facetItem) => {
      const checkFilter = {
        name: filter.name,
        value: facetItem.id ? facetItem.id :
          facetItem.name,
        title: null,
        noTransTitle: null
      };
      if (filter.translate) {
        checkFilter.title = facetItem.name;
      } else {
        checkFilter.noTransTitle = facetItem.name;
      }
      res.push(checkFilter);
    });
    return res;
  }

  private getCurrentSearchItem(searchContainer: SearchContainer) {
    return searchContainer.searchResult[AConst.ARTIFACTS][searchContainer.searchItemIndex];
  }

  private getDefaultCheckedFilters(searchContainer: SearchContainer) {
    const res = {};
    const filterGroups = searchContainer.currentPathView[AConst.CHECK_FILTER_GROUPS];
    if (filterGroups) {
      filterGroups.forEach((checkFilterGroup) => {
        this.commons.each(checkFilterGroup.filters, (key, filter) => {
          if (filter[AConst.CHECKED_VALUE] !== null) {
            res[filter.name] = [filter[AConst.CHECKED_VALUE]];
            filter.checked = true;
          }
        });
      });
    }
    return res;
  }

  /*
  Returns the default search result view, but also checks whether
  the default search view name is a part of the defined search views
  */
  private getDefaultSearchResultView(view) {
    let res = null;

    if (!view[AConst.SEARCH_RESULT_VIEWS]) {
      console.error('No search result views defined for ' + view[AConst.VIEW_NAME]);
    }
    view[AConst.SEARCH_RESULT_VIEWS][AConst.VIEWS].forEach(
      (searchResultView) => {
        if (searchResultView.name ===
          view[AConst.SEARCH_RESULT_VIEWS].default) {
          res = searchResultView;
        }
      }
    );
    if (!res) {
      console.error('Default search result view not found');
    }
    return res;
  }

  private getFacetItem(facet, itemName) {
    let t, res, item;
    for (t = 0; t < facet.items.length; t++) {
      item = facet.items[t];
      if (item.id === itemName || item[AConst.NAME] === itemName) {
        res = item;
        break;
      }
    }
    return res;
  }

  private getFacetItemFromFilter(facet, filter) {
    let res = {count: 0};
    facet.items.forEach((item) => {
      if (!item.id) {
        console.warn('No item id!');
      }
      if (item.id === filter.value) {
        res = item;
      }
    });
    return res;
  }

  private getFacetRangeFromTitle(facetRanges, title) {
    let res = null, t;
    for (t = 0; t < facetRanges.length; t++) {
      if (facetRanges[t].title === title) {
        res = facetRanges[t];
        break;
      }
    }
    return res;
  }

  private getFacets(pathView) {
    const res = pathView.facets ? this.commons.copy(pathView.facets) : [];
    const checkFilters = pathView[AConst.CHECK_FILTER_GROUPS];
    if (checkFilters) {
      this.commons.each(checkFilters, (key, fGroup) => {
        this.commons.each(fGroup.filters, (filterKey, filter) => {
          res.push(filter.name);
        });
      });
    }
    return res;
  }

  private getFilterName(key: string) {
    return key.split(':')[0];
  }

  private getFolderDate(searchContainer: SearchContainer) {
    const date = searchContainer.targetObject[AConst.CREATED_AT];
    searchContainer.targetObject.date = this.dateTools.precisionDateToString(date);
    return searchContainer;
  }

  private getNamedFilterGroup(searchContainer: SearchContainer, name) {
    let t, filterGroup, res;
    for (t = 0; t < searchContainer.filterGroups.length; t++) {
      filterGroup = searchContainer.filterGroups[t];
      if (filterGroup.name === name) {
        res = filterGroup;
        break;
      }
    }
    if (!res) {
      console.warn('Could not find filter group ' + name);
    }
    return res;
  }

  private getOverviewFields(objectType, templateGroupId) {
    return new Promise(resolve => {
      this.cmsQueue.runCmsFnWithQueue('getModelOverviewFields',
        {
          modelName: objectType,
          type: 'overview',
          template_group_id: templateGroupId
        }, false).then(
        (data) => {
          resolve(data);
        },
        (response) => {
          console.error('Unable to get overview fields: ' + response.toLocaleString());
        }
      );
    });
  }

  private getPath(searchContainer): string {
    if (!searchContainer.path) {
      searchContainer.path = 'home';
    }
    return searchContainer.path;
  }

  private getPreCheckedCheckFilter(filter) {
    const checkFilter = {
      preChecked: true,
      name: filter.name,
      checked: true,
      value: filter[AConst.CHECKED_VALUE],
      noTransTitle: ''
    };
    const params = {
      filters: {
        artifact_id: filter[AConst.CHECKED_VALUE]
      }
    };
    this.fieldValueHandler.getMappedValueQueued(params).then(
      (mappedValue) => {
        checkFilter.noTransTitle = mappedValue;
      });

    return checkFilter;
  }

  private getRangeFilterName(range) {
    return range[AConst.F_NAME] + ':' + range.title;
  }

  private getRangeFilterValue(range) {
    return '[' + range.start + ' TO ' + range.end + ']';
  }

  private getRows(searchContainer: SearchContainer) {
    let rows = searchContainer.rows[searchContainer.searchResultViewName];
    if (rows) {
      if (searchContainer.displayAll) {
        rows *= searchContainer.maxPage;
      }
    } else {
      if (searchContainer.searchResultViewName) {
        rows = this.getSearchRows(searchContainer.searchResultViewName).rows;
      } else {
        rows = searchContainer.rows;
      }
    }
    return rows;
  }

  private getSearchFacet(filter, searchContainer: SearchContainer) {
    let res, t, facet, facets;
    const sr = searchContainer.searchResult;
    const facetName = filter.name;
    if (sr && sr.facets) {
      facets = sr.facets;
      for (t = 0; t < facets.length; t++) {
        facet = facets[t];
        if (facet[AConst.F_NAME] === facetName) {
          res = facet;
          break;
        }
      }
    }
    if (!res) {
      console.warn('Facet named \'' + facetName + '\' not found for path view ' + searchContainer.path);
    }
    return res;
  }

  private getSearchFilters(pathView, searchContainer, includeSelected) {
    let res = {}, query = '';
    const qfm = this.hasQueryField(searchContainer);
    const selectFilter = searchContainer.allSelected ? '-artifact_id' : 'artifact_id';
    const checkedFilters = this.getCheckedFilters(searchContainer);
    let replaceStr = '\\ ';

    if (pathView.filters) {
      res = this.commons.copy(pathView.filters);
    }
    this.setRestrictionFilters(searchContainer, res);
    this.commons.each(checkedFilters, (key, value) => {
      const name = this.getFilterName(key);
      if (value.length > 0) {
        res[name] = value;
      }
    });
    if (qfm) {
      if (searchContainer.query) {
        query = searchContainer.query;
        if (qfm[AConst.QUERY_TYPE] === 'field_upper') {
          query = this.queryParser.parse(query);
          query = '*' + query.toLocaleUpperCase();
          replaceStr = '_';
        }
        query = query.replace(/ /g, replaceStr);
      }
      res[searchContainer.queryField] = query + '*';
    }
    if (includeSelected && searchContainer.selected.length > 0) {
      res[selectFilter] = searchContainer.selected;
    }
    // Filter values containing "range" values, e.g. "[
    this.commons.each(res, (key, value) => {
      if (Array.isArray(value)) {
        const v = value[0];
        if (typeof v === 'string' && v.indexOf('[') === 0) {
          res[key] = value[0];
        }
      }
    });
    return res;
  }

  private getSearchRows(viewName) {
    let res, heightOffset;

    switch (viewName) {
      case 'content-list':
        const artLowerEl = document.getElementById('artifactLower');
        heightOffset = 200;
        if (artLowerEl) {
          heightOffset = artLowerEl.offsetTop + 100;
        } else {
          console.warn('Cannot find element artifactLower anymore!!!');
        }
        res = this.calcSearchRows({
          minTileRows: 5,
          tileHeight: 140,
          heightOffset: heightOffset,
          noReport: true
        });
        break;
      case 'thumbnail':
        res = this.calcSearchRows({
          minTileRows: 3,
          tileHeight: 250,
          tileWidth: 202
        });
        break;
      case 'list-thumbnail':
        res = this.calcSearchRows({
          minTileRows: 5,
          tileHeight: 130
        });
        break;
      case 'list':
        res = this.calcSearchRows({
          minTileRows: 10,
          tileHeight: 56
        });
        break;
      case 'folder':
        res = this.calcSearchRows({
          minTileRows: 5,
          tileHeight: 180
        });
        break;
      case 'selector':
        res = this.calcSearchRows({
          minTileRows: 5,
          tileHeight: 136,
          noReport: true
        });
        break;
      case 'report':
        res = {
          rows: 1000
        };
    }
    return res;
  }

  private getSearchView(viewName, filters, viewItemId?) {
    return new Promise((resolve, reject) => {
      if (!viewName) {
        console.error('Missing view name');
      }
      this.cms.getSearchView(
        {
          viewName: viewName,
          filters: filters,
          view_item_id: viewItemId,
          transValues: {viewName: viewName}
        }).then(
        (view) => {
          resolve(view);
        },
        () => {
          reject();
        }
      );
    });
  }

  private getSearchViewSizes(params) {
    let width, height, offsetTop;
    const innerConEl = document.getElementById('innerCon');
    const searchResultViewsEl =
      document.getElementById('searchResultViews');
    const heightOffset = params.heightOffset || 0;
    if (!innerConEl || !searchResultViewsEl) {
      return null;
    }
    if (searchResultViewsEl.offsetTop > 140 && !params.noReport) {
      offsetTop = 140;
    } else {
      offsetTop = searchResultViewsEl.offsetTop;
    }
    width = innerConEl.offsetWidth;
    height = window.innerHeight - offsetTop - 126 - heightOffset;
    return {
      width: width,
      height: height
    };
  }

  private getSort(searchContainer: SearchContainer) {
    return new Promise(resolve => {
      this.checkFieldExistsAndSortable(searchContainer, searchContainer.order).then(
        (existsAndSortable) => {
          let res, isSortOrderMenuField = false;
          if (!existsAndSortable) {
            isSortOrderMenuField = this.checkFieldIsInSortOrderMenus(searchContainer, searchContainer.order);
          }
          if (existsAndSortable || isSortOrderMenuField) {
            res = searchContainer.order;
          } else {
            if (searchContainer.currentPathView) {
              res = searchContainer.currentPathView[AConst.SORT_ORDER_MENUS][0].order;
            } else {
              res = 'updated_at desc';
            }
            searchContainer.order = res;

          }
          resolve(res);
        });
    });
  }

  private getStateParamVal(stateParamKey, stateParamVal) {
    if (!Array.isArray(stateParamVal)) {
      stateParamVal = this.getDecodedStateParameter(stateParamVal);
    }
    let res = stateParamVal;
    const isArrayParam = ['selected'].indexOf(stateParamKey) !== -1;
    const isObjectParam = ['filter'].indexOf(stateParamKey) !== -1;
    if (stateParamVal === 'false') {
      res = false;
    } else if (stateParamVal === 'true') {
      res = true;
    } else if (!isNaN(stateParamVal) && searchSettingsParams[stateParamKey].type === 'int') {
      res = parseInt(stateParamVal, 10);
    } else if (isArrayParam) {
      if (typeof stateParamVal === 'string') {
        res = stateParamVal.split(',');
      }
    } else if (isObjectParam) {
      if (!Array.isArray(stateParamVal)) {
        stateParamVal = [stateParamVal];
      }
      res = {};
      stateParamVal.forEach((val) => {
        val = this.getDecodedStateParameter(val);
        const split = this.splitFilter(val);
        if (!res[split[0]]) {
          res[split[0]] = [];
        }
        res[split[0]].push(this.convertStateParamVal(split[1]));
      });
    }
    return res;
  }

  private getViewName(view) {
    let res, searchResultView;
    if (typeof view === 'object') {
      searchResultView = this.getDefaultSearchResultView(view);
      if (searchResultView) {
        res = searchResultView.name;
      }
    } else {
      res = view;
    }
    return res;
  }

  private hasQueryField(searchContainer: SearchContainer) {
    let res = null, qm;
    const qf = searchContainer.queryField;
    if (qf) {
      qm = searchContainer.currentPathView[AConst.QUERY_MENUS];
      if (qm) {
        qm.menus.forEach((menu) => {
          if (menu[AConst.QUERY_FIELD] === qf) {
            res = menu;
          }
        });
      }
    }
    return res;
  }

  private isObject(param) {
    return param !== null && typeof param === 'object' && !Array.isArray(param);
  }

  private loopFindMenuFilters(menu, filters, fn) {
    this.commons.each(filters, (fName, values) => {
      let valIndex;
      if (menu.facet === fName) {
        valIndex = values.indexOf(menu[AConst.FACET_ITEM]);
        if (valIndex !== -1) {
          fn(menu);
        }
      }
    });
  }

  private loopMenusAndFilters(searchContainer: SearchContainer, filters, fn) {
    if (filters && searchContainer.currentPathView.menus) {
      searchContainer.currentPathView.menus.forEach((menu) => {
        this.loopFindMenuFilters(menu, filters, fn);
      });
    }
  }

  private loadTargetObject(searchContainer: SearchContainer) {
    return new Promise(resolve => {
      const targetId = searchContainer.targetId;
      if (targetId && targetId !== 'none') {
        const params = {};
        params[AConst.ARTIFACT_ID] = searchContainer.targetId;
        this.cms.getArtifact(params).then(targetObject => {
          resolve(targetObject);
        });
      } else {
        resolve(searchContainer.targetObject);
      }
    });
  }

  private loopPathViewMenus(fn, searchContainer: SearchContainer) {
    const menus = searchContainer.currentPathView.menus;
    if (menus) {
      menus.forEach((mainMenu) => {
          fn(mainMenu);
          if (mainMenu.menus) {
            mainMenu.menus.forEach((subMenu) => {
              fn(subMenu);
            });
          }
        }
      );
    }
  }

  private resetFacetCounts(searchContainer: SearchContainer) {
    this.commons.each(searchContainer.facetCount, (key, facet) => {
      this.commons.each(facet.itemCounts, (itemCountsKey) => {
        facet.itemCounts[itemCountsKey] = 0;
      });
      facet.totalCount = 0;
    });
  }

  private resetFilter(searchContainer: SearchContainer) {
    if (!searchContainer.curFocusId) {
      this.setCheckedFilters(searchContainer, this.getDefaultCheckedFilters(searchContainer));
    }
    this.setFiltersChecked(searchContainer);
    this.resetMenuChecks(searchContainer);
    searchContainer.facetCount = {};
    searchContainer.selected = [];
    searchContainer.allSelected = false;
    this.runSearch(searchContainer).then(() => {
      this.checkMenusFromCheckedFilters(searchContainer);
      searchContainer.filterGroups = this.getCheckFilterGroups(searchContainer);
    });
  }

  private resetMenuChecks(searchContainer: SearchContainer) {
    this.loopPathViewMenus((menu) => {
      delete menu.checked;
    }, searchContainer);
  }

  private searchFiltersToMeta(filters) {
    const filterMetaAttr = [AConst.OBJECT_TYPE, AConst.CONCEPT_TYPE_ID];
    let meta = null;

    filterMetaAttr.forEach((attr) => {
      let attrVal = filters[attr];
      if (attrVal) {
        if (Array.isArray(attrVal)) {
          attrVal = attrVal[0];
        }
        meta = meta || {};
        meta[attr] = attrVal;
      }
    });

    return meta;
  }

  private setCheckedFilters(searchContainer: SearchContainer, value) {
    searchContainer.checkedFilters = value;
    this.setTotalSelectedFilters(searchContainer);
  }

  private setCheckedFiltersFromSearchContainer(searchContainer: SearchContainer, hasPathView, defaultCheckedFilters) {

    const checkedFilters = this.getCheckedFilters(searchContainer);
    if (hasPathView) {
      this.checkMenuFiltersFromDefaultFilters(
        searchContainer,
        defaultCheckedFilters);
      this.checkMenusFromCheckedFilters(searchContainer);
    }

    if (Object.keys(checkedFilters).length === 0) {
      this.setCheckedFilters(searchContainer,
        this.getDefaultCheckedFilters(searchContainer));
    } else {
      if (searchContainer.curFocusId) {
        this.setTotalSelectedFilters(searchContainer);
      }

    }
  }

  /*
  If a filter menu contains a pre-selected value, then only
  the single filter value will be displayed in the filter menu.
  If the user de-selects this value, then the filter menu needs to be
  rebuilt in order to include new filter values
   */
  private setCheckFilterMenusAfterSearch(searchContainer: SearchContainer) {
    if (searchContainer.filterGroups) {
      searchContainer.filterGroups.forEach((filterGroup) => {
        this.commons.each(filterGroup.filters, (key, filter) => {
          const facets = this.getSearchFacet(filter, searchContainer);
          const namedFilterGroup = this.getNamedFilterGroup(searchContainer, filter.name);
          if (facets && namedFilterGroup) {
            if (facets.items.length > namedFilterGroup.checkFilters.length) {
              this.getCheckFilterGroupFilters(namedFilterGroup, searchContainer);
            }
          }
        });
      });
    }
  }

  private setCurrentFocusName(searchContainer: SearchContainer) {
    this.commons.each(searchContainer.focuses, (id, focus) => {
      if (id === searchContainer.curFocusId) {
        searchContainer.currentFocusName = focus.name;
      }
    });
  }

  private setCurrentPathView(searchContainer: SearchContainer) {
    return new Promise(resolve => {
      const path = this.getPath(searchContainer);
      const pathView = this.getPathView(path, searchContainer);
      let searchView;
      if (pathView) {
        searchView = pathView[AConst.SEARCH_VIEW];
        searchContainer.currentPathView = searchView;
        if (pathView[AConst.RESET_QUERY]) {
          searchContainer.query = '';
        }
        this.setSelectedQueryFieldMenu(searchContainer);
      }
      this.loadTargetObject(searchContainer).then((targetObject) => {
        searchContainer.targetObject = targetObject;
        if (searchContainer.targetObject &&
          searchContainer.currentPathView[AConst.SEARCH_VIEW_TYPE] === 'folder') {
          searchContainer.object = {
            artifact_id: searchContainer.targetObject[AConst.CREATED_BY_ID]
          };
          this.getFolderDate(searchContainer);
        }
        resolve(searchView);
      });
    });
  }

  // If new path view contains own search result views, check
  // whether current search result view exists in the path view's
  // search results views, and if not set current search result
  // view to path view's default search result view
  private setCurrentResultViewFromPathView(searchContainer: SearchContainer, pathView) {
    let searchResultViews = pathView[AConst.SEARCH_RESULT_VIEWS];
    if (!searchResultViews) {
      searchResultViews = searchContainer.searchView[AConst.SEARCH_RESULT_VIEWS];
    }
    this.setDefaultOrExistingSearchView(searchContainer, searchResultViews);
  }

  private setDefaultOrExistingSearchView(searchContainer: SearchContainer, searchResultViews) {
    let hasThisViewName = false;
    searchContainer.searchResultViews = searchResultViews;
    searchResultViews[AConst.VIEWS].forEach(
      (searchResultView) => {
        if (searchResultView.name === searchContainer.searchResultViewName) {
          hasThisViewName = true;
        }
      });
    if (!hasThisViewName) {
      this.setCurrentResultView(searchResultViews.default, searchContainer);
    }
  }

  private setDefaultSearchContainerProps(searchContainer: SearchContainer, params) {
    const toState = this.$state.current.name;

    if (params.stateParams && toState &&
      toState.indexOf(params.targetState) === 0) {
      this.setSearchContainerPropsFromStateParams(searchContainer, params.stateParams);
    }
  }

  private setDefaultSearchResultView(searchContainer: SearchContainer) {
    const defaultSearchView = this.getDefaultSearchResultView(searchContainer.searchView);
    this.setCurrentResultView(defaultSearchView.name, searchContainer);
  }

  private setDefaultSelectedQueryFieldMenu(searchContainer: SearchContainer) {
    const qm = searchContainer.currentPathView[AConst.QUERY_MENUS];
    if (qm) {
      qm.menus.forEach((menu) => {
        if (menu.selected) {
          searchContainer.selectedQueryMenu = menu;
        }
      });
      if (!searchContainer.selectedQueryMenu) {
        searchContainer.selectedQueryMenu = qm[0];
      }
    }
  }

  private setFacetCount(searchContainer: SearchContainer) {
    const sr = searchContainer.searchResult;
    this.resetFacetCounts(searchContainer);
    sr.facets.forEach((facet) => {
      const facetName = facet[AConst.F_NAME];
      const facetCount = this.getFacetCount(facetName, searchContainer);
      facetCount.totalCount = facet[AConst.TOTAL_COUNT];
      this.setFacetItemCounts(facet, facetCount);
    });
  }

  private setFacetItemCounts(facet, facetCount) {
    facet.items.forEach((item) => {
      const name = item.id ? item.id : item.name;
      facetCount.itemCounts[name] = item.count;
    });
  }

  /**
   * @param searchContainer
   * The setFiltersChecked will set the searchContainer property
   * filtersChecked to "checked" if there are any checked filters.
   * It is used in searchFilterMenuSmall directive to flag whether
   * filters have been checked.
   */
  private setFiltersChecked(searchContainer: SearchContainer) {
    let res = 'notChecked', filterName, filters;
    const checkedFilters = this.getCheckedFilters(searchContainer);
    for (filterName in checkedFilters) {
      if (checkedFilters.hasOwnProperty(filterName)) {
        filters = checkedFilters[filterName];
        if (filters.length > 0) {
          res = 'checked';
          break;
        }
      }
    }
    searchContainer.filtersChecked = res;
  }

  private setMaxPage(searchContainer: SearchContainer) {
    const sc = searchContainer;
    sc.maxPage = Math.ceil(searchContainer.searchResult[AConst.SEARCH_COUNT] / sc.rows[sc.searchResultViewName]);
  }

  private setRangeFromFilter(range, filterValues) {
    if (range.start) {
      filterValues.forEach((val) => {
        const split = val.substring(1, val.length - 1).split(' TO ');
        range.start = split[0];
        range.end = split[1];
      });
    }
  }

  private setRestrictionFilters(searchContainer: SearchContainer, filters) {
    if (searchContainer.restrictions) {
      searchContainer.restrictions.forEach(
        (restrict) => {
          if (restrict.on) {
            filters[restrict.fieldId] = restrict.value;
          }
        }
      );
    }
  }

  private setScValFromStateParam(searchContainer: SearchContainer, paramKey, stateParamVal) {
    if (this.isObject(stateParamVal)) {
      this.commons.each(stateParamVal, (key, value) => {
        searchContainer[paramKey][key] = value;
      });
    } else {
      searchContainer[paramKey] = stateParamVal;
    }
  }

  private setSearchContainerFromFocus(fsi: Focus, params) {
    let curFocus;
    const curFocusId = params.stateParams.cur_focus_id;
    if (curFocusId) {
      curFocus = fsi.setCurrentFocus(curFocusId);
    } else if (params.useFocus) {
      curFocus = fsi.getDefaultCurrentFocus();
    }
    if (curFocus) {
      fsi.setFocusPathParamsOnSearchContainer(curFocus.pathParams[curFocus.homePath]);
    }
    return curFocus;
  }

  private setSearchContainerPropsFromStateParams(searchContainer: SearchContainer, $stateParams) {
    const paramMapper = searchStateParamMapper;
    let containerParamsChanged = false;
    this.commons.each($stateParams, (stateParamKey, stateParamVal) => {
        let conParamKey, conParamVal;
        if (stateParamVal) {
          conParamKey = paramMapper[stateParamKey];
          if (conParamKey) {
            stateParamVal = this.getStateParamVal(stateParamKey, stateParamVal);
            conParamVal = searchContainer[conParamKey];
            if (this.stateParamDiffersFromSc(stateParamVal, conParamVal)) {
              containerParamsChanged = true;
              this.setScValFromStateParam(searchContainer, conParamKey, stateParamVal);
            }
          }
        }
      }
    );
    this.setTotalSelectedFilters(searchContainer);
    return containerParamsChanged;
  }

  private setSearchResultItemProps(searchContainer: SearchContainer) {
    const parentObj = searchContainer.targetObject;
    if (parentObj) {
      searchContainer.searchResult[AConst.ARTIFACTS].forEach(
        (item) => {
          if (item[AConst.META_TYPE] === 'media') {
            this.mediaHelper.setMediaProps(item, parentObj);
          }
        }
      );
    }
  }

  private setSearchResultViewDefaultRows(searchContainer: SearchContainer) {
    searchContainer.searchView[AConst.SEARCH_RESULT_VIEWS][AConst.VIEWS].forEach((searchResultView) => {
      const r = this.getDefaultRows(searchResultView.name);
      if (r) {
        searchContainer.rows[searchResultView.name] = r.rows;
        if (r.widthResultItems) {
          searchContainer.widthResultItems = r.widthResultItems;
        }
        if (r.thumbWidth) {
          searchContainer.thumbWidth = r.thumbWidth;
        }
      }

    });
  }

  private setSelectedQueryFieldMenu(searchContainer: SearchContainer) {
    const qf = searchContainer.queryField;
    const qm = searchContainer.currentPathView[AConst.QUERY_MENUS];

    if (this.hasQueryField(searchContainer)) {
      qm.menus.forEach((menu) => {
        menu.selected = menu[AConst.QUERY_FIELD] === qf;
        if (menu.selected) {
          searchContainer.selectedQueryMenu = menu;
        }
      });
    } else {
      this.setDefaultSelectedQueryFieldMenu(searchContainer);
    }
    if (qm) {
      searchContainer.queryTooltip = qm[AConst.TOOLTIP];
    } else {
      searchContainer.queryTooltip = searchContainer.defaultQueryTooltip;
    }
    searchContainer.queryPlaceholder = searchContainer.currentPathView.title;
  }

  private setSelectedUsed(searchContainer: SearchContainer, searchRes) {
    let sel, used, t, art;
    sel = searchContainer.selected;
    used = searchContainer.used;
    if (searchContainer.allSelected) {
      searchRes[AConst.ARTIFACTS].forEach((artItem) => {
        artItem.selected = true;
        sel.forEach((selectedId) => {
          if (selectedId === artItem[AConst.ARTIFACT_ID]) {
            artItem.selected = false;
          }
        });
      });
    } else {
      if (sel.length > 0 || used.length > 0) {
        for (t = 0; t < searchRes[AConst.ARTIFACTS].length; t++) {
          art = searchRes[AConst.ARTIFACTS][t];
          this.checkItemInArray(sel, art, 'selected');
          this.checkItemInArray(used, art, 'used');
        }
      }
    }
  }

  private setStateParam(searchContainer: SearchContainer, stateParams, scParamName, stateParamName) {
    const scParamVal = searchContainer[scParamName];
    if (this.isObject(scParamVal)) {
      stateParams[stateParamName] = [];
      this.commons.each(scParamVal, (key, values) => {
        if (values && values.length > 0) {
          values.forEach((value) => {
            const param = key + ':' + value;
            stateParams[stateParamName].push(param);
          });
        }
      });

    } else {
      stateParams[stateParamName] = scParamVal;
    }
  }

  private setTotalSelectedFilters(searchContainer: SearchContainer) {
    searchContainer.totalSelectedFilters = 0;
    this.commons.each(this.getCheckedFilters(searchContainer), (key, filter) => {
      searchContainer.totalSelectedFilters += filter.length;
    });
  }

  private splitFilter(val) {
    let splitIndex;
    const split = [];
    if (val.indexOf('[') !== -1) {
      // is probably range filter
      splitIndex = val.indexOf('[') - 1;
    } else {
      splitIndex = val.indexOf(':');
    }
    if (splitIndex !== -1) {
      split[0] = val.substring(0, splitIndex);
      split[1] = val.substring(splitIndex + 1);
    } else {
      split[0] = val;
    }
    return split;

  }

  private startSearch(searchContainer: SearchContainer, searchParams, keepSelected) {
    return new Promise(resolve => {
      const viewName = searchContainer.searchView[AConst.VIEW_NAME];
      const timer = new PerformanceTimer('runSearch ' + viewName);

      timer.start();
      searchContainer.searching = true;
      this.cms.searchJson(searchParams).then(
        (searchRes) => {
          searchContainer.searching = false;
          timer.measure('Search success');
          if (searchRes[AConst.STATUS] !== 'failed') {
            if (!keepSelected) {
              searchContainer.allSelected = false;
              searchContainer.selected = [];
            }
            searchContainer.searchResult = searchRes;
            this.setSelectedUsed(searchContainer, searchRes);
            this.setSearchResultItemProps(searchContainer);
            this.setFacetCount(searchContainer);
            this.checkObjectCount(searchContainer);
            searchContainer.facetRangeGroups = searchRes[AConst.FACET_RANGE_GROUPS];
            this.setMaxPage(searchContainer);
            resolve(searchRes);
            if (searchContainer.searchPostFn) {
              searchContainer.searchPostFn(searchRes);
            }
            this.setState(searchContainer);
          } else {
            this.addSearchResError(searchRes, searchParams);
            resolve(searchRes);
          }
        },
        () => {
          searchContainer.searching = false;
        }
      );

    });
  }

  private stateParamDiffersFromSc(stateParamVal, scVal) {
    let res = false;
    if (this.isObject(stateParamVal)) {
      this.commons.each(stateParamVal, (key, values) => {
        const scValues = scVal[key];
        if (scValues && scValues.length === values.length) {
          values.forEach((val) => {
            if (values.indexOf(val) === -1) {
              res = true;
            }
          });
        } else {
          res = true;
        }
      });
    } else {
      res = stateParamVal !== scVal;
    }
    return res;
  }

  private unCheckOldRangeFilters(rangeGroup, rangeName, searchContainer: SearchContainer) {
    let res = false;
    rangeGroup[AConst.FACET_RANGES].forEach((oldRange) => {
        const oldRangeName = this.getRangeFilterName(oldRange);
        if (oldRange.checked) {
          this.checkFilter({
              name: oldRangeName,
              value: this.getRangeFilterValue(oldRange)
            },
            oldRange, searchContainer, false).then();
          if (oldRangeName === rangeName) {
            res = true;
          }
        }
      }
    );
    return res;
  }

}
