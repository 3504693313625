import {Component, Input, OnChanges} from '@angular/core';
import {RefService} from '../../core/ref.service';

@Component({
  selector: 'app-download-url',
  templateUrl: './download-url.component.html',
  styleUrls: ['./download-url.component.scss']
})
export class DownloadUrlComponent implements OnChanges {
  url: String = null;
  @Input() object;

  constructor(private ref: RefService) {
  }

  ngOnChanges() {
    const ref = this.ref.makeUrl({object: this.object});
    if (ref) {
      this.url = ref.url;
    }
  }
}
