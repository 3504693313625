/**
 * Service hosting methods used when serializing/deserializing JavaScript objects.
 *
 * @type {{serialize, deserialize}}
 */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ObjectParserService {

  constructor() { }


  /**
   * Removes newline- and tab characters from the specified argument.
   * @param arg
   * @returns {string}
   * @private
   */
  private static clean(arg) {
    let str = arg.toString();
    str = str.replace(new RegExp('\n', 'g'), '');
    str = str.replace(new RegExp('\t', 'g'), '');
    return str;
  }

  /**
   * Serializes the specified JavaScript object, preserving functions.
   * @param obj
   * @returns {string}
   * @private
   */
  serialize(obj) {
    return JSON.stringify(obj, (key, val) => {
      if (typeof(val) === 'function') {
        return ObjectParserService.clean(val);
      }
      return val;
    });
  }

  /**
   * Deserialize the specified string, restoring the original object's
   * properties and functions.
   * @param str
   * @returns {}
   * @private
   */
  deserialize(str) {
    return JSON.parse(str, function (key, val) {
      if (typeof(val) === 'string' && val.indexOf('function') > -1) {
        return eval('(' + val + ')');
      }
      return val;
    });
  }

  /**
   * Converts a function template to a string-representation of the function.
   * Values with the syntax ##value## is replaced by the specified value inside
   * the templateArgs array.
   *
   * The templateArgs array has the following syntax:
   * [{ key: 'keyname', val: 'value }, ....]
   * @param fn Function
   * @param templateArgs Array
   * @returns {string}
   * @private
   */
  fnToString(fn, templateArgs) {
    let f = fn.toString();
    f = f.replace(new RegExp('\n', 'g'), '');
    f = f.replace(new RegExp('\t', 'g'), '');
    // f = f.replace(new RegExp(' ', 'g'), '');

    for (let n = 0; n < templateArgs.length; n++) {
      f = f.replace('##' + templateArgs[n].key + '##', templateArgs[n].val);
    }

    return f;
  }
}
