import {Component, OnInit} from '@angular/core';
import {LoginService} from '../../core/login.service';
import {CmsApiService} from '../../core/cms-api.service';
import {SearchPageService} from '../../object-search/search-page.service';
import {CmsErrorHandlerService} from '../../core/cms-error-handler.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  username;
  password;
  rememberMe;
  error;
  message;

  constructor(private loginService: LoginService,
              private cms: CmsApiService,
              private searchPageService: SearchPageService,
              private cmsErrorHandler: CmsErrorHandlerService) {
  }

  ngOnInit() {
    this.searchPageService.clearSearchContainer();

    const fldUsername = document.getElementById('username');
    if (fldUsername) {
      fldUsername.focus();
    }
    this.cms.init((response, params) => {
      this.cmsErrorHandler.errHandler(response, params);
    });
    const sa = this.loginService.getStoredAuth();
    this.username = sa.username;
    this.password = sa.password;
    this.rememberMe = sa.rememberMe;
  }

  login() {
    this.loginService.loginUser(this.username, this.password, this.rememberMe).then(res => {
      this.error = res.error;
      this.message = res.message;
    });
  }

  isProcessing() {
    return this.loginService.isProcessing();
  }

  get progress() {
    let res = '';
    if (this.loginService.progress) {
      res = ' '  + this.loginService.progress + '%';
    }
    return res;
  }
}
