import {Component, Input, OnInit} from '@angular/core';
import {SectionsContainer} from '../../../core/sections-container';
import {AConst} from '../../../core/a-const.enum';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-admin-settings-edit-fields',
  templateUrl: './admin-settings-edit-fields.component.html',
  styleUrls: ['./admin-settings-edit-fields.component.scss']
})
export class AdminSettingsEditFieldsComponent implements OnInit {
  @Input() sectionsContainer: SectionsContainer;
  @Input() form: NgForm;

  AConst = AConst;

  constructor() {
  }

  ngOnInit() {
    this.sectionsContainer.form = this.form;
  }

  sectionFieldsOrdered(sectionNo) {
    // TODO: Set order using 'order' field
    return this.sectionsContainer.sections[sectionNo].fields;
  }

}
