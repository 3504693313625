import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {CmsApiService} from '../../core/cms-api.service';
import {CommonsService} from '../../core/commons.service';
import {ObjectEditService} from '../../core/object-edit.service';
import {DateToolsService} from '../../core/date-tools.service';

@Component({
  selector: 'app-object-update-log',
  templateUrl: './object-update-log.component.html',
  styleUrls: ['./object-update-log.component.scss']
})
export class ObjectUpdateLogComponent implements OnInit {

  @Input() contextId: string;
  @Input() showLog: boolean;

  logItems = [];
  AConst = AConst;

  constructor(private cms: CmsApiService,
              private commons: CommonsService,
              private objectEditService: ObjectEditService,
              private dateTools: DateToolsService) {
  }

  ngOnInit() {
    this.showLog = this.showLog || false;
    if (this.contextId && this.showLog) {
      this.loadLogItems();
    }
  }

  toggleLog() {
    if (!this.showLog) {
      if (this.contextId) {
        this.loadLogItems();
      }
    } else {
      this.showLog = false;
    }
  }

  private loadLogItems() {
    this.logItems = [];
    this.cms.getUpdateLog({contextId: this.contextId}).then(
      updateLogs => {
        let count = updateLogs.length;
        updateLogs.forEach(data => {
          this.objectEditService.createModelItem(data[AConst.OBJECT_TYPE], data).then(
            (item) => {
              this.logItems.push(this.getLogFields(item));
              if (!--count) {
                this.logItems = this.commons.orderArray(this.logItems, 'updated');
                this.showLog = true;
              }
            }
          );
        });
      });
  }

  private getLogFields(item) {
    const res = {
      fields: [],
      updated: item[AConst.UPDATED_AT][AConst.DD_DATE]
    };
    for (const metaFieldName in item[AConst.$$META]) {
      if (item[AConst.$$META].hasOwnProperty(metaFieldName)) {
        const metaField = item[AConst.$$META][metaFieldName];
        if (metaField.display && metaField.display.show === 'yes') {
          let value;
          const fieldName = metaField[AConst.NAME];
          const fieldType = metaField[AConst.FIELD_TYPE];
          const inputType = metaField[AConst.INPUT_TYPE];
          value = item[fieldName];
          if (fieldType === AConst.MAP_ID) {
            value = item[fieldName + '_value'];
          } else if (inputType === AConst.PRECISION_DATE) {
            value = this.dateTools.precisionDateToString(value);
          }
          res.fields.push({
            title: metaField[AConst.FIELD_TITLE],
            value: value
        });
        }
      }
    }
    return res;
  }

}
