import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InlineArrayItemService} from '../../core/inline-array-item.service';
import {SectionsContainer} from '../../core/sections-container';
import {AConst} from '../../core/a-const.enum';
import {FieldParameters} from '../../core/field-parameters';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {CommonsService} from '../../core/commons.service';

@Component({
  selector: 'app-edit-field-inline-array',
  templateUrl: './edit-field-inline-array.component.html',
  styleUrls: ['./edit-field-inline-array.component.scss']
})
export class EditFieldInlineArrayComponent implements OnInit {

  @Input() sectionsContainer: SectionsContainer;
  @Input() field;
  @Input() object;
  @Input() parentIndex;
  @Output() fieldOutsideTheScreen = new EventEmitter<object>();

  AConst = AConst;
  ACTIVE_DROPDOWN = '$$activeDropdown';
  public items;
  private unsorted;
  private fieldParameters: FieldParameters;

  constructor(private inlineArrayItemSvc: InlineArrayItemService,
              private commons: CommonsService) {
  }

  ngOnInit() {
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters.field = this.field;
    this.fieldParameters.object = this.object;
    this.fieldParameters.parentIndex = this.parentIndex;
    this.unsorted = this.inlineArrayItemSvc.getArrayItems(this.fieldParameters);
    this.items = this.commons.sortArray(this.unsorted, 'order_number');
  }

  checkFieldOutsideTheScreen() {
    this.fieldOutsideTheScreen.emit();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.items.forEach((item, index) => {
      item[AConst.ORDER_NUMBER] = index;
    });
    this.fieldParameters.index = event.currentIndex;
    this.inlineArrayItemSvc.changeInlineArrayItemsOrder(this.fieldParameters);
  }

  onDragStart() {
    this.inlineArrayItemSvc.setOpenArrayItemIndex(this.fieldParameters, undefined);
  }

  toggleDropdownMenu(item) {
    const activeDropDown = !item[this.ACTIVE_DROPDOWN];
    // Close all other drop downs
    this.object[this.field.name].forEach(menu => {
      menu[this.ACTIVE_DROPDOWN] = false;
    });
    item[this.ACTIVE_DROPDOWN] = activeDropDown;
    if (item[this.ACTIVE_DROPDOWN]) {
      window.addEventListener('mouseup', event => {
        this.checkOutsideClick(item, event);
      }, true);
    } else {
      window.removeEventListener('mouseup', event => {
        this.checkOutsideClick(item, event);
      }, true);
    }
  }

  checkOutsideClick(item, evt: Event) {
    const found = this.commons.findClassNameRecursively(evt.target,
      'dropdownIgnoreClicksSettingButton');
    if (!found) {
      setTimeout(() => {
        item[this.ACTIVE_DROPDOWN] = false;
        window.removeEventListener('mouseup', event => {
          this.checkOutsideClick(item, event);
        }, true);
      }, 100);
    }
  }
}
