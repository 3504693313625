import {FilterGroup} from './filter-group';

export class SearchContainer {
  allSelected = false;
  checkedFilters: object = {};
  combineHorizontal = true;
  curFocus: any;
  curFocusId: string;
  currentFocusName: string;
  currentPathView: any;
  defaultQueryPlaceholder: string;
  defaultQueryTooltip: string;
  displayAll = false;
  facetCount = {};
  facetRangeGroups: Array<any> = undefined;
  filtersChecked = 'notChecked';
  filterGroups: Array<FilterGroup>;
  focuses: Array<any>;
  keepSelected: boolean;
  maxPage: number;
  noFullSearch: boolean;
  object: any;
  objectCount: number;
  oldRangeFilter: string;
  oldTempGroupId: string;
  order: string = null;
  origSelected: Array<string> = [];
  path: string = undefined;
  query: string = undefined;
  queryField: string = undefined;
  queryPlaceholder: string;
  queryTooltip: string;
  reportContainer: any;
  restrictions: Array<any>;
  rows: object = {};
  searchEngine: string;
  searching: boolean;
  searchItemIndex = 0;
  searchMenuCollapse = false;
  searchPostFn: any;
  searchPage = 1;
  searchResultCallbacks: Array<any> = [];
  searchResultViewName: string;
  searchResultViews: { default: string, views: Array<any> };
  searchResult: any = undefined;
  searchView: any;
  selectCallback: any;
  selectButtonText: string;
  selected: Array<any> = [];
  selectedQueryMenu: any;
  singleSelect = false;
  state = {
    targetState: ''
  };
  targetId: string;
  targetObject: any;
  templateGroupId: string;
  toState: string;
  totalSelectedFilters: number;
  thumbWidth: number;
  toggleView: boolean;
  toggleSortMenu: boolean;
  toggleActionMenu: boolean;
  used: Array<string> = [];
  windowSize: any;
  widthResultItems: number;
}
