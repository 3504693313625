export class ContentListItem {
  fields: Array<any>;
  object: any;
}

export class ContentListSource {
  array: Array<any>;
  displayAll = false;
  items: Array<ContentListItem> = [];
  mainField: string;
  maxRows = 5;
  ownerObject;
  page = 1;

  constructor(array: Array<any>, mainField: string, ownerObject) {
    this.array = array;
    this.mainField = mainField;
    this.ownerObject = ownerObject;
  }
}
