import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import {CmsApiService} from '../../core/cms-api.service';
import {AConst} from '../../core/a-const.enum';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SectionsContainer} from '../../core/sections-container';
import {FieldValidationService} from '../../core/field-validation.service';
import {FieldValueService} from '../../core/field-value.service';

@Component({
  selector: 'app-edit-field-identifier',
  templateUrl: './edit-field-identifier.component.html',
  styleUrls: ['./edit-field-identifier.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldIdentifierComponent)
    }
  ]
})
export class EditFieldIdentifierComponent implements OnChanges, ControlValueAccessor {

  @Input() sectionsContainer: SectionsContainer;
  @Input() formControlName;
  @Input() placeholder;
  @Input() object;
  @Input() inFocus;
  @Output() fieldFocus = new EventEmitter<object>();
  @Output() fieldBlur = new EventEmitter<object>();

  onChange;

  constructor(private cms: CmsApiService,
              public fieldValidation: FieldValidationService,
              private fieldValueService: FieldValueService) {
  }

  ngOnChanges() {
  }

  generateAnIdentifier() {
    const params = {};
    params[AConst.OBJECT_TYPE] = this.object[AConst.OBJECT_TYPE];
    params[AConst.COLLECTION_ID] = this.object[AConst.COLLECTION_ID];
    if (this.object[AConst.CONTEXTS]) {
      this.object[AConst.CONTEXTS].forEach(context => {
        if (context[AConst.CONTEXT_ARTIFACT_ID]) {
          params[AConst.CONTEXT_ID] = context[AConst.CONTEXT_ARTIFACT_ID];
        }
      });
    }
    this.cms.nextIdentifier(params).then(
      data => {
        const formControl = this.sectionsContainer.formGroup.controls[this.formControlName];
        formControl.setValue(data[AConst.IDENTIFIER]);
        formControl.markAsDirty();
        this.fieldValueService.setFieldValue(this.sectionsContainer.rootObject, this.formControlName, data[AConst.IDENTIFIER]);
      },
      function (response) {
        console.error('Unable to generate identifier: ' + response.message);
      }
    );
  }

  onValueChange($event) {
    this.fieldValueService.setFieldValue(this.sectionsContainer.rootObject, this.formControlName, $event.target.value);
  }

  clearValue() {
    this.sectionsContainer.formGroup.controls[this.formControlName].setValue('');
    this.fieldValueService.setFieldValue(this.sectionsContainer.rootObject, this.formControlName, '');
  }

  pressedKey(evt) {
    evt = evt || window.event;

    if (evt.keyCode === 13) { // enter
      this.generateAnIdentifier();
      evt.preventDefault();
    }

  }

  onFieldIdentifierBlur() {
    this.fieldBlur.emit();
  }

  onFieldIdentifierFocus() {
    this.fieldFocus.emit();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {

  }

}
