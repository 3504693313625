import {Component, Input, OnChanges} from '@angular/core';
import {ActionMenuContainer} from '../../core/action-menu-container';
import {AConst} from '../../core/a-const.enum';
import {ObjectMenuService} from '../object-menu.service';
import {transition, trigger, useAnimation} from '@angular/animations';
import {slideDownMenu, slideUpMenu} from '../../shared/animations';

@Component({
  selector: 'app-object-menus-small',
  templateUrl: './object-menus-small.component.html',
  styleUrls: ['./object-menus-small.component.scss'],
  animations: [
    trigger('slideObjectMenusSmall', [
      transition(':leave', [
        useAnimation(slideUpMenu, { params: {time: '750ms ease-in'}}),
      ]),

      transition(':enter', [
        useAnimation(slideDownMenu, { params: {time: '750ms ease-out'}}),
      ])
    ])
  ]
})
export class ObjectMenusSmallComponent implements OnChanges {

  @Input() menuContainer: ActionMenuContainer;
  @Input() isEditing: boolean;
  @Input() mediaPage: boolean;

  AConst = AConst;

  constructor(private objectMenuService: ObjectMenuService) {
  }

  ngOnChanges(): void {
    if (this.menuContainer.art) {
      this.objectMenuService.initMenuContainer(this, this.menuContainer);
    }
  }

  openActionMenu(menu, menuGroup) {
    this.objectMenuService.openActionMenu(this, this.menuContainer, 'object-menus-small', menu, menuGroup);
  }

  toggleMenu(menuGroup) {
    this.objectMenuService.toggleMenuGroup(this, this.menuContainer, 'object-menus-small', menuGroup);
  }

}
