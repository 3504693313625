import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {AConst} from './a-const.enum';
import {CommonsService} from './commons.service';
import {DateToolsService} from './date-tools.service';
import {ModelSectionsService} from './model-sections.service';
import {ModelFactoryService} from './model-factory.service';
import {CmsQueueService} from './cms-queue.service';
import {AbstractControl, FormGroup} from '@angular/forms';
import {FieldMetaService} from './field-meta.service';
import {ModelsService} from './models.service';
import {hasOwnProperty} from 'tslint/lib/utils';
import {FieldStateService} from './field-state.service';
import {FieldParameters} from './field-parameters';

@Injectable({
  providedIn: 'root'
})
export class FieldValueService {

  private fieldWarnings = {};

  constructor(private translate: TranslateService,
              private commons: CommonsService,
              private dateTools: DateToolsService,
              private modelSectionsService: ModelSectionsService,
              private modelFactory: ModelFactoryService,
              private cmsQueue: CmsQueueService,
              private fieldMetaSvc: FieldMetaService,
              private modelsSvc: ModelsService,
              private fieldState: FieldStateService) {
  }


  private static getFieldPartNames(fieldStr: string) {
    return fieldStr.split('.');
  }

  private static getPythonStyleIndexes(value, bracketVal: string) {
    const res = {
      index1: undefined,
      index2: undefined
    };
    const split = bracketVal.split(':');
    if (!isNaN(split[0] as any)) {
      res.index1 = Number.parseInt(split[0], 10);
    } else {
      res.index1 = 0;
    }
    if (!isNaN(split[1] as any)) {
      res.index2 = Number.parseInt(split[1], 10);
    } else {
      res.index2 = value.length;
    }
    return res;
  }

  private static getIndexVal(value, indexes) {
    let res = null;
    const index1 = indexes.index1, index2 = indexes.index2;
    if (index1 === undefined && index2 === undefined) {
      console.warn('Indexes missing');
      return;
    }
    if (typeof value === 'string') {
      if (index2 === undefined) {
        res = value.substring(index1, index1 + 1);
      } else {
        res = value.substring(index1, index2);
      }
    } else if (Array.isArray(value)) {
      if (index2 === undefined) {
        res = value[index1];
      } else {
        res = value.slice(index1, index2);
      }
    } else {
      console.warn('Cannot get index value from \'' + value + '\'');
    }
    return res;
  }

  fieldIf(model, fieldName, comparator, compareValue) {
    const fieldValue = this.getFieldValue(model, fieldName);
    return this.commons.compareValues(fieldValue, comparator, compareValue);
  }

  /**
   * Get a field value from a model
   * @param model, a complex model object
   * @param fieldsIn, a string representation of the field value path,
   * e.g. 'designations[0].designation_type_id'
   * @param textValue, used for map_id based fields, if set to true will return the text value instead of the id value of the field
   * @param booleanAsYesNo, used with textValue=true, if set to true, return boolean text values as 'Yes' or 'No' instead of title or
   * nothing
   * @returns a field value within the model, or null if field path
   * does not exist
   * TODO: The getFieldValue and getFieldValueFromContainer methods
   * should be combined into one
   */
  getFieldValue(model, fieldsIn, textValue?: boolean, booleanAsYesNo?: boolean) {
    let negative = false, fieldNames, fields = fieldsIn;
    if (fields.indexOf('!') === 0) {
      fields = fields.substr(1);
      negative = true;
    }
    fieldNames = this.splitField(fields);
    return this.loopGetFieldValue(model, fieldNames, negative, textValue, booleanAsYesNo);
  }

  getMappedFieldValue(field, object, refId?): Promise<string> {
    return new Promise((resolve) => {
      let refProp, objectType, parentField, params, parentTargetField = 'parent_id', parentId;
      const res = object[field.name + '_value'];
      if (res) {
        resolve(res);
      } else {
        if (!refId) {
          refId = this.getFieldValue(object, field.name);
        }

        if (refId) {
          objectType = field.reference[AConst.OBJECT_TYPE];
          params = {
            filters: {
              object_type: objectType
            },
            labelProp: field.reference[AConst.LABEL_PROP]
          };

          parentField = field.reference[AConst.PARENT_FIELD];
          if (parentField) {
            parentId = object[parentField];
            if (parentId) {
              if (field.reference[AConst.PARENT_TARGET_FIELD]) {
                parentTargetField = field.reference[AConst.PARENT_TARGET_FIELD];
              }
              params.filters[parentTargetField] = parentId;
            }
          }

          refProp = this.getRefProp(field);
          if (res) {
            resolve(res);
          } else {
            params.filters[refProp] = refId;
            this.getMappedValueQueued(params).then(
              function (mappedValue) {
                resolve(mappedValue);
              });
          }
        } else {
          resolve('');
        }

      }
    });
  }

  getMappedValueQueued(params): Promise<string> {
    return new Promise(resolve => {
      const searchParams = {filters: params.filters};
      this.cmsQueue.runCmsFnWithQueue('searchJson', searchParams, false).then((data) => {
          let mapText = <any>'';
          const artifacts = data[AConst.ARTIFACTS];
          const labelProp = params.labelProp || AConst.ARTIFACT_NAME;
          if (params.getAll) {
            resolve(artifacts[0]);
            return;
          } else if (artifacts && artifacts.length === 1) {
            mapText = artifacts[0][labelProp];
            // mapText = artifacts[0][labelProp];
          } else if (artifacts && artifacts.length > 1) {
            mapText = [];
            artifacts.forEach((art) => {
              mapText.push(art[labelProp]);
            });
          } else {
            console.warn('No search result for ' + JSON.stringify(params.filters));
            mapText = params.filters.artifact_id;
          }
          resolve(mapText);
        },
        (response) => {
          const message = response.data ? response.data.message : '';
          console.error('Error getting mapped value: ' + message + ': ' + response.status);
          resolve(null);
        }
      );

    });
  }

  getFieldTextValue(model, fieldsIn, booleanAsYesNo?: boolean) {
    return this.getFieldValue(model, fieldsIn, true, booleanAsYesNo);
  }

  // Avoid using this method for inline array elements, use methods in InlineArrayItemService
  createAddItem(object, field, data?, form?: FormGroup, fieldKey?) {
    const model = this.modelFactory.getInlineModel(field);
    const item = this.modelFactory.createAddArrayItem(
      object, object[field.name], model, data);
    // $$grandParentModel used for models outside regular model regime, e.g. TemplateGroup
    item['$$grandParentModel'] = object;
    item['$$parentName'] = field.name;
    this.markAsDirty(form, fieldKey);
    return item;
  }

  // Avoid using this method for inline array elements, use methods in InlineArrayItemService
  deleteItem(rootObject, object, field, index, form?: FormGroup, fieldKey?) {
    this.modelFactory.deleteArrayItem(object[field.name], index, rootObject);
    this.markAsDirty(form, fieldKey);
  }

  setObjectEditFields(editFields: Array<string>, obj: any, firstSectionOnly: boolean, firstFieldNames?: string, sections?: Array<any>) {
    let firstSect;
    if (firstSectionOnly && sections) {
      firstSect = sections[0].name;
    }
    if (hasOwnProperty(obj, AConst.$$META)) {
      const meta = obj[AConst.$$META];
      for (const propName in meta) {
        if (meta.hasOwnProperty(propName)) {
          const metaValues = meta[propName];
          let fieldName;
          const inlineMod = metaValues[AConst.INLINE] ? metaValues[AConst.INLINE][AConst.MODEL] : null;
          if ((metaValues.edit && metaValues.edit.indexOf('edit') === 0) &&
            (!sections || !firstSectionOnly || metaValues.section === firstSect)) {
            fieldName = propName;
            if (firstFieldNames) {
              fieldName = firstFieldNames + '.' + fieldName;
            }

            if (!inlineMod) {
              editFields.push(fieldName);
            } else {
              if (hasOwnProperty(obj, propName)) {
                this.setObjectEditFields(editFields, obj[propName], firstSectionOnly, fieldName);
              }
            }
          }
        }
      }
    }
  }

  getFieldValFromFieldParameters(fieldParameters: FieldParameters, fieldStr) {
    const fieldPartNames = FieldValueService.getFieldPartNames(fieldStr);
    let modelVal;

    modelVal = this.loopGetFieldValFromModel(fieldParameters, fieldParameters.object, fieldPartNames);

    if ((modelVal === undefined || modelVal === null) && fieldParameters.grandParentObject) {
      modelVal = this.loopGetFieldValFromModel(fieldParameters, fieldParameters.grandParentObject, fieldPartNames);
    }

    if (modelVal === undefined || modelVal === null) {
      modelVal = this.loopGetFieldValFromModel(fieldParameters, fieldParameters.sectionsContainer.rootObject, fieldPartNames);
    }

    return modelVal;
  }

  getFieldValFromModel(fieldParams: FieldParameters, model, partFieldName) {
    let res;
    const fieldName = this.getRealFieldName(fieldParams, partFieldName);
    const indexVal = this.getModelIndexVal(model, fieldName);
    if (indexVal !== null) {
      res = indexVal;
    } else {
      res = model[fieldName];
    }
    return res;
  }

  getSubstituteString(str, startChar, endChar) {
    let res = '', subEnd, subStart;
    if (str) {
      subStart = str.indexOf(startChar);
      if (subStart !== -1) {
        subEnd = str.indexOf(endChar);
        if (subEnd !== -1) {
          res = str.substring(subStart + 1, subEnd);
        } else {
          console.warn('Missing \'' + endChar + '\' in ' + str);
        }
      }
    }
    return res;
  }

  /**
   * Set model field value in which fields is a string containing
   * dot separated field names, e.g. "user[0].info.name"
   * @param model
   * @param fields
   * @param fieldValue
   * @param textValue, if set to true will set
   */
  setFieldValue(model, fields, fieldValue, textValue?: boolean) {
    const fieldNames = this.splitField(fields);
    let mod = model;
    const lastIndex = fieldNames.length - 1;
    let changed = false;
    fieldNames.forEach((fieldName, index) => {
      if (fieldName === '$lastIndex') {
        fieldName = mod.length - 1;
      }
      if (index === lastIndex) {
        if (textValue) {
          fieldName += '_value';
        }
        fieldValue = fieldValue !== undefined ? fieldValue : null;
        changed = mod[fieldName] !== fieldValue;
        if (changed) {
          mod[fieldName] = fieldValue;
        }
      } else {
        if (mod[fieldName] === undefined) {
          console.warn('Missing \'' + fieldName + '\' in model');
        } else {
          mod = mod[fieldName];
        }
      }
    });
    return changed;
  }

  /**
   * Get the value of a field within an object in a format that can be used in a form control
   * @param object the object containing the field
   * @param fieldName the name of the field
   */
  getControlValueFromObjectField(object, fieldName: string): Promise<any> {
    return new Promise(resolve => {
      const res = object[fieldName];
      const field = object[AConst.$$META][fieldName];
      if (!field) {
        console.log('Field + "' + fieldName + '" not found in object');
        resolve(res);
      }
      if (field[AConst.INPUT_TYPE] === 'precision_date') {
        resolve(this.dateTools.precisionDateToString(res));
      } else if (field[AConst.INPUT_TYPE] === AConst.COMPARE_VALUE) {
        this.getValueCompanion(object, fieldName, true).then(value => {
          resolve(value || res);
        });
      } else if (field[AConst.FIELD_TYPE] === AConst.BOOLEAN) {
        resolve(!!res);
      } else if (field[AConst.FIELD_TYPE] === AConst.MAP_ID) {
        this.getValueCompanion(object, fieldName).then(value => {
          resolve(value);
        });
      } else if (field[AConst.FIELD_TYPE] === AConst.ARRAY) {
        const arrVal = res.length ? res.length : '';
        const inline = field[AConst.INLINE];
        if (inline) {
          const inlineList = inline[AConst.INLINE_LIST];
          if (inlineList && inlineList[AConst.MAX_LENGTH] === 1) {
            if (res && res.length > 0) {
              this.getControlValueFromObjectField(res[0], inline[AConst.PROP]).then(value => {
                resolve(value);
              });
            } else {
              resolve(arrVal);
            }
          } else {
            resolve(arrVal);
          }
        } else {
          resolve(arrVal);
        }
      } else {
        resolve(res);
      }
    });
  }

  getValueCompanion(object, fieldName: string, dontSet?): Promise<string> {
    return new Promise(resolve => {
      const res = object[fieldName + '_value'];
      if (object[fieldName] && (res === undefined || res === null) && !dontSet) {
        this.warnFieldCompanionMissing(object, fieldName);
        this.setValueCompanion(object, fieldName).then(value => {
          resolve(value);
        });
      } else {
        resolve(res);
      }
    });
  }

  getValueCompanionImmediate(object, fieldName: string, dontSet?): string {
    const res = object[fieldName + '_value'];
    if (object[fieldName] && (res === undefined || res === null) && !dontSet) {
      this.warnFieldCompanionMissing(object, fieldName);
      this.setValueCompanion(object, fieldName).then();
    }
    return res;
  }

  setFieldValueAndControlValue(fieldParameters: FieldParameters, object, fieldName: string, fieldValue): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const changed = object[fieldName] !== fieldValue;
      if (changed) {
        object[fieldName] = fieldValue;
        const meta = object[AConst.$$META][fieldName];
        if (meta) {
          if (meta[AConst.FIELD_TYPE] === AConst.MAP_ID) {
            this.getMappedValue(fieldValue).then(textValue => {
              this.setValueCompanion(object, fieldName, textValue).then(() => {
                this.setControlValue(fieldParameters, object, fieldName).then(() => {
                  resolve(changed);
                });
              });
            });
          } else {
            this.setControlValue(fieldParameters, object, fieldName).then(() => {
              resolve(changed);
            });
          }
        } else {
          resolve(changed);
        }
      } else {
        resolve(changed);
      }
    });
  }

  setControlValue(fieldParameters: FieldParameters, object, fieldName: string): Promise<void> {
    return new Promise<void>(resolve => {
      const fieldKey = this.fieldState.getFieldKey(fieldParameters.field, fieldParameters.index, fieldParameters.parentIndex, fieldName);
      const control: AbstractControl = fieldParameters.sectionsContainer.formGroup.controls[fieldKey];
      if (control) {
        this.getControlValueFromObjectField(object, fieldName).then(value => {
          control.setValue(value);
          control.markAsDirty();
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  hasValue(field, object) {
    let value, res = false;
    if (!field) {
      console.log('Field information empty');
      return res;
    }
    if (field[AConst.FIELD_TYPE] === 'action_button') {
      res = true;
    } else if (object) {
      value = object[field.name];
      if (value !== null && value !== undefined) {
        res = true;
        if (typeof value === 'string' || Array.isArray(value)) {
          res = value.length > 0;
        } else if (typeof value === 'object') {
          res = this.hasInlineValue(field, value);
        }
      }
    } else {
      // $log.warn("No parent model for " +
      // fi.path + ": " + fi.name);
    }
    return res;
  }

  getTextValue(object: object, field: any, booleanAsYesNo?: boolean) {
    let fieldName, fieldMeta;
    if (typeof field === 'string') {
      fieldName = field;
      fieldMeta = this.getFieldMeta(object, fieldName);
      if (!fieldMeta) {
        console.log('Object did not contain field meta for \'' + fieldName + '\'');
      }
    } else {
      fieldName = field.name;
      fieldMeta = field;
    }
    let value = object[fieldName];
    if (fieldMeta && value !== null) {
      if (fieldMeta[AConst.INPUT_TYPE] === AConst.PRECISION_DATE) {
        value = this.dateTools.precisionDateToString(object[fieldName]);
      } else if (fieldMeta[AConst.FIELD_TYPE] === AConst.BOOLEAN) {
        if (booleanAsYesNo) {
          value = value ? this.translate.instant('TRANS__FIELD__BOOLEAN__YES') : this.translate.instant('TRANS__FIELD__BOOLEAN__NO');
        } else {
          if (value) {
            value = this.translate.instant(fieldMeta[AConst.FIELD_TITLE]);
          }
        }
      } else if (fieldMeta[AConst.FIELD_TYPE] === AConst.NUMBER || fieldMeta[AConst.FIELD_TYPE] === AConst.DECIMAL) {
        if (value !== undefined) {
          value = value.toLocaleString();
        } else {
          console.warn('Value undefined for ' + field.name);
        }
      } else if (fieldMeta[AConst.INPUT_TYPE] === AConst.MAP_ID || fieldMeta[AConst.FIELD_TYPE] === AConst.MAP_ID) {
        value = this.getValueCompanionImmediate(object, fieldName);
      } else if (fieldMeta[AConst.FIELD_TYPE] === AConst.OPTION_STRING) {
        const label = this.getOptionLabel(value, fieldMeta);
        if (label) {
          value = this.translate.instant(label);
        }
      }
    }
    return value || '';
  }

  setValueCompanion(object, fieldName, value?: string): Promise<string> {
    return new Promise(resolve => {
      if (!value) {
        const fieldValue = object[fieldName];
        if (fieldValue) {
          this.getMappedValue(fieldValue).then(res => {
            object[fieldName + '_value'] = res;
            resolve(res);
          });
        } else {
          object[fieldName + '_value'] = '';
          resolve('');
        }
      } else {
        object[fieldName + '_value'] = value;
        resolve(value);
      }
    });
  }

  getMappedValue(value): Promise<any> {
    return new Promise<any>(resolve => {
      const params = {
        filters: {}
      };
      params.filters[AConst.ARTIFACT_ID] = value;
      this.getMappedValueQueued(params).then(res => {
        resolve(res);
      });
    });
  }

  private warnFieldCompanionMissing(object, fieldName) {
    if (!this.fieldWarnings[fieldName]) {
      console.warn('Field ' + fieldName + ', object ' + object[AConst.OBJECT_TYPE] + ' is missing _value companion');
      this.fieldWarnings[fieldName] = true;
    }
  }

  private hasInlineValue(field, value) {
    let res = false, inlineVal, modName;
    const inline = field[AConst.INLINE];
    let subModel, subFi;
    if (inline && inline.prop) {
      inlineVal = value[inline.prop];
    }
    modName = this.modelFactory.getInlineModel(field);
    if (modName === 'PrecisionDate') {
      res = value.dd_date !== null;
    } else {
      if (inlineVal !== undefined) {
        res = inlineVal !== null;
        if (res && typeof inlineVal === 'object') {
          subModel = this.modelsSvc.getModel(modName);
          if (subModel) {
            subFi = subModel[AConst.$$META][inline.prop];
            res = this.hasInlineValue(subFi, inlineVal);
          }
        }
      }
    }
    return res;
  }

  private loopGetFieldValue(model, fieldNames, negative, textValue?: boolean, booleanAsYesNo?: boolean) {
    let res = model;
    this.commons.each(fieldNames, (index, fieldName) => {
      if (fieldName === '$lastIndex') {
        fieldName = res.length - 1;
      }
      if (fieldName === '$all') {
        res = this.getAllArrayValues(res, fieldNames, index);
        return false;
      } else {
        const isLastField = index === fieldNames.length - 1;
        if (Array.isArray(res) && isNaN(Number.parseInt(fieldName, 10))) {
          if (res.length > 0) {
            const arrayRes = [];
            res.forEach(item => {
              arrayRes.push(this.getFieldValueFromSubObject(item, fieldName, isLastField, textValue));
            });
            res = arrayRes;
          } else {
            res = null;
          }
        }

        if (res !== undefined && res !== null) {
          if (textValue && index === fieldNames.length - 1) {
            res = this.getTextValue(res, fieldName, booleanAsYesNo);
          } else {
            res = this.getFieldValueFromSubObject(res, fieldName, isLastField, textValue);
          }
        }
      }
      return true;
    });
    if (typeof res === 'boolean' && negative) {
      res = !res;
    }
    res = textValue ? res || '' : res;
    return res;
  }

  private getFieldValueFromSubObject(subObject, fieldName, isLastField: boolean, textValue?: boolean) {
    if (textValue && isLastField) {
      subObject = this.getTextValue(subObject, fieldName);
    } else {
      subObject = subObject[fieldName];
    }
    return subObject;
  }

  private getOptionLabel(value, fieldMeta) {
    let res = '';
    const optionInfo = fieldMeta[AConst.OPTION_INFO];
    if (optionInfo) {
      optionInfo.options.forEach(option => {
        if (option.value.toString() === value.toString()) {
          res = option.label;
        }
      });
    }
    if (!res) {
      console.warn('Option not found for value "' + value + '"');
    }
    return res;
  }

  private getFieldMeta(object, fieldName) {
    let res = {};
    const meta = this.fieldMetaSvc.checkSetMetaData(object);
    if (meta) {
      res = meta[fieldName];
      if (!res) {
        console.warn('Found no field meta data for: ' + fieldName);
      }
    } else {
      console.warn('Object is missing meta data: ' + JSON.stringify(object));
    }
    return res;
  }

  private getAllArrayValues(array, fieldNames, index) {
    const items = [];
    const subNames = fieldNames.slice(index + 1);
    if (array && array.length > 0) {
      array.forEach(arrItem => {
        const item = this.loopGetFieldValue(arrItem, subNames, false);
        items.push(item);
      });
    }
    return items;
  }

  private splitField(field: string) {
    const dotSplit = field.split('.');
    const res = [];
    dotSplit.forEach(dotField => {
      let bracketEnd;
      const bracketStart = dotField.indexOf('[');
      if (bracketStart !== -1) {
        bracketEnd = dotField.indexOf(']');
        if (bracketEnd === -1) {
          throw new Error('Field missing bracket end: ' + field);
        }
        res.push(dotField.substring(0, bracketStart));
        res.push(dotField.substring(bracketStart + 1,
          bracketEnd));
      } else {
        res.push(dotField);
      }
    });
    return res;
  }

  private loopGetFieldValFromModel(fieldParameters: FieldParameters, modelVal, fieldPartNames) {
    let res = modelVal;
    fieldPartNames.forEach(fieldName => {
      if (res) {
        res = this.getFieldValFromModel(fieldParameters, res, fieldName);
      }
    });
    return res;
  }

  private getModelIndexVal(model, fieldNameIndex) {
    let res = null;
    let bracketVal = this.getSubstituteString(fieldNameIndex, '[', ']');
    let subStr, fieldName, value, indexes = {
      index1: undefined,
    };
    if (bracketVal) {
      fieldName = fieldNameIndex.replace('[' + bracketVal + ']', '');
      value = model[fieldName];
      if (value === undefined) {
        return null;
      }
      subStr = this.getSubstituteString(bracketVal, '{', '}');
      if (subStr) {
        bracketVal = subStr;
      }
      if (!isNaN(Number.parseInt(bracketVal, 10))) {
        indexes.index1 = Number.parseInt(bracketVal, 10);
      } else {
        if (bracketVal === '$last') {
          indexes.index1 = value.length - 1;
        } else if (bracketVal === '$first') {
          indexes.index1 = 0;
        } else if (bracketVal.indexOf(':') !== -1) {
          indexes = FieldValueService.getPythonStyleIndexes(value, bracketVal);
        } else {
          console.warn('Don\'t know how to handle array index \'' + subStr + '\'');
        }
      }
      res = FieldValueService.getIndexVal(value, indexes);
    }
    return res;
  }

  private getRealFieldName(fieldParameters: FieldParameters, fieldName) {
    let res = fieldName;
    let subVal;
    const subStr = this.getSubstituteString(fieldName, '{', '}');
    if (subStr) {
      subVal = this.getFieldValFromModel(fieldParameters, fieldParameters.object, subStr);
      res = fieldName.replace('{' + subStr + '}', subVal);
    }
    return res;
  }

  private getRefProp(field) {
    return field.reference[AConst.REF_PROP] || 'artifact_id';
  }

  private markAsDirty(form, fieldKey) {
    if (form && fieldKey) {
      form.controls[fieldKey].markAsDirty();
    }
  }

}
