import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SearchContainer} from '../search-container';
import {UiToolsService} from '../../core/ui-tools.service';
import {SearchSelectorService} from '../../core/search-selector.service';
import {SearchHandlerService} from '../search-handler.service';
import {SelectorContainer} from '../../core/selector-container';

@Component({
  selector: 'app-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss']
})
export class SearchHeaderComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;
  @Input() searchPlaceholder: string;
  @Input() sideMenuSmallScreen;
  @Output() onToggleSideMenuSmallScreen = new EventEmitter<object>();

  newLayout = true;
  setSearchQueryFocus = false;
  mediumScreen: boolean;
  templateSelectorContainer: SelectorContainer;
  private stopSizeWatch;

  constructor(private uiTools: UiToolsService,
              private selectorHandler: SearchSelectorService,
              private searchHandler: SearchHandlerService) {
  }

  ngOnInit() {
    this.mediumScreen = this.uiTools.setMediumScreenSizeOnLoad(this.mediumScreen);
    this.stopSizeWatch = this.uiTools.addWindowSizeListener(newVal => {
        if (newVal) {
          this.mediumScreen = newVal.width < 1025;
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
  }
  toggleSideFilterMenuSmallScreen() {
    this.onToggleSideMenuSmallScreen.emit();
  }
  selectorOverlay() {
    if (this.templateSelectorContainer.selectorEnabled) {
      this.selectorHandler.disableSelector(this.templateSelectorContainer);
    }
  }

  onTemplateSelectorCreated(templateSelectorContainer) {
    this.templateSelectorContainer = templateSelectorContainer;
  }

  onChangeTemplateGroup(newGroupId) {
    this.searchContainer.templateGroupId = newGroupId;
    // First get new overview fields for list view
    this.searchHandler.getSearchFields(this.searchContainer).then(() => {
      this.searchHandler.runSearch(this.searchContainer, true).then();
    });
  }

}
