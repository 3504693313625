import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {SelectorContainer} from '../../core/selector-container';
import {FieldValueService} from '../../core/field-value.service';
import {FieldMetaService} from '../../core/field-meta.service';
import {ActionMenuService} from '../../core/action-menu.service';
import {OptionsService} from '../../core/options.service';
import {SearchSelectorService} from '../../core/search-selector.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SectionsContainer} from '../../core/sections-container';
import {FieldParameters} from '../../core/field-parameters';

@Component({
  selector: 'app-edit-field-search-selector-multiple',
  templateUrl: './edit-field-search-selector-multiple.component.html',
  styleUrls: ['./edit-field-search-selector-multiple.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldSearchSelectorMultipleComponent)
    }
  ]
})
export class EditFieldSearchSelectorMultipleComponent implements OnInit, ControlValueAccessor {

  constructor(private fieldValueSvc: FieldValueService,
              private fieldMetaSvc: FieldMetaService,
              private actionMenuService: ActionMenuService,
              private optionsService: OptionsService,
              private searchSelector: SearchSelectorService) {
  }

  @Input() sectionsContainer: SectionsContainer;
  @Input() grandParentObject;
  @Input() object;
  @Input() field;
  @Input() formControlName;
  @Input() parentIndex;

  AConst = AConst;

  ngOnInit() {
  }

  private getSelectedValues() {
    const res = [];
    const values = this.fieldValueSvc.getFieldValue(this.sectionsContainer.rootObject, this.formControlName);
    if (values) {
      values.forEach((value) => {
        if (!value._destroy) {
          res.push(value[this.field[AConst.INLINE].prop]);

        }
      });
    }
    return res;
  }

  private checkAddNewItems(objects) {
    objects.forEach((obj) => {
      let targetData, existed = false;
      const artifactId = obj[AConst.ARTIFACT_ID];
      const values = this.fieldValueSvc.getFieldValue(this.sectionsContainer.rootObject, this.formControlName);
      values.forEach((existing) => {
        if (artifactId === existing[this.field[AConst.INLINE].prop]) {
          existed = true;
          delete existing._destroy;
        }
      });
      if (!existed) {
        targetData = {
          context_object_type: obj[AConst.OBJECT_TYPE],
          context_id: this.sectionsContainer.rootObject[AConst.ARTIFACT_ID] || this.sectionsContainer.rootObject[AConst.CONTEXT_ID]
        };
        targetData[this.field[AConst.INLINE].prop] = artifactId;
        this.fieldValueSvc.createAddItem(this.object, this.field, targetData, this.sectionsContainer.formGroup, this.formControlName);
      }
    });
  }

  private checkDeleted(objects) {
    const deletedList = [];
    this.getSelectedValues().forEach((existing, i) => {
      let existed = false;
      objects.forEach((obj) => {
        if (obj[AConst.ARTIFACT_ID] === existing) {
          existed = true;
        }
      });
      if (!existed) {
        deletedList.push(i);
      }
    });
    while (deletedList.length > 0) {
      this.deleteItemByIndex(deletedList.pop());
    }
  }

  deleteItemByIndex(index) {
    this.fieldValueSvc.deleteItem(
      this.sectionsContainer.rootObject, this.object, this.field, index, this.sectionsContainer.formGroup, this.formControlName);
  }

  // addSearchItem() {
  //   this.fieldValueSvc.createAddItem(this.object, this.field);
  // }

  clickEnableSelector() {
    let selector, container: SelectorContainer, params, selected;
    const meta = this.fieldMetaSvc.getFieldMetaData(this.object, this.field);
    selector = meta.reference[AConst.SELECTOR];
    container = <SelectorContainer>this.actionMenuService.actionMenuContainer;
    selected = this.getSelectedValues();
    params = {selected: selected};
    // TODO: Add template group id to enableSelector call
    this.searchSelector.enableSelector(selector, container, params, {
      searchContainerCreated: function (/*sc*/) {
        // console.log('Created: ' + sc);
      },
      selectorCallback: (selectedObj) => {
        this.searchSelector.disableSelector(container);
        this.checkDeleted(selectedObj);
        this.checkAddNewItems(selectedObj);
      }
    });
  }

  openCreateNew() {
    const fieldParameters = new FieldParameters();
    fieldParameters.field = { meta_type_id: 'ct_201-4' };
    this.optionsService.createOption(fieldParameters, '').then(art => {
      this.checkAddNewItems([art]);
    }, reason => {
      if (reason) {
        console.warn('Error storing new artifact: ' + reason.toLocaleString());
      }
    });
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    console.log('Selector Multiple write: ' + JSON.stringify(obj));
  }

}
