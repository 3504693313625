import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainMenuComponent} from './main-menu/main-menu.component';
import {TranslateService} from '@ngx-translate/core';
import {UIRouterModule} from '@uirouter/angular';
import {MatDialogModule} from '@angular/material';
import {AConst} from '../core/a-const.enum';
import {SharedModule} from '../shared/shared.module';
import {ObjectViewModule} from '../object-view/object-view.module';
import {CmsQueueService} from '../core/cms-queue.service';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule,
    MatDialogModule,
    SharedModule,
    ObjectViewModule
  ],
  exports: [
    MainMenuComponent
  ],
  declarations: [MainMenuComponent]
})
export class MainMenuModule {
  constructor(private translate: TranslateService,
              private cmsQueueService: CmsQueueService) {
    cmsQueueService.runCmsFnWithQueue('getSettings').then(data => {
      translate.use(data[AConst.CLIENT_LANGUAGE]);
    });
  }
}
