import {Component, Input, OnChanges} from '@angular/core';
import {SearchContainer} from '../../search-container';
import {SearchHandlerService} from '../../search-handler.service';
import {AConst} from '../../../core/a-const.enum';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-search-result-view-list',
  templateUrl: './search-result-view-list.component.html',
  styleUrls: ['./search-result-view-list.component.scss']
})
export class SearchResultViewListComponent implements OnChanges {

  @Input() searchContainer: SearchContainer;

  AConst = AConst;
  objectFields;

  private subTitleCounter = 0;
  private initializingObjectFields = false;

  constructor(
    private translateService: TranslateService,
    private searchHandler: SearchHandlerService) {
  }

  ngOnChanges(): void {
    this.initObjectFields();
  }

  initObjectFields() {
    const sc = this.searchContainer;
    const searchObjects = sc ? sc.searchResult[AConst.ARTIFACTS] : null;
    let modelOverviewFields;
    if (sc && searchObjects && !this.initializingObjectFields) {
      modelOverviewFields = sc.currentPathView[AConst.OVERVIEW_FIELDS];
      this.checkCanSetOrder(modelOverviewFields);
      this.initializingObjectFields = true;
      this.objectFields = {};
      searchObjects.forEach(searchObject => {
        this.objectFields[searchObject[AConst.ARTIFACT_ID]] = searchObject.$$overview;
      });
      this.initializingObjectFields = false;
    }
  }

  setOrder(fieldInfo) {
    if (fieldInfo.name === 'artifact_name' || fieldInfo.isSortable) {
      this.searchHandler.setOrder(this.searchContainer, fieldInfo.name, fieldInfo);
    }
  }

  select(obj) {
    this.searchHandler.selectSearchResultItem(obj, this.searchContainer);
  }

  getTitle(field) {
    let res;
    const fieldTitle = field[AConst.FIELD_TITLE];
    if (!Array.isArray(fieldTitle)) {
      const transRes = this.translateService.get(fieldTitle);
      res = transRes ? transRes['value'] : '';
    } else {
      const transRes = this.translateService.get(fieldTitle[this.subTitleCounter++]);
      res = transRes ? transRes['value'] : '';
      if (this.subTitleCounter === fieldTitle.length) {
        this.subTitleCounter = 0;
      }
    }
    return res;
  }

  getObjectFields(art) {
    const objectId = art[AConst.ARTIFACT_ID];
    const res = this.objectFields[objectId];
    if (!res) {
      // In some cases, the search result is changed after objectFields is set, causing some issues. Need to set objectFields again.
      console.log('Need to reinitialize object fields');
      this.initObjectFields();
    }
    return res;
  }

  private checkCanSetOrder(modelOverviewFields) {
    modelOverviewFields.forEach(field => {
      field.isSortable = this.searchHandler.checkFieldIsSortable(field);
    });
  }


}
