export class PerformanceTimer {
  timerName: string;
  curTime: number;
  constructor(timerName: string) {
    this.timerName = timerName;
  }

  start() {
    let self = this;
    self.curTime = new Date().getTime();
  }

  measure(msg: string) {
    let self = this;
    let time = new Date().getTime();
    console.log(self.timerName + ": " + msg + " took " + (time - self.curTime));
  }

}
