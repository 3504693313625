import { Injectable } from '@angular/core';
import {ReporterPdfFullArtifactParserService} from './reporter-pdf-full-artifact-parser.service';
import {ReporterPdfGridReportService} from './reporter-pdf-grid-report.service';
import {ReporterPdfImageToTheLeftService} from './reporter-pdf-image-to-the-left.service';
import {ReporterPdfSingleArtifactParserService} from './reporter-pdf-single-artifact-parser.service';
import {ReporterUtilityService} from './reporter-utility.service';
import {ReporterArtifactLoaderService} from './reporter-artifact-loader.service';
import {ExtDate} from './ext-date';

@Injectable({
  providedIn: 'root'
})
/**
 * Service hosting methods used when creating a PDF report.
 * @type {{init, getPrimusVersion, getPrimusLogo, getCreatedHR, createColouredSectionHeader}}
 */
export class ReporterPdfService {

  private handler = null;
  private searchContainer = null;
  private selectedArtifactIDs = null;
  private translations = null;
  private artifactIDs = null;
  private artifacts = null;
  private pdfReportType = null;
  private primusVersion = null;
  private created = null;
  private frontPageTexts = null;
  private overviewFieldsDataSet = null;
  private filterSettings = null;
  private _artifactLoaderService = null;


  constructor(private _fullArtifactParser: ReporterPdfFullArtifactParserService,
              private _gridReport: ReporterPdfGridReportService,
              private _imageToTheLeftReport: ReporterPdfImageToTheLeftService,
              private singleArtifactParser: ReporterPdfSingleArtifactParserService,
              private reporterUtility: ReporterUtilityService
              ) {
    this._artifactLoaderService = new ReporterArtifactLoaderService(this.reporterUtility);
  }

  /**
   * Creates the JSON-object/docDefinition used when rendering the report using pdfMake.
   * @returns object
   * @private
   */
  private createDocJSON() {
    return new Promise((resolve) => {
      const promises = [];

      if (this.pdfReportType === '3x2grid' || this.pdfReportType === '2x2grid' || this.pdfReportType === 'imageToTheLeftTable') {

        // Parse artifacts - make them ready to fit into the selected grid-layout.
        if (this.pdfReportType === '2x2grid') {
          this.artifacts.map((artifact) => {
            promises.push(
              this.singleArtifactParser.parse(artifact, [160, 160],
                'center', this.overviewFieldsDataSet[artifact.artifact_id])
            );
          });
        } else if (this.pdfReportType === '3x2grid') {
          this.artifacts.map((artifact) => {
            promises.push(
              this.singleArtifactParser.parse(artifact, [140, 140],
                'center', this.overviewFieldsDataSet[artifact.artifact_id])
            );
          });
        } else if (this.pdfReportType === 'imageToTheLeftTable') {
          this.artifacts.map((artifact) => {
            promises.push(
              this.singleArtifactParser.parse(artifact, [100, 100],
              'center', this.overviewFieldsDataSet[artifact.artifact_id])
            );
          });
        }
      } else if (this.pdfReportType === 'fullobject') {
        // Parse artifact - make them ready to fit into the selected "full report".
        promises.push(
          this._fullArtifactParser.parseAll(this.artifacts, this.overviewFieldsDataSet, this.frontPageTexts, this.created)
        );
      }

      Promise.all(promises).then((res) => {
        switch (this.pdfReportType) {
          case '3x2grid':
            this._gridReport.create3by2(res, this.frontPageTexts, this.created, this.filterSettings).then(function (dd) {
              resolve(dd);
            });
            break;
          case '2x2grid':
            this._gridReport.create2by2(res, this.frontPageTexts, this.created, this.filterSettings).then(function (dd) {
              resolve(dd);
            });
            break;
          case 'imageToTheLeftTable':
            this._imageToTheLeftReport.createImageToTheLeftTable(res, this.frontPageTexts, this.created, this.filterSettings)
              .then( dd => {
                resolve(dd);
              });
            break;
          case 'fullobject':
            this._fullArtifactParser.clearParsed();
            resolve(res);
            break;
        }
      }).catch(function (e) {
        console.error(e);
      });
    });
  }

  /**
   * Loops through all pages in the result set, fetching all artifact IDs.
   * @param page page index
   * @private
   */
  private pageLooper(page) {
    return new Promise((resolve) => {
      this.searchContainer.page = page;
      this.handler['runSearch'](this.searchContainer).then((res) => {
        res.artifacts.map((artifact) => {
          this.artifactIDs.push(artifact.artifact_id);
        });
        resolve();
      }).catch(function (e) {
        console.error(e);
      });
    });
  }

  /**
   * Downloads artifact data for the specified report type.
   * @returns {*}
   * @private
   */
  private downloadData() {
    return new Promise((resolve) => {
      if (typeof(this.selectedArtifactIDs) !== 'undefined' && this.selectedArtifactIDs !== null && this.selectedArtifactIDs.length > 0) {
        this.artifactIDs = this.selectedArtifactIDs;
        this._artifactLoaderService.loadArtifacts(this.pdfReportType, this.artifactIDs, this.overviewFieldsDataSet)
          .then( (artifacts) => {
            this.artifacts = artifacts;
            this.createDocJSON().then( (docDefinition) => {
              resolve(docDefinition);
            });
          });

      } else {
        // Loop through all pages in order to fetch all artifact IDs before loading the artifact data.
        const maxPages = this.searchContainer.maxPage;
        const promises = [];

        for (let i = 1; i <= maxPages; i++) {
          promises.push(this.pageLooper(i));
        }

        Promise.all(promises).then(() => {
          this._artifactLoaderService.loadArtifacts(this.pdfReportType, this.artifactIDs, this.overviewFieldsDataSet)
            .then( (artifacts) => {
              this.artifacts = artifacts;
              this.createDocJSON().then( _docDefinition => {
                resolve(_docDefinition);
              });
          });
        }).catch(function (e) {
          console.error(e);
        });
      }
    });
  }

  /**
   * Initializes rendering of a PDF report.
   * @param selectedArtifactIDs
   * @param overviewFieldsDataSet
   * @param searchContainer
   * @param handler
   * @param translations
   * @param pdfReportType
   * @param frontPageTexts
   * @param filterSettings
   */
  init(selectedArtifactIDs, overviewFieldsDataSet, searchContainer, handler, translations, pdfReportType, frontPageTexts, filterSettings) {
    this.handler = handler;
    this.searchContainer = searchContainer;
    this.selectedArtifactIDs = selectedArtifactIDs;
    this.translations = translations;
    this.artifactIDs = [];
    this.pdfReportType = pdfReportType;
    this.frontPageTexts = frontPageTexts;
    this.overviewFieldsDataSet = overviewFieldsDataSet;
    this.created = ExtDate.getHrDateTime();
    this.filterSettings = filterSettings;

    return new Promise((resolve) => {
        this.primusVersion = this.reporterUtility.getPrimusVersion();
        this.downloadData().then((res) => {
          resolve(Array.isArray(res) ? res[0] : res);
        });
    });
  }

}
