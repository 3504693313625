import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchContainer} from '../search-container';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-search-view-content-list',
  templateUrl: './search-view-content-list.component.html',
  styleUrls: ['./search-view-content-list.component.scss']
})
export class SearchViewContentListComponent implements OnInit {

  @Input() searchContainer: SearchContainer;
  @Output() artifactUpdated = new EventEmitter<boolean>();

  AConst = AConst;

  constructor() {
  }

  ngOnInit() {
  }

  setArtifactUpdated() {
    this.artifactUpdated.emit(true);
  }
}
