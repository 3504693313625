import { Injectable } from '@angular/core';
import {AConst} from './a-const.enum';
import {FieldValueService} from './field-value.service';
import {FieldParameters} from './field-parameters';

@Injectable({
  providedIn: 'root'
})
export class ReferenceFilterService {

  constructor(private fieldValueSvc: FieldValueService) { }

  /* Used for setting search filter or create new item */
  generateRefFilterData(refFilter, isTargetArray, fieldParameters: FieldParameters) {
    const target = {};
    let refFilters = [];
    if (refFilter) {
      refFilters = refFilter;
      if (!Array.isArray(refFilter)) {
        refFilters = [refFilter];
      }
    }
    refFilters.forEach(rFilter => {
      const name = rFilter[AConst.FILTER_FIELD];
      const values = rFilter.values;
      if (Array.isArray(values)) {
        values.forEach((value: any, index) => {
          let targetVal = value;
          if (typeof value === 'object') {
            targetVal = this.getSourceFieldVal(value[AConst.SOURCE_FIELD], fieldParameters);
          } else if (typeof value === 'string') {
            if (value.indexOf('{') !== -1) {
              const substStr = this.fieldValueSvc.getSubstituteString(value, '{', '}');
              const substVal = this.fieldValueSvc.getFieldValFromFieldParameters(fieldParameters, substStr);
              targetVal = value.replace('{' + substStr + '}', substVal);
            }
          }
          if (targetVal !== null && targetVal !== undefined) {
            if (isTargetArray) {
              target[name] = target[name] || [];
              if (!Array.isArray(targetVal)) {
                target[name].push(targetVal);
              } else {
                target[name] = target[name].concat(targetVal);
              }
            } else if (index === 0) {
              target[name] = targetVal;
            }
          }
        });
      }
    });
    return target;
  }


  private getSourceFieldVal(sourceField, fieldParameters: FieldParameters) {
    let sourceVal = null;
    if (sourceField) {
      sourceVal = this.fieldValueSvc.getFieldValFromFieldParameters(fieldParameters, sourceField);
    }
    return sourceVal;
  }

}
