import {ExtString} from './ext-string';

export class ExtDate extends Date {
  // ========================================================================
  //  Expanding Date object
  // ========================================================================

  static getHrDateTime() {
    let d = new Date();
    let date: ExtString = new ExtString(d.getDate().toString());
    let month: ExtString = new ExtString((d.getMonth()+1).toString()); // JavaScript month index starts at 0....
    let year = d.getFullYear();
    let hours: ExtString = new ExtString(d.getHours().toString());
    let minutes: ExtString = new ExtString(d.getMinutes().toString());
    let seconds: ExtString = new ExtString(d.getSeconds().toString());

    return date.lpad('0', 2) + '.' +
      month.lpad('0', 2) + '.' + year + ' ' +
      hours.lpad('0', 2) + ':' +
      minutes.lpad('0', 2) + ':' +
      seconds.lpad('0', 2);
  }

}
