import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material';

import {FaqPageComponent} from './faq-page/faq-page.component';
import {TopHeaderComponent} from './top-header/top-header.component';
import {TranslateService} from '@ngx-translate/core';
import {AConst} from '../core/a-const.enum';
import {SharedModule} from '../shared/shared.module';
import {ImageFullScreenComponent} from './image-full-screen/image-full-screen.component';
import {MediaCarouselModule} from '../media-carousel/media-carousel.module';
import {CmsQueueService} from '../core/cms-queue.service';
import {AdminSettingsComponent} from './admin-settings/admin-settings.component';
import {AdminSettingsEditFieldsComponent} from './admin-settings/admin-settings-edit-fields/admin-settings-edit-fields.component';
import {AdminSettingsFaqComponent} from './admin-settings/admin-settings-faq/admin-settings-faq.component';
import {AdminSettingsEditFormComponent} from './admin-settings/admin-settings-edit-form/admin-settings-edit-form.component';
import {ObjectEditModule} from '../object-edit/object-edit.module';
import { ObjectPageComponent } from './object-page/object-page.component';
import { AboutPrimusComponent } from './about-primus/about-primus.component';
import { AdminImportAuthoritiesComponent } from './admin-import-authorities/admin-import-authorities.component';
import { AdminSettingsViewFieldsComponent } from './admin-settings/admin-settings-view-fields/admin-settings-view-fields.component';
import {ObjectViewModule} from '../object-view/object-view.module';
import {ObjectSearchModule} from '../object-search/object-search.module';
import {OverviewFieldsModule} from '../overview-fields/overview-fields.module';
import { AdminMainPageComponent } from './admin-main-page/admin-main-page.component';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { AdminConceptListOverviewPageComponent } from './admin-concept-list-overview-page/admin-concept-list-overview-page.component';
import { AdminTemplateGroupComponent } from './admin-template-group/admin-template-group.component';
import { AdminTemplateModelComponent } from './admin-template-model/admin-template-model.component';
import {ImportSitulaComponent} from './import-situla/import-situla.component';
import { ObjectNewPageComponent } from './object-new-page/object-new-page.component';
import { ObjectNewObjectViewComponent } from './object-new-page/object-new-object-view/object-new-object-view.component';
import { ObjectNewCopyViewComponent } from './object-new-page/object-new-copy-view/object-new-copy-view.component';
import {MediaModule} from '../media/media.module';
import { MediaPageComponent } from './media-page/media-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import {UIRouterModule} from '@uirouter/angular';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    SharedModule,
    UIRouterModule,
    MediaCarouselModule,
    ObjectEditModule,
    ObjectViewModule,
    ObjectSearchModule,
    OverviewFieldsModule,
    MediaModule
  ],
  declarations: [
    FaqPageComponent,
    TopHeaderComponent,
    ImageFullScreenComponent,
    AdminSettingsComponent,
    AdminSettingsEditFieldsComponent,
    AdminSettingsFaqComponent,
    AdminSettingsEditFormComponent,
    ObjectPageComponent,
    AboutPrimusComponent,
    AdminImportAuthoritiesComponent,
    AdminSettingsViewFieldsComponent,
    AdminMainPageComponent,
    AdminPageComponent,
    AdminConceptListOverviewPageComponent,
    AdminTemplateGroupComponent,
    AdminTemplateModelComponent,
    ObjectNewPageComponent,
    ObjectNewObjectViewComponent,
    ObjectNewCopyViewComponent,
    MediaPageComponent,
    LoginPageComponent,
    ImportSitulaComponent
  ]
})
export class LandingPagesModule {
  constructor(private translate: TranslateService,
              private cmsQueueService: CmsQueueService) {
    cmsQueueService.runCmsFnWithQueue('getSettings').then(data => {
      translate.use(data[AConst.CLIENT_LANGUAGE]);
    });
  }
}
