import {Component, Input, OnInit} from '@angular/core';
import {ActionMenuContainer} from '../../core/action-menu-container';
import {AConst} from '../../core/a-const.enum';
import {transition, trigger, useAnimation} from '@angular/animations';
import {slideDownMenu, slideUpMenu} from '../../shared/animations';

@Component({
  selector: 'app-object-menu-current',
  templateUrl: './object-menu-current.component.html',
  styleUrls: ['./object-menu-current.component.scss'],
  animations: [
    trigger('slideObjectMenuCurrent', [
      transition(':leave', [
        useAnimation(slideUpMenu, { params: {time: '750ms ease-in'}}),
      ]),

      transition(':enter', [
        useAnimation(slideDownMenu, { params: {time: '750ms ease-out'}}),
      ])
    ])
  ]
})
export class ObjectMenuCurrentComponent implements OnInit {

  @Input() menuContainer: ActionMenuContainer;
  @Input() menu;

  AConst = AConst;

  constructor() {
  }

  ngOnInit() {
  }

}
