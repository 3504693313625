import {Injectable} from '@angular/core';

import {AConst} from './a-const.enum';
import {CmsApiService} from './cms-api.service';
import {ObjectView} from './object-view';

export class ObjectViewDataParams {
  objectId: string;
  object: any;
  templateGroupId: string;

  constructor() {
  }
}

@Injectable({
  providedIn: 'root'
})
export class ObjectViewDataService {

  constructor(private cms: CmsApiService) {
  }

  createViewData(params: ObjectViewDataParams): Promise<ObjectView> {
    return new Promise((resolve, reject) => {
      const cmsParams = {
        artifact_id: null,
        template_group_id: params.templateGroupId
      };
      if (params.object) {
        cmsParams.artifact_id = params.object[AConst.ARTIFACT_ID];
      } else {
        cmsParams.artifact_id = params.objectId;
      }
      this.cms.getArtifactView(cmsParams).then(
        viewData => {
          resolve(viewData);
        },
        reason => {
          reject(reason);
        });
    });
  }

}
