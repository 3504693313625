import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MediaHelperService} from '../../core/media-helper.service';
import {AConst} from '../../core/a-const.enum';
import {CommonsService} from '../../core/commons.service';
import {RefService} from '../../core/ref.service';
import {UiToolsService} from '../../core/ui-tools.service';

export class Media {
  $$selected: boolean;
  $$targetState: string;
  $$targetParams: any;
  $$imageUrl: string;
  $$thumbUrl: string;
  $$contextIds: string;
  $$parentId: string;
}

export class CarouselContainer {
  images: Array<Media>;
  imgIndex: number;
}


@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  AConst = AConst;
  carouselCont: CarouselContainer;
  zoomValue = 1;
  container;

  annotation_events;
  showAnnotationToggle = false;
  toggleAnnotations = false;
  activeAnnotation;
  fullScreenState = 'home.primus.full-screen';
  fullScreenParams = {};
  resizeTimeOut;
  showInformationButton = false;
  showFullScreenButton = false;
  showZoom = false;
  showDownload = false;
  imageGallery = true;
  showAllAnnotationPoints = false;
  activeAnnotationRef;
  isOverflown = false;
  showImageOrder = false;
  originalImages = [];
  stopSizeWatch;
  mediumScreen;

  @ViewChild('container', { static: true }) containerElement: ElementRef;
  @Input() images;
  @Input() startImageId;
  @Input() art;
  @Input() rootObject;
  @Input() curAnn;
  @Input() isEditing;
  @Output() updateObject = new EventEmitter<object>();

  constructor(private mediaHelper: MediaHelperService,
              private elementRef: ElementRef,
              private commons: CommonsService,
              private ref: RefService,
              private uiTools: UiToolsService) {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    // wait for resize to be finish before doing stuff
    if (this.resizeTimeOut) {
      clearTimeout(this.resizeTimeOut);
    }
    this.resizeTimeOut = setTimeout((() => {
      this.resize();
    }), 500);
  }

  ngOnChanges() {
    if (this.rootObject) {
      if (this.rootObject[this.AConst.META_TYPE] !== 'media') {
        this.showAnnotationToggle = true;
      }
      if (this.rootObject.annotation_events) {
        this.annotation_events = this.rootObject.annotation_events;
      }
    }
  }

  ngOnInit() {
    let indexSet = false, mustSetIndex;
    this.mediumScreen = this.uiTools.setMediumScreenSizeOnLoad(this.mediumScreen);
    this.carouselCont = new CarouselContainer();
    this.carouselCont.images = this.images;
    this.carouselCont.imgIndex = -1;

    this.originalImages = this.cloneArray(this.images);
    if (this.art) {
      if (this.art[this.AConst.META_TYPE] !== 'Image') {
        this.imageGallery = false;
      }
    }
    mustSetIndex = this.startImageId !== null;
    for (let i = 0; i < this.carouselCont.images.length; i++) {
      if (mustSetIndex &&
        this.carouselCont.images[i][this.AConst.ARTIFACT_ID] === this.startImageId) {
        indexSet = true;
        this.setImgIndex(i).then();
      }
    }
    if (!indexSet && this.carouselCont.imgIndex === -1) {
      this.setImgIndex(0).then();
    }
    this.stopSizeWatch = this.uiTools.addWindowSizeListener(
      (newVal) => {
        this.mediumScreen = newVal.width < 1025;
        // this.smallScreen = newVal.width < 642;
      }
    );
  }

  ngAfterViewInit() {
    this.container = this.containerElement.nativeElement;
  }

  cloneArray(oldArray: Object[]) {
    const newArray = [];
    oldArray.forEach((item) => {
      newArray.push(Object.assign({}, item));
    });
    return newArray;
  }


  canAnnotate() {
    let res = false;
    if (this.showAnnotationToggle && this.rootObject) {
      const image = this.carouselCont.images[this.carouselCont.imgIndex];
      if (image) {
        res = image[AConst.OBJECT_TYPE] === 'Image' &&
          this.commons.canAnnotate(this.rootObject);
      }
    }
    return res;
  }

  toggleAnnotation() {
    this.toggleAnnotations = !this.toggleAnnotations;
    this.onChangeZoomValue(1);
    if (this.toggleAnnotations) {
      this.setCurrentAnnotation(this.carouselCont.imgIndex).then(() => {
        this.checkAnnotationPointsOverflow();
      });
    } else {
      this.showAllAnnotationPoints = false;
    }
  }

  onSetActiveImg(index) {
    this.zoomValue = 1;
    this.setImgIndex(index).then(() => {
      if (this.toggleAnnotations) {
        this.checkAnnotationPointsOverflow();
      }
      const currentArtifact = this.carouselCont.images[index][this.AConst.ARTIFACT_ID];
      this.updateObject.emit(currentArtifact);
      this.onChangeZoomValue(this.zoomValue);
    });
  }

  onToggleChangeImageOrder(images?) {
    this.showImageOrder = !this.showImageOrder;
    if (images) {
      this.carouselCont.images = this.cloneArray(images);
      this.originalImages = this.cloneArray(images);
      this.commons.sortArray(this.carouselCont.images, 'order_number');
    } else {
      if (this.showImageOrder === false) {
        this.carouselCont.images = this.cloneArray(this.originalImages);
      }
    }
  }

  onChangeZoomValue(value) {
    this.zoomValue = value;
  }

  toggleAllAnnotationPoints() {
    this.showAllAnnotationPoints = !this.showAllAnnotationPoints;
  }

  checkAnnotationPointsOverflow() {
    this.showAllAnnotationPoints = false;
    setTimeout(() => {
      const annotationElement = this.elementRef.nativeElement.querySelector('#annotationPoints');
      if (this.activeAnnotation && annotationElement) {
        this.isOverflown = this.isOverFlow(annotationElement);
      } else {
        this.isOverflown = false;
      }
    }, 700);
  }

  isOverFlow(element) {
    return element.scrollHeight > element.clientHeight;
  }

  ngOnDestroy() {
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
  }

  private resize() {
    this.onChangeZoomValue(this.zoomValue);
  }

  private setImgIndex(imgIndex) {
    return new Promise (resolve => {
      this.carouselCont.imgIndex = imgIndex;
      this.setImageMenu(imgIndex);
      this.fullScreenParams = {
        imageId: this.carouselCont.images[this.carouselCont.imgIndex][this.AConst.ARTIFACT_ID]
      };
      if (this.toggleAnnotations) {
        this.setCurrentAnnotation(imgIndex).then(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  private setCurrentAnnotation(index) {
    return new Promise (resolve => {
      if (this.toggleAnnotations && this.curAnn) {
        const imageId = this.carouselCont.images[index][this.AConst.ARTIFACT_ID];
        this.curAnn.annotateImage = {
          image_id: imageId
        };
         this.getAnnotation().then((activeAnnotation) => {
           this.activeAnnotation = activeAnnotation;
           this.curAnn.ane = this.activeAnnotation;
           if (this.curAnn.ane) {
             this.curAnn.ane.$$readOnly = true;
           }
           let params;
           if (this.activeAnnotation && this.activeAnnotation[AConst.ANNOTATION_POINTS]) {
             const contextIds = this.commons.getContextIds(this.activeAnnotation.contexts);
             params = {
               object: this.activeAnnotation,
               stateParams: {
                 contextIds: contextIds,
                 parentId: this.activeAnnotation.parent_id,
                 rootObjId: this.activeAnnotation[this.AConst.ARTIFACT_ID],
                 edit: !this.art.$$readOnly
               }
             };
           } else {
             const object = {
               object_type: 'AnnotationEvent',
               image_id: imageId,
               contextIds: this.carouselCont.images[index].$$contextIds,
               parentId: this.carouselCont.images[index].$$parentId
             };
             params = {
               object: object,
               stateParams: {
                 contextIds: object.contextIds,
                 parentId: object.parentId,
                 rootObjId: object.parentId,
                 edit: !this.art.$$readOnly
               }
             };
           }
           this.activeAnnotationRef = this.ref.makeRef(params);
           resolve();
         });
      }
    });
  }

  private setImageMenu(index) {
    if (this.carouselCont.images[index][this.AConst.ARTIFACT_ID].indexOf('IMG-') !== -1) {
      this.showInformationButton = true;
      this.showFullScreenButton = true;
      this.showZoom = true;
      this.showDownload = true;
    } else if (this.carouselCont.images[index][this.AConst.ARTIFACT_ID].indexOf('VID-') !== -1) {
      this.showInformationButton = false;
      this.showFullScreenButton = false;
      this.showZoom = false;
      this.showDownload = true;
    } else if (this.carouselCont.images[index][this.AConst.ARTIFACT_ID].indexOf('ATT-') !== -1) {
      this.showInformationButton = false;
      this.showFullScreenButton = false;
      this.showZoom = false;
      this.showDownload = true;
    }
  }

  private getAnnotation() {
    return new Promise (resolve => {
      let activeAnnotation = null;
      if (this.annotation_events && this.annotation_events.length > 0) {
        this.annotation_events.forEach((anno) => {
          if (this.carouselCont.images[this.carouselCont.imgIndex][this.AConst.ARTIFACT_ID] === anno.image_id) {
            activeAnnotation = anno;
          }
        });
      }
      resolve(activeAnnotation);
    });
  }

}
