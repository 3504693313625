import {Injectable} from '@angular/core';
import {saveAs} from 'file-saver';

import {ReporterTranslationService} from './reporter-translation.service';
import {ResultParserService} from './result-parser.service';
import {ReporterUtilityService} from './reporter-utility.service';
import {ReporterPdfService} from './reporter-pdf.service';
import {ExtArray} from './ext-array';
import {ExcelService} from './excel.service';
import {ExtDate} from './ext-date';
import {AConst} from '../core/a-const.enum';

const _MIME_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
const _OUTPUT_FILENAME = 'primus_report.xls';

/**
 * Service hosting methods used when initializing a worker process used to generate a report.
 * @type {{init, getSettings, getFilterSettings, getReportDateTime}}
 */
@Injectable({
  providedIn: 'root'
})

export class ReporterService {

  private handler = null;                        // Search handler
  private workerSearchContainer = null;          // Search container
  private ctrl = null;                           // Search control
  private reportType = null;                     // Current report type
  private colHeaders = null;                     // Column headers
  private translations = null;                   // List of translation strings
  private selectedArtifacts = null;              // List of selected artifacts
  private settings = null;                       // Primus version
  private reportDate = null;                     // Date & time the report was created
  private overviewFields = null;                 // Overview fields

  private filtersHR = new ExtArray();                        // Filter settings in HR-format


  constructor(private reporterTranslation: ReporterTranslationService,
              private resultParser: ResultParserService,
              private reporterUtility: ReporterUtilityService,
              private reporterPdf: ReporterPdfService,
              private excel: ExcelService
  ) {
  }

  /**
   * Clears previous search container and artifact IDs.
   */
  _clear() {
    this.handler = null;
    this.workerSearchContainer = null;
    this.ctrl = null;
    this.reportType = null;
    this.colHeaders = null;
    this.selectedArtifacts = null;
    this.filtersHR = new ExtArray();
    this.overviewFields = null;
  }

  /**
   * Get the report's column headers from the artifact's meta-definition.
   * @param overviewFieldsDataSet
   * @returns {*}
   * @private
   */
  private getColumnHeaders(overviewFieldsDataSet) {
    const headers = [];
    const overviewFields = overviewFieldsDataSet[Object.keys(overviewFieldsDataSet)[0]];

    overviewFields.forEach(function (f) {
      headers.push({
        fieldTitle: f.field_title,
        order: f.order,
        headerType: f.name
      });
    });

    return headers;
  }

  /**
   * Merge data specified in overview fields with the artifacts data set.
   * @param artifacts
   * @private
   */
  private mergeOverviewFieldsData(artifacts) {
    artifacts.forEach((art, ix) => {
      const artifactOverviewFields = this.overviewFields[art.artifact_id];

      artifactOverviewFields.forEach(function (fld) {
        if (typeof (art[fld.name]) === 'undefined') {
          artifacts[ix][fld.name] = fld.value;
        }
      });
    });

    return artifacts;
  }

  /**
   * Callback run when data is fetched via the index/Solr, that renders the resulting report.
   * @private
   */
  private callbackGetDataFromIndex() {
    return new Promise((resolve) => {

      // Get column headings
      this.colHeaders = this.getColumnHeaders(this.overviewFields);

      // Run the query
      this.handler.setReportView(this.workerSearchContainer);
      this.handler['runSearch'](this.workerSearchContainer).then((res) => {
        let artifacts = [];
        res.artifacts.forEach(artifact => {
          if (!this.selectedArtifacts || this.selectedArtifacts.indexOf(artifact[AConst.ARTIFACT_ID]) !== -1) {
            if (artifact['source']) {
              artifacts.push(JSON.parse(artifact['source']));
            } else {
              artifacts.push(artifact);
            }
          }
        });
        artifacts = this.mergeOverviewFieldsData(artifacts);

        // Parse the result an create the finished report.
        this.resultParser.parse(artifacts).then((rows) => {
          this.createReport(rows).then(resolve);
        });
      });
    });
  }

  /**
   * Creates the specified report output.
   * @param artifacts
   * @private
   */
  private createReport(artifacts) {

    // Object containing the translated texts used on the front page, headers and footers of the report.
    const frontPageTexts = {
      header: this.reporterTranslation.getString('TRANS__REPORTER_PDF__FRONTPAGE_HEADER'),
      created: this.reporterTranslation.getString('TRANS__REPORTER_PDF__FRONTPAGE_CREATED'),
      version: this.reporterTranslation.getString('TRANS__REPORTER_PDF__FRONTPAGE_VERSION'),
      createdBy: this.reporterTranslation.getString('TRANS__REPORTER_PDF__FRONTPAGE_CREATEDBY'),
      page: this.reporterTranslation.getString('TRANS__REPORTER_PDF__FRONTPAGE_PAGE')
    };

    return new Promise((resolve) => {
      this.reportDate = ExtDate.getHrDateTime();

      switch (this.reportType) {
        case 'excel':
          const reportData = this.excel.renderReport(artifacts, this.overviewFields, this.colHeaders);
          const blob = new Blob([reportData], {
            type: _MIME_TYPE
          });
          saveAs(blob, _OUTPUT_FILENAME);
          resolve();
          break;
        case 'pdf2x2grid':
          this.reporterPdf.init(this.selectedArtifacts, this.overviewFields, this.workerSearchContainer, this.handler, this.translations,
            '2x2grid', frontPageTexts, this.getFilterSettings())
            .then((json) => {
              this.reporterUtility.downloadPDFReport(json).then(function () {
                resolve();
              });
            });
          break;
        case 'pdf3x2grid':
          this.reporterPdf.init(this.selectedArtifacts, this.overviewFields, this.workerSearchContainer, this.handler, this.translations,
            '3x2grid', frontPageTexts, this.getFilterSettings())
            .then((json) => {
              this.reporterUtility.downloadPDFReport(json).then(function () {
                resolve();
              });

            });
          break;
        case 'pdffullobject':
          this.reporterPdf.init(this.selectedArtifacts, this.overviewFields, this.workerSearchContainer, this.handler, this.translations,
            'fullobject', frontPageTexts, this.getFilterSettings())
            .then((json) => {
              if (Array.isArray(json)) {
                // If more than one object is selected, render a single PDF for each report.
                for (let n = 0; n < json.length; n++) {
                  this.reporterUtility.downloadPDFReport(json[n]).then(function () {
                    resolve();
                  });
                }
              } else {
                this.reporterUtility.downloadPDFReport(json).then(function () {
                  resolve();
                });
              }
              resolve();
            });
          break;
        case 'imageToTheLeftTable':
          this.reporterPdf.init(this.selectedArtifacts, this.overviewFields, this.workerSearchContainer, this.handler, this.translations,
            'imageToTheLeftTable', frontPageTexts, this.getFilterSettings())
            .then((json) => {
              console.log('PDF-JSON: ', JSON.stringify(json));
              this.reporterUtility.downloadPDFReport(json).then(function () {
                resolve();
              });
            });
          break;
      }
    });
  }

  /**
   * Returns the current filter settings.
   * @returns {Array}
   */
  getFilterSettings() {
    return this.filtersHR;
  }

  /**
   * Returns an object containing the report settings.
   * @returns object
   */
  getSettings() {
    return this.settings;
  }

  /**
   * Returns the date & time the report was created in HR-format.
   * @returns string
   */
  getReportDateTime() {
    return this.reportDate;
  }

  /***
   * Initializes report generation.
   * @param ctrl
   * @param handler searchHandler
   * @param overviewFields Overview fields, as fetched from the selected template.
   * @param reportType
   * @param fromIndex
   */
  init(ctrl, handler, overviewFields, reportType, fromIndex) {
    return new Promise((resolve) => {
      this.reporterUtility.getReportSettings().then((settings) => {

        this.settings = settings;

        // Reset reporter
        this._clear();

        // Set current report type
        this.reportType = reportType;

        this.overviewFields = overviewFields;

        // Ref. searchHandler
        if (handler) {
          this.handler = handler;
        }

        // Ref. ctrl
        if (ctrl) {
          this.ctrl = ctrl;
          this.workerSearchContainer = Object.assign({}, this.ctrl.searchContainer);

          if (!this.workerSearchContainer.allSelected) {
            const selectedItem = this.workerSearchContainer.selected;

            if (!this.selectedArtifacts) {
              this.selectedArtifacts = selectedItem;
            } else if (this.selectedArtifacts && !this.selectedArtifacts.includes(selectedItem)) {
              this.selectedArtifacts = selectedItem;
            }
          }
        }

        if (fromIndex) {
          // Data is read from the index/Solr.
          this.callbackGetDataFromIndex().then(resolve);
        } else {
          // Data is read directly from the database.
          this.filtersHR.merge(this.reporterTranslation.translateArtifactTypeFilterSettings(this.workerSearchContainer));
          this.filtersHR.merge(this.reporterTranslation.translateSearchFilterSettings(this.workerSearchContainer));

          this.createReport(null).then(resolve); // Artifacts will be fetched later in this case.
        }
      });
    });
  }

}
