import {Component, OnInit} from '@angular/core';
import {StateService} from '@uirouter/core';
import {CmsApiService} from '../../core/cms-api.service';
import {CommonsService} from '../../core/commons.service';
import {EventharvesterService} from '../../core/eventharvester.service';
import {SimpleClientCache} from '../../core/simple-client-cache';

@Component({
  selector: 'app-about-primus',
  templateUrl: './about-primus.component.html',
  styleUrls: ['./about-primus.component.scss']
})
export class AboutPrimusComponent implements OnInit {

  changeList = [];
  parent;
  currentTab;
  currentChangeIndex = 0;
  edition = {
    title: ''
  };
  version;
  userManual;

  private cacheKey = 'about';
  private cacheExpires = 3600 * 2;
  private simpleClientCache: SimpleClientCache = null;

  constructor(private stateService: StateService,
              private cms: CmsApiService,
              private commons: CommonsService,
              private eventHarvester: EventharvesterService) {
  }

  ngOnInit() {
    this.simpleClientCache = new SimpleClientCache(this.cacheKey, this.cacheExpires);
    this.setEditionAndVersion((edition, version) => {
      this.edition = edition;
      this.version = version;
    });

    this.getUserManual((res) => {
      this.userManual = res;
    });
    this.parent = {
      adminType: 'about',
      subMenus: false,
      currentState: this.stateService.current.name
    };

    this.getWhatIsNew();
  }

  setEmptyValue() {
    this.changeList.forEach((val, key1) => {
      for (const key2 in val.value) {
        if (val.value.hasOwnProperty(key2)) {
          const value = val.value[key2];
          if (value.edition.indexOf('small') === -1) {
            this.changeList[key1].value[key2].edition.push('smallEmpty');
          }
          if (value.edition.indexOf('medium') === -1) {
            this.changeList[key1].value[key2].edition.push('mediumEmpty');
          }
          if (value.edition.indexOf('large') === -1) {
            this.changeList[key1].value[key2].edition.push('largeEmpty');
          }
        }
      }
    });
  }

  tabButton(version, tabIndex) {
    this.currentTab = version;
    this.currentChangeIndex = tabIndex;
  }

  logDownloadManual(name) {
    this.eventHarvester.fileDownload('manual', name);
  }

  private getUrl(module) {
    let currentUrl;
    if (module) {
      currentUrl = 'translation/manual/' + module;
    } else {
      currentUrl = 'translation/manual/';
    }
    return this.cms.getApiUrl(currentUrl);
  }

  private getUserManual(fn) {
    const hrefBasis = this.getUrl(null);
    const hrefStandard = this.getUrl(null);
    const hrefFdv = this.getUrl('fdv');
    const hrefConservation = this.getUrl('kon');

    const userManuals = [
      {
        manuals: [{href: hrefBasis, title: 'TRANS__ABOUT_PRIMUS__USER_MANUAL_BASIS'}],
        editions: 'Small'
      },
      {
        manuals: [{href: hrefStandard, title: 'TRANS__ABOUT_PRIMUS__USER_MANUAL_STANDARD'}],
        editions: 'Medium'
      },
      {
        manuals: [
          {href: hrefFdv, title: 'TRANS__ABOUT_PRIMUS__USER_MANUAL_FDV'},
          {href: hrefConservation, title: 'TRANS__ABOUT_PRIMUS__USER_MANUAL_CONSERVATION'}],
        editions: 'Large'
      }
    ];

    console.log('Basis: ' + hrefBasis);

    this.commons.getValidEditionArray(userManuals, (validManuals) => {
      validManuals.forEach(validManual => {
        fn(validManual);
      });
    });
  }

  private setEditionAndVersion(fn) {
    this.commons.getUserData(false).then(user => {
      if (user) {
        const edition = this.commons.setEditionTitle(user);
        const version = user['version'];
        fn(edition, version);
      }
    });
  }

  private parseData(data) {
    let versions, highestVersion;

    versions = Object.keys(data).map((key) => {
      return key.replace(/[.]/g, '');
    });

    if (versions.indexOf('Unspecified') !== -1) {
      const index = versions.indexOf('Unspecified');
      versions.splice(index, 1);
    }

    highestVersion = versions.reduce((a, b) => {
      return Math.max(parseFloat(a), parseFloat(b));
    });

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (key !== 'Unspecified') {
          const currentKey = parseFloat(key.replace(/[.]/g, ''));

          if (currentKey === highestVersion) {
            this.currentTab = key;
            this.currentChangeIndex = this.changeList.length;
          }
          const changes = {
            version: key,
            value: value
          };
          this.changeList.push(changes);
        }
      }
    }
    if (this.changeList) {
      this.setEmptyValue();
    }
    this.commons.sortArray(this.changeList, 'version', true);
  }

  private getWhatIsNew() {
    let cached = false;
    const cachedData = this.simpleClientCache.get();

    if (cachedData) {
      this.parseData(cachedData);
      cached = true;
    }

    this.cms.getWhatIsNew().then((data: object) => {
        this.simpleClientCache.put(data);
        if (!cached) {
          this.parseData(data);
          cached = true;
        }
       },
      (failed) => {
        console.error('This failed! ' + failed.message);
      }
    );

  }
}
