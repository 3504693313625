import {Component, Input, OnChanges} from '@angular/core';
import {ActionMenuContainer} from '../../core/action-menu-container';
import {AConst} from '../../core/a-const.enum';
import {ObjectMenuService} from '../object-menu.service';

@Component({
  selector: 'app-object-menus',
  templateUrl: './object-menus.component.html',
  styleUrls: ['./object-menus.component.scss']
})
export class ObjectMenusComponent implements OnChanges {

  @Input() menuContainer: ActionMenuContainer;
  @Input() isEditing: boolean;
  @Input() mediaPage: boolean;

  AConst = AConst;


  constructor(private objectMenuService: ObjectMenuService) {
  }

  ngOnChanges(): void {
    if (this.menuContainer.art) {
      this.objectMenuService.initMenuContainer(this, this.menuContainer);
    }
  }

  openActionMenu(menu, menuGroup) {
    this.objectMenuService.openActionMenu(this, this.menuContainer, null, menu, menuGroup);
  }

  toggleMenu(menuGroup) {
    this.objectMenuService.toggleMenuGroup(this, this.menuContainer, null, menuGroup);
  }

}
