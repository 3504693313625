import {Component, ElementRef, Input, OnChanges, OnDestroy, ViewChild} from '@angular/core';
import {FieldValue} from '../../core/object-view';
import {UiToolsService} from '../../core/ui-tools.service';

@Component({
  selector: 'app-object-view-field-value',
  templateUrl: './object-view-field-value.component.html',
  styleUrls: ['./object-view-field-value.component.scss']
})
export class ObjectViewFieldValueComponent implements OnChanges, OnDestroy {

  @ViewChild('textAreaElement', { static: false }) textArea: ElementRef;
  @Input() fieldValue: FieldValue;
  @Input() edit: boolean;

  url = false;
  urlObject;
  imageObject;

  private initialHeight: number;
  private stopSizeWatch;

  constructor(private uiToolsService: UiToolsService) {
  }

  ngOnChanges() {
    if (this.fieldValue.ref_id) {
      if (this.fieldValue.ref_id.indexOf('IMG-') === 0) {
        this.imageObject = {thumbnail_id: this.fieldValue.ref_id};
      } else if (this.fieldValue.ref_id === 'url') {
        this.url = true;
      } else if (this.fieldValue.ref_id.indexOf('ct_') !== 0) {
        this.urlObject = {artifact_id: this.fieldValue.ref_id};
      }
    }
    setTimeout(() => {
      this.initTextArea();
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.stopSizeWatch) {
      this.uiToolsService.removeWindowSizeListener(this.stopSizeWatch);
    }
  }

  private initTextArea() {
    if (this.textArea) {
      this.initialHeight = this.textArea.nativeElement.style.height;
      this.stopSizeWatch = this.uiToolsService.addWindowSizeListener(() => {
        this.setTextAreaHeight();
      });
      this.setTextAreaHeight();
    }
  }

  private setTextAreaHeight() {
    this.textArea.nativeElement.style.height = 'auto';
    this.textArea.nativeElement.style.height = (this.textArea.nativeElement.scrollHeight) + 10 + 'px';
  }
}
