import { Injectable } from '@angular/core';
import {ReporterUtilityService} from './reporter-utility.service';
import {ReporterTranslationService} from './reporter-translation.service';
import {CommonsService} from '../core/commons.service';

@Injectable({
  providedIn: 'root'
})
export class ReporterPdfGridReportFrontPageService {
  private texts = null;
  private settings;
  private username;
  private filterSettings;

  constructor(private reporterUtility: ReporterUtilityService,
              private reporterTranslation: ReporterTranslationService,
              private commons: CommonsService) {
    this.reporterUtility.getReportSettings().then((settings) => {
      this.settings = settings;
      commons.getUserData(false).then( data => {
        this.username = data['name'];
      });
    });
  }

  /**
   * Creates the header JSON-structure.
   * @returns {{text: string, style: string, bold: boolean, margin: number[]}}
   * @private
   */
  private createHeaderJson() {
    return {
      text: this.texts.header.toUpperCase(),
      style: 'frontpageHeader',
      bold: true,
      margin: [0, 40, 0, 10]
    };
  }

  /**
   * Creates the museum name JSON-structure.
   * @returns {{text: *, style: string}}
   * @private
   */
  private createMuseumNameJson() {
    return {
      text: this.settings.museumName,
      style: 'frontpageDetails'
    };
  }


  /**
   * Returns a text element containing the specified search filters,
   * used when creating the report.
   * @returns {[{text: string, fontSize: number}]}
   * @private
   */
  private getFilterSettings() {
    const arr = [];

    this.filterSettings.getItems().map(function(f) {
      const row = [];

      // Filter setting header
      row[0] = {
        text: f['name'].toUpperCase(),
        bold: true,
        color: '#7e97ad',
        fontSize: 9,
        margin: [0, 4, 0, 0]
      };
      row[1] = [];

      // Filter setting values
      f['settings'].map((fs) => {
        row[1].push({text: fs, style: 'sectionText', fontSize: 9});
      });

      arr.push(row);
    });

    return arr;
  }

  /**
   * Creates the filter settings header with a coloured background used on the front page.
   * @param title
   * @returns {{table: {widths: string[], dontBreakRows: boolean, body: *[]}, layout: {hLineWidth: layout.hLineWidth, vLineWidth: layout.vLineWidth}, margin: number[]}}
   * @private
   */
  private static createColouredFilterSettingsHeader(title) {
    return {
      table: {
        widths: ['*'],
        dontBreakRows: true,
        body: [
          [{
            text: title.toUpperCase(),
            fontSize: 9,
            bold: true,
            fillColor: '#7e97ad',
            color: '#ffffff',
            margin: [2, 2, 2, 2]
          }]
        ]
      },
      layout: {
        hLineWidth: function (i) { return 0; },
        vLineWidth: function (i) { return 0; }
      },
      margin: [0, 20, 0, 0],
    };
  }


  /**
   * Creates the filter settings table's items.
   * @returns {Array}
   * @private
   */
  private createFilterSettingsTable() {
    const items = [];

    const filterSettings = this.getFilterSettings();

    if (filterSettings.length > 0) {
      items.push(ReporterPdfGridReportFrontPageService.createColouredFilterSettingsHeader(
        this.reporterTranslation.getString('TRANS__REPORTER_PDF__FILTER_FILTERSETTINGS')));

      items.push({
        table: {
          widths: ['auto', '*'],
          body: filterSettings
        },
        layout: {
          hLineWidth: function (i) { return 1; },
          vLineWidth: function (i) { return 0; },
          hLineColor: function (i) { return '#7e97ad'; }
        }
      });
    }

    return items;
  }

  /**
   * Creates the table containing the report's metadata on the front page.
   * @param username
   * @param createdHr
   * @param version
   * @returns {*[]}
   * @private
   */
  private createFrontPageTable(username, createdHr, version) {
    version = this.texts.version + ' ' + version;

    const items = [
      {
        image:  this.reporterUtility.getPrimusLogo(),
        width: 80
      },
      this.createHeaderJson(),
      this.createMuseumNameJson(),
      {
        text: this.texts.created + ': ' + createdHr,
        style: 'frontpageDetails'
      },
      {
        text: version,
        style: 'frontpageDetails'
      },
      {
        text: this.texts.createdBy + ' ' + username,
        style: 'frontpageDetails'
      }
    ];

    this.createFilterSettingsTable();

    return items;
  }

  /**
   * Creates a front page containing:
   * - Museum name
   * - List of search filters used
   * - Report creation date & time
   * @param texts
   * @param createdHr
   * @param filterSettings
   * @returns Array
   * @private
   */
  create(texts, createdHr, filterSettings) {
    this.texts = texts;
    this.filterSettings = filterSettings;

    return new Promise((resolve) => {
      const self = this;
      const version = this.reporterUtility.getPrimusVersion();

      const frontPage = [{
        table: {
          widths: ['auto', 'auto'],
          body: [
            [
              {text: ' ', margin: [7, 0, 0, 0]},
              self.createFrontPageTable(this.username, createdHr, version)
            ]
          ]
        },
        layout: {
          hLineWidth: function (i, node) { return 0; },
          vLineWidth: function (i, node) { return (i === 0) ? 4 : 0; },
          vLineColor: function (i, node) { return '#7e97ad'; }
        },
        margin: [40, 30, 0, 0],
        pageBreak: 'after'
      }];

      resolve(frontPage);
    });
  }
}
