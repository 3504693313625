import {Annotation} from './annotation';

export class AnnotationContainer {
  canvas;
  client;
  ctx: CanvasRenderingContext2D;
  curAnn: Annotation;
  dashboard;
  dashboardId;
  editable: boolean;
  heightIsSmallerThanContainer: boolean;
  imageLoaded: boolean;
  imageId;
  imageObj;
  imageSize;
  lockHeight: boolean;
  menus: Array<any>;
  offsets: number;
  parentContainerId: string;
  quality;
}
