import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-thumbnail-carousel-nav-button',
  templateUrl: './thumbnail-carousel-nav-button.component.html',
  styleUrls: ['./thumbnail-carousel-nav-button.component.scss']
})
export class ThumbnailCarouselNavButtonComponent implements OnInit {

  @Input() direction;
  @Input() carouselCont;
  @Output() setActiveImg = new EventEmitter<object>();

  constructor() {
  }

  ngOnInit() {
  }

  setImage() {
    if (this.direction === 'next') {
      this.carouselCont.imgIndex = this.nextImage();
    } else {
      this.carouselCont.imgIndex = this.previousImage();
    }
    this.setActiveImg.emit(this.carouselCont.imgIndex);
  }

  private previousImage() {
    let index = this.carouselCont.imgIndex - 1;
    if (index < 0) {
      index = this.carouselCont.images.length - 1;
    }
    return index;
  }

  private nextImage() {
    let index = this.carouselCont.imgIndex + 1;
    if (index >= this.carouselCont.images.length) {
      index = 0;
    }
    return index;
  }
}
