import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {CmsApiService} from '../../core/cms-api.service';
import {CommonsService} from '../../core/commons.service';
import {FieldStateService} from '../../core/field-state.service';

@Component({
  selector: 'app-field-tool-tip',
  templateUrl: './field-tool-tip.component.html',
  styleUrls: ['./field-tool-tip.component.scss']
})
export class FieldToolTipComponent implements OnInit {

  AConst = AConst;
  loading = false;
  showToolTip = false;
  fieldName;
  tooltip = {
    loaded: false,
    default: '',
    spectrum: ''
  };

  @Input() field: object;
  @Input() rootObject;

  constructor(private cms: CmsApiService, private commons: CommonsService, private fieldStateSvc: FieldStateService) {
  }

  ngOnInit() {
    this.fieldName = this.fieldStateSvc.getFieldTitle(this.field, this.rootObject);
  }

  getToolTip(field) {
    this.cms.getToolTip({field_id: field.field_id}).then(
      (data) => {
        this.tooltip.spectrum = data['spectrum'];
        this.tooltip.default = data['default'];
        this.tooltip.loaded = true;
        this.loading = false;
      },
      () => {
        this.loading = false;
        console.warn('failed to get tooltip for field ' + field.field_id);
      }
    );
  }

  toggleToolTip() {
    this.showToolTip = !this.showToolTip;
    if (this.showToolTip) {
      this.loading = true;
      this.getToolTip(this.field);
      window.addEventListener('mouseup', this.checkOutsideClick, true);
    } else {
      window.removeEventListener('mouseup', this.checkOutsideClick, true);
    }
  }

  checkOutsideClick = (evt: Event) => {
    const found = this.commons.findClassNameRecursively(evt.target, 'dropdownIgnoreClicks');
    if (!found) {
      setTimeout(() => {
        this.showToolTip = false;
        window.removeEventListener('mouseup', this.checkOutsideClick, true);
      }, 100);
    }
  }

}
