import { Injectable } from '@angular/core';
import {ReporterUtilityService} from './reporter-utility.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Service hosting methods used when converting
 * an image URL to a Base64 encoded string.
 * @type {{convert, convertMultiple}}
 */
export class ReporterPdfImageConverterService {

  constructor(private reporterUtility: ReporterUtilityService) { }

  /**
   * Downloads the image that corresponds to the specified imageID,
   * and returns it as a base64-encoded string.
   * @param imageID
   * @param noBorder
   * @returns {*}
   * @private
   */
  private downloadImage(imageID, noBorder?) {

    function stringFromUTF8Array(data) {
      const extraByteMap = [ 1, 1, 1, 1, 2, 2, 3, 0 ];
      const count = data.length;
      let str = '';

      for (let index = 0; index < count;) {
        let ch = data[index++];
        if (ch & 0x80) {
          let extra = extraByteMap[(ch >> 3) & 0x07];
          if (!(ch & 0x40) || !extra || ((index + extra) > count)) {
            return null;
          }

          ch = ch & (0x3F >> extra);
          for (; extra > 0; extra -= 1) {
            let chx = data[index++];
            if ((chx & 0xC0) != 0x80) {
              return null;
            }

            ch = (ch << 6) | (chx & 0x3F);
          }
        }

        str += String.fromCharCode(ch);
      }
      return str;
    }

    return new Promise((resolve, reject) => {
      let url = this.reporterUtility.getCmsApiUrl('multimedia/imageb64/') + imageID;
      url += '?size=large';
      url += ((typeof(noBorder) !== 'undefined') ? '&no_border=' + noBorder : '');

      const req = new XMLHttpRequest();

      req.onload = function (oEvent) {
        if (req.status === 200) {
          const arrayBuffer = req.response;
          if (arrayBuffer) {
            const byteArray = new Uint8Array(arrayBuffer);
            const imageData = stringFromUTF8Array(byteArray),
              imageUrl = 'data:image/jpeg;base64,' + imageData;

            resolve(imageUrl);
          }
        } else {
          reject(req.statusText);
        }
      };

      req.responseType = 'arraybuffer';
      req.open('GET', url);
      req.setRequestHeader('Content-Type', 'application/json');
      req.send();
    });
  }

  /**
   * Converts artifact's images to Base64 encoded strings,
   * as this is required by pdfMake.
   * @param artifact
   * @returns {*}
   */
  convert(artifact) {
    return new Promise((resolve, reject) => {

      if (typeof(artifact.image_ids.length) === 'undefined' || artifact.image_ids.length === 0) {
        resolve(artifact);
      } else {
        const imageID = artifact.image_ids[0];
        this.downloadImage(imageID).then((res) => {
          artifact.b64Img = res;
          resolve(artifact);
        }).catch( reason => {
          artifact.b64Img = null;
          resolve(artifact);
        });
      }
    });
  }

  /**
   * Downloads the images that matches the specified imageIDs and returns them
   * as an array of base64-encoded strings.
   * @param imageIDs
   * @param firstWithoutBorder
   */
  convertMultiple(imageIDs, firstWithoutBorder) {
    return new Promise((resolve) => {
      const b64images = [];
      const promises = [];

      for (let i = 0; i < imageIDs.length; i++) {
        const imageId = imageIDs[i];

        if (typeof(firstWithoutBorder) !== 'undefined' && firstWithoutBorder && i === 0) {
          promises.push(this.downloadImage(imageId, true).then( res => {
            b64images.push(res);
          }).catch(reason => {
            // Image download failed
          }));
        } else {
          promises.push(this.downloadImage(imageId).then(res => {
            b64images.push(res);
          }).catch( reason => {
            // Image download failed
          }));
        }
      }

      Promise.all(promises).then(() => {
        resolve(b64images);
      });
    });
  }

}
