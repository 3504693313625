import {Component, HostListener, OnInit} from '@angular/core';
import {StateService} from '@uirouter/core';
import {LoginService} from './core/login.service';
import {UiToolsService} from './core/ui-tools.service';
import {CmsApiService} from './core/cms-api.service';
import {CmsErrorHandlerService} from './core/cms-error-handler.service';
import {AConst} from './core/a-const.enum';
import {CmsQueueService} from './core/cms-queue.service';
import {TranslateService} from '@ngx-translate/core';
import {CommonsService} from './core/commons.service';
import {TransitionHandlerService} from './core/transition-handler.service';
import {CurrentObjectService} from './core/current-object.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private translate: TranslateService,
              private loginService: LoginService,
              private stateService: StateService,
              private uiTools: UiToolsService,
              private cms: CmsApiService,
              private cmsQueue: CmsQueueService,
              private cmsErrorHandlerService: CmsErrorHandlerService,
              private commons: CommonsService,
              private transitionHandler: TransitionHandlerService,
              public currentObjectService: CurrentObjectService) {
    transitionHandler.initTransitionHandler();
  }

  ngOnInit(): void {
    console.log('Init app component');

    this.cms.init((response, params) => {
      this.cmsErrorHandlerService.errHandler(response, params);
    });

    this.cmsQueue.runCmsFnWithQueue('getSettings').then(
      data => {
        this.translate.use(data[AConst.CLIENT_LANGUAGE]);
        this.commons.setObjectTypes(data[AConst.OBJECT_TYPES]);
      },
      failed => {
        console.warn('Failed loading settings: ' + failed.message);
      }
    );
  }

  hasMainMenu() {
    const noMenuStates = ['home', 'home.primus.full-screen']; // , 'home.primus.annotate'
    return this.loginService.getCurrentUser() && this.stateService.current.name &&
      noMenuStates.indexOf(this.stateService.current.name) === -1;
  }

  registerClick(event) {
    this.uiTools.registerDocumentClick(event);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.uiTools.setWindowSize();
  }

}
