import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications;

  constructor(private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.notifications = this.notificationService.notifications;
  }

  remove(index) {
    this.notificationService.removeNotification(index);
  }

  notType(notification) {
    let res;
    switch (notification.type) {
      case 'info':
        res = 'notification-info';
        break;
      case 'error':
        res = 'notification-error';
        break;
      case 'warning':
        res = 'notification-warning';
        break;
      case 'success':
        res = 'notification-success';
        break;
      default :
        res = 'notification-info';
        console.warn('Unknown notification type: \'' + notification.type + '\'');
    }
    return res;
  }

}
