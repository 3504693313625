import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';

import {SearchHandlerService} from '../../search-handler.service';
import {CommonsService} from '../../../core/commons.service';
import {Focus, SearchFocusService} from '../../search-focus.service';
import {SearchContainer} from '../../search-container';
import {SearchFocusEditDialogComponent} from '../search-focus-edit-dialog/search-focus-edit-dialog.component';
import {UiToolsService} from '../../../core/ui-tools.service';

@Component({
  selector: 'app-search-focus-menu',
  templateUrl: './search-focus-menu.component.html',
  styleUrls: ['./search-focus-menu.component.scss']
})
export class SearchFocusMenuComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;

  focusButtonDropdown = false;
  dialogOpen = false;
  searchQuery: string;
  storeMessage: string;
  showFocusPanel = false;
  showSelectFocusPanel = false;
  displayFocus = [];

  private fsi: Focus;
  private clickListenerId;

  constructor(private modalService: MatDialog,
              private searchHandler: SearchHandlerService,
              private commons: CommonsService,
              private searchFocusService: SearchFocusService,
              private uiTools: UiToolsService) {
  }

  ngOnInit() {
    this.fsi = this.searchFocusService.createFocus(this.searchContainer);
    if (this.fsi.getFocuses()) {
      this.getSetCurrentFocus();
    }
    this.clickListenerId = this.uiTools.addDocumentClickListener(() => {
      this.showFocusPanel = false;
      this.focusButtonDropdown = false;
    });
  }

  ngOnDestroy(): void {
    this.uiTools.removeDocumentClickListener(this.clickListenerId);
  }

  getFocuses() {
    return this.fsi.getFocusesAsArray();
  }

  toggleFocusPanel() {
    this.showFocusPanel = !this.showFocusPanel;
    if (this.showFocusPanel) {
      if (!this.fsi.getCurrentFocusId()) {
        this.setCurrentFocus(this.fsi.createNewFocus());
      }
      this.updateDisplayFocus(null);
      if (this.displayFocus.length > 0) {
        this.showSelectFocusPanel = true;
      }
    }
    this.toggleSettingMenu();
  }

  toggleFocusButtonDropDown() {
    this.focusButtonDropdown = !this.focusButtonDropdown;
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
  }

  clearSearchInput() {
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    this.searchQuery = '';
    this.updateDisplayFocus(this.searchQuery);
  }

  toggleSelectFocusPanel() {
    this.showSelectFocusPanel = !this.showSelectFocusPanel;
    if (this.showSelectFocusPanel) {
      if (!this.displayFocus) {
        if (this.searchQuery === undefined) {
          this.clearSearchInput();
        }
        this.updateDisplayFocus(this.searchQuery);
      }
    }
    this.toggleSettingMenu();
  }


  storeFocusChanges() {
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    const res = this.fsi.storeFocusChanges(this.searchContainer.curFocus);
    if (res === 'ok') {
      this.setStoreMessage('TRANS__FOCUS__STORE_MESSAGE__FOCUS_CHANGES_STORED');
    } else if (res === 'name_exists') {
      this.setStoreMessage('TRANS__FOCUS__STORE_MESSAGE__NAME_EXISTS');
    }
  }

  setFocusAsNewSearch() {
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    this.dialogOpen = true;
    const dialog = this.modalService.open(SearchFocusEditDialogComponent, {panelClass: 'edit-dialog'});
    dialog.componentInstance.fsi = this.fsi;
    dialog.componentInstance.focusInEdit = this.searchContainer.curFocus;
    dialog.afterClosed().subscribe(focusNameQuery => {
      this.dialogOpen = false;
      if (focusNameQuery) {
        if (focusNameQuery) {
          let res;
          if (this.searchContainer.curFocus.name === '' && focusNameQuery) {
            this.searchContainer.curFocus.name = focusNameQuery;
          }
          if (this.searchContainer.curFocus.stored) {
            res = this.fsi.storeFocusAsNewFocus(this.searchContainer.curFocus, focusNameQuery);
          } else {
            res = this.fsi.storeFocusChanges(this.searchContainer.curFocus);
            this.setCurrentFocus(this.searchContainer.curFocus);
          }
          if (res === 'ok') {
            this.setStoreMessage('TRANS__FOCUS__STORE_MESSAGE__FOCUS_STORED');
            this.updateDisplayFocus(null);
            this.selectTheFocus(this.fsi.getCurrentFocus());
          } else if (res === 'name_exists') {
            this.setStoreMessage('TRANS__FOCUS__STORE_MESSAGE__NAME_EXISTS');
          }
          dialog.close();
          this.showFocusPanel = false;
        }
      }
    });
  }

  toggleSettingMenu(focus?) {
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    this.displayFocus.forEach(fo => {
      if (focus) {
        if (fo.id === focus.id) {
          fo.settingMenu = !fo.settingMenu;
        } else {
          fo.settingMenu = false;
        }
      } else {
        fo.settingMenu = false;
      }
      if (fo.id === this.searchContainer.curFocusId) {
        this.searchContainer.curFocus.settingMenu = fo.settingMenu;
      }
    });
  }

  editFocus(focus) {
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    this.dialogOpen = true;
    const dialog = this.modalService.open(SearchFocusEditDialogComponent, {panelClass: 'edit-dialog'});
    dialog.componentInstance.fsi = this.fsi;
    dialog.componentInstance.originalFocus = focus.isDefault;
    dialog.componentInstance.focusInEdit = focus;
    dialog.componentInstance.focusNameQuery = focus.name;
    dialog.afterClosed().subscribe(focusNameQuery => {
      this.dialogOpen = false;
      if (focusNameQuery) {
        if (focusNameQuery) {
          let res;
          if (focus.stored) {
            focus.name = focusNameQuery;
            res = this.fsi.storeFocusChanges(focus);
          }
          if (res === 'ok') {
            this.setStoreMessage('TRANS__FOCUS__STORE_MESSAGE__FOCUS_CHANGES_STORED');
            this.updateDisplayFocus(null);
          } else if (res === 'name_exists') {
            this.setStoreMessage('TRANS__FOCUS__STORE_MESSAGE__NAME_EXISTS');
          }
          this.showFocusPanel = false;
        }
      }
    });
  }

  inputChange() {
    this.updateDisplayFocus(this.searchQuery);
  }

  clickInputField() {
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
  }

  selectFocus(focus) {
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    if (focus) {
      this.setStoreMessage(null);
      this.selectTheFocus(focus);
      // setSelectedFocus();
      if (!this.searchQuery) {
        this.searchQuery = focus.name;
      }
      this.inputChange();
      this.showFocusPanel = false;
    }
    // else {
    // this.selectedFocus is being set to null after store!
    // getSetCurrentFocus();
    // }
  }

  deselectFocus() {
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    if (this.searchContainer.curFocus) {
      this.searchContainer.curFocus = null;
      this.searchContainer.curFocusId = null;
      this.searchContainer.currentFocusName = null;
      this.searchHandler.goPathView(this.searchContainer.path, this.searchContainer);
    }
  }

  hasChanges() {
    return this.fsi.focusHasChanges(this.searchContainer.curFocus);
  }

  delete(focus) {
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    const focusId = focus.id;
    this.fsi.deleteFocus(focusId);
    this.fsi.storeFocuses();
    this.updateDisplayFocus(null);
    this.showFocusPanel = false;
    if (focusId === this.searchContainer.curFocusId) {
      this.searchContainer.curFocusId = null;
    }
    if (this.searchContainer.curFocusId === null) {
      this.searchContainer.curFocus = null;
      this.searchContainer.currentFocusName = null;
      this.searchHandler.goPathView(this.searchContainer.path, this.searchContainer);
    }
  }

  getFocusNameWeighedWordBreak(focus) {
    return this.commons.applyWordBreakOpportunity(focus.$$optionNameWeighed);
  }

  private selectTheFocus(foc) {
    this.setCurrentFocus(foc);
    this.searchHandler.goPathView(foc.homePath, this.searchContainer);
  }

  private getSetCurrentFocus() {
    this.setCurrentFocus(this.fsi.getCurrentFocus());
  }

  private setCurrentFocus(curFocus) {
    this.fsi.setCurrentFocus(curFocus ? curFocus.id : null);
    if (curFocus && this.searchQuery !== undefined) {
      this.searchQuery = curFocus.name;
    }
    this.searchContainer.curFocus = this.commons.copy(curFocus);
  }

  private setStoreMessage(message) {
    this.storeMessage = message;
    setTimeout(() => {
      this.setStoreMessage(null);
    }, 5000);
  }

  private setSelectedFocus() {
    this.displayFocus.forEach((focus, key) => {
      focus.marked = false;
      if (focus.id === this.searchContainer.curFocus.id) {
        this.searchContainer.curFocus.marked = true;
        this.displayFocus[key].marked = true;
        this.commons.each(this.getFocuses(), (focusKey, foc) => {
          if (focusKey === key) {
            foc.marked = true;
          }
        });
      }
    });
  }

  private updateDisplayFocus(query) {
    // Make copy of copies due to weight method setting properties on focuses that causes focuses to be set as changed
    const focusesCopy = this.commons.copy(this.getFocuses());
    this.displayFocus = this.getWeightedAndOrderedOptions(focusesCopy, query);
    this.setSelectedFocus();
  }

  private getWeightedAndOrderedOptions(options, query) {
    let res;
    const wOps = this.getWeightedOptions(options, query);
    res = query ? this.commons.sortArray(wOps, '$$weight') : wOps;
    return res;
  }

  private getWeightedOptions(options, query) {
    const res = [];
    options.forEach((option) => {
      const oWeight = this.weightOption(option, query);
      option.$$optionNameWeighed = oWeight.name;
      option.$$weight = oWeight.weight;
      res.push(option);
    });
    return res;
  }

  private weightOption(option, query) {
    let optionName, start, middle, end, endPos, qPos,
      startPos = 0, weight = 9, find;
    optionName = option.name;
    if (query) {
      find = query.toLocaleUpperCase();
      do {
        qPos = optionName.toLocaleUpperCase().indexOf(find,
          startPos);
        if (qPos !== -1) {
          weight -= qPos === 0 ? 2 : 1;
          endPos = qPos + find.length;
          start = optionName.substring(0, qPos);
          middle = optionName.substring(qPos, endPos);
          end = optionName.substring(endPos);
          optionName = start + '<strong>' + middle +
            '</strong>';
          startPos = optionName.length;
          optionName += end;
        }
      } while (qPos !== -1);
    }
    return {
      name: optionName,
      weight: weight
    };
  }

}
