import {Component, Input, OnInit} from '@angular/core';
import {UiToolsService} from '../../core/ui-tools.service';
import {SearchSelectorService} from '../../core/search-selector.service';
import {SearchHandlerService} from '../search-handler.service';
import {SearchContainer} from '../search-container';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-search-view-full',
  templateUrl: './search-view-full.component.html',
  styleUrls: ['./search-view-full.component.scss']
})
export class SearchViewFullComponent implements OnInit {
  @Input() searchContainer: SearchContainer;
  @Input() searchPlaceholder: string;
  @Input() title: string;

  AConst = AConst;
  actionMenus: Array<any> = [];
  mediumScreen: boolean;
  sideMenuSmallScreen = false;

  private unRegWindowSizeWatch;

  constructor(private uiTools: UiToolsService,
              private selectorHandler: SearchSelectorService,
              private searchHandler: SearchHandlerService) {
  }

  ngOnInit() {
    if (this.searchContainer) {
      this.searchHandler.subscribeToSearchResult(this.searchContainer, this.setActionMenus);
    }
    this.mediumScreen = this.uiTools.setMediumScreenSizeOnLoad(this.mediumScreen);
    this.unRegWindowSizeWatch = this.uiTools.addWindowSizeListener(newVal => {
        this.mediumScreen = newVal.width < 1025;
      }
    );
  }
  toggleSideMenuSmallScreen() {
    this.sideMenuSmallScreen = !this.sideMenuSmallScreen;
  }

  private setActionMenus = () => {
    this.actionMenus = this.searchHandler.getCurrentActionMenus(this.searchContainer);
    const menuSections = this.actionMenus[AConst.SECTION_MENUS];

    if (!menuSections) {
      this.actionMenus['title'] = 'TRANS__VALIDATION__NO_MENU_ACTIONS_FOR_SELECTION';
    }
  }
}
