import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-thumbnail-stack',
  templateUrl: './thumbnail-stack.component.html',
  styleUrls: ['./thumbnail-stack.component.scss']
})
export class ThumbnailStackComponent implements OnInit {
  thumbWidth = null;
  thumbSize = {small: 'Liten', medium: 'Medium', large: 'Stor'};
  thumbSizeKeys;
  invalidSrc = {};
  @Input() images;
  @Input() imgIndex;
  @Input() isEditing;
  @Input() imageGallery;
  @Output() setActiveImg = new EventEmitter<object>();
  @Output() toggleChangeImageOrder = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.thumbSizeKeys = Object.keys(this.thumbSize);
    if (this.thumbWidth === null) {
      this.thumbWidth = 'medium';
    }
  }

  setImage(index) {
    this.imgIndex = index;
    this.setActiveImg.emit(this.imgIndex);
  }

  setImgSize(size) {
    this.thumbWidth = size;
  }

  setInvalidSrc(index) {
    this.invalidSrc[index] = true;
  }

  changeImageOrder() {
    this.toggleChangeImageOrder.emit();
  }
}
