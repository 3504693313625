import {Component, Input, OnChanges} from '@angular/core';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-search-tile-overview-fields',
  templateUrl: './search-tile-overview-fields.component.html',
  styleUrls: ['./search-tile-overview-fields.component.scss']
})
export class SearchTileOverviewFieldsComponent implements OnChanges {

  @Input() searchObject: any;
  @Input() viewName: string;
  @Input() disableLinks: boolean;

  AConst = AConst;
  fields = [];

  constructor() {
  }

  ngOnChanges(): void {
    if (this.searchObject) {
      this.fields = this.searchObject['$$overview'];
    }
  }

}
