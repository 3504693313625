import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {SectionsContainer} from '../../core/sections-container';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-edit-field-precision-date',
  templateUrl: './edit-field-precision-date.component.html',
  styleUrls: ['./edit-field-precision-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldPrecisionDateComponent)
    }]
})
export class EditFieldPrecisionDateComponent implements OnInit, ControlValueAccessor {

  @Input() sectionsContainer: SectionsContainer;
  @Input() object;
  @Input() field;
  @Input() formControlName;
  @Input() placeholder;

  inFocus = false;
  AConst = AConst;


  constructor() {
  }

  ngOnInit() {
  }

  registerOnChange(fn: any): void {
    console.log('Register on change: edit precision date');
  }

  registerOnTouched(fn: any): void {
    console.log('Register on touch: edit precision date');
  }

  setDisabledState(isDisabled: boolean): void {
    console.log('Set disabled state: edit precision date');
  }

  writeValue(obj: any): void {
    console.log('Write value: edit precision date: ' + obj);
  }

}
