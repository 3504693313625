import {Injectable} from '@angular/core';
import {UserSettingsService} from '../core/user-settings.service';
import {CommonsService} from '../core/commons.service';
import {AConst} from '../core/a-const.enum';
import {SearchContainer} from './search-container';

export class Focus {
  focusParamKeys = ['path', 'query', 'checkedFilters', 'order', 'searchResultViewName', 'templateGroupId'];


  constructor(private searchContainer: SearchContainer,
              private commons: CommonsService,
              private userSettings: UserSettingsService) {
  }

  getFocuses() {
    return this.searchContainer.focuses;
  }

  getFocusesAsArray() {
    const res = [];
    this.commons.each(this.getFocuses(), (key, focus) => {
      if (focus.name) {
        res.push(focus);
      }
    });
    return res;
  }

  getFocus(focusId) {
    let fid, res = null;
    fid = this.getActualFocusId(focusId);
    if (fid) {
      res = this.getFocuses()[fid];
    }
    return res;
  }

  deleteFocus(focusId) {
    let fid;
    if (focusId === this.getCurrentFocusId()) {
      this.setCurrentFocus(null);
    }
    fid = this.getActualFocusId(focusId);
    if (fid) {
      delete this.getFocuses()[fid];
    }
  }

  getSetFocuses() {
    return new Promise((resolve) => {
      this.userSettings.getFocusOptions().then((data) => {
        this.searchContainer.focuses = data;
        resolve(data);
      });
    });
  }

  getCurrentFocusId() {
    return this.searchContainer.curFocusId;
  }

  setCurrentFocus(focusId) {
    const focus = this.getFocus(focusId);
    if (focus && focus.stored) {
      this.setCurrentFocusId(focusId);
    }
    return focus;
  }

  getDefaultCurrentFocus() {
    if (!this.getCurrentFocusId()) {
      this.commons.each(this.getFocuses(), (key, focus) => {
        if (focus.isDefault) {
          this.setCurrentFocus(focus.id);
        }
      });
    }
    return this.getCurrentFocus();
  }

  getCurrentFocus() {
    let res = null;
    if (this.getCurrentFocusId()) {
      res = this.getFocus(this.getCurrentFocusId());
    }
    return res;
  }

  storeFocusChanges(focus) {
    let res = 'ok';
    if (focus) {
      if (this.checkNameExists(focus)) {
        res = 'name_exists';
      }

      if (res === 'ok') {
        focus.pathParams[this.searchContainer.path] = this.getFocusPathParamsFromSearchContainer();
        focus.stored = true;
        this.addFocus(focus);
        this.deleteUnnamedFocuses();
        this.userSettings.storeFocusOptions(this.getFocuses());
      }
    }
    return res;
  }

  checkPathParamsExists(path, searchContainer) {
    let res = false;
    if (searchContainer.curFocusId) {
      this.commons.each(searchContainer.curFocus.pathParams, (key, focus) => {
        if (focus && focus.path === path) {
          res = true;
        }
      });
    }
    return res;
  }

  storeFocusAsNewFocus(focus, newName) {
    const res = 'ok';
    let newFocus, oldFocus;
    if (focus) {
      oldFocus = this.getFocuses()[focus.id];
      newFocus = this.commons.copy(focus);
      newFocus.id = this.commons.uuid();
      newFocus.name = newName;
      if (focus.isDefault) {
        this.getFocuses()[focus.id].isDefault = false;
      }
      if (focus.id === oldFocus.id) {
        this.getFocuses()[oldFocus.id].pathParams[this.searchContainer.path] = this.commons.copy(
          focus.pathParams[this.searchContainer.path]);
      }
      newFocus.pathParams[this.searchContainer.path] = this.getFocusPathParamsFromSearchContainer(newFocus.path);
      this.addFocus(newFocus);
      this.setCurrentFocus(newFocus.id);
      this.deleteUnnamedFocuses();
      this.userSettings.storeFocusOptions(this.getFocuses());
      return res;
    }
  }

  storeFocuses() {
    this.userSettings.storeFocusOptions(this.getFocuses());
  }

  toggleFocusIsDefault(fo) {
    this.commons.each(this.getFocuses(), (key, focus) => {
      if (focus.id !== fo.id) {
        focus.isDefault = false;
      }
    });
  }

  createNewFocus() {
    const focus = {
      id: this.commons.uuid(),
      name: '',
      isDefault: false,
      marked: true,
      homePath: this.searchContainer.path,
      pathParams: {},
      stored: false
    };
    focus.pathParams[this.searchContainer.path] = null;
    this.deleteUnnamedFocuses();
    this.addFocus(focus);
    return focus;
  }

  getSetFocusPathParams(focusId, path) {
    const focus = this.getFocus(focusId);
    let pathParams;
    if (focus && focus.pathParams) {
      pathParams = focus.pathParams[path];
      if (!pathParams) {
        if (path.indexOf(focus.homePath) === 0) {
          pathParams = this.createFocusPathParams(focus, path);
        }
      }
    }
    if (!pathParams) {
      this.setCurrentFocusId(null);
    }
    return pathParams;
  }

  setFocusPathParamsOnSearchContainer(pathParams) {
    this.focusParamKeys.forEach((paramKey) => {
      this.searchContainer[paramKey] = pathParams[paramKey];
    });
  }

  focusHasChanges(focus) {
    let changed = !this.commons.equals(focus, this.getFocus(focus.id));
    let searchPathParams, focusPathParams;
    if (!changed) {
      searchPathParams = this.getFocusPathParamsFromSearchContainer();
      focusPathParams = focus.pathParams[this.searchContainer.path];
      changed = !this.commons.equals(searchPathParams, focusPathParams);
    }
    return changed;
  }

  focusHasNewName(focus) {
    return focus.name !== this.getFocus(focus.id).name;
  }

  private getActualFocusId(focusId) {
    let fid;
    if (!focusId) {
      return null;
    }
    if (this.getFocuses()[focusId]) {
      fid = focusId;
    } else {
      this.commons.each(this.getFocuses(), (key, focus) => {
        if (focus.id === focusId) {
          fid = key;
        }
      });
      if (!fid) {
        console.warn('Unable to find focus width id ' + focusId);
      }
    }
    return fid;
  }

  private addFocus(focus) {
    this.getFocuses()[focus.id] = focus;
  }

  private setCurrentFocusId(focusId) {
    this.searchContainer.curFocusId = focusId;
  }

  private checkNameExists(focus) {
    let res = false, t, f;
    const focuses = this.getFocusesAsArray();
    for (t = 0; t < focuses.length; t++) {
      f = focuses[t];
      if (f.name.trim() === focus.name.trim() &&
        f.id !== focus.id) {
        res = true;
        break;
      }
    }
    return res;
  }

  private deleteUnnamedFocuses() {
    this.commons.each(this.getFocuses(), (key, focus) => {
      if (!focus.name) {
        this.deleteFocus(key);
      }
    });
  }

  private getFocusPathParamsFromSearchContainer(path?) {
    const res = {};
    this.focusParamKeys.forEach((paramKey) => {
      let value = this.searchContainer[paramKey];
      if (value !== undefined) {
        value = this.commons.copy(value, {ignoreEmptyArray: true});
        if (!path || paramKey !== 'checkedFilters') {
          res[paramKey] = value;
        } else {
          res[paramKey] = this.getValidCheckedFilters(value, path);
        }
      }
    });
    return res;
  }

  // Remove filters that are not relevant for the path view, e.g.
  // "object_type" if going from "home/artifacts" to "home/artifacts/design"
  private getValidCheckedFilters(filters, path) {
    const validFilters = {};
    let filterGroups;
    const pathViews = this.commons.filter(this.searchContainer.searchView.paths, {path_name: path});
    if (pathViews && pathViews.length > 0) {
      filterGroups = pathViews[0][AConst.SEARCH_VIEW][AConst.CHECK_FILTER_GROUPS];

      filterGroups.forEach((filterGroup) => {
        filterGroup.filters.forEach((filter) => {
          validFilters[filter.name] = true;
        });
      });

      if (Array.isArray(filters)) {
        filters.forEach((value, filterName) => {
          if (!validFilters[filterName]) {
            delete filters[filterName];
          }
        });
      }
    }
    return filters;
  }

  private createFocusPathParams(focus, path) {
    focus.pathParams[path] = this.getFocusPathParamsFromSearchContainer(path);
    focus.pathParams[path].path = path;
    return focus.pathParams[path];
  }
}

@Injectable({
  providedIn: 'root'
})
export class SearchFocusService {

  constructor(private userSettings: UserSettingsService,
              private commons: CommonsService) {
  }

  createFocus(searchContainer: SearchContainer): Focus {
    return new Focus(searchContainer, this.commons, this.userSettings);
  }

}
