import {Component, Input, OnInit} from '@angular/core';
import {MediaHelperService} from '../../core/media-helper.service';

@Component({
  selector: 'app-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss']
})
export class ThumbnailComponent implements OnInit {
  imgClass;
  imgUrl;
  @Input() item;
  @Input() viewMode;
  @Input() imageIdField;
  @Input() stylingClass: string;

  constructor(private mediaHelper: MediaHelperService) {
  }

  ngOnInit() {
    let thumb_id;
    if (!this.imageIdField || this.imageIdField === '0') {
      this.imageIdField = 'thumbnail_id';
    }
    if (this.item !== undefined) {
      thumb_id = this.item[this.imageIdField];
    }
    if (typeof thumb_id !== 'undefined') {
      this.imgUrl = this.mediaHelper.getThumbUrl(this.item, this.imageIdField);
      if (this.viewMode && this.viewMode === 'thumbnail') {
        this.imgClass = 'searchThumbnailBig';
      } else {
        this.imgClass = 'searchThumbnail';
      }
    } else {
      if (this.viewMode && this.viewMode === 'thumbnail') {
        this.imgClass = 'imgPlaceholderBig';
      } else {
        this.imgClass = 'imgPlaceholder';
      }
    }
  }

}
