import {Injectable} from '@angular/core';
import {AConst} from './a-const.enum';
import {CommonsService} from './commons.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectRestrictionService {

  constructor(private commons: CommonsService) {
  }

  isObjectReadOnly(obj): Promise<boolean> {
    return new Promise(resolve => {
      this.commons.getUserData(true).then(user => {
        let res = false;
        if (obj && obj[AConst.$$ACL] && obj[AConst.$$ACL][AConst.RESTRICTIONS]) {
          obj[AConst.$$ACL][AConst.RESTRICTIONS].forEach((rest) => {
            // Hack to allow users to edit their own e-mail and password info
            if (rest.name === 'put_artifact') {
              if (!(obj[AConst.OBJECT_TYPE] === 'user' && obj[AConst.ARTIFACT_ID] === user[AConst.ARTIFACT_ID])) {
                res = true;
              }
            }
          });
        }
        resolve(res);
      });
    });
  }

}
