import {Injectable} from '@angular/core';
import {AConst} from './a-const.enum';
import {TranslateService} from '@ngx-translate/core';
import {ObjectFieldTraverseService} from './object-field-traverse.service';

@Injectable({
  providedIn: 'root'
})
export class FieldStateService {

  constructor(private objectFieldTraverse: ObjectFieldTraverseService,
              private translate: TranslateService) {
  }

  isEditable(rootObject, object, field, userData): boolean {
    let res = false, edit;
    let createdById = rootObject[AConst.CREATED_BY_ID];
    if (!createdById && object) {
      createdById = object[AConst.CREATED_BY_ID];
    }
    const userId = userData[AConst.ARTIFACT_ID];

    edit = field.edit;
    switch (edit) {
      case undefined:
      case null:
      case 'no_edit':
        res = false;
        break;
      case 'edit':
        res = true;
        break;
      case 'edit_owner':
        res = !createdById || createdById === userId;
        break;
      case 'edit_once':
        res = !createdById || object.$$justAdded;
        break;
      default:
        console.warn('Unable to handle edit type ' + edit);

    }
    return res;
  }

  getFieldInputType(field, rootObject, object, userData, isCopy): string {
    let res;
    const editable = this.isEditable(rootObject, object, field, userData);
    if (editable) {
      switch (field[AConst.INPUT_TYPE]) {
        case 'map_id':
        case AConst.REF_ARRAY:
        case AConst.REF_DYNAMIC_ARRAY:
          res = 'map_id';
          break;
        case AConst.TEXT_AREA:
          res = AConst.TEXT_AREA;
          break;
        case 'select':
          res = 'select';
          break;
        case 'input':
          res = 'text';
          break;
        case AConst.PRECISION_DATE:
          const inline = field[AConst.INLINE];
          if (inline[AConst.TO_DATE_FIELD]) {
            res = AConst.PRECISION_DATE_RANGE;
          } else if (inline[AConst.IS_TO_DATE_FIELD]) {
            res = null;
          } else {
            res = AConst.PRECISION_DATE;
          }
          break;
        case AConst.IDENTIFIER:
          res = AConst.IDENTIFIER;
          break;
        case AConst.IDENTIFIER_NO_GEN_BUTTON:
          res = 'text';
          break;
        case 'number':
          res = 'number';
          break;
        case 'checkbox':
          res = 'checkbox';
          break;
        case AConst.CHECK_ARRAY:
          res = AConst.CHECK_ARRAY;
          break;
        case 'radio':
          res = 'radio';
          break;
        case AConst.SEARCH_SELECTOR:
          res = AConst.SEARCH_SELECTOR;
          break;
        case AConst.SEARCH_SELECTOR_MULTIPLE:
          res = AConst.SEARCH_SELECTOR_MULTIPLE;
          break;
        case 'password':
          res = 'password';
          break;
        case 'image':
          res = 'image';
          break;
        case AConst.OBJECT_REFERENCE:
          // Must not display this field if in "copy mode", thus setting input type to null
          res = !isCopy ? AConst.OBJECT_REFERENCE : null;
          break;
        case AConst.SPECIFIC_ARRAY_ELEMENTS:
          res = AConst.SPECIFIC_ARRAY_ELEMENTS;
          break;
        default:
          console.log('No known type: ' + field[AConst.INPUT_TYPE]);
          res = 'text';
      }
    } else {
      res = 'display';
    }
    return res;
  }

  getFieldTitle(field, rootObject) {
    let title = field[AConst.FIELD_TITLE] || field[AConst.ADMIN_TITLE];
    if (!title) {
      const traverseRes = this.objectFieldTraverse.traverseObjectByPath(rootObject, field.path, 1);
      if (traverseRes.subObject) {
        const metaField = traverseRes.subObject[AConst.$$META][traverseRes.fieldName];
        title = metaField[AConst.FIELD_TITLE] || metaField[AConst.ADMIN_TITLE];
      }
    }
    if (Array.isArray(title)) {
      title = title[0];
    }
    if (title) {
      title = this.translate.instant(title);
    } else {
      console.warn('Could not get title from field ' + field.name);
    }
    return title || field.name;
  }

  getSubObject(rootObject, field, index, parentIndex) {
    let fieldPath;
    if (index !== undefined) {
      fieldPath = this.getFieldKeyWhileDrawingInputs(field, index, parentIndex);
    } else {
      fieldPath = this.getFieldKeyWhileDrawingInputs(field, parentIndex);
    }
    const traverseRes = this.objectFieldTraverse.traverseObjectByPath(rootObject, fieldPath, 1);
    return traverseRes.subObject;
  }

  getParentSubObject(rootObject, field, index, parentIndex) {
    let fieldPath;
    if (index !== undefined) {
      fieldPath = this.getFieldKeyWhileDrawingInputs(field, index, parentIndex);
    } else {
      fieldPath = this.getFieldKeyWhileDrawingInputs(field, parentIndex);
    }
    return this.objectFieldTraverse.traverseObjectByPath(rootObject, fieldPath, 1);
  }

  // This is method must be used for getting field key during generation of field inputs as the indexes
  // are generated differently than getting field keys when generating form controls
  getFieldKeyWhileDrawingInputs(field, index?, parentIndex?) {
    let fieldKey;
    if (field) {
      if (field.key) {
        if (index === undefined) {
          fieldKey = field.key;
        } else if (parentIndex === undefined) {
          fieldKey = this.getFieldKey(field, index);
        } else {
          fieldKey = this.getFieldKey(field, parentIndex, index);
        }
      } else {
        fieldKey = field.name;
        if (field.path) {
          fieldKey = field.path + '.' + field.name;
        }
      }
    } else {
      console.warn('Field is missing!');
    }
    return fieldKey;
  }

  // Get a field key in which inline array index names are replaced with the actual indexes
  getFieldKey(field, index1?, index2?, fieldName?) {
    let fieldKey = field.key;
    if (!fieldKey) {
      throw new Error('Key not generated for field ' + field.name);
    }
    if (index1 !== undefined) {
      fieldKey = fieldKey.replace('{index1}', index1);
      if (index2 !== undefined) {
        fieldKey = fieldKey.replace('{index2}', index2);
      }
    } else if (index2 !== undefined) {
      fieldKey = fieldKey.replace('{index1}', index2);
    }
    if (fieldKey.indexOf('{') !== -1) {
      console.warn('Key not properly generated: ' + fieldKey + ' index1: ' + index1 + ' index2: ' + index2 + ' key ' + field.key);
    }
    if (fieldName) {
      const parentKey = this.getKeysParentKey(fieldKey);
      if (parentKey) {
        fieldKey = parentKey + '.' + fieldName;
      } else {
        fieldKey = fieldName;
      }
    }
    return fieldKey;
  }

  // Recursively generate field key based on parent field keys
  generateFieldKey(field, parentKey: string): string {
    let res = field.key;
    if (!res) {
      res = field.name;
      const parentName = field[AConst.PARENT_NAME];
      if (parentName && (!parentKey || !this.parentNameExists(parentKey, parentName))) {
        res = field[AConst.PARENT_NAME] + '.' + res;
      }
      if (parentKey) {
        res = parentKey + '.' + res;
      }
      field.key = res;
    }
    return res;
  }

  getKeysParentKey(key: string) {
    const lastDot = key.lastIndexOf('.');
    let res = '';
    if (lastDot !== -1) {
      res = key.substring(0, lastDot);
    }
    return res;
  }

  /**
   * Checks whether a field is an "edit once" field and if so, returns "true" if is has a value.
   * @param field, field meta data
   * @param object, the object containing field data
   */
  checkEditOnce(field, object) {
    let res = false;
    if (field[AConst.EDIT] === AConst.EDIT_ONCE) {
      res = object[field.name] !== null && object[field.name] !== undefined;
    }
    return res;
  }

  private parentNameExists(parentKey, parentName) {
    let res = false;
    const existsIndex = parentKey.indexOf(parentName);
    if (existsIndex !== -1) {
      if (existsIndex === 0) {
        res = true;
      } else if (parentKey.endsWith(parentName)) {
        res = true;
      } else if (parentKey.indexOf('.' + parentName + '.') !== -1) {
        res = true;
      } else if (parentKey.indexOf('.' + parentName + '[') !== -1) {
        res = true;
      }
    }
    return res;
  }

}
