import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {CmsApiService} from '../../core/cms-api.service';
import {CommonsService} from '../../core/commons.service';
import {ContentListSource} from '../content-list-source';

@Component({
  selector: 'app-content-list-source-array',
  templateUrl: './content-list-source-array.component.html',
  styleUrls: ['./content-list-source-array.component.scss']
})
export class ContentListSourceArrayComponent implements OnChanges {

  @Input() contentListSource: ContentListSource;
  @Output() artifactUpdated = new EventEmitter<boolean>();

  AConst = AConst;
  searchingForThumbnails = false;

  constructor(private cms: CmsApiService, private commons: CommonsService) {
  }

  ngOnChanges() {
    this.setItems();
  }

  get pageItems() {
    let res = [];
    if (this.contentListSource.displayAll) {
      res = this.contentListSource.items;
    } else {
      this.loopItems(item => res.push(item));
    }
    this.setThumbnailIds(res);
    return res;
  }

  private setItems() {
    const objectIds = [];
    this.contentListSource.items = [];
    if (this.contentListSource && this.contentListSource.array && this.contentListSource.array.length) {
      this.contentListSource.array.forEach(item => {
        const meta = item[AConst.$$META];
        if (meta) {
          const fields = [];
          for (const key in meta) {
            if (meta.hasOwnProperty(key)) {
              const field = meta[key];
              if (field[AConst.DISPLAY] && field[AConst.DISPLAY].show === 'yes') {
                let value = item[key];
                if (field[AConst.FIELD_TYPE] === 'map_id' && item[key + '_value']) {
                  value = item[key + '_value'];
                }
                value = value || '-';
                fields.push({title: field[AConst.TITLE] || field[AConst.ADMIN_TITLE], value: value, order: field[AConst.SUB_ORDER]});
              }
            }
          }
          const objectId = item[this.contentListSource.mainField];
          objectIds.push(objectId);
          this.contentListSource.items.push({fields: this.commons.sortArray(fields, 'order'), object: {artifact_id: objectId}});
        }
      });
    }
  }

  private setThumbnailIds(items) {
    if (items.length && items[0].object[AConst.THUMBNAIL_ID] === undefined && !this.searchingForThumbnails) {
      this.searchingForThumbnails = true;
      const objectIds = items.map(item => item.object[AConst.ARTIFACT_ID]);
      this.cms.searchJson({
        filters: {artifact_id: objectIds},
        fl: [AConst.ARTIFACT_ID, AConst.THUMBNAIL_ID],
        rows: objectIds.length
      }).then(res => {
        this.searchingForThumbnails = false;
        const objects = res[AConst.ARTIFACTS];
        if (objects) {
          objects.forEach(object => {
            this.loopItems(item => {
              if (item.object[AConst.ARTIFACT_ID] === object[AConst.ARTIFACT_ID]) {
                item.object[AConst.THUMBNAIL_ID] = object[AConst.THUMBNAIL_ID] || null;
              }
            });
          });
        } else {
          console.error('Search failed: ' + res[AConst.STATUS_MSG]);
        }
      });
    }
  }

  private loopItems(callback) {
    let loopStart;
    let loopEnd;
    if (this.contentListSource.displayAll) {
      loopStart = 0;
      loopEnd = this.contentListSource.items.length;
    } else {
      loopStart = (this.contentListSource.page - 1) * this.contentListSource.maxRows;
      loopEnd = Math.min(loopStart + this.contentListSource.maxRows, this.contentListSource.items.length);
    }
    for (let t = loopStart ; t < loopEnd ; t++) {
      callback(this.contentListSource.items[t]);
    }
  }
}
