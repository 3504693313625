import {Component, EventEmitter, Input, Output} from '@angular/core';
import {HierarchyRow} from '../edit-field-select-hierarchy/edit-field-select-hierarchy.component';
import {HierarchicNode} from "../hierarchic-node";

@Component({
  selector: 'app-edit-field-select-hierarchy-nodes',
  templateUrl: './edit-field-select-hierarchy-nodes.component.html',
  styleUrls: ['./edit-field-select-hierarchy-nodes.component.scss']
})
export class EditFieldSelectHierarchyNodesComponent {
  @Input() nodes: HierarchicNode[];
  @Input() selectedOptionId;
  @Input() selectedRow: HierarchyRow;
  @Input() maxRows;
  @Input() rootNodeIndex;
  @Output() childSelected = new EventEmitter<object>();
  @Output() childUnselected = new EventEmitter<object>();
  @Output() setMaxRow = new EventEmitter<object>();

  getRootIndex(node, i) {
    if (node.option.level === 1) {
      this.rootNodeIndex = i;
      return i;
    } else {
      return this.rootNodeIndex;
    }
  }

  onChildSelected(child) {
    this.childSelected.emit(child);
  }

  onChildUnselected(child) {
    this.childUnselected.emit(child);
  }

  onsetMaxRow(maxRow) {
    this.setMaxRow.emit(maxRow);
  }

}
