import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FieldValueService} from '../../core/field-value.service';
import {AConst} from '../../core/a-const.enum';
import {CmsApiService} from '../../core/cms-api.service';
import {DragulaService} from 'ng2-dragula';
import {CommonsService} from '../../core/commons.service';
import {ProgressDialogComponent} from '../../shared/progress-dialog/progress-dialog.component';
import {ObjectStorageService} from '../../core/object-storage.service';
import {UiToolsService} from '../../core/ui-tools.service';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-change-image-order',
  templateUrl: './change-image-order.component.html',
  styleUrls: ['./change-image-order.component.scss']
})
export class ChangeImageOrderComponent implements OnInit, OnDestroy {

  AConst = AConst;
  optionsFound;
  changes = false;
  modalRef;
  lastCodeMenu;
  currentCodeMenu;
  @Input() images;
  @Input() art;
  @Output() toggleChangeImageOrder = new EventEmitter<object>();
  @ViewChild('imageContainer', { static: true }) imageContainer: ElementRef;

  private clickListenerId;

  constructor(private fieldValueService: FieldValueService,
              private cms: CmsApiService,
              private dragService: DragulaService,
              private commons: CommonsService,
              private modalService: MatDialog,
              private objectStorage: ObjectStorageService,
              private uiTools: UiToolsService) {

    dragService.dropModel().subscribe((value) => {
      this.updateOrder(value);
    });
    dragService.drag().subscribe((value) => {
      document.onmousemove = e => {
        const elementHeight = value.el.getBoundingClientRect().height;
        if (value.source.parentElement.clientHeight <
          (this.images.length / 3) * elementHeight) {
          const event = e || window.event;
          const mouseY = event['pageY'] - this.imageContainer.nativeElement.offsetTop;
          const scrollTop = this.imageContainer.nativeElement.scrollTop;
          const scrollBottom = this.imageContainer.nativeElement.offsetHeight - scrollTop;

          if (mouseY - elementHeight / 2 < scrollTop) {
            this.imageContainer.nativeElement.scrollBy(0, -15);
          } else if (mouseY + elementHeight > scrollBottom) {
            this.imageContainer.nativeElement.scrollBy(0, 15);
          }
        }
      };
    });

    dragService.dragend().subscribe(() => {
      document.onmousemove = null;
    });
  }

  private static copyItem(origItem, newItem) {
    let key;
    for (key in origItem) {
      if (!origItem.hasOwnProperty(key)) {
        continue;
      }
      newItem[key] = origItem[key];
    }
  }

  ngOnInit() {
    const orderField = 'order_number';
    this.getOptions().then(data => {
      this.optionsFound = data;
    });
    this.setImageProp().then((images) => {
      this.images = images;
      this.commons.sortArray(this.images, orderField);
    });

    this.clickListenerId = this.uiTools.addDocumentClickListener(() => {
      if (this.currentCodeMenu && this.currentCodeMenu.$$selected) {
        this.currentCodeMenu.$$selected = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.uiTools.removeDocumentClickListener(this.clickListenerId);
  }

  setCodeValue() {
    return new Promise(resolve => {
      this.images.forEach((image, index) => {
        image.$$selected = false;
        this.art.images.forEach((artImage) => {
          if (image[AConst.ARTIFACT_ID] === artImage[AConst.IMAGE_ID]) {
            this.images[index].code = artImage.code;
            this.images[index].code_value = artImage.code_value;
            this.images[index].order_number = artImage.order_number;
          }
        });
      });
      resolve(this.images);
    });
  }

  setImageProp() {
    return new Promise(resolve => {
      this.setCodeValue().then((result) => {
        resolve(result);
      });
    });
  }

  getOptions() {
    return new Promise((resolve) => {
      this.cms.searchJson({
        filters: {
          object_type: 'ct_92'
        }
      }).then(
        (res) => {
          if (res[AConst.ARTIFACTS]) {
            resolve(res[AConst.ARTIFACTS]);
          }
        },
        (response) => {
          console.log(response.message, response.status);
        });
    });
  }

  toggleDropDown(image) {
    let finishedLoopingArray, resetSelectedImages;
    if (this.lastCodeMenu && this.lastCodeMenu !== image) {
      resetSelectedImages = this.setSelectedImageValue(null);
      if (resetSelectedImages) {
        finishedLoopingArray = this.setSelectedImageValue(image);
      }
    } else {
      finishedLoopingArray = this.setSelectedImageValue(image);
    }
    if (finishedLoopingArray) {
      this.currentCodeMenu = image;
      this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    }
    this.lastCodeMenu = image;
  }

  setSelectedImageValue(image?) {
    let lastIndex = 0, endOfArray = false;
    this.images.forEach((img, index) => {
      if (image && image[AConst.ARTIFACT_ID] === img[AConst.ARTIFACT_ID]) {
        image.$$selected = !image.$$selected;
      } else {
        this.images[index].$$selected = false;
      }
      lastIndex = index + 1;
    });
    if (this.images.length === lastIndex) {
      endOfArray = true;
    }
    return endOfArray;
  }

  selectOption(option, image) {
    this.images.forEach((img, index) => {
      if (image[AConst.ARTIFACT_ID] === img[AConst.ARTIFACT_ID]) {
        this.images[index].code = option.id;
        this.images[index].code_value = option[AConst.ARTIFACT_NAME];
      }
    });
    this.changes = true;
  }

  updateOrder(value) {
    const orderField = 'order_number';
    const index = value.sourceIndex;
    this.moveItem(this.images, index, this.images[value.sourceIndex]).then(
      () => {
        this.resetOrder(this.images, orderField);

      });
  }

  moveItem(arr, index, item) {
    return new Promise((resolve) => {
      const orderField = 'order_number';
      arr.forEach((i, arrIndex) => {
        if (index !== arrIndex &&
          i[orderField] === item[orderField]) {
          // Copy properties from item to array item.
          // This ensures that "hidden" properties, like
          // "$$meta" is copied over from item
          ChangeImageOrderComponent.copyItem(item, i);
        }
      });

      arr.splice(index, 1);
      this.changes = true;
      resolve();
    });
  }

  resetOrder(arr, orderField) {
    let order = 0;
    arr.forEach((i) => {
      i[orderField] = order;
      order++;
    });
  }

  setArtifactProps(artifactArray) {
    return new Promise((resolve) => {
      this.images.forEach((image) => {
        artifactArray.forEach((artifactImg, arrIndex) => {
          if (artifactImg[AConst.IMAGE_ID] === image[AConst.ARTIFACT_ID]) {
            artifactArray[arrIndex].code = image.code;
            artifactArray[arrIndex].code_value = image.code_value;
            artifactArray[arrIndex].order_number = image.order_number;
          }
        });
      });
      this.commons.sortArray(artifactArray, 'order_number');
      resolve(artifactArray);
    });
  }

  saveChanges() {
    this.setArtifactProps(this.art.images).then((images) => {
      this.art.images = images;
      this.loopAndStoreImageItems(this.art.images);
    });
  }

  loopAndStoreImageItems(arr) {
    this.modalRef = this.modalService.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
    let i = 0;
    arr.forEach((image) => {
      i++;
      this.storeImageItem(image, i);
    });
  }

  storeImageItem(image, i) {
    if (this.changes) {
      this.objectStorage.storeObject(image).then(img => {
        image.image_id = img[AConst.ARTIFACT_ID];
        if (this.art.images.length === i) {
          this.modalRef.close();
          this.closeAndUpdate(this.images);
        }
      }).catch(reason => {
        console.log('Store failed with message: ' + reason.error.message);
        this.modalRef.close();
      });
    }
  }

  closeAndUpdate(images) {
    this.toggleChangeImageOrder.emit(images);
  }

  closeAndReset() {
    this.toggleChangeImageOrder.emit();
  }
}
