import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SectionsContainer} from '../../core/sections-container';

@Component({
  selector: 'app-object-edit',
  templateUrl: './object-edit.component.html',
  styleUrls: ['./object-edit.component.scss']
})
export class ObjectEditComponent implements OnInit {

  @Input() sectionsContainer: SectionsContainer;
  @Output() closeEdit = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  onSubmit() {
    console.log('On submit has been called');
  }

  onCloseEdit() {
    this.closeEdit.emit();
  }

}
