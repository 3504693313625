import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {InlineArrayItemService} from '../../../core/inline-array-item.service';
import {SectionsContainer} from '../../../core/sections-container';
import {FieldParameters} from '../../../core/field-parameters';

@Component({
  selector: 'app-edit-field-inline-array-add-button',
  templateUrl: './edit-field-inline-array-add-button.component.html',
  styleUrls: ['./edit-field-inline-array-add-button.component.scss']
})
export class EditFieldInlineArrayAddButtonComponent implements OnInit {

  @Input() sectionsContainer: SectionsContainer;
  @Input() field;
  @Input() object;
  @Input() parentIndex;

  AConst = AConst;
  addItemNotifications = [];
  private fieldParameters: FieldParameters;

  constructor(private inlineArrayItemSvc: InlineArrayItemService) {
  }

  ngOnInit() {
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters.field = this.field;
    this.fieldParameters.object = this.object;
    this.fieldParameters.parentIndex = this.parentIndex;
  }

  addItem() {
    this.inlineArrayItemSvc.addInlineArrayItemToForm(this.fieldParameters).then((/*item*/) => {
      // Do nothing for now
    });
  }

  canAddItem() {
    const inlineList = this.field.inline[AConst.INLINE_LIST];
    let res = true;
    if (inlineList) {
      let fixed, maxLength, overMax = false;
      const arr = this.object[this.field.name];
      if (arr === undefined) {
        throw new Error('Could not find array named ' + this.field.name);
      }
      const listLength = arr.length;
      fixed = inlineList[AConst.FIXED];
      maxLength = inlineList[AConst.MAX_LENGTH];
      if (maxLength) {
        overMax = listLength >= maxLength;
      }
      res = !fixed && !overMax;
      const notifications = inlineList[AConst.INDEX_NOTIFICATIONS];
      if (notifications) {
        notifications.forEach(indexNot => {
          if (listLength >= indexNot.index && !this.addItemNotifications[indexNot.index]) {
            this.addItemNotifications[indexNot.index] = indexNot;
          }
        });
      }
    }
    return res;
  }


}
