import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material';

import {SearchContainer} from '../search-container';
import {AConst} from '../../core/a-const.enum';
import {SearchHandlerService} from '../search-handler.service';
import {FolderEditDialogComponent} from '../../object-edit/folder-edit-dialog/folder-edit-dialog.component';
import {FolderService} from '../../core/folder.service';
import {UiToolsService} from '../../core/ui-tools.service';

@Component({
  selector: 'app-search-view-folder',
  templateUrl: './search-view-folder.component.html',
  styleUrls: ['./search-view-folder.component.scss']
})
export class SearchViewFolderComponent implements OnChanges, OnDestroy {

  @Input() searchContainer: SearchContainer;

  AConst = AConst;
  actionMenus: Array<any> = [];
  showFolderDescription = false;
  canEditFolders = {};
  mediumScreen: boolean;
  sideMenuSmallScreen = false;

  private unRegWindowSizeWatch;

  constructor(
    private modalService: MatDialog,
    private uiTools: UiToolsService,
    private searchHandler: SearchHandlerService,
    private folderService: FolderService) {
  }

  ngOnChanges() {
    if (this.searchContainer) {
      this.searchHandler.subscribeToSearchResult(this.searchContainer, () => {
        this.setActionMenus();
      });
    }
    this.mediumScreen = this.uiTools.setMediumScreenSizeOnLoad(this.mediumScreen);
    this.unRegWindowSizeWatch = this.uiTools.addWindowSizeListener(newVal => {
        this.mediumScreen = newVal.width < 1025;
      }
    );
  }

  ngOnDestroy(): void {
    this.searchHandler.unSubscribeToSearchResult(this.searchContainer, () => {
      this.setActionMenus();
    });
  }

  toggleFolderDescription() {
    this.showFolderDescription = !this.showFolderDescription;
  }

  toggleSideMenuSmallScreen() {
    this.sideMenuSmallScreen = !this.sideMenuSmallScreen;
  }

  canEditFolder(folder) {
    const res = this.canEditFolders[folder[AConst.ARTIFACT_ID]];
    if (res === undefined) {
      this.folderService.canUserEditFolder(folder).then(canEdit => {
        this.canEditFolders[folder[AConst.ARTIFACT_ID]] = canEdit;
      });
    }
    return res;
  }

  editFolder(folder) {
    const modalRef = this.modalService.open(FolderEditDialogComponent, {panelClass: 'edit-dialog'});
    modalRef.componentInstance.folderId = folder[AConst.ARTIFACT_ID];
    modalRef.afterClosed().subscribe(folderEdited => {
      if (folderEdited) {
        this.folderService.goToFolder(folderEdited[AConst.ARTIFACT_ID]);
      }
    });
  }

  get targetObject() {
    return this.searchContainer.targetObject ? this.searchContainer.targetObject : {};
  }

  private setActionMenus() {
    this.actionMenus = this.searchHandler.getCurrentActionMenus(this.searchContainer);
    const menuSections = this.actionMenus[AConst.SECTION_MENUS];

    if (!menuSections) {
      this.actionMenus['title'] = 'TRANS__VALIDATION__NO_MENU_ACTIONS_FOR_SELECTION';
    }
  }

}
