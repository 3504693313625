import {Component, Input, OnInit} from '@angular/core';
import {SectionsContainer} from '../../core/sections-container';

@Component({
  selector: 'app-edit-prime-fields',
  templateUrl: './edit-prime-fields.component.html',
  styleUrls: ['./edit-prime-fields.component.scss']
})
export class EditPrimeFieldsComponent implements OnInit {

  @Input() sectionsContainer: SectionsContainer;

  constructor() {
  }

  ngOnInit() {

  }

  onSubmit() {
    console.log('Prime fields on submit called');
  }

}
