import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {InlineArrayHeaderService} from '../inline-array-header.service';
import {AConst} from '../../core/a-const.enum';
import {InlineArrayHeaderContainer} from '../inline-array-header-container';

@Component({
  selector: 'app-inline-array-header',
  templateUrl: './inline-array-header.component.html',
  styleUrls: ['./inline-array-header.component.scss']
})
export class InlineArrayHeaderComponent implements OnChanges, OnDestroy {

  @Input() rootObject;
  @Input() field;
  @Input() arrayIndex;
  @Input() parentIndex;
  @Input() edit;

  AConst = AConst;
  container: InlineArrayHeaderContainer;
  hasIcon = false;

  constructor(private inlineArrayHeaderSvc: InlineArrayHeaderService) {
  }

  ngOnChanges() {
    this.container = this.inlineArrayHeaderSvc.createContainer(this.rootObject, this.field, this.arrayIndex, this.parentIndex);
    this.inlineArrayHeaderSvc.setValues(this.container, this.edit ? 2000: null);
    this.container.labels.forEach((label) => {
      if(label.icon === '{conceptIcon}'){
        this.hasIcon = true;
      }
    });
  }

  ngOnDestroy() {
    if (this.container.timeOutId) {
      clearTimeout(this.container.timeOutId);
    }
  }

}
