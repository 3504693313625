import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FieldConditionService} from '../../core/field-condition.service';
import {AConst} from '../../core/a-const.enum';
import {FieldActionService} from '../field-action.service';
import {SectionsContainer} from '../../core/sections-container';
import {FieldActionParameters} from '../field-action-parameters';

@Component({
  selector: 'app-field-action-button',
  templateUrl: './field-action-button.component.html',
  styleUrls: ['./field-action-button.component.scss']
})
export class FieldActionButtonComponent implements OnInit {

  @Input() sectionsContainer: SectionsContainer;
  @Input() grandParentObject;
  @Input() object;
  @Input() field;
  @Input() fieldTitle;
  @Input() edit;
  @Input() index;
  @Input() parentIndex;
  @Output() closeEdit = new EventEmitter<any>();

  AConst = AConst;
  parameters: FieldActionParameters;

  constructor(private fieldCondition: FieldConditionService,
              private fieldActionService: FieldActionService) {
  }

  ngOnInit() {
    this.parameters = new FieldActionParameters();
    this.parameters.sectionsContainer = this.sectionsContainer;
    this.parameters.rootObject = this.sectionsContainer.rootObject;
    this.parameters.grandParentObject = this.grandParentObject;
    this.parameters.object = this.object || this.sectionsContainer.rootObject;
    this.parameters.field = this.field;
    this.parameters.edit = this.edit;
    this.parameters.parentIndex = this.parentIndex;
    this.parameters.index = this.index;
    this.parameters.trigger = 'on_click';
    this.parameters.closeEdit = this.closeEdit;
  }

  runAction() {
    this.fieldActionService.runActions(this.parameters);
  }

  get isDisabled() {
    return this.fieldCondition.runIf('disable', this.parameters);
  }

}
