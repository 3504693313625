import {Component, Input, OnChanges, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {SearchContainer} from '../search-container';
import {UiToolsService} from '../../core/ui-tools.service';
import {SearchHandlerService} from '../search-handler.service';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-search-result-view',
  templateUrl: './search-result-view.component.html',
  styleUrls: ['./search-result-view.component.scss']
})
export class SearchResultViewComponent implements OnInit, OnChanges, OnDestroy {

  @Input() searchContainer: SearchContainer;
  @Input() templateGroupId: string;
  @Output() artifactUpdated = new EventEmitter<boolean>();

  AConst = AConst;

  private unRegWindowWatch;
  private timeoutPromise;

  constructor(private uiTools: UiToolsService,
              private searchHandler: SearchHandlerService) {
  }

  ngOnInit() {
    const windowSize = this.uiTools.windowSize;
    this.checkSetSearchRows(windowSize);
  }

  ngOnChanges(): void {
    this.setOverviewFields();

    this.unRegWindowWatch = this.uiTools.addWindowSizeListener(newVal => {
      if (newVal) {
        this.checkSetSearchRows(newVal);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.unRegWindowWatch) {
      this.uiTools.removeWindowSizeListener(this.unRegWindowWatch);
    }
  }

  setArtifactUpdated() {
    this.artifactUpdated.emit(true);
  }

  private setOverviewFields() {
    this.searchHandler.getSearchFields(this.searchContainer).then();
  }

  private checkSetSearchRows(windowSize) {
    this.searchContainer.searchMenuCollapse = windowSize.width <= 887;
    this.searchHandler.setDefaultRows(this.searchContainer);

    if (this.timeoutPromise) {
      clearTimeout(this.timeoutPromise);
    }

    this.timeoutPromise = setTimeout(() => {
      const viewName = this.searchContainer.searchResultViewName;
      let mustSearch = !this.searchContainer.searchResult && !this.searchContainer.searching;
      const oldRows = this.searchContainer.rows[viewName];
      const r = this.searchHandler.getDefaultRows(viewName);
      if (r.widthResultItems) {
        this.searchContainer.widthResultItems = r.widthResultItems;
      }
      if (r.thumbWidth) {
        this.searchContainer.thumbWidth = r.thumbWidth;
      }
      if (r.rows && r.rows !== oldRows) {
        mustSearch = true;
        this.searchContainer.rows[viewName] = r.rows;
      }
      if (mustSearch && !this.searchContainer.displayAll) {
        this.searchHandler.runSearch(this.searchContainer, true).then();
      }
    }, 500);
  }

}
