import {Component, Input, OnChanges} from '@angular/core';
import {CopyKeepService, CopyKeepServiceParams} from './copy-keep.service';
import {AConst} from '../../core/a-const.enum';
import {InlineArrayItemService} from '../../core/inline-array-item.service';
import {FieldParameters} from '../../core/field-parameters';

@Component({
  selector: 'app-copy-keep-checkbox',
  templateUrl: './copy-keep-checkbox.component.html',
  styleUrls: ['./copy-keep-checkbox.component.scss']
})
export class CopyKeepCheckboxComponent implements OnChanges {
  @Input() sectionsContainer;
  @Input() object;
  @Input() field;
  @Input() index;
  @Input() parentIndex;

  hasKeep = false;
  keepObj;
  fieldId: string;
  private params;
  private fieldParameters: FieldParameters;

  constructor(private copyKeepService: CopyKeepService, private inlineArrayItemSvc: InlineArrayItemService) {
  }

  ngOnChanges() {
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters.object = this.object;
    this.fieldParameters.field = this.field;
    this.fieldParameters.index = this.index;
    this.fieldParameters.parentIndex = this.index;
    // WARNING: The change callback will only work as long as the change tracker is running in the background
    this.params = new CopyKeepServiceParams(this.object, this.field, this.index, (changed) => {
      this.setKeep(changed);
    });
    this.setKeep();
  }

  checkIndex() {
    if (this.index !== undefined) {
      const keepProps = this.copyKeepService.getKeepProps(this.params);
      const keep = !keepProps.keep;
      const itemIndex = this.inlineArrayItemSvc.getOpenArrayItemIndex(this.fieldParameters);
      if ((keep && itemIndex !== this.index) ||
        (!keep && itemIndex === this.index)) {
        this.inlineArrayItemSvc.toggleInlineArrayItemOpen(this.fieldParameters);
      }
    }
  }

  toggleKeep() {
    this.keepObj.keep = !this.keepObj.keep;
  }

  private setKeep(changed?) {
    let keep = true;
    const keepProps = this.copyKeepService.getKeepProps(this.params);
    if (keepProps) {
      keep = keepProps.keep;
      if (changed !== undefined) {
        console.log('Has field been changed? ' + changed);
        keep = keep && !changed;
      }
    }
    this.hasKeep = this.copyKeepService.setKeep(keep, this.params);
    if (this.hasKeep && !this.keepObj) {
      this.setKeepProps();
    }
  }

  private setKeepProps() {
    const keepRoot = this.object['$$keep'];
    if (keepRoot) {
      if (this.index === undefined) {
        this.keepObj = keepRoot[this.field.name];
        this.fieldId = this.object[AConst.OBJECT_TYPE] + '-' + this.field.name;
      } else {
        this.keepObj = keepRoot[this.field.name]['indexes'][this.index];
        this.fieldId = this.object[AConst.OBJECT_TYPE] + '-' + this.field.name + '-' + this.index;
      }
    } else {
      console.warn('$$keep not set: ' + this.field.name);
    }
  }
}
