import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatDialogModule} from '@angular/material';

import { ReportMenuComponent } from './report-menu/report-menu.component';
import { ReportOptionsComponent } from './report-menu/report-options/report-options.component';
import { ReportLayoutOptionsComponent } from './report-menu/report-layout-options/report-layout-options.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
  ],
  exports: [
    ReportMenuComponent
  ],
  declarations: [
    ReportMenuComponent,
    ReportOptionsComponent,
    ReportLayoutOptionsComponent
  ]
})
export class ReportModule { }
