import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-media-upload-camera',
  templateUrl: './media-upload-camera.component.html',
  styleUrls: ['./media-upload-camera.component.scss']
})
export class MediaUploadCameraComponent implements OnInit, OnDestroy {

  @Output() uploadCameraImages = new EventEmitter<Array<any>>();
  @Input() fileAccept;
  @Input() verticalView;
  @ViewChild('video', { static: true }) video: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  @ViewChild('photo', { static: true }) photo: ElementRef;

  accept;
  private streaming = false;
  private width = 0;
  private localMediaStream;
  private height = 700;

  constructor() {
  }

  ngOnInit() {
    let accept = this.fileAccept;
    if (!accept) {
      accept = 'image/*';
    }
    this.accept = accept + ';capture=camera';

    this.startCamera();

    // resize the video to the desired dimensions
    this.video.nativeElement.addEventListener('canplay', () => {
      this.canPlayListener();
    }, false);
  }

  ngOnDestroy(): void {
    this.stopCamera();
    this.video.nativeElement.removeEventListener('canplay', () => {
      this.canPlayListener();
    });
  }

  // re-set the canvas size to the dimensions of the video
  takePicture($event) {
    $event.preventDefault();
    this.canvas.nativeElement.width = this.width;
    this.canvas.nativeElement.height = this.height;
    this.canvas.nativeElement.getContext('2d').drawImage(this.video.nativeElement, 0, 0, this.width, this.height);
    const data = this.canvas.nativeElement.toDataURL('image/png');
    this.photo.nativeElement.setAttribute('src', data);

    let byteString;

    if (data.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(data.split(',')[1]);
    } else {
      byteString = decodeURI(data.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = data.split(',')[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ia], {type: mimeString});
    const file = new File([blob], 'camera.png', {
      // optional - default = now
      lastModified: new Date().getTime(),
      // optional - default = ''
      type: 'image/png'
    });
    this.uploadCameraImages.emit([file]);
  }

  private canPlayListener() {
    if (!this.streaming) {
      this.width = this.video.nativeElement.videoWidth / (this.video.nativeElement.videoHeight / this.height);
      this.video.nativeElement.setAttribute('width', this.width.toString());
      this.video.nativeElement.setAttribute('height', this.height.toString());
      this.canvas.nativeElement.setAttribute('width', this.width.toString());
      this.canvas.nativeElement.setAttribute('height', this.height.toString);
      this.streaming = true;
    }
  }

  private startCamera() {

    // get the video from the web cam
    window.navigator['getMedia'] = (window.navigator.getUserMedia || window.navigator['webkitGetUserMedia'] ||
      window.navigator['mozGetUserMedia'] || window.navigator['msGetUserMedia']);

    //  ask the browser to give us a video without audio
    // and get a stream in the callback function
    window.navigator['getMedia']({video: true, audio: false}, stream => {
        if (window.navigator['mozGetUserMedia']) {
          // Older versions of firefox (Firefox 34
          // and before)
          this.video.nativeElement.mozSrcObject = stream;
        } else {
          this.video.nativeElement.srcObject = stream;
        }
        this.localMediaStream = stream;
        this.video.nativeElement.play();
      },
      function (err) {
        console.log('An error occurred! ' + err);
      }
    );
  }

  private stopCamera() {
    if (this.localMediaStream.stop) {
      this.localMediaStream.stop();
    } else {
      // updated browsers
      const tracks = this.localMediaStream.getTracks();
      for (let i = 0; i < tracks.length; i++) {
        tracks[i].stop();
      }
    }
  }

}
