import {Component, Input, OnInit} from '@angular/core';
import {CommonsService} from '../../core/commons.service';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnInit {

  @Input() icon: string;
  @Input() text: string;

  isVisible = false;

  constructor(private commons: CommonsService) {
  }

  ngOnInit() {
  }

  toggleDropdown() {
    this.isVisible = !this.isVisible;
    if (this.isVisible === true) {
      window.addEventListener('mouseup', this.callback, true);
    } else {
      window.removeEventListener('mouseup', this.callback, true);
    }
  }

  private callback = (item) => {
    const found = this.commons.findClassNameRecursively(item.target, 'dropdownIgnoreClicks');
    if (!found) {
      setTimeout(() => {
        this.isVisible = false;
        window.removeEventListener('mouseup', this.callback, true);
      }, 100);
    }
  }

}
