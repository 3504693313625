import { Injectable } from '@angular/core';
import {FieldMetaHandlerService} from './field-meta-handler.service';
import {ModelFactoryService} from './model-factory.service';
import {AConst} from './a-const.enum';
import {ModelsService} from './models.service';

@Injectable({
  providedIn: 'root'
})
export class ChangeTrackerService {

  constructor(private fieldMetaHandler: FieldMetaHandlerService,
              private modelFactory: ModelFactoryService,
              private modelsSvc: ModelsService) { }

  objectHasChanges(obj, modelName?) {
    let res = false;
    this.getItems(obj).forEach((item) => {
      let meta = item && item[AConst.$$META];
      if (!meta) {
        if (modelName) {
          const model = this.modelsSvc.getModel(modelName);
          meta = model[AConst.$$META];
        } else {
          console.warn('No meta?');

        }
      }
      if (meta && !res) {
        for (const fName in meta) {
          if (meta.hasOwnProperty(fName)) {
            const fieldMeta = meta[fName];
            const display = fieldMeta[AConst.DISPLAY];
            const show = display ? display[AConst.SHOW] : null;
            if (!res && typeof fieldMeta === 'object' &&
              (fieldMeta[AConst.EDIT] || show)) {
              res = this.checkFieldChanged(item, fName);
            }
          }
        }
      }
    });
    return res;
  }

  checkFieldChanged(object, fieldName) {
    let changed = false, isArray, origVal, inline, field;

    field = object[fieldName];
    inline = this.fieldMetaHandler.getMetaProp({
      metaPropName: 'inline',
      fieldName: fieldName,
      parentModel: object
    });
    isArray = Array.isArray(field);

    if (inline && !isArray) {
      if (!field) {
        return changed;
      }
      changed = this.objectHasChanges(field, inline.model);
    } else {
      origVal = this.getOriginalFieldValue(object, fieldName);
      if (origVal) {
        if (isArray) {
          changed = origVal.length !== field.length;
          if (!changed) {
            changed = this.arrayItemsDeleted(field);
          }
          if (!changed) {
            changed = this.arrayItemsMoved(field, origVal);
          }
          if (!changed) {
            changed = this.objectHasChanges(field);
          }
        } else {
          changed = field !== origVal;
        }
      } else {
        if (isArray) {
          changed = field.length > 0;
        } else if (origVal === 0) {
          changed = field !== 0;
        } else if (origVal === '') {
          changed = field !== '';
        } else if (origVal === false) {
          changed = field !== false;
        } else {
          changed = field !== null;
        }
      }
    }
    this.fieldChangeCallback(object, fieldName, changed);
    return changed;
  }

  getOriginalFieldValue(object, fieldName) {
    return this.modelFactory.getOrigVal(object, fieldName);
  }

  setFieldChangeCallback(object, fieldName, callback) {
    object['$$changeCallbackInfo-' + fieldName] = { callback: callback, changed: false};
  }

  private fieldChangeCallback(object, fieldName, changed) {
    const callbackInfo = object['$$changeCallbackInfo-' + fieldName];
    if (callbackInfo && callbackInfo.changed !== changed) {
      callbackInfo.changed = changed;
      callbackInfo.callback(changed);
    }
  }

  private getItems(items) {
    let res = items;
    if (!Array.isArray(res)) {
      res = [items];
    }
    return res;
  }

  private arrayItemsDeleted(array) {
    let res = false;
    array.forEach((item) => {
      if (item._destroy) {
        res = true;
      }
    });
    return res;
  }

  private arrayItemsMoved(array, origOrder) {
    let res = false;
    array.forEach((item, index) => {
      if (item.order_number !== undefined &&
        Number(item.order_number) !== Number(origOrder[index])) {
        res = true;
      }
    });
    return res;
  }

}
