import {Component, Input, OnInit} from '@angular/core';
import {SearchContainer} from '../../search-container';
import {AConst} from '../../../core/a-const.enum';
import {SearchHandlerService} from '../../search-handler.service';

@Component({
  selector: 'app-search-menu-select-chosen',
  templateUrl: './search-menu-select-chosen.component.html',
  styleUrls: ['./search-menu-select-chosen.component.scss']
})
export class SearchMenuSelectChosenComponent implements OnInit {

  @Input() searchContainer: SearchContainer;

  isTemplateSelector = false;

  constructor(private searchHandler: SearchHandlerService) {
  }

  ngOnInit() {
    this.isTemplateSelector = this.searchContainer.searchView[AConst.VIEW_NAME] === 'selector_template';
  }

  select() {
    if (this.searchContainer.selectCallback) {
      this.searchContainer.selectCallback(this.searchContainer.selected);
    } else {
      console.warn('Missing select callback');
    }
  }

  selectDisabled() {
    return !this.searchHandler.selectionsChanged(this.searchContainer);
  }

}
