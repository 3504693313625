import {AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {DateToolsService} from './date-tools.service';
import {CmsApiService} from './cms-api.service';
import {AConst} from './a-const.enum';
import {CommonsService} from './commons.service';

export class FieldValidators {

  constructor() {
  }

  static validatePrecisionDate(dateTools: DateToolsService): ValidatorFn {
    return (field: FormControl): ValidationErrors | null => {
      if (field.value) {
        const validDate = dateTools.validateDate(field.value);
        return typeof validDate === 'object' ? null : {
          validatePrecisionDate: {
            valid: false,
            message: validDate
          }
        };
      } else {
        return null;
      }
    };
  }

  static validateIdentifier(cms: CmsApiService, originalValue: string): AsyncValidatorFn {
    return (field: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise(resolve => {
        if (field.value && field.value !== originalValue) {
          cms.identifierExists({identifier: field.value}).then(
            () => {
              // Identifier did not exist
              resolve(null);
            },
            () => {
              // Identifier already exists
              resolve({
                validateIdentifier: {
                  valid: false
                }
              });
            }
          );
        } else {
          resolve(null);
        }
      });
    };
  }

  static validateCompare(dateTools: DateToolsService, commons: CommonsService, object, metaProps): ValidatorFn {
    return (field: FormControl): ValidationErrors | null => {
      const res = {
        valid: true,
        type: 'validCompare',
        message: ''
      };
      let val1, val2, errorMsg, compProp, op;
      const validProps = metaProps[AConst.VALIDATION];
      const comp = validProps[AConst.COMPARE];
      if (comp) {
        let parentObject = object;
        if (metaProps[AConst.PARENT_NAME]) {
          parentObject = object[metaProps[AConst.PARENT_NAME]] || object;
        }
        compProp = parentObject[comp.field];
        if (compProp === undefined || compProp === null) {
          return null; // Field not set
        }
        op = comp[AConst.COMPARATOR];
        if (metaProps[AConst.INPUT_TYPE] === 'precision_date') {
          const fieldVal = parentObject[metaProps.name];
          if (fieldVal && fieldVal[AConst.DD_DATE] !== null && compProp[AConst.DD_DATE] !== null && field.value) {
            errorMsg = 'TRANS__VALIDATE__INVALID_DATE';
            if (op === '<' || op === '<=') {
              errorMsg = 'TRANS__VALIDATE__FROM_DATE_EXCEEDS_TO_DATE';
            } else if (op === '>' || op === '>=') {
              errorMsg = 'TRANS__VALIDATE__TO_DATE_LESS_THAN_FROM_DATE';
            }
            val1 = dateTools.precisionDateToDate(dateTools.validateDate(field.value));
            // This somewhat confusing chain of transformations makes sure the two dates share the same time stamp
            val2 = dateTools.precisionDateToDate(dateTools.validateDate(dateTools.precisionDateToString(compProp)));
          }
        } else {
          errorMsg = 'TRANS__VALIDATE__COMPARE_FAILED';
          val1 = field.value;
          val2 = compProp;
        }
        if (val1 !== undefined && val2 !== undefined) {
          res.valid = commons.compareValues(val1, op, val2);
          if (!res.valid) {
            res.message = errorMsg;
          }
        }
      }
      return res.valid ? null : {
        validateCompare: res
      };
    };
  }

  static validateUsername(cms: CmsApiService, fieldMeta): AsyncValidatorFn {
    return (field: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        const valProps = fieldMeta[AConst.VALIDATION];
        if (valProps[AConst.USERNAME]) {
          const searchParams = {
            filters: {
              'object_type': 'user',
              'signature': field.value.toLocaleUpperCase()
            },
            rows: 1,
            include_meta: false
          };
          cms.searchJson(searchParams).then(
            function (searchRes) {
              if (searchRes[AConst.ARTIFACTS] && searchRes[AConst.ARTIFACTS].length > 0) {
                resolve({validateUsername: {valid: false}});
              } else {
                resolve(null);
              }
            },
            function (response) {
              console.warn(response.message, response.status);
              reject();
            }
          );
        }
      });
    };
  }
}

