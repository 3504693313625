import {Component, Input, OnInit} from '@angular/core';
import {CommonsService} from '../../core/commons.service';
import {AConst} from '../../core/a-const.enum';
import {CmsApiService} from '../../core/cms-api.service';
import {UploadMediaContainer} from '../../core/upload-media-container';

@Component({
  selector: 'app-media-selectors',
  templateUrl: './media-selectors.component.html',
  styleUrls: ['./media-selectors.component.scss']
})
export class MediaSelectorsComponent implements OnInit {

  @Input() object: any;
  @Input() container: any;
  @Input() annotateDashboardClass: string;

  uploadMediaContainers: Array<UploadMediaContainer> = [];

  constructor(private commons: CommonsService,
              private cms: CmsApiService) {
  }

  ngOnInit() {
    if (this.object) {
      this.container['uploadMediaContainers'] = [];
      this.cms.getModelUploadInfo({modelName: this.object[AConst.OBJECT_TYPE]}).then(res => {
        res[AConst.UPLOAD_INFO_LIST].forEach(uploadInfo => {
          const mediaContainer = new UploadMediaContainer();
          mediaContainer.uploadInfo = uploadInfo;
          mediaContainer.parentObject = this.object;
          this.uploadMediaContainers.push(mediaContainer);
          this.container['uploadMediaContainers'].push(mediaContainer);
        });
      });
    }
  }

}
