import {Component, Input, OnInit} from '@angular/core';
import {SearchContainer} from '../search-container';
import {AConst} from '../../core/a-const.enum';
import {group, query, transition, trigger, useAnimation} from '@angular/animations';
import {slideDownMenu, slideUpMenu} from '../../shared/animations';

@Component({
  selector: 'app-search-menus',
  templateUrl: './search-menus.component.html',
  styleUrls: ['./search-menus.component.scss'],
  animations: [
    trigger('slideSearchReportMenu', [
      transition(':leave', [
          group([
            query('.search-action-menu-report', [
              useAnimation(slideUpMenu, { params: {time: '750ms ease-in'}}),
            ]),
          ])
      ]),

      transition(':enter', [
        group([
          query('.search-action-menu-report', [
            useAnimation(slideDownMenu, { params: {time: '750ms ease-out'}}),
          ]),
        ])
      ])
    ])
  ]
})
export class SearchMenusComponent implements OnInit {

  @Input() searchContainer: SearchContainer;
  @Input() actionMenus: Array<any>;

  AConst = AConst;

  constructor() {
  }

  ngOnInit() {
  }

  get actionMenuDisabled() {
    const requiresSelections = this.actionMenus && this.actionMenus[AConst.REQUIRES_SELECTIONS];
    const sectionsMenuLength = this.actionMenus[AConst.SECTION_MENUS] ? this.actionMenus[AConst.SECTION_MENUS].length : 0;
    return (!(this.searchContainer.selected.length || this.searchContainer.allSelected) && requiresSelections)
      || ((!this.actionMenus || !sectionsMenuLength) && requiresSelections);
  }

}
