import {Injectable} from '@angular/core';
import {CmsApiService} from './cms-api.service';

@Injectable({
  providedIn: 'root'
})
export class CmsQueueService {

  constructor(private cms: CmsApiService) {
  }

  private callQueue = {};
  private cachedCmsData = {};
  private maxCachedTime = 10 * 60 * 1000;

  private getCachedData(paramKey) {
    let data = this.cachedCmsData[paramKey];
    let thisTime;
    if (data) {
      thisTime = new Date().getTime();
      if (thisTime > data.$$cachedTime + this.maxCachedTime) {
        this.cachedCmsData[paramKey] = null;
        data = null;
      }
    }
    return data;
  }

  private setCachedData(paramKey, data) {
    if (data) {
      data.$$cachedTime = new Date().getTime();
    }
    this.cachedCmsData[paramKey] = data;
  }

  public runCmsFnWithQueue(fnName, params?, noCache?) {
    return new Promise((resolve, reject) => {
      const paramKey = this.getParamKey(fnName, params);
      const cachedData = this.getCachedData(paramKey);

      if (cachedData && !noCache) {
        resolve(cachedData);
      } else {
        if (!this.getCallQueue(fnName, paramKey, true)) {
          this.createCallQueue(fnName, paramKey);
          this.cms[fnName](params).then(
            (data) => {
              this.setCachedData(paramKey, data);
              this.executeCallQueue(fnName, paramKey, data);

            },
            (error, status) => {
              this.clearQueue(fnName, paramKey);
              if (reject) {
                reject(error);
              } else {
                throw new Error('Error executing \'' + fnName +
                '\': ' + error.data.message + ': ' + status);
              }
            }
          );
        }
        this.addToCallQueue(fnName, paramKey, resolve);
      }
    });
  }

  private getParamKey(fnName, params) {
    let res = fnName;
    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const value = params[key];
          if (typeof value !== 'object' && value !== undefined) {
            res += ':' + key + ':' + value.toString();
          } else {
            for (const subKey in value) {
              if (value.hasOwnProperty(subKey)) {
                const subVal = value[subKey];
                if (typeof subVal !== 'object') {
                  res += ':' + key + ':' + subKey + ':' + subVal;
                } else if (Array.isArray(subVal)) {
                  subVal.forEach((subSubVal) => {
                    res += ':' + key + ':' + subKey + ':' + subSubVal;
                  });
                }
              }
            }
          }
        }
      }
    }
    return res;
  }

  private createCallQueue(fnName, paramKey) {
    if (!this.callQueue[fnName]) {
      this.callQueue[fnName] = {};
    }
    this.callQueue[fnName][paramKey] = {
      queue: [],
      time: new Date().getTime()
    };
  }

  private getCallQueue(functionName, paramKey, checkTimeout?) {
    let res, paramQueue;
    const functionQueue = this.callQueue[functionName];
    if (functionQueue) {
      paramQueue = functionQueue[paramKey];
      if (paramQueue) {
        if (checkTimeout &&
          paramQueue.time + 10000 < new Date().getTime()) {
          console.warn('Queue timed out!');
        } else {
          res = paramQueue.queue;
        }
      }
    }
    return res;
  }

  private addToCallQueue(fnName, paramKey, resolve) {
    let queue;
    if (resolve) {
      queue = this.getCallQueue(fnName, paramKey);
      if (queue) {
        queue.push(resolve);
      }
    }
  }

  private clearQueue(fnName, paramKey) {
    this.callQueue[fnName][paramKey] = null;
  }

  private executeCallQueue(fnName, paramKey, data) {
    const queue = this.getCallQueue(fnName, paramKey);
    if (queue && queue.length > 0) {
      queue.forEach((resolve) => {
        resolve(data);
      });
    } else {
      console.warn('No queue for ' + paramKey);
    }
    this.clearQueue(fnName, paramKey);
  }


}
