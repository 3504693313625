import {Component, ElementRef, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {CurrentObjectService} from '../../core/current-object.service';

@Component({
  selector: 'app-object-content-dropdown-tab',
  templateUrl: './object-content-dropdown-tab.component.html',
  styleUrls: ['./object-content-dropdown-tab.component.scss']
})
export class ObjectContentDropdownTabComponent implements OnChanges {

  @Input() menu;
  @Output() menuOpened = new EventEmitter();

  AConst = AConst;
  private dropdownTabItem;

  constructor(public currentObjectService: CurrentObjectService,
              private dropdownTabElementRef: ElementRef) {
  }

  ngOnChanges() {
    this.dropdownTabItem = this.dropdownTabElementRef.nativeElement;
  }

  openMenu() {
    this.menuOpened.emit(this.menu);
  }

  getDropdownTabElement() {
    return this.dropdownTabElementRef.nativeElement;
  }

}
