import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DragulaModule} from 'ng2-dragula';
import {registerLocaleData} from '@angular/common';
import localeNb from '@angular/common/locales/nb';

import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {ObjectSearchModule} from './object-search/object-search.module';
import {CmsApiService} from './core/cms-api.service';
import {CmsAuthService} from './core/cms-auth.service';

import {ObjectEditModule} from './object-edit/object-edit.module';
import {OptionsService} from './core/options.service';
import {LandingPagesModule} from './landing-pages/landing-pages.module';
import {ObjectViewModule} from './object-view/object-view.module';
import {MainMenuModule} from './main-menu/main-menu.module';
import {MediaCarouselModule} from './media-carousel/media-carousel.module';
import {ReportModule} from './report/report.module';

import {EditFieldSelectComponent} from './object-edit/edit-field-select/edit-field-select.component';
import {FaqPageComponent} from './landing-pages/faq-page/faq-page.component';
import {EditObjectDialogComponent} from './object-edit/edit-object-dialog/edit-object-dialog.component';
import {TopHeaderComponent} from './landing-pages/top-header/top-header.component';
import {SectionViewEditComponent} from './object-edit/section-view-edit/section-view-edit.component';
import {ActionToolbarComponent} from './shared/action-toolbar/action-toolbar.component';
import {MainMenuComponent} from './main-menu/main-menu/main-menu.component';
import {ObjectIconComponent} from './shared/object-icon/object-icon.component';
import {ObjectNameComponent} from './shared/object-name/object-name.component';
import {ObjectUrlComponent} from './shared/object-url/object-url.component';
import {DownloadUrlComponent} from './shared/download-url/download-url.component';
import {ConfirmDialogComponent} from './object-edit/confirm-dialog/confirm-dialog.component';
import {AlertDialogComponent} from './shared/alert-dialog/alert-dialog.component';
import {InlineArrayHeaderComponent} from './shared/inline-array-header/inline-array-header.component';
import {FieldActionButtonComponent} from './shared/field-action-button/field-action-button.component';

import {ImageCarouselComponent} from './media-carousel/image-carousel/image-carousel.component';
import {ImageFullScreenComponent} from './landing-pages/image-full-screen/image-full-screen.component';
import {ImageZoomComponent} from './media-carousel/image-zoom/image-zoom.component';
import {SelectImagesComponent} from './media/select-images/select-images.component';
import {FieldToolTipComponent} from './shared/field-tool-tip/field-tool-tip.component';
import {ReportMenuComponent} from './report/report-menu/report-menu.component';
import {KulturnavSearchDialogComponent} from './shared/kulturnav-search-dialog/kulturnav-search-dialog.component';
import {ProgressDialogComponent} from './shared/progress-dialog/progress-dialog.component';
import {EditPrimeFieldsComponent} from './object-edit/edit-prime-fields/edit-prime-fields.component';
import {FolderAppendDialogComponent} from './object-edit/folder-append-dialog/folder-append-dialog.component';
import {FolderEditDialogComponent} from './object-edit/folder-edit-dialog/folder-edit-dialog.component';
import {EditFieldSwitchComponent} from './object-edit/edit-field-switch/edit-field-switch.component';
import {FieldTitleComponent} from './shared/field-title/field-title.component';
import {SectionMenuComponent} from './object-view/section-menu/section-menu.component';
import {SectionMenuScrollComponent} from './object-view/section-menu-scroll/section-menu-scroll.component';
import {SearchCollapseComponent} from './object-view/search-collapse/search-collapse.component';
import {ObjectNavigatorComponent} from './object-search/object-navigator/object-navigator.component';
import {SearchQueryComponent} from './object-search/search-query/search-query.component';
import {TemplateGroupSelectButtonComponent} from './shared/template-group-select-button/template-group-select-button.component';
import {ObjectEditComponent} from './object-edit/object-edit/object-edit.component';
import {AdminSettingsEditFormComponent} from './landing-pages/admin-settings/admin-settings-edit-form/admin-settings-edit-form.component';
import {AdminSettingsFaqComponent} from './landing-pages/admin-settings/admin-settings-faq/admin-settings-faq.component';
import {EditFieldImageSelectorComponent} from './object-edit/edit-field-image-selector/edit-field-image-selector.component';
import {ContentListSourceArrayComponent} from './object-view/content-list-source-array/content-list-source-array.component';
import {ListPagerComponent} from './object-view/list-pager/list-pager.component';
import {SearchFilterMenuPathComponent} from './object-search/search-filter-menu/search-filter-menu-path/search-filter-menu-path.component';
import {SearchFilterMenuItemComponent} from './object-search/search-filter-menu/search-filter-menu-item/search-filter-menu-item.component';
import {SearchFilterGroupComponent} from './object-search/search-filter-menu/search-filter-group/search-filter-group.component';
import {SearchFacetRangesComponent} from './object-search/search-filter-menu/search-facet-ranges/search-facet-ranges.component';
import {SearchFilterMenuComponent} from './object-search/search-filter-menu/search-filter-menu.component';
import {SearchFilterMenuSmallComponent} from './object-search/search-filter-menu/search-filter-menu-small/search-filter-menu-small.component';
import {OverviewFieldValueComponent} from './overview-fields/overview-field-value/overview-field-value.component';
import {OverviewFieldsModule} from './overview-fields/overview-fields.module';
import {OverviewFieldLinkComponent} from './overview-fields/overview-field-link/overview-field-link.component';
import {ObjectOverviewFieldsComponent} from './overview-fields/object-overview-fields/object-overview-fields.component';
import {SearchTileOverviewFieldsComponent} from './overview-fields/search-tile-overview-fields/search-tile-overview-fields.component';
import {SearchColumnsOverviewFieldsComponent} from './overview-fields/search-columns-overview-fields/search-columns-overview-fields.component';
import {DeleteItemButtonComponent} from './shared/delete-item-button/delete-item-button.component';
import {DropdownMenuComponent} from './shared/dropdown-menu/dropdown-menu.component';
import {DropdownMenuItemComponent} from './shared/dropdown-menu/dropdown-menu-item/dropdown-menu-item.component';
import {DropdownMenuHrComponent} from './shared/dropdown-menu/dropdown-menu-hr/dropdown-menu-hr.component';
import {SearchResultViewContentListComponent} from './object-search/search-result-view/search-result-view-content-list/search-result-view-content-list.component';
import {SearchResultViewThumbnailComponent} from './object-search/search-result-view/search-result-view-thumbnail/search-result-view-thumbnail.component';
import {SearchResultViewListSelectorComponent} from './object-search/search-result-view/search-result-view-list-selector/search-result-view-list-selector.component';
import {SearchResultViewListThumbnailComponent} from './object-search/search-result-view/search-result-view-list-thumbnail/search-result-view-list-thumbnail.component';
import {SearchResultViewListComponent} from './object-search/search-result-view/search-result-view-list/search-result-view-list.component';
import {SearchResultViewFolderComponent} from './object-search/search-result-view/search-result-view-folder/search-result-view-folder.component';
import {SearchResultViewComponent} from './object-search/search-result-view/search-result-view.component';
import {SearchMenuSortComponent} from './object-search/search-menus/search-menu-sort/search-menu-sort.component';
import {ObjectUsageDialogComponent} from './shared/object-usage-dialog/object-usage-dialog.component';
import {SearchMenuViewSelectorComponent} from './object-search/search-menus/search-menu-view-selector/search-menu-view-selector.component';
import {SearchMenuFolderSettingsComponent} from './object-search/search-menus/search-menu-folder-settings/search-menu-folder-settings.component';
import {SearchMenuActionsComponent} from './object-search/search-menus/search-menu-actions/search-menu-actions.component';
import {SearchActionMenuReportComponent} from './object-search/search-menus/search-action-menu-report/search-action-menu-report.component';
import {SearchMenuSelectAllComponent} from './object-search/search-menus/search-menu-select-all/search-menu-select-all.component';
import {SearchMenuToggleRestrictionsComponent} from './object-search/search-menus/search-menu-toggle-restrictions/search-menu-toggle-restrictions.component';
import {SearchMenuSelectChosenComponent} from './object-search/search-menus/search-menu-select-chosen/search-menu-select-chosen.component';
import {SearchMenusComponent} from './object-search/search-menus/search-menus.component';
import {SearchResultPagerComponent} from './object-search/search-result-view/search-result-pager/search-result-pager.component';
import {SearchResultPagerListComponent} from './object-search/search-result-view/search-result-pager-list/search-result-pager-list.component';
import {SearchViewSelectorComponent} from './object-search/search-view-selector/search-view-selector.component';
import {SearchHeaderComponent} from './object-search/search-header/search-header.component';
import {SearchViewContentListComponent} from './object-search/search-view-content-list/search-view-content-list.component';
import {SearchFocusEditDialogComponent} from './object-search/search-menus/search-focus-edit-dialog/search-focus-edit-dialog.component';
import {SearchFocusMenuComponent} from './object-search/search-menus/search-focus-menu/search-focus-menu.component';
import {SearchViewFolderComponent} from './object-search/search-view-folder/search-view-folder.component';
import {SearchViewFullComponent} from './object-search/search-view-full/search-view-full.component';
import {SearchPageComponent} from './object-search/search-page/search-page.component';
import {ObjectSearchMenuComponent} from './object-view/object-search-menu/object-search-menu.component';
import {ThumbnailComponent} from './shared/thumbnail/thumbnail.component';
import {ObjectMenusComponent} from './object-view/object-menus/object-menus.component';
import {ObjectMenusSmallComponent} from './object-view/object-menus-small/object-menus-small.component';
import {AnnotationCanvasComponent} from './image-annotation/annotation-canvas/annotation-canvas.component';
import {ImageAnnotationModule} from './image-annotation/image-annotation.module';
import {AboutPrimusComponent} from './landing-pages/about-primus/about-primus.component';
import {AdminImportAuthoritiesComponent} from './landing-pages/admin-import-authorities/admin-import-authorities.component';
import {AnnotationDashboardComponent} from './image-annotation/annotation-dashboard/annotation-dashboard.component';
import {ImageAnnotationComponent} from './image-annotation/image-annotation/image-annotation.component';
import {MediaUploadCameraComponent} from './media/media-upload-camera/media-upload-camera.component';
import {MediaSelectorImageVerticalComponent} from './media/media-selector-image-vertical/media-selector-image-vertical.component';
import {MediaSelectorsComponent} from './media/media-selectors/media-selectors.component';
import {MediaSelectorGeneralComponent} from './media/media-selector-general/media-selector-general.component';
import {ObjectMenuUploadComponent} from './object-view/object-menu-upload/object-menu-upload.component';
import {ObjectMenuCurrentComponent} from './object-view/object-menu-current/object-menu-current.component';
import {ObjectContentTabsComponent} from './object-view/object-content-tabs/object-content-tabs.component';
import {ObjectContentListComponent} from './object-view/object-content-list/object-content-list.component';
import {ObjectViewComponent} from './shared/object-view/object-view.component';
import {ObjectViewAndEditComponent} from './object-view/object-view-and-edit/object-view-and-edit.component';
import {AdminSettingsComponent} from './landing-pages/admin-settings/admin-settings.component';
import {ObjectUpdateLogComponent} from './object-view/object-update-log/object-update-log.component';
import {ObjectPageComponent} from './landing-pages/object-page/object-page.component';
import {AdminMainPageComponent} from './landing-pages/admin-main-page/admin-main-page.component';
import {AdminPageComponent} from './landing-pages/admin-page/admin-page.component';
import {AdminConceptListOverviewPageComponent} from './landing-pages/admin-concept-list-overview-page/admin-concept-list-overview-page.component';
import {NotificationsComponent} from './shared/notifications/notifications.component';
import {AdminTemplateGroupComponent} from './landing-pages/admin-template-group/admin-template-group.component';
import {AdminTemplateModelComponent} from './landing-pages/admin-template-model/admin-template-model.component';
import {ImportSitulaComponent} from './landing-pages/import-situla/import-situla.component';
import {ObjectNewPageComponent} from './landing-pages/object-new-page/object-new-page.component';
import {MediaPageComponent} from './landing-pages/media-page/media-page.component';
import {LoginPageComponent} from './landing-pages/login-page/login-page.component';
import {AppComponent} from './app.component';
import {UIRouterModule} from '@uirouter/angular';
import {APP_STATES} from './app.states';
import {routerConfigFn} from './router.config';
import {MediaListComponent} from './media/media-list/media-list.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';

registerLocaleData(localeNb);

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/cms_api/v1.0/translation/translations?lang=', '');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    UIRouterModule.forRoot({
      states: APP_STATES,
      useHash: true,
      otherwise: {state: 'home'},
      config: routerConfigFn
    }),
    DragulaModule.forRoot(),
    CoreModule,
    SharedModule,
    ObjectEditModule,
    LandingPagesModule,
    ObjectViewModule,
    ObjectSearchModule,
    MainMenuModule,
    MediaCarouselModule,
    ReportModule,
    OverviewFieldsModule,
    ImageAnnotationModule
  ],
  providers: [
    CmsApiService,
    CmsAuthService,
    OptionsService,
    {provide: LOCALE_ID, useValue: 'nb'},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
  ],
  entryComponents: [
    EditFieldSelectComponent,
    EditFieldSwitchComponent,
    FaqPageComponent,
    EditObjectDialogComponent,
    TopHeaderComponent,
    SectionViewEditComponent,
    ActionToolbarComponent,
    MainMenuComponent,
    ThumbnailComponent,
    TopHeaderComponent,
    ObjectNameComponent,
    ObjectIconComponent,
    ObjectUrlComponent,
    DownloadUrlComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    ProgressDialogComponent,
    InlineArrayHeaderComponent,
    FieldActionButtonComponent,
    ImageCarouselComponent,
    ImageFullScreenComponent,
    ImageZoomComponent,
    SelectImagesComponent,
    FieldToolTipComponent,
    ReportMenuComponent,
    KulturnavSearchDialogComponent,
    EditPrimeFieldsComponent,
    FolderAppendDialogComponent,
    FolderEditDialogComponent,
    FieldTitleComponent,
    SectionMenuComponent,
    SectionMenuScrollComponent,
    SearchCollapseComponent,
    ObjectNavigatorComponent,
    SearchQueryComponent,
    TemplateGroupSelectButtonComponent,
    ObjectEditComponent,
    AdminSettingsEditFormComponent,
    AdminSettingsFaqComponent,
    EditFieldImageSelectorComponent,
    ContentListSourceArrayComponent,
    ListPagerComponent,
    EditFieldImageSelectorComponent,
    SearchFilterMenuPathComponent,
    SearchFilterMenuItemComponent,
    SearchFilterGroupComponent,
    SearchFacetRangesComponent,
    SearchFilterMenuComponent,
    SearchFilterMenuSmallComponent,
    OverviewFieldValueComponent,
    OverviewFieldLinkComponent,
    ObjectOverviewFieldsComponent,
    SearchTileOverviewFieldsComponent,
    SearchColumnsOverviewFieldsComponent,
    DeleteItemButtonComponent,
    DropdownMenuComponent,
    DropdownMenuItemComponent,
    DropdownMenuHrComponent,
    ObjectUsageDialogComponent,
    SearchResultViewContentListComponent,
    SearchResultViewThumbnailComponent,
    SearchResultViewListSelectorComponent,
    SearchResultViewListThumbnailComponent,
    SearchResultViewListComponent,
    SearchResultViewFolderComponent,
    SearchResultViewComponent,
    SearchMenuSortComponent,
    SearchMenuViewSelectorComponent,
    SearchMenuFolderSettingsComponent,
    SearchMenuActionsComponent,
    SearchActionMenuReportComponent,
    SearchMenuSelectAllComponent,
    SearchMenuToggleRestrictionsComponent,
    SearchMenuSelectChosenComponent,
    SearchMenusComponent,
    SearchResultPagerComponent,
    SearchResultPagerListComponent,
    SearchViewSelectorComponent,
    SearchHeaderComponent,
    SearchViewContentListComponent,
    SearchFocusEditDialogComponent,
    SearchFocusMenuComponent,
    SearchViewFolderComponent,
    SearchViewFullComponent,
    SearchPageComponent,
    ObjectSearchMenuComponent,
    ObjectMenusComponent,
    ObjectMenusSmallComponent,
    AnnotationCanvasComponent,
    AnnotationDashboardComponent,
    ObjectMenusSmallComponent,
    AboutPrimusComponent,
    AdminImportAuthoritiesComponent,
    ImageAnnotationComponent,
    MediaUploadCameraComponent,
    MediaSelectorImageVerticalComponent,
    MediaSelectorsComponent,
    MediaSelectorGeneralComponent,
    ObjectMenuUploadComponent,
    ObjectMenuCurrentComponent,
    ObjectContentTabsComponent,
    ObjectContentListComponent,
    ObjectViewComponent,
    ObjectViewAndEditComponent,
    AdminSettingsComponent,
    ObjectUpdateLogComponent,
    ObjectPageComponent,
    AdminMainPageComponent,
    AdminPageComponent,
    AdminConceptListOverviewPageComponent,
    NotificationsComponent,
    AdminTemplateGroupComponent,
    AdminTemplateModelComponent,
    ImportSitulaComponent,
    AdminTemplateModelComponent,
    ObjectNewPageComponent,
    MediaPageComponent,
    LoginPageComponent,
    MediaListComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
