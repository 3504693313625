import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';

import {SectionsContainer} from '../../core/sections-container';
import {FolderService} from '../../core/folder.service';
import {AConst} from '../../core/a-const.enum';
import {NotificationService} from '../../shared/notification.service';
import {ObjectEditService} from '../../core/object-edit.service';

@Component({
  selector: 'app-folder-edit-dialog',
  templateUrl: './folder-edit-dialog.component.html',
  styleUrls: ['./folder-edit-dialog.component.scss']
})
export class FolderEditDialogComponent implements OnInit {

  @Input() objectIds;
  @Input() folderId;

  sectionsContainer: SectionsContainer = new SectionsContainer();
  storeStatus = {
    failed: false,
    message: '',
    serverMsg: ''
  };

  constructor(private dialogRef: MatDialogRef<FolderEditDialogComponent>,
              private objectEditService: ObjectEditService,
              private folderService: FolderService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    if (!this.folderId) {
      this.objectEditService.createModelItemGetSectionsContainer(AConst.FOLDER).then(sectionsContainer => {
        this.sectionsContainer = sectionsContainer;
        this.sectionsContainer.isDialog = true;
      });
    } else {
      this.objectEditService.loadObjectGetSectionsContainer(this.folderId, null).then(
        sectionsContainer => {
          const folder = sectionsContainer.rootObject;
          this.folderService.canUserEditFolder(folder).then(canEdit => {
            if (canEdit) {
              this.sectionsContainer = sectionsContainer;
              this.sectionsContainer.isDialog = true;
            } else {
              this.dialogRef.close(folder);
              this.notificationService.addNotification({
                messages: ['TRANS__FOLDER__EDIT_FOLDER_PERMISSIONS_ERROR'],
                values: {folderName: folder[AConst.TITLE]},
                // "Insufficient permissions edit folder: {{name}}"
                type: 'error'
              });
            }
          });
        },
        () => {
          this.dialogRef.close(false);
        }
      );
    }
  }

  onSubmit() {
    const folder = this.sectionsContainer.rootObject;
    this.folderService.appendFolderItems(folder, this.objectIds);
    this.objectEditService.setObjectValuesStoreObject(this.sectionsContainer, true).then(
      loadedFolder => {
        this.dialogRef.close(loadedFolder);
      },
      reason => {
        this.storeStatus.failed = true;
        this.storeStatus.message = 'TRANS__EDIT_OBJECT_DIALOG__STORE_FAILED';
        this.storeStatus.serverMsg = reason.error.message;
      }
    );
  }

  get isValid(): boolean {
    return this.sectionsContainer.formGroup.valid;
  }

}
