import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {CommonsService} from '../../core/commons.service';
import {FieldValue} from '../../core/object-view';

@Component({
  selector: 'app-overview-field-link',
  templateUrl: './overview-field-link.component.html',
  styleUrls: ['./overview-field-link.component.scss']
})
export class OverviewFieldLinkComponent implements OnInit {
  @Input() linkValue: FieldValue;
  @Input() linkValues: Array<FieldValue>;
  @Input() disableLinks;
  @Input() toneDownTextColor: boolean;
  @Input() stylingClass: string;

  items = [];
  AConst = AConst;
  showMore = false;

  constructor(private commons: CommonsService) {
  }

  ngOnInit() {
    const linkValues = this.linkValues || [this.linkValue];
    linkValues.forEach(linkValue => {
      this.items.push({artifact_id: linkValue.ref_id, artifact_name: linkValue.value});
    });
  }

  showMoreItems() {
    this.showMore = !this.showMore;
    if (this.showMore) {
      window.addEventListener('mouseup', this.showMoreCallback, true);
    } else {
      window.removeEventListener('mouseup', this.showMoreCallback, true);
    }
  }

  showMoreCallback = (item) => {
    const found = this.commons.findClassNameRecursively(item.target, 'dropdownIgnoreClicks');
    if (!found) {
      setTimeout(() => {
        this.showMore = false;
        window.removeEventListener('mouseup', this.showMoreCallback, true);
      }, 100);
    }
  }

}
