import {SelectorContainer} from './selector-container';
import {SearchContainer} from '../object-search/search-container';
import {ContentInfo} from './content-info.service';
import {Annotation} from '../image-annotation/annotation';
import {SectionsContainer} from './sections-container';

export class ActionMenu {
  changePropertyField: string;
  changePropertyParent: string;
  menuSaveObject: any;
  noChildren: boolean;
  obj: any;
  sectionsContainer: SectionsContainer;
  showMenu: boolean;
  templateGroupId: string;
  view: string;
}

export class ActionMenuContainer implements SelectorContainer {
  actionsFirstStep: Array<object>;
  actionsReport: Array<object>;
  art: any;
  artifactId: string;
  artifactUpdateCallback;
  checkboxChange: boolean;
  contentInfo: ContentInfo;
  contextIds: Array<string>;
  cancelActionMenu;
  curAnn: Annotation;
  currentMenu: ActionMenu;
  currentMenuGroup: any;
  disableSelector;
  enableSelector;
  getActionsStoreMenuArray;
  getActionsStoreMenuObject;
  makeReportFn;
  menus: Array<ActionMenu>;
  objectType: string;
  parentId: string;
  saveCloseMenu;
  searchContainer: SearchContainer;
  selectorCallback;
  selectorEnabled: boolean;
  showMenu: boolean;
  showMenuReport: boolean;
}
