import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private appNotifications = [];
  private notificationTime = 0;

  // Wait 3 seconds before auto-dismissing notification.
  private autoDismissDelay = 3 * 1000;

  // Auto-dismiss notifications of these types.
  private autoDismissTypes = ['success', 'info', 'warning'];

  constructor() { }

  get notifications() {
    return this.appNotifications;
  }

  addNotification(notification) {
    let mxLen = 3;
    let notTime = new Date().getTime();
    let mustDelay = false, delay = 1000;
    if (this.appNotifications.length > 0) {
      mustDelay = notTime < this.notificationTime;
      if (mustDelay) {
        delay += this.notificationTime - notTime;
      }
    }
    this.notificationTime = notTime + delay;
    setTimeout(() => {
      if (this.appNotifications.length > mxLen) {
        this.appNotifications.splice(0, this.appNotifications.length - mxLen);
        mustDelay = true;
      }
      // Referencing this ID when auto-dismissing success-notifications
      notification._id = this.appNotifications.length;

      this.appNotifications.push(notification);
    }, mustDelay ? delay : 0);

    if (notification.type in this.autoDismissTypes) {
      setTimeout(() => {
        this.appNotifications = this.autoDismissSuccess(this.appNotifications, notification._id);
      }, this.autoDismissDelay);
    }
  }

  removeNotification(index) {
    this.appNotifications.splice(index, 1);
  }

  private autoDismissSuccess(notifications, id) {
    let ix = notifications.findIndex(function(n) {
      return (n._id === id);
    });

    notifications.splice(ix, 1);
    return notifications;
  }

}
