import { Injectable } from '@angular/core';
import {AConst} from './a-const.enum';
import {CmsApiService} from './cms-api.service';

@Injectable({
  providedIn: 'root'
})
export class ModelSectionsService {

  constructor(private cms: CmsApiService) { }

  // Getting section information from object. This information will usually be
  // stored in the $$SECTIONS attribute of the objects, but in some cases
  // this information must be retrieved from the server, e.g. for new objects
  // that are created on the client side.
  public getObjectSections(object, templateGroupId?): Promise<Array<any>> {
    return new Promise((resolve) => {
      let res, objectType = object[AConst.OBJECT_TYPE];
      if (objectType) {
        res = object[AConst.$$SECTIONS];
        if (!res) {
          res = object[AConst.TEMPLATE_SECTIONS];
        }
        if (!res) {
          this.cms.getModelSections({
            object_type: objectType,
            template_group_id: templateGroupId
          }).then((res) => {
            object[AConst.$$SECTIONS] = res;
            resolve(<Array<any>>res)
          });
        } else {
          resolve(res);
        }
      } else {
        resolve([])
      }

    });
  };

}
