import {Component, OnInit} from '@angular/core';
import {StateService} from '@uirouter/core';
import {AConst} from '../../../core/a-const.enum';
import {CmsApiService} from '../../../core/cms-api.service';

@Component({
  selector: 'app-admin-settings-faq',
  templateUrl: './admin-settings-faq.component.html',
  styleUrls: ['./admin-settings-faq.component.scss']
})
export class AdminSettingsFaqComponent implements OnInit {

  visibleFq = {};
  adminSettingsFq = [];
  AConst = AConst;

  constructor(private stateService: StateService, private cms: CmsApiService) {
  }

  ngOnInit() {
    this.getFaq();
  }

  toggleFq(index) {
    this.visibleFq[index] = !this.visibleFq[index];
  }

  private getFaq() {
    this.cms.getFaq({category: this.stateService.params['admin_type']}).then((data) => {
        this.adminSettingsFq = data[AConst.FAQ];
      },
      function (failed) {
        console.error('This failed! ' + failed.message);
      }
    );
  }


}
