import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateToolsService} from '../../core/date-tools.service';
import {SectionsContainer} from '../../core/sections-container';
import {MatDatepickerInputEvent} from '@angular/material';
import {FieldValidationService} from '../../core/field-validation.service';


@Component({
  selector: 'app-edit-field-date-picker',
  templateUrl: './edit-field-date-picker.component.html',
  styleUrls: ['./edit-field-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldDatePickerComponent)
    }
  ]
})
export class EditFieldDatePickerComponent implements OnInit, ControlValueAccessor {

  @Input() object: any;
  @Input() field: any;
  @Input() otherField: any;
  @Input() sectionsContainer: SectionsContainer;
  @Input() formControlName: string;
  @Input() otherFormControlName: string;
  @Input() isToDate: boolean;
  @Input() placeholder: string;

  dateControl: AbstractControl;
  otherDateControl: AbstractControl;
  pickerDateControl: FormControl;
  private inFocus;

  constructor(private dateTools: DateToolsService,
              private fieldValidation: FieldValidationService) {
  }

  ngOnInit() {
    const precisionDate = this.object[this.field.name];
    const fieldDateValue = this.dateTools.precisionDateToDate(precisionDate);
    this.pickerDateControl = new FormControl(fieldDateValue);
    this.dateControl = this.sectionsContainer.formGroup.controls[this.formControlName];
    if (this.otherFormControlName) {
      this.otherDateControl = this.sectionsContainer.formGroup.controls[this.otherFormControlName];
    }
  }

  isInvalid() {
    return this.fieldValidation.isFieldInvalid(this.sectionsContainer, this.formControlName);
  }

  onFieldBlur() {
    this.inFocus = false;
  }

  onFieldFocus() {
    if (!this.sectionsContainer.isDialog) {
      this.inFocus = true;
      if (this.isToDate && this.dateControl.value === null) {
        const fromDate = this.object[this.otherField.name];
        if (fromDate) {
          this.object[this.field.name] = fromDate;
          this.pickerDateControl.setValue(this.dateTools.precisionDateToDate(fromDate));
        }
      }
    }
  }

  onValueChange($event) {
    const value = $event.target.value;
    const validDate = this.dateTools.validateDate(value);
    if (typeof validDate !== 'string') {
      this.object[this.field.name] = validDate;
      this.dateControl.setValue(this.dateTools.precisionDateToString(validDate));
      if (validDate && this.otherDateControl) {
        this.otherDateControl.updateValueAndValidity();
      }
    } else {
      // This will trigger validations
      this.dateControl.setValue($event.target.value);
    }
  }

  onDateSelection(dateEvent: MatDatepickerInputEvent<Date>) {
    this.dateControl.markAsDirty();
    if (dateEvent.value) {
      const validDate = this.dateTools.dateToPrecisionDate(dateEvent.value['_d']);
      this.object[this.field.name] = validDate;
      this.dateControl.setValue(this.dateTools.precisionDateToString(validDate));
    }
  }

  get minDate() {
    let minDate = new Date(-5000, 0, 1);
    if (this.isToDate) {
      minDate = this.dateTools.precisionDateToDate(this.object[this.otherField.name]) || minDate;
      // minDate = this.dateTools.precisionDateToYearMonthDayObject(this.object[this.otherField.name]) || minDate;
    }
    return minDate;
  }

  get maxDate() {
    let maxDate = new Date(5000, 0, 1);
    if (!this.isToDate && this.otherField) {
      // maxDate = this.dateTools.precisionDateToYearMonthDayObject(this.object[this.otherField.name]) || maxDate;
      maxDate = this.dateTools.precisionDateToDate(this.object[this.otherField.name]) || maxDate;
    }
    return maxDate;
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
  }

}
