import { Injectable } from '@angular/core';
import {ReporterPdfConfigHelperService} from './reporter-pdf-config-helper.service';
import {ReporterPdfGridReportFrontPageService} from './reporter-pdf-grid-report-front-page.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Service hosting methods used when rendering a pages in a grid-based PDF-report.
 * @type {{getDocDef, create3by2}}
 */
export class ReporterPdfGridReportService {
  private bulkSize = 0;
  private numPages = 0;
  private rows = 0;
  private cols = 0;

  private frontPageTexts = null;

  constructor(
    private reporterPdfGridReportFrontPage: ReporterPdfGridReportFrontPageService,
    private reporterPdfConfigHelper: ReporterPdfConfigHelperService) { }

  /**
   * Calculates the total number of pages in the report being created.
   * @param numArtifacts
   * @private
   */
  _calcNumPages(numArtifacts) {
    this.bulkSize = this.cols * this.rows;
    this.numPages = Math.ceil(numArtifacts / this.bulkSize);
  }

  /**
   * Creates the specified page, populating it with the specified artifacts.
   * @param artifacts
   * @param pageIndex
   * @returns object
   * @private
   */
  _create(artifacts, pageIndex) {

    // Calculate number of spacing columns
    const numSpaces = this.cols - 1;              // 2 => 1 and 3 => 2
    const totalCols = this.cols + numSpaces;      // 2+1 => 3 and 3+2 => 5

    let widths = null;
    if (this.cols === 2) {
      widths = ['47%', '5%', '47%'];
    } else if (this.cols === 3) {
      widths = ['32%', '2%', '32%', '2%', '32%'];
    }

    const rows = [];
    let cols = [];

    for (let n = 0; n < artifacts.length; n++) {
      const obj = artifacts[n];

      let img = (typeof(obj.image) !== 'undefined') ? obj.image : null;

      if (img != null) {
        img = {
          table: {
            widths: ['*'],
            body: [[img]]
          },
          layout: {
            hLineWidth: function (i) { return 0; },
            vLineWidth: function (i) { return 0; },
            hLineColor: function (i) { return '#7e97ad'; }
          }
        };
      }

      const title = (typeof(obj.title) !== 'undefined') ? obj.title : null;
      const data = (typeof(obj.data) !== 'undefined') ? obj.data : null;

      const pdfArtifact = [
        img,
        title,
        data
      ];

      cols.push(pdfArtifact); // Store artifact as cell

      if (cols.length < totalCols) {
        cols.push({});  // Add spacing cell
      }

      if (n > 0 && cols.length % totalCols === 0) {
        rows.push(cols);    // Add columns to current row.
        cols = [];
      }
    }

    if (cols.length > 0 && rows.length === 0) {
      // If the total no. of artifacts selected is lower than the columns pr. row
      // definition selected in this report type, insert all columns
      // in the same row.
      rows.push(cols);
    } else if (cols.length > 0) {
      // Make sure the row is filled with the specified number of columns,
      // to avoid breaking the pdf make JSON table-structure.
      if (cols.length < totalCols) {
        while (cols.length < totalCols) {
          cols.push({});
        }
        rows.push(cols);
      }
    }

    const json = {
      table: {
        headerRows: 1,
        widths: widths,
        body: rows
      },
      layout: {
        hLineWidth: function (i) { return 0; },
        vLineWidth: function (i) { return 0; },
        hLineColor: function (i) { return '#dddddd'; },
        height: function (i) { return 100; }
      },
      pageBreak: 'after'
    };

    if (pageIndex === (this.numPages - 1)) {
      json.pageBreak = null;
    }

    return json;
  }

  /**
   * Creates a grid-based page, based on the previously set grid-type.
   * @param artifacts
   * @param createdHr
   * @param filterSettings
   * @returns {Array}
   * @private
   */
  private createGridPage(artifacts, createdHr, filterSettings) {
    return new Promise((resolve) => {
      this._calcNumPages(artifacts.length);

      const pages = [];

      this.reporterPdfGridReportFrontPage.create(this.frontPageTexts, createdHr, filterSettings).then((res) => {
        pages.push(res);

        for (let i = 0; i < this.numPages; i++) {
          const start = i * this.bulkSize,
            end = this.bulkSize + (i * this.bulkSize);

          const artifactsBulk = artifacts.slice(start, end);
          pages.push(this._create(artifactsBulk, i));
        }
        resolve(pages);
      });
    });
  }

  /**
   * Creates a page containing 2 rows, each with 2 artifacts.
   * @param artifacts
   * @param frontPageTexts
   * @param createdHr
   * @param filterSettings
   * @returns JSON
   */
  create2by2(artifacts, frontPageTexts, createdHr, filterSettings) {
    this.frontPageTexts = frontPageTexts;

    return new Promise((resolve) => {
      this.cols = 2;
      this.rows = 2;

      const docDef = this.reporterPdfConfigHelper.get2x2DocDef(this.frontPageTexts, createdHr);
      this.createGridPage(artifacts, createdHr, filterSettings).then(function (pages) {
        docDef.content = pages;
        resolve(docDef);
      });
    });
  }

  /**
   * Creates a page containing 2 rows, each with 3 artifacts.
   * @param artifacts
   * @param frontPageTexts
   * @param createdHr
   * @param filterSettings
   * @returns JSON
   */
  create3by2(artifacts, frontPageTexts, createdHr, filterSettings) {
    this.frontPageTexts = frontPageTexts;

    return new Promise((resolve) => {
      this.cols = 3;
      this.rows = 2;

      const docDef = this.reporterPdfConfigHelper.get3x2DocDef(this.frontPageTexts, createdHr);
      this.createGridPage(artifacts, createdHr, filterSettings).then(function (pages) {
        docDef.content = pages;
        resolve(docDef);
      });
    });
  }

  /**
   * Creates a table with the primary object image to the left,
   * and the object's metadata to the right.
   * @param artifacts
   * @param frontpageTexts
   * @param createdHr
   * @param filterSettings
   */
  createImageToTheLeftTable(artifacts, frontpageTexts, createdHr, filterSettings) {
    this.frontPageTexts = frontpageTexts;

    return new Promise( resolve => {
      this.cols = 2;
      this.rows = 1;

      const docDef = this.reporterPdfConfigHelper.getImageToTheLeftTable(this.frontPageTexts, createdHr);
      this.createGridPage(artifacts, createdHr, filterSettings).then( pages => {
        docDef.content = pages;
        resolve(docDef);
      });
    });
  }
}
