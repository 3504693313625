import {Injectable} from '@angular/core';
import {Annotation} from '../image-annotation/annotation';
import {ModelFactoryService} from './model-factory.service';
import {CommonsService} from './commons.service';
import {ObjectDeletionService} from './object-deletion.service';
import {UiToolsService} from './ui-tools.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentObjectService {
  get className(): string {
    return this._className;
  }

  set className(value: string) {
    this._className = value;
  }
  get isEditing(): boolean {
    return this._isEditing;
  }

  set isEditing(value: boolean) {
    this._isEditing = value;
  }

  get currentObject() {
    return this._currentObject;
  }

  set currentObject(value) {
    this._currentObject = value;
  }

  get curAnn(): Annotation {
    if (!this._curAnn) {
      this._curAnn = new Annotation(this.modelFactory, this.commons, this.objectDeletionService, this.uiTools, this);
    }
    return this._curAnn;
  }

  set curAnn(value: Annotation) {
    this._curAnn = value;
  }

  private _isEditing = false;
  private _currentObject;
  private _curAnn: Annotation;
  private _className: string;

  constructor(private modelFactory: ModelFactoryService,
              private commons: CommonsService,
              private objectDeletionService: ObjectDeletionService,
              private uiTools: UiToolsService) {
  }
}
