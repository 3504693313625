import {Injectable} from '@angular/core';
import {CommonsService} from './commons.service';
import {ChangeTrackerService} from './change-tracker.service';
import {AConst} from './a-const.enum';
import {FieldStateService} from './field-state.service';
import {ObjectFieldTraverseService} from './object-field-traverse.service';
import {FieldParameters} from './field-parameters';
import {CmsQueueService} from './cms-queue.service';

@Injectable({
  providedIn: 'root'
})
export class FieldConditionService {

  userData;
  settings;
  ready = false;

  constructor(private fieldStateSvc: FieldStateService,
              private changeTracker: ChangeTrackerService,
              private cmsQueue: CmsQueueService,
              private commons: CommonsService,
              private objectFieldTraverse: ObjectFieldTraverseService) {

    this.commons.getUserData(false).then((data) => {
      this.userData = data;
      this.setReady();
    });

    cmsQueue.runCmsFnWithQueue('getSettings').then(
      (data) => {
        this.settings = data;
        this.setReady();
      }
    );

  }

  runIf(ifType, fieldParameters: FieldParameters) {
    const ifItem = this.getIfByType(fieldParameters, ifType);
    let res = true;
    if (ifItem) {
      res = this.runIfItem(ifItem, fieldParameters);
    } else {
      res = this.getDefaultRes(ifType);
    }

    if (res && ifItem) {
      this.runIfThen(ifItem, fieldParameters);
    }
    return res;
  }

  private runIfItem(ifItem, fieldParameters: FieldParameters) {
    let t, conditions, cond, res;
    conditions = ifItem[AConst.CONDITIONS] || [];
    for (t = 0; t < conditions.length; t++) {
      cond = conditions[t];
      res = this.runCompare(cond, fieldParameters);
      if (res && ifItem.operator === 'or') {
        break;
      }
      if (!res && ifItem.operator !== 'or') {
        break;
      }
    }
    return res;
  }

  private getCompareValue(value) {
    let res = value;
    if (typeof res === 'string' && res.indexOf('::') !== -1) {
      res = this.getSpecialFieldValue(res);
    }
    if (res === undefined) {
      res = null;
    }
    return res;
  }

  private getSpecialFieldValue(sourceField) {
    let res = null;
    const specialInfo = [
      {prefix: 'user::', fields: this.userData},
      {prefix: 'settings::', fields: this.settings}
    ];
    specialInfo.forEach(comp => {
      let field;
      if (sourceField.indexOf(comp.prefix) === 0) {
        if (comp[AConst.FIELDS]) {
          field = sourceField.substring(comp.prefix.length);
          const fieldParameters = new FieldParameters();
          fieldParameters.rootObject = comp[AConst.FIELDS];
          fieldParameters.field = {name: field};
          res = this.getFieldValueFromModels(field, fieldParameters);
          if (!res) {
            console.warn('Unable to get compare field ' + sourceField);
          }
        } else if (this.ready) {
          throw new Error('No compare data for ' + comp.prefix);
        }
      }
    });
    return res;
  }

  private runCompare(cond, fieldParameters: FieldParameters) {
    let res, fieldVal, otherValue = cond.value;
    const edit = fieldParameters.edit || false;

    if (cond.field) {
      fieldVal = this.getFieldValue(cond.field, fieldParameters);
    }
    if (cond[AConst.COMPARE_FIELD]) {
      otherValue = this.getFieldValue(cond[AConst.COMPARE_FIELD], fieldParameters);
    }
    if (cond[AConst.COND_TYPE] === 'editing') {
      res = edit;
    } else if (cond[AConst.COND_TYPE] === 'not_editing') {
      res = !edit;
    } else if (cond[AConst.COND_TYPE] === 'object_has_changes') {
      res = this.changeTracker.objectHasChanges(fieldParameters.rootObject);
    } else if (cond[AConst.COND_TYPE] === 'object_has_no_changes') {
      res = !this.changeTracker.objectHasChanges(fieldParameters.rootObject);
    } else if (cond[AConst.COND_TYPE] === AConst.EDITION) {
      res = this.compareEdition(cond);
    } else if (cond.values) {
      res = this.compareValues(cond, fieldVal);
    } else {
      res = this.compareValue(cond, fieldVal, otherValue);
    }
    return res;
  }

  private compareEdition(cond) {
    let values = [], res = false;
    if (cond.value) {
      values = [cond.value];
    } else if (cond.values) {
      values = cond.values;
    } else {
      console.warn('Nothing compares to you');
    }
    values.forEach(value => {
      if (!res) {
        res = this.userData ? value.toLowerCase() === this.userData[AConst.EDITION].toLowerCase() : false;
      }
    });
    return res;
  }

  private compareValue(sif, value, otherValue) {
    const compValue1 = this.getCompareValue(value);
    const compValue2 = this.getCompareValue(otherValue);
    return this.commons.compareValues(compValue1, sif[AConst.COMPARATOR],
      compValue2);
  }

  private compareValues(sif, fieldVal) {
    let res, t;
    for (t = 0; t < sif.values.length; t++) {
      res = this.compareValue(sif, sif.values[t], fieldVal);
      if (sif[AConst.COMPARATOR] !== '!=') {
        if (res) {
          break;
        }
      } else {
        if (!res) {
          break;
        }
      }
    }
    return res;
  }

  private getIfByType(fieldParameters: FieldParameters, ifType) {
    let res, ifs, i, ifItem;
    if (fieldParameters.field['$$hasIf'] && fieldParameters.field['$$hasIf'][ifType] !== undefined) {
      res = fieldParameters.field['$$hasIf'][ifType];
    } else {
      fieldParameters.field['$$hasIf'] = fieldParameters.field['$$hasIf'] || {};
      fieldParameters.field['$$hasIf'][ifType] = false;
      ifs = fieldParameters.field[AConst.FIELD_IFS];
      if (ifs && !Array.isArray(ifs)) {
        ifs = [ifs];
      }
      if (ifs && ifs.length > 0) {
        for (i = 0; i < ifs.length; i++) {
          ifItem = ifs[i];
          if (ifItem[AConst.IF_TYPE] !== ifType) {
            continue;
          }
          res = ifItem;
          fieldParameters.field['$$hasIf'][ifType] = ifItem;
          break;
        }
      }
    }
    return res;
  }

  private getFieldValue(fieldIn, fieldParameters: FieldParameters) {
    let fieldVal;
    const field = this.getReplaceField(fieldIn, fieldParameters);
    if (field === null) {
      return null;
    }
    if (field.indexOf('::') === -1) {
      fieldVal = this.getFieldValueFromModels(field, fieldParameters);
    } else {
      fieldVal = this.getSpecialFieldValue(field);
    }
    return fieldVal;
  }

  private getReplaceField(field, fieldParameters: FieldParameters) {
    let res = field, searchField, start, end, replaceField;
    start = field.indexOf('{');
    if (start > -1) {
      end = field.indexOf('}');
      if (end > start) {
        searchField = field.substring(start + 1, end);
        replaceField = this.getFieldValue(searchField, fieldParameters);
        if (replaceField !== null) {
          res = field.substring(0, start) + replaceField +
            field.substring(end + 1);
        } else {
          res = null;
        }
      }
    }
    return res;
  }

  private getFieldPath(fieldName, fieldParameters: FieldParameters) {
    let fieldKey = '';
    const field = this.getFieldKeyInfo(fieldName, fieldParameters);
    if (field) {
      fieldKey = this.fieldStateSvc.getFieldKeyWhileDrawingInputs(field, fieldParameters.index, fieldParameters.parentIndex);
      if (fieldKey.indexOf(fieldName) === -1) {
        // Field name is a compare field. Need to exchange the last part of the field key with the compare field name
        const lastPathSep = fieldKey.lastIndexOf('.');
        if (lastPathSep !== -1) {
          fieldKey = fieldKey.substring(0, lastPathSep + 1) + fieldName;
        }
      }
    } else {
      console.warn('Field info not found for ' + fieldName);
    }
    return fieldKey;
  }

  private getFieldKeyInfo(fieldName, fieldParameters: FieldParameters) {
    let field;
    if (fieldParameters.field.path) {
      const traverseRes = this.objectFieldTraverse.traverseObjectByPath(
        fieldParameters.rootObject, fieldParameters.field.path, 0);
      const parentObject = traverseRes.subObject;
      if (parentObject) {
        const fieldMeta = parentObject[AConst.$$META][fieldName];
        if (fieldMeta) {
          field = {key: traverseRes.parentKey + '.' + fieldName};
        } else {
          console.warn('Field ' + fieldName + ' not found in path ' + fieldParameters.field.path);
        }
      } else {
        console.warn('No sub model found for path ' + fieldParameters.field.path);
      }
    } else {
      const fieldMeta = fieldParameters.rootObject[AConst.$$META][fieldName];
      if (fieldMeta) {
        field = {key: fieldName};
      } else {
        console.warn('Field ' + fieldName + ' not found in root object');
      }
    }
    return field;
  }

  private getFieldValueFromModels(fieldName: string, fieldParameters: FieldParameters) {
    let traverseRes = this.objectFieldTraverse.traverseObjectByPath(fieldParameters.rootObject, fieldName, 0);
    if (traverseRes.subObject === undefined) {
      const fieldPath = this.getFieldPath(fieldName, fieldParameters);
      traverseRes = this.objectFieldTraverse.traverseObjectByPath(fieldParameters.rootObject, fieldPath, 0);
    }
    const fieldVal = traverseRes.subObject;

    if (fieldVal === undefined) {
      console.log('Value not found for field: ' + fieldName);
    }
    return fieldVal;
  }

  private getDefaultRes(ifType) {
    const res = {
      disable: false
    }[ifType];
    return res !== undefined ? res : true;
  }

  private runIfThen(ifItem, fieldParameters: FieldParameters) {
    if (ifItem[AConst.IF_THEN]) {
      ifItem[AConst.IF_THEN].forEach((ifThen) => {
        if (ifThen[AConst.IF_THEN_TYPE] === 'set_default_val') {
          fieldParameters.object[fieldParameters.field.name] = ifThen.value;
        }
      });
    }
  }

  private setReady() {
    this.ready = this.settings && this.userData;
  }

}
