import {Injectable} from '@angular/core';
import {UndoHandlerService} from './undo-handler.service';
import {DateToolsService} from './date-tools.service';
import {FieldMetaHandlerService} from './field-meta-handler.service';
import {AConst} from './a-const.enum';
import {ModelsService} from './models.service';
import {CommonsService} from './commons.service';

@Injectable({
  providedIn: 'root'
})
export class ModelFactoryService {

  constructor(private undoHandler: UndoHandlerService,
              private dateTools: DateToolsService,
              private fieldMetaHandler: FieldMetaHandlerService,
              private models: ModelsService,
              private commons: CommonsService) {
  }

  userData;
  gettingUserData = false;

  private static setInlineDefaultValues(defValue, modelData) {
    for (const key in defValue) {
      if (!defValue.hasOwnProperty(key)) { continue; }
      modelData[key] = defValue[key];
    }
  }
  // Set properties defined in model data but missing in model.
  // This is usually properties not to be stored, like $$arefs
  // and $$acl
  private static setMissingDataProps(modelName, item, data: object,
                                     setProps) {
    if (data && typeof data !== 'object') {
      throw new Error('Data: ' + data + ' is not an object of model ' +
      'type ' + modelName);
    }
    for (const propName in data) {
      if (!data.hasOwnProperty(propName)) { continue; }
      const val = data[propName];
      if (val !== undefined && !setProps[propName]) {
        if (propName !== 'meta_type' &&
          propName.indexOf('$$') !== 0 &&
          !item[AConst.$$META][propName]) {
          console.warn('Property \'' + modelName + '.' +
            propName + '\' of type \'' + typeof val +
            '\' is not defined in models.py!');
        }
        item[propName] = val;
      }
    }
  }
  private static getModelIdField(model) {
    const meta = model[AConst.$$META];
    let res, metaFieldName, metaField;
    if (meta) {
      if (meta[AConst.ARTIFACT_ID]) {
        res = AConst.ARTIFACT_ID;
      } else {
        for (metaFieldName in meta) {
          if (meta.hasOwnProperty(metaFieldName)) {
            metaField = meta[metaFieldName];
            if (metaField[AConst.FIELD_TYPE] === 'id') {
              res = metaFieldName;
            }
          }
        }
      }
    }
    if (!res) {
      throw new Error('Unable to get model id field for model type ' + model[AConst.OBJECT_TYPE]);
    }
    return res;
  }
  private static isArrayItemDeleted(item) {
    return item._destroy;
  }
  // TODO: Set to receive fieldContainer
  public getOrigVal(object, fieldName) {
    return object.$$orig && object.$$orig[fieldName];
  }
  public getInlineModel(metaData) {
    if (!metaData) {
      throw new Error('Meta data not set!');
    }
    return metaData[AConst.INLINE] ? metaData[AConst.INLINE][AConst.MODEL] : null;
  }
  // Validation properties may be set on a level higher up. This
  // requires that grandParentModel is set in validation directive
  // scope and that the root model contains a inlineReq property.
  public setInlineValidProps(fieldContainer) {
    let rootValidProps, inlineReqProp, targetValidProps;
    let inlineMod;
    let res = false;
    if (fieldContainer.grandParentModel) {
      rootValidProps = this.fieldMetaHandler.getMetaParamsFromFieldContainer(
        fieldContainer, 'parent', true);
      if (rootValidProps) {
        targetValidProps = this.fieldMetaHandler.getMetaParamsFromFieldContainer(
          fieldContainer, 'current', true);
        if (rootValidProps[AConst.INLINE]) {
          inlineMod = this.getInlineModel(rootValidProps);
          inlineReqProp =
            rootValidProps[AConst.INLINE][AConst.REQUIRED_FIELD];
          if (inlineReqProp && targetValidProps &&
            inlineMod ===
            fieldContainer.parentModel.object_type) {
            res = true;
            targetValidProps.required = true;
          }
        }
      }
    }
    return res;
  }
  public setModelItemAsync(modelName, data) {
    return new Promise(resolve => {
      this.models.getModelsAsync().then(models => {
        const modelItem = this.setModelItem(modelName, data, models);
        resolve(modelItem);
      });
    });
  }
  public createModelItem(modelName, data?) {
    const item = this.setModelItem(modelName, data);
    item._create = true;
    return item;
  }
  public createModelItemAsync(modelName, data) {
    return new Promise(resolve => {
      this.setModelItemAsync(modelName, data).then(item => {
        item['_create'] = true;
        resolve(item);
      });
    });
  }
  public createAddArrayItem(rootModel, arr, modelName,
                            data?) {
    const item = this.createModelItem(modelName, data);
    this.addArrayItem(arr, item);
    return item;
  }
  public createAddArrayItemAsync(arr, modelName, data) {
    return new Promise(resolve => {
      this.createModelItemAsync(modelName, data).then(
        (item) => {
          this.addArrayItem(arr, item);
          resolve(item);
        }
      );
    });
  }
  public deleteArrayItem(arr, index, rootModel) {
    const item = arr[index];
    const modelIdField = ModelFactoryService.getModelIdField(rootModel);
    if (item._create || !rootModel[modelIdField]) {
      arr.splice(index, 1);
      this.undoHandler.addUndo(arr, item, index);
    } else {
      item._destroy = true;
      this.undoHandler.addUndo(arr, item);
    }
    return item;
  }
  public undoDeleteArrayItem(arr) {
    this.undoHandler.undo(arr);
  }
  // For-each loop that ignores destroyed array elements
  public forEach(arr, fn) {
    arr.forEach((item, index) => {
      if (!ModelFactoryService.isArrayItemDeleted(item)) {
        fn(item, index);
      }
    });
  }

  // Count array elements that have not been deleted
  public countArrayElements(arr) {
    let res = 0;
    arr.forEach(item => {
      if (!ModelFactoryService.isArrayItemDeleted(item)) {
        res++;
      }
    });
    return res;
  }

  public traverseModelField(fn, model, fieldName) {
    this.fieldMetaHandler.getMetaParams({
      parentModel: model,
      propName: fieldName,
      noThrow: true,
      fn: (meta) => {
        const subMod = model[fieldName];
        let show;
        if (meta) {
          show = meta.display ? meta.display.show : null;
        }
        if (meta && (meta[AConst.EDIT] || show)) {
          fn(model, fieldName);
          if (this.getInlineModel(meta)) {
            if (Array.isArray(subMod)) {
              subMod.forEach(
                (item, index) => {
                  fn(model, fieldName, index);
                  this.traverseModel(fn, item);
                });
            } else {
              this.traverseModel(fn, subMod);
            }
          }
        }
      }
    });
  }
  private setModelItemProps(modelItem: object, data, models) {
    const propsSet = {};
    for (const propName in modelItem) {
      if (!modelItem.hasOwnProperty(propName)) { continue; }
      let metaData, hadData = false;
      let propVal = this.getPropVal(modelItem, propName);
      let inlineMod, origVal;
      if (propName.indexOf('$$') !== 0) {
        if (data && typeof data[propName] !== 'undefined') {
          propVal = data[propName];
          hadData = true;
        }
        metaData = this.fieldMetaHandler.getMetaParams({
          parentModel: modelItem,
          propName: propName,
          noThrow: true
        });
        if (metaData) {
          inlineMod = this.getInlineModel(metaData);
          if (inlineMod) {
            propVal = this.createSubModelItem(propVal, metaData,
              hadData, models);
          }
          origVal = this.findOrigVal(metaData, propVal);
          if (origVal !== undefined) {
            modelItem['$$orig'] = modelItem['$$orig'] || {};
            modelItem['$$orig'][propName] = origVal;
          }
        }
        modelItem[propName] = propVal;
        propsSet[propName] = true;
      }
    }
    return propsSet;
  }
  private getPropVal(item, propName) {
    let value = item[propName];
    if (!this.userData && !this.gettingUserData) {
      this.gettingUserData = true;
      this.commons.getUserData(false).then(res => {
        this.userData = res;
        this.gettingUserData = false;
      });
    }
    if (typeof value === 'string') {
      if (value === 'user.default_collection_id') {
        if (this.userData) {
          value = this.userData[AConst.DEFAULT_COLLECTION_ID];
        } else {
          console.warn('User data not obtained yet!');
        }
      }
    }
    return value;
  }

  // Set sub model items belonging to arrays or inline objects
  // of a parent model item
  private createSubModelItem(origVal, metaData, hadData, models) {
    let arr, propVal = origVal;
    const primitives = ['string', 'numeric', 'decimal',
      'boolean', 'text'];
    const inlineMod = this.getInlineModel(metaData);
    models = models || this.models.getModels(false);
    if (metaData[AConst.FIELD_TYPE] === 'inline') {
      if (!hadData) { // Get default value from sub model
        propVal = this.commons.copy(models[inlineMod]);
        ModelFactoryService.setInlineDefaultValues(origVal, propVal);
      }
      if (metaData[AConst.INLINE][AConst.TODAY_DATE] &&
        (!propVal || !propVal.dd_date)) {
        propVal = this.createTodayDate(metaData, models);
      } else {
        propVal = this.setModelItem(inlineMod, propVal,
          models);
      }
    } else if (metaData[AConst.FIELD_TYPE] === 'array') {
      arr = [];
      propVal.forEach((arrItem) => {
        if (primitives.indexOf(inlineMod) !== -1) {
          arr.push(arrItem);
        } else {
          arr.push(this.setModelItem(inlineMod,
            arrItem, models));
        }
      });
      propVal = arr;
    }
    return propVal;
  }
  private createTodayDate(metaData, models) {
    let propVal;
    const inlineMod = this.getInlineModel(metaData);
    models = models || this.models.getModels(false);
    propVal = this.commons.copy(models[inlineMod]);
    propVal.dd_date = this.dateTools.getTodayUtcTime();
    return this.setModelItem(inlineMod, propVal, models);
  }
  // Recursively populate a model with data. "Data" can either be
  // data from server or default values defined in model
  private setModelItem(modelName, data?, models?) {
    let modelItem = {
      _create: false
    }, propsSet = {};
    models = models || this.models.getModels(false);
    if (models !== null && modelName in models) {
      modelItem = this.commons.copy(models[modelName]);
      propsSet = this.setModelItemProps(modelItem, data, models);
      ModelFactoryService.setMissingDataProps(modelName, modelItem, data,
        propsSet);
    } else {
      console.warn('Unknown model \'' + modelName + '\'');
    }
    return modelItem;
  }
  private addArrayItem(arr, item) {
    arr.push(item);
    this.undoHandler.resetUndo(arr);
    this.checkSetOrderNumber(arr, item);
  }
  private checkSetOrderNumber(arr, item) {
    let lastOrder = -1;
    const orderNumbers = {};
    if (!item.order_number) {
      arr.forEach((i) => {
        if (i.order_number !== undefined && i.order_number !== null) {
          if (orderNumbers[i.order_number]) {
            console.warn('Order number already existed: ' + i.order_number);
            i.order_number++;
          }
          lastOrder =
            Math.max(i.order_number, lastOrder);
          orderNumbers[i.order_number] = true;
        }
      });
      item.order_number = lastOrder + 1;
    }
  }
  private findOrigVal(metaData, propVal) {
    let t, val, arrVal;
    const arr = [];
      const inlineMod = this.getInlineModel(metaData);
    const show = metaData.display ? metaData.display.show : null;
    if ((metaData.edit && metaData.edit.indexOf('edit') === 0) ||
      show) {
      val = propVal;
      if (inlineMod) {
        if (Array.isArray(propVal)) {
          for (t = 0; t < propVal.length; t++) {
            arrVal = propVal[t].order_number || t;
            arr.push(arrVal);
          }
          val = arr;
        }
      }
    }
    return this.commons.copy(val);
  }
  /**
   * Traversing an object and executing a callback every time
   * an editable or displayable field is reached
   * @param fn the callback receives the parameters model, field
   * name and, if the model is an array, an index for each item
   * in the array
   *
   * @param model the model to traverse
   */
  private traverseModel(fn, model: object) {
    for (const fieldName in model) {
      if (!model.hasOwnProperty(fieldName)) { continue; }
      if (fieldName.indexOf('$$') !== 0) {
        this.traverseModelField(fn, model, fieldName);
      }

    }
  }
}
