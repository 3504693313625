export class ExtArray<T> {
  // ========================================================================
  //  Expanding Array object
  // ========================================================================
  constructor(private items?: Array<T>) {
    if (!items) {
      this.items = [];
    }
  }

  merge(arr) {
    this.items.push.apply(this, arr)
  }

  removeDuplicates() {
    return this.items.filter((elem, index, self) => {
      return index == self.indexOf(elem);
    });
  }

  getItems() {
    return this.items;
  }
}
