import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {Annotation} from '../annotation';

@Component({
  selector: 'app-annotation-dashboard',
  templateUrl: './annotation-dashboard.component.html',
  styleUrls: ['./annotation-dashboard.component.scss']
})
export class AnnotationDashboardComponent implements OnInit {

  @Input() curAnn: Annotation;
  @Input() target;
  @Input() mediaList;

  AConst = AConst;

  constructor() {
  }

  ngOnInit() {
  }

  hideParent() {
    let res = true;
    const ane = this.curAnn.ane;
    if (!this.target && ane) {
      if (ane[AConst.ARTIFACT_ID]) {
        res = !(ane[AConst.CONTEXTS].length > 0 || ane[AConst.PARENT_ID]);
      }
    }
    return res;
  }

}
