import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchContainer} from '../../search-container';
import {SearchHandlerService} from '../../search-handler.service';
import {UiToolsService} from '../../../core/ui-tools.service';
import {AConst} from '../../../core/a-const.enum';
import {group, query, stagger, style, transition, trigger, useAnimation} from '@angular/animations';
import {fadeIn, fadeOut, slideDownMenu, slideUpMenu} from '../../../shared/animations';

@Component({
  selector: 'app-search-menu-view-selector',
  templateUrl: './search-menu-view-selector.component.html',
  styleUrls: ['./search-menu-view-selector.component.scss'],
  animations: [
    trigger('slideInViewSelector', [
      transition(':leave', [
        group([
        useAnimation(slideUpMenu, { params: {time: '300ms ease-in-out'}}),
          query('li', [
            style({opacity: 1}),
            stagger(-100, useAnimation(fadeOut, {params: {time: '50ms'}}))
          ]),
        ])
      ]),
      transition(':enter', [
        group([
          useAnimation(slideDownMenu, { params: {time: '300ms ease'}}),
          query('li', [
            style({opacity: 0}),
            stagger(100, useAnimation(fadeIn, {params: {time: '50ms'}}))
          ]),
        ])
      ]),
    ])
  ]
})
export class SearchMenuViewSelectorComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;

  currentIcon = '';
  currentDesc = '';
  searchViewClass = 'search';

  private clickListenerId: string;
  private oldTempGroupId: string;

  constructor(private searchHandler: SearchHandlerService,
              private uiTools: UiToolsService) {
  }

  ngOnInit() {
    if (this.searchContainer.searchResultViewName === 'content-list' ||
      this.searchContainer.searchResultViewName === 'selector') {
      this.searchViewClass = 'object';
    }
    this.oldTempGroupId = this.searchContainer.templateGroupId;
    this.clickListenerId = this.uiTools.addDocumentClickListener(() => {
      this.closeMenu();
    });
    this.searchHandler.subscribeToSearchResult(this.searchContainer, () => {
      this.setCurrentSearchResultView();
    });
  }

  ngOnDestroy(): void {
    if (this.clickListenerId) {
      this.uiTools.removeDocumentClickListener(this.clickListenerId);
    }
    this.searchHandler.unSubscribeToSearchResult(this.searchContainer, () => {
      this.setCurrentSearchResultView();
    });
  }

  setCurrentView(view) {
    this.currentIcon = view.icon;
    this.currentDesc = view.description;
    this.searchHandler.setCurrentResultView(view.name, this.searchContainer);
    this.searchHandler.runSearch(this.searchContainer, true).then();
  }

  toggleViewModeMenu() {
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    this.searchContainer.toggleView = !this.searchContainer.toggleView;
    this.searchContainer.toggleSortMenu = false;
    this.searchContainer.toggleActionMenu = false;
  }


  private setCurrentSearchResultView() {
    this.setCurrentIcon(this.searchContainer);
  }

  private closeMenu() {
    if (this.searchContainer) {
      this.searchContainer.toggleView = false;
    }
  }

  private setCurrentIcon(searchContainer) {
    if (searchContainer) {
      let views = searchContainer.currentPathView[AConst.SEARCH_RESULT_VIEWS];
      if (!views) {
        views = searchContainer.searchView[AConst.SEARCH_RESULT_VIEWS];
      }
      searchContainer.searchResultViews = views;
      views.views.forEach(view => {
        if (view.name === searchContainer.searchResultViewName) {
          this.currentIcon = view.icon;
          this.currentDesc = view.description;
        }
      });
    }
  }


}
