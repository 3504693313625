export class ExtString {
  // ========================================================================
  //  Expanding String object
  // ========================================================================

  constructor(private myString: String) { }
  /**
   * Adds the specified pad to the left of the string until the specified size is reached.
   * @param pad
   * @param size
   * @returns {*}
   */
  lpad(pad, size) {
    let str = this.myString;

    while (str.length < size) {
      str = pad + str;
    }

    return str;
  }

  /**
   * Replaces special characters with their HTML-safe equivalent codes.
   */
  escapeHtml() {
    return this.myString.replace(/[&<>"'\/æøåÆØÅöä©®±¼ÀÁÂÃÄÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ÙÚÛÜÝÞßàáâãçèéêëìíîïðñòóôõ÷ùúûüýþÿŒœŠšŸƒ‰€™]/g, (s) => {
      // noinspection NonAsciiCharacters
      let entityMap = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': '&quot;',
        "'": '&#39;',
        "/": '&#x2F;',
        "æ": '&aelig;',
        "å": '&aring;',
        "Æ": '&AElig;',
        "Ø": '&#216;',
        "Å": '&Aring;',
        "ä": '&#228;',
        "©": '&#169;',
        "®": '&#174;',
        "±": '&#177;',
        "¼": '&#188;',
        "½": '&#189;',
        "¾": '&#190;',
        "À": '&#192;',
        "Á": '&#193;',
        "Â": '&#194;',
        "Ã": '&#195;',
        "Ä": '&#196;',
        "Ç": '&#199;',
        "È": '&#200;',
        "É": '&#201;',
        "Ê": '&#202;',
        "Ë": '&#203;',
        "Ì": '&#204;',
        "Í": '&#205;',
        "Î": '&#206;',
        "Ï": '&#207;',
        "Ð": '&#208;',
        "Ñ": '&#209;',
        "Ò": '&#210;',
        "Ó": '&#211;',
        "Ô": '&#212;',
        "Õ": '&#213;',
        "Ö": '&#214;',
        "×": '&#215;',
        "Ù": '&#217;',
        "Ú": '&#218;',
        "Û": '&#219;',
        "Ü": '&#220;',
        "Ý": '&#221;',
        "Þ": '&#222;',
        "ß": '&#223;',
        "à": '&#224;',
        "á": '&#225;',
        "â": '&#226;',
        "ã": '&#227;',
        "ç": '&#231;',
        "è": '&#232;',
        "é": '&#233;',
        "ê": '&#234;',
        "ë": '&#235;',
        "ì": '&#236;',
        "í": '&#237;',
        "î": '&#238;',
        "ï": '&#239;',
        "ð": '&#240;',
        "ñ": '&#241;',
        "ò": '&#242;',
        "ó": '&#243;',
        "ô": '&#244;',
        "õ": '&#245;',
        "ö": '&#246;',
        "÷": '&#247;',
        "ø": '&#248;',
        "ù": '&#249;',
        "ú": '&#250;',
        "û": '&#251;',
        "ü": '&#252;',
        "ý": '&#253;',
        "þ": '&#254;',
        "ÿ": '&#255;',
        "Œ": '&#338;',
        "œ": '&#339;',
        "Š": '&#352;',
        "š": '&#353;',
        "Ÿ": '&#376;',
        "ƒ": '&#402;',
        "‰": '&#8240;',
        "€": '&#8364;',
        "™": '&#8482;'
      };

      return entityMap[s];
    });
  }

}
