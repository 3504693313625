import {Component, ElementRef, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {CurrentObjectService} from '../../core/current-object.service';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-object-content-main-tab',
  templateUrl: './object-content-main-tab.component.html',
  styleUrls: ['./object-content-main-tab.component.scss']
})
export class ObjectContentMainTabComponent implements OnChanges {
  @Input() menu;
  @Input() invisible: boolean;
  @Output() menuOpened = new EventEmitter();

  AConst = AConst;
  private tabItem;

  constructor(public currentObjectService: CurrentObjectService,
              private tabElementRef: ElementRef) {
  }

  ngOnChanges() {
    this.tabItem = this.tabElementRef.nativeElement;
  }

  openMenu() {
    this.menuOpened.emit(this.menu);
  }

  getTabElement() {
    return this.tabElementRef.nativeElement;
  }

}
