import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {SectionsContainer} from '../../core/sections-container';
import {FolderService} from '../../core/folder.service';
import {NotificationService} from '../../shared/notification.service';
import {ObjectEditService} from '../../core/object-edit.service';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-folder-append-dialog',
  templateUrl: './folder-append-dialog.component.html',
  styleUrls: ['./folder-append-dialog.component.scss']
})
export class FolderAppendDialogComponent implements OnInit {

  @Input() objectIds;

  sectionsContainer: SectionsContainer = new SectionsContainer();
  storeStatus = {
    failed: false,
    message: '',
    serverMsg: ''
  };

  constructor(public dialogRef: MatDialogRef<FolderAppendDialogComponent>,
              private objectEditService: ObjectEditService,
              private folderService: FolderService,
              private notificationService: NotificationService) { }

  ngOnInit() {
    this.objectEditService.createModelItemGetSectionsContainer(AConst.MY_FOLDER).then(sectionsContainer => {
      this.sectionsContainer = sectionsContainer;
      this.sectionsContainer.isDialog = true;
    });
  }

  onSubmit() {
    const folderId = this.sectionsContainer.rootObject[AConst.FOLDER_ID];
    this.folderService.addFolderItemsStoreFolder(folderId, this.objectIds).then(
      folder => {
        this.notificationService.addNotification({
          messages: ['TRANS__FOLDER__APPEND_SUCCESS'],
          values: {
            count: this.objectIds.length,
            folderName: folder[AConst.TITLE]
          },
          type: 'success'
        });

        this.dialogRef.close(folder);
      },
      reason => {
        this.storeStatus.failed = true;
        this.storeStatus.message = 'TRANS__EDIT_OBJECT_DIALOG__STORE_FAILED';
        this.storeStatus.serverMsg = reason.error.message;
      }
    );
  }

  get isValid(): boolean {
    return this.sectionsContainer.formGroup.valid;
  }

}
