import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {FieldConditionService} from '../../core/field-condition.service';
import {SectionsContainer} from '../../core/sections-container';
import {CommonsService} from '../../core/commons.service';
import {FieldParameters} from '../../core/field-parameters';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-edit-fields',
  templateUrl: './edit-fields.component.html',
  styleUrls: ['./edit-fields.component.scss']
})
export class EditFieldsComponent implements OnChanges {

  AConst = AConst;

  @Input() sectionsContainer: SectionsContainer;
  @Input() object;
  @Input() grandParentObject;
  @Input() fields;
  @Input() index: number;
  @Input() parentIndex: number;
  @Input() form: NgForm;
  @Output() closeEdit = new EventEmitter<any>();

  fieldParameters: FieldParameters;
  orderedFields = [];

  constructor(private fieldCondition: FieldConditionService,
              private commons: CommonsService) {
  }

  ngOnChanges() {
    if (this.form) {
      this.sectionsContainer.form = this.form;
    }
    this.orderedFields = this.commons.sortArray(this.fields, 'order');
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters.rootObject = this.sectionsContainer.rootObject || this.object;
    this.fieldParameters.grandParentObject = this.grandParentObject;
    this.fieldParameters.object = this.object;
    this.fieldParameters.edit = true;
    this.fieldParameters.index = this.index;
    this.fieldParameters.parentIndex = this.parentIndex;
  }

  canShow(field) {
    this.fieldParameters.field = field;
    return this.fieldCondition.runIf('show', this.fieldParameters);
  }

  onCloseEdit() {
    this.closeEdit.emit();
  }
}
