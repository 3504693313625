import {Component, OnDestroy, OnInit} from '@angular/core';
import {StateService, UIRouterGlobals} from '@uirouter/core';
import {MatDialog} from '@angular/material';

import {UiToolsService} from '../../core/ui-tools.service';
import {NotificationService} from '../../shared/notification.service';
import {ProgressDialogComponent} from '../../shared/progress-dialog/progress-dialog.component';
import {CmsApiService} from '../../core/cms-api.service';
import {CommonsService} from '../../core/commons.service';
import {ActionMenuService} from '../../core/action-menu.service';
import {AConst} from '../../core/a-const.enum';
import {DateToolsService} from '../../core/date-tools.service';
import {ActionMenuContainer} from '../../core/action-menu-container';

@Component({
  selector: 'app-admin-import-authorities',
  templateUrl: './admin-import-authorities.component.html',
  styleUrls: ['./admin-import-authorities.component.scss']
})
export class AdminImportAuthoritiesComponent implements OnInit, OnDestroy {

  searchParams = {
    query: ''
  };
  mediumScreen;
  actionMenuContainer: ActionMenuContainer;
  actions = [];
  typeFilter;
  selectedFilter = {value: '', label: 'ALL'};
  authority_statusFilter;
  topBarToggler = false;
  parent;
  authorities = [];
  editView = {name: ''};

  private lastClickedIndex;
  private clicks = 0;
  private adminType;
  private stopSizeWatch;
  private clickListenerId;

  constructor(
    private stateService: StateService,
    private uiRouterGlobals: UIRouterGlobals,
    private modalService: MatDialog,
    private uiTools: UiToolsService,
    private notificationService: NotificationService,
    private cms: CmsApiService,
    private commons: CommonsService,
    private actionMenuService: ActionMenuService,
    private dateTools: DateToolsService) {
  }

  ngOnInit() {
    this.mediumScreen = this.uiTools.setMediumScreenSizeOnLoad(this.mediumScreen);
    this.actionMenuContainer = this.actionMenuService.newActionMenuContainer();
    this.adminType = this.uiRouterGlobals.params['admin_type'];
    this.stopSizeWatch = this.uiTools.addWindowSizeListener(newVal => {
        this.mediumScreen = newVal.width < 1025;
      }
    );
    this.clickListenerId = this.uiTools.addDocumentClickListener(() => {
      this.topBarToggler = false;
    });

    this.cms.getKulturNAVLists({}).then(data => {
        this.authorities = this.getAuthoritiesFiltered(data);
      },
      message => {
        console.error(message);
      }
    );

    this.actions = [
      {
        containerClass: 'register-button-container',
        buttonClass: 'register-button',
        type: 'register',
        fn: () => {
          this.saveAuthorities();
        },
        buttonText: 'TRANS__EDIT__STORE_ARTIFACT'
      },
      {
        buttonClass: 'cancel-button',
        fn: () => {
          this.stateService.go(this.stateService.current, {}, {reload: true});
        },
        buttonText: 'TRANS__EDIT__CANCEL'
      }
    ];
    this.setParent();
    this.selectTypeFilter(this.uiRouterGlobals.params.typeFilter);

  }

  ngOnDestroy(): void {
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
    this.uiTools.removeDocumentClickListener(this.clickListenerId);
  }

  search(row) {
    const source = (row[AConst.SOURCE_NAME]).toLocaleLowerCase() || '';
    const query = (this.searchParams.query).toLocaleLowerCase() || '';
    return source.indexOf(query) !== -1;
  }

  toggleStatusFilter() {
    if (this.authority_statusFilter === null || this.authority_statusFilter.value === '') {
      this.authority_statusFilter = {value: true, label: 'DEACTIVATED'};
    } else if (this.authority_statusFilter.value === true) {
      this.authority_statusFilter = {value: false, label: 'ACTIVE'};
    } else if (this.authority_statusFilter.value === false) {
      this.authority_statusFilter = {value: '', label: 'ALL'};
    }
  }

  topBarSmallScreenToggle() {
    this.topBarToggler = !this.topBarToggler;
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
  }

  toggleList(item, index) {
    if (!item[AConst.AUTHORITY_MARKED]) {
      // || (item[AConst.AUTHORITY_MARKED] && item.selected)
      this.editView = {
        name: 'authority_edit'
      };
    }
    if (item[AConst.AUTHORITY_MARKED]) {
      this.onClick(index, this.lastClickedIndex);
      this.lastClickedIndex = index;
    }
  }

  getDate(object, fieldName) {
    return this.dateTools.precisionDateToString(object[fieldName]);
  }

  // (authorities | orderBy:'source_name' | filter: search | filter:{owner_type: typeFilter} |
  // filter:{authority_marked: authority_statusFilter.value} | filter:{selected: selectedFilter.value})
  private getAuthoritiesFiltered(authorities) {
    const res = [];
    if (authorities) {
      const ordered = this.commons.orderArray(authorities, 'source_name');
      ordered.forEach(item => {
        if (this.search(item) &&
          (!this.typeFilter || item['owner_type'] === Number(this.typeFilter)) &&
          ((this.authority_statusFilter.value === '' && item['authority_marked'] !== true) ||
            (this.authority_statusFilter.value !== '' && item['authority_marked'] === this.authority_statusFilter.value))) {
          res.push(item);
        }
      });
    }
    return res;
  }

  private selectTypeFilter(type) {
    this.typeFilter = type;
    this.selectedFilter = {value: '', label: 'ALL'};
    this.authority_statusFilter = {value: '', label: 'ALL'};
  }

  private setParent() {
    this.parent = {
      path: 'home.primus.admin',
      adminType: this.adminType,
      subMenus: true,
      target_params: {admin_type: this.adminType},
      currentChildPath: this.uiRouterGlobals.params.typeFilter,
      currentState: this.stateService.current.name
    };
  }

  private saveAuthorities() {
    this.storeAuthorities(this.authorities, {
      success: (message, status) => {
        this.editView.name = '';
        console.log('authorities saved');
        this.notificationService.addNotification({
          messages: [
            'TRANS__LIST__KULTURNAVIMPORT_UPDATED'],
          type: 'success',
          status: status
        });
      },
      error: (message, status) => {
        console.warn('Storing authorities failed ' +
          'with message: ' + message + ': ' +
          status);
      }
    });
  }

  private storeAuthorities(authorities, fns) {
    const modalRef = this.modalService.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
    this.cms.saveKulturNAVLists(authorities).then(
      () => {
        fns.success();
        modalRef.close();
      },
      () => {
        modalRef.close();
      }
    );
  }

  private onClick(index, lastClickedNdx) {
    this.clicks += 1;
    if (lastClickedNdx && lastClickedNdx !== index) {
      document.getElementById('helperBox-kulturNav-' + lastClickedNdx).style.display = 'none';
      this.clicks = 1;
    }
    if (index) {
      if (this.clicks !== 1) {
        this.clicks = 0;
      }
      if (this.clicks === 1) {
        document.getElementById('helperBox-kulturNav-' + index).style.display = 'inline-block';
      } else {
        document.getElementById('helperBox-kulturNav-' + index).style.display = 'none';
      }
    }
  }

}
