import {Injectable} from '@angular/core';
import {AnnotationContainer} from './annotation-container';

@Injectable({
  providedIn: 'root'
})
export class AnnotationMenusService {
  private MENU_COLOR_ACTIVE = '#ffffff';
  private MENU_COLOR_INACTIVE = '#aaaaaa';

  private menItemWidth = 30;
  private menItemHeight = 30;
  private menuSpace = 10;
  private menItemTotWith = this.menItemWidth + this.menuSpace;
  private menStartX;
  private menStartY = 10;
  private state;

  constructor() {

  }

  drawMenus(container: AnnotationContainer, mouseCoord) {
    this.menStartX = container.canvas.width - this.getMenuWidth(container);
    this.state = container.curAnn.state;

    this.init(container, mouseCoord);
  }

  getMenus(container: AnnotationContainer) {
    let readOnly = true;
    const res = [];
    if (container.curAnn.ane) {
      readOnly = container.curAnn.ane.$$readOnly;
    }
    const menus = [
      {
        actionState: container.curAnn.actionStates.draw,
        hideReadOnly: true,
        shape: [0.7, 0.1, 0.1, 0.7, 0.1, 0.9, 0.3, 0.9,
          0.9, 0.3, 0.7, 0.1]
      },
      {
        actionState: container.curAnn.actionStates.undo,
        hideReadOnly: true,
        shape: [0.1, 0.5, 0.1, 0.3, 0.3, 0.1, 0.7, 0.1,
          0.9, 0.3, 0.9, 0.7, 0.7, 0.9, 0.4, 0.9,
          0.4, 0.7, 0.6, 0.7, 0.7, 0.6, 0.7, 0.4,
          0.6, 0.3, 0.5, 0.3, 0.3, 0.5, 0.1, 0.5
        ]
      }
      // ,
      // {
      //    actionState: curAnn.actionStates.zoomIn,
      //    shape: [0.4, 0.1, 0.4, 0.4, 0.1, 0.4, 0.1, 0.6,
      //        0.4, 0.6, 0.4, 0.9, 0.6, 0.9, 0.6, 0.6, 0.9,
      // 0.6, 0.9, 0.4, 0.6, 0.4, 0.6, 0.1, 0.4, 0.1] }, {
      // actionState: curAnn.actionStates.zoomOut, shape:
      // [0.1,
      // 0.4, 0.9, 0.4, 0.9, 0.6, 0.1, 0.6, 0.1, 0.4] }, {
      // actionState: curAnn.actionStates.pan, shape:
      // [0.2, 0.2,
      // 0.1, 0.3, 0.1, 0.7, 0.3, 0.9, 0.7, 0.9, 0.9, 0.7,
      // 0.9, 0.4, 0.8, 0.3, 0.7, 0.4, 0.7, 0.6, 0.7, 0.2,
      // 0.6, 0.1, 0.5, 0.2, 0.5, 0.6, 0.5, 0.2, 0.4, 0.1,
      // 0.3, 0.2, 0.3, 0.6, 0.3, 0.3, 0.2, 0.2] }
    ];
    menus.forEach(menu => {
      if (!menu.hideReadOnly || !readOnly) {
        res.push(menu);
      }
    }, res);
    return res;
  }

  hoverMenuIndex(container: AnnotationContainer, mouseCoord) {
    const menStartY = 10;
    let res = -1;
    let diffX;
    const diffY = mouseCoord.y - menStartY;

    if (diffY >= 0 && diffY < this.menItemHeight) {
      const menuWidth = this.getMenuWidth(container);
      diffX = mouseCoord.x - (container.canvas.width - menuWidth);
      if (diffX >= 0 && diffX <= menuWidth) {
        res = Math.floor(diffX / this.menItemTotWith);
      }
    }
    return res;
  }

  private drawShape(container: AnnotationContainer, menu, startX, startY) {
    let t, x, y;
    container.ctx.lineWidth = 2;
    container.ctx.shadowColor = '#000';
    container.ctx.shadowBlur = 3;
    container.ctx.shadowOffsetX = 2;
    container.ctx.shadowOffsetY = 2;
    container.ctx.moveTo(startX + menu.shape[0] * this.menItemWidth, this.menStartY + menu.shape[1] * this.menItemHeight);
    for (t = 2; t < menu.shape.length; t += 2) {
      x = menu.shape[t] * this.menItemWidth + startX;
      y = menu.shape[t + 1] * this.menItemHeight + startY;
      container.ctx.lineTo(x, y);
    }
  }

  private menuColor(menu, menuIndex, hoverIndex) {
    return hoverIndex === menuIndex || this.state.action === menu.actionState ? this.MENU_COLOR_ACTIVE : this.MENU_COLOR_INACTIVE;
  }

  private init(container: AnnotationContainer, mouseCoord) {
    const hoverIdx = this.hoverMenuIndex(container, mouseCoord);
    container.ctx.lineWidth = 2;
    this.getMenus(container).forEach((menu, index) => {
      let startX, startY;
      startX = this.menStartX + index * this.menItemTotWith;
      startY = this.menStartY;
      container.ctx.beginPath();
      container.ctx.strokeStyle = this.menuColor(menu, index, hoverIdx);
      container.ctx.rect(startX, this.menStartY, this.menItemWidth, this.menItemHeight);
      this.drawShape(container, menu, startX, startY);
      container.ctx.stroke();
    });
  }

  private getMenuWidth(container: AnnotationContainer) {
    // TODO: May be slow
    return this.menItemTotWith * this.getMenus(container).length;
  }

}
