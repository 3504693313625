import {Component, Input, OnInit} from '@angular/core';
import {InlineArrayHeaderContainer} from '../../inline-array-header-container';
import {AConst} from '../../../core/a-const.enum';
import {ActionMenuService} from '../../../core/action-menu.service';

@Component({
  selector: 'app-inline-array-header-headlines',
  templateUrl: './inline-array-header-headlines.component.html',
  styleUrls: ['./inline-array-header-headlines.component.scss']
})
export class InlineArrayHeaderHeadlinesComponent implements OnInit {

  @Input() container: InlineArrayHeaderContainer;

  constructor(private actionMenuService: ActionMenuService) {
  }

  ngOnInit() {
  }

  checkValue($event, label) {
    const checkboxField = label[AConst.CHECKBOX_FIELD] || label.name;
    this.container.arrayItem[checkboxField] = !this.container.arrayItem[checkboxField];
    // This will "turn on" the bottom action menu
    this.actionMenuService.actionMenuContainer.checkboxChange = true;
  }


}
