import {Component, Input, OnChanges} from '@angular/core';
import {SearchHandlerService} from '../../search-handler.service';
import {SearchContainer} from '../../search-container';
import {AConst} from '../../../core/a-const.enum';
import {Focus, SearchFocusService} from '../../search-focus.service';

@Component({
  selector: 'app-search-filter-menu-item',
  templateUrl: './search-filter-menu-item.component.html',
  styleUrls: ['./search-filter-menu-item.component.scss']
})
export class SearchFilterMenuItemComponent implements OnChanges {

  @Input() menu;
  @Input() sideMenu;
  @Input() subMenu;
  @Input() searchContainer: SearchContainer;

  AConst = AConst;
  private fsi: Focus;

  constructor(private searchHandler: SearchHandlerService,
              private searchFocusService: SearchFocusService) {
  }

  ngOnChanges() {
    if (this.menu) {
      if (this.menu.menus && this.menu.order === 0) {
        this.setOpenItem(this.menu);
      }
    }
  }

  openFilterItem(menu) {
    this.setOpenItem(menu);
  }

  checkMenuFilter($event) {
    $event.preventDefault();
    this.searchHandler.checkMenuFilter(this.menu, this.searchContainer);
  }

  goToMenuPath() {
    this.fsi = this.searchFocusService.createFocus(this.searchContainer);
    if (this.searchContainer.curFocus && this.searchContainer.curFocus.stored) {
      const exists = this.fsi.checkPathParamsExists(this.menu.path, this.searchContainer);
      if (!exists) {
        this.fsi.storeFocusChanges(this.searchContainer.curFocus);
      }
    }
    this.searchContainer.objectCount = 0;
    this.searchContainer.path = this.menu.path;
    this.searchHandler.goPathView(this.searchContainer.path, this.searchContainer);
  }

  getMenuCount() {
    if (this.searchContainer && this.searchContainer.searchResult) {
      this.menu['count'] = this.searchHandler.getMenuCount(this.menu, this.searchContainer);
    }
    return this.menu.count;
  }

  private setOpenItem(menu) {
    if (this.searchContainer.currentPathView.menus) {
      this.searchContainer.currentPathView.menus.forEach(mainMenu => {
        if (menu[AConst.FACET_ITEM] === mainMenu[AConst.FACET_ITEM]) {
          menu.openItem = !menu.openItem;
        } else {
          mainMenu.openItem = false;
        }
      });
    }
  }

}
