import {Component, Input, OnChanges} from '@angular/core';
import {CmsApiService} from '../../core/cms-api.service';
import {AConst} from '../../core/a-const.enum';
import {CommonsService} from '../../core/commons.service';
import {FieldStateService} from '../../core/field-state.service';
import {SectionsContainer} from '../../core/sections-container';

@Component({
  selector: 'app-edit-field-compare',
  templateUrl: './edit-field-compare.component.html',
  styleUrls: ['./edit-field-compare.component.scss']
})
export class EditFieldCompareComponent implements OnChanges {

  @Input() sectionsContainer: SectionsContainer;
  @Input() field;
  @Input() index: number;
  @Input() parentIndex: number;

  compareFieldId;
  compareObject;
  myField;

  constructor(private cms: CmsApiService,
              private commons: CommonsService,
              private fieldStateSvc: FieldStateService) { }

  ngOnChanges() {
    this.setCompareProps();
    this.setCompareField();
  }

  setCompareProps() {
    const showCondFieldName = this.field.reference[AConst.COMPARE_VALUE_FIELD];
    this.compareObject = this.fieldStateSvc.getSubObject(this.sectionsContainer.rootObject, this.field, this.index, this.parentIndex);
    let compareFieldId = this.compareObject[showCondFieldName];
    const parentName = this.field[AConst.PARENT_NAME];
    if (compareFieldId === undefined && parentName) {
      this.compareObject = this.compareObject[parentName];
      compareFieldId = this.compareObject[showCondFieldName];
    }
    this.compareFieldId = compareFieldId;
  }

  setCompareField() {
    this.cms.searchJson({
      filters: {
        object_type: 'ct_207',
        artifact_id: this.compareFieldId
      }
    }).then((res) => {
      let fiSearch, reference, fiCreate, parentMeta, refType;
      const fi = this.field;
      const existingVal = this.compareObject[fi.name];
      if (res[AConst.ARTIFACTS]) {
        fiSearch = res[AConst.ARTIFACTS][0];
        if (fiSearch[AConst.INPUT_TYPE] === AConst.MAP_ID) {
          reference = this.parsePythonJson(fiSearch.reference);
          refType = reference[AConst.OBJECT_TYPE];
          if (existingVal && existingVal.indexOf(refType) !== 0) {
            this.compareObject[fi.name] = null;
          }
        }
        parentMeta = this.compareObject[AConst.$$META][fi.name];
        parentMeta[AConst.REFERENCE] = reference;
        parentMeta[AConst.INPUT_TYPE] = fiSearch[AConst.INPUT_TYPE];
        fiCreate = this.commons.copy(fi);
        fiCreate[AConst.FIELD_TYPE] = fiSearch[AConst.FIELD_TYPE];
        fiCreate[AConst.INPUT_TYPE] = fiSearch[AConst.INPUT_TYPE];
        fiCreate[AConst.REFERENCE] = reference;
        this.myField = fiCreate;
      }
    });
  }



  parsePythonJson(inStr) {
    let str = inStr.replace(/'/g, '"');
    str = str.replace(/: True/g, ': true');
    str = str.replace(/: False/g, ': false');
    str = str.replace(/: None/g, ': null');
    return JSON.parse(str);
  }

}
