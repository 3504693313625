import {Injectable} from '@angular/core';
import {ModelsService} from './models.service';
import {AConst} from './a-const.enum';
import {CommonsService} from './commons.service';
import {FieldParameters} from './field-parameters';

@Injectable({
  providedIn: 'root'
})
export class FieldMetaHandlerService {

  constructor(private models: ModelsService, private commons: CommonsService) {
  }

  private callbacks = {};
  private cachedMetaProps = {};

  private static getPathItem(path, itemIndex) {
    let pathSplit, res = null;
    if (path) {
      pathSplit = path.split('.');
      if (itemIndex < pathSplit.length) {
        res = pathSplit[itemIndex];
      } else {
        console.warn('Wrong path index: ' + itemIndex +
          ' for ' + path);
      }
    }

    return res;
  }

  private static genMetaFromDynamicField(field) {
    const meta = {
      order: field.order_number.toString(),
      name: 'dyn-field-' + field[AConst.FIELD_ID],
      display: 'pri',
      edit: 'edit',
      title: field[AConst.FIELD_NAME],
      input_type: null,
      reference: {},
      parent_ref_type: null
    };

    if (field[AConst.FIELD_TYPE] === 'text') {
      meta[AConst.FIELD_TYPE] = 'string';
      meta.input_type = 'input';
    } else if (field[AConst.FIELD_TYPE] === 'list') {
      meta[AConst.FIELD_TYPE] = AConst.MAP_ID;
      meta.input_type = AConst.MAP_ID;
      meta.reference[AConst.OBJECT_TYPE] = field.concept_type_id;
      meta.parent_ref_type =
        field[AConst.PARENT_CONCEPT_TYPE_ID];
    } else {
      throw new Error('Unknown field type \'' + field[AConst.FIELD_TYPE] +
        '\'');
    }
    return meta;
  }

  private static getMetaFromRoot(params) {
    let meta, res;
    if (params.parentFieldName) {
      meta = params.rootModel[AConst.$$META][params.parentFieldName];
      if (meta) {
        res = meta[params.metaPropName];
      }
    }
    return res;
  }

  /**
   * Get the meta properties belonging to a field, using information
   * in a field container
   * @param fieldContainer container with information about a specific field within a model structure
   * @param parentLevel set to "current" if not set.
   * If set to "parent", return meta properties for the parent level.
   * If set to "root", return meta properties for the root level
   * @param noThrow set to true to avoid exceptions if meta missing
   * @param fn optional callback function
   * @returns {*}
   */
  public getMetaParamsFromFieldContainer(fieldContainer, parentLevel?, noThrow?, fn?) {
    let res, fieldName, parentName;
    parentLevel = parentLevel || 'current';
    switch (parentLevel) {
      case 'current':
        res = this.getMetaParams({
          parentModel: fieldContainer.parentModel,
          propName: fieldContainer.propName,
          fieldId: fieldContainer.fieldId,
          noThrow: noThrow,
          fn: fn
        });
        break;
      case 'parent':
        if (!fieldContainer.grandParentModel && !noThrow) {
          console.warn('No grandparent model');
        } else {
          parentName = fieldContainer.fieldInfo[AConst.PARENT_NAME] ||
            fieldContainer.fieldInfo.path;
          res = this.getMetaParams({
            parentModel: fieldContainer.grandParentModel,
            propName: parentName,
            fieldId: fieldContainer.fieldId,
            noThrow: noThrow,
            fn: fn
          });
        }
        break;
      case 'root':
        if (!fieldContainer.rootModel && !noThrow) {
          console.warn('No root model');
        } else {
          fieldName = FieldMetaHandlerService.getPathItem(
            fieldContainer.fieldInfo.path, 0);
          if (fieldName) {
            res = this.getMetaParams({
              parentModel: fieldContainer.rootModel,
              propName: fieldName,
              fieldId: fieldContainer.fieldId,
              noThrow: noThrow,
              fn: fn
            });
          }
        }
        break;
      default:
        console.warn('Unknown level ' + parentLevel);
    }
    return res;
  }

  /**
   * Get the meta properties belonging to a field, using information
   * in a field container
   * @param fieldParameters container with information about a specific field within a model structure
   * @param parentLevel set to "current" if not set.
   * If set to "parent", return meta properties for the parent level.
   * If set to "root", return meta properties for the root level
   * @param noThrow set to true to avoid exceptions if meta missing
   * @param fn optional callback function
   * @returns {*}
   */
  public getMetaParamsFromFieldParameters(fieldParameters: FieldParameters, parentLevel?, noThrow?, fn?) {
    let res, fieldName, parentName;
    parentLevel = parentLevel || 'current';
    switch (parentLevel) {
      case 'current':
        res = this.getMetaParams({
          parentModel: fieldParameters.object,
          propName: fieldParameters.field.name,
          fieldId: fieldParameters.field.key,
          noThrow: noThrow,
          fn: fn
        });
        break;
      case 'parent':
        if (!fieldParameters.grandParentObject && !noThrow) {
          console.warn('No grandparent model');
        } else {
          parentName = fieldParameters.field[AConst.PARENT_NAME] || fieldParameters.field.path;
          res = this.getMetaParams({
            parentModel: fieldParameters.grandParentObject,
            propName: parentName,
            fieldId: fieldParameters.field.key,
            noThrow: noThrow,
            fn: fn
          });
        }
        break;
      case 'root':
        if (!fieldParameters.rootObject && !noThrow) {
          console.warn('No root model');
        } else {
          fieldName = FieldMetaHandlerService.getPathItem(
            fieldParameters.field.path, 0);
          if (fieldName) {
            res = this.getMetaParams({
              parentModel: fieldParameters.rootObject,
              propName: fieldName,
              fieldId: fieldParameters.field.key,
              noThrow: noThrow,
              fn: fn
            });
          }
        }
        break;
      default:
        console.warn('Unknown level ' + parentLevel);
    }
    return res;
  }


  public getMetaParams(params) {
    let meta, metaParams, fnRun = false;
    const parentModel = params.parentModel, propName = params.propName;
    const noThrow = params.noThrow, fn = params.fn;
    if (parentModel) {
      meta = parentModel[AConst.$$META];
      if (meta) {
        metaParams = this.getParamsFromMeta(meta, params);
      } else {
        if (parentModel[AConst.FIELD_ID]) {
          metaParams = FieldMetaHandlerService.genMetaFromDynamicField(parentModel);
          if (fn) {
            fn(metaParams);
          }
        } else {
          if (fn) {
            fnRun = true;
            // Could be a SOLR object, which lacks $$meta
            // field. Try to get meta from Models instead.
          }
          metaParams = this.getMetaFromModels(
            parentModel, propName, noThrow, fn);
        }
      }
    }
    if (!fn) {
      return metaParams;
    } else if (!fnRun) {
      fn(metaParams);
    }
  }

  /**
   * Get a named meta property belonging to an object field within
   * an parent object. If meta property not found, try to search for
   * the meta property within the grand parent model, if provided
   *
   * @param params
   * @param params.metaPropName
   * @param params.fieldName
   * @param params.parentFieldName,
   * @param params.parentModel
   * @param params.grandParentModel
   * @param params.rootModel
   * @param params.noWarn
   * @returns {*}
   */
  public getMetaProp(params) {
    const metaPropName = params.metaPropName;
    const fieldName = params.fieldName;
    const parentModel = params.parentModel;
    const grandParentModel = params.grandParentModel;

    let meta, res = null, myParent;
    // noinspection SuspiciousTypeOfGuard
    if (Array.isArray(parentModel) && typeof fieldName === 'number') {
      meta = parentModel[fieldName][AConst.$$META];
      myParent = parentModel[fieldName];
    } else {
      meta = this.getMetaParams({
        parentModel: parentModel,
        propName: fieldName,
        noThrow: true
      });
      myParent = parentModel;
    }
    if (meta) {
      res = meta[metaPropName];
    }
    if (!res && grandParentModel) {
      res = this.getMetaFromAncestor(grandParentModel, myParent, metaPropName);
    }
    if (!res && params.rootModel) {
      res = FieldMetaHandlerService.getMetaFromRoot(params);
    }
    if (!params.noWarn && res === null &&
      metaPropName.indexOf('_') !== 0) {
      console.warn('No meta prop \'' + metaPropName +
        '\' found. Should be defined in ' +
        myParent.object_type + '.' + fieldName +
        ' or in grand parent model');
    }
    return res;
  }

  public searchGetMetaProp(mod, propName, metaPropName) {
    let metaProp, searchRes, searchKey;
    const objType = mod[AConst.OBJECT_TYPE];
    if (!objType) {
      console.warn('Missing object type creating search key!');
    } else {
      searchKey = objType + ':' + propName + ':' + metaPropName;
    }
    if (!searchKey || !this.cachedMetaProps.hasOwnProperty(searchKey)) {
      searchRes = this.commons.searchModel(mod, propName);
      if (searchRes) {
        metaProp = this.getMetaProp({
          metaPropName: metaPropName,
          fieldName: propName,
          parentModel: searchRes.parentModel,
          noWarn: true
        });
        if (!metaProp && searchRes.grandParentModel) {
          metaProp = this.getMetaProp({
            metaPropName: metaPropName,
            fieldName: searchRes.parentPropName,
            parentModel: searchRes.grandParentModel,
            noWarn: true
          });
        }
      }
      this.cachedMetaProps[searchKey] = metaProp;
    } else {
      metaProp = this.cachedMetaProps[searchKey];
    }
    return metaProp;
  }

  private getMetaFromModels(parentModel, propName, noThrow,
                            fn) {
    const parentObjectType = parentModel[AConst.OBJECT_TYPE];
    if (fn) {
      this.getMetaFromModelsAsync(parentObjectType, propName, noThrow, fn);
    } else {
      return this.getMetaFromModelsSub(
        this.models.getModels(false), parentObjectType, propName, noThrow);
    }
  }

  private getMetaFromModelsAsync(
    parentObjectType, propName, noThrow, fn) {
    const callbackKey = parentObjectType + ':' + propName;
    let cList = this.callbacks[callbackKey];
    if (cList && cList.length > 0) {
      cList.push(fn);
    } else {
      this.callbacks[callbackKey] = [fn];
      this.models.getModelsAsync().then(mods => {
        let callback;
        const metaParams = this.getMetaFromModelsSub(
          mods, parentObjectType, propName, noThrow);
        if (metaParams) {
          cList = this.callbacks[callbackKey];
          do {
            callback = cList.pop();
            callback(metaParams);
          } while (cList.length > 0);
        }
      });
    }
  }

  private getMetaFromModelsSub(
    mods, parentObjectType, propName, noThrow) {
    let meta, metaParams, realParent;
    if (mods) {
      realParent = mods[parentObjectType];
      if (realParent) {
        meta = realParent[AConst.$$META];
        if (!meta[propName]) {
          meta = this.getMetaFromOverviewField(
            meta, propName, mods);
        }
        metaParams = this.getParamsFromMeta(
          meta, {propName: propName, noThrow: noThrow});
      }
    } else {
      throw new Error('Missing models');
    }
    return metaParams;
  }

  private getMetaFromOverviewField(meta, propName, mods) {
    let res = null;
    const overviewFields = meta[AConst.OVERVIEW_FIELDS];
    let foundOvf, ovfMeta, ovfName, ovfModel;
    if (overviewFields) {
      overviewFields.forEach((ovf) => {
        if (ovf.name.indexOf(propName) !== -1) {
          foundOvf = ovf;
        }
      });
      if (foundOvf) {
        ovfName = foundOvf.name.split('.')[0];
        ovfMeta = meta[ovfName];
        if (ovfMeta) {
          ovfModel = ovfMeta[AConst.INLINE] ? ovfMeta[AConst.INLINE][AConst.MODEL] : null;
          if (ovfModel) {
            res = mods[ovfModel][AConst.$$META];
            if (!res) {
              console.warn('No meta in model ' + ovfModel);
            }
          } else {
            console.warn('Missing \'model\' prop from ' +
              ovfName);
          }
        } else {
          console.warn('No meta found for ' + ovfName);
        }
      } else {
        console.warn('No overview field containing ' + propName +
          ' found');
      }
    } else {
      console.warn('No overview fields in meta');
    }
    return res;
  }

  private getParamsFromMeta(meta, params) {
    let metaParams, propNames, inline, inlineMod, subName;
    const propNamePath = params.fieldId || params.propName;

    if (meta) {
      if (!propNamePath) {
        metaParams = meta;
      } else if (meta[params.propName]) {
        metaParams = meta[params.propName];
      } else if (propNamePath && propNamePath.indexOf('.') !== -1) {
        propNames = propNamePath.split('.');
        for (let t = propNames.length - 1; t >= 0; t--) {
          subName = propNames[t].split('[')[0];
          metaParams = meta[subName];
          if (metaParams) {
            inline = metaParams[AConst.INLINE];
            if (inline) {
              inlineMod = this.models.getModel(inline.model);
              if (inlineMod) {
                meta = inlineMod[AConst.$$META];
              }
            } else {
              break;
            }
          } else {
            metaParams = meta[params.propName] || meta;
          }
        }
      } else {
        metaParams = null;
      }
    }
    if (!metaParams && !params.noThrow) {
      if (propNamePath) {
        throw new Error('Meta prop \'' + propNamePath +
          '\' not found in model');
      } else {
        throw new Error('Meta props not found in model');
      }
    }
    return metaParams;
  }

  // Try to retrieve meta properties of an object's parent object.
  // This will only work if there are no two child objects having the
  // same object type within the parent object...
  private getGrandMetaParams(grandParentModel, parentModel) {
    let grandMeta = null, val, name, objType;
    for (name in grandParentModel) {
      if (grandParentModel.hasOwnProperty(name)) {
        val = grandParentModel[name];
        if (Array.isArray(val) && val.length > 0) {
          val = val[0];
        }
        if (name.indexOf('$$') === -1 && val && !Array.isArray(val) && typeof val === 'object') {
          objType = val.object_type;
          if (objType &&
            objType === parentModel.object_type) {
            grandMeta =
              this.getMetaParams({
                parentModel: grandParentModel,
                propName: name,
                noThrow: true
              });
            break;
          }
        }
      }
    }
    return grandMeta;
  }

  private getMetaFromAncestor(ancestor, parent, metaPropName) {
    let meta, res;
    if (ancestor.hasOwnProperty(metaPropName)) {
      meta = ancestor[AConst.$$META];
    } else {
      meta = this.getGrandMetaParams(ancestor, parent);
    }
    if (meta) {
      res = meta[metaPropName];
    }
    return res;
  }

}
