import {Injectable} from '@angular/core';
import {CmsApiService} from './cms-api.service';
import {AConst} from './a-const.enum';
import {ModelFactoryService} from './model-factory.service';
import {ObjectViewAndData} from './object-view';

@Injectable({
  providedIn: 'root'
})
export class ObjectStorageService {

  constructor(private cms: CmsApiService, private modelFactory: ModelFactoryService) {
  }

  storeObject(objectIn): Promise<any> {
    return new Promise((resolve, reject) => {
      let saveFn, saveParams;
      const metaType = objectIn[AConst.META_TYPE];
      const subArtTypes = ['sub_model', 'item_model'];
      // This conversion removes attributes that should not be included when storing
      const art = this.getCleanObject(objectIn);

      if (metaType === 'context_list') {
        saveFn = this.cms.saveSubArtifacts.name || 'saveSubArtifacts';
        saveParams = {subArtifacts: art};
      } else if (subArtTypes.indexOf(metaType) !== -1) {
        saveFn = this.cms.saveSubArtifact.name || 'saveSubArtifact';
        saveParams = {subArtifact: art};
      } else if (art[AConst.META_TYPE] === 'template_model' &&
        art[AConst.OBJECT_TYPE] !== 'TemplateGroup') {
        saveFn = this.cms.putMetaObject.name || 'putMetaObject';
        saveParams = {
          object: art
        };
      } else if (art[AConst.OBJECT_TYPE] !== 'concept' &&
        metaType !== 'sub_model') {
        saveFn = this.cms.saveArtifact.name || 'saveArtifact';
        saveParams = {artifact: art};
      } else {
        saveFn = this.cms.saveConcept.name || 'saveConcept';
        saveParams = {concept: art};
      }

      this.cms[saveFn](saveParams).then(data => {
          resolve(data);
        },
        function (error) {
          reject(error);
        });
    });
  }

  loadObject(objectId, templateGroupId?): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {};
      params[AConst.ARTIFACT_ID] = objectId;
      params[AConst.INCLUDE_META] = true;
      params[AConst.TEMPLATE_GROUP_ID] = templateGroupId;
      this.cms.getArtifact(params).then(
        object => {
          if (object) {
            this.setObjectProps(object).then(res => {
              resolve(res);
            });
          }
        },
        reason => {
          reject(reason);
        }
      );
    });
  }

  loadObjectWithViewData(objectId, templateGroupId?): Promise<ObjectViewAndData> {
    return new Promise<ObjectViewAndData>((resolve, reject) => {
      const params = {};
      params[AConst.ARTIFACT_ID] = objectId;
      params[AConst.TEMPLATE_GROUP_ID] = templateGroupId;
      this.cms.getArtifactViewAndData(params).then(
        objectViewAndData => {
          this.setObjectProps(objectViewAndData.artifact).then(item => {
            objectViewAndData.artifact = item;
            resolve(objectViewAndData);
          });
        },
        reason => {
          reject(reason);
        });
    });
  }

  loadObjects(objIds, templateGroupId?) {
    return new Promise((resolve, reject) => {
      const params = {};
      params[AConst.ARTIFACT_IDS] = objIds;
      params[AConst.INCLUDE_META] = true;
      params[AConst.TEMPLATE_GROUP_ID] = templateGroupId;
      this.cms.getArtifacts(params).then(
        objects => {
          if (objects) {
            this.setObjectsProps(<Array<any>>objects).then(res => {
              resolve(res);
            });
          }
        },
        reason => {
          reject(reason);
        }
      );
    });
  }

  copyObject(objectId, templateGroupId?, targetObjectType?) {
    return new Promise((resolve, reject) => {
      const params = {};
      params[AConst.ARTIFACT_ID] = objectId;
      params[AConst.INCLUDE_META] = true;
      params[AConst.TEMPLATE_GROUP_ID] = templateGroupId;
      params[AConst.OBJECT_TYPE] = targetObjectType;
      this.cms.copyArtifact(params).then(
        object => {
          if (object) {
            this.setObjectProps(object).then(res => {
              resolve(res);
            });
          }
        },
        reason => {
          reject(reason);
        }
      );
    });
  }

  deleteObject(objectId) {
    return new Promise(((resolve, reject) => {
      this.cms.deleteArtifact({artifact_id: objectId}).then(
        function () {
          resolve();
        },
        function (response) {
          reject('Error: ' + response.status);
        }
      );
    }));
  }

  copyObjects(objectIds, templateGroupId?) {
    return new Promise((resolve, reject) => {
      const results = [];
      let counter = objectIds.length;
      objectIds.forEach(objectId => {
        const params = {};
        params[AConst.ARTIFACT_ID] = objectId;
        params[AConst.INCLUDE_META] = true;
        params[AConst.TEMPLATE_GROUP_ID] = templateGroupId;
        this.cms.copyArtifact(params).then(
          object => {
            if (object) {
              this.setObjectProps(object).then(res => {
                results.push(res);
                if (!--counter) {
                  resolve(results);
                }
              });
            }
          },
          reason => {
            reject(reason);
          }
        );
      });
    });
  }

  private setObjectProps(object) {
    return new Promise(resolve => {
      this.modelFactory.setModelItemAsync(object[AConst.OBJECT_TYPE], object).then((res) => {
        resolve(res);
      });
    });
  }

  private setObjectsProps(objects: Array<any>) {
    return new Promise(resolve => {
      if (objects) {
        let count = objects.length;
        const results = [];
        objects.forEach(object => {
          this.setObjectProps(object).then((res) => {
            count--;
            results.push(res);
            if (!count) {
              resolve(results);
            }
          });
        });
      }
    });
  }

  // Return a "clean" object without $$ attributes
  private getCleanObject(object: object) {
    const res = {};
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        if (key.indexOf('$$') !== 0) {
          const valueCheck = object[key];
          let value = valueCheck;
          if (valueCheck) {
            if (Array.isArray(valueCheck)) {
              value = [];
              valueCheck.forEach(item => {
                if (typeof item === 'object') {
                  value.push(this.getCleanObject(item));
                } else {
                  value.push(item);
                }
              });
            } else if (typeof valueCheck === 'object') {
              value = this.getCleanObject(valueCheck);
            }
          }
          res[key] = value;
        }
      }
    }
    return res;
  }
}
