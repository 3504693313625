import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SearchContainer} from '../../search-container';
import {SearchHandlerService} from '../../search-handler.service';
import {ObjectDeletionService} from '../../../core/object-deletion.service';
import {AConst} from '../../../core/a-const.enum';

@Component({
  selector: 'app-search-result-view-list-thumbnail',
  templateUrl: './search-result-view-list-thumbnail.component.html',
  styleUrls: ['./search-result-view-list-thumbnail.component.scss']
})
export class SearchResultViewListThumbnailComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;
  @Output() artifactUpdated = new EventEmitter();

  AConst = AConst;


  constructor(private searchHandler: SearchHandlerService,
              private objectDeletionService: ObjectDeletionService) {
  }

  ngOnInit() {
    this.searchHandler.subscribeToSearchResult(this.searchContainer, this.setArtifactsDeletable);
  }

  ngOnDestroy(): void {
    this.searchHandler.unSubscribeToSearchResult(this.searchContainer, this.setArtifactsDeletable);
  }

  afterDelete() {
    if (this.artifactUpdated) {
      this.artifactUpdated.emit();
    }
    this.searchHandler.runSearch(this.searchContainer).then();
  }

  select(obj) {
    this.searchHandler.selectSearchResultItem(obj, this.searchContainer);
  }

  getAuth(art) {
    let auth = art[AConst.AUTHORITY];
    if (auth) {
      auth = auth.toLowerCase();
    }
    return auth;
  }

  private setArtifactsDeletable = (searchResult) => {
    if (searchResult && searchResult[AConst.ARTIFACTS].length > 0 && this.searchContainer.path !== 'home/folders/folder') {
      this.objectDeletionService.setObjectsDeletable(searchResult[AConst.ARTIFACTS], !!this.searchContainer.targetObject);
    }
  }


}
