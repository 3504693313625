import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MediaHelperService} from '../../core/media-helper.service';
import {StateService} from '@uirouter/core';
import {CmsApiService} from '../../core/cms-api.service';
import {ActiveImageComponent} from '../../media-carousel/active-image/active-image.component';

@Component({
  selector: 'app-image-full-screen',
  templateUrl: './image-full-screen.component.html',
  styleUrls: ['./image-full-screen.component.scss']
})
export class ImageFullScreenComponent implements OnInit, AfterViewInit {
  imageUrl;
  zoomValue = 1;
  container;
  @ViewChild(ActiveImageComponent, { static: true }) activeImage;
  @ViewChild('imageFullScreen', { static: true }) imageFullScreen: ElementRef;

  constructor(private mediaHelper: MediaHelperService,
              private $state: StateService,
              private cms: CmsApiService,
              private elementRef: ElementRef) {
  }

  ngOnInit() {
    const imageId = this.$state.params.imageId;
    this.imageUrl = this.getImageUrl(imageId);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.container = this.elementRef.nativeElement.querySelector('#imageFullScreenContainer');
      this.imageFullScreen.nativeElement.focus();
    });
  }

  public getImageUrl(imageId) {
    let path;
    path = 'multimedia/image/' + imageId;
    const imageSize = 'large';
    path = this.mediaHelper.appendPathParam(path, 'size', imageSize);
    return this.cms.getApiUrl(path);
  }

  onChangeZoomValue(value) {
    this.zoomValue = value;
    this.activeImage.resizeActiveImage(value);
  }

  goBack() {
    window.history.back();
  }

  goBackWithKey(event) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.goBack();
    }
  }

}
