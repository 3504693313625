import {Injectable} from '@angular/core';
import {AConst} from './a-const.enum';
import {CommonsService} from './commons.service';
import {CmsApiService} from './cms-api.service';
import {StateService} from '@uirouter/core';
import {RefService} from './ref.service';
import {ArefHandlerService} from './aref-handler.service';

@Injectable({
  providedIn: 'root'
})
export class MediaHelperService {
  onDragStart;
  onDrag;
  onDragEnd;
  dragActive = false;
  draggingStarted;
  container;
  lastZoomValue;
  imageLoaded;
  draggableImage;
  lastMousePosition = {x: null, y: null};
  imageSize = {width: 0, height: 0};
  zoomValue;
  modelRelations = {};

  constructor(private cms: CmsApiService,
              private commons: CommonsService,
              private arefHandler: ArefHandlerService,
              private ref: RefService,
              private $state: StateService) {
  }

  public getImageUrl(media, imageSize?, quality?, annotationId?) {
    let path;
    const imageId = this.getItemId(media);
    path = 'multimedia/image/' + imageId;
    if (!imageSize) {
      imageSize = 'medium';
    }
    path = this.appendPathParam(path, 'size', imageSize);
    path = this.appendPathParam(path, 'annotation_id', annotationId);
    path = this.appendPathParam(path, 'quality', quality);
    path = this.appendPathParam(path, 'updated',
      this.getUpdatedTime(media));
    return this.cms.getApiUrl(path);
  }

  public getThumbUrl(object, imageIdField?) {
    let path;
    path = 'multimedia/image/' + this.getItemId(object, imageIdField);
    path = this.appendPathParam(path, 'updated',
      this.getUpdatedTime(object));
    return this.cms.getApiUrl(path);
  }

  public appendPathParam(path, name, value) {
    let appender = '?';
    let res = path;
    if (value) {
      if (path.indexOf('?') !== -1) {
        appender = '&';
      }
      res += appender + name + '=' + value;
    }
    return res;
  }

  public getMediaPlaybackUrls(media, fn) {
    if (media[AConst.OBJECT_TYPE] === 'Video') {
      this.cms.getVideoPlaybackUrls({
        videoId: this.getItemId(media)
      }).then((urls: any[]) => {
          const res = [];
          for (const [key, url] of Object.entries(urls)) {
            res.push({
              url: url,
              // url: $sce.trustAsResourceUrl(url),
              type: 'video/' + key
            });
          }
          fn(res);
        },
        (response) => {
          console.log('Unable to retrieve media URLS for ' +
            this.getItemId(media) + ': ' + response.message + ': ' +
            response.status);
        }
      );
    } else if (media[AConst.OBJECT_TYPE] !== 'Image') {
      throw new Error('Media type \'' + media[AConst.OBJECT_TYPE] +
        '\' not supported yet');
    }
  }

  public getMediaUploadStatus(media, fn) {
    if (media[AConst.OBJECT_TYPE] === 'Video') {
      this.cms.getVideoUploadStatus({
        videoId: this.getItemId(media)
      }).then((uploadStatus) => {
          fn(uploadStatus);
        },
        (response) => {
          console.log('Unable to retrieve media upload ' +
            'status for ' + this.getItemId(media) + ': ' +
            response.message + ': ' + response.status);
        }
      );
    } else if (media[AConst.OBJECT_TYPE] !== 'Image') {
      throw new Error('Media type \'' + media[AConst.OBJECT_TYPE] +
        '\' not supported yet');
    }
  }

  public addImageUrls(images) {
    images.forEach((image) => {
      image.$$imageUrl = this.getImageUrl(image);
      image.$$thumbUrl = this.getThumbUrl(image);
    });
  }

  public setMediaProps(media, parent) {
    let ref, contextIds = this.commons.getContextIds(parent[AConst.CONTEXTS]);

    if (!contextIds) {
      contextIds = [parent[AConst.ARTIFACT_ID]];
    }
    ref = this.ref.makeRef({
      object: media,
      stateParams: {
        contextIds: contextIds,
        parentId: parent[AConst.ARTIFACT_ID],
        rootObjId: this.$state.params.rootObjId
      }
    });
    media.$$parentObjType = parent[AConst.OBJECT_TYPE];
    media.$$parentId = ref.param.parentId;
    media.$$contextIds = ref.param.contextIds;
    media.$$targetState = ref.stateName;
    media.$$targetParams = ref.param;
    media.$$imageUrl = this.getImageUrl(media);
    media.$$thumbUrl = this.getThumbUrl(media);
  }

  public getAllImages(obj, fn) {
    return this.getAllObjects(
      {
        objectTypes: ['Image', 'Attachment'],
        arrayNames: ['images']
      },
      obj, fn);
  }

  public getAllVideos(obj, fn) {
    return this.getAllObjects(
      {
        objectTypes: ['Video'],
        arrayNames: ['videos']
      },
      obj, fn);
  }

  public getPosition(position, containerSize, itemSize) {
    if (position > 0) {
      position = 0;
    }
    if (position < (containerSize - itemSize)) {
      position = containerSize - itemSize;
    }
    return position;
  }

  public imgDrag(container, imageLoaded, draggableImage, imageSize, zoomValue) {
    this.container = container;
    this.imageLoaded = imageLoaded;
    this.draggableImage = draggableImage;
    this.imageSize = imageSize;
    this.zoomValue = zoomValue;
    this.dragActive = true;
    this.onDragStart = this.dragStart.bind(this);
    this.onDrag = this.drag.bind(this);
    this.onDragEnd = this.dragEnd.bind(this);
    this.container.addEventListener('mousedown', this.onDragStart, true);
    this.container.addEventListener('mousemove', this.onDrag, true);
    this.container.addEventListener('mouseup', this.onDragEnd, true);
    this.container.addEventListener('touchstart', this.onDragStart, true);
    this.container.addEventListener('touchmove', this.onDrag, true);
    this.container.addEventListener('touchend', this.onDragEnd, true);
  }

  public dragEnd(event) {
    event.preventDefault();
    event.stopPropagation();
    this.draggingStarted = 0;
    this.stopCallBack.bind(this);
  }

  public dragStart(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.imageLoaded === 1 && this.dragActive) {
      this.draggingStarted = 1;
      if (event.type === 'touchstart') {
        this.lastMousePosition = {
          x: event.touches[0].pageX - this.container.offsetLeft,
          y: event.touches[0].pageY - this.container.offsetTop
        };
      } else {
        this.lastMousePosition = {
          x: event.pageX - this.container.offsetLeft,
          y: event.pageY - this.container.offsetTop
        };
      }
    }
  }

  public drag(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'mousemove' && event.buttons === 1) {
      this.setDraggedImagePosition(event);
    }
    if (event.type === 'touchmove') {
      this.setDraggedImagePosition(event);
    }
  }

  private setDraggedImagePosition(event) {
    if (this.draggingStarted === 1) {
      let currentMousePosition, changeX, changeY, img_top_new, img_left_new,
        widthValue, heightValue, img_width, img_height;
      if (event.type === 'touchmove') {
        currentMousePosition = {
          x: event.touches[0].pageX - this.container.offsetLeft,
          y: event.touches[0].pageY - this.container.offsetTop
        };
      } else {
        currentMousePosition = {
          x: event.pageX - this.container.offsetLeft,
          y: event.pageY - this.container.offsetTop
        };
      }

      changeX = currentMousePosition.x - this.lastMousePosition.x;
      changeY = currentMousePosition.y - this.lastMousePosition.y;
      this.lastMousePosition = currentMousePosition;

      img_top_new = this.draggableImage.offsetTop + changeY;
      img_left_new = this.draggableImage.offsetLeft + changeX;
      widthValue = this.imageSize.width * (Number(this.zoomValue) - 1);
      heightValue = this.imageSize.height * (Number(this.zoomValue) - 1);
      img_width = this.imageSize.width + widthValue;
      img_height = this.imageSize.height + heightValue;

      if (this.container.offsetHeight > img_height) {
        this.draggableImage.style.top = 'initial';
      } else {
        img_top_new = this.getPosition(img_top_new,
          this.container.offsetHeight, img_height);
        this.draggableImage.style.top = img_top_new + 'px';
      }
      if (this.container.offsetWidth > img_width) {
        this.draggableImage.style.left = 'initial';
      } else {
        img_left_new = this.getPosition(img_left_new,
          this.container.offsetWidth, img_width);
        this.draggableImage.style.left = img_left_new + 'px';
      }
    }
  }

  public stopCallBack() {
    this.dragActive = false;
    this.draggingStarted = 0;
    // this.imageSize = {width: 0, height: 0};

    this.container.removeEventListener('mousedown',
      this.onDragStart, false);
    this.container.removeEventListener('mousemove',
      this.onDrag, false);
    this.container.removeEventListener('mouseup',
      this.onDragEnd, false);
    this.container.removeEventListener('touchstart',
      this.onDragStart, false);
    this.container.removeEventListener('touchmove',
      this.onDrag, false);
    this.container.removeEventListener('touchend',
      this.onDragEnd, false);
  }

  public setNewImageSize(container, image, width, height) {
    this.container = container;
    if (width > this.container.offsetWidth ||
      height > this.container.offsetHeight) {

      if (width > height) { // Landscape
        this.imageSize.width = this.container.offsetWidth;
        this.imageSize.height = image.clientHeight;
      } else if (height > width) { // portrait
        this.imageSize.height = this.container.offsetHeight;
        this.imageSize.width = image.clientWidth;
      } else {
        this.imageSize = {
          width: this.container.offsetWidth,
          height: this.container.offsetHeight
        };
      }
    } else {
      this.imageSize = {
        width: width,
        height: height
      };
    }
    return this.imageSize;
  }

  public resizeImage(container, zoomValue, image, imageNaturalSize, imageActiveSize, annotation) {
    let widthValue, heightValue, newWidth, newHeight, height, width, maxHeight,
      maxWidth;
    this.zoomValue = zoomValue;
    this.imageSize = imageActiveSize;
    widthValue = this.imageSize.width * (Number(zoomValue) - 1);
    heightValue = this.imageSize.height * (Number(zoomValue) - 1);
    newWidth = this.imageSize.width + widthValue;
    newHeight = this.imageSize.height + heightValue;

    if (Number(this.zoomValue) === 1) {
      height = 'auto';
      maxHeight = '100%';
      width = 'auto';
      maxWidth = '95%';
      this.setWidthAndHeight(annotation, image, height, maxHeight, width, maxWidth);
      image.style.top = 'initial';
      image.style.left = 'initial';
      this.stopCallBack.bind(this);
    } else {
      if (imageNaturalSize.width > imageNaturalSize.height) { // Landscape
        height = 'auto';
        maxHeight = 'none';
        width = newWidth + 'px';
        maxWidth = 'none';
        this.setWidthAndHeight(annotation, image, height, maxHeight, width, maxWidth);
      } else if (imageNaturalSize.height > imageNaturalSize.width) { // portrait
        height = newHeight + 'px';
        maxHeight = 'none';
        width = 'auto';
        maxWidth = 'none';
        this.setWidthAndHeight(annotation, image, height, maxHeight, width, maxWidth);
      } else {
        height = newHeight + 'px';
        maxHeight = 'none';
        width = newWidth + 'px';
        maxWidth = 'none';
        this.setWidthAndHeight(annotation, image, height, maxHeight, width, maxWidth);
      }
      if (Number(this.lastZoomValue) !== Number(this.zoomValue)) {
        this.setPosition(container, imageActiveSize, image, newWidth, newHeight);
      }
    }
    this.lastZoomValue = this.zoomValue;
  }

  public setPosition(container, imageActiveSize, image, newWidth, newHeight) {
    let left, top;
    if (imageActiveSize.width > container.offsetWidth) {
      left = this.getPosition(
        image.offsetLeft,
        container.offsetWidth,
        newWidth);
      left = left + 'px';
    } else {
      left = 'initial';
    }
    if (imageActiveSize.height > container.offsetHeight) {
      top = this.getPosition(image.offsetTop,
        container.offsetHeight, newHeight);
      top = top + 'px';
    } else {
      top = 'initial';
    }
    image.style.left = left;
    image.style.top = top;
  }

  public setWidthAndHeight(annotation, image, height, maxHeight, width, maxWidth) {
    image.style.maxHeight = maxHeight;
    image.style.maxWidth = maxWidth;
    image.style.height = height;
    image.style.width = width;
    if (annotation) {
      if (Number(this.zoomValue) === 1) {
        image.width = this.imageSize.width;
        image.height = this.imageSize.height;

      } else {
        image.width = width;
        image.height = height;
      }
    }
  }

  objectCanHaveImages(object): Promise<boolean> {
    return new Promise(resolve => {
      this.objectCanHaveObjectTypes(object, ['Image']).then(res => {
        resolve(res);
      });
    });
  }

  objectCanHaveVideos(object): Promise<boolean> {
    return new Promise(resolve => {
      this.objectCanHaveObjectTypes(object, ['Video']).then(res => {
        resolve(res);
      });
    });
  }

  objectCanHaveAttachments(object): Promise<boolean> {
    return new Promise(resolve => {
      this.objectCanHaveObjectTypes(object, ['Attachment']).then(res => {
        resolve(res);
      });
    });
  }

  objectCanHaveMedia(object): Promise<boolean> {
    return new Promise(resolve => {
      this.objectCanHaveObjectTypes(object, ['Attachment', 'Video', 'Image']).then(res => {
        resolve(res);
      });
    });
  }

  private objectCanHaveObjectTypes(object, canHaveTypes: Array<string>): Promise<boolean> {
    return new Promise(resolve => {
      const objectType = object[AConst.OBJECT_TYPE];
      if (objectType.indexOf('ct_') !== 0) {
        if (!this.modelRelations[objectType]) {
          this.cms.getModelRelations({modelName: objectType}).then(modelRelations => {
            this.modelRelations[objectType] = modelRelations;
            resolve(this.isObjectTypesInModelRelations(modelRelations, canHaveTypes));
          });
        } else {
          resolve(this.isObjectTypesInModelRelations(this.modelRelations[objectType], canHaveTypes));
        }
      } else {
        resolve(false);
      }
    });
  }

  private isObjectTypesInModelRelations(modelRelations, objectTypes): boolean {
    let res = false;
    if (modelRelations) {
      objectTypes.forEach(objectType => {
        if (modelRelations['object_types'].indexOf(objectType) !== -1) {
          res = true;
        }
      });
    }
    return res;
  }

  private getUpdatedTime(object) {
    let res = null;
    if (object[AConst.TO_DATE] && object[AConst.TO_DATE][AConst.DD_DATE]) {
      res = object[AConst.TO_DATE][AConst.DD_DATE];
    } else if (object[AConst.UPDATED_AT] &&
      object[AConst.UPDATED_AT][AConst.DD_DATE]) {
      res = object[AConst.UPDATED_AT][AConst.DD_DATE];
    }
    return res;
  }

  private setObjectProps(parentItems, objects) {
    const res = [];
    parentItems.forEach((parentItem) => {
      let found = false, t, parent, object, newObject;
      for (t = 0; t < objects.length; t++) {
        object = objects[t];
        if (parentItem.itemId === this.getItemId(object)) {
          parent = parentItem.parent;
          found = true;
          newObject = Object.assign({}, object);
          res.push(newObject);
          if (parent[AConst.ARTIFACT_ID]) {
            this.setMediaProps(newObject, parent);
          }
          break;
        }
      }
    });
    return res;
  }

  private getAllObjects(meta, obj, fn) {
    const ids = [], parents = [];
    let objects = [];
    if (meta.objectTypes.indexOf(obj[AConst.OBJECT_TYPE]) !== -1) {
      objects = [obj];
      parents.push({
        parent: obj,
        itemId: this.getItemId(obj)
      });
      fn(this.setObjectProps(parents, objects));
    } else {
      meta.arrayNames.forEach((arrayName) => {
        if (obj[arrayName]) {
          obj[arrayName].forEach((item) => {
            ids.push(this.getItemId(item));
            parents.push({
              parent: obj,
              itemId: this.getItemId(item)
            });
          });
        }
      });

      if (ids.length > 0) {
        this.arefHandler.getRefsFromServer(ids, (item) => {
          if (item.length > 0) {
            item = this.setObjectProps(parents, item);
          }
          fn(item);
        });
      } else {
        fn([]);
      }
    }
  }

  private getItemId(item, imageIdField?) {
    let res;
    if (imageIdField) {
      res = item[imageIdField];
    } else {
      res = item[this.commons.getObjectIdField(item)];
    }
    return res;
  }

}
