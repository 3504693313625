import {Injectable} from '@angular/core';
import {StateService} from '@uirouter/core';

import {CmsApiService} from './cms-api.service';
import {CmsAuthService} from './cms-auth.service';
import {CommonsService} from './commons.service';
import {ModelsService} from './models.service';
import {EventharvesterService} from './eventharvester.service';
import {SearchPageService} from '../object-search/search-page.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  currentUser = null;
  debug = undefined;
  processing = false;
  progress = 0;
  destState;
  destParams;

  constructor(private cms: CmsApiService,
              private auth: CmsAuthService,
              private commons: CommonsService,
              private models: ModelsService,
              private state: StateService,
              private eventharvester: EventharvesterService,
              private searchPageService: SearchPageService) {

    // Get data about current user, if already logged in
    this.getUserData(true).then(
      data => {
        console.log('User data: ' + JSON.stringify(data));
      },
      reason => {
        console.log('User not logged in yet: ' + reason);
      });
  }

  getStoredAuth() {
    const storedAuth = this.auth.getStoredAuth();
    const res = {
      username: '',
      password: '',
      rememberMe: false
    };
    if (storedAuth) {
      res.username = storedAuth.username;
      res.password = 'password';
      res.rememberMe = true;
    }
    return res;
  }

  loginUser(username, password, rememberMe): Promise<any> {
    return new Promise(resolve => {
      if (this.processing) {
        // Do nothing
      } else {
        this.progress = 0;
        this.processing = true;

        if (this.useStoredAuth(username, password)) {
          this.cms.loginWithStoredAuth({
            username: username,
            rememberMe: rememberMe
          }).then(
            data => {
              this.processLogin(data).then(res => {
                this.processing = false;
                resolve(res);
              });
            },
            response => {
              this.processing = false;
              resolve(this.processErr(response));
            });
        } else {
          this.cms.login({
            username: username,
            password: password,
            rememberMe: rememberMe
          }).then(
            data => {
              this.processLogin(data).then(res => {
                this.processing = false;
                resolve(res);
              });
            },
            response => {
              this.processing = false;
              resolve(this.processErr(response));
            }
          );

        }
      }

    });
  }

  isProcessing() {
    return this.processing;
  }

  logoutUser() {
    this.cms.logout().then(data => {
      console.log('Logout said: ' + data['message']);
      this.currentUser = null;
      this.eventharvester.loggedOff();
      setTimeout(() => {
        this.state.go('home', {
          debug: this.debug
        });
      }, 1000);
    });
  }

  setDebug(value) {
    this.debug = value;
  }

  isDebug() {
    return this.debug;
  }

  setLoginDestinationState(destState, destParams) {
    this.destState = destState;
    this.destParams = destParams;
  }

  getCurrentUser() {
    return this.currentUser;
  }

  private useStoredAuth(username, password) {
    const sa = this.getStoredAuth();
    return (username === sa.username && 'password' === password);
  }

  private processLogin(data): Promise<any> {
    return new Promise<any>(resolve => {
      this.progress = 25;
      const res = {
        error: false,
        message: '',
      };
      if (data) {
        if (data.status === 200) {
          this.getUserData().then(() => {
              this.progress = 40;
              this.models.getModelsAsync().then(() => {
                this.progress = 60;
                this.searchPageService.checkCreateSearchContainer().then(() => {
                  this.progress = 100;
                  this.setPostLoginState();
                  this.eventharvester.clientData();
                  this.eventharvester.loggedOn();
                  resolve(res);
                });
              });
            },
            reason => {
              res.error = true;
              res.message = 'TRANS__LOGIN__FAILED';
              console.error('An error occurred getting user data: ' + reason);
              resolve(res);
            });
          // Models are user specific, and must be reloaded at login

        } else {
          res.error = true;
          res.message = 'TRANS__LOGIN__FAILED';
          resolve(res);
        }
      }
    });
  }

  private processErr(response) {
    return {
      error: true,
      message: response.error.message,
    };
  }

  private getUserData(suppressErrHandler?) {
    return new Promise((resolve, reject) => {
      this.commons.getUserData(true, suppressErrHandler).then(
        userData => {
          this.currentUser = userData;
          resolve(userData);
        },
        response => {
          reject(response);
        });
    });
  }


  private setPostLoginState() {
    if (!this.destState) {
      this.state.go('home.primus.search', {
        searchText: '',
        searchPage: 1,
        filters: {},
        show_filters: false
      });
    } else {
      this.state.go(this.destState, this.destParams);
      this.destState = null;
      this.destParams = null;
    }
  }
}
