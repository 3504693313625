import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MediaSelectorImageVerticalComponent} from './media-selector-image-vertical/media-selector-image-vertical.component';
import {MediaUploadCameraComponent} from './media-upload-camera/media-upload-camera.component';
import { MediaSelectorsComponent } from './media-selectors/media-selectors.component';
import {ImageAnnotationModule} from '../image-annotation/image-annotation.module';
import {SharedModule} from '../shared/shared.module';
import { MediaSelectorGeneralComponent } from './media-selector-general/media-selector-general.component';
import {SelectImagesComponent} from './select-images/select-images.component';
import {ThumbnailCarouselComponent} from './thumbnail-carousel/thumbnail-carousel.component';
import {ThumbnailCarouselNavButtonComponent} from './thumbnail-carousel-nav-button/thumbnail-carousel-nav-button.component';
import {ThumbnailStackComponent} from './thumbnail-stack/thumbnail-stack.component';
import {MediaListComponent} from './media-list/media-list.component';
import {ObjectEditModule} from '../object-edit/object-edit.module';

@NgModule({
  declarations: [
    MediaUploadCameraComponent,
    MediaSelectorImageVerticalComponent,
    MediaSelectorsComponent,
    MediaSelectorGeneralComponent,
    SelectImagesComponent,
    ThumbnailCarouselComponent,
    ThumbnailCarouselNavButtonComponent,
    ThumbnailStackComponent,
    MediaListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    SharedModule,
    ImageAnnotationModule,
    ObjectEditModule
  ],
  exports: [
    MediaSelectorGeneralComponent,
    SelectImagesComponent,
    ThumbnailCarouselComponent,
    ThumbnailCarouselNavButtonComponent,
    ThumbnailStackComponent,
    MediaSelectorsComponent,
    MediaSelectorImageVerticalComponent,
    MediaListComponent
  ]
})
export class MediaModule {
}
