import {FormGroup, NgForm} from '@angular/forms';
import {ObjectView} from './object-view';

export class SectionsContainer {
  copyOptions: object;
  formGroup: FormGroup;
  form: NgForm;
  isCopy = false;
  isDialog = false;
  isTemplate = false;
  hideSectionTitle = false;
  primeFields: Array<any>;
  rootObject: any;
  sections: Array<any>;
  submitted = false;
  templateGroupId: string;
  objectView: ObjectView;
  viewMode: string;
}
