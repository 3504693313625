import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  AConst = AConst;

  @Input() modalTitle;
  @Input() modalContent;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {
  }

  ngOnInit() {
  }

}
