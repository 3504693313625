import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SearchHandlerService} from '../search-handler.service';
import {SearchContainer} from '../search-container';
import {CommonsService} from '../../core/commons.service';

@Component({
  selector: 'app-search-filter-menu',
  templateUrl: './search-filter-menu.component.html',
  styleUrls: ['./search-filter-menu.component.scss']
})
export class SearchFilterMenuComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;
  @Input() sideMenu;
  @Input() dropdownMenu;
  @Input() sideMenuSmallScreen;
  @Output() onToggleDropdownMenu = new EventEmitter<object>();
  @Output() onToggleSideMenuSmallScreen = new EventEmitter<object>();

  pathView = { menus: [] };
  filterGroups = [];
  private path = 'not set';
  private curFocusId;

  constructor(private searchHandler: SearchHandlerService,
              private commons: CommonsService) {
  }

  ngOnInit() {
    this.searchHandler.subscribeToSearchResult(this.searchContainer, this.init);
  }

  ngOnDestroy(): void {
    this.searchHandler.unSubscribeToSearchResult(this.searchContainer, this.init);
  }

  // Show filter group if:
  //  - the group has more than one "check filters"
  //  - or group has only 1 facet based "check filter" and
  //     - facet item count not equals search result count
  //     - or filter has been previously selected
  showFilterGroup(fGroup) {
    return fGroup.checkFilters.length > 0;
  }
  toggleFilterMenuDropDown() {
    this.onToggleDropdownMenu.emit();
  }
  toggleSideFilterMenuSmallScreen() {
    this.onToggleSideMenuSmallScreen.emit();
  }
  private setCheckFilters() {
    if (!this.searchContainer.filterGroups) {
      this.searchContainer.filterGroups = this.searchHandler.getCheckFilterGroups(this.searchContainer);
    }
    this.filterGroups = this.searchContainer.filterGroups ? this.commons.sortArray(this.searchContainer.filterGroups, 'order') : [];
  }

  private init = () => {
    const sc = this.searchContainer;
    if (sc) {
      if (this.path !== sc.path || this.curFocusId !== sc.curFocusId) {
        let menus = this.commons.copy(this.searchContainer.currentPathView.menus);
        if (menus) {
          menus = this.commons.sortArray(menus, 'order');
          menus.forEach(mainMenu => {
            mainMenu.menus = mainMenu.menus ? this.commons.sortArray(mainMenu.menus, 'order') : [];
          });
          this.pathView.menus = menus;
        }
        if (this.path !== 'not set') {
          this.searchContainer.filterGroups = null;
        }
        this.path = sc.path;
        this.curFocusId = sc.curFocusId;
      }

      if (sc.searchResult) {
        this.setCheckFilters();
      }
    }
  }

}
