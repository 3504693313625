import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {ModelsService} from '../../core/models.service';
import {FieldValueService} from '../../core/field-value.service';
import {ReferenceFilterService} from '../../core/reference-filter.service';
import {CmsApiService} from '../../core/cms-api.service';
import {SectionsContainer} from '../../core/sections-container';
import {ObjectEditService} from '../../core/object-edit.service';
import {FieldParameters} from '../../core/field-parameters';
import {MatDialogRef} from '@angular/material';
import {CommonsService} from '../../core/commons.service';


@Component({
  selector: 'app-edit-object-dialog',
  templateUrl: './edit-object-dialog.component.html',
  styleUrls: ['./edit-object-dialog.component.scss']
})
export class EditObjectDialogComponent implements OnInit {

  sectionsContainer: SectionsContainer = new SectionsContainer();
  AConst = AConst;
  primeFields;
  storeStatus = {
    failed: false,
    message: '',
    serverMsg: ''
  };
  objectTypes;
  selectedObjectType = false;

  @Input() fieldParameters: FieldParameters;
  @Input() createText;

  constructor(public dialogRef: MatDialogRef<EditObjectDialogComponent>,
              private objectEditService: ObjectEditService,
              private models: ModelsService,
              private fieldValueHandler: FieldValueService,
              private referenceFilterSvc: ReferenceFilterService,
              private cms: CmsApiService,
              private commons: CommonsService) {
  }

  ngOnInit() {
    if (this.fieldParameters.object) {
      this.createOptionFromFieldParameters(this.fieldParameters, this.createText);
    } else {
      const metaTypeId = this.fieldParameters.field['meta_type_id'];
      if (metaTypeId) {
        this.getSelectableObjectTypes(metaTypeId).then(objectTypes => {
          this.objectTypes = objectTypes;
        });
      }
    }
  }

  onSubmit() {
    this.objectEditService.setObjectValuesStoreObject(this.sectionsContainer, true).then(
      value => {
        this.dialogRef.close(value);
      },
      reason => {
        this.storeStatus.failed = true;
        this.storeStatus.message = 'TRANS__EDIT_OBJECT_DIALOG__STORE_FAILED';
        this.storeStatus.serverMsg = reason.error.message;
      }
    );
  }

  get isValid(): boolean {
    return this.sectionsContainer.formGroup.valid;
  }

  objectTypeSelected($event) {
    if ($event.target.value) {
      this.selectedObjectType = true;
      this.createObject($event.target.value);
    }
  }

  private createOptionFromFieldParameters(fieldParameters: FieldParameters, text?) {
    const parentMeta = fieldParameters.field;
    const parentRef = parentMeta.reference;
    const refObjectType = parentRef[AConst.OBJECT_TYPE];
    if (parentRef[AConst.CAN_ADD_NEW] &&
      (!parentRef[AConst.IS_HIERARCHIC] || parentMeta[AConst.PARENT_ID])) {
      const optionData = this.getOptionData(fieldParameters, text);
      this.createObject(refObjectType, optionData, parentMeta, text);
    } else {
      console.log('Did not create a new option');
    }
  }

  private getOptionData(fieldParameters: FieldParameters, text?) {
    const parentRef = fieldParameters.field.reference;
    const refModel = this.models.getModel(parentRef[AConst.OBJECT_TYPE]);
    const optionData = {
      $$icon: 'icon-plus',
      $$weight: -1
    };
    if (text) {
      optionData[AConst.NAME] = text;
    }

    for (const metaFieldName in refModel.$$meta) {
      if (metaFieldName === AConst.REG_LEVEL_TYPE_ID) {
        optionData[metaFieldName] = this.fieldValueHandler.getFieldValue(
          fieldParameters.object, AConst.REG_LEVEL + '.' + AConst.REG_LEVEL_TYPE_ID);
      } else if (metaFieldName === AConst.PARENT_FIELD) {
        const parentField = parentRef[AConst.PARENT_FIELD];
        const parentVal = this.fieldValueHandler.getFieldValue(fieldParameters.object, parentField);
        const parentTargetField = parentRef[AConst.PARENT_TARGET_FIELD] || AConst.PARENT_ID;
        optionData[parentTargetField] = parentVal;
      }
    }
    this.mergeData(optionData, this.referenceFilterSvc.generateRefFilterData(
      parentRef[AConst.REF_FILTER], false, fieldParameters));

    this.setFieldsFromOtherObject(optionData, fieldParameters);
    return optionData;
  }

  private createObject(objectType, objectData?, parentMeta?, text?) {
    if (parentMeta && parentMeta.reference && parentMeta.reference[AConst.ADD_NEW_PARAMS] &&
      parentMeta.reference[AConst.ADD_NEW_PARAMS][AConst.FULL_OBJECT_EDIT]) {
      this.objectEditService.createModelItemGetSectionsContainer(objectType, objectData).then(sectionsContainer => {
        this.setNewObjectStuff(sectionsContainer, parentMeta, text);
      });
    } else {
      this.objectEditService.createModelItemGetSectionsContainerForPrimeFields(objectType, objectData).then(sectionsContainer => {
        this.setNewObjectStuff(sectionsContainer, parentMeta, text);
        this.primeFields = sectionsContainer.primeFields;
      });

    }
  }

  private setNewObjectStuff(sectionsContainer: SectionsContainer, parentMeta?, text?) {
    const newObject = sectionsContainer.rootObject;
    text = this.objectEditService.setObjectName(newObject, text);
    if (text && parentMeta) {
      newObject[this.getLabelProp(parentMeta)] = this.getLabelText(parentMeta, text);
    }
    this.sectionsContainer = sectionsContainer;
    this.sectionsContainer.isDialog = true;
  }

  private getLabelProp(meta) {
    const refLabelProp = meta.reference[AConst.LABEL_PROP];
    return refLabelProp ? refLabelProp : 'artifact_name';
  }

  private getLabelText(meta, text) {
    let res = text;
    const labelProp = this.getLabelProp(meta);
    if (labelProp === 'f_path') {
      if (text.indexOf('»') !== 0) {
        res = '»' + text;
      }
    }
    return res;
  }


  private mergeData(target, data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        target[key] = data[key];
      }
    }
  }

  private getSelectableObjectTypes(metaTypeId) {
    return new Promise(resolve => {
      this.cms.getArtifactTypes({
        meta_type_ids: [metaTypeId]
      }).then((artifactTypes) => {
        this.cms.searchJson({
          filters: {
            artifact_id: artifactTypes
          }
        }).then(function (searchRes) {
          const res = searchRes[AConst.ARTIFACTS];
          res.unshift({
            description: '',
            artifact_name: ''
          });
          resolve(res);
        });
      });
    });
  }

  private setFieldsFromOtherObject(object, fieldParameters: FieldParameters) {
    const parentRef = fieldParameters.field.reference;
    const addNewParams = parentRef[AConst.ADD_NEW_PARAMS];
    const copyFields = addNewParams ? addNewParams[AConst.COPY_FIELDS] : [];
    copyFields.forEach(copyField => {
      const sourceType = copyField[AConst.COPY_SOURCE_TYPE];
      const sourceField = copyField[AConst.COPY_SOURCE_FIELD];
      const targetField = copyField[AConst.COPY_TARGET_FIELD];
      const copyDefaultValue = copyField[AConst.COPY_DEFAULT_VALUE];
      if (sourceField && targetField) {
        let otherObject = fieldParameters.sectionsContainer.rootObject;
        if (sourceType === 'prev_object') {
          otherObject = fieldParameters.prevObject;
        }
        let sourceValue = otherObject ? otherObject[sourceField] : copyDefaultValue;
        const transformType = copyField[AConst.COPY_TRANSFORM_TYPE];
        if (transformType) {
          if (transformType === '!') {
            sourceValue = !sourceValue;
          }
        }
        this.commons.setObjectValue(object, targetField, sourceValue);
      }
    });
  }

}
