import {StateService} from '@uirouter/core';
import {Component, Input, OnInit} from '@angular/core';
import {SearchHandlerService} from '../search-handler.service';
import {RefService} from '../../core/ref.service';
import {AConst} from '../../core/a-const.enum';
import {SearchContainer} from '../search-container';

@Component({
  selector: 'app-object-navigator',
  templateUrl: './object-navigator.component.html',
  styleUrls: ['./object-navigator.component.scss']
})
export class ObjectNavigatorComponent implements OnInit {

  @Input() searchContainer: SearchContainer;
  @Input() currentObject: any;
  @Input() rootObjId: string;
  @Input() isEditing: boolean;

  canGoPrev: boolean;
  canGoNext: boolean;
  currentObjectId: string;
  searchMode: boolean;

  constructor(private $state: StateService,
              private searchHandler: SearchHandlerService,
              private ref: RefService) {
  }

  ngOnInit() {
    this.currentObjectId = this.currentObject[AConst.ARTIFACT_ID];
    this.searchMode = this.$state.current.name.indexOf('search') !== -1;
    this.setCanNavigateSearchItem();
  }

  goToSearchPage() {
    const state = 'home.primus.search';
    const params = {
      path: this.searchContainer.path,
      targetId: 'none',
      objectCount: 0
    };
    this.$state.go(state, params, {reload: true});
  }

  prevSearchItem() {
    if (this.canGoPrev) {
      this.searchHandler.searchItemPrev(this.searchContainer).then((item) => {
        this.goToSearchItem(item);
      });
    }
  }

  nextSearchItem() {
    if (this.canGoNext) {
      this.searchHandler.searchItemNext(this.searchContainer).then((item) => {
        this.goToSearchItem(item);
      });
    }
  }

  goToRootSearchItem() {
    this.goToSearchItem({
      artifact_id: this.rootObjId
    });
  }

  private goToSearchItem(item) {
    let refInfo;
    if (item) {
      refInfo = this.createRef(item);
      this.$state.go(refInfo.stateName, refInfo.param, {reload: true});
    }
  }

  private createRef(searchItem) {
    const params = {
      object: searchItem,
      searchItemIndex: this.searchContainer.searchItemIndex,
      rootObjId: searchItem[AConst.ARTIFACT_ID],
      stateParams: null
    };
    if (this.currentObject[AConst.OBJECT_TYPE] !== searchItem[AConst.OBJECT_TYPE]) {
      params.stateParams = {
        listName: 'overview'
      };
    }
    return this.ref.makeRef(params);
  }

  private setCanNavigateSearchItem() {
    const sc = this.searchContainer;
    this.canGoPrev = sc.searchItemIndex > 0 || sc.searchPage > 1;
    this.canGoNext = sc.searchItemIndex + 1 < sc.searchResult[AConst.ARTIFACTS].length || sc.searchPage < sc.maxPage;
  }

}
