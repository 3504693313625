import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material';

import {AConst} from './a-const.enum';
import {CmsApiService} from './cms-api.service';
import {EditObjectDialogComponent} from '../object-edit/edit-object-dialog/edit-object-dialog.component';
import {ReferenceFilterService} from './reference-filter.service';
import {KulturnavSearchDialogComponent} from '../shared/kulturnav-search-dialog/kulturnav-search-dialog.component';
import {CommonsService} from './commons.service';
import {FieldParameters} from './field-parameters';
import {QueryParserService} from './query-parser.service';
import {FieldValueService} from './field-value.service';
import {AlertDialogComponent} from '../shared/alert-dialog/alert-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class OptionsService {

  constructor(private modalService: MatDialog,
              private cms: CmsApiService,
              private referenceFilterSvc: ReferenceFilterService,
              private commons: CommonsService,
              private queryParser: QueryParserService,
              private fieldValue: FieldValueService) {
  }

  searchOptions(params): Promise<Array<object>> {
    return new Promise((resolve) => {
      this.getSearchParams(params).then(searchParams => {
        this.cms.searchJson(searchParams).then((searchRes) => {
          let res = [];
          if (searchRes[AConst.ARTIFACTS]) {
            res = this.filterOptions(params.fieldParameters, searchRes[AConst.ARTIFACTS]);
          }
          resolve(res);
        });
      });
    });
  }

  filterOptions(fieldParameters: FieldParameters, options) {
    let res = this.commons.copy(options), inline, uniqueProp,
      uniqueValues;
    const parentModel = fieldParameters.object;
    let parentFieldName = fieldParameters.field[AConst.PARENT_NAME];
    let grandParentModel = fieldParameters.grandParentObject;
    // $$grandParentModel used for models outside regular model regime, e.g. TemplateGroup
    if (!grandParentModel && parentModel.$$grandParentModel) {
      grandParentModel = parentModel.$$grandParentModel;
      parentFieldName = parentModel.$$parentName;
    }
    if (grandParentModel) {
      inline = grandParentModel[AConst.$$META][parentFieldName][AConst.INLINE];
      if (inline) {
        uniqueProp = inline[AConst.UNIQUE_PROP];
        uniqueValues = inline[AConst.UNIQUE_VALUES];
        if (uniqueProp) {
          res = this.filterUniqueOptions(grandParentModel[parentFieldName], options, uniqueProp, uniqueValues);
        }
      }
    }
    return res;
  }

  createOption(fieldParameters: FieldParameters, text?) {
    return new Promise((resolve, reject) => {
      const modalRef = this.modalService.open(EditObjectDialogComponent, {panelClass: 'edit-dialog'});
      modalRef.componentInstance.fieldParameters = fieldParameters;
      modalRef.componentInstance.createText = text;
      modalRef.afterClosed().subscribe(result => {
        if (result) {
          resolve(result);
        } else {
          reject();
        }
      });
    });
  }

  searchKulturNav(field, phase): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      const modalRef = this.modalService.open(KulturnavSearchDialogComponent, {panelClass: 'edit-dialog'});
      modalRef.componentInstance.field = field;
      modalRef.componentInstance.phrase = phase;
      modalRef.afterClosed().subscribe(result => {
        if (result) {
          resolve(result);
        } else {
          reject();
        }
      });
    });
  }

  searchHierarchicOptions(objectType: string, level: number, getLeaf: boolean, start: number, rows: number, parentId?, query?) {
    return new Promise(resolve => {
      const params = {filters: {}, rows: rows, start: 0};
      params.filters[AConst.OBJECT_TYPE] = objectType;
      params.filters[AConst.LEVEL] = level;
      params.filters[AConst.PARENT_ID] = parentId;
      params.filters[AConst.IS_LEAF] = getLeaf ? 'true' : 'false';
      params.filters['-marked'] = true;
      if (query) {
        params['query'] = this.buildHierarchicQuery(query, true);
      }
      params.start = start;
      this.cms.searchJson(params).then(res => {
        resolve(res);
      });
    });
  }

  buildHierarchicQuery(query: string, addWildcards: boolean) {
    let res = query;
    let lastPathSepIdx = res.lastIndexOf('»');
    if (lastPathSepIdx === -1) {
      lastPathSepIdx = res.lastIndexOf(':');
    }
    if (lastPathSepIdx !== -1) {
      res = res.substring(lastPathSepIdx + 2);
    }
    if (addWildcards) {
      res = (res || '') + '*';
    }
    return res;
  }

  getSingleOptionFromId(optionId): Promise<object> {
    return new Promise((resolve, reject) => {
      this.cms.searchJson({filters: {artifact_id: optionId}}).then(
        res => {
          if (res[AConst.ARTIFACTS] && res[AConst.ARTIFACTS].length) {
            resolve(res[AConst.ARTIFACTS][0]);
          } else {
            console.warn('No options found with id ' + optionId);
            resolve(null);
          }
        },
        reason => {
          reject(reason);
        }
      );
    });
  }

  weightOptionsByQuery(options, queryIn) {
    let res = options;
    if (queryIn) {
      const query = this.buildHierarchicQuery(queryIn, false).toLocaleLowerCase();
      const exact = [], beginsWith = [], contains = [], rest = [];
      options.forEach(option => {
        const name = this.getOptionName(option).toLocaleLowerCase();
        if (name === query) {
          exact.push(option);
        } else if (name.indexOf(query) === 0) {
          beginsWith.push(option);
        } else if (name.indexOf(query) !== -1) {
          contains.push(option);
        } else {
          rest.push(option);
        }
      });
      res = exact.concat(beginsWith, contains, rest);
    }
    return res;
  }

  setOptionsQueryHighlight(options, query, preferArtifactNameField?: boolean, addCodeToOption?: boolean) {
    options.forEach(option => {
      option.$$name = this.getTextWithQueryHighlight(option, 'name', query, preferArtifactNameField, addCodeToOption);
      option.$$description = this.getTextWithQueryHighlight(option, 'description', query, preferArtifactNameField, addCodeToOption);
    });
  }

  setSelectedOptionName(option, query, preferArtifactNameField?: boolean) {
    option.$$name = this.getTextWithQueryHighlight(option, 'name', query, preferArtifactNameField);
  }

  getOptionName(option, preferArtifactNameField?: boolean, addCodeToOption?: boolean): string {
    let name;
    if (!preferArtifactNameField) {
      name = option.name || option[AConst.ARTIFACT_NAME] || '';
      if (addCodeToOption && option.code) {
        name = option.code + ': ' + option.name;
      }
    } else {
      name = option[AConst.ARTIFACT_NAME] || '';
    }
    if (!name) {
      console.warn('Cannot get option name for ' + JSON.stringify(option));
    }
    return name;
  }

  setIconAndAuthority(item: any, fieldName: string, fieldInfo?: any) {
    let icon, artifactId: string;
    if (fieldInfo && fieldInfo.display && fieldInfo.display.label !== undefined) {
      icon = fieldInfo.display.label.icon;
    }
    artifactId = item[fieldName];
    const objectType = this.commons.getObjectTypeFromObjectId(artifactId);
    if (artifactId && objectType) {
      this.cms.searchJson({
        filters: {
          artifact_id: artifactId,
          object_type: objectType
        }
      }).then(
        res => {
          let art;
          if (res[AConst.ARTIFACTS]) {
            art = res[AConst.ARTIFACTS][0];
            if (art[AConst.AUTHORITY] === 'KulturNav' || icon) {
              this.setSelectedOptionName(art, null, true);
              item['$$' + fieldName + '_value'] = art['$$name'];
            } else {
              this.fieldValue.getValueCompanion(item, fieldName).then(value => {
                item['$$' + fieldName + '_value'] = value;
              });
            }
          }
        },
        reason => {
          console.error('Error retrieving icon/authority data for id ' + artifactId + ', object type ' + objectType +
            ': ' + reason.error.message);
        }
      );
    }
  }

  markSelectedOptions(allOptions, selectedOptions, inlineProp) {
    if (selectedOptions.length > 0) {
      allOptions.forEach(option => {
        selectedOptions.forEach(selectedOption => {
          if (option[AConst.ARTIFACT_ID] === selectedOption[inlineProp] && !selectedOption._destroy) {
            option.$$isSelected = true;
          }
        });
      });
    }
  }

  toggleDescription(option) {
    option.$$showDescription = true;
    const description = option['$$description'];
    const title = option['$$name'];
    const modalRef = this.modalService.open(AlertDialogComponent,
      {panelClass: 'alert-dialog'});
    modalRef.componentInstance.modalContent = description;
    modalRef.componentInstance.modalTitle = title;
    modalRef.afterClosed().subscribe(() => {
      // setTimeout(() => {
      option.$$showDescription = false;
      // }, 300);
    });
  }

  private getTextWithQueryHighlight(
    option: object, fieldName: string, query: string, preferArtifactNameField?: boolean, addCodeToOption?: boolean) {
    let res;
    if (fieldName === 'name') {
      res = this.getOptionName(option, preferArtifactNameField, addCodeToOption);
    } else {
      res = option[fieldName];
    }
    if (res && query) {
      const myQuery = this.buildHierarchicQuery(query, false);
      if (myQuery.length > 1) {
        res = this.replaceRecursive(res, myQuery);
      }
    }
    if (fieldName === 'name' && option[AConst.AUTHORITY] === 'KulturNav') {
      const markedClass = option[AConst.AUTHORITY_MARKED] === true ? 'marked' : '';
      if (option[AConst.M_PATH]) {
        res = '<span class=\'concept-authority-left ' + markedClass + '\'></span>' +
          '<span class=\'concept-name\'>' + res + '</span>';
      } else {
        res = '<span class=\'concept-name\'>' + res + '</span>' +
          '<span class=\' concept-authority-right' + markedClass + '\'></span>';
      }
    }
    if (option['icon']) {
      res = this.getOptionIcon(option) + '<span class=\'concept-name\'>' + res + '</span>';
    }
    return res;
  }

  private getOptionIcon(option) {
    let res = '';
    if (option.icon) {
      res = '<i class="' + option.icon + ' select-options-icon">' + option[AConst.ICON_FRAME] + '</i>';
    }
    return res;
  }

  private replaceRecursive(text: string, findText: string) {
    let res = text;
    const findIndex = res.toLocaleLowerCase().indexOf(findText.toLocaleLowerCase());
    if (findIndex !== -1) {
      const endQPos = findIndex + findText.length;
      res = res.substring(0, findIndex) + '<strong>' + res.substring(findIndex, endQPos) + '</strong>' +
        this.replaceRecursive(res.substring(endQPos), findText);
    }
    return res;
  }

  private setQueryField(params, query) {
    params.query = this.queryParser.parse(query).toLocaleUpperCase();
  }


  private getSearchParams(params): Promise<object> {
    return new Promise(resolve => {
      let res, refFilterData, operator, parentInfo;
      const meta = params.meta;
      this.getSearchOptions(params).then(options => {
        res = {
          'filters': {
            'object_type': meta.reference[AConst.OBJECT_TYPE],
            'meta_type': meta.reference[AConst.META_TYPE],
            'artifact_id': options
          },
          'sort': meta.reference[AConst.SORT],
          'facet': meta.facet,
          'rows': 200,
          'parents_only': meta.reference[AConst.PARENTS_ONLY]
        };
        ['-artifact_id'].forEach(filterName => {
          if (meta[filterName] !== undefined) {
            res.filters[filterName] = meta[filterName];
          }
        });
        // Do not return options that are marked as disabled
        const objType = meta.reference[AConst.OBJECT_TYPE];
        if (objType.indexOf('ct_') === 0 || objType === 'place' || objType === 'actor') {
          res.filters['-marked'] = true;
        }
        parentInfo = this.getParentInfo(params);
        if (parentInfo) {
          res.filters[parentInfo.parentFilterField] = parentInfo.parentId;
        }
        refFilterData = this.referenceFilterSvc.generateRefFilterData(
          meta.reference[AConst.REF_FILTER], true, params.fieldParameters);

        operator = meta.reference[AConst.FILTER_OPERATOR];
        if (operator) {
          // Used when adding items to existing folder
          res.filters.sub_filters = refFilterData;
          res.filters.sub_filters.operator = operator;
        } else {
          this.mergeData(res.filters, refFilterData);
        }
        if (params.query) {
          this.setQueryField(res, params.query);
        }

        resolve(res);
      });
    });
  }

  private getSearchOptions(params): Promise<Array<object>> {
    return new Promise(resolve => {
      this.commons.getUserData(false).then(userData => {
        const optionsInfo = params.meta[AConst.OPTION_INFO];
        let options;
        if (optionsInfo) {
          options = optionsInfo[AConst.OPTIONS];
          if (typeof options === 'string') {
            if (options === 'user.collections') {
              options = Object.keys(userData['collections']);
            } else {
              console.warn('Unable to handle option "' + options + '"');
            }
          }
        }
        resolve(options);

      });
    });
  }

  private getParentInfo(params) {
    let res = null, ref;
    let parentId = params.meta[AConst.PARENT_ID], parentField;
    let parentFilterField = 'parent_id';
    if (!parentId) {
      ref = params.meta.reference;
      if (ref && ref[AConst.PARENT_FIELD]) {
        parentField = ref[AConst.PARENT_FIELD];
        parentFilterField = ref[AConst.PARENT_TARGET_FIELD] || parentFilterField;
        const fieldParameters: FieldParameters = params.fieldParameters;
        if (!parentId) {
          parentId = fieldParameters.object[parentField];
        }
        if (!parentId) {
          parentId = fieldParameters.rootObject[parentField];
        }
      }
    }
    if (parentId) {
      res = {
        parentFilterField: parentFilterField,
        parentId: parentId
      };
    }
    return res;
  }

  private mergeData(target, data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        target[key] = data[key];
      }
    }
  }

  private filterUniqueOptions(parentArray, options, uniqueProp, uniqueValues) {
    parentArray.forEach((item) => {
      const indexOf = this.getOptionIndex(options, item[uniqueProp]);
      if (indexOf !== -1) {
        if (uniqueValues === undefined) {
          options.splice(indexOf, 1);
        } else {
          if (uniqueValues.indexOf(item[uniqueProp]) !== -1) {
            options.splice(indexOf, 1);
          }
        }
      }
    });
    return options;
  }

  private getOptionIndex(options, value) {
    let t, indexOf = options.indexOf(value);
    if (indexOf === -1) {
      for (t = 0; t < options.length; t++) {
        if (this.getOptionCompareValue(options[t]) === value) {
          indexOf = t;
          break;
        }
      }
    }
    return indexOf;
  }

  private getOptionCompareValue(option) {
    let compVal = option.value;
    if (compVal === undefined) {
      compVal = option[AConst.ARTIFACT_ID];
    }
    if (compVal === undefined) {
      console.warn('Option missing compare value');
    }
    return compVal;
  }

}
