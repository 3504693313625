import {Component, Input, OnChanges} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';

@Component({
  selector: 'app-inline-array-header-item',
  templateUrl: './inline-array-header-item.component.html',
  styleUrls: ['./inline-array-header-item.component.scss']
})
export class InlineArrayHeaderItemComponent implements OnChanges {
  @Input() label;
  @Input() container;
  @Input() isHeadline: boolean;
  @Input() edit: boolean;

  AConst = AConst;
  labelClass = '';

  constructor() { }

  ngOnChanges() {
    if (this.edit && this.label[AConst.CSS_CLASS_EDIT]) {
      this.labelClass = this.label[AConst.CSS_CLASS_EDIT];
    } else if (!this.edit && this.label[AConst.CSS_CLASS_VIEW]) {
      this.labelClass = this.label[AConst.CSS_CLASS_VIEW];
    }
  }

}
