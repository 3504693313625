import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {OptionsService} from '../../../core/options.service';
import {HierarchyRow} from '../edit-field-select-hierarchy/edit-field-select-hierarchy.component';

@Component({
  selector: 'app-edit-field-select-child-options',
  templateUrl: './edit-field-select-child-options.component.html',
  styleUrls: ['./edit-field-select-child-options.component.scss']
})
export class EditFieldSelectChildOptionsComponent implements OnChanges {
  @Input() selectedOptionId;
  @Input() parentNode;
  @Input() children;
  @Input() selectedRow: HierarchyRow;
  @Input() maxRows;
  @Input() rootNodeIndex;
  @Input() parentNodeIndex;
  @Output() childSelected = new EventEmitter<object>();
  @Output() childUnselected = new EventEmitter<object>();
  @Output() setMaxRow = new EventEmitter<object>();

  isArray = false;
  childStart = 0;
  searchCount = 0;
  objectType;
  options = [];

  constructor(private optionsService: OptionsService) {
  }

  ngOnChanges() {
    this.isArray = Array.isArray(this.selectedOptionId);
    this.childStart = 0;
    this.options = this.children.slice(0, this.maxRows);
  }

  setSelectedRow(child, i) {
    let isSelected = false;
    if (child.level === this.selectedRow.level && i === this.selectedRow.selectedChild) {
      if (child.level === 1) {
        isSelected = true;
      } else if (child.level === 2) {
        if (this.rootNodeIndex === this.selectedRow.selectedNode) {
          isSelected = true;
        }
      } else if (child.level === 3) {
        if (this.rootNodeIndex === this.selectedRow.selectedNode &&
          this.parentNodeIndex === this.selectedRow.selectedChildNode) {
          isSelected = true;
        }
      }
    }
    return isSelected;
  }

  selectChild(event, option) {
    event.preventDefault();
    if (!option.$$isSelected) {
      this.childSelected.emit(option);
    } else {
      this.childUnselected.emit(option);
    }
  }

  selectChildWithKey(event, option) {
    switch (event.key) {
      case 'Enter':
      case ' ':
        if (!option.$$showDescription) {
          this.selectChild(event, option);
          event.preventDefault();
        }
        break;
    }
  }

  toggleDescription(child) {
    this.optionsService.toggleDescription(child);
  }

  showAllChildren() {
    this.childStart = 0;
    this.maxRows = this.children.length;
    this.setMaxRow.emit(this.maxRows);
    this.options = this.children;
  }

  nextChildren() {
    this.childStart += this.maxRows;
    this.options = this.options.concat(this.children.slice(this.childStart, this.childStart + this.maxRows));
    this.maxRows = this.options.length;
    this.setMaxRow.emit(this.maxRows);
  }

}
