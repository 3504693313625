import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {UIRouterModule} from '@uirouter/angular';
import {MatDialogModule} from '@angular/material';

import {ObjectNavigatorComponent} from './object-navigator/object-navigator.component';
import {SharedModule} from '../shared/shared.module';
import {SearchQueryComponent} from './search-query/search-query.component';
import {SearchFilterMenuComponent} from './search-filter-menu/search-filter-menu.component';
import {SearchFilterMenuPathComponent} from './search-filter-menu/search-filter-menu-path/search-filter-menu-path.component';
import {SearchFilterMenuItemComponent} from './search-filter-menu/search-filter-menu-item/search-filter-menu-item.component';
import {SearchFilterGroupComponent} from './search-filter-menu/search-filter-group/search-filter-group.component';
import {DateSearchFieldComponent} from './date-search-field/date-search-field.component';
import {SearchFacetRangesComponent} from './search-filter-menu/search-facet-ranges/search-facet-ranges.component';
import { SearchFilterMenuSmallComponent } from './search-filter-menu/search-filter-menu-small/search-filter-menu-small.component';
import {OverviewFieldsModule} from '../overview-fields/overview-fields.module';
import { SearchResultViewComponent } from './search-result-view/search-result-view.component';
import { SearchResultViewContentListComponent } from './search-result-view/search-result-view-content-list/search-result-view-content-list.component';
import { SearchResultViewThumbnailComponent } from './search-result-view/search-result-view-thumbnail/search-result-view-thumbnail.component';
import { SearchResultViewListSelectorComponent } from './search-result-view/search-result-view-list-selector/search-result-view-list-selector.component';
import { SearchResultViewListThumbnailComponent } from './search-result-view/search-result-view-list-thumbnail/search-result-view-list-thumbnail.component';
import { SearchResultViewListComponent } from './search-result-view/search-result-view-list/search-result-view-list.component';
import { SearchResultViewFolderComponent } from './search-result-view/search-result-view-folder/search-result-view-folder.component';
import { SearchMenusComponent } from './search-menus/search-menus.component';
import { SearchMenuSortComponent } from './search-menus/search-menu-sort/search-menu-sort.component';
import { SearchMenuViewSelectorComponent } from './search-menus/search-menu-view-selector/search-menu-view-selector.component';
import { SearchMenuFolderSettingsComponent } from './search-menus/search-menu-folder-settings/search-menu-folder-settings.component';
import { SearchMenuActionsComponent } from './search-menus/search-menu-actions/search-menu-actions.component';
import { SearchActionMenuReportComponent } from './search-menus/search-action-menu-report/search-action-menu-report.component';
import {ReportModule} from '../report/report.module';
import { SearchMenuSelectAllComponent } from './search-menus/search-menu-select-all/search-menu-select-all.component';
import { SearchMenuToggleRestrictionsComponent } from './search-menus/search-menu-toggle-restrictions/search-menu-toggle-restrictions.component';
import { SearchMenuSelectChosenComponent } from './search-menus/search-menu-select-chosen/search-menu-select-chosen.component';
import { SearchResultPagerComponent } from './search-result-view/search-result-pager/search-result-pager.component';
import { SearchResultPagerListComponent } from './search-result-view/search-result-pager-list/search-result-pager-list.component';
import { SearchViewSelectorComponent } from './search-view-selector/search-view-selector.component';
import { SearchHeaderComponent } from './search-header/search-header.component';
import { SideMenuTogglerComponent } from './search-header/side-menu-toggler/side-menu-toggler.component';
import { SearchViewContentListComponent } from './search-view-content-list/search-view-content-list.component';
import { SearchFocusMenuComponent } from './search-menus/search-focus-menu/search-focus-menu.component';
import { SearchFocusEditDialogComponent } from './search-menus/search-focus-edit-dialog/search-focus-edit-dialog.component';
import { SearchViewFolderComponent } from './search-view-folder/search-view-folder.component';
import { SearchViewFullComponent } from './search-view-full/search-view-full.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { SearchQueryPathMenuComponent } from './search-query/search-query-path-menu/search-query-path-menu.component';

@NgModule({
  imports: [
    FormsModule,
    UIRouterModule,
    CommonModule,
    MatDialogModule,
    SharedModule,
    OverviewFieldsModule,
    ReportModule
  ],
  exports: [
    ObjectNavigatorComponent,
    SearchQueryComponent,
    SearchViewContentListComponent,
    SearchViewSelectorComponent,
    SearchViewFullComponent
  ],
  declarations: [
    ObjectNavigatorComponent,
    SearchQueryComponent,
    SearchFilterMenuComponent,
    SearchFilterMenuPathComponent,
    SearchFilterMenuItemComponent,
    SearchFilterGroupComponent,
    DateSearchFieldComponent,
    SearchFacetRangesComponent,
    SearchFilterMenuSmallComponent,
    SearchResultViewComponent,
    SearchResultViewContentListComponent,
    SearchResultViewThumbnailComponent,
    SearchResultViewListSelectorComponent,
    SearchResultViewListThumbnailComponent,
    SearchResultViewListComponent,
    SearchResultViewFolderComponent,
    SearchMenusComponent,
    SearchMenuSortComponent,
    SearchMenuViewSelectorComponent,
    SearchMenuFolderSettingsComponent,
    SearchMenuActionsComponent,
    SearchActionMenuReportComponent,
    SearchMenuSelectAllComponent,
    SearchMenuToggleRestrictionsComponent,
    SearchMenuSelectChosenComponent,
    SearchResultPagerComponent,
    SearchResultPagerListComponent,
    SearchViewSelectorComponent,
    SearchHeaderComponent,
    SideMenuTogglerComponent,
    SearchViewContentListComponent,
    SearchFocusMenuComponent,
    SearchFocusEditDialogComponent,
    SearchViewFolderComponent,
    SearchViewFullComponent,
    SearchPageComponent,
    SearchQueryPathMenuComponent
  ],
  providers: [
  ]
})
export class ObjectSearchModule {
}
