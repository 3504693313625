import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DateToolsService} from '../../core/date-tools.service';

@Component({
  selector: 'app-date-search-field',
  templateUrl: './date-search-field.component.html',
  styleUrls: ['./date-search-field.component.scss']
})
export class DateSearchFieldComponent implements OnInit {
  @Input() parentModel;
  @Input() propName: string;
  @Input() greaterThan: string;
  @Input() lessThan: string;
  @Input() dateChangeArgs;
  @Output() dateChange = new EventEmitter<object>();

  valid = true;
  localDate;

  constructor(private dateTools: DateToolsService) {
  }

  ngOnInit() {
    if (this.parentModel) {
      this.localDate = this.dateTools.solrDateToLocalStringDate(this.parentModel[this.propName]);
    }
  }

  setSolrDate() {
    let dateRes;
    if (this.parentModel) {
      dateRes = this.dateTools.localStringDateToSolrDate(this.localDate);
      this.valid = this.checkValidDate(dateRes);
      if (dateRes.valid && this.parentModel[this.propName] !== dateRes.solrDate) {
        this.parentModel[this.propName] = dateRes.solrDate;
        this.runDateChangeFn(dateRes.solrDate);
      }
    }
  }

  private runDateChangeFn(solrDate) {
    if (this.dateChangeArgs) {
      this.dateChange.emit(this.dateChangeArgs);
    } else {
      this.dateChange.emit(solrDate);
    }
  }

  private checkValidDate(dateRes) {
    if (dateRes.valid) {
      if (this.lessThan && dateRes.solrDate > this.parentModel[this.lessThan]) {
        dateRes.valid = false;
      }
      if (this.greaterThan && dateRes.solrDate < this.parentModel[this.greaterThan]) {
        dateRes.valid = false;
      }
    }
    return dateRes.valid;
  }

}
