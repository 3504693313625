import {SectionsContainer} from './sections-container';

export class FieldParameters {
  sectionsContainer: SectionsContainer;
  rootObject: any;
  grandParentObject: any;
  object: any;
  field: any;
  edit: boolean;
  index: number;
  parentIndex: number;
  prevObject: any;
}
