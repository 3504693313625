import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {UserSettingsService} from '../../../core/user-settings.service';
import {CopyKeepService} from '../../copy-keep-checkbox/copy-keep.service';
import {SectionsContainer} from '../../../core/sections-container';

@Component({
  selector: 'app-section-view-title-edit',
  templateUrl: './section-view-title-edit.component.html',
  styleUrls: ['./section-view-title-edit.component.scss']
})
export class SectionViewTitleEditComponent implements OnInit, OnChanges {
  AConst = AConst;
  hideSectionWarning = '';

  @Input() section: any;
  @Input() sectionsContainer: SectionsContainer;
  @Output() updateKeepSection = new EventEmitter<object>();

  hideHideSectionWarning = false;
  sectionInfo: any = {};

  constructor(private copyKeepService: CopyKeepService, private userSettings: UserSettingsService) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.sectionsContainer.isCopy) {
      if (this.sectionsContainer.copyOptions) {
        const objType = this.sectionsContainer.rootObject[AConst.OBJECT_TYPE];
        const objCopyOptions = this.sectionsContainer.copyOptions[objType];
        this.sectionInfo = objCopyOptions.sections[this.section.name];
        if (this.section.order > 1) {
          if (objCopyOptions.allSections.keep) {
            this.sectionInfo.keep = true;
          }
          this.setKeepSection(this.sectionInfo.keep);
          this.section.keep = this.sectionInfo.keep;
          this.updateKeepSection.emit(this.section);
        } else {
          this.section.keep = true;
        }
      } else {
        console.warn('Copy options missing!');
      }
    }
  }

  setSectionKeep() {
    this.sectionInfo.keep = !this.sectionInfo.keep;
    const keep = this.sectionInfo.keep;
    if (this.sectionInfo) {
      this.section.keep = keep;
      if (!keep) {
        const objType = this.sectionsContainer.rootObject[AConst.OBJECT_TYPE];
        const objCopyOptions = this.sectionsContainer.copyOptions[objType];
        objCopyOptions.allSections.keep = false;
      }
      this.setKeepSection(keep);
      this.userSettings.storeCopyOptions(this.sectionsContainer.copyOptions);
      this.updateKeepSection.emit(this.section);
    }
  }

  onSetSectionKeepWithKey(evt) {
    evt = evt || window.event;
    switch (evt.key) {
      case 'Enter':
        // case ' ': //Space
        this.setSectionKeep();
        evt.preventDefault();
        break;
    }
  }

  toggleSectionVisible() {
    this.section[AConst.SECTION_VISIBLE] = !this.section[AConst.SECTION_VISIBLE];
    if (!this.section[AConst.SECTION_VISIBLE] && !this.hideHideSectionWarning) {
      this.hideSectionWarning = 'TRANS__HIDE_SECTION_WARNING';
    } else {
      this.hideSectionWarning = '';
    }
  }

  setHideHideSectionWarning() {
    this.hideHideSectionWarning = true;
    this.hideSectionWarning = '';
  }

  private setKeepSection(keep) {
    this.copyKeepService.setKeepSection(this.sectionsContainer.rootObject, this.section, keep);
  }

}
