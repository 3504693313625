import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {StateService} from '@uirouter/core';
import {SearchHandlerService} from '../../search-handler.service';
import {SearchContainer} from '../../search-container';
import {UiToolsService} from '../../../core/ui-tools.service';
import {group, query, stagger, style, transition, trigger, useAnimation} from '@angular/animations';
import {fadeIn, fadeOut, slideDownMenu, slideUpMenu} from '../../../shared/animations';


@Component({
  selector: 'app-search-query-path-menu',
  templateUrl: './search-query-path-menu.component.html',
  styleUrls: ['./search-query-path-menu.component.scss'],
  animations: [
    trigger('slideInPathMenu', [
      transition(':leave', [
        group([
          useAnimation(slideUpMenu, {params: {time: '300ms ease-in-out'}}),
          query('.search-query-menu-item', [
            style({opacity: 1}),
            stagger(-100, useAnimation(fadeOut, {params: {time: '50ms'}}))
          ]),
        ])
      ]),
      transition(':enter', [
        group([
          useAnimation(slideDownMenu, {params: {time: '300ms ease'}}),
          query('.search-query-menu-item', [
            style({opacity: 0}),
            stagger(100, useAnimation(fadeIn, {params: {time: '50ms'}}))
          ]),
        ])
      ]),
    ])
  ]
})
export class SearchQueryPathMenuComponent implements OnInit, OnDestroy {

  @Input() showPathMenu: boolean;
  @Input() searchContainer: SearchContainer;
  @Output() menuPathSet = new EventEmitter<string>();

  searchMenuPath: Array<any>;
  searchMenuPathTitle;
  private clickListenerId;

  constructor(private stateService: StateService,
              private searchHandler: SearchHandlerService,
              private uiTools: UiToolsService) {
  }

  ngOnInit() {
    this.setSearchMenuPath();
    this.clickListenerId = this.uiTools.addDocumentClickListener(() => {
      this.showPathMenu = false;
    });
  }

  ngOnDestroy(): void {
    this.uiTools.removeDocumentClickListener(this.clickListenerId);
  }

  setSearchMenuPath() {
    this.searchMenuPath = this.searchHandler.getSearchMenuPath(this.searchContainer);
    this.searchMenuPath[this.searchMenuPath.length - 1].path = this.searchContainer.path;
    this.searchMenuPathTitle = this.searchContainer.currentPathView.title;
    this.menuPathSet.emit(this.searchMenuPathTitle);
  }


  togglePathMenu() {
    const pathMenus = this.searchMenuPath;
    if (pathMenus && pathMenus.length > 1) {
      this.showPathMenu = !this.showPathMenu;
      this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    } else {
      // search();
    }
  }

  selectFilterMenu(menu) {
    if (menu.path) {
      this.searchMenuPathTitle = menu.title;
      this.menuPathSet.emit(menu.title);
      this.searchHandler.goPathView(menu.path, this.searchContainer);
    }
  }

}
