import {Component, Input, OnInit} from '@angular/core';
import {SearchContainer} from '../search-container';
import {SelectorContainer} from '../../core/selector-container';
import {AConst} from '../../core/a-const.enum';
import {SearchSelectorService} from '../../core/search-selector.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-search-view-selector',
  templateUrl: './search-view-selector.component.html',
  styleUrls: ['./search-view-selector.component.scss'],
  animations: [
    trigger('slideSelector', [
      transition(':leave', [
        style({overflow: 'hidden'}),
        animate('200ms ease-in', style({transform: 'translateX(100%)'}))
      ]),

      transition(':enter', [
        style({overflow: 'hidden', transform: 'translateX(100%)'}),
        animate('200ms ease-out', style({transform: 'translateX(0%)'}))
      ])
    ])
  ]
})
export class SearchViewSelectorComponent implements OnInit {

  @Input() searchContainer: SearchContainer;
  @Input() selectorContainer: SelectorContainer;

  AConst = AConst;
  setSearchQueryFocus = true;
  newLayout = false;
  searchPlaceholder = 'TRANS__HEADER__SEARCH_PLACEHOLDER';

  constructor(private searchSelectorService: SearchSelectorService) {
  }

  ngOnInit() {
  }

  disableSelector() {
    if (!this.selectorContainer) {
      console.error('A selector container must be set!');
    }
    this.searchSelectorService.disableSelector(this.selectorContainer);
  }

  cancelTemplate() {
    if (this.searchContainer.selectCallback) {
      this.searchContainer.selectCallback(null);
    } else {
      console.warn('Missing select callback');
    }
  }

}
