export enum AConst {
  $$ACL = '$$acl',
  $$META = '$$meta',
  $$PARENT_NAME = '$$parent_name',
  $$SECTIONS = '$$sections',
  A_DESCRIPTION = 'a_description',
  A_TYPE = 'a_type',
  ACTION_BUTTON = 'action_button',
  ACTION_MENUS = 'action_menus',
  ACTION_NAME = 'action_name',
  ACTION_LIST = 'action_list',
  ACTION_TYPE = 'action_type',
  ACTIONS = 'actions',
  ADD_BUTTON_TITLE = 'add_button_title',
  ADD_NEW_PARAMS = 'add_new_params',
  ADMIN_TITLE = 'admin_title',
  ANNOTATION_POINTS = 'annotation_points',
  ANSWER = 'answer',
  ARRAY = 'array',
  ARRAY_LEVEL = 'array_level',
  ARRAY_NAME = 'array_name',
  ART_TARGET = 'art_target',
  ARTIFACT_ID = 'artifact_id',
  ARTIFACT_IDS = 'artifact_ids',
  ARTIFACT_NAME = 'artifact_name',
  ARTIFACT_PROP_NAME = 'artifact_prop_name',
  ARTIFACT_PROP_REQUIRED = 'artifact_prop_required',
  ARTIFACT_TYPE_ID = 'artifact_type_id',
  ARTIFACTS = 'artifacts',
  ATTACHMENT_ID = 'attachment_id',
  ATTACHMENT_TYPE = 'attachment_type',
  AUTHORITY = 'authority',
  AUTHORITY_MARKED = 'authority_marked',
  BOOLEAN = 'boolean',
  CAN_ADD_NEW = 'can_add_new',
  CANCEL_ACTIONS = 'cancel_actions',
  CATEGORIES = 'categories',
  CATEGORY_TITLE = 'category_title',
  COPY_DEFAULT_VALUE = 'copy_default_value',
  COPY_FIELDS = 'copy_fields',
  COPY_SOURCE_FIELD = 'copy_source_field',
  COPY_SOURCE_TYPE = 'copy_source_type',
  COPY_TARGET_FIELD = 'copy_target_field',
  COPY_TRANSFORM_TYPE = 'copy_transform_type',
  FIXED = 'fixed',
  CHECK_EXPIRED = 'check_expired',
  CHECK_FILTER_GROUPS = 'check_filter_groups',
  CHECKBOX = 'checkbox',
  CHECK_ARRAY = 'check_array',
  CHECKBOX_FIELD = 'checkbox_field',
  CHECKED_FILTERS = 'checked_filters',
  CHECKED_VALUE = 'checked_value',
  CLIENT_LANGUAGE = 'CLIENT_LANGUAGE',
  CLOSE_ACTIONS = 'close_actions',
  COLLECTION_ID = 'collection_id',
  COLOR = 'color',
  COMPARATOR = 'comparator',
  COMPARE = 'compare',
  COMPARE_FIELD = 'compare_field',
  COMPARE_VALUE = 'compare_value',
  COMPARE_VALUE_FIELD = 'compare_value_field',
  CONCEPT_TYPE_ID = 'concept_type_id',
  COND_TYPE = 'cond_type',
  CONDITIONS = 'conditions',
  CONTENT_LIST = 'content_list',
  CONTEXT_ARTIFACT_ID = 'context_artifact_id',
  CONTEXT_ID = 'context_id',
  CONTEXT_OBJECT_TYPE = 'context_object_type',
  CONTEXT_RESTRICT = 'context_restrict',
  CONTEXTS = 'contexts',
  COUNT = 'count',
  CREATED_AT = 'created_at',
  CREATED_BY_ID = 'created_by_id',
  CSS_CLASS_EDIT = 'css_class_edit',
  CSS_CLASS_VIEW = 'css_class_view',
  DATE_TYPE = 'date_type',
  DD_DATE = 'dd_date',
  DD_PRECISION = 'dd_precision',
  DECIMAL = 'decimal',
  DEFAULT_COLLECTION_ID = 'default_collection_id',
  DELETABLE = 'deletable',
  DELETABLE_TYPE = 'deletable_type',
  DISABLE_CHANGE_CHECK = 'disable_change_check',
  DISABLE_CHECK = 'disable_check',
  DISPLAY = 'display',
  EDIT = 'edit',
  EDIT_ONCE = 'edit_once',
  EDITION = 'edition',
  F_NAME = 'f_name',
  F_PATH = 'f_path',
  FACET = 'facet',
  FACET_AS_FILTER = 'facet_as_filter',
  FACET_ITEM = 'facet_item',
  FACET_RANGE_GROUPS = 'facet_range_groups',
  FACET_RANGES = 'facet_ranges',
  FAQ = 'faq',
  FIELD_ID = 'field_id',
  FIELD_NAME = 'field_name',
  FIELD_TITLE = 'field_title',
  FIELD_TYPE = 'field_type',
  FIELDS = 'fields',
  FILTER_FIELD = 'filter_field',
  FILTER_OPERATOR = 'filter_operator',
  FILTER_PROP_NAME = 'filter_prop_name',
  FILTER_PROP_NAME_INFO = 'filter_prop_name_info',
  FILTER_PROP_VALUE = 'filter_prop_value',
  FILTER_PROP_VALUE_SOURCE_PROP = 'filter_prop_value_source_prop',
  FILTER_PROPS = 'filter_props',
  FOLDER = 'folder',
  FOLDER_ARTIFACT_ID = 'folder_artifact_id',
  FOLDER_ID = 'folder_id',
  FOLDER_ITEMS = 'folder_items',
  FOLDER_TYPE_ID = 'folder_type_id',
  FROM_DATE = 'from_date',
  FULL_OBJECT_EDIT = 'full_object_edit',
  GROUP_NAME = 'group_name',
  HISTORIC = 'historic',
  ICON_FRAME = 'icon_frame',
  IDENTIFIER = 'identifier',
  IDENTIFIER_NO_GEN_BUTTON = 'identifier_no_gen_button',
  IF_THEN = 'if_then',
  IF_THEN_TYPE = 'if_then_type',
  IF_TYPE = 'if_type',
  FIELD_IFS = 'field_ifs',
  IMAGE = 'image',
  IMAGE_ID = 'image_id',
  IMAGES = 'images',
  INCLUDE_META = 'include_meta',
  INDEX_NOTIFICATIONS = 'index_notifications',
  INGRESS = 'ingress',
  INLINE = 'inline',
  INLINE_ARRAY = 'inline_array',
  INLINE_CHECK_FIELD = 'inline_check_field',
  INLINE_FIELDS = 'inline_fields',
  INLINE_LIST = 'inline_list',
  INLINE_VALUE_FIELD = 'inline_value_field',
  INPUT_TYPE = 'input_type',
  IS_ARRAY = 'is_array',
  IS_DELETABLE = 'is_deletable',
  IS_HIERARCHIC = 'is_hierarchic',
  IS_TO_DATE_FIELD = 'is_to_date_field',
  IS_LEAF = 'is_leaf',
  KULTURNAV_PARAMS = 'kulturnav_params',
  LABEL = 'label',
  LABEL_PROP = 'label_prop',
  LEAF_MENU = 'leaf_menu',
  LEVEL = 'level',
  LOCKED = 'locked',
  M_PATH = 'm_path',
  MAIN_FIELD = 'main_field',
  MAIN_TYPE = 'main_type',
  MAP_ID = 'map_id',
  MENU_ICON = 'menu_icon',
  MENU_REF_PARAMS = 'menu_ref_params',
  MENU_TITLE = 'menu_title',
  MENU_TOOLTIP = 'menu_tooltip',
  MENU_TYPE = 'menu_type',
  MENUS = 'menus',
  MESSAGE = 'message',
  MESSAGE_CLASS = 'message_class',
  MESSAGES = 'messages',
  META_TYPE = 'meta_type',
  MAX_FILTER_LENGTH = 10,
  MAX_LENGTH = 'max_length',
  MAX_LEVEL = 'max_level',
  MIN_LENGTH = 'min_length',
  MODEL = 'model',
  MODEL_TITLE = 'model_title',
  MY_FOLDER = 'my_folder',
  NAME = 'name',
  NAME_FIELD = 'name_field',
  NO_CLEAR = 'no_clear',
  NO_MEDIA_ADD = 'no_media_add',
  NUMBER = 'number',
  OBJECT_REFERENCE = 'object_reference',
  OBJECT_TYPE = 'object_type',
  OBJECT_TYPES = 'OBJECT_TYPES',
  ONE_STEP = 'one_step',
  OPTION_INFO = 'option_info',
  OPTION_STRING = 'option_string',
  OPTIONS = 'options',
  ORDER = 'order',
  ORDER_NUMBER = 'order_number',
  OVERRIDE_TARGET = 'override_target',
  OVERVIEW_FIELDS = 'overview_fields',
  PARAM_TYPE = 'param_type',
  PARENT_CONCEPT_TYPE_ID = 'parent_concept_type_id',
  PARENT_FIELD = 'parent_field',
  PARENT_ID = 'parent_id',
  PARENT_OBJECT_TYPE = 'parent_object_type',
  PARENT_NAME = 'parent_name',
  PARENT_TARGET_FIELD = 'parent_target_field',
  PARENTS_ONLY = 'parents_only',
  PATH_NAME = 'path_name',
  PRE_SEARCH = 'pre_search',
  PRECISION_DATE = 'precision_date',
  PRECISION_DATE_RANGE = 'precision_date_range',
  PROPERTY_NAME = 'property_name',
  PROP = 'prop',
  QUERY_FIELD = 'query_field',
  QUERY_MENUS = 'query_menus',
  QUERY_TYPE = 'query_type',
  READ_ACCESS = 'read_access',
  REF_ARRAY = 'ref_array',
  REF_DYNAMIC_ARRAY = 'ref_dynamic_array',
  REF_FILTER = 'ref_filter',
  REF_PROP = 'ref_prop',
  REFERENCE = 'reference',
  REG_EXP = 'reg_exp',
  REG_LEVEL = 'reg_level',
  REG_LEVEL_TYPE_ID = 'reg_level_type_id',
  REQUIRED = 'required',
  REQUIRED_FIELD = 'required_field',
  REQUIRES_SELECTIONS = 'requires_selections',
  RESET_QUERY = 'reset_query',
  RESTRICTIONS = 'restrictions',
  SAVE_BUTTON_TITLE = 'save_button_title',
  SET_OTHERS = 'set_others',
  SEARCH_KULTURNAV = 'search_kulturnav',
  SEARCH_RESULT_VIEWS = 'search_result_views',
  SEARCH_VIEW_TYPE = 'search_view_type',
  SELECTOR_BUTTON_TEXT = 'selector_button_text',
  SELECT_BUTTON_TEXT = 'select_button_text',
  SEARCH_COUNT = 'search_count',
  SEARCH_SELECTOR = 'search_selector',
  SEARCH_SELECTOR_MULTIPLE = 'search_selector_multiple',
  SEARCH_VIEW = 'search_view',
  SECTION_MENUS = 'section_menus',
  SECTION_VISIBLE = 'section_visible',
  SELECTOR = 'selector',
  SELECTORS = 'selectors',
  SHOW_COND_FIELD = 'show_cond_field',
  SHOW_COND_VALUE = 'show_cond_value',
  SHOW = 'show',
  SHOW_IF = 'show_if',
  SHOW_IF_HAS_PARENT = 'show_if_has_parent',
  SORT = 'sort',
  SORT_ORDER_MENUS = 'sort_order_menus',
  SOURCE_ARRAY = 'source_array',
  SOURCE_FIELD = 'source_field',
  SOURCE_NAME = 'source_name',
  SOURCE_TYPE = 'source_type',
  SOURCE_VALUE = 'source_value',
  SPECIFIC_ARRAY_ELEMENTS = 'specific_array_elements',
  STATUS = 'status',
  STATUS_CODE = 'status_code',
  STATUS_MSG = 'status_msg',
  STATUS_TYPE_ID = 'status_type_id',
  STORE_PARENT = 'store_parent',
  SUB_FIELD = 'sub_field',
  SUB_FIELDS = 'sub_fields',
  SUB_MENUS = 'sub_menus',
  SUB_ORDER = 'sub_order',
  SUB_TYPE = 'sub_type',
  SUB_TYPE_ID = 'sub_type_id',
  TARGET_ARRAY = 'target_array',
  TARGET_ARRAY_MODEL = 'target_array_model',
  TARGET_FIELD = 'target_field',
  TARGET_MODEL = 'target_model',
  TARGET_PARAMS = 'target_params',
  TARGET_STATE = 'target_state',
  TARGET_TYPE = 'target_type',
  TEMPLATE_GROUP_ID = 'template_group_id',
  TEMPLATE_GROUPS = 'template_groups',
  TEMPLATE_MODELS = 'template_models',
  TEMPLATE_SECTIONS = 'template_sections',
  TEXT_AREA = 'text_area',
  THING = 'thing',
  THUMBNAIL_ID = 'thumbnail_id',
  TIMESPAN = 'timespan',
  TITLE = 'title',
  TO_DATE = 'to_date',
  TO_DATE_FIELD = 'to_date_field',
  TODAY_DATE = 'today_date',
  TOOLTIP = 'tooltip',
  TOTAL_COUNT = 'total_count',
  TRANS__REFERENCE__REF_NOT_AVAILABLE = 'TRANS__REFERENCE__REF_NOT_AVAILABLE',
  UNIQUE_PROP = 'unique_prop',
  UNIQUE_VALUES = 'unique_values',
  UPDATED_AT = 'updated_at',
  UPLOAD_INFO = 'upload_info',
  UPLOAD_INFO_LIST = 'upload_info_list',
  USAGE = 'usage',
  USER = 'user',
  USER_ID = 'user_id',
  USERNAME = 'username',
  VALIDATION = 'validation',
  VERSION = 'VERSION',
  VIDEO_ID = 'video_id',
  VIEW = 'view',
  VIEW_NAME = 'view_name',
  VIEWS = 'views',
  WHAT_IS_NEW = 'what_is_new'
}
