import {Injectable} from '@angular/core';
import {Annotation} from './annotation';
import {AConst} from '../core/a-const.enum';
import {ObjectEditService} from '../core/object-edit.service';
import {CmsApiService} from '../core/cms-api.service';
import {CurrentObjectService} from '../core/current-object.service';

@Injectable({
  providedIn: 'root'
})
export class AnnotationService {

  constructor(private objectEditService: ObjectEditService,
              private cms: CmsApiService,
              private currentObjectService: CurrentObjectService) {
  }

  setCurAnnotation(curAnn: Annotation, annotationId, target, image, contextIds, parentId, findAnnotation, fn?) {
    if (annotationId) {
      this.loadAnnotation(curAnn, target, annotationId).then(res => {
        if (fn) {
          fn(res);
        }
      });
    } else {
      if (findAnnotation) {
        this.findAnnotations(image[AConst.IMAGE_ID], contextIds, parentId, annotations => {
            if (annotations.length > 0) {
              this.loadAnnotation(curAnn, target, annotations[0][AConst.ARTIFACT_ID]).then(res => {
                if (fn) {
                  fn(res);
                }
              });
            } else {
              this.createAnnotation(curAnn, target, image, contextIds, parentId, fn);
            }
          }
        );
      } else {
        this.createAnnotation(curAnn, target, image, contextIds, parentId, fn);
      }
    }
  }

  saveAnnotation(curAnn: Annotation) {
    return new Promise((resolve, reject) => {
      curAnn.ane[AConst.TIMESPAN][AConst.TO_DATE][AConst.DD_DATE] = new Date().getTime();
      this.objectEditService.storeObjectShowProgressModal(curAnn.ane).then(
        () => {
          this.currentObjectService.currentObject = null;
          resolve();
        },
        reason => {
          console.warn('Saving annotations failed with ' + 'message: ' + reason.error.message);
          reject();
        }
      );
    });
  }

  // store.loadArtifact puts annotation event in
  // scope.AnnotationEventTarget
  private loadAnnotation(curAnn: Annotation, target, annotationId) {
    return new Promise(((resolve, reject) => {
      this.objectEditService.loadObject(annotationId).then(
        ane => {
          this.currentObjectService.currentObject = ane;
          this.addSetCurAnn(curAnn, ane, target, {image_id: ane[AConst.IMAGE_ID]});
          resolve(ane);
        },
        reason => {
          console.warn('Error loading  annotation: ' + reason.error.message);
          reject();
        }
      );
    }));
  }

  private findAnnotations(imageId, contextIds, parentId, fn) {
    this.cms.searchJson({
      filters: {
        object_type: 'AnnotationEvent',
        context_artifact_id: contextIds,
        parent_id: parentId,
        image_id: imageId
      },
      sort: 'updated_at desc'
    }).then(
      data => {
        fn(data[AConst.ARTIFACTS]);
      },
      () => {
        console.error('Failed to find annotations');
        fn([]);
      }
    );
  }

  private newPrecisionDate() {
    const pd = {};
    pd[AConst.DD_DATE] = new Date().getTime();
    pd[AConst.DD_PRECISION] = 'full';
    return pd;
  }

  private newAnnotationEventData(image, contextIds, parentId, fn) {
    this.createContextItems(
      contextIds, parentId, (contexts) => {
        const md = {};
        md[AConst.CONTEXTS] = contexts;
        md[AConst.IMAGE_ID] = image[AConst.IMAGE_ID];
        md[AConst.PARENT_ID] = parentId;
        md[AConst.TIMESPAN] = {};

        md[AConst.TIMESPAN][AConst.FROM_DATE] = this.newPrecisionDate();
        md[AConst.TIMESPAN][AConst.TO_DATE] = this.newPrecisionDate();
        fn(md);
      });
  }

  private createContextItems(contextIds, parentId, fn) {
    const contexts = [];
    if (contextIds && contextIds.length) {
      let count = contextIds.length;
      contextIds.forEach(contextId => {
        this.objectEditService.createModelItem('AdmEventContextItem',
          {
            context_artifact_id: contextId,
            context_id: parentId
          }).then(item => {
            contexts.push(item);
            count--;
            if (!count) {
              fn(contexts);
            }
          }
        );
      });
    } else {
      fn(contexts);
    }
  }

  private addSetCurAnn(curAnn: Annotation, ane, target, image) {
    if (!target.annotation_events) {
      target.annotation_events = [];
      console.warn('Object type ' + target[AConst.OBJECT_TYPE] + ' is missing annotation_events array!!');
    }
    target.annotation_events.push(ane);
    this.setCurAnn(curAnn, ane, target, image);
  }

  private setCurAnn(curAnn: Annotation, ane, target, image) {
    const aPoints = curAnn.getAnnotationPoints({ane: ane});
    curAnn.annotateImage = image;
    curAnn.target = target;
    if (aPoints && aPoints.length > 0) {
      curAnn.selectedAnnPoint = aPoints[0];
    } else {
      curAnn.selectedAnnPoint = null;
    }
    curAnn.ane = ane;

    curAnn.aneSet = true;
    if (curAnn.canvasCallback) {
      curAnn.canvasCallback();
    }
  }


  private createAnnotation(curAnn: Annotation, target, image, contextId, parentId, fn) {
    this.newAnnotationEventData(image, contextId, parentId,
      md => {
        this.objectEditService.createModelItem('AnnotationEvent', md).then(ane => {
            this.addSetCurAnn(curAnn, ane, target, image);
            if (fn) {
              fn(ane);
            }
          }
        );

      }
    );
  }

}
