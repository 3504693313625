import {Component, Input, OnInit} from '@angular/core';
import {SearchContainer} from '../../search-container';
import {SearchHandlerService} from '../../search-handler.service';
import {AConst} from '../../../core/a-const.enum';

@Component({
  selector: 'app-search-menu-select-all',
  templateUrl: './search-menu-select-all.component.html',
  styleUrls: ['./search-menu-select-all.component.scss']
})
export class SearchMenuSelectAllComponent implements OnInit {

  @Input() searchContainer: SearchContainer;

  AConst = AConst;

  constructor(private searchHandler: SearchHandlerService) {
  }

  ngOnInit() {
  }

  selectAll() {
    this.searchContainer.selected = [];
    this.toggleSelectedItems();
    this.searchHandler.setState(this.searchContainer);
  }

  private toggleSelectedItems() {
    const sc = this.searchContainer, allSel = sc.allSelected;
    sc.searchResult.artifacts.forEach(item => {
      let selected = false;
      if (sc.selected) {
        selected = sc.selected.indexOf(item[AConst.ARTIFACT_ID]) !== -1;
      }
      item.selected = selected ? !allSel : allSel;
    });
  }

}
