import {
  AfterViewInit,
  Component,
  ElementRef,
  Input, OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import {MediaHelperService} from '../../core/media-helper.service';
import {AConst} from '../../core/a-const.enum';
import {AnnotationRendererService} from '../../image-annotation/annotation-renderer.service';
import {AnnotationContainer} from '../../image-annotation/annotation-container';

@Component({
  selector: 'app-active-annotate-image',
  templateUrl: './active-annotate-image.component.html',
  styleUrls: ['./active-annotate-image.component.scss']
})
export class ActiveAnnotateImageComponent implements AfterViewInit, OnDestroy, OnChanges {
  canvas;
  annotationContainer: AnnotationContainer;
  imageSize = {width: 0, height: 0};
  naturalImageSize = {width: 0, height: 0};
  imageLoaded = 0;
  mouseCoord = {x: 0, y: 0};
  newQuality = 70;
  parentContainerId = 'activeImage';
  heightIsSmallerThanContainer = false;
  @Input() curAnn;
  @Input() imgIndex;
  @Input() zoomValue;
  @Input() dashboardId;
  @Input() lockHeight;
  @Input() container;
  @Input() edit;

  constructor(private annotationRenderer: AnnotationRendererService,
              private elementRef: ElementRef,
              private mediaHelper: MediaHelperService) {
  }

  ngAfterViewInit() {
    this.canvas = this.getCanvasItem();
    this.imageLoaded = 1;
    this.resizeActiveAnnotationImage(this.zoomValue);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('imgIndex') && changes.imgIndex.previousValue !== changes.imgIndex.currentValue) {
      this.imgIndex = changes.imgIndex.currentValue;
      this.setActiveAnnotationImageSize().then(() => {
        this.resizeActiveAnnotationImage(this.zoomValue);
      });
    } else {
      if (changes.hasOwnProperty('zoomValue') &&
        changes.zoomValue.previousValue !== changes.zoomValue.currentValue &&
        !changes.zoomValue.firstChange) {
        this.resizeActiveAnnotationImage(changes.zoomValue.currentValue);
      }
    }
  }

  getCanvasItem() {
    return this.imgIndex === undefined ?  this.elementRef.nativeElement.querySelector('#canvasItem-') :
      this.elementRef.nativeElement.querySelector('#canvasItem-' + this.imgIndex);
  }

  setActiveAnnotationImageSize() {
    return new Promise(resolve => {
      this.ngOnDestroy();
        setTimeout(() => {
          this.canvas = this.getCanvasItem();
        }, 100);

        setTimeout(() => {
          this.imageSize.width = 0;
          this.imageSize.height = 0;
            this.imageLoaded = 1;
            resolve();
        }, 200);

    });
  }

  resizeActiveAnnotationImage(value) {
    this.zoomValue = value;
    this.getImageNaturalSize().then(() => {
      if (this.imageSize.width === 0 && this.imageSize.height === 0) {
        this.imageSize.width = this.naturalImageSize.width;
        this.imageSize.height = this.naturalImageSize.height;
      }
      if (Number(value) === 1 && (this.container.offsetHeight > this.naturalImageSize.height)) {
        this.heightIsSmallerThanContainer = true;
        this.imageSize.height = this.naturalImageSize.height;
      } else {
        this.heightIsSmallerThanContainer = false;
      }
      this.mediaHelper.resizeImage(
        this.container,
        this.zoomValue,
        this.canvas,
        this.naturalImageSize,
        this.imageSize, true);

      this.initAnnotateService();

      if (this.canvas.clientWidth > this.container.offsetWidth ||
        this.canvas.clientHeight > this.container.offsetHeight) {
        this.mediaHelper.imgDrag(
          this.container,
          this.imageLoaded,
          this.canvas,
          this.imageSize,
          this.zoomValue);
      } else {
        this.stopCallBack.bind(this);
      }
    });
  }

  getImageNaturalSize() {
    return new Promise(resolve => {
      const imageObj = new Image();
      const imgArtifact = {};
      imgArtifact[AConst.IMAGE_ID] = this.curAnn.annotateImage[AConst.IMAGE_ID];
      imgArtifact[AConst.OBJECT_TYPE] = 'ImageItem';
      imageObj.src = this.mediaHelper.getImageUrl(imgArtifact,
        'medium', this.newQuality);
      setTimeout(() => {
        this.naturalImageSize.width = imageObj.width;
        this.naturalImageSize.height = imageObj.height;
        resolve();
       }, 100);
    });
  }

  draw() {
    this.annotationRenderer.drawAnnotations(this.annotationContainer, {
      mouseCoord: this.mouseCoord
    });
  }

  initAnnotateService() {
    const ac = new AnnotationContainer();
    this.annotationContainer = ac;
    ac.curAnn =  this.curAnn;
    ac.canvas = this.canvas;
    ac.parentContainerId = this.parentContainerId;
    ac.dashboardId = this.dashboardId;
    ac.lockHeight = this.lockHeight;
    ac.heightIsSmallerThanContainer = this.heightIsSmallerThanContainer;
    ac.editable = true;
    ac.imageSize = 'medium';
    ac.quality = this.newQuality;
    ac.imageId = this.curAnn.annotateImage[AConst.IMAGE_ID];

    this.draw();
  }

  stopCallBack(): void {
    this.mediaHelper.stopCallBack();
  }

  ngOnDestroy() {
    this.stopCallBack.bind(this);
  }
}
