import {Component, EventEmitter, HostListener, Input, OnChanges, Output} from '@angular/core';

@Component({
  selector: 'app-thumbnail-carousel',
  templateUrl: './thumbnail-carousel.component.html',
  styleUrls: ['./thumbnail-carousel.component.scss']
})
export class ThumbnailCarouselComponent implements OnChanges {
  thumbImages = [];
  thumbWidth;
  resizeTimeOut;
  @Input() images;
  @Input() imgIndex;
  @Input() containerElement;
  @Input() fixedNumberOfThumbs;
  @Input() thumbnailWidth;
  @Output() setActiveImg = new EventEmitter<object>();

  constructor() {
  }

  ngOnChanges() {
    this.setThumbImages();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    // wait for resize to be finish before doing stuff
    if (!this.fixedNumberOfThumbs) {
      if (this.resizeTimeOut) {
        clearTimeout(this.resizeTimeOut);
      }
      this.resizeTimeOut = setTimeout((() => {
        // Set correct number of thumbnails at window resize
        this.setThumbImages();
      }), 500);
    }
  }

  public getContainerWidth(containerElement) {
    let containerWidth, thumbnailContainer;
    thumbnailContainer = document.getElementById(containerElement);
    if (thumbnailContainer) {
      containerWidth = thumbnailContainer.clientWidth;
    } else {
      throw new Error('No element with id \'' + containerElement +
      '\' found!');
    }
    return containerWidth;
  }

  public setThumbImages() {
    let containerWidth;
    let imgLen, startNdx, t, i, showLen, img;
    const res = [];
    imgLen = this.images.length;
    startNdx = this.imgIndex;

    if (this.containerElement) {
      containerWidth = this.getContainerWidth(this.containerElement);

      if (this.fixedNumberOfThumbs) {
        showLen = this.fixedNumberOfThumbs;
      } else {
        // Only display as many thumbnails as there is
        // space for within the container element
        showLen = Math.floor(containerWidth / this.thumbnailWidth);
        showLen = Math.min(imgLen, showLen);

      }
      this.thumbWidth = 100 / showLen;
    }
    showLen = showLen !== undefined ? showLen : imgLen;

    // "Rotate" images
    for (t = 0; t < showLen; t++) {
      i = (startNdx + t) % imgLen;
      if (t < this.images.length) {
        img = this.images[i];
        img.ndx = i;
        res.push(img);
      }
    }

    this.thumbImages = res;

  }

  public setImage(index) {
    this.imgIndex = index;
    this.setActiveImg.emit(this.imgIndex);
    this.setThumbImages();
  }
}
