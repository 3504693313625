import {
  Component,
  ElementRef, EventEmitter,
  Input, OnChanges, Output,
  ViewChild
} from '@angular/core';
import {SectionsContainer} from '../../../core/sections-container';
import {AConst} from '../../../core/a-const.enum';
import {InlineArrayItemService} from '../../../core/inline-array-item.service';
import {FieldParameters} from '../../../core/field-parameters';

@Component({
  selector: 'app-edit-field-inline-array-item',
  templateUrl: './edit-field-inline-array-item.component.html',
  styleUrls: ['./edit-field-inline-array-item.component.scss']
})
export class EditFieldInlineArrayItemComponent implements OnChanges {

  @Input() sectionsContainer: SectionsContainer;
  @Input() object;
  @Input() field;
  @Input() item;
  @Input() arrayIndex;
  @Input() parentIndex;
  @ViewChild('fieldInlineArrayItem', { static: false }) fieldInlineArrayItem: ElementRef;
  @Output() fieldFocus = new EventEmitter<object>();

  AConst = AConst;
  fields: Array<any>;
  arrayItemFieldId = '';
  private fieldParameters: FieldParameters;

  constructor(private inlineArrayItemSvc: InlineArrayItemService) {
  }

  ngOnChanges() {
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters.object = this.object;
    this.fieldParameters.field = this.field;
    this.fieldParameters.index = this.arrayIndex;
    this.fieldParameters.parentIndex = this.parentIndex;
    this.fields = this.field[AConst.INLINE_FIELDS];
    this.arrayItemFieldId = this.inlineArrayItemSvc.getInlineArrayItemId(this.fieldParameters);
    this.onSetFirstInputFocus();
  }

  onSetFirstInputFocus() {
    let displayFields, array, firstEditableItem, index, container, focusElement, inputType;
    if (this.arrayItemIsOpen()) {
      displayFields = this.fields.filter(item =>
        item.display[AConst.SHOW] === 'yes');
      array = displayFields.filter(item =>
        (item.edit === 'edit' || (item.edit === 'edit_once' && this.item.$$justAdded)));
      setTimeout(() => {
        firstEditableItem = array.find((item) => {
          return item;
        });
        if (firstEditableItem) {
          index = displayFields.indexOf(firstEditableItem);
          container = this.fieldInlineArrayItem.nativeElement.querySelector('ul');
          focusElement = container.children[index];
          inputType = firstEditableItem.input_type;
          if (inputType === 'input' || inputType === 'map_id') {
            focusElement.querySelector('input').focus();
            this.onFieldFocus();
          } else if (inputType === 'text_area') {
            focusElement.querySelector('textArea').focus();
            this.onFieldFocus();
          } else if (inputType === 'select') {
            focusElement.querySelector('select').focus();
            this.onFieldFocus();
          } else if (inputType === 'action_button') {
            focusElement.querySelector('a').focus();
            this.onFieldFocus();
          }
        }
      }, 100);
    }
  }
  onFieldFocus() {
    if (!this.sectionsContainer.isDialog) {
      this.fieldFocus.emit();
    }
  }

  arrayItemIsOpen() {
    return this.inlineArrayItemSvc.isInlineArrayItemOpen(this.fieldParameters);
  }

}
