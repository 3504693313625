import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchContainer} from '../../search-container';
import {AConst} from '../../../core/a-const.enum';
import {FolderService} from '../../../core/folder.service';
import {FolderEditDialogComponent} from '../../../object-edit/folder-edit-dialog/folder-edit-dialog.component';
import {UiToolsService} from '../../../core/ui-tools.service';
import {group, query, stagger, style, transition, trigger, useAnimation} from '@angular/animations';
import {fadeIn, fadeOut, slideDownMenu, slideUpMenu} from '../../../shared/animations';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-search-menu-folder-settings',
  templateUrl: './search-menu-folder-settings.component.html',
  styleUrls: ['./search-menu-folder-settings.component.scss'],
  animations: [
    trigger('slideInFolderSettingsMenu', [
      transition(':leave', [
        group([
          useAnimation(slideUpMenu, {params: {time: '300ms ease-in-out'}}),
          query('li', [
            style({opacity: 1}),
            stagger(-150, useAnimation(fadeOut, {params: {time: '75ms'}}))
          ]),
        ])
      ]),
      transition(':enter', [
        group([
          useAnimation(slideDownMenu, {params: {time: '300ms ease'}}),
          query('li', [
            style({opacity: 0}),
            stagger(150, useAnimation(fadeIn, {params: {time: '75ms'}}))
          ]),
        ])
      ]),
    ])
  ]
})
export class SearchMenuFolderSettingsComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;

  toggleMenu = false;
  private canEditFolders = {};
  private clickListenerId;


  constructor(private folderService: FolderService,
              private modalService: MatDialog,
              private uiTools: UiToolsService) {
  }

  ngOnInit() {
    this.clickListenerId = this.uiTools.addDocumentClickListener(() => {
      this.toggleMenu = false;
    });
  }

  ngOnDestroy(): void {
    this.uiTools.removeDocumentClickListener(this.clickListenerId);
  }

  toggleFolderSettingMenu() {
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    this.toggleMenu = !this.toggleMenu;
  }

  canEditFolder(folder) {
    const res = this.canEditFolders[folder[AConst.ARTIFACT_ID]];
    if (res === undefined) {
      this.folderService.canUserEditFolder(folder).then(canEdit => {
        this.canEditFolders[folder[AConst.ARTIFACT_ID]] = canEdit;
      });
    }
    return res;
  }

  copyFolder(folder) {
    this.folderService.copyFolder(folder).then(folderCopy => {
      this.folderService.goToFolder(folderCopy[AConst.ARTIFACT_ID]);
    });
  }

  editFolder(folder) {
    const modalRef = this.modalService.open(FolderEditDialogComponent, {panelClass: 'edit-dialog'});
    modalRef.componentInstance.folderId = folder[AConst.ARTIFACT_ID];
    modalRef.afterClosed().subscribe(folderEdited => {
      if (folderEdited) {
        this.searchContainer.targetObject = folderEdited;
        this.folderService.goToFolder(folderEdited[AConst.ARTIFACT_ID]);
      }
    });
  }

}
