import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {FieldValueService} from '../../core/field-value.service';
import {AConst} from '../../core/a-const.enum';
import {ActionMenuService} from '../../core/action-menu.service';
import {SearchSelectorService} from '../../core/search-selector.service';
import {SectionsContainer} from '../../core/sections-container';
import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-edit-field-search-selector',
  templateUrl: './edit-field-search-selector.component.html',
  styleUrls: ['./edit-field-search-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldSearchSelectorComponent)
    }
  ]
})
export class EditFieldSearchSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() sectionsContainer: SectionsContainer;
  @Input() grandParentObject;
  @Input() object;
  @Input() field;
  @Input() formControlName;
  @Input() parentIndex;

  mapText = '';
  AConst = AConst;


  constructor(private searchSelectorService: SearchSelectorService,
              private fieldValueSvc: FieldValueService,
              private actionMenuService: ActionMenuService) { }

  ngOnInit() {
    this.setTextValue();

  }

  clickEnableSelector() {
    let selector, container, params, selected;
    selector = this.field.reference[AConst.SELECTOR];
    container = this.actionMenuService.actionMenuContainer;
    selected = this.getSelectedValues();
    params = {
      selected: selected,
      used: this.getUsedValues(selected, selector.item_removed_property)
    };
    // TODO: Add template group id to enableSelector call
    this.searchSelectorService.enableSelector(selector, container, params, {
      searchContainerCreated: (/*sc*/) => {
        // console.debug('Created: ' + sc);
      },
      selectorCallback: (selectedObj) => {
        this.searchSelectorService.disableSelector(container);
        const artifactId = selectedObj[0][AConst.ARTIFACT_ID];
        const artifactName = selectedObj[0][AConst.ARTIFACT_NAME];
        this.fieldValueSvc.setFieldValue(this.object, this.field.name, artifactId);
        this.fieldValueSvc.setFieldValue(this.object, this.field.name, artifactName, true);
        this.setTextValue();
        const control: AbstractControl = this.sectionsContainer.formGroup.controls[this.formControlName];
        if (control) {
          control.setValue(artifactName);
          control.markAsDirty();
        } else {
          console.error('Control not found: ' + this.formControlName);
        }
      }
    });
  }


  private setTextValue() {
    this.fieldValueSvc.getMappedFieldValue(this.field, this.object).then(
      (mapText) => {
        this.mapText = mapText;
      });
  }

  private getUsedValues(selected, itemRemovedProperty) {
    const parentArray = this.sectionsContainer.rootObject[this.field[AConst.PARENT_NAME]];
    const res = [];
    if (parentArray) {
      parentArray.forEach((item) => {
        const value = item[this.field.name];
        if (selected.indexOf(value) === -1 && (!itemRemovedProperty || !item[itemRemovedProperty])) {
          res.push(value);
        }
      });
    }
    return res;
  }

  private getSelectedValues() {
    const res = [];
    const value = this.fieldValueSvc.getFieldValue(this.object, this.field.name);
    if (value) {
      res.push(value);
    }
    return res;
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
  }


}
