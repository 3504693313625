import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchHandlerService} from '../../search-handler.service';
import {Focus, SearchFocusService} from '../../search-focus.service';

@Component({
  selector: 'app-search-filter-menu-path',
  templateUrl: './search-filter-menu-path.component.html',
  styleUrls: ['./search-filter-menu-path.component.scss']
})
export class SearchFilterMenuPathComponent implements OnInit, OnDestroy {

  @Input() searchContainer;
  @Input() sideMenu;

  searchMenuPath = [];
  private fsi: Focus;

  constructor(private searchHandler: SearchHandlerService,
              private searchFocusService: SearchFocusService) {
  }

  ngOnInit() {
    this.searchHandler.subscribeToSearchResult(this.searchContainer, this.setSearchMenuPath);
  }

  ngOnDestroy(): void {
    this.searchHandler.unSubscribeToSearchResult(this.searchContainer, this.setSearchMenuPath);
  }

  goToMenuPath(menuPath) {
    if (this.searchContainer.curFocus) {
      this.fsi = this.searchFocusService.createFocus(this.searchContainer);
      const exists = this.fsi.checkPathParamsExists(menuPath.path, this.searchContainer);
      if (!exists) {
        this.searchContainer.curFocus = null;
        this.searchContainer.curFocusId = null;
        this.searchContainer.currentFocusName = null;
      }
    }
    this.searchContainer.objectCount = 0;
    this.searchContainer.targetId = menuPath.targetId;
    this.searchContainer.path = menuPath.path;
    this.searchHandler.goPathView(menuPath.path, this.searchContainer);
  }


  private setSearchMenuPath = () => {
    const path = this.searchHandler.getSearchMenuPath(this.searchContainer);
    if (path) {
      this.searchMenuPath = path;
    }
  }

}
