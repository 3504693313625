import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchContainer} from '../../search-container';

@Component({
  selector: 'app-side-menu-toggler',
  templateUrl: './side-menu-toggler.component.html',
  styleUrls: ['./side-menu-toggler.component.scss']
})
export class SideMenuTogglerComponent implements OnInit {

  @Input() searchContainer: SearchContainer;
  @Input() sideMenuSmallScreen;
  @Output() onToggleFilterSideMenuSmallScreen = new EventEmitter<object>();

  constructor() {
  }

  ngOnInit() {
  }
  toggleSideFilterMenuSmallScreen() {
    this.onToggleFilterSideMenuSmallScreen.emit();
  }
}
