import {Component, OnDestroy, OnInit} from '@angular/core';
import {StateService, UIRouterGlobals} from '@uirouter/core';
import {AConst} from '../../core/a-const.enum';
import {ObjectEditService} from '../../core/object-edit.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {ContentInfo, ContentInfoService} from '../../core/content-info.service';
import {MediaHelperService} from '../../core/media-helper.service';
import {ReportMenuService} from '../../report/report-menu/report-menu.service';
import {ContentMenusService} from '../../object-view/content-menus.service';
import {SearchSelectorService} from '../../core/search-selector.service';
import {UiToolsService} from '../../core/ui-tools.service';
import {ActionMenuService} from '../../core/action-menu.service';
import {CommonsService} from '../../core/commons.service';
import {ActionMenuContainer} from '../../core/action-menu-container';
import {TemplateSelectorContainer} from '../../core/template-selector-container';
import {SelectorContainer} from '../../core/selector-container';
import {SectionMenuContainer} from '../../object-view/section-menu/section-menu.component';
import {SectionsContainer} from '../../core/sections-container';

@Component({
  selector: 'app-object-page',
  templateUrl: './object-page.component.html',
  styleUrls: ['./object-page.component.scss']
})
export class ObjectPageComponent implements OnInit, OnDestroy {

  art = {$$allMedia: []};
  artifactId;
  sectionsContainer: SectionsContainer;
  sectionMenuContainer = new SectionMenuContainer();
  contentInfo = new ContentInfo();
  actionMenuContainer = new ActionMenuContainer();
  templateSelectorContainer = new TemplateSelectorContainer();
  smallObjectMenus: boolean;
  mediumScreen: boolean;
  smallScreen: boolean;
  scrollContent: boolean;
  AConst = AConst;
  state;
  contextIds;
  isNew;
  reload = false;
  menus = [];
  artifactActions;
  status = 'closed';
  // Used to display annotation on image gallery
  annotation = {};
  loadFailed = false;


  private templateGroupId;
  private stopSizeWatch;

  constructor(private stateService: StateService,
              private uiRouterGlobals: UIRouterGlobals,
              private objectEditService: ObjectEditService,
              public currentObjectService: CurrentObjectService,
              private contentInfoService: ContentInfoService,
              private mediaHelper: MediaHelperService,
              private reportMenuService: ReportMenuService,
              private commons: CommonsService,
              private contentMenusService: ContentMenusService,
              private searchSelectorService: SearchSelectorService,
              private uiTools: UiToolsService,
              private actionMenuService: ActionMenuService) {
  }

  ngOnInit() {
    this.currentObjectService.isEditing = this.uiRouterGlobals.params.edit;
    this.templateGroupId = this.uiRouterGlobals.params.template_group_id;
    this.stopSizeWatch = this.uiTools.addWindowSizeListener(newVal => {
        this.smallObjectMenus = newVal.width < 1025;
        this.mediumScreen = newVal.width < 1025;
        this.smallScreen = newVal.width < 642;
      }
    );


    this.state = this.stateService.current;

    this.artifactId = this.uiRouterGlobals.params.artifactId;
    this.contextIds = this.uiRouterGlobals.params.contextIds;
    this.isNew = this.uiRouterGlobals.params.isNew;

    this.actionMenuContainer = this.actionMenuService.newActionMenuContainer();
    this.actionMenuContainer.contextIds = this.uiRouterGlobals.params.contextIds;
    this.actionMenuContainer.parentId = this.uiRouterGlobals.params.parentId;
    this.actionMenuContainer.artifactId = this.artifactId;
    this.actionMenuContainer.checkboxChange = false;
    this.actionMenuContainer.curAnn = this.currentObjectService.curAnn;
    this.actionMenuContainer.currentMenu = null;
    this.actionMenuContainer.showMenu = false;
    this.actionMenuContainer.showMenuReport = false;
    this.actionMenuContainer.makeReportFn = () => {
      this.makeReport();
    };
    this.actionMenuContainer.enableSelector = (selector, params) => {
      this.enableSelector(selector, params);
    };
    this.actionMenuContainer.disableSelector = () => {
      this.disableSelector();
    };
    this.actionMenuContainer.artifactUpdateCallback = () => {
      this.artifactUpdated();
    };

    this.state = this.stateService.current;
    this.menus = [];

    this.artifactActions = [
      {
        containerClass: 'register-button-container',
        buttonClass: 'register-button',
        type: 'register',
        fn: () => {
          this.updateArtifact();
        },
        buttonText: 'TRANS__EDIT__STORE_ARTIFACT',
        helperBoxText: 'TRANS__EDIT__STORE_ARTIFACT__HELP_TEXT'
      },
      {
        buttonClass: 'cancel-button',
        fn: () => {
          this.cancelArtifact();
        },
        buttonText: 'TRANS__EDIT__CANCEL'
      }
    ];

    if (this.artifactId) {
      this.loadArtifact();
    }

    const windowSize = this.uiTools.windowSize;
    this.mediumScreen = windowSize.width < 1025;
    this.smallObjectMenus = windowSize.width < 1025;
    this.smallScreen = windowSize.width < 642;
    window.addEventListener('scroll', () => {
      this.checkScroll();
    });
  }

  ngOnDestroy(): void {
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
    window.removeEventListener('scroll', () => {
      this.checkScroll();
    });
  }

  artifactUpdated() {
    this.loadArtifact();
  }

  selectorOverlay() {
    if (this.templateSelectorContainer && this.templateSelectorContainer.selectorEnabled) {
      this.searchSelectorService.disableSelector(this.templateSelectorContainer);
    } else {
      this.disableSelector();
    }
  }

  onTemplateSelectorCreated(templateSelectorContainer) {
    this.templateSelectorContainer = templateSelectorContainer;
  }

  onChangeTemplateGroup(newGroupId) {
    this.stateService.go(this.stateService.current,
      {template_group_id: newGroupId},
      {location: 'replace', reload: true});
  }

  private loadArtifact() {
    this.art = null;
    this.objectEditService.loadObjectGetSectionsContainer(this.artifactId, this.templateGroupId).then(
      sectionsContainer => {
        this.sectionsContainer = sectionsContainer;
        this.postLoadOperations(sectionsContainer.rootObject);
      },
      reason => {
        this.loadFailed = true;
        console.error('Failed loading object: ' + reason.error.message);
        this.art = {$$allMedia: []};
        this.art[AConst.ARTIFACT_ID] = this.artifactId;
      });
  }

  private postLoadOperations(data) {
    this.sectionMenuContainer.artifact = data;
    this.sectionMenuContainer.sections = this.sectionsContainer.sections;
    this.sectionMenuContainer.contentInfo = this.contentInfo;
    let listName = this.uiRouterGlobals.params.listName;
    if (this.contentInfo.curListName) {
      listName = this.contentInfo.curListName;
    }
    this.art = data;
    this.currentObjectService.currentObject = data;
    this.actionMenuContainer.checkboxChange = false;

    this.contentInfoService.updateContentInfo(this.contentInfo, this.art, listName);
    this.contentInfo.templateGroupId = this.templateGroupId;

    this.setActionMenuProps();
    this.setActionMenuProps();
    this.getMedia();
  }

  private storeArtifact() {
    this.objectEditService.setObjectValuesStoreObject(this.sectionsContainer).then(value => {
      this.art[AConst.ARTIFACT_ID] = value[AConst.ARTIFACT_ID];
      this.currentObjectService.isEditing = false;
      this.stateService.go(
        this.stateService.current.name,
        {edit: undefined, isNew: undefined},
        {location: 'replace ', reload: true});
    });
  }

  private setActionMenuProps() {
    this.actionMenuContainer.art = this.art;
    this.actionMenuContainer.contentInfo = this.contentInfo;
  }

  private getMedia() {
    this.mediaHelper.getAllImages(this.art, images => {
      this.art.$$allMedia = images;
    });
  }

  private makeReport() {
    this.reportMenuService.downloadWithSpinner(
      this.reportMenuService.getReportParams(), this.artifactId);
  }

  private enableSelector(selector, params?) {
    let restrictVal;
    const mc = this.actionMenuContainer;
    const menu = mc.currentMenu;
    const contentMenu = this.contentMenusService.getMenuFromListName(menu[AConst.CONTENT_LIST], this.contentInfo);

    if (selector[AConst.CONTEXT_RESTRICT] &&
      contentMenu && contentMenu.count) {
      const contexts = this.commons.getContextIds(this.art[AConst.CONTEXTS]);
      if (contexts && contexts.length > 0) {
        restrictVal = contexts;
      } else {
        restrictVal = [this.art[AConst.ARTIFACT_ID]];
      }
      selector.restrictions = [
        {
          fieldId: 'contexts.context_artifact_id',
          value: restrictVal,
          on: true,
          title: 'TRANS__SELECTOR__CONTEXT_RESTRICTION'
        }

      ];
    }

    this.searchSelectorService.enableSelector(selector, <SelectorContainer>mc, params,
      {
        searchContainerCreated: () => {
        },
        selectorCallback: selectedObj => {
          this.searchSelectorService.disableSelector(<SelectorContainer>mc);
          mc.selectorCallback(selectedObj, selector);
        }
      }, this.templateGroupId
    );
  }

  private disableSelector() {
    this.searchSelectorService.disableSelector(<SelectorContainer>this.actionMenuContainer);
  }

  private cancelArtifact() {
    if (this.art && this.art[AConst.ARTIFACT_ID]) {
      this.stateService.go(this.stateService.current,
        {edit: undefined},
        {location: 'replace', reload: true});
    } else {
      window.history.back();
    }
  }

  private updateArtifact() {
    this.storeArtifact();
    setTimeout(() => {
      // window.scrollTo('#artifactCtrl', {
      //   duration: '750',
      //   offsetTop: '50'
      // });
    }, 1000);
  }

  private checkScroll() {
    if (!this.mediumScreen) {
      if (!this.contentInfo || this.contentInfo.curListName === 'overview') {
        this.scrollContent = window.pageYOffset > 30 && window.pageYOffset < 240;
      } else {
        this.scrollContent = window.pageYOffset > 30;
      }
    }
  }

}
