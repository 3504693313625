import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchContainer} from '../../search-container';
import {SearchHandlerService} from '../../search-handler.service';
import {AConst} from '../../../core/a-const.enum';
import {UiToolsService} from '../../../core/ui-tools.service';
import {group, query, stagger, style, transition, trigger, useAnimation} from '@angular/animations';
import {fadeIn, fadeOut, slideDownMenu, slideUpMenu} from '../../../shared/animations';

@Component({
  selector: 'app-search-menu-sort',
  templateUrl: './search-menu-sort.component.html',
  styleUrls: ['./search-menu-sort.component.scss'],
  animations: [
    trigger('slideInSortMenu', [
      transition(':leave', [
        group([
          useAnimation(slideUpMenu, {params: {time: '300ms ease-in-out'}}),
          query('li', [
            style({opacity: 1}),
            stagger(-50, useAnimation(fadeOut, {params: {time: '25ms'}}))
          ]),
        ])
      ]),
      transition(':enter', [
        group([
          useAnimation(slideDownMenu, {params: {time: '300ms ease'}}),
          query('li', [
            style({opacity: 0}),
            stagger(50, useAnimation(fadeIn, {params: {time: '25ms'}}))
          ]),
        ])
      ]),
    ])
  ]
})
export class SearchMenuSortComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;

  AConst = AConst;
  searchViewClass = 'search';

  private clickListenerId;

  constructor(private searchHandler: SearchHandlerService,
              private uiTools: UiToolsService) {
  }

  ngOnInit() {
    if (this.searchContainer.searchResultViewName === 'content-list' ||
      this.searchContainer.searchResultViewName === 'selector') {
      this.searchViewClass = 'object';
    }
    this.clickListenerId = this.uiTools.addDocumentClickListener(() => {
      this.closeMenu();
    });
  }

  ngOnDestroy(): void {
    if (this.clickListenerId) {
      this.uiTools.removeDocumentClickListener(this.clickListenerId);
    }
  }

  toggleMenu() { // SortButton
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    this.searchContainer.toggleSortMenu = !this.searchContainer.toggleSortMenu;
    this.searchContainer.toggleView = false;
    this.searchContainer.toggleActionMenu = false;
  }

  changeSortOrder(s) {
    this.toggleMenu();
    this.searchHandler.setOrder(this.searchContainer, s[AConst.ORDER]);
  }

  private closeMenu() {
    if (this.searchContainer) {
      this.searchContainer.toggleSortMenu = false;
    }
  }

}
