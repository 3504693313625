import {Component, Input, OnInit} from '@angular/core';
import {Annotation} from '../../annotation';

@Component({
  selector: 'app-annotation-shape-menu',
  templateUrl: './annotation-shape-menu.component.html',
  styleUrls: ['./annotation-shape-menu.component.scss']
})
export class AnnotationShapeMenuComponent implements OnInit {

  @Input() curAnn: Annotation;

  constructor() { }

  ngOnInit() {
  }

}
