import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UiToolsService} from '../../core/ui-tools.service';
import {AConst} from '../../core/a-const.enum';
import {OverviewField} from '../../core/object-view';

@Component({
  selector: 'app-search-columns-overview-fields',
  templateUrl: './search-columns-overview-fields.component.html',
  styleUrls: ['./search-columns-overview-fields.component.scss']
})
export class SearchColumnsOverviewFieldsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() searchObject: any;
  @Input() viewName: string;
  @Input() disableLinks: boolean;
  @Input() isEditing: boolean;
  @Input() rootObjId: string;
  @Input() searchItemIndex: number;
  @ViewChild('sizeElement', { static: true }) sizeElementRef: ElementRef;

  AConst = AConst;
  columns: Array<Array<OverviewField>> = [];
  private stopSizeWatch;

  constructor(private uiTools: UiToolsService) {
  }

  ngOnInit() {
    this.stopSizeWatch = this.uiTools.addWindowSizeListener((newVal, oldVal) => {
      if (this.viewName === 'list-thumbnail') {
        if ((newVal.width !== oldVal.width) && (newVal.width > 740 && oldVal.width < 760)) {
          this.setColumns();
        }
      }
    });
  }

  ngOnChanges(): void {
    this.setColumns();
  }

  ngOnDestroy(): void {
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
  }

  private getColumns(overviewFields) {
    const columns = [];
    const el = this.sizeElementRef.nativeElement;
    if (el) {
      const width = el.offsetWidth;
      overviewFields.forEach((ovf, index) => {
        const colIndex = Math.floor(index / 4);
        if (colIndex === 0 || width > 500) {
          if (index < 12) {
            if (columns.length <= colIndex) {
              columns.push([]);
            }
            columns[colIndex].push(ovf);
          }
        }
      });
    }
    return columns;
  }

  setColumns() {
    if (this.searchObject) {
      const overviewFields = this.searchObject['$$overview'];
      if (overviewFields) {
        this.columns = this.getColumns(overviewFields);
        if (this.columns.length === 0) {
          console.warn('No columns set!');
        }
      } else {
        console.error('No overview fields found');
      }
    }
  }


}
