import {Component, Input, OnInit} from '@angular/core';
import {OverviewField} from '../../core/object-view';
import {CommonsService} from '../../core/commons.service';

@Component({
  selector: 'app-overview-field',
  templateUrl: './overview-field.component.html',
  styleUrls: ['./overview-field.component.scss']
})
export class OverviewFieldComponent implements OnInit {

  @Input() overviewField: OverviewField;
  @Input() viewName: string;
  @Input() disableLinks: boolean;
  @Input() toneDownTextColor: boolean;

  isArrayValue: boolean;
  showMore: boolean;
  expired = false;

  constructor(private commons: CommonsService) {
  }

  ngOnInit() {
    if (this.overviewField.items.length > 1) {
      this.isArrayValue = true;
      this.showMore = false;
    }
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
    if (this.showMore) {
      window.addEventListener('mouseup', this.showMoreCallback, true);
    } else {
      window.removeEventListener('mouseup', this.showMoreCallback, true);
    }
  }

  private showMoreCallback = (item) => {
    const found = this.commons.findClassNameRecursively(item.target, 'dropdownIgnoreClicks');
    if (!found) {
      setTimeout(() => {
        this.showMore = false;
        window.removeEventListener('mouseup', this.showMoreCallback, true);
      }, 100);
    }
  }

}
