import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {FieldStateService} from '../../core/field-state.service';

@Component({
  selector: 'app-field-title',
  templateUrl: './field-title.component.html',
  styleUrls: ['./field-title.component.scss']
})
export class FieldTitleComponent implements OnInit {

  AConst = AConst;
  fieldTitle = '';
  inputType;

  @Input() field;
  @Input() rootObject;
  @Input() edit;
  @Input() editable;
  @Input() showToolTip;
  @Input() appendText;
  @Input() toneDownTextColor: boolean;

  constructor(private fieldState: FieldStateService) { }

  ngOnInit() {
    this.fieldTitle = this.fieldState.getFieldTitle(this.field, this.rootObject);
    this.inputType = this.field[AConst.INPUT_TYPE] || '';
  }

}
