/*
* The following classes are a reflection of classes defined on the service side, object_view_builder.py.
* Any changes to the server classes must be repeated here.
* This is also the reason why fields are written as "snake case" instead of camel case.
* */

export class ObjectView {
  artifact_name: string;
  authority: string;
  icon: string;
  image_ids: Array<string>;
  meta_type: string;
  object_type: string;
  overview: Array<OverviewField>;
  sections: Array<ViewSection>;
}

export class ObjectViewAndData {
  view_data: ObjectView;
  artifact: any;
}

export class ViewSection {
  title: string;
  name: string;
  section_fields: Array<ViewField>;
}

export class OverviewField {
  field_name: string;
  title: string;
  items: Array<FieldItem>;
  field_type: string;
  order;
  field_class: string;
  container_class: string;
  is_link: boolean;
}

export class ActionButtonField {
  actions: Array<any>;
  display: any;
  field_ifs: Array<any>;
}

export class ViewField {
  title: string;
  items: Array<FieldItem>;
  field_type: string;
  action_button: ActionButtonField;
}

export class FieldItem {
  field_values: FieldValues;
  header_values: FieldValues;
  is_new_array_item: boolean;
  checkbox_field: CheckboxField;
}

export class FieldValues {
  values: Array<FieldValue>;
  field_icon: FieldIcon;
  title_values: Array<FieldValue>;
  level: number;
}

export class FieldValue {
  value: any;
  ref_id: string;
  is_text: boolean;
}

export class FieldIcon {
  icon: string;
  icon_frame: string;
}

export class CheckboxField {
  array_field_name: string;
  index: number;
  field_name: string;
}
