import {Injectable} from '@angular/core';
import {StateService} from '@uirouter/core';
import {FieldValueService} from '../core/field-value.service';
import {ModelFactoryService} from '../core/model-factory.service';
import {FieldMetaHandlerService} from '../core/field-meta-handler.service';
import {AConst} from '../core/a-const.enum';
import {CommonsService} from '../core/commons.service';
import {CmsApiService} from '../core/cms-api.service';
import {ObjectEditService} from '../core/object-edit.service';
import {FieldStateService} from '../core/field-state.service';
import {FieldActionParameters} from './field-action-parameters';

@Injectable({
  providedIn: 'root'
})
export class FieldActionService {

  constructor(
    private state: StateService,
    private fieldValueService: FieldValueService,
    private fieldMetaHandler: FieldMetaHandlerService,
    private fieldState: FieldStateService,
    private modelFactory: ModelFactoryService,
    private commons: CommonsService,
    private cms: CmsApiService,
    private objectEditService: ObjectEditService) {
  }

  runActions(params: FieldActionParameters) {
    let actions = params.field.actions;
    if (!actions) {
      actions = this.fieldMetaHandler.getMetaProp({
        metaPropName: 'actions',
        fieldName: params.field.name,
        parentModel: params.object
      });
    }
    if (actions) {
      this.actionLooper(params, actions, 0, 0);
    }
  }

  getActionButtonsFromModel(model, showType) {
    const fields = model['$$meta'];
    const buttons = [];
    showType = showType || 'yes';
    for (const fieldName in fields) {
      if (fields.hasOwnProperty(fieldName)) {
        const field = fields[fieldName];
        if (field[AConst.FIELD_TYPE] === 'action_button' &&
          field.display && field.display.show === showType) {
          buttons.push({
            rootModel: model,
            parentModel: model,
            propName: field.name,
            field: field
          });
        }
      }
    }
    return buttons;
  }

  private actionLooper(params: FieldActionParameters, actions, actionIndex, actionItemIndex) {
    const action = actions[actionIndex];
    if (action.trigger === params.trigger) {
      const actionList = action[AConst.ACTION_LIST];
      this.runAction(params, actionList[actionItemIndex]).then(() => {
        if (++actionItemIndex >= actionList.length) {
          actionItemIndex = 0;
          if (++actionIndex >= actions.length) {
            console.log('All actions run');
            return;
          }
        }
        this.actionLooper(params, actions, actionIndex, actionItemIndex);
      });
    }
  }

  private runAction(params: FieldActionParameters, actionItem): Promise<void> {
    return new Promise<void>(resolve => {
      switch (actionItem[AConst.ACTION_TYPE]) {
        case 'add_to_prop':
          this.addToItem(params, actionItem).then(() => {
            resolve();
          });
          break;
        case 'copy_me':
          this.copyMe(params, actionItem).then(() => {
            resolve();
          });
          break;
        case 'go_state':
          this.goState(params, actionItem);
          resolve();
          break;
        case 'move_to':
          this.moveTo(params, actionItem);
          resolve();
          break;
        case 'remove_new_array_item':
          this.removeNewArrayItem(params, actionItem);
          resolve();
          break;
        case 'scroll_to_top':
          this.scrollToTop();
          resolve();
          break;
        case 'set_prop':
          this.setProp(params, actionItem).then(() => {
            resolve();
          });
          break;
        case 'store_changes':
          this.storeChanges(params).then(() => {
            resolve();
          });
          break;
        case 'get_coordinates':
          this.getCoordinates(params, actionItem).then(() => {
            resolve();
          });
          break;
        case 'reset_form':
          this.objectEditService.resetSectionsContainerFormGroup(params.sectionsContainer).then(() => {
            resolve();
          });
          break;
        case 'close_edit':
          params.closeEdit.emit();
          break;
        default:
          console.warn('Unknown action type ' + actionItem[AConst.ACTION_TYPE]);
          resolve();
      }
    });
  }

  private addToItem(params: FieldActionParameters, actionItem): Promise<void> {
    return new Promise<void>(resolve => {
      let value = this.getTargetVal(params, actionItem);
      value += actionItem.value;
      this.setTargetVal(params, actionItem, value).then(() => {
        resolve();
      });
    });
  }

  private copyMe(params: FieldActionParameters, actionItem): Promise<void> {
    return new Promise<void>(resolve => {
      const artifactId = params.sectionsContainer.rootObject[AConst.ARTIFACT_ID];
      this.cms.copyArtifact({artifact_id: artifactId}).then(copy => {
        if (copy) {
          this.modelFactory.setModelItemAsync(copy[AConst.OBJECT_TYPE], copy).then(data => {
            // Should have run store.setSetModelItemHelperProps
            // here, but will cause circular dependency issues
            params.object = data;
            if (actionItem.target) {
              params.newData = data;
              this.setProp(params, actionItem).then(() => {
                resolve();
              });
            } else {
              resolve();
            }
          });
        }
      });
    });
  }

  private goState(params: FieldActionParameters, actionItem) {
    const stateParams = {};
    for (const key in actionItem.value) {
      if (actionItem.value.hasOwnProperty(key)) {
        const subst = actionItem.value[key];
        let value = subst;
        const fieldName = this.fieldValueService.getSubstituteString(
          subst, '{', '}');
        if (fieldName) {
          value = this.fieldValueService.getFieldValFromFieldParameters(params, fieldName);
        }
        stateParams[key] = value;
      }
    }
    this.state.go(actionItem.target, stateParams);
  }

  private moveTo(params: FieldActionParameters, actionItem) {
    const targetArray = this.fieldValueService.getFieldValFromFieldParameters(params, actionItem.target);
    const parentArray = params.grandParentObject[params.field[AConst.PARENT_NAME]];
    const moveItem = params.object;
    let deleteIndex;

    deleteIndex = this.getItemIndex(parentArray, moveItem, actionItem.source);
    if (deleteIndex !== -1) {
      parentArray.splice(deleteIndex, 1);
    }
    targetArray.push(moveItem);
  }

  private removeNewArrayItem(params: FieldActionParameters, actionItem) {
    let targetModel, targetArray, itemIndex;
    const item = params.object;
    if (item._create) {
      targetModel = this.getTargetModel(params, actionItem);
      targetArray = targetModel[actionItem.target];
      itemIndex = targetArray.indexOf(item);
      if (itemIndex !== -1) {
        this.modelFactory.deleteArrayItem(targetArray, itemIndex, targetModel);
      }
    }
  }

  private setProp(params: FieldActionParameters, actionItem): Promise<void> {
    return new Promise<void>(resolve => {
      let sourceVal = null;
      if (params.newData) {
        if (actionItem.source) {
          sourceVal = params.newData[actionItem.source];
        } else {
          sourceVal = params.newData;
        }
      }
      if (sourceVal) {
        this.setTargetVal(params, actionItem, sourceVal).then(() => {
          resolve();
        });
      } else {
        this.getSetProp(params, actionItem).then(() => {
          resolve();
        });
      }
    });
  }

  private scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }


  private storeChanges(params: FieldActionParameters): Promise<void> {
    return new Promise<void>(resolve => {
      this.objectEditService.setObjectValuesStoreObject(params.sectionsContainer, true).then(data => {
        console.log('Changes stored');
        params.newData = data;
        params.edit = false;
        resolve();
      });
    });
  }

  private getSetProp(params: FieldActionParameters, actionItem): Promise<void> {
    return new Promise<void>(resolve => {
      let value;
      if (actionItem.source) {
        value = this.getSourceVal(params, actionItem);
      } else {
        value = actionItem.value;
      }
      if (value !== undefined) {
        this.setTargetVal(params, actionItem, value).then(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  private setTargetVal(params: FieldActionParameters, actionItem, value): Promise<void> {
    return new Promise<void>(resolve => {
      const targetField = actionItem.target;
      const targetModel = this.getTargetModel(params, actionItem);
      if (!Array.isArray(targetModel[targetField])) {
        this.fieldValueService.setFieldValueAndControlValue(params, targetModel, targetField, value).then(() => {
          resolve();
        });
      } else {
        targetModel[targetField].push(value);
        resolve();
      }
    });
  }

  private getTargetModel(params: FieldActionParameters, actionItem) {
    const target = actionItem.target;
    let targetModel;
    if (params.grandParentObject &&
      params.grandParentObject[target] !== undefined) {
      targetModel = params.grandParentObject;
    } else if (params.sectionsContainer.rootObject &&
      params.sectionsContainer.rootObject[target] !== undefined) {
      targetModel = params.sectionsContainer.rootObject;
    } else if (params.sectionsContainer.rootObject['$$topModel'] &&
      params.sectionsContainer.rootObject['$$topModel'][target] !== undefined) {
      targetModel = params.sectionsContainer.rootObject['$$topModel'];
    } else {
      targetModel = params.object;
    }
    return targetModel;
  }

  private getTargetVal(params: FieldActionParameters, actionItem) {
    return this.fieldValueService.getFieldValFromModel(params, params.object, actionItem.target);
  }


  private getSourceVal(params: FieldActionParameters, actionItem) {
    return this.fieldValueService.getFieldValFromFieldParameters(params, actionItem.source);
  }

  private getItemIndex(array, arrayItem, compareField) {
    let foundIndex = -1;
    array.forEach((item, index) => {
      const compareValue = item[compareField];
      if (compareValue) {
        if (compareValue === arrayItem[compareField]) {
          foundIndex = index;
        }
      } else {
        console.warn('Array items require field "' + compareField + '"!');
      }
    });
    return foundIndex;
  }

  private getCoordinates(params: FieldActionParameters, actionItem): Promise<void> {
    return new Promise<void>(resolve => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          // Warning! The coordinate properties are hardcoded, which will be a problem if model is changed!
          const object = params.object;
          const targets = actionItem.target.split(';');
          let countDown = targets.length;
          targets.forEach((target: string) => {
            const nameVal = target.split('=');
            this.fieldValueService.setFieldValueAndControlValue(params, object, nameVal[0], position.coords[nameVal[1]]).then(() => {
              if (!--countDown) {
                resolve();
              }
            });
          });
        });
      } else {
        console.warn('Geolocation is not supported by this browser.');
        resolve();
      }
    });
  }

}
