import { Injectable } from '@angular/core';
import {ReporterUtilityService} from './reporter-utility.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
/**
 * Service containing method used when handling translated text strings.
 * @type {{getString, translateSearchFilterSettings, translateArtifactTypeFilterSettings}}
 */
export class ReporterTranslationService {

  constructor(private reporterUtility: ReporterUtilityService,
              private translate: TranslateService) {
  }

  /**
   * Returns the string specified by the key from the list of translated strings.
   * @param key
   * @returns {*}
   * @private
   */
  getString(key) {
    return this.translate.instant(key);
  }

  /**
   * Extracts the selected search filters and returns them in a translated HR-format.
   * @param searchContainer
   * @returns {*}
   * @private
   */
  translateSearchFilterSettings(searchContainer) {

    if (searchContainer === null ||
      typeof(searchContainer.filterGroups) === 'undefined') {
      return;
    }

    const filtersHr = [];

    searchContainer.filterGroups.map((fg) => {
      const filterHr = {
        name: this.getString(fg.title),
        settings: []
      };

      filterHr.name =
        filterHr.settings = [];

      fg.checkFilters.filter((f) => {
        if (f.checked) {
          const val = (
            (typeof(f.noTransTitle) !== 'undefined') ?
              f.noTransTitle :
              this.getString(f.title)
          );
          filterHr.settings.push(val);
        }
      });

      if (filterHr.settings.length > 0) {
        filtersHr.push(filterHr);
      }
    });

    return filtersHr;
  }

  /**
   * Extracts the selected artifact types and returns the title info in HR-format.
   * @private
   */
  translateArtifactTypeFilterSettings(searchContainer) {

    const filtersHr = [];

    const artifactTypes = {
      name: this.getString('TRANS__REPORTER_PDF__FILTER_ARTIFACTTYPES'),
      settings: []
    };

    const artifactTypesMenuItems = searchContainer.currentPathView.menus;
    if (artifactTypesMenuItems !== null) {
      artifactTypesMenuItems.map((aType) => {
        if (aType.checked) {
          artifactTypes.settings.push(this.getString(aType.title));
        }
      });
    }

    if (artifactTypes.settings.length > 0) {
      filtersHr.push(artifactTypes);
    }

    return filtersHr;
  }

}
