import {Component, Input, OnInit} from '@angular/core';
import {ActionMenuContainer} from '../../core/action-menu-container';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-object-menu-report',
  templateUrl: './object-menu-report.component.html',
  styleUrls: ['./object-menu-report.component.scss']
})
export class ObjectMenuReportComponent implements OnInit {

  @Input() menuContainer: ActionMenuContainer;
  @Input() menu;

  AConst = AConst;

  constructor() {
  }

  ngOnInit() {
  }

}
