import {Component, Input, OnInit} from '@angular/core';
import {StateService, UIRouterGlobals} from '@uirouter/core';
import {ObjectEditService} from '../../core/object-edit.service';
import {SectionsContainer} from '../../core/sections-container';
import {CurrentObjectService} from '../../core/current-object.service';
import {RefService} from '../../core/ref.service';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss']
})
export class AdminSettingsComponent implements OnInit {

  @Input() edit;
  @Input() objectType;
  @Input() admin_type;

  AConst = AConst;
  artifactId;
  sectionsContainer: SectionsContainer;
  sections: Array<any>;
  obj: any;
  contentInfo;
  parent;
  actions = [
    {
      containerClass: 'register-button-container',
      buttonClass: 'register-button',
      type: 'register',
      fn: () => {
        this.saveSettings();
      },
      buttonText: 'TRANS__EDIT__STORE_ARTIFACT',
      helperBoxText: 'TRANS__EDIT__STORE_ARTIFACT__HELP_TEXT'
    },
    {
      buttonClass: 'cancel-button',
      fn: () => {
        this.cancelArtifact();
      },
      buttonText: 'TRANS__EDIT__CANCEL'
    }
  ];

  constructor(
    private stateService: StateService,
    private uiRouterGlobals: UIRouterGlobals,
    private objectEditService: ObjectEditService,
    private currentObjectService: CurrentObjectService,
    private ref: RefService) {
  }

  ngOnInit() {
    const adminType = this.uiRouterGlobals.params['admin_type'];
    this.artifactId = this.uiRouterGlobals.params.artifactId;
    this.uiRouterGlobals.params.listName = 'overview';
    const curStateName = 'home.primus.admin.adminSettings';
    this.contentInfo = {
      stateName: curStateName,
      curListName: this.uiRouterGlobals.params.listName
    };

    this.parent = {
      path: 'home.primus.admin',
      adminType: adminType,
      subMenus: true,
      target_params: {admin_type: adminType},
      currentState: curStateName
    };

    this.loadSetObject();
  }

  private loadSetObject() {
    this.objectEditService.loadObjectGetSectionsContainer(this.artifactId, null).then(sectionsContainer => {
      this.sectionsContainer = sectionsContainer;
      this.sections = sectionsContainer.sections;
      this.obj = sectionsContainer.rootObject;
      this.edit = this.uiRouterGlobals.params.edit;
      this.currentObjectService.isEditing = this.edit;
      this.currentObjectService.currentObject = sectionsContainer.rootObject;
    });
  }

  private saveSettings() {
    const sRef = this.ref.makeRef({
      object: this.obj,
      stateParams: this.uiRouterGlobals.params,
      isNew: false
    });
    sRef.param.edit = undefined;
    this.objectEditService.setObjectValuesStoreObject(this.sectionsContainer).then(() => {
      // $scope.$emit('artifactUpdated');
      this.currentObjectService.isEditing = false;
      this.stateService.go(sRef.stateName, sRef.param);
    });
  }

  private cancelArtifact() {
    if (this.obj && this.obj[AConst.ARTIFACT_ID]) {
      this.stateService.go('home.primus.admin.adminSettings');
      // $scope.$emit('artifactUpdated');
    } else {
      window.history.back();
    }
  }

}
