import {
  Component,
  ElementRef, EventEmitter,
  forwardRef,
  Input,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {OptionsService} from '../../core/options.service';
import {ModelFactoryService} from '../../core/model-factory.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FieldValueService} from '../../core/field-value.service';
import {FieldStateService} from '../../core/field-state.service';
import {SectionsContainer} from '../../core/sections-container';
import {FieldValidationService} from '../../core/field-validation.service';
import {EditFieldSelectOptionsComponent} from './edit-field-select-options/edit-field-select-options.component';
import {TranslateService} from '@ngx-translate/core';
import {EditFieldSelectHierarchyComponent} from './edit-field-select-hierarchy/edit-field-select-hierarchy.component';
import {CommonsService} from '../../core/commons.service';
import {FieldActionParameters} from '../../shared/field-action-parameters';
import {FieldActionService} from '../../shared/field-action.service';
import {FieldParameters} from '../../core/field-parameters';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-edit-field-select',
  templateUrl: './edit-field-select.component.html',
  styleUrls: ['./edit-field-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldSelectComponent)
    }
  ]
})
export class EditFieldSelectComponent implements OnInit, ControlValueAccessor {
  reference = {};
  refProp;
  optionContainer = {options: []};
  showOptions = false;
  AConst = AConst;
  query = {value: ''};
  closeResult: string;
  placeHolderSearchFor;
  onChange;
  fieldKey;
  temporaryFieldValueName;
  placeholder;
  openModal = false;
  selectedRow = 0;
  isArray = false;
  timeoutId;
  useQuery = false;
  fullSearch = false;

  @Input() sectionsContainer: SectionsContainer;
  @Input() fieldParameters: FieldParameters;
  @Input() inFocus;
  @Output() fieldFocus = new EventEmitter<object>();
  @Output() fieldBlur = new EventEmitter<object>();

  @ViewChild('fieldSelectInput', { static: true }) fieldSelectInput: ElementRef;
  @ViewChild('addNewOption', { static: true }) addNewOption: ElementRef;
  @ViewChild(EditFieldSelectOptionsComponent, { static: true }) editFieldSelectOptions;
  @ViewChild(EditFieldSelectHierarchyComponent, { static: true }) editFieldSelectHierarchyOption;


  constructor(private optionsService: OptionsService,
              private modelFactory: ModelFactoryService,
              private fieldValue: FieldValueService,
              private fieldState: FieldStateService,
              public fieldValidation: FieldValidationService,
              private translate: TranslateService,
              private commons: CommonsService,
              private fieldActionService: FieldActionService,
              private modalService: MatDialog) {
  }

  ngOnInit() {
    const fieldInfo = this.fieldParameters.field;
    let fieldName: string;
    this.isArray = this.getIsArray();
    this.fieldKey = this.fieldState.getFieldKeyWhileDrawingInputs(fieldInfo, this.fieldParameters.index, this.fieldParameters.parentIndex);
    this.reference = fieldInfo.reference;
    this.refProp = this.reference[AConst.REF_PROP] || AConst.ARTIFACT_ID;
    this.placeholder = this.fieldState.getFieldTitle(fieldInfo, this.fieldParameters.rootObject);
    this.placeHolderSearchFor = this.translate.instant('TRANS__FIELD_SELECT__SEARCH_PLACEHOLDER');

    if (fieldInfo[AConst.FIELD_TYPE] === AConst.ARRAY) {
      if (!this.isSingleItemArray) {
        this.setQueryValue('');
      } else {
        this.getTextValueAsync().then(value => {
          this.setQueryValue(value);
        });
      }
      this.temporaryFieldValueName = '$$' + fieldInfo.inline.prop + '_value';
      if (this.fieldParameters.object[fieldInfo.name].length > 0) {
        fieldName = fieldInfo.inline.prop;
        this.fieldParameters.object[fieldInfo.name].forEach((option) => {
          this.optionsService.setIconAndAuthority(option, fieldName, fieldInfo);
        });
      }

    } else {
      this.getTextValueAsync().then(value => {
        this.setQueryValue(value);
      });
      this.temporaryFieldValueName = '$$' + fieldInfo.name + '_value';
      fieldName = fieldInfo.name;
      this.optionsService.setIconAndAuthority(this.fieldParameters.object, fieldName, fieldInfo);
    }
  }

  clearField() {
    this.setQueryValue('');
    this.setFieldValue(this.fieldParameters.field[AConst.FIELD_TYPE]  === 'array' ? [] : null);
    this.setFieldTextValue('');
    if (this.temporaryFieldValueName) {
      this.fieldParameters.object[this.temporaryFieldValueName] = '';
    }
  }

  onInputKeyDown(event) {
    if (event.key === 'Tab') {
      this.tabToNextField();
    }
  }


  onInputKey(event) {
    if (event.key !== 'Tab') {
      this.fullSearch = false;
      this.setQueryValue(event.target.value, 1000, true);
      if (!this.showOptions) {
        this.toggleShowOptions(true);
      } else {
        if (this.reference[AConst.IS_HIERARCHIC]) {
          if (event.key === 'ArrowDown') {
            this.editFieldSelectHierarchyOption.setNodeToFocusFromInput();
          }
        } else {
          if (event.key === 'Enter') {
            if (this.selectedRow === 0 && this.optionContainer.options.length) {
              if (!this.optionContainer.options[this.selectedRow]['$$isSelected']) {
                this.onOptionsSelected([this.optionContainer.options[this.selectedRow]]);
              } else {
                this.onOptionUnchecked(this.optionContainer.options[this.selectedRow]);
              }
            }
          } else if (event.key === 'ArrowDown') {
            if (this.optionContainer.options.length - 1 === this.selectedRow) {
              this.selectedRow = 0;
            } else {
              if (this.selectedRow === 0) {
                this.selectedRow = 1;
              }
              if (this.selectedRow === 1) {
                this.editFieldSelectOptions.setOptionsToFocus(this.selectedRow);
              }
            }
          }
        }
        event.preventDefault();
      }
    }

    if (event.key === 'Escape' || event.key === 'Esc') {
      this.toggleShowOptions(false);
    }
  }

  tabToNextField() {
    if (this.showOptions) {
      if ((this.reference[AConst.CAN_ADD_NEW] ||
        this.reference[AConst.SEARCH_KULTURNAV]) && this.query.value !== '') {
        setTimeout(() => {
          this.addNewOption.nativeElement.focus();
        }, 100);
      } else {
        this.toggleShowOptions(false);
      }
    } else {
      this.fieldSelectBlur();
    }
  }

  onKeyEventsInShowOptions(value) {
    if (value.row) {
      this.updateSelectedRow(value.row);
    }
    if (value.setFocus) {
      this.setInputToFocus();
    }

    if (value.tabbing) {
      this.tabToNextField();
    }
  }

  updateSelectedRow(row) {
    this.selectedRow = row;
  }

  setInputToFocus() {
    this.fieldSelectInput.nativeElement.focus();
  }

  refreshAndShowOptions() {
    this.toggleShowOptions(true, true);
  }

  onOptionsSelected(options) {
    const fieldInfo = this.fieldParameters.field;
    let value;

    if (fieldInfo[AConst.FIELD_TYPE] !== 'array') {
      value = this.setFieldValueFromOption(options[0]);
    } else {
      value = this.addFieldArrayItemsFromOptions(options);
    }
    this.myFormControl.markAsDirty();
    if (this.onChange) {
      this.onChange(value);
    } else {
      console.warn('No "onChange" function set for ' + this.fieldKey);
    }
  }

  onOptionUnchecked(option) {
    const optionId = option[this.refProp];
    const array = this.fieldValue.getFieldValue(this.fieldParameters.rootObject, this.fieldKey);
    const foundIndex = this.findFieldArrayItemIndex(optionId);
    if (foundIndex !== -1) {
      this.modelFactory.deleteArrayItem(array, foundIndex, this.fieldParameters.rootObject);
      const count = this.modelFactory.countArrayElements(array);
      this.myFormControl.setValue(count ? count : '');
      this.myFormControl.markAsDirty();
      option.$$isSelected = false;
    }
  }

  fieldSelectBlur() {
    setTimeout(() => {
      if (!this.showOptions) {
        this.fieldBlur.emit();
      }
    }, 300);
  }

  checkOutsideClick(evt: Event) {
    let found;
    if (this.sectionsContainer.isDialog) {
      found = this.commons.findClassNameRecursively(evt.target, 'dropdownIgnoreClicksModal');
      if (!found) {
        setTimeout(() => {
          this.toggleShowOptions(false);
          window.removeEventListener('mouseup', this.checkOutsideClick, true);
        }, 100);
      }
    } else {
      found = this.commons.findClassNameRecursively(evt.target, 'dropdownIgnoreClicks');
      if (!found && (!this.modalService.openDialogs || !this.modalService.openDialogs.length)) {
        setTimeout(() => {
          this.toggleShowOptions(false);
          window.removeEventListener('mouseup', this.checkOutsideClick, true);
        }, 100);
      }
    }
  }

  onFieldSelectFocus() {
    this.fieldFocus.emit();
  }

  onCloseOptions() {
    if (!this.isArray && this.query.value !== this.textValue) {
      if (this.fieldParameters.object[this.fieldParameters.field.name]) {
        this.setQueryValue(this.textValue);
      } else {
        this.setQueryValue('');
      }
    }
  }

  createOption() {
    this.openModal = true;
    this.optionsService.createOption(this.fieldParameters, this.query.value).then(option => {
      this.onOptionsSelected([option]);
    }, (reason) => {
      if (reason) {
        this.closeResult = `Dismissed ${reason}`;
      }
      this.openModal = false;
    });
  }

  createOptionBlur() {
    if (!this.openModal && !this.reference[AConst.SEARCH_KULTURNAV]) {
      this.toggleShowOptions(false);
    }
  }

  searchKulturNavBlur() {
    if (!this.openModal) {
      this.toggleShowOptions(false);
    }
  }

  createOptionWithKey(event) {
    if (event.key === 'Enter') { // enter and space
      // event.preventDefault();
      event.target.focus();
      this.createOption();
    }
  }

  searchKulturNav() {
    this.openModal = true;
    this.optionsService.searchKulturNav(this.fieldParameters.field, this.query.value).then(options => {
      this.onOptionsSelected(options);
    }, (reason) => {
      this.closeResult = `Dismissed ${reason}`;
      this.openModal = false;
    });
  }

  searchKulturNavWithKey(event) {
    if (event.key === 'Enter') { // enter and space
      // event.preventDefault();
      event.target.focus();
      this.searchKulturNav();
    }
  }

  get textValue() {
    let res;
    if (!this.isSingleItemArray) {
      res = this.fieldValue.getValueCompanionImmediate(this.fieldParameters.object, this.fieldParameters.field.name);
    } else {
      const item = this.firstArrayItemNotDeleted;
      if (item) {
        res = this.fieldValue.getValueCompanionImmediate(item, this.fieldParameters.field.inline[AConst.PROP]);
      }
    }
    return res;
  }

  private setQueryValue(value, timeout?, useQuery?) {
    this.useQuery = useQuery;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = 0;
    }
    this.timeoutId = setTimeout(() => {
      if (this.myFormControl) {
        this.query.value = value;
        let setControlValue = true;
        if (this.fieldParameters.field[AConst.FIELD_TYPE] === 'array') {
          setControlValue = false;
          const inline = this.fieldParameters.field[AConst.INLINE];
          if (inline && inline[AConst.INLINE_LIST] && inline[AConst.INLINE_LIST][AConst.MAX_LENGTH] === 1) {
            setControlValue = true;
          }
        }
        if (setControlValue) {
          this.myFormControl.setValue(value);
        }
      }
    }, timeout ? timeout : 100);
  }

  private getTextValueAsync() {
    return new Promise(resolve => {
      if (!this.isSingleItemArray) {
        this.fieldValue.getValueCompanion(this.fieldParameters.object, this.fieldParameters.field.name).then(value => {
          resolve(value);
        });
      } else {
        const item = this.firstArrayItemNotDeleted;
        if (item) {
          this.fieldValue.getValueCompanion(item, this.fieldParameters.field.inline[AConst.PROP]).then(value => {
            resolve(value);
          });
        } else {
          resolve('');
        }
      }
    });
  }

  private get firstArrayItemNotDeleted() {
    let res;
    for (let t = 0; t < this.fieldArray.length; t++) {
      const item = this.fieldArray[t];
      if (!item._destroy) {
        res = item;
        break;
      }
    }
    return res;
  }

  private get myFormControl() {
    const res = this.sectionsContainer.formGroup.controls[this.fieldKey];
    if (!res) {
      console.warn('No form control for key "' + this.fieldKey + '" yet');
    }
    return res;
  }

  get fieldArray(): Array<any> {
    return this.fieldValue.getFieldValue(this.fieldParameters.rootObject, this.fieldKey);
  }

  private setFieldValue(value) {
    this.fieldValue.setFieldValue(this.fieldParameters.rootObject, this.fieldKey, value);
    const actionParams = new FieldActionParameters();
    actionParams.sectionsContainer = this.sectionsContainer;
    actionParams.rootObject = this.sectionsContainer.rootObject;
    actionParams.field = this.fieldParameters.field;
    actionParams.object = this.fieldParameters.object;
    actionParams.parentIndex = this.fieldParameters.parentIndex;
    actionParams.grandParentObject = this.fieldParameters.grandParentObject;
    actionParams.edit = true;
    actionParams.trigger = 'after_edit';
    this.fieldActionService.runActions(actionParams);
  }

  private setFieldTextValue(value) {
    this.fieldValue.setFieldValue(this.fieldParameters.rootObject, this.fieldKey, value, true);
  }

  private setFieldValueFromOption(option) {
    this.setFieldValue(option[this.refProp]);
    this.setOthers(option);
    const queryValue = this.optionsService.getOptionName(option, true);
    this.optionsService.setSelectedOptionName(option, null, true);
    this.temporaryFieldValueName = '$$' + this.fieldParameters.field.name + '_value';
    this.fieldParameters.object[this.temporaryFieldValueName] = option['$$name'];
    this.fieldParameters.object['icon'] = option['icon'];
    this.fieldParameters.object['icon_frame'] = option['icon_frame'];
    this.setQueryValue(queryValue);
    this.setFieldTextValue(queryValue);
    this.toggleShowOptions(false);
    // Run a new search on all options so that next time user clicks the fields, all options will be displayed
    return queryValue;
  }

  private addFieldArrayItemsFromOptions(options: Array<any>) {
    const array = this.fieldArray;
    const queryValue = this.optionsService.getOptionName(options[0], true);
    if (this.isSingleItemArray) {
      if (array.length) {
        this.deleteExistingArrayElements();
      }
      this.createAddArrayProps(options, array);
      this.setQueryValue(queryValue);
      this.toggleShowOptions(false);
      return queryValue;
    } else {
      this.createAddArrayProps(options, array);
      this.myFormControl.setValue(array.length ? array.length : '');
      return array.length;
    }
  }

  private createAddArrayProps(options, array) {
    const inline = this.fieldParameters.field.inline;
    options.forEach(option => {
      const data = {};
      data[inline[AConst.PROP]] = option[this.refProp];
      this.fieldValue.setValueCompanion(data, inline[AConst.PROP], option[AConst.ARTIFACT_NAME]).then();
      this.optionsService.setSelectedOptionName(option, null, true);
      this.temporaryFieldValueName = '$$' + inline[AConst.PROP] + '_value';
      data[this.temporaryFieldValueName] = option['$$name'];
      this.modelFactory.createAddArrayItem(this.fieldParameters.rootObject, array, inline[AConst.MODEL], data);
      option.$$isSelected = true;
    });
  }

  private deleteExistingArrayElements() {
    const array = this.fieldArray;
    for (let t = array.length - 1; t >= 0; t--) {
      this.modelFactory.deleteArrayItem(array, t, this.fieldParameters.rootObject);
    }
  }

  // The reference meta attribute may contain a "set_others" attribute that allows other field values to be set based on the option data
  private setOthers(option) {
    if (this.reference[AConst.SET_OTHERS]) {
      this.reference[AConst.SET_OTHERS].forEach(setOther => {
        const sourceField = setOther[AConst.SOURCE_FIELD];
        const targetField = setOther[AConst.TARGET_FIELD];
        this.fieldParameters.object[targetField] = option[sourceField];
      });
    }
  }

  private findFieldArrayItemIndex(itemId) {
    const inlinePropName = this.fieldParameters.field.inline[AConst.PROP];
    const array = this.fieldArray;
    let foundIndex = -1;
    array.forEach((item, index) => {
      if (item[inlinePropName] === itemId) {
        foundIndex = index;
      }
    });
    if (foundIndex === -1) {
      console.warn('Array element with id ' + itemId + ' not found');
    }
    return foundIndex;
  }

  toggleShowOptions(setValue, fullSearch?) {
    if (setValue === undefined) {
      setValue = !this.showOptions;
    }
    this.fullSearch = fullSearch;
    this.showOptions = setValue;

    if (this.showOptions) {
      window.addEventListener('mouseup', event => {
        this.checkOutsideClick(event);
      }, true);
    } else {
      this.onCloseOptions();
      window.removeEventListener('mouseup', event => {
        this.checkOutsideClick(event);
      }, true);
      this.fieldSelectBlur();
    }
  }

  private getIsArray() {
    return this.fieldParameters.field[AConst.FIELD_TYPE] === AConst.ARRAY && !this.isSingleItemArray;
  }

  get isSingleItemArray() {
    let res = false;
    const inline = this.fieldParameters.field[AConst.INLINE];
    if (inline) {
      const inlineList = inline[AConst.INLINE_LIST];
      if (inlineList && inlineList[AConst.MAX_LENGTH] === 1) {
        res = true;
      }
    }
    return res;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {

  }

  writeValue(obj: any): void {

  }
}
