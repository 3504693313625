import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {SectionsContainer} from '../../core/sections-container';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FieldParameters} from '../../core/field-parameters';
import {AConst} from '../../core/a-const.enum';
import {CommonsService} from '../../core/commons.service';
import {FieldValueService} from '../../core/field-value.service';
import {ModelsService} from '../../core/models.service';
import {InlineArrayItemService} from '../../core/inline-array-item.service';
import {ModelObjectService} from '../../core/model-object.service';

class SpecificArrayElementMeta {
  path_to_array: string;
  set_field_name: string;
  specific_field_name: string;
  specific_field_value: string;
}

@Component({
  selector: 'app-edit-field-specific-array-element',
  templateUrl: './edit-field-specific-array-element.component.html',
  styleUrls: ['./edit-field-specific-array-element.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldSpecificArrayElementComponent)
    }
  ]
})

export class EditFieldSpecificArrayElementComponent implements OnInit, ControlValueAccessor {

  @Input() sectionsContainer: SectionsContainer;
  @Input() fieldParameters: FieldParameters;

  specificFieldParameters: FieldParameters;
  inFocus = false;
  private onChange;
  private models;

  ngOnInit() {
    if (this.sectionsContainer.isCopy) {
      return;
    }
    const element = this.getSpecificArrayElementFromObject();
    if (element) {
      this.specificFieldParameters = this.createSpecificFieldParameters(element);
    } else {
      // This happens if the specific array element did not exist
      this.modelsService.getModelsAsync(false).then(models => {
        this.models = models;
        this.specificFieldParameters = this.createSpecificFieldParametersFromModel();
      });
    }
  }

  constructor(private commons: CommonsService,
              private fieldValueService: FieldValueService,
              private modelsService: ModelsService,
              private inlineArrayItemService: InlineArrayItemService,
              private modelObjectService: ModelObjectService) {
  }

  onFieldBlur() {
    // If the specific array element already existed (e.g. artist on an artwork) the relevant field value will be changed directly on the
    // model. But if the array element did not exist before, it need to be added to the model.
    if (this.specificFieldParameters.field.key === this.fieldParameters.field.key) {
      this.specificFieldParameters = null;
      this.addSpecificArrayElementValue().then(() => {
        const element = this.getSpecificArrayElementFromObject();
        if (element) {
          this.specificFieldParameters = this.createSpecificFieldParameters(element);
        } else {
          console.warn('Element no found, something went horribly wrong!');
        }
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
  }

  private getSpecificArrayElementFromObject(obj?: any,
                                                 elementIdx?: number,
                                                 parentIndex?: number,
                                                 key?: string): FieldParameters {
    const specificMetas = <Array<SpecificArrayElementMeta>>this.fieldParameters.field[AConst.SPECIFIC_ARRAY_ELEMENTS];
    let element = null;
    obj = obj || this.sectionsContainer.rootObject;
    elementIdx = elementIdx || 0;
    const specificMeta = specificMetas[elementIdx];
    const fieldName = specificMeta.specific_field_name;
    const fieldValue = specificMeta.specific_field_value;
    const node = specificMetas[elementIdx].path_to_array;
    const nodeMeta = obj[AConst.$$META][node];
    const nodeKey = key ? key + '.' + node : node;
    if (nodeMeta[AConst.FIELD_TYPE] === AConst.ARRAY) {
      const array = obj[node];
      if (array && array.length) {
        for (let arrayIndex = 0; arrayIndex < array.length; arrayIndex++) {
          const arrayKey = nodeKey + '[' + arrayIndex + ']';
          const item = array[arrayIndex];
          if (elementIdx === specificMetas.length - 1) {
            if (item[fieldName] === fieldValue) {
              element = {
                object: item,
                parentName: node,
                grandParentObject: obj,
                index: arrayIndex,
                parentIndex: parentIndex,
                key: arrayKey + '.' + specificMeta.set_field_name
              };
            }
          } else {
            element = this.getSpecificArrayElementFromObject(item, elementIdx + 1, arrayIndex, arrayKey);
          }
          if (element) {
            break;
          }
        }
      }
    } else {
      element = this.getSpecificArrayElementFromObject(obj[node], elementIdx + 1, parentIndex, nodeKey);
    }
    return element;
  }

  private createSpecificFieldParametersFromModel() {
    const specificMetas = <Array<SpecificArrayElementMeta>>this.fieldParameters.field[AConst.SPECIFIC_ARRAY_ELEMENTS];
    const model = this.getModelFromPaths();
    const lastMeta = specificMetas[specificMetas.length - 1];
    model[lastMeta.specific_field_name] = lastMeta.specific_field_value;
    return this.createSpecificFieldParameters({
      object: model,
      key: this.fieldParameters.field.key
    });
  }

  private getPathListFromSpecificArrayElements() {
    const specificMetas = <Array<SpecificArrayElementMeta>>this.fieldParameters.field[AConst.SPECIFIC_ARRAY_ELEMENTS];
    const pathList = [];
    specificMetas.forEach(specificMeta => {
      pathList.push(specificMeta.path_to_array);
    });
    return pathList;
  }

  private getModelFromPaths(paths?) {
    if (!paths) {
      paths = this.getPathListFromSpecificArrayElements();
    }
    const objectType = this.sectionsContainer.rootObject[AConst.OBJECT_TYPE];
    let model = this.models[objectType];
    paths.forEach(path => {
      const nodeMeta = model[AConst.$$META][path];
      const inline = nodeMeta[AConst.INLINE];
      if (inline) {
        model = this.models[inline[AConst.MODEL]];
      } else {
        console.log('What to do now?');
      }
    });
    return model;
  }

  private addSpecificArrayElementValue(): Promise<void> {
    return new Promise<void>(resolve => {
      const fields = this.modelObjectService.getSectionFields(this.sectionsContainer, this.getPathListFromSpecificArrayElements());
      this.addSpecificArrayElementValueAsync(this.sectionsContainer.rootObject, fields, 0).then(() => {
        resolve();
      });
    });
  }

  private addSpecificArrayElementValueAsync(object, fields, pathIndex, parentIndex?): Promise<void> {
    return new Promise<void>(resolve => {
      const field = fields[pathIndex];
      const addFieldParameters = this.createFieldParametersFromField(object, field, parentIndex);
      this.inlineArrayItemService.addInlineArrayItemToForm(addFieldParameters, true).then(item => {
        this.setSpecificArrayElementFieldValues(item, field, pathIndex, parentIndex).then(() => {
          parentIndex = item['$$addedIndex'];
          const specificMetas = <Array<SpecificArrayElementMeta>>this.fieldParameters.field[AConst.SPECIFIC_ARRAY_ELEMENTS];
          if (pathIndex < specificMetas.length - 1) {
            this.addSpecificArrayElementValueAsync(item, fields, pathIndex + 1, parentIndex).then(() => {
              resolve();
            });
          } else {
            resolve();
          }
        });
      });
    });
  }

  private setSpecificArrayElementFieldValues(item, field, pathIndex, parentIndex?): Promise<void> {
    return new Promise<void>(resolve => {
      const specificMetas = <Array<SpecificArrayElementMeta>>this.fieldParameters.field[AConst.SPECIFIC_ARRAY_ELEMENTS];
      const specificMeta = specificMetas[pathIndex];
      let subfield = this.modelObjectService.getInlineField(field, specificMeta.specific_field_name);
      let fieldParams = this.createFieldParametersFromField(item, subfield, parentIndex);
      this.fieldValueService.setFieldValueAndControlValue(
        fieldParams, item, specificMeta.specific_field_name, specificMeta.specific_field_value).then(() => {
          if (pathIndex === specificMetas.length - 1) {
            subfield = this.modelObjectService.getInlineField(field, specificMeta.set_field_name);
            fieldParams = this.createFieldParametersFromField(item, subfield, parentIndex);
            this.fieldValueService.setFieldValueAndControlValue(fieldParams, item, specificMeta.set_field_name,
              this.sectionsContainer.rootObject[this.fieldParameters.field.name]).then(() => {
                resolve();
              }
            );
          } else {
            resolve();
          }
        }
      );
    });
  }

  private createFieldParametersFromField(object, field, parentIndex) {
    const fieldParameters = new FieldParameters();
    fieldParameters.object = object;
    fieldParameters.field = field;
    fieldParameters.sectionsContainer = this.sectionsContainer;
    fieldParameters.rootObject = this.sectionsContainer.rootObject;
    fieldParameters.parentIndex = parentIndex;
    fieldParameters.index = object['$$addedIndex'];
    return fieldParameters;
  }

  private createSpecificFieldParameters(data): FieldParameters {
    let res = null;
    if (data) {
      const specificMetas = <Array<SpecificArrayElementMeta>>this.fieldParameters.field[AConst.SPECIFIC_ARRAY_ELEMENTS];
      const lastSpecificMeta = specificMetas[specificMetas.length - 1];
      res = new FieldParameters();
      res.sectionsContainer = this.sectionsContainer;
      res.field = this.commons.copy(data.object[AConst.$$META][lastSpecificMeta.set_field_name]);
      res.field[AConst.PARENT_NAME] = data.parentName;
      res.rootObject = this.fieldParameters.rootObject;
      res.object = data.object;
      res.grandParentObject = data.grandParentObject;
      res.parentIndex = data.parentIndex;
      res.index = data.index;
      res.field.key = data.key;
    }
    return res;
  }
}
