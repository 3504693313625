import {Component, Input, OnInit} from '@angular/core';
import {SearchContainer} from '../../search-container';
import {AConst} from '../../../core/a-const.enum';
import {SearchHandlerService} from '../../search-handler.service';

@Component({
  selector: 'app-search-result-view-list-selector',
  templateUrl: './search-result-view-list-selector.component.html',
  styleUrls: ['./search-result-view-list-selector.component.scss']
})
export class SearchResultViewListSelectorComponent implements OnInit {

  @Input() searchContainer: SearchContainer;

  AConst = AConst;

  constructor(private searchHandler: SearchHandlerService) {
  }

  ngOnInit() {
  }

  select(obj) {
    this.searchHandler.selectSearchResultItem(obj, this.searchContainer);
  }

}
