import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-select-images',
  templateUrl: './select-images.component.html',
  styleUrls: ['./select-images.component.scss']
})
export class SelectImagesComponent implements OnInit {
  carouselCont;
  @Input() images;
  @Input() startImageId;
  @Input() target;
  @Output() itemSelected = new EventEmitter<object>();

  constructor() {
  }

  ngOnInit() {
    let indexSet = false, mustSetIndex;
    this.carouselCont = {
      images: this.images,
      imgIndex: -1
    };
    mustSetIndex = this.startImageId !== null;
    for (let i = 0; i < this.carouselCont.images.length; i++) {
      if (mustSetIndex &&
        this.carouselCont.images[i].artifact_id === this.startImageId) {
        indexSet = true;
        this.setImgIndex(i);
      }
    }
    if (!indexSet && this.carouselCont.imgIndex === -1) {
      this.setImgIndex(0);
    }
  }

  onSetActiveImg(index) {
    this.setImgIndex(index);
  }

  selectImage() {
    let reInserted = false;
    const image = this.carouselCont.images[this.carouselCont.imgIndex];
    this.target.forEach((item) => {
      if (image.image_id === item.image_id &&
        item._destroy) {
        item._destroy = false;
        reInserted = true;
      }
    });
    if (!reInserted) {
      image._create = true;
      this.target.push(image);
      this.itemSelected.emit(image);
    }
  }

  hasImage() {
    const image = this.carouselCont.images[this.carouselCont.imgIndex];
    let res = false;
    this.target.forEach((item) => {
      if (image.image_id === item.image_id && !item._destroy) {
        res = true;
      }
    });
    return res;
  }

  private setImgIndex(imgIndex) {
    this.carouselCont.imgIndex = imgIndex;
  }
}
