import {Component, Input, OnInit} from '@angular/core';
import {ObjectNameService} from '../../core/object-name.service';
import {CommonsService} from '../../core/commons.service';

@Component({
  selector: 'app-object-name',
  templateUrl: './object-name.component.html',
  styleUrls: ['./object-name.component.scss']
})
export class ObjectNameComponent implements OnInit {
  objName: string = null;
  translate = false;
  translationData: string = null;

  @Input() object;
  @Input() toLowerCase;
  @Input() replace;
  @Input() dontApplyWordBreak;
  @Input() stylingClass: string;

  constructor(private ons: ObjectNameService,
              private commons: CommonsService) {
  }


  ngOnInit() {
    if (this.object) {
      this.ons.getObjectName({object: this.object, toLowerCase: this.toLowerCase, replace: this.replace},
        (params) => {
          this.objName = params.name;
          this.translate = params.translate;
          this.translationData = params.translationData;
          if (!this.translate && !this.dontApplyWordBreak) {
            this.objName = this.commons.applyWordBreakOpportunity(this.objName);
          }
        }
      );
    }
  }

  get title() {
    let res = '';
    if (this.translationData && this.translationData['id']) {
      res = 'TRANS__OBJECT_NAME__NOT_INDEXED_MESSAGE';
    }
    return res;
  }
}
