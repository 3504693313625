import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpRequest, HttpEvent, HttpResponse, HttpEventType} from '@angular/common/http';
import {MatDialog} from '@angular/material';
import {Observable} from 'rxjs';
import {CmsApiService} from './cms-api.service';
import {ProgressDialogComponent} from '../shared/progress-dialog/progress-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private http: HttpClient,
              private cms: CmsApiService,
              private modalService: MatDialog) {
  }

  uploadFiles(container, files, uploadType, extraData?): Promise<Array<any>> {
    return new Promise(resolve => {
      const uploadParams = {
        url: this.cms.getApiUrl('multimedia/' + uploadType + '/upload/'),
        method: 'POST',
        file: null,
        data: extraData
      };

      if (files) {
        const waitParams = {
          downCount: files.length,
          maxWaits: 1000,
          container: container,
          result: [],
          resolve: resolve
        };
        const progressModal = this.modalService.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
        for (let i = 0; i < files.length; i++) {
          uploadParams.file = files[i];
          container.upload = this.uploadFile(uploadParams).subscribe(event => {
              if (event.type === HttpEventType.UploadProgress) {
                const percentDone = Math.round(100 * event.loaded / event.total);
                console.log(`File is ${percentDone}% loaded.`);
              } else if (event instanceof HttpResponse) {
                console.log('File is completely loaded!');
                waitParams.result.push(event.body);
                waitParams.downCount--;
              }
            },
            (err) => {
              console.log('Upload Error:', err);
              progressModal.close();
            }, () => {
              console.log('Upload done');
              progressModal.close();
            }
          );
        }

        this.waitForUpload(waitParams);
      }
    });
  }

  // file from event.target.files[0]
  uploadFile(uploadParams): Observable<HttpEvent<any>> {

    const formData = new FormData();
    formData.append('file', uploadParams.file);

    const params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest(uploadParams.method, uploadParams.url, formData, options);
    return this.http.request(req);
  }

  private waitForUpload(params) {
    setTimeout(() => {
      params.maxWaits--;
      if (params.downCount && params.maxWaits) {
        this.waitForUpload(params);
      } else {
        if (params.downCount) {
          console.warn('Waited too long. Stopping now');
          params.container.progress = 1;
        }
        params.resolve(params.result);
      }
    }, 100);
  }

}
