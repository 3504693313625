import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CmsAuthService} from './cms-auth.service';
import {FieldItem, ObjectView, ObjectViewAndData} from './object-view';


@Injectable({
  providedIn: 'root'
})
export class CmsApiService {

  constructor(private http: HttpClient, private auth: CmsAuthService) {
  }

  private cmsApiPath = '/cms_api/v1.0/';
  private loginFn = null;
  private errHandler = null;

  private static log(text, data?) {
    let outText = text;
    if (window.console) {
      if (data) {
        outText += ': ' + JSON.stringify(data);
      }
      console.log(outText);
    }
  }

  private static getUrl(httpParams) {
    let url = httpParams.url;
    if (httpParams.params) {
      let prefix = '?';
      for (const key in httpParams.params) {
        if (httpParams.params.hasOwnProperty(key)) {
          const value = httpParams.params[key];
          if (value !== undefined) {
            url += prefix + key + '=' + value;
            prefix = '&';
          }
        }
      }
    }
    return url;
  }

  init(errHandler, loginFn?) {
    this.errHandler = errHandler;
    this.loginFn = loginFn;
  }

  getApiUrl(call_name) {
    let origin = window.location.origin || window.location.protocol + '//' + window.location.host;
    if (!window.location.href.indexOf('file')) {
      origin = 'https://primus2-cur.mid.no';
    }
    return origin + this.cmsApiPath + call_name;
  }

  eventSave(data) {
    return new Promise((resolve, reject) => {
      if (!data) {
        resolve();
      }

      this.execAjax(
        'eventdata',
        {
          withCredentials: false,
          contentType: 'application/json',
          type: 'POST',
          data: data
        },
        () => {
          resolve({});
        },
        () => {
          reject({});
        }
      );
    });
  }


  login(params) {
    return new Promise((resolve, reject) => {
      this.auth.setAuth(params.username, params.password,
        params.rememberMe);
      this.execApi('login',
        {
          async: false,
          authRequired: true,
          suppressErrHandler: true,
          clientMsg: 'TRANS__ERROR__LOGGING_IN'
        },
        (data) => {
          if (data.status !== 200) {
            this.auth.clearAuth();
          }
          resolve(data);
        },
        (response) => {
          this.auth.clearAuth();
          if (reject) {
            reject(response);
          }
        }
      );

    });
  }

  loginWithStoredAuth(params) {
    return new Promise((resolve, reject) => {
      this.auth.useStoredAuth(params.username, params.rememberMe);
      this.execApi('login', {
          async: false,
          authRequired: true,
          clientMsg: 'TRANS__ERROR__LOGGING_IN'
        },
        (data) => {
          if (data.status !== 200) {
            this.auth.clearAuth();
          }
          resolve(data);
        },
        (response) => {
          this.auth.clearAuth();
          if (reject) {
            reject(response);
          }
        }
      );
    });
  }

  logout(params?) {
    return new Promise((resolve, reject) => {
      this.execApi('logout',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__LOG_OUT'
        },
        (response) => {
          resolve(response);
          // let auth = new Auth();
          this.auth.clearAuth();
        },
        (response) => {
          reject(response);
          // TODO: The logout call fails for unknown
          // reason. Adding this.auth.clearAuth() until we are
          // able to figure out why logout fails.
          this.auth.clearAuth();
        }
      );
    });
  }

  getSettings(params?) {
    return new Promise((resolve, reject) => {
      this.execApi('setting/settings',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_SETTINGS'
        },
        resolve, reject);
    });
  }

  getSetting(params) {
    return new Promise((resolve, reject) => {
      this.execApi('setting/setting/' + params.key, null, resolve, reject);
    });
  }

  getReportSettings() {
    return new Promise((resolve, reject) => {
      this.execApi('artifact/get/SETTINGS-1', {}, resolve, reject);
    });
  }

  getUserData(params?) {
    return new Promise((resolve, reject) => {
      const myParams = params || {};
      this.execApi('setting/user_data',
        {
          clientMsg: 'TRANS__ERROR__GETTING_USER_DATA',
          suppressErrHandler: myParams.suppressErrHandler
        },
        resolve, reject);
    });
  }

  getObjectMenus(params) {
    return new Promise((resolve, reject) => {
      this.execApi('meta/object_menus/' + params.objectType,
        {
          data: {
            parent_type: params.parentType,
            collection_id: params.collectionId,
            status: params.status
          },
          clientMsg: 'TRANS__ERROR__GETTING_OBJECT_MENUS'
        }, resolve, reject
      );
    });
  }

  getReportMenusAdminEvent(params) {
    return new Promise((resolve, reject) => {
      this.execApi('meta/report_menus_admin_event/' + params.objectType,
        {
          data: {
            collection_id: params.collectionId
          },
          clientMsg: 'TRANS__ERROR__GETTING_REPORT_MENUS'
        },
        resolve, reject);
    });
  }

  getContentMenus(params): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.execApi('meta/content_menus/' + params.objectType,
        {
          data: {
            collection_id: params.collectionId
          },
          clientMsg: 'TRANS__ERROR__GETTING_CONTENT_MENUS'
        },
        resolve, reject);
    });
  }

  getMainMenu() {
    return new Promise((resolve, reject) => {
      this.execApi('meta/main_menu',
        {clientMsg: 'TRANS__ERROR__GETTING_MAIN_MENU'},
        resolve, reject);
    });
  }

  getAdminPageMenus(params) {
    return new Promise((resolve, reject) => {
      this.execApi('meta/admin_page_menus/' + params.adminType,
        {clientMsg: 'TRANS__ERROR__GETTING_ADMIN_PAGE_MENUS'},
        resolve, reject);
    });
  }

  getEditionFilter(params) {
    return new Promise((resolve, reject) => {
      this.execApi('meta/edition_filter', {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_EDITION_FILTER',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  /*
  params: meta_type_ids, list of meta type ids used for restricting
   the list of artifact types to be returned (optional)
   */
  getArtifactTypes(params) {
    return new Promise((resolve, reject) => {
      this.execApi('meta/artifact_types',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_ARTIFACT_TYPES'
        }, resolve, reject);
    });
  }

  /*
   params: artifact_id: artifact id
   include_meta: set to true to include object references
   load_events: set to true to include belonging admin events
   */
  getArtifact(params) {
    return new Promise((resolve, reject) => {
      if (params.include_meta !== false) {
        params.include_meta = true;
      }
      this.execApi('artifact/get/' + params.artifact_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_ARTIFACT',
          transValues: {objectId: params.artifact_id}
        }, resolve, reject);
    });
  }

  getArtifactView(params): Promise<ObjectView> {
    return new Promise(((resolve, reject) => {
      this.execApi('artifact/view/' + params.artifact_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_ARTIFACT_VIEW',
          transValues: {objectId: params.artifact_id}
        }, resolve, reject);
    }));
  }

  getArtifactViewAndData(params): Promise<ObjectViewAndData> {
    return new Promise(((resolve, reject) => {
      this.execApi('artifact/view_and_data/' + params.artifact_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_ARTIFACT_VIEW_AND_DATA',
          transValues: {objectId: params.artifact_id}
        }, resolve, reject);
    }));
  }

  /**
   * Get fields value items for a specified object and a specified field (field type must be object_reference)
   *
   * @param params
   * @param params.artifact_id the id of the object from which to get data
   * @param params.fieldName the name of the field from which to get data
   */
  getObjectFieldItems(params): Promise<Array<FieldItem>> {
    return new Promise(((resolve, reject) => {
      this.execApi('artifact/object_field_items/' + params.artifact_id + ',' + params.fieldName,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_OBJECT_FIELD_ITEMS',
          transValues: {objectId: params.artifact_id}
        }, resolve, reject);
    }));
  }

  /**
   * @param params
   * @param params.artifact_id, id of artifact to copy
   * @param params.template_group_id, id of template group to use as model template for the copy
   * @param params.include_meta, set to "true" in order to retrieve meta information from copy
   */
  copyArtifact(params) {
    return new Promise((resolve, reject) => {
      this.execApi('artifact/copy/' + params.artifact_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__COPYING_ARTIFACT',
          transValues: {objectId: params.artifact_id}
        }, resolve, reject);
    });
  }

  /*
   params:
   artifact_ids: array of artifact ids
   include_meta: set to true to include object references
   load_events: set to true to include belonging admin events
   */
  getArtifacts(params) {
    return new Promise((resolve, reject) => {
      this.execApi('artifact/artifacts', {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_ARTIFACTS',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  /*
   params:
   artifact_id: artifact id
   lang: language code
   no_sections: omit object sections from result (faster)
   no_overview: omit object overview from result (faster)
   overview_type: type of overview to be displayed
   no_primitive_list: image id list displayed as:
    [{id: 'img-1'}, {id: 'img-2'}]
   instead of:
   ['img-1', 'img-2']. (implemented due to issues with Delphi json
   parser)
   */
  getArtifactSimple(params) {
    return new Promise((resolve, reject) => {
      this.execApi('artifact/artifact_simple/' + params.artifact_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_ARTIFACT_SIMPLE',
          transValues: {objectId: params.artifact_id}
        }, resolve, reject);
    });
  }

  /*
   params:
   artifact_ids: list of artifact ids
   lang: language code
   no_sections: omit object sections from result (faster)
   no_overview: omit object overview from result (faster)
   overview_type: type of overview to be displayed
   no_primitive_list: image id list displayed as:
    [{id: 'img-1'}, {id: 'img-2'}]
   instead of:
   ['img-1', 'img-2']. (implemented due to issues with Delphi json
   parser)
   */
  getArtifactsSimple(params) {
    return new Promise((resolve, reject) => {
      this.execApi('artifact/artifacts_simple',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_ARTIFACT_SIMPLE',
          transValues: {objectId: params.artifact_id},
          type: 'POST',
          contentType: 'application/json'
        }, resolve, reject);
    });
  }

  getArtifactImages(params) {
    return new Promise((resolve, reject) => {
      this.execApi('artifact/images/' + params.artifact_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_ARTIFACT_IMAGE',
          transValues: {objectId: params.artifact_id}
        }, resolve, reject);
    });
  }

  getArtifactUsage(params) {
    return new Promise((resolve, reject) => {
      this.execApi('artifact/usage/' + params.artifact_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_ARTIFACT_USAGE',
          transValues: {objectId: params.artifact_id}
        }, resolve, reject);
    });
  }

  saveArtifact(params) {
    return new Promise((resolve, reject) => {
      this.execApi('artifact/put', {
        data: params.artifact,
        clientMsg: 'TRANS__ERROR__SAVING_ARTIFACT',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  saveSubArtifact(params) {
    return new Promise((resolve, reject) => {
      this.execApi('artifact/sub_artifact', {
        data: params.subArtifact,
        clientMsg: 'TRANS__ERROR__SAVING_ARTIFACT',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  saveSubArtifacts(params) {
    return new Promise((resolve, reject) => {
      this.execApi('artifact/sub_artifacts', {
        data: params.subArtifacts,
        clientMsg: 'TRANS__ERROR__SAVING_ARTIFACT',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  deleteArtifact(params) {
    return new Promise((resolve, reject) => {
      this.execApi('artifact/delete/' + params.artifact_id, {
        data: {},
        clientMsg: 'TRANS__ERROR__DELETING_ARTIFACT',
        transValues: {objectId: params.artifact_id},
        type: 'DELETE'
      }, resolve, reject);
    });
  }

  saveConcept(params) {
    return new Promise((resolve, reject) => {
      this.execApi('artifact/concept', {
        data: params.concept,
        clientMsg: 'TRANS__ERROR__SAVING_CONCEPT',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  // getSearchReport(params) {
  //     return new Promise((resolve, reject) => {
  //         this.execApi("report/search", {data: params}, resolve, reject);
  //     });
  // };

  identifierExists(params) {
    return new Promise((resolve, reject) => {
      this.execApi('artifact/identifier_exists/' + params.identifier,
        {
          suppressErrHandler: true,
          clientMsg: 'TRANS__ERROR__CHECKING_EXISTING_IDENTIFIER',
          transValues: {identifier: params.identifier}
        },
        resolve, reject);
    });
  }

  nextIdentifier(params) {
    return new Promise((resolve, reject) => {
      let url = 'artifact/next_identifier/' + params.object_type;
      if (params.context_id) {
        url += ',' + params.context_id;
      }
      this.execApi(url,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__CREATING_IDENTIFIER'
        },
        resolve, reject);
    });
  }

  search(params) {
    return new Promise((resolve, reject) => {
      this.execApi('search/search', {
        data: params,
        clientMsg: 'TRANS__ERROR__SEARCHING'
      }, resolve, reject);
    });
  }

  searchJson(params) {
    return new Promise((resolve, reject) => {
      this.execApi('search/json', {
        data: params,
        clientMsg: 'TRANS__ERROR__SEARCHING',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  getSearchView(params) {
    return new Promise((resolve, reject) => {
      this.execApi('meta/search/view/' + params.viewName,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_SEARCH_VIEW',
          transValues: {viewName: params.viewName},
          type: 'POST',
          contentType: 'application/json'
        }, resolve, reject
      );
    });
  }

  getTranslations(params?) {
    return new Promise((resolve, reject) => {
      this.execApi('translation/translations', {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_TRANSLATIONS'
      }, resolve, reject);
    });
  }

  updateTranslation(params) {
    return new Promise((resolve, reject) => {
      this.execApi('translation/translation',
        {
          data: params, type: 'POST',
          clientMsg: 'TRANS__ERROR__UPDATING_TRANSLATION'
        },
        resolve, reject);
    });
  }

  getFaq(params?) {
    return new Promise((resolve, reject) => {
      this.execApi('translation/faq' + (params ? '/' + params.category : ''),
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_FAQ'
        },
        resolve, reject);
    });
  }

  getToolTip(params): Promise<object> {
    return new Promise((resolve, reject) => {
      this.execApi('translation/tool_tip/' + params.field_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_TOOL_TIP'
        },
        resolve, reject);
    });
  }

  getWhatIsNew(params?) {
    return new Promise((resolve, reject) => {
      this.execApi('translation/what_is_new',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_WHAT_IS_NEW'
        },
        resolve, reject);
    });
  }

  getUpcomingVersion(params?) {
    return new Promise((resolve, reject) => {
      this.execApi('translation/upcoming_version',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_UPCOMING_VERSION'
        },
        resolve, reject);
    });
  }

  getMessage(params?) {
    return new Promise((resolve, reject) => {
      this.execApi('translation/message',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_MESSAGE'
        },
        resolve, reject);
    });
  }

  deleteTranslation(params) {
    return new Promise((resolve, reject) => {
      this.execApi('translation/translation',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__DELETING_TRANSLATION',
          type: 'DELETE'
        },
        resolve, reject
      );
    });
  }

  getVideoPlaybackUrls(params) {
    return new Promise((resolve, reject) => {
      this.execApi('multimedia/video/playback/' + params.videoId,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_VIDEO_PLAYBACK_URL'
        }, resolve, reject);
    });
  }

  getVideoUploadStatus(params) {
    return new Promise((resolve, reject) => {
      this.execApi('multimedia/video/upload/status/' + params.videoId,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_VIDEO_UPLOAD_STATUS'
        }, resolve, reject);
    });
  }

  getModel(params) {
    return new Promise((resolve, reject) => {
      this.execApi('meta/model/' + params.modelName,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_MODEL',
          transValues: {modelName: params.modelName}
        },
        resolve, reject);
    });
  }

  getModels(params) {
    return new Promise((resolve, reject) => {
      this.execApi('meta/models',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_MODELS'
        },
        resolve, reject);
    });
  }

  getModelSections(params) {
    return new Promise((resolve, reject) => {
      this.execApi('meta/model_sections/' + params.object_type,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_MODELS'
        },
        resolve, reject);
    });
  }

  getModelOverviewFields(params) {
    return new Promise((resolve, reject) => {
      this.execApi('meta/model_overview_fields/' + params.modelName,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_OVERVIEW_FIELDS',
          transValues: {modelName: params.modelName}
        }, resolve, reject);
    });
  }

  getModelIcons() {
    return new Promise((resolve, reject) => {
      this.execApi('meta/model_icons',
        {
          clientMsg: 'TRANS__ERROR__GETTING_MODEL_ICONS'
        },
        resolve, reject);
    });
  }

  getNewModelInfo(params) {
    return new Promise((resolve, reject) => {
      this.execApi('meta/new_model_info/' + params.modelName,
        {
          clientMsg: 'TRANS__ERROR__GETTING_NEW_MODEL_INFO',
          transValues: {modelName: params.modelName}
        },
        resolve, reject);
    });
  }

  getModelRelations(params) {
    return new Promise(((resolve, reject) => {
      this.execApi('meta/model_relations/' + params.modelName, {
        clientMsg: 'TRANS__ERROR__GETTING_MODEL_RELATIONS',
        transValues: {modelName: params.modelName}
      }, resolve, reject);
    }));
  }

  getModelUploadInfo(params) {
    return new Promise((resolve, reject) => {
      this.execApi('meta/model_upload_info/' + params.modelName,
        {
          clientMsg: 'TRANS__ERROR__GETTING_MODEL_UPLOAD_INFO'
        },
        resolve, reject);
    });
  }

  getKulturNAVLists(params) {
    return new Promise((resolve, reject) => {
      this.execApi('kulturnav/authorities' +
        (params.listType ? '/' + params.listType : ''),
        {
          clientMsg: 'TRANS__ERROR__GETTING_AUTHORITIES_LIST',
          transValues: {listType: params.listType}
        },
        resolve, reject);
    });
  }

  saveKulturNAVLists(params) {
    return new Promise((resolve, reject) => {
      this.execApi('kulturnav/authorities', {
        clientMsg: 'TRANS__ERROR__SAVING_AUTHORITIES_LIST',
        data: params,
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  searchKulturNav(params): Promise<Array<any>> {
    return new Promise(((resolve, reject) => {
      this.execApi('kulturnav/search/' + params.query, {
        suppressErrHandler: params.suppressErrHandler,
        clientMsg: 'TRANS__ERROR__SEARCHING_KULTURNAV',
        data: params,
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    }));
  }

  importFromKulturNav(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this.execApi('kulturnav/import_entity/' + params.uuid, {
        suppressErrHandler: params.suppressErrHandler,
        clientMsg: 'TRANS__ERROR__IMPORTING_FROM_KULTURNAV'
      }, resolve, reject);
    });
  }

  downloadSitulaDatasets(refresh) {
    return new Promise((resolve, reject) => {
      const url = ((!refresh) ? 'situla/datasets' : 'situla/import_datasets');

      this.execApi(url,
        {
          clientMsg: 'TRANS__ERROR__GETTING_SITULA_DATASETS'
        }, resolve, reject);

    });
  }

  importSitulaDataset(id) {
    return new Promise((resolve, reject) => {
      this.execApi('situla/import_dataset/' + id, {
        type: 'GET'
      }, resolve, reject);
    });
  }

  deleteSitulaDataset(id) {
    return new Promise((resolve, reject) => {
      this.execApi('situla/delete_dataset/' + id, {
        type: 'DELETE'
      }, resolve, reject);
    });
  }

  getObjectStatusTypes() {
    return new Promise((resolve, reject) => {
      this.execApi('meta/object_status_types',
        {
          clientMsg: 'TRANS__ERROR__GETTING_OBJECT_STATUS_TYPES'
        },
        resolve, reject);
    });
  }

  getUserTemplateGroupInfo() {
    return new Promise((resolve, reject) => {
      this.execApi('meta/user_template_group_info',
        {
          clientMsg: 'TRANS__ERROR__GETTING_USER_TEMPLATE_GROUP_INFO'
        },
        resolve, reject);
    });
  }

  getMetaObject(params) {
    return new Promise((resolve, reject) => {
      this.execApi('meta/meta_object/' + params.objectType,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_META_OBJECT',
          transValues: {objectType: params.objectType}
        }, resolve, reject);
    });
  }

  putMetaObject(params) {
    return new Promise((resolve, reject) => {
      this.execApi('meta/meta_object', {
        data: params.object,
        clientMsg: 'TRANS__ERROR__PUTTING_META_OBJECT',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  getUpdateLog(params): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.execApi('artifact/update_log/' + params.contextId,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_UPDATE_LOG',
          transValues: {artifactId: params.contextId}
        }, resolve, reject);
    });
  }

  isAuthenticated(fn) {
    this.auth.getAuth(
      () => {
        fn(true);
      },
      () => {
        fn(false);
      }
    );
  }

  private runRequest(httpParams): Observable<object> {
    const url = CmsApiService.getUrl(httpParams);
    const httpOptions = {headers: httpParams.httpHeaders};
    if (httpParams.method === 'POST') {
      return this.http.post(url, httpParams.data, httpOptions);
    } else if (httpParams.method === 'DELETE') {
      return this.http.delete(url, httpOptions);
    } else {
      return this.http.get(url, httpOptions);
    }
  }

  private execApi(url, settings, resolve, reject) {
    let withCredentials;
    settings = settings || {};
    if (!settings.type) {
      settings.type = 'GET';
    }
    if (!settings.async) {
      settings.async = true;
    }

    withCredentials = settings.authRequired;
    if (withCredentials) {
      this.auth.getAuth(
        (auth) => {
          settings.auth = auth;
          settings.with_credentials = true;
          this.execAjax(url, settings, resolve, reject);
        },
        () => {
          // User not logged inn. Redirect to login page.
          this.logout().then(
            () => {
              if (this.loginFn) {
                this.loginFn(
                  {
                    clientMsg: '' +
                      'TRANS__ERROR__LOGGING_IN'
                  });
              }
            },
            () => {
              throw new Error('Unable to log out!');
            }
          );
        }
      );
    } else {
      this.execAjax(url, settings, resolve, reject);
    }
  }

  private execAjax(url, params, resolve, reject) {
    let startTime;
    let httpHeaders;
    // with_credentials should only be at login
    if (params.with_credentials) {
      httpHeaders = new HttpHeaders({
        'Content-Type': params.contentType || '',
        'Authorization': params.auth.authorization
      });
    } else {
      httpHeaders = new HttpHeaders({
        'Content-Type': params.contentType || ''
      });
    }
    const httpParams = {
      method: params.type,
      url: this.getApiUrl(url),
      cache: false,
      withCredentials: false,
      header: null,
      data: null,
      params: null,
      contentType: params.contentType,
      httpHeaders: httpHeaders
    };
    if (params.data) {
      if ((params.type === 'POST' || params.type === 'PUT') &&
        params.contentType === 'application/json') {
        httpParams.data = params.data;
      } else {
        httpParams.params = params.data;
      }
    }
    startTime = new Date().getTime();
    this.runRequest(httpParams)
      .subscribe(
        (data: any) => {
          let err;
          const endTime = new Date().getTime() - startTime;
          if (endTime > 6000) {
            CmsApiService.log('Warning! Executing ' + url + ' took ' + endTime + ' ms. You need to look into that!');
          }
          if (typeof data === 'string' &&
            (<string>data).indexOf('<!DOCTYPE html>') === 0) {
            err = {
              data: {
                message: 'Probably redirected to login page'
              },
              status: 307
            };
            CmsApiService.log(err.data.message, httpParams);
            if (reject) {
              reject(err);
            }
            this.logout().then(
              () => {
                if (this.loginFn) {
                  this.loginFn(() => {
                    // Try to repeat request after
                    // login
                    this.execAjax(url, params, resolve,
                      reject);
                  });
                }
              },
              () => {
                throw new Error('Unable to log out!');
              }
            );
          } else {
            if (resolve) {
              resolve(data);
            }
          }

        },
        (response: HttpErrorResponse) => {
          const error = response.error;
          const status = response.status;
          CmsApiService.log('Call failed for url ' + url +
            ' with parameters ' + JSON.stringify(httpParams));
          if (status === 422 && error) {
            CmsApiService.log('Validation failed: ' +
              JSON.stringify(error));
          }
          if (!params.suppressErrHandler) {
            this.errHandler(response, params);
          }
          if (reject) {
            reject(response);
          }
        }
      );
  }
}
