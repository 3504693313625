import { Injectable } from '@angular/core';
import {ReporterUtilityService} from './reporter-utility.service';
import {ObjectParserService} from '../core/object-parser.service';

@Injectable({
  providedIn: 'root'
})
export class ReporterPdfConfigHelperService {
  private frontPageTexts = null;
  private settings;


  constructor(private reporterUtility: ReporterUtilityService,
              private objectParser: ObjectParserService) {
    this.reporterUtility.getReportSettings().then((settings) => {
      this.settings = settings;
    });
  }

  /**
   * Default footer function template.
   * @param currentPage
   * @param pageCount
   * @returns {{table: {headerRows: number, widths: string[], body: *[]}, layout: {hLineWidth: layout.hLineWidth, vLineWidth: layout.vLineWidth, hLineColor: layout.hLineColor}, margin: number[]}}
   */
  defaultFooterFnTemplate(currentPage, pageCount) {
    if (currentPage > 1) {
      return {
        table: {
          headerRows: 0,
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: '##created##',
                alignment: 'left',
                style: 'pageFooter'
              },
              {
                text: '##page##' + ' ' + currentPage.toString(),
                alignment: 'right',
                style: 'pageFooter'
              }
            ]
          ]
        },
        layout: {
          hLineWidth: function (i, node) { return 1; },
          vLineWidth: function (i, node) { return 0; },
          hLineColor: function (i, node) { return '#cccccc'; }
        },
        margin: [40, 30, 40, 0]
      };
    }
  }

  /**
   * Object containing the default document definition.
   * @type {
     *  {
     *      pageSize: string,
     *      orientation: string,
     *      includeTOC: boolean,
     *      pageMargins: number[],
     *      header: {
     *          inclInstitutionName: boolean,
     *          inclDateTime: boolean
     *      },
     *      styles: {
     *          pageFooter: {
     *              fontSize: number,
     *              margin: number[],
     *              color: string
     *          },
     *          frontpageHeader: {
     *              fontSize: number,
     *              color: string
     *          },
     *          frontpageDetails: {
     *              fontSize: number,
     *              margin: number[]
     *          },
     *          sectionText: {
     *              fontSize: number,
     *              margin: number[]
     *          },
     *          overviewText: {
     *              fontSize: number,
     *              margin: number[]
     *          }
     *      },
     *      footer: footer
     *  }
     * }
   * @private
   */
  _defaultDocDef = {
    pageSize: 'A4',
    orientation: 'portrait',
    includeTOC: false,
    pageMargins: [40, 60, 40, 60],
    header: {
      inclInstitutionName: true,
      inclDateTime: true
    },
    styles: {
      pageFooter: {
        fontSize: 9,
        margin: [0, 5, 0, 50],
        color: '#999999'
      },
      frontpageHeader: {
        fontSize: 20,
        color: '#7e97ad'
      },
      frontpageDetails: {
        fontSize: 10,
        margin: [0, 0, 0, 5]
      },
      sectionText: {
        fontSize: 9,
        margin: [0, 3, 0, 3]
      },
      overviewText: {
        fontSize: 9,
        margin: [0, 0, 25, 0]
      }
    },
    footer: null,
    info: {},
    content: null,
    _frontpageTexts: null
  };

  /**
   * PDF 3x2 document definition.
   * @param frontPageTexts Object containing the translated texts used in this report type.
   * @param createdHr report created date
   * @returns {
     *  {
     *      pageSize: string,
     *      orientation: string,
     *      includeTOC: boolean,
     *      pageMargins: number[],
     *      header: {
     *          inclInstitutionName: boolean,
     *          inclDateTime: boolean
     *      },
     *      styles: {
     *          pageFooter: {
     *              fontSize: number,
     *              margin: number[],
     *              color: string
     *          },
     *          frontpageHeader: {
     *              fontSize: number,
     *              color: string
     *          },
     *          frontpageDetails: {
     *              fontSize: number,
     *              margin: number[]
     *          },
     *          sectionText: {
     *              fontSize: number,
     *              margin: number[]
     *          },
     *          overviewText: {
     *              fontSize: number,
     *              margin: number[]
     *          }
     *      },
     *      footer: footer
     *   }
     * }
   */
  get3x2DocDef(frontPageTexts, createdHr) {
    this.frontPageTexts = frontPageTexts;

    const docDef = this._defaultDocDef;

    docDef.footer = this.objectParser.fnToString(this.defaultFooterFnTemplate, [
      {
        key: 'created',
        val: this.frontPageTexts.created + ': ' + createdHr
      },
      {key: 'page', val: this.frontPageTexts.page}
    ]);

    const headerFnTemplate = function (currentPage) {
      if (currentPage > 1) {
        return {
          columns: [
            {
              width: 'auto',
              text: '##museumName##',
              fontSize: 8,
              alignment: 'left',
              margin: [0, 5, 0, 0]
            },
            {
              width: '*',
              text: ' '
            },
            {
              image: '##primusLogo##',
              width: 60
            }
          ],
          margin: [40, 30, 40, 0]
        };
      }
    };

    docDef.header = this.objectParser.fnToString(headerFnTemplate, [
      {key: 'museumName', val: this.settings.museumName},
      {key: 'primusLogo', val: this.reporterUtility.getPrimusLogo()}
    ]);

    return docDef;
  }

  /**
   * PFD 2x2 document definition
   * @param frontPageTexts Object containing the translated texts used in this report type.
   * @param createdHr report created date
   * @returns {
     *  {
     *      pageSize: string,
     *      orientation: string,
     *      includeTOC: boolean,
     *      pageMargins: number[],
     *      header: {
     *          inclInstitutionName: boolean,
     *          inclDateTime: boolean
     *      },
     *      styles: {
     *          pageFooter: {
     *             fontSize: number,
     *             margin: number[],
     *             color: string
     *          },
     *          frontpageHeader: {
     *              fontSize: number,
     *              color: string
     *          },
     *          frontpageDetails: {
     *              fontSize: number,
     *              margin: number[]
     *          },
     *          sectionText: {
     *              fontSize: number,
     *              margin: number[]
     *          },
     *          overviewText: {
     *              fontSize: number,
     *              margin: number[]
     *          }
     *      },
     *      footer: footer
     *  }
     * }
   */
  get2x2DocDef(frontPageTexts, createdHr) {
    this.frontPageTexts = frontPageTexts;

    const docDef = this._defaultDocDef;

    docDef.footer = this.objectParser.fnToString(this.defaultFooterFnTemplate, [
      {
        key: 'created',
        val: this.frontPageTexts.created + ': ' + createdHr
      },
      {key: 'page', val: this.frontPageTexts.page}
    ]);

    const headerFnTemplate = function (currentPage) {
      if (currentPage > 1) {
        return {
          columns: [
            {
              width: 'auto',
              text: '##museumName##',
              fontSize: 8,
              alignment: 'left',
              margin: [0, 5, 0, 0]
            },
            {
              width: '*',
              text: ' '
            },
            {
              image: '##primusLogo##',
              width: 60
            }
          ],
          margin: [40, 30, 40, 0]
        };
      }
    };

    docDef.header = this.objectParser.fnToString(headerFnTemplate, [
      {key: 'museumName', val: this.settings.museumName},
      {key: 'primusLogo', val: this.reporterUtility.getPrimusLogo()}
    ]);

    return docDef;
  }

  /**
   * Full object report document definition.
   * @param version
   * @param artifactName
   * @param username
   * @param subject
   * @param keywords
   * @param frontPageTexts
   * @param createdHr
   * @returns
   * {
     *  {
     *      pageSize: string,
     *      orientation: string,
     *      includeTOC: boolean,
     *      pageMargins: number[],
     *      header: {
     *          inclInstitutionName: boolean,
     *          inclDateTime: boolean
     *      },
     *      styles: {
     *          pageFooter: {
     *              fontSize: number,
     *              margin: number[],
     *              color: string
     *          },
     *          frontpageHeader: {
     *              fontSize: number,
     *              color: string
     *          },
     *          frontpageDetails: {
     *              fontSize: number,
     *              margin: number[]
     *          },
     *          sectionText: {
     *              fontSize: number,
     *              margin: number[]
     *          },
     *          overviewText: {
     *              fontSize: number,
     *              margin: number[]
     *          }
     *      },
     *      footer: footer
     *  }
     * }
   */
  getFullReportDocDef(version, artifactName, username, subject, keywords, frontPageTexts, createdHr) {
    this.frontPageTexts = frontPageTexts;

    let headerFnTemplate = function (currentPage) {
      if (currentPage > 1) {
        return {
          columns: [
            {
              width: 'auto',
              text: '##artifactName##',
              fontSize: 8,
              alignment: 'left',
              margin: [0, 5, 0, 0]
            },
            {
              width: '*',
              text: ' '
            },
            {
              image: '##primusLogo##',
              width: 60
            }
          ],
          margin: [40, 30, 40, 0]
        };
      }
    };

    const footerFnTemplate = function (currentPage) {
      if (currentPage > 1) {
        return {
          table: {
            headerRows: 0,
            widths: ['50%', '50%'],
            body: [
              [
                {
                  text: '##created##',
                  alignment: 'left',
                  style: 'pageFooter'
                },
                {
                  text: '##page##' + ' ' + currentPage.toString(),
                  alignment: 'right',
                  style: 'pageFooter'
                }
              ]
            ]
          },
          layout: {
            hLineWidth: function (i, node) { return 1; },
            vLineWidth: function (i, node) { return 0; },
            hLineColor: function (i, node) { return '#cccccc'; }
          },
          margin: [40, 30, 40, 0]
        };
      }
    };

    const docDef = this._defaultDocDef;

    docDef.info = {
      title: 'Full rapport - ' + artifactName,
      author: username,
      subject: subject,
      keywords: keywords,
      producer: version,
      creator: version
    };

    docDef.header = this.objectParser.fnToString(headerFnTemplate, [
      {
        key: 'artifactName',
        val: artifactName
      },
      {key: 'primusLogo', val: this.reporterUtility.getPrimusLogo()}
    ]);

    docDef.footer = this.objectParser.fnToString(footerFnTemplate, [
      {
        key: 'created',
        val: this.frontPageTexts.created + ': ' + createdHr
      },
      {key: 'page', val: this.frontPageTexts.page}
    ]);

    return docDef;
  }

  getImageToTheLeftTable(frontPageTexts, createdHr) {
    this.frontPageTexts = frontPageTexts;

    const docDef = this._defaultDocDef;

    docDef.footer = this.objectParser.fnToString(this.defaultFooterFnTemplate, [
      {
        key: 'created',
        val: this.frontPageTexts.created + ': ' + createdHr
      },
      {key: 'page', val: this.frontPageTexts.page}
    ]);

    const headerFnTemplate = function (currentPage) {
      if (currentPage > 1) {
        return {
          columns: [
            {
              width: 'auto',
              text: '##museumName##',
              fontSize: 8,
              alignment: 'left',
              margin: [0, 5, 0, 0]
            },
            {
              width: '*',
              text: ' '
            },
            {
              image: '##primusLogo##',
              width: 60
            }
          ],
          margin: [40, 30, 40, 0]
        };
      }
    };

    docDef.header = this.objectParser.fnToString(headerFnTemplate, [
      {key: 'museumName', val: this.settings.museumName},
      {key: 'primusLogo', val: this.reporterUtility.getPrimusLogo()}
    ]);

    return docDef;
  }

}
