import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UndoHandlerService {

  constructor() { }

  public resetUndo(arr) {
    if (arr.$$undo) {
      arr.$$undo = [];
    }
  };

  public addUndo(arr, item, index?) {
    let undoList = UndoHandlerService.getUndoList(arr);
    undoList.push({
      item: item,
      index: index
    });
    if (undoList.length > 50) {
      undoList.shift();
    }
  };

  public undo(arr) {
    let undoItem, undoList = UndoHandlerService.getUndoList(arr);
    if (undoList.length > 0) {
      undoItem = undoList.pop();
      if (undoItem.index !== undefined) {
        arr.splice(undoItem.index, 0, undoItem.item);
      } else {
        delete undoItem.item._destroy;
      }
    }
  };

  private static getUndoList(arr) {
    if (!arr.$$undo) {
      arr.$$undo = [];
    }
    return arr.$$undo;
  };

}
