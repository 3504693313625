import {Component, Input, OnInit} from '@angular/core';
import {UIRouterGlobals} from '@uirouter/core';
import {SearchPageService} from '../../object-search/search-page.service';
import {SearchContainer} from '../../object-search/search-container';
import {transition, trigger, useAnimation} from '@angular/animations';
import {slideInFromLeft, slideInToLeft} from '../../shared/animations';

@Component({
  selector: 'app-object-search-menu',
  templateUrl: './object-search-menu.component.html',
  styleUrls: ['./object-search-menu.component.scss'],
  animations: [
    trigger('slideInQueryMenu', [
      transition(':leave', [
        useAnimation(slideInToLeft, {params: {time: '750ms ease-in-out'}}),
      ]),
      transition(':enter', [
        useAnimation(slideInFromLeft, {params: {time: '750ms ease'}}),
      ]),
    ])
  ]
})
export class ObjectSearchMenuComponent implements OnInit {
  @Input() isEditing: boolean;
  @Input() stylingClass: string;
  @Input() object;

  rootObjId: string;
  defaultSearchContainer: SearchContainer;
  isSearch = false;
  setSearchQueryFocus = false;
  newLayout = true;

  constructor(private searchPageService: SearchPageService,
              private uiRouterGlobals: UIRouterGlobals) {
  }

  ngOnInit() {
    const stateParams = this.uiRouterGlobals.params;
    this.rootObjId = stateParams.rootObjId || stateParams.parentId;
    console.log('Init object search menu');

    this.searchPageService.checkCreateSearchContainer().then(searchContainer => {
      this.defaultSearchContainer = searchContainer;
    });
  }

  onToggleSearch(isSearch) {
    this.isSearch = isSearch;
  }

}
