import {Injectable} from '@angular/core';
import {CmsApiService} from '../../core/cms-api.service';
import {SearchHandlerService} from '../../object-search/search-handler.service';
import {CommonsService} from '../../core/commons.service';
import {ReportMainTypes, ReportSettings} from './report-settings';
// import {AlertDialogComponent} from '../../shared/alert-dialog/alert-dialog.component';
import {ProgressDialogComponent} from '../../shared/progress-dialog/progress-dialog.component';
import {ActionMenuService} from '../../core/action-menu.service';
import {saveAs} from 'file-saver';
import {MatDialog} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class ReportMenuService {

  modalContent = 'TRANS__MODAL_ALERT__REPORT_ERROR';
  modalTitle = 'TRANS__MODAL_ALERT__UNKNOWN_EXCEPTION';

  constructor(private cms: CmsApiService,
              private searchHandler: SearchHandlerService,
              private commons: CommonsService,
              private modalService: MatDialog,
              private actionMenuService: ActionMenuService) {
  }

  reportSettings: ReportSettings;

  setReportScope(reportSettings) {
    this.reportSettings = reportSettings;
    this.reportSettings.id = this.commons.uuid();
  }

  getReportMenusAdminEvent(): Promise<Array<any>> {
    return new Promise(resolve => {
      if (!(!this.reportSettings.isSearchView || this.reportSettings.metaType === 'artifact' || this.reportSettings.objectType)) {
        resolve([]);
      }
      const params = {
        collectionId: this.reportSettings.collectionId,
        objectType: this.reportSettings.objectType || 'all'
      };
      this.cms.getReportMenusAdminEvent(params).then(
        (menus) => {
          resolve(<Array<any>>menus);
        },
        (response) => {
          resolve([]);
          console.error('Unable to retrieve report menus:' +
            response.status + ': ' + response.message);
        }
      );
    });
  }

  getMakeSearchReportFn(searchContainer) {
      if (searchContainer.selected.length > 0 ||
        searchContainer.allSelected) {
        this.searchHandler.getSearchParams(searchContainer, false).then(
          (searchParams) => {
            this.setReportLocation(searchContainer, this.getRelevantSearchParamsOnly(searchParams));
          }
        );
      }
  }

  getReportParams() {
    let params, reportType;
    if (this.reportSettings.reportTypeSelected) {
      reportType = this.reportSettings.reportTypeSelected.type;
    } else {
      console.log('No report type selected');
    }
    switch (this.reportSettings.mainType) {
      case ReportMainTypes.adminEvent:
      case ReportMainTypes.adminEventSearch:
        params = {
          report_type: reportType,
          title_page: this.reportSettings.titlePage,
          toc: this.reportSettings.toc,
          single_sided: this.reportSettings.singleSided,
          large_images: this.reportSettings.largeImages,
          report_title: this.reportSettings.title,
          full: this.reportSettings.fullReport.set,
          event: this.selectedEvents()
        };
        break;
      case ReportMainTypes.artifact:
        params = {
          report_type: reportType,
          title_page: this.reportSettings.titlePage,
          toc: this.reportSettings.toc,
          single_sided: this.reportSettings.singleSided,
          large_images: this.reportSettings.largeImages,
          report_title: this.reportSettings.title,
          full: this.reportSettings.fullReport.set,
          event: this.selectedEvents()
        };
        break;
      case ReportMainTypes.simple:
        params = {
          report_type: reportType,
          title_page: this.reportSettings.titlePage,
          toc: this.reportSettings.toc,
          single_sided: this.reportSettings.singleSided,
          large_images: this.reportSettings.largeImages,
          report_title: this.reportSettings.title,
          full: this.reportSettings.fullReport.set,
          event: []
        };
        break;
      case ReportMainTypes.searchViewAll:
        params = {
          report_type: reportType,
          title_page: this.reportSettings.titlePage,
          toc: this.reportSettings.toc,
          single_sided: this.reportSettings.singleSided,
          large_images: this.reportSettings.largeImages,
          report_title: this.reportSettings.title,
          full: this.reportSettings.fullReport.set,
          event: []
        };
        break;
      default:
        console.warn('Unknown report mainType ' + this.reportSettings.mainType);
    }
    return params;
  }

  /**
   * Displays a spinner while creating and downloading the report.
   * @param params
   * @param artifactId
   * @param url
   */
  downloadWithSpinner(params, artifactId?, url?) {
    // Show spinner
    const progressModal = this.modalService.open(ProgressDialogComponent,
      {
        disableClose: true,
        panelClass: 'progress-modal'
      });

    const filename = 'report.' + params.report_type;
    const mimeType = (params.report_type === 'docx' ? 'application/msword' : 'application/pdf');

    if (!url) {
      if (artifactId) {
        params.artifact_id = [artifactId];
      }
      const urlBuilder = this.commons.UrlBuilder('report/search');
      urlBuilder.object2url(params);
      url = urlBuilder.getUrl();
    }

    const self = this;
    const req = new XMLHttpRequest();
    req.responseType = 'arraybuffer';

    req.onreadystatechange = function () {
      if (this.readyState === 4 && this.statusText === 'OK') {
        const blob = new Blob([this.response], {type: mimeType});
        saveAs(blob, filename);
        self.endReport(self.actionMenuService, progressModal);
      } else if (this.readyState === 4) {
        console.error(this.statusText);
        progressModal.close();
        self.endReport(self.actionMenuService, progressModal);
      }
    };

    req.open('GET', url);
    req.send();
  }

  /**
   * Close the actionbar at the bottom of the screen, and remove the progress dialog
   * when the report has been created and downloaded.
   * @param actionMenuService
   * @param progressModal
   */
  private endReport(actionMenuService, progressModal) {
    if (actionMenuService) {
      actionMenuService.cancelActionMenu();
    }
    progressModal.close();
  }


private selectedEvents() {
    const res = [];

    this.reportSettings.reportAdminEvents.forEach((event) => {
      if (event.selected) {
        res.push(event.name);
      }
    });

    return res;
  }

  /**
   * Called when report creation is initiated from the search page.
   * @param searchContainer
   * @param searchParams
   */
  private setReportLocation(searchContainer, searchParams) {
    const params = this.getReportParams();
    const urlBuilder = this.commons.UrlBuilder('report/search');
    urlBuilder.object2url(params);

    if (searchContainer.allSelected) {
      urlBuilder.valName2url('all_selected',
        searchContainer.allSelected);
      urlBuilder.array2url('-artifact_id',
        searchContainer.selected);
    } else {
      urlBuilder.array2url('artifact_id',
        searchContainer.selected);
    }
    urlBuilder.object2url(searchParams);

    this.downloadWithSpinner(params, null, urlBuilder.getUrl());
  }

  private getRelevantSearchParamsOnly(searchParams) {
    const relevant = {
      facets: false,
      facet_range_groups: false,
      filters: true,
      fq: true,
      include_meta: false,
      query: true,
      rows: false,
      sort: true,
      start: false
    };
    const res = {};
    this.commons.each(searchParams, (name, val) => {
      if (relevant[name] === true) {
        res[name] = val;
      }
      if (relevant[name] === undefined) {
        console.warn('Search parameter \'' + name + '\' unknown.');
      }
    });
    return res;
  }

}
