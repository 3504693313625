import {Injectable} from '@angular/core';
import {AConst} from '../core/a-const.enum';
import {CmsApiService} from '../core/cms-api.service';
import {SearchHandlerService} from '../object-search/search-handler.service';
import {ContentListSource} from './content-list-source';
import {ContentInfo} from '../core/content-info.service';

@Injectable({
  providedIn: 'root'
})
export class ContentMenusService {

  constructor(private cms: CmsApiService,
              private searchHandler: SearchHandlerService) {
  }

  getMenus(contentInfo: ContentInfo): Promise<Array<any>> {
    return new Promise(resolve => {
      this.cms.getContentMenus({
        objectType: contentInfo.artifact[AConst.OBJECT_TYPE],
        collectionId: contentInfo.artifact[AConst.COLLECTION_ID]
      }).then(
        data => {
          let menus = [];
          if (Array.isArray(data)) {
            menus = <any[]>data;
          }
          if (menus.length > 0) {
            menus[0].isContentView = true;
          }
          resolve(menus);
        },
        response => {
          console.warn('Unable to load content menus: ' + response.status + ': ' + response.message);
        }
      );
    });
  }

  setContentMenus(menus, contentInfo, $stateParams, noListSearch) {
    contentInfo.searchContainers = {};
    this.loopSetContentMenus(menus, contentInfo, $stateParams, noListSearch);
    this.setActiveTopMenu(menus, contentInfo);
  }

  setActiveTopMenu(menus, contentInfo) {
    if (contentInfo.curListName) {
      menus.forEach(menu => {
        let found = false;
        menu.open = false;
        if (menu[AConst.SUB_MENUS]) {
          menu[AConst.SUB_MENUS].forEach(sm => {
              sm.open = false;
              if (sm[AConst.CONTENT_LIST] ===
                contentInfo.curListName) {
                menu.open = true;
                sm.open = true;
                found = true;
                contentInfo.selectedMainMenu = menu;
              }
            }
          );
        } else {
          if (menu[AConst.CONTENT_LIST] === contentInfo.curListName) {
            menu.open = true;
            contentInfo.selectedMainMenu = menu;
          }
        }
      });
    }
  }

  getMenuFromListName(listName, contentInfo) {
    const res = this.loopGetMenuFromListName(contentInfo.menus, listName);
    if (!res) {
      console.warn('Unable to retrieve menu from list name ' + listName);
    }
    return res;
  }

  runListSearch(listName, contentInfo) {
    return new Promise(resolve => {
      const sc = contentInfo.searchContainers[listName];
      if (sc) {
        sc.noFullSearch = contentInfo.curListName !== listName;
        this.searchHandler.runSearch(sc).then(res => {
          this.setMenuSearchResult(res[AConst.SEARCH_COUNT], listName, contentInfo);
          resolve();
        });
      } else {
        this.setMenuSearchResult(0, listName, contentInfo);
        resolve();
      }
    });
  }

  private loopSetContentMenus(menus, contentInfo, $stateParams, noListSearch) {
    menus.forEach(menu => {
      this.setContentMenu(menu, contentInfo, $stateParams, noListSearch);
      if (menu[AConst.SUB_MENUS]) {
        this.loopSetContentMenus(menu[AConst.SUB_MENUS], contentInfo, $stateParams, noListSearch);
      }
    });
  }

  private setContentMenu(menu, contentInfo, $stateParams, noListSearch) {
    const listName = menu[AConst.CONTENT_LIST];
    if (menu[AConst.SEARCH_VIEW]) {
      this.getListSearchContainer(menu, contentInfo, $stateParams).then(
        listSC => {
          contentInfo.searchContainers[listName] = listSC;
          if (!noListSearch) {
            this.runListSearch(listName, contentInfo).then();
          }
        });
    } else if (menu[AConst.SOURCE_ARRAY]) {
      const listMenu = this.getMenuFromListName(listName, contentInfo);
      const sourceArray = contentInfo.artifact[menu[AConst.SOURCE_ARRAY]];
      contentInfo.sources = contentInfo.sources || {};
      contentInfo.sources[listName] = new ContentListSource(sourceArray, menu[AConst.MAIN_FIELD], contentInfo.artifact);
      if (listMenu) {
        listMenu.count = sourceArray ? sourceArray.length : 0;
      }
      contentInfo.contentListSource = contentInfo.curListName === listName ? contentInfo.sources[listName] : contentInfo.contentListSource;
    }
  }

  private getListSearchContainer(menu, contentInfo, $stateParams) {
    return new Promise(resolve => {
      this.getSearchFilters(menu, contentInfo.artifact).then(filters => {
        const searchView = menu[AConst.SEARCH_VIEW];
        menu.count = 0;
        if (filters || menu['use_join']) {
          this.searchHandler.createSearchContainer(
            {
              filters: filters,
              rows: {'content-list': 5},
              searchViewName: searchView,
              stateParams: $stateParams,
              targetObject: contentInfo.artifact,
              templateGroupId: $stateParams.template_group_id
            }).then(
            function (sc) {
              resolve(sc);
            }
          );
        } else {
          resolve(null);
        }
      });
    });
  }

  private loopGetMenuFromListName(menus, listName) {
    let t, menu, res;
    for (t = 0; t < menus.length; t++) {
      menu = menus[t];
      if (menu[AConst.CONTENT_LIST] === listName) {
        res = menu;
        break;
      }
      if (menu[AConst.SUB_MENUS]) {
        res = this.loopGetMenuFromListName(menu[AConst.SUB_MENUS], listName);
        if (res) {
          break;
        }
      }
    }
    return res;
  }

  private setMenuSearchResult(count, listName, contentInfo) {
    const menu = this.getMenuFromListName(listName, contentInfo);
    if (menu) {
      menu.count = count;
      this.setRecursiveMenuCount(contentInfo.menus);
    }
    if (contentInfo.curListName === listName) {
      contentInfo.curListSearchContainer = contentInfo.searchContainers[listName];
    }
  }

  private getRecursiveMenuCount(menu) {
    if (menu[AConst.SUB_MENUS]) {
      menu.count = 0;
      menu[AConst.SUB_MENUS].forEach(sm => {
        menu.count += this.getRecursiveMenuCount(sm);
      });
    }
    return menu.count;
  }

  private setRecursiveMenuCount(menus) {
    menus.forEach(menu => {
      menu.count = this.getRecursiveMenuCount(menu);
    });
  }

  // In some cases, need to run a "pre search" in order to set search filters, due to the relevant information being
  // indexed within separate search documents instead of within the "parent" document, e.g. for attachments usage.
  private getFiltersFromPreSearch(menu, artifact): Promise<any> {
    return new Promise(resolve => {
      const preFilters = {};
      menu[AConst.PRE_SEARCH].forEach(preSearchFilter => {
        const filterPropName = preSearchFilter[AConst.FILTER_PROP_NAME];
        const filterPropValueSourceProp = preSearchFilter[AConst.FILTER_PROP_VALUE_SOURCE_PROP];
        const filterPropValue = preSearchFilter[AConst.FILTER_PROP_VALUE];
        if (filterPropValueSourceProp) {
          preFilters[filterPropName] = artifact[filterPropValueSourceProp];
        } else if (filterPropValue) {
          preFilters[filterPropName] = filterPropValue;
        }
      });
      this.cms.searchJson({filters: preFilters}).then(
        preSearchRes => {
          if (preSearchRes[AConst.ARTIFACTS] && preSearchRes[AConst.ARTIFACTS].length) {
            const preSearchObjects = preSearchRes[AConst.ARTIFACTS];
            const filters = {};
            if (preSearchObjects) {
              preSearchObjects.forEach(preSearchObj => {
                const filter = this.getSearchFiltersFromObject(menu, preSearchObj);
                for (const key in filter) {
                  if (filter.hasOwnProperty(key)) {
                    const value = filter[key];
                    if (!filters[key]) {
                      filters[key] = [value];
                    } else {
                      filters[key].push(value);
                    }
                  }
                }
              });
            }
            resolve(filters);
          } else {
            console.warn('Pre search did not return a result');
            resolve();
          }
        },
        reason => {
          console.error('Pre search failed: ' + reason.error.message);
          resolve();
        });

    });
  }

  private getSearchFilters(menu, artifact) {
    return new Promise(resolve => {
      if (menu[AConst.PRE_SEARCH]) {
        this.getFiltersFromPreSearch(menu, artifact).then(filters => {
          resolve(filters);
        });
      } else {
        resolve(this.getSearchFiltersFromObject(menu, artifact));
      }
    });
  }

  private getSearchFiltersFromObject(menu, object) {
    const filterProps = menu[AConst.FILTER_PROPS] || [];
    let res = null, requiredMissing = false;
    filterProps.forEach(filterProp => {
      const propVal = this.getFilterPropValue(filterProp, object);
      if (propVal && propVal.length > 0) {
        res = res || {};
        let filterPropName = this.getFilterPropName(filterProp, object);
        if (filterProp['filter_type']) {
          filterPropName = filterProp['filter_type'] + ':' + filterPropName;
        }
        res[filterPropName] = propVal;
      } else {
        if (filterProp[AConst.ARTIFACT_PROP_REQUIRED]) {
          requiredMissing = true;
        }
      }
    });
    if (requiredMissing) {
      res = null;
    }
    return res;
  }

  private getFilterPropValue(filterProp, artifact) {
    const artPropName = <string>filterProp[AConst.ARTIFACT_PROP_NAME];
    let res = filterProp[AConst.FILTER_PROP_VALUE];
    let propSplit, mod;

    if (artPropName) {
      propSplit = artPropName.split('.');
      mod = artifact;
      propSplit.forEach(propName => {
        if (mod) {
          if (Array.isArray(mod)) {
            res = [];
            mod.forEach(arrItem => {
              res.push(arrItem[propName]);
            });
          } else {
            res = mod[propName];
            if (!res) {
              console.warn('Object does not contain property "' + propName + '"');
            }
          }
          mod = res;
        }
      });
    }
    return res;
  }

  private getFilterPropName(filterPropInfo, artifact) {
    const propNameInfo = <object>filterPropInfo[AConst.FILTER_PROP_NAME_INFO];
    let elseProp;
    let res = propNameInfo[AConst.FILTER_PROP_NAME];
    if (Object.keys(propNameInfo).length > 1) {
      let test;
      for (const key in propNameInfo) {
        if (propNameInfo.hasOwnProperty(key)) {
          const value = propNameInfo[key];
          if (key === 'if_clause') {
            test = this.checkFilterPropNameEquals(value, artifact);
          } else if (key === 'else_value') {
            elseProp = value;
          }
        }
      }
      if (!test) {
        if (elseProp) {
          res = elseProp;
        } else {
          throw new Error('\'else_value\' attribute missing');
        }
      }
    } else {

    }
    if (!res) {
      console.warn('No valid filter prop name found');
    }
    return res;
  }

  private checkFilterPropNameEquals(value: string, artifact) {
    let res = false;
    const values = value.split(' or ');
    values.forEach((valuePair: string) => {
      const split = valuePair.split('==');
      if (!res) {
        const testProp = split[0];
        const testValue = split[1];
        res = artifact[testProp] === testValue;
      }
    });
    return res;
  }
}
