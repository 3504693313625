import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QueryParserService {

  constructor() {
  }

  parse(query, addWildCards?) {
    let t, spc = '', qSplit, res = '', last;
    addWildCards = addWildCards !== undefined ? addWildCards : true;
    if (query) {
      const qTrim = query.trim();
      const hasQuotes = ['"', '\''].indexOf(qTrim.substring(qTrim.length - 1)) !== -1;
      qSplit = this.cleanSplit(query);
      if (qSplit.length > 0) {
        for (t = 0; t < qSplit.length - 1; t++) {
          res += spc + qSplit[t];
          spc = ' ';
        }
        last = this.clean(qSplit[qSplit.length - 1]);
        res += spc + last;
        res = res.trim();
        if (!hasQuotes && addWildCards) {
          res += '*';
        }
      }
      res = res.trim();
      if (addWildCards && res.split(' ').length === 1 && !hasQuotes) {
        res = '*' + res;
      }
    } else if (addWildCards) {
      res = '*';
    }
    return res;
  }

  private cleanSplit(query) {
    const res = [];
    const qSplit = query.split(/[\s\/()\-+:«»;?,."]/g);
    qSplit.forEach((str) => {
      if (str !== '' && str !== '.' && str !== ' ') {
        res.push(str);
      }
    });
    return res;
  }

  private clean(query: string) {
    let res = query, dotSplit, rep: string;
    rep = query.replace(/[ ]+$/, '');
    if (rep.indexOf('.') !== -1) {
      dotSplit = rep.split('.');
      dotSplit.forEach((str) => {
        res += ' OR ' + str;
      });
    }
    res = res.replace(/[\^\[\]{}?]/g, '');
    res = res.trim();
    return res;
  }

}
