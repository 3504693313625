import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material';

import {CmsApiService} from '../../core/cms-api.service';
import {AConst} from '../../core/a-const.enum';

@Component({
  selector: 'app-kulturnav-search-dialog',
  templateUrl: './kulturnav-search-dialog.component.html',
  styleUrls: ['./kulturnav-search-dialog.component.scss']
})
export class KulturnavSearchDialogComponent implements OnInit {
  @Input() field;
  @Input() phrase;
  @ViewChild('searchDialogInput', { static: true }) searchDialogInput: ElementRef;
  @ViewChild('optionContainer', { static: true }) optionContainer: ElementRef;

  AConst = AConst;
  searchRes = [];
  selected: any = {};
  added = {};
  error = null;
  searching = false;
  imported = [];
  searchOptions = null;
  selectedOptionValue = null;
  selectedRow = -1;
  lastPage;
  rowHeight = 26;

  constructor(public dialogRef: MatDialogRef<KulturnavSearchDialogComponent>,
              private cms: CmsApiService) {
  }

  ngOnInit() {
    this.setSearchOptions();
    this.runSearch(this.phrase.trim(), true);
    setTimeout(() => {
      this.searchDialogInput.nativeElement.focus();
    }, 800);

  }

  runSearch(phrase, init?) {
    if (init || phrase !== this.phrase) { // Prevents refresh when clicking shift, ctrl etc.
      this.error = null;
      this.selected = {};
      if (phrase && this.validPhrase(phrase)) {
        this.searching = true;
        this.cms.searchKulturNav(this.createSearchParams(phrase)).then(
          res => {
            this.searching = false;
            if (res !== null) {
              this.searchRes = res;
            } else {
              this.error = 'TRANS__KULTURNAV_SEARCH__KULTURNAV_OFFLINE';
            }
          },
          reason => {
            this.error = reason.message;
            this.searching = false;
          }
        );
      } else {
        this.searchRes = [];
      }
      this.phrase = phrase;
    }
  }

  keyEventInputField(event: any) {
    switch (event.key) {
      case 'Enter':
        this.runSearch(event.target.value.trim());
        break;
      case 'ArrowDown':
        if (this.selectedRow === -1) {
          this.selectedRow = 0;
        }
        this.focusSelectSearchItem(this.selectedRow);
        event.preventDefault();
        break;
      case 'Escape':
      case 'Esc':
        this.dialogRef.close(false);
        break;
    }
  }

  searchResListKey(event: any, item, index) {
    switch (event.key) {
      case 'Enter':
        this.addItem(item);
        break;
      case 'ArrowUp':
        if (this.selectedRow > 0) {
          this.selectedRow--;
          this.focusSelectSearchItem(this.selectedRow);

        } else {
          this.focusSelectSearchItem(index, true);
        }
        break;
      case 'ArrowDown':
        this.selectedRow++;
        this.focusSelectSearchItem(this.selectedRow);
        break;
    }
    event.preventDefault();
    event.stopPropagation();
  }

  clickItem(item) {
    if (this.selected.uuid !== item.uuid) {
      this.selectItem(item);
    } else {
      this.addItem(item);
    }
  }

  selectItem(item) {
    this.error = null;
    this.selected = item;
  }

  getAddedList() {
    return Object.values(this.added);
  }

  removeFromAdded(item) {
    delete this.added[item.uuid];
  }

  importItems() {
    const items = this.getAddedList();
    let count = items.length;
    this.error = null;
    items.forEach(item => {
      this.cms.importFromKulturNav({uuid: item['uuid'], suppressErrHandler: true}).then(
        res => {
          if (res[AConst.ARTIFACT_ID]) {
            this.imported.push(res);
            this.removeFromAdded(item);
            count--;
            if (!count) {
              this.dialogRef.close(this.imported);
            }
          } else {
            if (res && res.message) {
              this.error = res.message;
            } else {
              this.error = 'TRANS__KULTURNAV_SEARCH__IMPORT_FAILED';
            }
          }
        },
        reason => {
          this.error = reason.message;
        }
      );
    });
  }

  selectOption(event) {
    this.selectedOptionValue = event.target.value;
    const phrase = this.phrase;
    this.phrase = null;
    this.runSearch(phrase);
  }

  scrollWithKey(rows) {
    const container = this.optionContainer.nativeElement;
    let scrollToRow, containerHeight, page;
    if (container) {
      containerHeight = container.offsetHeight;
      page = Math.ceil(rows / containerHeight);

      if (page === 1) {
        container.scrollTop = 0;
      } else if (page !== this.lastPage) { // scroll only on page
        if (page > this.lastPage) { // increase on keydown
          scrollToRow = rows - this.rowHeight;
        } else {
          scrollToRow = rows - 182; // decrease on keyup
        }
        container.scrollTop = scrollToRow;
      }
      this.lastPage = page;
    }
  }

  private getKulturNavParams() {
    let kulturnavParams;
    if (this.field[AConst.REFERENCE]) {
      kulturnavParams = this.field[AConst.REFERENCE][AConst.KULTURNAV_PARAMS];
      if (!kulturnavParams) {
        console.warn('Missing KulturNav parameters');
      }
    } else {
      console.warn('Missing reference data');
    }
    return kulturnavParams;
  }

  private setSearchOptions() {
    const kulturNavParams = this.getKulturNavParams();
    for (const key of Object.keys(kulturNavParams)) {
      const value = kulturNavParams[key];
      if (typeof value === 'object' && value[AConst.PARAM_TYPE] === 'select') {
        this.searchOptions = value[AConst.OPTIONS];
        this.searchOptions.forEach(opt => {
          if (opt.selected) {
            this.selectedOptionValue = opt.value;
          }
        });
      }
    }
  }

  private addItem(item) {
    this.added[item.uuid] = item;
    item.selected = true;
  }

  private focusSelectSearchItem(index, unfocused?) {
    let rows;
    const goToElement = document.getElementById('searchItem-' + index);
    if (goToElement) {
      if (!unfocused) {
        goToElement.focus();
        rows = (index + 1) * this.rowHeight;
        this.scrollWithKey(rows);
      } else {
        this.selectedRow = -1;
        goToElement.blur();
        this.searchDialogInput.nativeElement.focus();
      }
    }
  }

  private createSearchParams(phrase) {
    const params = {
      query: phrase,
      suppressErrHandler: true
    };
    const kulturNavParams = this.getKulturNavParams();
    if (kulturNavParams) {
      for (const key of Object.keys(kulturNavParams)) {
        const value = kulturNavParams[key];
        if (typeof value === 'object' && value[AConst.PARAM_TYPE] === 'select') {
          params[key] = this.selectedOptionValue;
        } else {
          params[key] = value;

        }
      }
    }
    return params;
  }

  private validPhrase(searchText) {
    let res = true;
    const illegalCharacters = ['#', '%', '/', '\\', '?'];
    illegalCharacters.forEach(illegalCharacter => {
      if (searchText.indexOf(illegalCharacter) !== -1) {
        res = false;
        this.error = 'TRANS__KULTURNAV_SEARCH__ILLEGAL_CHARACTER_IN_QUERY';
      }
    });
    return res;
  }
}
