import {Component, OnInit} from '@angular/core';
import {StateService, UIRouterGlobals} from '@uirouter/core';
import {SearchHandlerService} from '../../object-search/search-handler.service';
import {CmsApiService} from '../../core/cms-api.service';
import {UiToolsService} from '../../core/ui-tools.service';
import {SearchContainer} from '../../object-search/search-container';


@Component({
  selector: 'app-admin-concept-list-overview-page',
  templateUrl: './admin-concept-list-overview-page.component.html',
  styleUrls: ['./admin-concept-list-overview-page.component.scss']
})
export class AdminConceptListOverviewPageComponent implements OnInit {

  loading = true;
  parent;
  conceptsSearchContainer: SearchContainer;
  private adminType = 'concepts';

  constructor(private stateService: StateService,
              private uiRouterGlobals: UIRouterGlobals,
              private searchHandler: SearchHandlerService,
              private cms: CmsApiService,
              private uiTools: UiToolsService) {
  }

  ngOnInit() {
    this.parent = {
      path: 'home.primus.admin.adminPage',
      adminType: this.adminType,
      subMenus: true,
      target_params: {admin_type: this.adminType},
      currentState: this.stateService.$current.name
    };

    this.cms.getAdminPageMenus({adminType: this.adminType}).then(
      data => {
        this.parent.menus = data['menus'][0];
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
    if (this.uiRouterGlobals.params.path === 'home') {
      this.stateService.go('home.primus.admin.adminPage', {admin_type: this.adminType});
    } else {
      const path = this.uiRouterGlobals.params.path;
      const currentChildPath = path.split('%2F')[1];
      this.parent.currentChildPath = currentChildPath;
      this.searchHandler.createSearchContainer({
        searchViewName: 'concepts',
        targetState: 'home.primus.admin.overviewConcepts',
        toState: this.stateService.current.name,
        stateParams: this.uiRouterGlobals.params,
        runSearch: true
      }).then(
        sc => {
          sc.windowSize = this.uiTools.windowSize;
          this.conceptsSearchContainer = sc;
        }
      );
    }
  }

}
