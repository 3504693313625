import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'applyWordBreakOpportunity'
})
export class ApplyWordBreakOpportunityPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let re = /([\\;/_\-»])(?![^<]*>|[^<>]*<\/)/gm;
    let subst = '$1<wbr>';
    let maxLengthWithoutBreak = 25;

    if (typeof value !== "string") {
      return value;
    }

    if (value.length > maxLengthWithoutBreak &&
      !value.match(/([\\;/_\-»\s])/gm)) {
      return value.substr(0, maxLengthWithoutBreak) + '<wbr>' +
        value.substr(maxLengthWithoutBreak);
    }

    if (value && value.replace !== undefined) {
      return value.replace(re, subst);
      //return $sce.trustAsHtml(value.replace(re, subst));
    }
  }

}
