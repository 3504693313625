import {Injectable} from '@angular/core';
import {StateService, TransitionService, UIRouterGlobals} from '@uirouter/core';
import {CommonsService} from './commons.service';
import {LoginService} from './login.service';
import {CurrentObjectService} from './current-object.service';
import {CmsAuthService} from './cms-auth.service';
import {ChangeTrackerService} from './change-tracker.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TransitionHandlerService {

  constructor(private translate: TranslateService,
              private transitionService: TransitionService,
              private commons: CommonsService,
              private loginService: LoginService,
              private currentObjectService: CurrentObjectService,
              private uiRouterGlobals: UIRouterGlobals,
              private auth: CmsAuthService,
              private stateService: StateService,
              private changeTracker: ChangeTrackerService) {
  }

  initTransitionHandler() {
    console.log('Init transition handler');
    this.transitionService.onStart({to: 'home.primus.*'}, trans => {
      return this.commonTransHandler(trans);
    });
    this.transitionService.onStart({to: 'home.primus.admin.*'}, trans => {
      return this.commonTransHandler(trans);
    });
    this.transitionService.onStart({to: 'home.primus.admin.adminSettings.*'}, trans => {
      return this.commonTransHandler(trans);
    });
    this.transitionService.onStart({to: 'home.primus.search.*'}, trans => {
      return this.commonTransHandler(trans);
    });
  }

  private commonTransHandler(trans) {
    if (this.checkCancel()) {
      return false;
    }

    this.setMissingRootStuff();
    console.log('Set missing root stuff');

    trans.promise.finally(() => {
      console.log('transHandlerPostOperations');
      this.transHandlerPostOperations(trans);
    });

    return true;
  }

  private setMissingRootStuff() {
    this.commons.getStatusTypeIds();
  }

  private transHandlerPostOperations(trans) {
    const toState = trans._targetState._definition;
    const toParams = trans._targetState._params;

    if (toParams.debug) {
      this.loginService.setDebug(true);
    }

    switch (toState.name) {
      case 'home.primus.gallery':
      case 'home.primus.videoGallery':
      case 'home.primus.search.gallery':
      case 'home.primus.search.videoGallery':
        this.currentObjectService.className = 'body-image-gallery';
        break;
      case 'home.primus.search.annotate':
        this.currentObjectService.className = 'body-image-annotate';
        break;
      case 'home.primus.search':
      case 'home.primus.admin.overviewConcepts':
        this.currentObjectService.className = 'search-page';
        break;
      case 'home':
        this.currentObjectService.className = 'home';
        break;
      case 'home.primus.admin':
      case 'home.primus.about':
      case 'home.primus.admin.adminPage':
      case 'home.primus.admin.adminSettings':
      case 'home.primus.admin.adminSettings.edit':
      case 'home.primus.admin.templateGroup':
        this.currentObjectService.className = 'admin';
        break;
      case 'home.primus.faq':
        this.currentObjectService.className = 'faq-page';
        break;
      case 'home.primus.admin.importKulturNAV':
        this.currentObjectService.className = 'admin-list-editMode';
        break;
      case 'home.primus.artifact':
      case 'home.primus.search.artifact':
      case 'home.primus.admin.templateModel':
        this.currentObjectService.className = 'artifact';
        break;
      default:
        console.log('this.currentObjectService.className set to state name ' + toState.name);
        this.currentObjectService.className = toState.name;
    }
    this.currentObjectService.isEditing = this.uiRouterGlobals.params.edit === 'true' || toState.name === 'home.primus.artifactNew';
    if (this.currentObjectService.isEditing && toState.name !== 'home.primus.search.annotate') {
      this.currentObjectService.className = 'edit-mode';
    }

    this.auth.getAuth(
      () => {

      },
      () => {
        if (toState.name !== 'home') {
          this.loginService.setLoginDestinationState(toState, toParams);
          setTimeout(() => {
            this.stateService.go('home', {
              debug: this.loginService.isDebug()
            });
          }, 1000);
        }
      }
    );
    if (toState.name === 'home') {
      // Hard-coding login info for now
    }
  }

  private checkCancel() {
    let res = false, curObj;
    if (this.currentObjectService.isEditing) {
      curObj = this.currentObjectService.currentObject;
      if (curObj) {
        if (this.changeTracker.objectHasChanges(curObj)) {
          res = !window.confirm(this.translate.instant('TRANS__MODAL_DIALOG__ARE_YOU_SURE'));
        }
      } else {
        console.warn('No current object available for change check');
      }

    }
    return res;
  }

}
