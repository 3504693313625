import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-object-usage-dialog',
  templateUrl: './object-usage-dialog.component.html',
  styleUrls: ['./object-usage-dialog.component.scss']
})
export class ObjectUsageDialogComponent implements OnInit {

  @Input() objects: Array<any>;
  @Input() hasUsageMessage: string;

  constructor(public dialogRef: MatDialogRef<ObjectUsageDialogComponent>) {
  }

  ngOnInit() {
  }

}
