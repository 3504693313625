import {Injectable} from '@angular/core';
import {StateService, UIRouterGlobals} from '@uirouter/core';
import {FieldValueService} from '../core/field-value.service';
import {CmsApiService} from '../core/cms-api.service';
import {CommonsService} from '../core/commons.service';
import {DateToolsService} from '../core/date-tools.service';
import {ModelFactoryService} from '../core/model-factory.service';
import {ObjectFieldTraverseService} from '../core/object-field-traverse.service';
import {ObjectStorageService} from '../core/object-storage.service';
import {AConst} from '../core/a-const.enum';
import {ObjectEditService} from '../core/object-edit.service';
import {ActionMenuContainer} from '../core/action-menu-container';
import {UploadInfo} from './upload-info';
import {UploadMediaContainer} from '../core/upload-media-container';

@Injectable({
  providedIn: 'root'
})
export class ObjectMenuService {

  constructor(private stateService: StateService,
              private uiRouterGlobals: UIRouterGlobals,
              private fieldValueHandler: FieldValueService,
              private cms: CmsApiService,
              private commons: CommonsService,
              private dateTools: DateToolsService,
              private modelFactory: ModelFactoryService,
              private objectEditService: ObjectEditService,
              private objectFieldTraverseService: ObjectFieldTraverseService,
              private objectStorage: ObjectStorageService) {
  }

  private menuActions = {
    setScopeTarget: (scope, menuContainer: ActionMenuContainer, art, action, menu, fn, data) => {
      const mod = action[AConst.TARGET_MODEL];

      if (mod) {
        const item = this.modelFactory.createModelItem(mod, data);
        this.setScopeTarget(scope, item, mod);
        this.setArtProp(art, action, item);
        this.setMenuObjectData(menu, item, !!data, false);
        if (fn) {
          fn(item);
        }
      }

    },
    /* appendArray is used for adding array elements into model
     objects.
     * */
    appendArray: (scope, menuContainer: ActionMenuContainer, art, action, menu, fn) => {
      const target = this.getTarget(scope, art, action, menu);
      const targetArrName = action[AConst.ARRAY_NAME];
      const traverseRes = this.objectFieldTraverseService.traverseObjectByPath(target, targetArrName, 1);
      const arrayMeta = traverseRes.subObject[AConst.$$META][traverseRes.fieldName];
      const modelName = arrayMeta.inline[AConst.MODEL];
      const item = this.modelFactory.createAddArrayItem(target, traverseRes.subObject[traverseRes.fieldName], modelName);

      if (fn) {
        fn(item);
      }
    },
    concatArray: (scope, menuContainer: ActionMenuContainer, art, action, menu, fn) => {
      const target = this.getTarget(scope, art, action, menu);
      const sourceProps = this.getSourceProps(scope, art, action, menu);

      this.commons.each(sourceProps, (propName, propVal) => {
        target[propName] = target[propName].concat(propVal);
      });
      if (fn) {
        fn(target);
      }
    },
    setProperty: (scope, menuContainer: ActionMenuContainer, art, action, menu, fn) => {
      const target = this.getTarget(scope, art, action, menu);
      if (!target) {
        console.error('Target not found for action');
        return;
      }
      const sourceProps = this.getSourceProps(scope, art, action, menu);
      const targetArrName = action[AConst.TARGET_ARRAY];
      const targetArrMod = action[AConst.TARGET_ARRAY_MODEL];

      if (targetArrName && !target[targetArrName]) {
        target[targetArrName] = [];
      }
      this.commons.each(sourceProps, (propName, propVal) => {
        if (targetArrName) {
          if (!Array.isArray(propVal)) {
            propVal = [propVal];
          }
          propVal.forEach(val => {
            let item = {};
            item[action[AConst.PROPERTY_NAME]] = val;
            if (targetArrMod) {
              item = this.modelFactory.createModelItem(targetArrMod, item);
            }
            target[targetArrName].push(item);
          });
        } else {
          this.fieldValueHandler.setFieldValue(target, propName, propVal);
        }
      });

      if (fn) {
        fn(target);
      }
    },
    copyArtifact: (scope, menuContainer: ActionMenuContainer, art, action, menu, fn) => {
      const model = action[AConst.TARGET_MODEL];
      this.objectEditService.copyObjectGetSectionsContainer(art[AConst.ARTIFACT_ID], menu.templateGroupId, model).then(
        sectionsContainer => {
          menu.sectionsContainer = sectionsContainer;
          menu.obj = sectionsContainer.rootObject;
          menu.obj.$$firstStep = false;
          menu.menuSaveObject = sectionsContainer.rootObject;
          if (fn) {
            fn(sectionsContainer.rootObject);
          }
        }
      );
    },
    saveClose: (scope, menuContainer: ActionMenuContainer, art, action, menu, fn) => {
      scope.menuContainer.saveCloseMenu(scope, menuContainer);
      this.setMenuStuff(scope, [menu]);
      if (fn) {
        fn();
      }
    },
    nextIdentifier: (scope, menuContainer: ActionMenuContainer, art, action, menu, fn) => {
      const sourceProps = this.getSourceProps(scope, art, action, menu);
      const contextId = sourceProps[action[AConst.PROPERTY_NAME]];
      this.cms.nextIdentifier({
        object_type: action[AConst.TARGET_MODEL],
        context_id: contextId
      }).then(res => {
        const target = this.getTarget(scope, art, action, menu);
        target[action[AConst.PROPERTY_NAME]] = res[AConst.IDENTIFIER];
        if (fn) {
          fn(target);
        }
      });
    }
  };

  openActionMenu(scope, menuContainer: ActionMenuContainer, viewName, menu, menuGroup?) {
    if (menuGroup) {
      menuGroup.skipToggle = false;
    }
    if (!menu.disabled) {
      menu.showMenu = !menu.showMenu;

      if (menu[AConst.MENU_REF_PARAMS]) {
        this.stateService.go(this.stateService.current, menu[AConst.MENU_REF_PARAMS], {location: 'replace', reload: true});
      } else if (menu[AConst.ACTIONS] || menu.view) {
        this.openMenuAsync(scope, menuContainer, menu);
        if (menuGroup) {
          menuGroup.showMenu = false;
        }
      } else if (viewName === 'object-menus-small' &&
        menu[AConst.SUB_MENUS] && menuGroup) {
        this.showSubMenus(menu, menuGroup);
      }
    }
  }

  toggleMenuGroup(scope, menuContainer: ActionMenuContainer, viewName, menuGroup) {
    let showMenu;
    if (!menuGroup.skipToggle) {
      showMenu = !menuGroup.showMenu;
      if (!menuGroup[AConst.SUB_MENUS]) {
        this.clearShowMenus(menuContainer, menuContainer.menus);
        this.openActionMenu(scope, menuContainer, viewName, menuGroup);
      } else {
        this.clearShowMenus(menuContainer, menuContainer.menus);
        menuGroup.showMenu = showMenu;
        menuContainer.currentMenuGroup = menuGroup;
        if (menuGroup.showMenu) {
          window.addEventListener('mouseup', event => {
            this.checkCloseMenuGroupCallback(menuContainer, event);
          }, true);
        } else {
          window.removeEventListener('mouseup', event => {
            this.checkCloseMenuGroupCallback(menuContainer, event);
          }, true);
        }
      }
    }
    menuGroup.skipToggle = false;
  }

  runActions(scope, menuContainer: ActionMenuContainer, art, menu, action_type, fn?) {
    const actions = menu[action_type] ? menu[action_type] : [];
    let actionFn, t, action, res;

    this.checkCancelActions(art, menu, action_type);

    for (t = 0; t < actions.length; t++) {
      action = actions[t];
      actionFn = this.getMenuAction(action);
      if (actionFn) {
        res = actionFn(scope, menuContainer, art, action, menu, fn);
      }
    }
    return res;
  }

  setMenuStuff(scope, menus) {
    this.checkSetDisabledMenus(scope, menus);
  }

  setMenuObjectData(menu, object, nextStep, isCopy) {
    if (!menu[AConst.ONE_STEP] && !nextStep) {
      this.objectEditService.getSectionsContainerForObjectPrimeFields(object).then(res => {
        menu.sectionsContainer = res;
        menu.sectionsContainer.isCopy = isCopy;
        menu.obj = object;
        menu.obj.$$firstStep = true;
        menu.menuSaveObject = object;
      });
    } else {
      this.objectEditService.getSectionsContainerForObject(object, menu.templateGroupId, isCopy).then(res => {
        menu.sectionsContainer = res;
        menu.obj = object;
        menu.obj.$$firstStep = false;
        menu.menuSaveObject = object;
      });
    }
  }

  setMenuObject(scope, menuContainer: ActionMenuContainer, object) {
    menuContainer.currentMenu.menuSaveObject = object;
    const sourceArt = scope.art || menuContainer.art;
    this.runMenuActionsOnExistingObject(scope, menuContainer, sourceArt, object, menuContainer.currentMenu, () => {
    });
  }

  initMenuContainer(scope, menuContainer: ActionMenuContainer) {
    const mc: ActionMenuContainer = menuContainer;
    let status;
    const parentType = mc.parentId ? this.commons.getObjectTypeFromObjectId(mc.parentId) : null;

    mc.saveCloseMenu = () => {
      this.saveCloseMenu(scope, mc);
    };
    mc.actionsFirstStep = [
      {
        containerClass: 'register-button-container',
        buttonClass: 'register-button',
        type: 'register',
        fn: (closeAfterSaveFn) => {
          this.saveMenuObject(menuContainer, closeAfterSaveFn);
        },
        buttonText: 'TRANS__ADMIN_EVENT__REGISTER_AND_CONTINUE',
        helperBoxTextOpenSelector: 'TRANS__ADMIN_EVENT__HELP_TEXT'
      },
      {
        buttonClass: 'cancel-button',
        fn: (fn) => {
          this.cancelActionMenu(scope, menuContainer, fn);
        },
        buttonText: 'TRANS__ADMIN_EVENT__CANCEL'
      }
    ];
    mc.getActionsStoreMenuObject = () => {
      return [
        {
          containerClass: 'register-button-container',
          buttonClass: 'register-button',
          type: 'register',
          fn: () => {
            this.saveCloseMenu(scope, mc);
          },
          buttonText: mc.currentMenu[AConst.SAVE_BUTTON_TITLE] || 'TRANS__ADMIN_EVENT__REGISTER',
          helperBoxTextOpenSelector: 'TRANS__ADMIN_EVENT__HELP_TEXT'
        },
        {
          buttonClass: 'cancel-button',
          fn: (fn) => {
            this.cancelActionMenu(scope, menuContainer, fn);
          },
          buttonText: 'TRANS__ADMIN_EVENT__CANCEL_DELETE'
        }
      ];
    };
    mc.getActionsStoreMenuArray = () => {
      return [
        {
          containerClass: 'register-button-container',
          buttonClass: 'register-button',
          type: 'register',
          fn: () => {
            this.saveCloseMenu(scope, mc);
          },
          buttonText: mc.currentMenu[AConst.SAVE_BUTTON_TITLE] || 'TRANS__ADMIN_EVENT__REGISTER',
          helperBoxText: 'TRANS__EDIT__STORE_ARTIFACT__HELP_TEXT',
          helperBoxTextOpenSelector: 'TRANS__EDIT__STORE_ARTIFACT__HELP_TEXT'
        },
        {
          buttonClass: 'cancel-button',
          fn: (fn) => {
            this.cancelActionMenu(scope, menuContainer, fn);
          },
          buttonText: 'TRANS__ADMIN_EVENT__CANCEL'
        }
      ];
    };
    mc.actionsReport = [
      {
        containerClass: 'register-button-container',
        buttonClass: 'register-button',
        type: 'register',
        fn: mc.makeReportFn,
        buttonText: 'TRANS__REPORT_MENU__MAKE_REPORT_BUTTON'
      },
      {
        buttonClass: 'cancel-button',
        fn: (fn) => {
          this.cancelActionMenu(scope, menuContainer, fn);
        },
        buttonText: 'TRANS__REPORT_MENU__CANCEL_REPORT'
      }
    ];
    // mc.saveMenuObject = saveMenuObject;
    mc.cancelActionMenu = (fn) => {
      this.cancelActionMenu(scope, menuContainer, fn);
    };

    mc.selectorCallback = (selectedObj, selector) => {
      this.selectorCallback(scope, menuContainer, selectedObj, selector);
    };

    status = this.commons.getStatusFromArtifact(menuContainer.art);
    let objectType = menuContainer.art[AConst.OBJECT_TYPE];
    if (objectType === 'concept') {
      objectType = menuContainer.art[AConst.CONCEPT_TYPE_ID];
    }

    this.cms.getObjectMenus({
      objectType: objectType,
      parentType: parentType,
      collectionId: menuContainer.art[AConst.COLLECTION_ID],
      status: status
    }).then(
      objectMenus => {
        menuContainer.menus = objectMenus[AConst.MENUS];
        this.setMenuStuff(scope, objectMenus[AConst.MENUS]);
        this.setMenuView(menuContainer);
      },
      response => {
        console.warn('Unable to load menus: ' + response.status + ': ' + response.message);
      }
    );
  }

  private getTarget(scope, art, action, menu) {
    let res, targetName;
    const targetType = action[AConst.TARGET_TYPE];
    if (targetType) {
      if (targetType === 'art') {
        res = art;
      } else if (targetType === 'menu') {
        res = menu;
      }
    }
    if (res === undefined) {
      targetName = this.getScopeTargetName(action[AConst.TARGET_MODEL]);
      res = scope[targetName];
    }
    return res;
  }

  private getScopeTargetName(modelName) {
    return modelName + 'Target';
  }

  private setScopeTarget(scope, item, modelName) {
    const scopeTargetName = this.getScopeTargetName(modelName);
    scope[scopeTargetName] = item;
  }

  private setArtProp(art, action, prop) {
    if (action[AConst.ART_TARGET]) {
      if (!Array.isArray(art[action[AConst.ART_TARGET]])) {
        art[action[AConst.ART_TARGET]] = prop;
      } else {
        art[action[AConst.ART_TARGET]].push(prop);
      }
    }
  }

  private getProp(propName, source) {
    let res, t;
    if (!Array.isArray(propName)) {
      // res = angular.copy(source[propName]);
      res = this.fieldValueHandler.getFieldValue(source, propName);
    } else {
      res = [];
      for (t = 0; t < propName.length; t++) {
        const value = this.commons.copy(this.fieldValueHandler.getFieldValue(source, propName[t]));
        if (value !== undefined && value !== null) {
          if (!Array.isArray(value)) {
            res.push(value);
          } else {
            res = res.concat(value);
          }
        }
      }
    }
    if (res === undefined) {
      throw new Error('Property \'' + propName + '\' not found in source');
    }
    return res;
  }

  private checkSetDisabledMenus(scope, menus) {
    menus.forEach(menu => {
      let subMenus;
      const disableCheck = menu[AConst.DISABLE_CHECK];
      if (disableCheck) {
        if (this.fieldValueHandler.fieldIf(scope.art, disableCheck.field, '==', disableCheck.value)) {
          menu.disabled = true;
        }
      }
      subMenus = menu[AConst.SUB_MENUS];
      if (subMenus) {
        this.checkSetDisabledMenus(scope, subMenus);
      }
    });
  }

  private getSourceProps(scope, art, action, menu) {
    const psv = action[AConst.SOURCE_VALUE];
    const res = {}, propName = action[AConst.PROPERTY_NAME];
    const sourceType = action[AConst.SOURCE_TYPE];

    switch (sourceType) {
      case 'art':
        res[propName] = this.getProp(psv, art);
        break;
      case 'scope':
        res[propName] = this.getProp(psv, scope);
        break;
      case 'menu':
        res[propName] = this.getProp(psv, menu);
        break;
      case 'menuContainer':
        res[propName] = this.getProp(psv, scope.menuContainer);
        break;
      case 'precisionDate':
        const dateProp = propName + '.dd_date';
        const precisionProp = propName + '.dd_precision';
        res[dateProp] = this.dateTools.getTodayUtcTime();
        res[precisionProp] = 'date';
        break;
      case 'constant':
        res[propName] = psv;
        break;
      default:
        throw new Error('Unknown property type ' + sourceType);
    }
    return res;
  }

  // If artTarget set in actions and user selects cancel, need to
  // remove the added values to artTarget
  private checkCancelActions(art, menu, actionType) {
    if (actionType === AConst.CANCEL_ACTIONS) {
      if (menu.obj && !menu[AConst.ONE_STEP] && !menu.obj.$$firstStep && menu.obj[AConst.ARTIFACT_ID]) {
        this.objectStorage.deleteObject(menu.obj[AConst.ARTIFACT_ID]).then(() => {
            // console.info('Cleanup successful');
          },
          () => {
            console.warn('Unable to clean up');
          }
        );
      }
      if (menu[AConst.ACTIONS]) {
        menu[AConst.ACTIONS].forEach(action => {
          const artTarget = action[AConst.ART_TARGET];
          if (artTarget) {
            if (Array.isArray(art[artTarget])) {
              art[artTarget].pop();
            } else {
              console.warn('No cancel action defined for \'' + artTarget + '\'');
            }
          }
        });
      }
    }
  }

  private getMenuAction(action) {
    let res;
    switch (action[AConst.ACTION_NAME]) {
      case 'setScopeTarget':
        res = this.menuActions.setScopeTarget;
        break;
      case 'appendArray':
        res = this.menuActions.appendArray;
        break;
      case 'concatArray':
        res = this.menuActions.concatArray;
        break;
      case 'setProperty':
        res = this.menuActions.setProperty;
        break;
      case 'copyArtifact':
        res = this.menuActions.copyArtifact;
        break;
      case 'saveClose':
        res = this.menuActions.saveClose;
        break;
      case 'nextIdentifier':
        res = this.menuActions.nextIdentifier;
        break;
      default:
        console.warn('Unknown menu action ' + action[AConst.ACTION_NAME]);
    }
    return res;
  }

  private runMenuActionsOnExistingObject(scope, menuContainer: ActionMenuContainer, art, item, menu, fn) {

    menu[AConst.ACTIONS].forEach(action => {
      let actionFn;
      const name = action[AConst.ACTION_NAME];

      actionFn = this.menuActions[action[AConst.ACTION_NAME]];
      if (name === 'setScopeTarget') {
        actionFn(scope, menuContainer, art, action, menu, fn, item);
      } else if (name === 'setProperty') {
        actionFn(scope, menuContainer, art, action, menu, fn);
      }
    });
  }

  // Display 3rd level menus (menu group/sub menus/sub menus)
  private showSubMenus(menu, menuGroup) {
    menuGroup.skipToggle = true;
    menuGroup[AConst.SUB_MENUS].forEach(m => {
      if (m[AConst.MENU_TITLE] !== menu[AConst.MENU_TITLE]) {
        m.showMenu = false;
      }
    });
  }

  private openMenuAsync(scope, menuContainer: ActionMenuContainer, menu) {
    this.setCurrentMenu(menuContainer, menu);

    // scope.expandCollapse(menu);
    if (menu[AConst.ACTIONS]) {
      this.runActions(scope, menuContainer, menuContainer.art, menu, 'actions');
    }
  }

  private clearShowMenus(menuContainer: ActionMenuContainer, menus) {
    const curMen = menuContainer.currentMenu;
    if (menus) {
      menus.forEach(m => {
        if (!curMen || m[AConst.MENU_TITLE] !==
          curMen[AConst.MENU_TITLE]) {
          m.showMenu = false;
        }
        this.clearShowMenus(menuContainer, m[AConst.SUB_MENUS]);
      });
    }
  }

  private setCurrentMenu(menuContainer: ActionMenuContainer, menu) {
    menuContainer['currentMenu'] = menu;
  }

  private getCurrentMenu(menuContainer: ActionMenuContainer) {
    return menuContainer.currentMenu;
  }

  private setMenuView(menuContainer: ActionMenuContainer) {
    menuContainer.menus.forEach((menu, key) => {
      if (menu[AConst.SUB_MENUS]) {
        menu[AConst.SUB_MENUS].forEach(subMenu => {
          menuContainer.menus[key].noChildren = subMenu[AConst.SUB_MENUS] === undefined;
        });
      }
    });
  }

  // TODO: The following code will break if actionMenus
  // directive used without artifactCtrl being defined in DOM
  private scrollToTop(delay) {
    setTimeout(() => {
      // TODO: Implement scroller that works in new Angular
      // const artifactCtrlElement = angular.element(document.getElementById('actionMenuContainer'));
      // $document.duScrollToElement(artifactCtrlElement, 50, 750);
    }, delay);
  }

  private cancelActionMenu(scope, menuContainer: ActionMenuContainer, fn) {
    const menu = menuContainer.currentMenu;

    this.runActions(scope, menuContainer, menuContainer.art, menu, 'cancel_actions');
    this.closeActionMenu(menuContainer, fn);
  }

  private closeActionMenu(menuContainer: ActionMenuContainer, fn?) {
    const menu = menuContainer.currentMenu;

    menuContainer.disableSelector();
    menu.showMenu = false;
    this.scrollToTop(0);

    setTimeout(() => {
      this.setCurrentMenu(menuContainer, fn);
      menuContainer['showMenuReport'] = false;
      menuContainer['showMenu'] = false;
      if (fn) {
        setTimeout(() => {
          fn();
        }, 50);
      }
    }, 700);
  }

  // Params are extra params that can be attached to the
  // artifact before submitting to server
  private saveMenuObject(menuContainer: ActionMenuContainer, closeAfterSaveFn) {
    const menu = this.getCurrentMenu(menuContainer);
    if (menu.menuSaveObject || menu[AConst.STORE_PARENT]) {
      this.validateStore(menuContainer, closeAfterSaveFn);
    } else {
      closeAfterSaveFn();
    }
  }

  private saveCloseMenu(scope, menuContainer: ActionMenuContainer) {
    const menu = this.getCurrentMenu(menuContainer);
    this.runActions(scope, menuContainer, menuContainer.art, menu, AConst.CLOSE_ACTIONS);

    const uploadMediaContainers: Array<UploadMediaContainer> = menu['uploadMediaContainers'];
    if (!uploadMediaContainers) {
      menuContainer['showMenu'] = false;
      this.saveMenuObject(menuContainer, () => {
        this.closeAfterSave(menuContainer);
      });
    } else {
      let multipleFiles = false;
      if (uploadMediaContainers.length) {
        multipleFiles = uploadMediaContainers[0].uploadInfo ? uploadMediaContainers[0].uploadInfo.multiple_files : false;
      }
      if (multipleFiles) {
        this.saveMenuObject(menuContainer, storedResult => {
          this.loopUploadMediaContainersStoreFileObjects(menuContainer, storedResult).then(valid => {
            if (valid) {
              menuContainer['showMenu'] = false;
              this.closeAfterSave(menuContainer);
            }
          });
        });
      } else {
        // For Actors and Users, the items must be added to the artifact images array before storing
        this.loopUploadMediaContainersStoreFileObjects(menuContainer).then(valid => {
          if (valid) {
            menuContainer['showMenu'] = false;
            this.saveMenuObject(menuContainer, () => {
              this.closeAfterSave(menuContainer);
            });
          }
        });
      }
    }
  }

  private loopUploadMediaContainersStoreFileObjects(menuContainer: ActionMenuContainer, storedResult?): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const menu = this.getCurrentMenu(menuContainer);
      let res = true;
      const uploadMediaContainers: Array<UploadMediaContainer> = menu['uploadMediaContainers'];
      let downCunt = uploadMediaContainers ? uploadMediaContainers.length : 0;
      if (downCunt) {
        uploadMediaContainers.forEach(uploadMediaContainer => {
          this.storeFileObjects(uploadMediaContainer.fileObjects, (valid, parentObjects) => {
            downCunt--;
            if (valid) {
              const items = this.getItemsFromFiles(uploadMediaContainer, parentObjects, storedResult);
              const uploadInfo: UploadInfo = uploadMediaContainer.uploadInfo;
              if (uploadInfo && uploadInfo.multiple_files) {
                this.saveItems(items, isValid => {
                  if (isValid) {
                  } else {
                    res = false;
                  }
                  if (!downCunt) {
                    resolve(res);
                  }
                });
              } else {
                // For Actors and Users, the items must be added to the artifact images array
                menuContainer.art[AConst.IMAGES] = items;
                if (!downCunt) {
                  resolve(res);
                }
              }
            } else {
              res = false;
              if (!downCunt) {
                resolve(res);
              }
            }
          });
        });
      } else {
        resolve(res);
      }
    });
  }

  // Recursively run through all file objects. Used
  // when uploading files.
  private storeFileObjects(fileObjects, fn) {
    const parentObjects = {};
    if (fileObjects && fileObjects.length > 0) {
      this.storeFileObject(fileObjects, 0, parentObjects, fn);
    } else {
      fn(true);
    }
  }

  private storeFileObject(fileObjects, index, parentObjects, fn) {
    const fileObject = fileObjects[index];
    this.objectEditService.storeObjectShowProgressModal(fileObject, true).then(
      obj => {
        index++;
        // TODO: Due to a bug on the server, need to use file object instead of reloaded object
        // parentObjects[obj[AConst.ARTIFACT_ID]] = obj;
        parentObjects[obj[AConst.ARTIFACT_ID]] = fileObject;
        if (index < fileObjects.length) {
          this.storeFileObject(fileObjects, index, parentObjects, fn);
        } else {
          fn(true, parentObjects);
        }
      },
      reason => {
        console.warn('A file store failed: ' + reason.error.message);
        fn(false);
      }
    );
  }

  private saveItems(items, fn) {
    if (items && items.length) {
      this.saveItem(items, 0, fn);
    } else {
      fn(true);
    }
  }

  private getItemsFromFiles(uploadMediaContainer: UploadMediaContainer, parentObjects, storedResult) {
    const files = uploadMediaContainer.files;
    const res = [];
    if (files) {
      const targetObject = uploadMediaContainer.parentObject;
      const uploadInfo: UploadInfo = uploadMediaContainer.uploadInfo;
      const targetArrayName = uploadInfo.target_array;
      const modelName = uploadInfo.item_model;
      files.forEach((file, index) => {
        const order_number = targetObject[targetArrayName].length ?
          targetObject[targetArrayName].length + index : index;
        const contextId = storedResult ? storedResult[AConst.ARTIFACT_ID] : targetObject[AConst.ARTIFACT_ID];
        const item = this.modelFactory.createModelItem(modelName, {
          context_id: contextId,
          order_number: order_number
        });
        const objectIdField = this.commons.getObjectIdField(item);
        item[objectIdField] = file[AConst.ARTIFACT_ID];
        this.setParentToItemFields(item, uploadInfo, parentObjects[file[AConst.ARTIFACT_ID]]);
        res.push(item);
      });
    }
    return res;
  }

  private saveItem(items, index, fn) {
    this.objectEditService.storeObjectShowProgressModal(items[index]).then(
      res => {
        console.log('Item stored, got id ' + res[AConst.ARTIFACT_ID]);
        index++;
        if (index < items.length) {
          this.saveItem(items, index, fn);
        } else {
          fn(true);
        }
      },
      reason => {
        const msg = 'Save failed with message: ' + reason.error.message + ': ' + reason.status;
        console.warn(msg);
        fn(false);
      });
  }

  private setParentToItemFields(item, uploadInfo: UploadInfo, parentObject) {
    const parentToItemFields = uploadInfo.parent_to_item_fields;
    if (parentToItemFields) {
      parentToItemFields.forEach((fieldName) => {
        item[fieldName] = parentObject[fieldName];
      });
    }
  }

  private closeAfterSave(menuContainer: ActionMenuContainer) {
    const menu = this.getCurrentMenu(menuContainer);
    const listName = menu[AConst.CONTENT_LIST];
    this.closeActionMenu(menuContainer);

    if (listName && menuContainer.contentInfo.curListName !== listName) {
      menuContainer.contentInfo.setCurrentList(listName);
      this.stateService.go(this.stateService.current.name, {listName: listName});
    }

    setTimeout(() => {
      menuContainer.artifactUpdateCallback();
    }, 3000);
  }

  private validateStore(menuContainer: ActionMenuContainer, closeAfterSaveFn) {
    let doubleSave = false;
    const menu = this.getCurrentMenu(menuContainer);
    if (menu[AConst.STORE_PARENT]) {
      if (!menu.menuSaveObject) {
        menu.menuSaveObject = menuContainer.art;
      } else {
        doubleSave = !(menu.obj && menu.obj.$$firstStep);
      }
    }
    if (doubleSave) {
      this.objectEditService.storeObjectShowProgressModal(menuContainer.art).then(
        res => {
          console.log('Stored object, got id ' + res[AConst.ARTIFACT_ID]);
          this.storeMenuObject(menuContainer, closeAfterSaveFn);
        },
        reason => {
          const msg = 'Save failed with message: ' + reason.error.message + ': ' + reason.status;
          console.warn(msg);
        }
      );
      // validateFn(res);
    } else {
      this.storeMenuObject(menuContainer, closeAfterSaveFn);
      // validateFn(res);
    }
  }

  private storeMenuObject(menuContainer: ActionMenuContainer, closeAfterSaveFn) {
    this.objectEditService.storeObjectShowProgressModal(
      this.getCurrentMenu(menuContainer).menuSaveObject, this.canReload(menuContainer)).then(
      result => {
        this.afterStore(menuContainer, result);
        if (closeAfterSaveFn) {
          closeAfterSaveFn(result);
        }
      },
      reason => {
        const msg = 'Save failed with message: ' + reason.error.message + ': ' + reason.status;
        console.warn(msg);
      }
    );
  }

  private canReload(menuContainer: ActionMenuContainer) {
    const reloadObject = this.getCurrentMenu(menuContainer).menuSaveObject[AConst.ARTIFACT_ID] !== undefined;
    if (!reloadObject) {
      console.warn('Object type ' + this.getCurrentMenu(menuContainer).menuSaveObject[AConst.OBJECT_TYPE] +
        ' has no artifact_id field an cannot be reloaded');
    }
    return reloadObject;
  }

  private afterStore(menuContainer: ActionMenuContainer, obj) {
    const menu = this.getCurrentMenu(menuContainer);
    if (menu) {
      menu.templateGroupId = this.uiRouterGlobals.params.template_group_id;
      this.objectEditService.createModelItem(obj[AConst.OBJECT_TYPE], obj).then(
        (item) => {
          this.setMenuObjectData(menu, item, true, false);
        });
    }
  }

  private selectorCallback(scope, menuContainer: ActionMenuContainer, selectedObj, selector) {
    if (selector.action === 'setMenuObject') {
      this.setMenuObject(scope, menuContainer, selectedObj[0]);
    } else {
      menuContainer.selectorCallback(selectedObj);
    }
  }

  private checkCloseMenuGroupCallback(menuContainer: ActionMenuContainer, item) {
    const found = this.commons.findClassNameRecursively(item.target, 'dropdownIgnoreClicks');
    if (!found) {
      setTimeout(() => {
        menuContainer.currentMenuGroup.showMenu = false;
        window.removeEventListener('mouseup', event => {
          this.checkCloseMenuGroupCallback(menuContainer, event);
        }, true);
      }, 100);
    }
  }

}
