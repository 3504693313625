import {FaqPageComponent} from './landing-pages/faq-page/faq-page.component';
import {LoginPageComponent} from './landing-pages/login-page/login-page.component';
import {MainMenuComponent} from './main-menu/main-menu/main-menu.component';
import {ImageFullScreenComponent} from './landing-pages/image-full-screen/image-full-screen.component';
import {SearchPageComponent} from './object-search/search-page/search-page.component';
import {ObjectPageComponent} from './landing-pages/object-page/object-page.component';
import {MediaPageComponent} from './landing-pages/media-page/media-page.component';
import {ImageAnnotationComponent} from './image-annotation/image-annotation/image-annotation.component';
import {AboutPrimusComponent} from './landing-pages/about-primus/about-primus.component';
import {AdminImportAuthoritiesComponent} from './landing-pages/admin-import-authorities/admin-import-authorities.component';
import {AdminMainPageComponent} from './landing-pages/admin-main-page/admin-main-page.component';
import {AdminSettingsComponent} from './landing-pages/admin-settings/admin-settings.component';
import {AdminPageComponent} from './landing-pages/admin-page/admin-page.component';
import {AdminTemplateGroupComponent} from './landing-pages/admin-template-group/admin-template-group.component';
import {AdminTemplateModelComponent} from './landing-pages/admin-template-model/admin-template-model.component';
import {ImportSitulaComponent} from './landing-pages/import-situla/import-situla.component';
// tslint:disable-next-line:max-line-length
import {AdminConceptListOverviewPageComponent} from './landing-pages/admin-concept-list-overview-page/admin-concept-list-overview-page.component';
import {ObjectNewPageComponent} from './landing-pages/object-new-page/object-new-page.component';
import {searchSettingsParams, searchSettingsStringParams} from './object-search/search-state-params';

export const homeState = {
  name: 'home',
  url: '/?debug',
  views: {
    'content@': {
      component: LoginPageComponent
    }
  }
};

export const homePrimusState = {
  // parent: 'home',
  name: 'home.primus',
  url: 'primus/?template_group_id',
  views: {
    'mainMenu@': {
      component: MainMenuComponent
    }
  }
};

export const searchState = {
  // parent: 'home.primus',
  name: 'home.primus.search',
  reloadOnSearch: false,
  url: 'search/' + searchSettingsStringParams,
  params: searchSettingsParams,
  views: {
    'content@': {
      component: SearchPageComponent
    }
  }
};

export const objectPageSearchState = {
  // parent: 'home.primus.search',
  name: 'home.primus.search.artifact',
  edit: false,
  url: 'artifact/?artifactId&contextIds&parentId&listName&edit&rootObjId',
  reloadOnSearch: false,
  views: {
    'content@': {
      component: ObjectPageComponent
    }
  }
};

export const mediaImagePageState = {
  // parent: 'home.primus',
  name: 'home.primus.gallery',
  edit: false,
  url: 'gallery/:mediaId?parentId&contextIds&listName&edit&rootObjId&objectType',
  reloadOnSearch: false,
  views: {
    'content@': {
      component: MediaPageComponent
    }
  }
};

export const mediaImagePageSearchState = {
  // parent: 'home.primus.search',
  name: 'home.primus.search.gallery',
  edit: false,
  url: 'gallery/:mediaId?parentId&contextIds&listName&edit&rootObjId&objectType',
  reloadOnSearch: false,
  views: {
    'content@': {
      component: MediaPageComponent
    }
  }
};

export const mediaVideoPageState = {
  // parent: 'home.primus',
  name: 'home.primus.videoGallery',
  edit: false,
  url: 'video-gallery/:mediaId?parentId&contextIds&listName&edit&rootObjId&objectType',
  reloadOnSearch: false,
  views: {
    'content@': {
      component: MediaPageComponent
    }
  }
};

export const mediaVideoPageSearchState = {
  // parent: 'home.primus.search',
  name: 'home.primus.search.videoGallery',
  edit: false,
  url: 'video-gallery/:mediaId?parentId&contextIds&listName&edit&rootObjId&objectType',
  reloadOnSearch: false,
  views: {
    'content@': {
      component: MediaPageComponent
    }
  }
};

export const faqState = {
  // parent: 'home.primus',
  name: 'home.primus.faq',
  url: 'faq/',
  views: {
    'content@': {
      component: FaqPageComponent
    }
  }
};

export const fullScreenState = {
  // parent: 'home.primus',
  name: 'home.primus.full-screen',
  url: 'full-screen/?imageId',
  views: {
    'content@': {
      component: ImageFullScreenComponent
    }
  }
};

export const annotateState = {
  // parent: 'home.primus',
  name: 'home.primus.annotate',
  edit: false,
  url: 'annotate/?annotationId&imageId&contextIds&parentId&edit&rootObjId',
  views: {
    'content@': {
      component: ImageAnnotationComponent
    }
  }
};

export const annotateSearchState = {
  // parent: 'home.primus.search',
  name: 'home.primus.search.annotate',
  edit: false,
  url: 'annotate/?annotationId&imageId&contextIds&parentId&edit&rootObjId',
  views: {
    'content@': {
      component: ImageAnnotationComponent
    }
  }
};

export const objectPageState = {
  // parent: 'home.primus',
  name: 'home.primus.artifact',
  edit: false,
  url: 'artifact/:artifactId?contextIds&parentId&isNew&listName&edit&rootObjId',
  reloadOnSearch: false,
  views: {
    'content@': {
      component: ObjectPageComponent
    }
  }
};

export const aboutState = {
  // parent: 'home.primus',
  name: 'home.primus.about',
  edit: false,
  url: 'about/',
  views: {
    'content@': {
      component: AboutPrimusComponent
    }
  }
};

export const adminState = {
  // parent: 'home.primus',
  name: 'home.primus.admin',
  url: 'admin',
  views: {
    'content@': {
      component: AdminMainPageComponent
    }
  }
};

export const adminImportAuthoritiesState = {
  // parent: 'home.primus.admin',
  name: 'home.primus.admin.importKulturNAV',
  url: '/importKulturNAV/:typeFilter',
  views: {
    'content@': {
      component: AdminImportAuthoritiesComponent,
    }
  },
  data: {
    role: ['admin']
  }
};

export const adminSettingsState = {
  // parent: 'home.primus.admin',
  name: 'home.primus.admin.adminSettings',
  url: '/:artifactId',
  views: {
    'content@': {
      component: AdminSettingsComponent,
    }
  },
  params: {
    edit: false,
    objectType: 'Settings',
    admin_type: 'settings'
  }
};

export const adminSettingsEditState = {
  // parent: 'home.primus.admin.adminSettings',
  name: 'home.primus.admin.adminSettings.edit',
  url: '/edit/',
  views: {
    'content@': {
      component: AdminSettingsComponent,
    }
  },
  params: {
    edit: true,
    objectType: 'Settings',
    admin_type: 'settings'
  }
};

export const adminPageState = {
  // parent: 'home.primus.admin',
  name: 'home.primus.admin.adminPage',
  url: '/admin-page/:admin_type',
  views: {
    'content@': {
      component: AdminPageComponent
    }
  }
};

export const adminTemplateGroupState = {
  // parent: 'home.primus.admin',
  name: 'home.primus.admin.templateGroup',
  url: '/template-group',
  views: {
    'content@': {
      component: AdminTemplateGroupComponent,
    }
  },
  params: {
    admin_type: 'templateGroup'
  }
};

export const adminTemplateModelState = {
  // parent: 'home.primus.admin',
  name: 'home.primus.admin.templateModel',
  url: '/template-model/:template_group_id?model_type_id',
  views: {
    'content@': {
      component: AdminTemplateModelComponent,
    }
  },
  params: {
    admin_type: 'templateModel'
  }
};

export const adminImportSitulaState = {
  // parent: 'home.primus.admin',
  name: 'home.primus.admin.importSitula',
  url: '/import-situla',
  views: {
    'content@': {
      component: ImportSitulaComponent
    }
  }
};

export const adminConceptListOverviewPageState = {
  // parent: 'home.primus.admin',
  name: 'home.primus.admin.overviewConcepts',
  url: 'overview-concepts/' + searchSettingsStringParams,
  reloadOnSearch: false,
  views: {
    'content@': {
      component: AdminConceptListOverviewPageComponent,
    }
  },
  data: {
    role: ['admin']
  }
};

export const artifactNewPageState = {
  // parent: 'home.primus',
  name: 'home.primus.artifactNew',
  edit: false,
  url: 'new/:objectType',
  views: {
    'content@': {
      component: ObjectNewPageComponent
    }
  }
};

export const APP_STATES = [
  homeState,
  homePrimusState,
  faqState,
  fullScreenState,
  searchState,
  objectPageSearchState,
  mediaImagePageState,
  mediaImagePageSearchState,
  mediaVideoPageState,
  mediaVideoPageSearchState,
  annotateState,
  annotateSearchState,
  objectPageState,
  aboutState,
  adminState,
  adminImportAuthoritiesState,
  adminSettingsState,
  adminSettingsEditState,
  adminPageState,
  adminTemplateGroupState,
  adminTemplateModelState,
  adminImportSitulaState,
  adminConceptListOverviewPageState,
  artifactNewPageState
];

