import {Injectable} from '@angular/core';
import {AConst} from './a-const.enum';
import {CmsApiService} from './cms-api.service';
import {CommonsService} from './commons.service';
import {StateService, UIRouterGlobals} from '@uirouter/core';

@Injectable({
  providedIn: 'root'
})
export class RefService {

  constructor(private cms: CmsApiService,
              private commons: CommonsService,
              private $state: StateService,
              private $stateParams: UIRouterGlobals) {
  }

  public makeUrl(params) {
    const obj = params.object;
    let url = '';
    const refStuff = this.getRefStuff(params);
    switch (refStuff.objectType) {
      case 'Image':
        url = this.cms.getApiUrl('multimedia/image/' + obj.artifact_id + '?size=large');
        break;
      case 'Video':
        url = this.cms.getApiUrl('multimedia/video/' + obj.artifact_id);
        break;
      case 'Attachment':
        url = this.cms.getApiUrl('multimedia/attachment/' + obj.artifact_id);
        break;
      default:
        console.log('Cannot make url for object type \'' + refStuff.objectType + '\'');
    }

    return {
      url: url
    };
  }

  public makeRef(params) {
    let stateName, stateParams, $stateParams, objectCount, refStuff;
    const obj = params.object;
    params.stateParams = params.stateParams || {};
    // The below statement necessary in order to correctly open
    // gallery from media content lists within artifact page

    $stateParams = this.$stateParams.params;
    if ($stateParams.artifactId) {
      params.stateParams.parentId = $stateParams.artifactId;
    }
    if (!params.rootObjId) {
      params.rootObjId = this.$state.params.rootObjId;
    }

    if (!obj) {
      return null;
    }
    refStuff = this.getRefStuff(params);
    stateName = this.getObjectState(refStuff.objectType);

    if (refStuff.metaType === 'sub_model') {
      stateParams = {
        artifactId: obj[AConst.CONTEXT_ID]
      };
      params.rootObjId = obj[AConst.CONTEXT_ID];
    } else if (refStuff.objectType === 'Image') {
      stateParams = {
        mediaId: obj.artifact_id,
        parentId: refStuff.parentId,
        contextIds: refStuff.contextIds,
        size: 'large',
        quality: 90,
        objectType: 'Image'
      };
    } else if (refStuff.objectType === 'Video') {
      stateParams = {
        mediaId: obj.artifact_id,
        parentId: refStuff.parentId,
        contextIds: refStuff.contextIds,
        objectType: 'Video'
      };
    } else if (refStuff.objectType === 'Attachment') {
      stateParams = {
        artifactId: obj.artifact_id,
        parentId: refStuff.parentId,
        contextIds: refStuff.contextIds,
        listName: 'overview'
      };
    } else if (refStuff.objectType === 'AnnotationEvent') {
      stateParams = {
        annotationId: obj.artifact_id,
        imageId: obj.image_id,
        parentId: obj.parent_id,
        contextIds: refStuff.contextIds,
        edit: !obj.artifact_id
      };
    } else if (refStuff.objectType === 'conceptType') {
      stateParams = {
        path: 'home/concepts/' + obj[AConst.ARTIFACT_ID]
      };
    } else if (refStuff.objectType === 'Settings') {
      stateName = 'home.primus.admin.adminSettings';
      stateParams = {
        artifactId: obj.artifact_id,
        listName: 'overview'
      };
    } else if (refStuff.objectType === 'folder') {
      const folderArtifactIdFilter = AConst.FOLDER_ITEMS + '.' + AConst.FOLDER_ARTIFACT_ID;
      objectCount = (obj[folderArtifactIdFilter] && obj[folderArtifactIdFilter].length) || 0;
      stateParams = {
        targetId: obj.id,
        path: 'home/folders/folder',
        objectCount: objectCount,
        search_result_view: 'thumbnail'
      };
    } else if (refStuff.objectType === 'AdmEventContextItem') {
      stateParams = {
        artifactId: refStuff.contextIds[0],
        listName: 'overview'
      };
    } else {
      stateParams = {
        artifactId: obj.artifact_id,
        parentId: refStuff.parentId,
        contextIds: refStuff.contextIds
      };
    }
    if (params.searchItemIndex !== undefined) {
      stateParams.searchItemIndex = params.searchItemIndex;
    }
    if (params.rootObjId) {
      stateParams.rootObjId = params.rootObjId;
    }

    if (params.edit) {
      stateParams.edit = true;
    }
    if (params.isNew) {
      stateParams.isNew = true;
    }
    // Used for prev/next search object functionality
    if (params.stateParams) {
      Object.keys(params.stateParams).forEach(key => {
        if (!stateParams[key]) {
          stateParams[key] = params.stateParams[key];
        }
      });
    }
    return {
      stateName: stateName,
      param: stateParams
    };
  }

  private getObjectState(objectType) {
    let states, res, stateType;
    const artifactStates = {
      regular: 'home.primus.artifact',
      search: 'home.primus.search.artifact'
    };
    const objectStates = {
      Image: {
        regular: 'home.primus.gallery',
        search: 'home.primus.search.gallery'
      },
      Video: {
        regular: 'home.primus.videoGallery',
        search: 'home.primus.search.videoGallery'
      },
      Attachment: artifactStates,
      AnnotationEvent: {
        regular: 'home.primus.annotate',
        search: 'home.primus.search.annotate'
      },
      conceptType: {
        regular: 'home.primus.search',
        search: 'home.primus.search'
      },
      folder: {
        regular: 'home.primus.search',
        search: 'home.primus.search'
      },
      AdmEventContextItem: artifactStates
    };

    if (this.$state.current.name.indexOf('search') !== -1) {
      stateType = 'search';
    } else {
      stateType = 'regular';
    }
    states = objectStates[objectType] || artifactStates;
    res = states[stateType];
    if (!res) {
      throw new Error('No search state defined for \'' + objectType + '\': \'' + stateType + '\'');
    }
    return res;
  }

  private getRefStuff(params) {
    let contextIds, parentId;
    const obj = params.object;
    const objectType = this.commons.getObjectType(obj);
    if (obj.contexts) {
      contextIds = this.commons.getContextIds(obj.contexts);
    } else if (obj[AConst.CONTEXT_ARTIFACT_ID]) {
      contextIds = obj[AConst.CONTEXT_ARTIFACT_ID];
    } else if (params.stateParams && params.stateParams.contextIds) {
      contextIds = params.stateParams.contextIds;
    }
    if (obj.parent_id) {
      parentId = obj.parent_id;
    } else if (params.stateParams && params.stateParams.parentId) {
      parentId = params.stateParams.parentId;
    }
    return {
      parentId: parentId,
      contextIds: contextIds,
      objectType: objectType,
      metaType: obj[AConst.META_TYPE]
    };
  }
}
