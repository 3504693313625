import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {SectionsContainer} from '../../core/sections-container';
import {
  AbstractControl,
  ControlValueAccessor, NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {AConst} from '../../core/a-const.enum';
import { FieldConditionService } from 'src/app/core/field-condition.service';
import {FieldParameters} from '../../core/field-parameters';

@Component({
  selector: 'app-edit-field-precision-date-range',
  templateUrl: './edit-field-precision-date-range.component.html',
  styleUrls: ['./edit-field-precision-date-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldPrecisionDateRangeComponent)
    }
  ]

})
export class EditFieldPrecisionDateRangeComponent implements OnInit, ControlValueAccessor {

  @Input() sectionsContainer: SectionsContainer;
  @Input() object;
  @Input() fromDateField;
  @Input() fromDateControlName: string;
  @Input() toDateField;
  @Input() toDateControlName: string;

  AConst = AConst;
  fromDateName: string;
  toDateName: string;
  fromDateControl: AbstractControl;
  toDateControl: AbstractControl;
  placeholderFromDate = 'not set';
  placeholderToDate = 'not set';

  inFocus = false;

  constructor(private fieldConditionService: FieldConditionService) {
  }

  ngOnInit() {
    this.fromDateName = this.fromDateField.name;
    this.toDateName = this.toDateField.name;
    this.placeholderFromDate = this.fromDateField[AConst.FIELD_TITLE];
    this.placeholderToDate = this.toDateField[AConst.FIELD_TITLE];
    this.fromDateControl = this.sectionsContainer.formGroup.controls[this.fromDateControlName];
    if (!this.fromDateControl) {
      console.error('Control not found: ' + this.fromDateControlName);
    }
    this.toDateControl = this.sectionsContainer.formGroup.controls[this.toDateControlName];
    if (!this.toDateControl) {
      console.error('Control not found: ' + this.toDateControlName);
    }
  }

  canShow(field) {
    const fieldParameters = new FieldParameters();
    fieldParameters.sectionsContainer = this.sectionsContainer;
    fieldParameters.rootObject = this.sectionsContainer.rootObject;
    fieldParameters.object = this.object;
    fieldParameters.field = field;
    return this.fieldConditionService.runIf('show', fieldParameters);
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
  }

}
