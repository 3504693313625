import {Component, Input, OnInit} from '@angular/core';
import {Annotation} from '../../annotation';

@Component({
  selector: 'app-annotation-description',
  templateUrl: './annotation-description.component.html',
  styleUrls: ['./annotation-description.component.scss']
})
export class AnnotationDescriptionComponent implements OnInit {

  @Input() curAnn: Annotation;

  constructor() {
  }

  ngOnInit() {
  }

}
