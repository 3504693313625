import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {FieldParameters} from '../../../core/field-parameters';

@Component({
  selector: 'app-field-select-selected-options',
  templateUrl: './edit-field-select-selected-options.component.html',
  styleUrls: ['./edit-field-select-selected-options.component.scss']
})
export class EditFieldSelectSelectedOptionsComponent implements OnInit {

  AConst = AConst;
  valueProp: String;
  selectedOptions = [];

  @Input() fieldParameters: FieldParameters;
  @Output() optionUnChecked = new EventEmitter<object>();

  constructor() {
  }

  ngOnInit() {
    const fieldInfo = this.fieldParameters.field;
    const propName = fieldInfo.name;
    this.selectedOptions = this.fieldParameters.object[propName];
    this.valueProp = '$$' + fieldInfo.inline.prop + '_value';
  }

  unCheckOption(option) {
    if (option['artifact_id'] == null) {
      const prop = this.fieldParameters.field.inline.prop;
      option['artifact_id'] = option[prop];
    }
    this.optionUnChecked.emit(option);
  }
}
