import {Component, OnDestroy, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {CommonsService} from '../../core/commons.service';
import {CmsApiService} from '../../core/cms-api.service';
import {StateService} from '@uirouter/core';
import {UiToolsService} from '../../core/ui-tools.service';
import {MediaHelperService} from '../../core/media-helper.service';
import {ConfirmDialogComponent} from '../../object-edit/confirm-dialog/confirm-dialog.component';
import {LoginService} from '../../core/login.service';
import {ActionMenuService} from '../../core/action-menu.service';
import {EventharvesterService} from '../../core/eventharvester.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy {
  AConst = AConst;
  menus = [];
  toggleMenuItem = false;
  openMessage = false;
  notifications = [];
  message = [];
  upComing = [];
  user;
  currentIndex = 0;
  computer = false;
  version = undefined;
  edition = undefined;
  images = null;
  waitTime = 600000;
  modalContent = 'TRANS__MODAL_DIALOG__ARE_YOU_SURE';
  currentState = '';
  private clickListenerId;

  constructor(private cms: CmsApiService,
              private commons: CommonsService,
              private loginService: LoginService,
              private stateService: StateService,
              private uiTools: UiToolsService,
              private mediaHelper: MediaHelperService,
              private modalService: MatDialog,
              private actionMenuService: ActionMenuService,
              private eventHarvester: EventharvesterService,
              private currentObjectService: CurrentObjectService) {
  }

  ngOnInit() {
    this.currentState = this.stateService.current.name;
    this.computer = this.uiTools.getClientType().isComputer;

    this.cms.getMainMenu().then(
      (data) => {
        if (data) {
          this.setMenuProp(data);
        }
      });

    this.clickListenerId = this.uiTools.addDocumentClickListener(() => {
      this.toggleMenuItem = false;
    });

    this.reloadScopeData();
    this.setEdition();
    this.checkChangedNotifications();
  }

  ngOnDestroy(): void {
    this.uiTools.removeDocumentClickListener(this.clickListenerId);
  }

  setMenuProp(menu) {
    this.menus = menu;
    this.commons.sortArray(this.menus, 'order');
    this.selectedItem();
  }

  toggleMenu() {
    this.toggleMenuItem = !this.toggleMenuItem;
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
  }

  toggleMessage() {
    this.openMessage = !this.openMessage;
  }

  previousMessage() {
    this.currentIndex = this.currentIndex - 1;
    if (this.currentIndex < 0) {
      this.currentIndex = this.notifications.length - 1;
    }
    return this.currentIndex;
  }

  nextMessage() {
    this.currentIndex = this.currentIndex + 1;
    if (this.currentIndex >= this.notifications.length) {
      this.currentIndex = 0;
    }
    return this.currentIndex;
  }

  goToMessage(index) {
    this.currentIndex = index;
  }

  getIndex(id) {
    let index = 0;
    this.notifications.forEach((item, i) => {
      if (item.message.id === id) {
        index = i;
      }
    });
    return index;
  }

  getUpComingVersion() {
    return new Promise((resolve, reject) => {
      this.cms.getUpcomingVersion().then(
        (data) => {
          let id, index, exist;
          if (data[AConst.MESSAGE]) {
            id = data[AConst.MESSAGE].id;
            index = this.getIndex(id);
            exist = this.notifications[index];

            if (!exist) {
              data[AConst.NAME] = 'upComing';
              this.upComing.push(data);
            } else {
              if ((JSON.stringify(data[AConst.MESSAGE].description) !== JSON.stringify(exist[AConst.MESSAGE].description)) ||
                (JSON.stringify(data[AConst.MESSAGE].title) !== JSON.stringify(exist[AConst.MESSAGE].title))) {
                this.notifications[index].message =
                  data[AConst.MESSAGE];
              }
              if (JSON.stringify(data[AConst.WHAT_IS_NEW]) !== JSON.stringify(exist[AConst.WHAT_IS_NEW])) {
                this.notifications[index][AConst.WHAT_IS_NEW] =
                  data[AConst.WHAT_IS_NEW];
              }
            }
          } else {
            if (this.upComing.length > 0) {
              this.upComing = [];
            }
          }
          resolve();
        }, (reason) => {
          reject(reason);
        }
      );
    });
  }

  setNotifications() {
    this.notifications = this.message.concat(this.upComing);
    this.commons.sortArray(this.notifications, 'priority');
  }

  getMessage() {
    return new Promise((resolve, reject) => {
      this.cms.getMessage().then(
        (data) => {
          if (data[AConst.MESSAGES]) {
            this.message = data[AConst.MESSAGES];
          } else {
            this.message = [];
          }
          resolve();
        },
        (response) => {
          if (reject) {
            reject(response);
          }
        }
      );
    });
  }

  checkChangedNotifications() {
    if (!this.computer) {
      return;
    }

    setInterval(() => {
      this.getMessage().then(
        () => this.getUpComingVersion()).then(
        () => this.setNotifications());

      if (this.currentIndex > this.notifications.length) {
        this.currentIndex = this.currentIndex - 1;
      }
    }, this.waitTime);
  }

  getArtifact(artifactId) {
    this.cms.getArtifact({artifact_id: artifactId, include_meta: true}).then((art) => {
        this.images = null;
        if (art[AConst.IMAGE_ID]) {
          art[AConst.ARTIFACT_ID] = art[AConst.IMAGE_ID];
          this.images = this.getImages([art]);
        }
      }
    );
  }

  getImages(images) {
    if (images && images.length > 0) {
      this.mediaHelper.addImageUrls(images);
    } else {
      return [];
    }
    return images;
  }

  setEdition() {
    this.edition = this.commons.setEditionTitle(this.user);
  }

  reloadScopeData() {
    this.user = this.loginService.getCurrentUser();
    this.version = this.user.version;
    this.getArtifact(this.user[AConst.ARTIFACT_ID]);
  }

  selectedItem(state?, params?) {
    let targetState, location, adminType, isAdmin;
    location = this.stateService.current.name;
    // adminType = $stateParams["admin_type"];


    if (state) {
      if (state === 'home.primus.faq') {
        this.eventHarvester.faqClicked();
      }
      location = state;
    }
    if (params) {
      adminType = params['admin_type'];
    }

    isAdmin = location.indexOf('home.primus.admin');
    this.menus.forEach((menu) => {
      if (menu[AConst.TARGET_STATE]) {
        targetState = menu[AConst.TARGET_STATE];
        if (targetState === location) {
          menu.isSelected = !(targetState === 'home.primus.admin.adminPage' &&
            adminType !== 'create-new');
        } else {
          menu.isSelected = targetState === 'home.primus.admin' &&
            isAdmin !== -1 && adminType !== 'create-new';
        }
      }
    });
    if (this.toggleMenuItem) {
      this.toggleMenuItem = false;
    }
  }

  goToCurrentUser() {
    const state = 'home.primus.artifact';
    const params = {
      artifactId: this.user.artifact_id,
      objectType: 'user'
    };
    this.stateService.go(state, params, {reload: true});
  }

  homeButton() {
    const state = 'home.primus.search';
    this.stateService.go(state, {}, {reload: true});
    this.selectedItem(state);
  }

  logOut() {
    if (this.currentObjectService.isEditing ||
      this.actionMenuService.isEditableActionMenu()) {
      this.safeLogout(true).then(confirmed => {
        if (confirmed) {
          this.safeLogoutFunction();
        }
      });
    } else {
      this.safeLogoutFunction();
    }
  }

  safeLogout(openDialog) {
    return new Promise(resolve => {
      if (openDialog) {
        const modalRef = this.modalService.open(ConfirmDialogComponent,
          {panelClass: 'confirm-dialog'});
        modalRef.componentInstance.modalContent = this.modalContent;
        modalRef.componentInstance.modalTitle = 'TRANS__HEADER__LOG_OUT';
        modalRef.afterClosed().subscribe(result => {
          resolve(result);
        });
      } else {
        resolve(null);
      }

    });
  }

  safeLogoutFunction() {
    this.actionMenuService.cancelActionMenu();
    this.loginService.logoutUser();
  }

}
