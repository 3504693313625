import {Component, Input, OnInit} from '@angular/core';
import {ReportSettings} from '../report-settings';

@Component({
  selector: 'app-report-layout-options',
  templateUrl: './report-layout-options.component.html',
  styleUrls: ['./report-layout-options.component.scss']
})
export class ReportLayoutOptionsComponent implements OnInit {
  @Input() reportSettings: ReportSettings;

  constructor() { }

  ngOnInit() {
  }

  reportTitlePage() {
    this.reportSettings.titlePage = !this.reportSettings.titlePage;
  }
  reportToc() {
    this.reportSettings.toc = !this.reportSettings.toc;
  }
  reportSingleSided() {
    this.reportSettings.singleSided = !this.reportSettings.singleSided;
  }
  reportLargeImages() {
    this.reportSettings.largeImages = !this.reportSettings.largeImages;
  }
  setTitle($event) {
    this.reportSettings.title = $event.target.value;
  }

}
