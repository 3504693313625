import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {CurrentObjectService} from '../../core/current-object.service';
import {AConst} from '../../core/a-const.enum';
import {ObjectContentDropdownTabComponent} from '../object-content-dropdown-tab/object-content-dropdown-tab.component';
import {ObjectContentMainTabComponent} from '../object-content-main-tab/object-content-main-tab.component';

@Component({
  selector: 'app-object-content-dropdown-menu',
  templateUrl: './object-content-dropdown-menu.component.html',
  styleUrls: ['./object-content-dropdown-menu.component.scss']
})
export class ObjectContentDropdownMenuComponent implements OnInit, OnDestroy {
  @ViewChild('moreMenuItem', { static: true }) moreMenuElement: ElementRef;
  @ViewChildren(ObjectContentDropdownTabComponent) dropdownMenuItems: QueryList<ObjectContentDropdownTabComponent>;


  @Input() contentInfo;
  @Input() menuItems: QueryList<ObjectContentMainTabComponent>;
  @Input() myRoot;
  @Input() selectedMainMenu;
  @Output() menuOpened = new EventEmitter();

  showMoreMenu = false;
  dropdownOpen = false;

  private closeDropdownListener;
  private toggleDropdownListener;
  private resizeListener;
  private buildMenuTimeout;

  constructor(private renderer: Renderer2,
              public currentObjectService: CurrentObjectService) {
  }

  ngOnInit() {
    this.toggleDropdownListener = this.renderer.listen(this.moreMenuElement.nativeElement, 'click', (event) => {
      this.toggleDropdown(event);
    });
    this.resizeListener = this.renderer.listen('window', 'resize', () => {
      this.buildMenu();
    });

    setTimeout(() => {
      this.initElements();
      this.buildMenu();
      this.updateActiveState(this.contentInfo.curListName);
      this.addWidthObservers();
    }, 100);
  }

  ngOnDestroy(): void {
    if (this.toggleDropdownListener) {
      this.toggleDropdownListener();
    }
    if (this.resizeListener) {
      this.resizeListener();
    }
  }

  openMenu(menu) {
    this.menuOpened.emit(menu);
  }

  private drawDropDown() {
    if (!this.dropdownOpen) {
      if (this.closeDropdownListener) {
        this.closeDropdownListener();
      }
    } else {
      this.closeDropdownListener = this.renderer.listen('document', 'click', () => {
        this.closeDropdown();
      });
    }
  }

  // dropdown controls
  private toggleDropdown(e) {
    if (e) {
      e.stopPropagation();
    }
    this.dropdownOpen = !this.dropdownOpen;
    this.drawDropDown();
  }


  closeDropdown() {
    this.dropdownOpen = false;
    this.drawDropDown();
  }

  private setVisibleDropdownMenus(visibleItems, set?) {
    if (this.contentInfo.menus) {
      this.contentInfo.menus.forEach((menu, index) => {
        this.contentInfo.visibleDropDowns[index] = set !== undefined ? set : visibleItems.indexOf(index) === -1;
      });
    }
  }

  private buildMenu() {
    const maxWidth = this.myRoot.offsetWidth;
    const activeItemIndex = this.getActiveItemIndex();
    this.setVisibleDropdownMenus([], false);
    if (this.buildMenuTimeout) {
      clearTimeout(this.buildMenuTimeout);
      this.buildMenuTimeout = null;
    }
    this.buildMenuTimeout = setTimeout(() => {
      const visibleItems = this.getVisibleItems(maxWidth, activeItemIndex);
      this.showMoreMenu = visibleItems.length < this.menuItems.length;
      if (this.showMoreMenu) {
        this.dropdownOpen = false;
        this.drawDropDown();
      }
      this.setVisibleDropdownMenus(visibleItems);
    }, 10);
  }

  private initElements() {
    const baseTag = this.myRoot.tagName;
    if (baseTag !== 'UL' && baseTag !== 'OL') {
      throw new Error('Wrong element: only OL and UL are supported by df-tab-menu');
    }

    this.renderer.setAttribute(this.myRoot, 'role', 'tablist');
    this.renderer.addClass(this.myRoot, 'df-tab-menu');

    this.menuItems.forEach(menuItem => {
      const mainTabElementItem = menuItem.getTabElement();
      this.renderer.setAttribute(mainTabElementItem, 'role', 'presentation');
      const moreElementsChildren = mainTabElementItem.children;
      for (let ec = 0; ec < moreElementsChildren.length; ec++) {
        this.renderer.setAttribute(moreElementsChildren[ec], 'aria-selected', 'false');
        this.renderer.setAttribute(moreElementsChildren[ec], 'role', 'tab');
      }
    });
  }

  private getActiveItemIndex() {
    this.menuItems.forEach((menuItem, i) => {
      if (menuItem.getTabElement().classList.contains('df-tab-menu-active')) {
        return i;
      }
    });
    return 0; // fallback
  }

  private getElementsSize() {
    const elementsSize = [];
    this.menuItems.forEach((menuItem, e) => {
      const menuElement = menuItem.getTabElement();
      const size = menuElement['offsetWidth'];
      if (size > 0) {
        elementsSize[e] = menuElement['offsetWidth'];
      } else {
        console.warn('No size');
      }
    });
    return elementsSize;
  }

  private getMoreElementSize() {
    return this.moreMenuElement.nativeElement.offsetWidth;
  }

  private getVisibleItems(_maxWidth, _activeItemIndex) {
    const elementsSize = this.getElementsSize();
    const visibleItems = [];
    // 40px: scrollbar tolerance. Not proud of this, but it works...
    let sum = elementsSize[_activeItemIndex] + this.getMoreElementSize() + 40;
    visibleItems.push(_activeItemIndex);
    this.menuItems.forEach((menuItem, i) => {
      if (i !== _activeItemIndex) {
        sum += elementsSize[i];
        if (sum > _maxWidth) {
          return visibleItems;
        } else {
          visibleItems.push(i);
        }
      }
    });
    return visibleItems;
  }

  private getCurListItem(listName) {
    let res = null;
    this.menuItems.forEach(menuItem => {
      if (menuItem.menu[AConst.CONTENT_LIST] === listName) {
        res = menuItem.getTabElement();
      }
    });
    return res;
  }

  private updateActiveState(listName) {
    // set active state
    const e1 = this.myRoot.querySelector('li.df-tab-menu-active');
    if (e1) {
      this.renderer.removeClass(e1, 'df-tab-menu-active');
      for (let t = 0; t < e1.children.length; t++) {
        this.renderer.setAttribute(e1.children[t], 'aria-selected', 'false');
      }
    }
    // const e2 = this.myRoot.querySelector('li[data-menu-item=\"' + listName + '\"][role=presentation]');
    const e2 = this.getCurListItem(listName);
    if (e2) {
      this.renderer.addClass(e2, 'df-tab-menu-active');
      for (let t = 0; t < e2.children.length; t++) {
        this.renderer.setAttribute(e2.children[t], 'aria-selected', 'true');
      }
    }
  }

  private addWidthObservers() {
    // #PRIMUS-1055:
    // Adding a MutationObserver, used to keep track of the total width of the tabs.
    // When the width of a tab changes, as numbers are being applied next to the tab-title,
    // an event is absorbed and the menu re-painted.
    const observer = new MutationObserver(() => {
      observer.disconnect();  // Disconnect observer, as this i a "one-time" operation as the page loads.
      this.buildMenu();            // Repaint the menu
    });
    // Make the target to observe, the container holding the menu elements.
    // Observe the menu container and its child-elements for mutations.
    const target = document.querySelector('ul[class="object-content-tabs__main-tabs df-tab-menu"]');
    observer.observe(target, {
      attributes: true,
      childList: true,
      subtree: true
    });
  }


}
