import { Injectable } from '@angular/core';
import {AConst} from './a-const.enum';
import {ModelsService} from './models.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectFieldTraverseService {

  constructor(private modelsSvc: ModelsService) { }

  traverseObjectByPath(object, fieldPath, pathIndexOffset, createArrayElement?) {
    let subObject = object;
    let parentObject = object;
    let fieldName = '';
    let parentFieldName = '';
    let parentIndex;
    let parentKey = '';
    let keySep = '';
    if (fieldPath) {
      const fieldNameSplits = fieldPath.split('.');
      fieldNameSplits.forEach((fieldNameSplit, index) => {
        const arrIndexRes = this.getFieldArrayIndex(fieldNameSplit);
        fieldName = arrIndexRes.fieldName;
        parentKey += keySep + fieldNameSplit;
        keySep = '.';
        if (subObject && index < fieldNameSplits.length - pathIndexOffset) {
          parentObject = subObject;
          subObject = subObject[fieldName];
          if (subObject) {
            if (Array.isArray(subObject)) {
              // Field path either contains array indexes, in which case the correct array items must be traversed,
              // or not, in which case the first element in the arrays are traversed because the indexes don't matter
              if (arrIndexRes.arrIndex !== -1) {
                subObject = subObject[arrIndexRes.arrIndex];
              } else {
                parentKey += '[{index1}]';
                if (subObject.length > 0) {
                  subObject = subObject[0];
                  parentIndex = 0;
                } else if (createArrayElement) {
                  const arrayMeta = object[AConst.$$META][fieldName];
                  const model = this.modelsSvc.getModel(arrayMeta.inline.model);
                  subObject.push(model);
                  subObject = subObject[0];
                  // This makes sure array based prime fields work
                  parentIndex = 0;
                } else {
                  console.warn('No items in array ' + fieldName);
                }
              }
            }
          }
        }
        if (index < fieldNameSplits.length - pathIndexOffset - 1) {
          parentFieldName = fieldName;
        }
      });
    }
    return {
      parentFieldName: parentFieldName,
      fieldName: fieldName,
      subObject: subObject,
      parentObject: parentObject,
      parentIndex: parentIndex,
      parentKey: parentKey
    };
  }

  getSubObjectFromField(object, field) {
    let subObject = object;
    if (field.path) {
      const traverseRes = this.traverseObjectByPath(object, field.path, 0);
      subObject = traverseRes.subObject;
    }
    return subObject;
  }

  getFieldMetaAndSubObjectFromPath(object, fieldPath: string) {
    const traverseRes = this.traverseObjectByPath(object, fieldPath, 1);
    let subObject = traverseRes.subObject;
    let subMeta;
    if (subObject) {
      let meta = subObject[AConst.$$META];
      if (!meta && traverseRes.parentFieldName) {
        const parentFieldMeta = object[AConst.$$META][traverseRes.parentFieldName];
        const model = this.modelsSvc.getModel(parentFieldMeta.inline.model);
        meta = model ? model[AConst.$$META] : null;
      }
      if (meta) {
        subMeta = meta[traverseRes.fieldName];
        if (!subMeta) {
          console.warn('Could not find meta field ' + traverseRes.fieldName);
        } else {
          if (subMeta.inline) {
            subObject = this.modelsSvc.getModel(subMeta.inline.model);
          }
        }
      } else {
        subMeta = {};
        console.warn('Sub object missing meta');
      }
    } else {
      subMeta = {};
      console.warn('Could not find sub object for ' + fieldPath);
    }

    return {
      fieldMeta: subMeta,
      subObject: subObject
    };
  }

  private getFieldArrayIndex(fieldNameIn: string) {
    const bracketPos = fieldNameIn.indexOf('[');
    let arrIndex = -1;
    let fieldName = fieldNameIn;
    if (bracketPos !== -1) {
      fieldName = fieldNameIn.substring(0, bracketPos);
      arrIndex = Number(fieldNameIn.substring(bracketPos + 1, fieldNameIn.indexOf(']')));
    }
    return {
      fieldName: fieldName,
      arrIndex: arrIndex
    };
  }

}
