import {Injectable} from '@angular/core';
import {CurrentObjectService} from './current-object.service';
import {SearchContainer} from '../object-search/search-container';

export class ContentInfo {
  artifact: any;
  contentListSource: any;
  curListName: string;
  curListSearchContainer: SearchContainer;
  menus: Array<any>;
  searchContainers: Array<SearchContainer>;
  selectedMainMenu: any;
  setContentMenus: any;
  setCurrentList: any;
  sources: any;
  templateGroupId: string;
  visibleDropDowns: Array<any>;
}

@Injectable({
  providedIn: 'root'
})
export class ContentInfoService {

  constructor(private currentObjectService: CurrentObjectService) {
  }

  updateContentInfo(contentInfo: ContentInfo, art, listName) {
    let useListName = listName;
    if (!contentInfo) {
      contentInfo = new ContentInfo();
    }
    if (this.currentObjectService.isEditing || !listName) {
      useListName = 'overview';
    }
    contentInfo.curListName = useListName;
    contentInfo.artifact = art;
    if (contentInfo.setContentMenus) {
      contentInfo.setContentMenus(contentInfo);
    }
    return contentInfo;
  }

}
