/**
 * Plugin used when exporting data as an Excel report.
 * @type {{renderReport}}
 */
import { Injectable } from '@angular/core';
import {ReporterTranslationService} from './reporter-translation.service';
import {ExtDate} from './ext-date';
import {ExtString} from './ext-string';
import {OverviewField} from '../core/object-view';
import {AConst} from '../core/a-const.enum';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(private reporterTranslation: ReporterTranslationService)  {

  }

  /**
   * Renders the HTML-table that will appear as the finished Excel-report.
   * @param headers
   * @param rows
   * @returns { string }
   * @private
   */
  private static renderTable(headers, rows) {
    return '<table>' + headers + '<tbody>' + rows + '</tbody></table>';
  }

  /**
   * Renders a report table row.
   * @param data
   * @returns { string }
   * @private
   */
  private static renderRow(data) {
    let r = '<tr>';
    for (const n in data) {
      if (data.hasOwnProperty(n)) {
        let s = data[n];
        if (typeof(s) === 'undefined' || s === null) {
          s = '';
        }
        const str = new ExtString(s.toString());
        r += '<td>' + str.escapeHtml() + '</td>';
      }
    }
    r += '</tr>';
    return r;
  }

  /**
   * Renders the report table header.
   * @param headers
   * @returns { string }
   * @private
   */
  private renderHeaders(headers) {
    let h = '<thead><tr>';
    headers.map(function (obj) {
      const str = new ExtString(obj);
      h += '<th align="left">' + str.escapeHtml() + '</th>';
    });
    h += '</tr></thead>';
    return h;
  }

  /**
   * Renders the report table rows.
   * @param rows
   * @returns { string }
   * @private
   */
  private renderRows(rows) {
    let html = '';
    rows.map((o) => {
      html += ExcelService.renderRow(o);
    });

    html += '<tr><td></td></tr>' +
      '<tr><td colspan="4">Rapport generert: ' + ExtDate.getHrDateTime() + '</td></tr>';

    return html;
  }

  /**
   * Gets the report column headers.
   * @private
   */
  private getColHeaders(colHeaders, rpColHeaders) {
    colHeaders.map((o) => {
      rpColHeaders.push(this.reporterTranslation.getString(o.fieldTitle));
    });
  }

  /**
   * Mapping the data set, only fetching the values that
   * corresponds to the specified column headers.
   * @param selectedObjects
   * @param overviewFieldObject
   * @returns {Array}
   * @private
   */
  private mapDataSet(selectedObjects: Array<any>, overviewFieldObject) {
    const dataSet = [];

    selectedObjects.forEach(object => {
      const artifactId = object[AConst.ARTIFACT_ID];
      const overviewFields: Array<OverviewField> = overviewFieldObject[artifactId];
      const r = {
        artifact_name: null
      };
      r.artifact_name = object[AConst.ARTIFACT_NAME];
      overviewFields.forEach(overviewField => {
        r[overviewField.field_name] = overviewField['value'];
      });
      dataSet.push(r);
    });
    return dataSet;
  }


  /**
   * Parses the report's data set.
   * @param rows
   * @param rpRows
   * @private
   */
  private parseDataSet(rows, rpRows) {
    rows.map(function (r) {
      rpRows.push(r);
    });
  }

  /**
   * Renders the report as HTML.
   * @param selectedArtifacts
   * @param overviewFields
   * @param colHeaders
   */
  renderReport(selectedArtifacts, overviewFields: any, colHeaders) {
    const rpColHeaders = ['Navn'];
    const rpRows = [];

    // Collect data
    this.getColHeaders(colHeaders, rpColHeaders);  // Get column names/headers
    const data = this.mapDataSet(selectedArtifacts, overviewFields); // Map data set to match column names/headers
    this.parseDataSet(data, rpRows);

    // Render the report table
    const reportContent = ExcelService.renderTable(this.renderHeaders(rpColHeaders), this.renderRows(rpRows));

    console.log('Excel report complete');

    return reportContent;
  }
}
