import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {ActionMenuContainer} from '../../core/action-menu-container';
import {UploadMediaContainer} from '../../core/upload-media-container';

@Component({
  selector: 'app-object-menu-upload',
  templateUrl: './object-menu-upload.component.html',
  styleUrls: ['./object-menu-upload.component.scss']
})
export class ObjectMenuUploadComponent implements OnInit {

  @Input() menuContainer: ActionMenuContainer;
  @Input() menu;

  AConst = AConst;
  uploadMediaContainer: UploadMediaContainer;

  constructor() {
  }

  ngOnInit() {
    this.uploadMediaContainer = new UploadMediaContainer();
    this.uploadMediaContainer.uploadInfo = this.menu[AConst.UPLOAD_INFO];
    this.uploadMediaContainer.parentObject = this.menuContainer.art;
    this.menu['uploadMediaContainers'] = [this.uploadMediaContainer];
    this.menu['changePropertyParent'] = this.uploadMediaContainer;
    this.menu['changePropertyField'] = 'files';
  }

}
