import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss']
})
export class ImageZoomComponent implements OnInit, OnChanges {
  zoomValuePercent = 0;
  inputBackground = 'linear-gradient(to left, #c2d6d6 100%, #00b0ff 0%)';
  @Output() changeZoomVal = new EventEmitter<object>();
  @Input() overlay;
  @Input() zoomValue;

  constructor(private elementRef: ElementRef, ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    const inputRange = this.elementRef.nativeElement.querySelector('#zoom');
    inputRange.value = this.zoomValue;
    this.setInputRangeBackground(this.zoomValue);
  }

  changeZoomValue(value) {
    this.setInputRangeBackground(value);
    this.changeZoomVal.emit(value);
  }

  private setInputRangeBackground(value) {
    this.zoomValuePercent = ((value - 1) * 100);
    if (this.zoomValuePercent < 50) {
      this.inputBackground = 'linear-gradient(to left, #c2d6d6 ' +
        (100 - this.zoomValuePercent) + '%,  #00b0ff ' +
        this.zoomValuePercent + '%)';
    } else {
      this.inputBackground = 'linear-gradient(to right, #00b0ff ' +
        this.zoomValuePercent + '%, #c2d6d6 ' +
        (100 - this.zoomValuePercent) + '%)';
    }
  }
}
