import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {UiToolsService} from '../../core/ui-tools.service';
import {MediaHelperService} from '../../core/media-helper.service';
import {CmsApiService} from '../../core/cms-api.service';
import {CommonsService} from '../../core/commons.service';
import {UploadService} from '../../core/upload.service';
import {UploadInfo} from '../../object-view/upload-info';

@Component({
  selector: 'app-media-selector-general',
  templateUrl: './media-selector-general.component.html',
  styleUrls: ['./media-selector-general.component.scss']
})
export class MediaSelectorGeneralComponent implements OnInit {

  @Input() uploadInfo: UploadInfo;
  @Input() parentObject: any;
  @Input() files: Array<any>;

  AConst = AConst;
  objectType: string;
  multipleFiles = true;
  fileType: string;
  fileAccept: string;
  fromCamera = false;
  selectFromParent = false;
  selectImg = false;
  cameraOpen = false;
  parentMedia = [];
  computer = false;
  selectedItems = [];

  private uploadContainer = {};

  constructor(private uiTools: UiToolsService,
              private mediaHelper: MediaHelperService,
              private cms: CmsApiService,
              private commons: CommonsService,
              private uploadService: UploadService) {
  }

  ngOnInit() {
    const contexts = this.parentObject.contexts;
    const parentId = this.parentObject[AConst.PARENT_ID];
    this.objectType = this.uploadInfo.object_type;
    this.multipleFiles = this.uploadInfo.multiple_files;
    this.fileType = this.uploadInfo.file_type;
    this.fileAccept = this.uploadInfo.file_accept;
    this.fromCamera = this.uploadInfo.from_camera;
    this.selectFromParent = this.uploadInfo.select_from_parent;


    if (contexts && contexts.length > 0) {
      contexts.forEach(context => {
        this.loadParentMedia(context[AConst.CONTEXT_ARTIFACT_ID]);
      });
    }
    if (parentId) {
      this.loadParentMedia(parentId);
    }

    this.computer = this.uiTools.getClientType().isComputer;
  }

  openCamera() {
    this.cameraOpen = !this.cameraOpen;
    this.selectImg = false;
  }

  uploadFile() {
    this.selectImg = false;
    this.cameraOpen = false;
  }

  selectFiles() {
    this.selectImg = !this.selectImg;
    this.cameraOpen = false;
  }

  deleteFile(index) {
    const file = this.files[index];
    if (file._create) {
      this.files.splice(index, 1);
    } else {
      file._destroy = true;
    }
  }

  // At the drag drop area
  // (drop)="onDropFile($event)"
  onDropFile(event) {
    event.preventDefault();
    this.uploadFiles(event.dataTransfer.files);
  }

  // At the drag drop area
  // (dragover)="onDragOverFile($event)"
  onDragOverFile(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  // At the file input element
  // (change)="selectFile($event)"
  onFileSelect(event) {
    this.uploadFiles(event.target.files);
  }

  uploadFiles(files) {
    const identifier = this.parentObject[AConst.IDENTIFIER];
    let extraData;
    if (identifier) {
      extraData = {
        identifier: identifier
      };
    }
    this.uploadService.uploadFiles(this.uploadContainer, files, this.fileType).then(res => {
        if (!this.multipleFiles) {
          this.files[0] = res[0];
        } else {
          res.forEach(file => {
            this.files.push(file);
          });
        }
      }, extraData
    );
  }

  private loadParentMedia(parentId) {
    this.loadNewParentMedia(parentId).then(media => {
      this.mediaHelper.addImageUrls(media);
      this.parentMedia = this.parentMedia.concat(media);
    });
  }

  private loadNewParentMedia(parentId) {
    return new Promise(resolve => {
      this.cms.getArtifactImages({artifact_id: parentId}).then(
        data => {
          const newImages = data[AConst.IMAGES];
          const res = [];
          newImages.forEach(newImage => {
            let exists = false;
            this.parentMedia.forEach(existingImg => {
                if (newImage[AConst.ARTIFACT_ID] === existingImg[AConst.ARTIFACT_ID]) {
                  exists = true;
                }
              }
            );
            if (!exists) {
              res.push(newImage);
            }
          });
          resolve(res);

        },
        function (status) {
          console.warn('Failed with code: ' + status);
        }
      );
    });
  }

  private getArtifactParams(item) {
    const itemType = item[AConst.OBJECT_TYPE];
    let getParams;
    switch (itemType) {
      case 'ImageItem':
        getParams = {artifact_id: item[AConst.IMAGE_ID]};
        break;
      case 'VideoItem':
        getParams = {artifact_id: item[AConst.VIDEO_ID]};
        break;
      case 'AttachmentItem':
        getParams = {artifact_id: item[AConst.ATTACHMENT_ID]};
        break;
      default:
        console.warn('No handling of item type ' + itemType);
    }
    return getParams;
  }

  private onSelectedItem(item) {
    let alreadyAdded = false;
    const getParams = this.getArtifactParams(item);
    if (getParams) {
      this.cms.getArtifact(getParams).then(object => {
        this.files.forEach(file => {
          if (file[AConst.ARTIFACT_ID] === object[AConst.ARTIFACT_ID]) {
            alreadyAdded = true;
          }
        });
        if (!alreadyAdded) {
          this.files.push(object);
        }
      });
    }
  }

}
