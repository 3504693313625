import {Component, Input, OnInit} from '@angular/core';
import {ReportMainTypes, ReportSettings} from '../report-settings';

@Component({
  selector: 'app-report-options',
  templateUrl: './report-options.component.html',
  styleUrls: ['./report-options.component.scss']
})
export class ReportOptionsComponent implements OnInit {

  @Input() reportSettings: ReportSettings;
  ReportMainTypes = ReportMainTypes;

  constructor() { }

  ngOnInit() {
  }

  toggleBelongingEvents() {
    this.reportSettings.belongingEvents.set = !this.reportSettings.belongingEvents.set;
    this.reportSettings.reportAdminEvents.forEach((event) => {
      event.selected = this.reportSettings.belongingEvents.set;
    });
  }

  toggleEventSelected(event) {
    event.selected = !event.selected;
  }

  toggleSetting(setting) {
    setting.set = !setting.set;
  }

  toggleAllSelectedReport() {
    this.reportSettings.allSelectedReport.set = !this.reportSettings.allSelectedReport.set;
    this.reportSettings.fullReport.set = this.reportSettings.allSelectedReport.set;
    this.reportSettings.belongingEvents.set = this.reportSettings.allSelectedReport.set;
    this.reportSettings.reportAdminEvents.forEach((event) => {
      event.selected = this.reportSettings.allSelectedReport.set;
    });
  }

}
