import {Injectable} from '@angular/core';
import {SearchHandlerService} from '../object-search/search-handler.service';
import {ObjectStorageService} from './object-storage.service';
import {SelectorContainer} from './selector-container';
import {AConst} from './a-const.enum';

@Injectable({
  providedIn: 'root'
})
export class SearchSelectorService {

  constructor(private searchHandler: SearchHandlerService,
              private objectStorage: ObjectStorageService) {
  }

  createSelectorContainer(art): SelectorContainer {
    return {
      art: art,
      selectorEnabled: false,
      searchContainer: null,
      selectorCallback: null
    };
  }

  disableSelector(container: SelectorContainer) {
    document.getElementsByTagName('body')[0].classList.remove('selector-open');
    document.getElementsByTagName('html')[0].classList.remove('selector-open');
    container.selectorEnabled = false;
  }

  enableSelector(selector, container: SelectorContainer, params?, fns?, templateGroupId?) {
    const filters = this.getFilters(selector.filters, container.art);
    const singleSelect = selector.type.indexOf('single') !== -1;
    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          selector[key] = params[key];
        }
      }
    }

    if (container.selectorEnabled) {
      this.disableSelector(container);
      return;
    }

    filters[AConst.CONTEXT_OBJECT_TYPE] = container.art[AConst.CONTEXT_OBJECT_TYPE];

    this.searchHandler.createSearchContainer(
      {
        searchViewName: selector.view,
        filters: filters,
        defaultCheckedFilters: this.getFilters(selector[AConst.CHECKED_FILTERS], container.art),
        runSearch: false,
        selected: selector.selected,
        used: selector.used,
        restrictions: selector.restrictions,
        keepSelected: true
      }).then(
      (sc) => {
        container.searchContainer = sc;
        sc.query = selector.query;
        sc.singleSelect = singleSelect;
        sc.selectCallback = (ids) => {
          this.selectCallback(selector, fns, ids, templateGroupId);
        };
        sc.selectButtonText = selector[AConst.SELECT_BUTTON_TEXT];
        fns.searchContainerCreated(sc);
        this.searchHandler.runSearch(sc, true);
        document.getElementsByTagName('body')[0]
          .classList.add('selector-open');
        document.getElementsByTagName('html')[0]
          .classList.add('selector-open');
        container.selectorEnabled = true;
      }
    );
  }

  private getFilters(filterInfo, art) {
    const res = {};

    if (filterInfo) {
      filterInfo.forEach((filter) => {
        let filterVal;
        const sourceField = filter[AConst.SOURCE_FIELD];
        res[filter.field] = res[filter.field] || [];
        if (sourceField) {
          filterVal = art[sourceField];
        } else if (filter.value) {
          filterVal = filter.value;
        } else {
          throw new Error('Missing filter value!');
        }
        res[filter.field].push(filterVal);
      });
    }
    return res;
  }

  private selectCallback(selector, fns, objIds, templateGroupId) {
    if (!objIds) {
      fns.selectorCallback([]);
    } else {
      if (selector.type.indexOf('copy') === -1) {
        this.loadCallback(fns, objIds, templateGroupId);
      } else {
        this.copyCallback(fns, objIds, templateGroupId);
      }
    }
  }

  private loadCallback(fns, objIds, templateGroupId) {
    this.objectStorage.loadObjects(objIds, templateGroupId).then(
      arts => {
        fns.selectorCallback(arts);
      },
      reason => {
        console.error('Failed loading selected artifacts: ' + reason.error.message);
      }
    );
  }

  private copyCallback(fns, objIds, templateGroupId) {
    this.objectStorage.copyObjects(objIds, templateGroupId).then(
      copies => {
        const artifactContainer = <HTMLElement>document.querySelector('#scrollToThisFromCopyCallback');
        if (artifactContainer) {
          window.scrollTo({
            top: artifactContainer.offsetTop - 50,
            behavior: 'smooth'
          });
        }
        fns.selectorCallback(copies);
      },
      reason => {
        console.error('Failed copying selected artifacts: ' + reason.error.message);
      }
    );
  }


}
