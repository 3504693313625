import {Component, Input, OnChanges, OnDestroy, Renderer2} from '@angular/core';
import {StateService} from '@uirouter/core';
import {SearchHandlerService} from '../search-handler.service';
import {CommonsService} from '../../core/commons.service';
import {UiToolsService} from '../../core/ui-tools.service';
import {AConst} from '../../core/a-const.enum';
import {SearchContainer} from '../search-container';
import {group, query, stagger, style, transition, trigger, useAnimation} from '@angular/animations';
import {fadeIn, fadeOut, slideDownMenu, slideUpMenu} from '../../shared/animations';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-search-query',
  templateUrl: './search-query.component.html',
  styleUrls: ['./search-query.component.scss'],
  animations: [
    trigger('slideInPathMenu', [
      transition(':leave', [
        group([
          useAnimation(slideUpMenu, {params: {time: '300ms ease-in-out'}}),
          query('.search-query-menu-item', [
            style({opacity: 1}),
            stagger(-100, useAnimation(fadeOut, {params: {time: '50ms'}}))
          ]),
        ])
      ]),
      transition(':enter', [
        group([
          useAnimation(slideDownMenu, {params: {time: '300ms ease'}}),
          query('.search-query-menu-item', [
            style({opacity: 0}),
            stagger(100, useAnimation(fadeIn, {params: {time: '50ms'}}))
          ]),
        ])
      ]),
    ])
  ]
})
export class SearchQueryComponent implements OnChanges, OnDestroy {
  @Input() searchContainer: SearchContainer;
  @Input() placeholder: string;
  @Input() hideIcon: boolean;
  @Input() setFocus;
  @Input() newLayout;

  private sc: SearchContainer;
  private stopSizeWatch;
  private menuPathPlaceholder;
  private queryMenuPlaceHolder;
  private clickListenerId;
  showPathMenu: boolean;
  showMenu = false;
  mediumScreen: boolean;
  pathAsFilter: boolean;
  queryMenuText = 'TRANS__HEADER__SEARCH_PLACEHOLDER';


  constructor(private $state: StateService,
              private searchHandler: SearchHandlerService,
              private commons: CommonsService,
              private uiTools: UiToolsService,
              private renderer: Renderer2,
              private translate: TranslateService) {
  }

  ngOnChanges() {
    this.stopSizeWatch = this.uiTools.addWindowSizeListener(
      (newVal) => {
        if (newVal) {
          this.mediumScreen = newVal.width < 1025;
        }
      });
    this.mediumScreen = this.uiTools.setMediumScreenSizeOnLoad(this.mediumScreen);
    if (this.setFocus && !this.mediumScreen) {
      setTimeout(() => {
        const element = this.renderer.selectRootElement('#searchQuery');
        element.focus();
      }, 1000);
    }
    this.clickListenerId = this.uiTools.addDocumentClickListener(() => {
      this.showMenu = false;
    });
    this.sc = this.searchContainer;
    this.pathAsFilter = this.commons.isObjectState(this.$state.current.name);
  }

  ngOnDestroy() {
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
    this.uiTools.removeDocumentClickListener(this.clickListenerId);
  }

  queryChanged() {
    const targetState = this.sc.state.targetState;
    this.sc.searchPage = 1;
    // Searching while typing shall only occur if search will not cause
    // change of target state
    if (!targetState || targetState === this.$state.current.name) {
      this.search();
    }
  }

  submit() {
    this.search();
  }

  onTextClick() {
    this.showMenu = false;
    this.showPathMenu = false;
  }

  toggleMenu() {
    const queryMenus = this.sc.currentPathView[AConst.QUERY_MENUS];
    if (queryMenus) {
      this.showMenu = !this.showMenu;
      this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    } else {
      this.search();
    }
  }

  selectQueryMenu(menu) {
    if (!menu.selected) {
      this.sc.selectedQueryMenu.selected = false;
      this.sc.selectedQueryMenu = menu;
      this.queryMenuText = menu['description'];
      if (menu[AConst.QUERY_TYPE] === 'general') {
        this.sc.queryField = null;
        this.queryMenuPlaceHolder = null;
      } else {
        this.sc.queryField = menu[AConst.QUERY_FIELD];
        this.queryMenuPlaceHolder = this.translate.instant(menu['description']);
      }
      menu.selected = true;
      this.search();
    }
  }

  clearSearch() {
    this.searchContainer.query = null;
    this.queryChanged();
  }

  get pathViewMenus() {
    let res = [];
    if (this.searchContainer.currentPathView[AConst.QUERY_MENUS]) {
      res = this.searchContainer.currentPathView[AConst.QUERY_MENUS].menus;
    }
    return res;
  }

  get searchCount() {
    let res = 0;
    if (this.searchContainer.searchResult) {
      res = this.searchContainer.searchResult[AConst.SEARCH_COUNT];
    }
    return res;
  }

  get searchPlaceHolder() {
    if (this.queryMenuPlaceHolder) {
      return this.queryMenuPlaceHolder;
    } else if (this.menuPathPlaceholder) {
      return this.menuPathPlaceholder;
    } else {
      return this.getDefaultPlaceHolder();
    }
  }

  onMenuPathSet(menuTitle) {
    this.menuPathPlaceholder = this.createPlaceHolder(menuTitle);
  }

  private createPlaceHolder(text) {
    return this.translate.instant('TRANS__FILTER__SEARCH_IN') + ' ' + this.translate.instant(text).toLocaleLowerCase();
  }

  private getDefaultPlaceHolder() {
    let res;
    if (this.placeholder) {
      res = this.translate.instant(this.placeholder);
    } else {
      res = this.createPlaceHolder(this.sc.queryPlaceholder);
    }
    return res;
  }

  private search() {
    if (this.checkSearchContainer()) {
      if (this.sc.state.targetState && this.$state.current.name !== this.sc.state.targetState) {
        this.$state.go(
          this.sc.state.targetState,
          {query: this.sc.query, path: this.sc.path},
          {reload: true});
      } else {
        this.searchHandler.runSearch(this.sc, true).then();
      }
    }
  }

  // Check whether search container has been created yet
  private checkSearchContainer() {
    if (!this.sc) {
      console.log('Search container not created!');
      return false;
    } else {
      return true;
    }
  }


}
